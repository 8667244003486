import React, { Component } from 'react'
import { Toast } from 'antd-mobile'
import { findParamDataRecord, findEnvironmentInfo } from '../../../api/api'
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import ReactEcharts from 'echarts-for-react';

class EnvironmentDetail extends Component {

  state = {
    data: {},
    curveData: [], // 数据曲线
  }

  componentDidMount = () => {
    this.setState({
      data: this.props.location.state.item
    })
    this.handleCurve(this.props.location.state.item)
  }

  // 显示文字
  handleWord = val => {
    // 设备状态：1 在线 2 离线
    if (val.device_state === 1) {
      return val.parameter_value ? val.parameter_value : '--'
    } else {
      return '离线'
    }
  }

  // 刷新
  handleSx = async () => {
    Toast.loading('', 0)
    const { data } = this.state
    const res = await findEnvironmentInfo()
    if (res.code === 20000) {
      const obj = res.data.find(x => x.parameter_id === data.parameter_id)
      this.setState({
        data: obj
      })
      this.handleCurve(obj)
    }
  }

  // 数据曲线
  handleCurve = async item => {
    const res = await findParamDataRecord({ parameterId: item.parameter_id })
    if (res.code === 20000) {
      Toast.hide()
      this.setState({
        curveData: res.data
      })
    }
  }

  render() {
    const { data, curveData } = this.state
    const option = {
      grid: {
        top: '9%',
        left: '12%',
        right: '3%',
        bottom: '10%',
      },
      xAxis: {
        type: 'category',
        axisLine: {    // x轴线的宽度
          show: true,
          lineStyle: {
            width: 0,
            type: "dashed"
          }
        },
        axisTick: {
          show: false // 不显示坐标轴刻度线
        },
        axisLabel: {
          textStyle: {
            color: '#646566', // x轴字体颜色
            fontSize: 10,
          },
        },
        data: curveData.map(x => x.times)
      },
      yAxis: {
        type: 'value',
        axisLine: {      // y轴线的颜色以及宽度
          show: true,
          lineStyle: {
            color: "#e5e5ea",
            width: 1,
            type: "solid"
          },
        },
        axisTick: {
          show: false // 不显示坐标轴刻度线
        },
        splitLine: {
          lineStyle: {
            color: "#e5e5ea",
            type: 'dashed' // 虚线
          },
        },
        axisLabel: {
          textStyle: {
            color: '#646566', // y轴字体颜色
            fontSize: 10,
          },
          formatter: function (value) {
            if (curveData.filter(x => x.parameter_flag).length) {
              var texts = [];
              if (value === 0) {
                texts.push(0);
              } else if (value === 1) {
                texts.push(curveData.find(x => x.parameter_flag === 1).parameter_value);
              } else if (value === 2) {
                texts.push(curveData.find(x => x.parameter_flag === 1).parameter_value);
              }
              return texts;
            } else {
              return value
            }
          }
        },
      },
      series: [
        {
          type: 'line',
          smooth: true,
          itemStyle: {
            normal: {
              color: "#00b4c4",
              lineStyle: {
                color: "#00b4c4",
                width: 2,
              },
              areaStyle: {   //添加背景颜色
                type: "default",
                color: "#00b4c4",  //背景颜色
                opacity: 0.06,   //透明度
              },
            },
          },
          data: curveData.filter(x => x.parameter_flag).length ? curveData.map(x => x.parameter_flag) : curveData.map(x => x.parameter_value),
        }
      ]
    };
    return (
      <div className={`banpai_detail ${data.device_state === 1 ? 'environment_detail_online' : 'banpai_detail_offline'}`}>
        <div className='banpai_detail_img'>
          <img alt='' src={require('../../../images/hjjc.png')} />
        </div>
        <div className='banpai_detail_main'>
          <div className='banpai_detail_con environment_detail_con'>
            <div className='banpai_detail_con_left' style={{ maxWidth: `${data.device_state === 1 ? '224px' : 'auto'}` }}>
              <div className='banpai_detail_con_left_name'>{data.deviceName}</div>
              <div className='banpai_detail_con_left_addr'>{data.roomName}</div>
              <div className='banpai_detail_con_left_desc'>
                <div className={`banpai_detail_con_left_status ${data.device_state === 1 ? 'banpai_detail_status_online' : 'banpai_detail_status_offline'}`}>
                  <span></span>
                  <span>{data.device_state === 1 ? '设备在线' : '设备离线'}</span>
                </div>
              </div>
            </div>
            {
              data.device_state === 1 ? <div className='environment_detail_con_r'>
                <div className='environment_detail_con_r_sj'>
                  {
                    this.handleWord(data) === '离线' ? <span style={{ color: '#969799' }}>离线</span> : this.handleWord(data)
                  }
                  {this.handleWord(data) === '离线' ? '' : data.parameter_unit}
                </div>
                <div className='environment_detail_con_r_type'>{data.parameter_name}</div>
              </div> : <div></div>
            }
            {/* {
              data.device_state !== 1 ? <div className='banpai_detail_con_sx' onClick={() => this.handleSx()}>
                <img alt='' src={require('../../../images/sx.png')} />
                <span>刷新状态</span>
              </div> : ''
            } */}
          </div>
          <div className='banpai_detail_operation' onClick={() => this.props.history.push(`/module/environment/environmentAlarm/${data.parameter_id}`)}>
            <div className='banpai_detail_operation_left'>
              <img alt='' src={require('../../../images/gj.png')} />
              <span>告警数据</span>
            </div>
            <img alt='' src={require('../../../images/more2.png')} />
          </div>
        </div>
        <div className='environment_curve'>
          <div className='environment_modal_con'>
            <div className='environment_modal_con_title'>最近12条数据曲线</div>
            <div className='environment_modal_con_echarts'>
              <ReactEcharts option={option} style={{ height: '262px', width: '100%' }} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EnvironmentDetail
