import React, { Component } from 'react'
import { statisticsReport, statisticsProjectBj } from '../../../api/api'
import ReactEcharts from 'echarts-for-react'

class TestState extends Component {

  state = {
    reportData: [],
    classData: [], // 班级信息
  }

  componentDidMount = () => {
    this.fetchInfo(this.props.match.params.id)
    this.fetchClass(this.props.match.params.id)
  }

  fetchInfo = async templateId => {
    const res = await statisticsReport({ templateId })
    if (res.code === 20000) {
      this.setState({
        reportData: [res.data.tjNum || 0, res.data.bjNum || 0]
      })
    }
  }

  fetchClass = async templateId => {
    const res = await statisticsProjectBj({ templateId })
    if (res.code === 20000) {
      this.setState({
        classData: res.data
      })
    }
  }

  render() {
    const { reportData, classData } = this.state
    const option1 = {
      grid: {
        top: '10%',
        left: '12%',
        right: '5%',
        bottom: '10%',
      },
      color: ['#279cf7'],
      xAxis: {
        type: 'category',
        axisLine: {    // x轴线的宽度
          show: true,
          lineStyle: {
            width: 1,
            type: "solid",
            color: '#dee0e3', // x轴字体颜色
          }
        },
        axisTick: {
          show: false // 不显示坐标轴刻度线
        },
        axisLabel: {
          textStyle: {
            color: '#848a92', // x轴字体颜色
            fontSize: 12,
          },
        },
        data: ['报告提交', '上课班级']
      },
      yAxis: {
        type: 'value',
        axisLine: {      // 不显示y轴0坐标轴线
          show: false
        },
        axisTick: {
          show: false // 不显示坐标轴刻度线
        },
        splitLine: {
          lineStyle: {
            color: "#dee0e3",
            type: 'dashed' // 虚线
          },
        },
        axisLabel: {
          textStyle: {
            color: '#848a92', // y轴字体颜色
            fontSize: 12,
          }
        },
      },
      series: [
        {
          type: 'bar',
          barWidth: 12,
          itemStyle: {
            normal: {
              //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
              barBorderRadius: [2, 2, 0, 0]
            }
          },
          data: reportData
        }
      ]
    };
    const option2 = {
      grid: {
        top: '10%',
        left: '12%',
        right: '5%',
        bottom: '10%',
      },
      color: ['#f15f2c'],
      xAxis: {
        type: 'category',
        axisLine: {    // x轴线的宽度
          show: true,
          lineStyle: {
            width: 1,
            type: "solid",
            color: '#dee0e3', // x轴字体颜色
          }
        },
        axisTick: {
          show: false // 不显示坐标轴刻度线
        },
        axisLabel: {
          textStyle: {
            color: '#848a92', // x轴字体颜色
            fontSize: 12,
          },
        },
        data: (classData || []).map(x => x.kcbh)
      },
      yAxis: {
        type: 'value',
        axisLine: {      // 不显示y轴0坐标轴线
          show: false
        },
        axisTick: {
          show: false // 不显示坐标轴刻度线
        },
        splitLine: {
          lineStyle: {
            color: "#dee0e3",
            type: 'dashed' // 虚线
          },
        },
        axisLabel: {
          textStyle: {
            color: '#848a92', // y轴字体颜色
            fontSize: 12,
          }
        },
      },
      series: [
        {
          type: 'line',
          smooth: true,
          areaStyle: {  //区域填充颜色
            color: 'rgba(241, 95, 44, 0.2)'
          },
          data: (classData || []).map(x => x.tjNum)
        }
      ]
    };
    return (
      <div className='data_state detail_state'>
        <div className='data_state_list'>
          <div className='data_state_list_header'>
            <div className='data_state_list_title'>
              <div></div>
              <div>报告提交和上课班级数量统计</div>
            </div>
          </div>
          <div className='data_state_list_echarts'>
            <ReactEcharts option={option1} style={{ height: 230 }} />
          </div>
        </div>
        <div className='data_state_list'>
          <div className='data_state_list_header'>
            <div className='data_state_list_title'>
              <div></div>
              <div>各个班级提交人数</div>
            </div>
          </div>
          <div className='data_state_list_echarts'>
            <ReactEcharts option={option2} style={{ height: 230 }} />
          </div>
        </div>
      </div>
    )
  }
}

export default TestState