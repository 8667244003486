import React, { Component } from 'react'
import { TreeSelect, Select } from 'antd'
import { Picker, List, InputItem, DatePicker, Toast } from 'antd-mobile';
import { listDict, addPdInfo, findRepository, req_findDepartmentInfoToTeacher } from './../../../../api/whp'
import './add.less'
import moment from 'moment';

const { TreeNode } = TreeSelect;

class Add extends Component {
  state = {
    warehouseData: [],
    wpflData: [],
    depUserData: [],
    pdmc: '',
    pdckid: undefined,
    pdlxdmtj: undefined,
    pdrkrzhtj: undefined,
    pdsjtj: undefined,
  }

  componentDidMount = () => {
    document.title = '新增盘点'
    window.scrollTo(0, 0)
    this.fetchWarehouse()
    this.fetchWpfl()
    this.fetchDepUser()
  }

  // 查询仓库
  fetchWarehouse = async () => {
    const res = await findRepository()
    if (res.code === 20000) {
      const data = res.data.map(item => {
        return {
          label: item.repository_name,
          value: item.repository_id,
        }
      })
      this.setState({
        warehouseData: data
      })
    }
  }

  // 查询物品分类
  fetchWpfl = async () => {
    const res = await listDict({ ZDLX: 'lb' })
    if (res.code === 20000) {
      this.setState({
        wpflData: res.data
      })
    }
  }

  // 查询部门和人员
  fetchDepUser = async () => {
    const res = await req_findDepartmentInfoToTeacher()
    if (res.code === 20000) {
      let menuList = (res.data || []).map(item => {
        return {
          title: item.dwmc,
          value: item.dw_id,
          parentId: item.sj_dm,
          disabled: true,
          children: (item.teacherInfoEntities || []).map(val => {
            return {
              title: val.xm,
              value: val.gh,
              parentId: val.dwdm,
              disabled: false,
            }
          })
        }
      })
      menuList = menuList.map(item => {
        item.children = item.children.length ? item.children : menuList.filter(p => p.parentId === item.value)
        return item
      }).filter(p => p.parentId === '0')
      const a = []
      const c = a.concat(menuList)
      const depArr = this.deep(c)
      this.setState({
        depUserData: menuList,
        depArr
      })
    }
  }

  // 数组转变
  deep = (node) => {
    let stack = node,
      data = []
    while (stack.length !== 0) {
      let pop = stack.pop()
      data.push({
        title: pop.title,
        parentId: pop.parentId,
        value: pop.value,
      })
      let children = pop.children
      if (children) {
        for (let i = children.length - 1; i >= 0; i--) {
          stack.push(children[i])
        }
      }
    }
    return data
  }


  // 提交
  submit = async () => {
    const { pdmc, pdckid, pdlxdmtj, pdrkrzhtj, pdsjtj, warehouseData, wpflData, depArr } = this.state

    if (pdmc === '') {
      Toast.info('请输入盘点名称！')
      return false
    }

    let params = {
      pdmc,
      pdckid,
      pdlxdmtj,
      pdrkrzhtj,
      pdsjtj,
    }
    if (pdckid && pdckid.length > 0) {
      params.pdckmc = warehouseData.find(x => String(x.value) === pdckid.join(',')).label
      params.pdckid = pdckid.join(',')
    }

    if (pdlxdmtj) {
      params.pdlxtj = pdlxdmtj.map(item => {
        return wpflData.find(x => x.zdbh === item).zdmc
      })
      params.pdlxdmtj = pdlxdmtj.join(',')
      params.pdlxtj = params.pdlxtj.join(',')
    }

    if (pdrkrzhtj) {
      params.pdrkrmctj = pdrkrzhtj.map(item => {
        return depArr.find(x => x.value === item).title
      })
      params.pdrkrzhtj = pdrkrzhtj.join(',')
      params.pdrkrmctj = params.pdrkrmctj.join(',')
    }


    params.pdsjtj = pdsjtj ? moment(pdsjtj).format('YYYY-MM-DD') : ''

    const res = await addPdInfo(params)
    if (res.code === 20000) {
      Toast.info("提交成功！")
      setTimeout(() => {
        this.props.history.goBack()
      }, 2000);
    }
  }

  render() {
    const loop = data =>
      data.map(item => {
        if (item.children && item.children.length) {
          return (
            <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled} />;
      });
    return (
      <div className='subscription_add'>
        <div className='subscription_add_title'>
          基础信息
        </div>
        <div className='subscription_add_form'>
          <div className='subscription_add_input'>
            <InputItem placeholder="请输入" value={this.state.pdmc} onChange={(e) => {
              this.setState({
                pdmc: e
              })
            }}><span style={{ color: 'red' }}>* </span>盘点名称</InputItem>
          </div>

        </div>

        <div className='subscription_add_form'>
          <div className='subscription_add_picker'>
            <Picker
              data={this.state.warehouseData}
              value={this.state.pdckid}
              cols={1}
              onChange={(e) => {
                this.setState({
                  pdckid: e,
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: '#fff' }}>* </span>仓库</List.Item>
            </Picker>
          </div>

          <div className='subscription_add_select subscription_add_select_multiple'>
            <div>
              <span style={{ color: '#fff' }}>* </span>危化品类型
            </div>
            <Select mode="multiple" optionFilterProp="children" allowClear placeholder='请选择' value={this.state.pdlxdmtj} onChange={(e) => {
              console.log(e)
              this.setState({
                pdlxdmtj: e
              })
            }}>
              {
                (this.state.wpflData || []).map(item => {
                  return <Select.Option key={item.zdbh} value={item.zdbh}>{item.zdmc}</Select.Option>
                })
              }
            </Select>
          </div>
        </div>
        <div className='subscription_add_form'>
          <div className='subscription_add_tree subscription_add_tree_s'>
            <div>
              <span style={{ color: '#fff' }}>* </span>入库人
            </div>
            <TreeSelect
              multiple
              placeholder="请选择"
              showSearch
              treeNodeFilterProp='title'
              style={{ width: '100%' }}
              dropdownStyle={{ paddingRight: 15, maxHeight: 400, overflow: 'auto' }}
              value={this.state.pdrkrzhtj}
              onChange={(e) => {
                this.setState({
                  pdrkrzhtj: e,
                })
              }}
            >
              {loop(this.state.depUserData)}
            </TreeSelect>
          </div>

          <div className='subscription_add_picker subscription_add_picker_date'>
            <DatePicker
              mode="date"
              title="选择时间"
              extra="请选择"
              value={this.state.pdsjtj}
              onChange={date => this.setState({ pdsjtj: date })}
            >
              <List.Item arrow="horizontal">入库时间</List.Item>
            </DatePicker>
          </div>
        </div>


        <div className='subscription_list_but'>
          <div onClick={() => {
            this.submit()
          }}>提交盘点</div>
        </div>

      </div >
    )
  }
}

export default Add
