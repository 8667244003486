import React, { Component } from 'react'
import { Upload } from 'antd'
import { List, Picker, DatePicker, Toast, InputItem } from 'antd-mobile'
import { saveResourcesInfo } from '../../../api/api'
import moment from 'moment'
import cookie from 'react-cookies'
import IP from '../../../config/config'

class ResourceWj extends Component {

  state = {
    xn: '', // 学年
    xqData: [
      { value: '1', label: '秋季学期' },
      { value: '2', label: '春季学期' },
      { value: '3', label: '夏季学期' },
    ],
    xqId: [], // 学期
    kcmc: '', // 课程
    fileAddr: {}, // 上传资源
  }

  handleSubmit = async () => {
    const { xn, xqId, fileAddr, kcmc } = this.state
    if (!xn) return Toast.info('请选择学年！')
    if (!xqId.length) return Toast.info('请选择学期！')
    if (!kcmc) return Toast.info('请输入课程名称！')
    if (!fileAddr.fileurl) return Toast.info('请上传资源文件！')
    const req = {
      record_xn: xn,
      record_xq: xqId[0],
      record_kcmc: kcmc,
      resources_file: fileAddr.fileurl,
      resources_name: fileAddr.filename,
      resources_size: fileAddr.filesize,
    }
    const res = await saveResourcesInfo(req)
    if (res.code === 20000) {
      Toast.info(res.message)
      setTimeout(() => {
        this.props.history.go(-1)
      }, 1500);
    }
  }

  render() {
    const that = this
    const { xn, xqData, xqId, kcmc, fileAddr } = this.state
    const props = {
      name: 'file',
      action: IP.host + '/report/resourcesrecord/uploadResourcesFile',
      headers: { 'Authorization': 'sastc ' + cookie.load('token') },
      showUploadList: false,
      onChange(info) {
        if (info.file.status !== 'uploading') {
          Toast.info('上传中', 0)
        }
        if (info.file.status === 'done') {
          Toast.info(info.file.response.message)
          that.setState({
            fileAddr: info.file.response.data
          })
        } else if (info.file.status === 'error') {
          Toast.fail(`${info.file.name} 文件上传失败.`);
        }
      }
    };
    return (
      <div className='testMb'>
        <div className='testMb_list'>
          <DatePicker
            mode="year"
            extra={xn ? xn + '年' : '学年'}
            onChange={date => this.setState({ xn: moment(date).format('YYYY') })}
          >
            <List.Item arrow="horizontal">学年</List.Item>
          </DatePicker>
        </div>
        <div className='testMb_list'>
          <Picker
            extra="学期"
            data={xqData}
            cols={1}
            value={xqId}
            onChange={e => this.setState({ xqId: e })}
          >
            <List.Item arrow="horizontal">学期</List.Item>
          </Picker>
        </div>
        <div className='testMb_list'>
          <InputItem value={kcmc} placeholder="请输入课程" onChange={e => this.setState({ kcmc: e })}>
            课程
          </InputItem>
        </div>
        <div className='testMb_upload'>
          <div className='testMb_upload_title'>上传文件</div>
          <Upload {...props}>
            <div className='testMb_upload_btn'>
              上传
            </div>
          </Upload>
        </div>
        {
          fileAddr.fileurl ? <div className='testMb_file'>
            {fileAddr.filename}
          </div> : ''
        }
        <div className='testMb_btn'>
          <div className='testMb_btn_list' onClick={() => this.handleSubmit()}>保存</div>
        </div>
      </div>
    )
  }
}

export default ResourceWj