import React, { Component } from 'react'
import { Modal, Toast } from 'antd-mobile'
import { Checkbox } from 'antd'
import { req_findTeacherInfo, req_findStudentInfo, findRlcj } from '../../../api/api'
import cookie from 'react-cookies'
import IP from '../../../config/config'
import './index.less'

class Gather extends Component {

  state = {
    data: {},
    visible: false,
    dealData: {}, // 人脸采集协议
    checked: false,
    flag: 1,
  }

  componentDidMount = () => {
    if (cookie.load('sf') == 1) {
      this.fetchTer()
    } else {
      this.fetchStu()
    }
    this.fetchDeal()
  }

  fetchDeal = async () => {
    const res = await findRlcj()
    if (res.code === 20000) {
      this.setState({
        dealData: res.data
      })
    }
  }

  // 查询教师信息
  fetchTer = async () => {
    const res = await req_findTeacherInfo({ zh: cookie.load('userData').id })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }

  // 查询学生信息
  fetchStu = async () => {
    const res = await req_findStudentInfo({ zh: cookie.load('userData').id })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }

  // 点击开始采集按钮
  handleStart = () => {
    const { checked, flag } = this.state
    if (checked && (flag === 2)) {
      this.props.history.push('/module/gather/qrImage')
    } else {
      if (checked) {
        this.setState({
          visible: true
        })
      } else {
        Toast.info('请勾选人脸采集协议并查看！')
      }
    }
  }

  handleOk = () => {
    this.setState({
      visible: false,
      flag: 2
    })
  }

  render () {
    const { data, visible, checked, dealData } = this.state
    return (
      <div className='approveDetail' style={{ padding: '10px 0 100px' }}>
        <div className='gather_title'>请确认实名信息</div>
        <div className='approveDetail_con'>
          <div className='approveDetail_detail'>
            <div className='approveDetail_detail_list'>
              <div className='approveDetail_detail_list_name'>{cookie.load('sf') == 1 ? '工号' : '学号'}</div>
              <div className='approveDetail_detail_list_con'>{cookie.load('sf') == 1 ? data.gh : data.xh}</div>
            </div>
            <div className='approveDetail_detail_list'>
              <div className='approveDetail_detail_list_name'>姓名</div>
              <div className='approveDetail_detail_list_con'>{data.xm}</div>
            </div>
            <div className='approveDetail_detail_list'>
              <div className='approveDetail_detail_list_name'>性别</div>
              <div className='approveDetail_detail_list_con'>{data.xb === 1 ? '男' : '女'}</div>
            </div>
            <div className='approveDetail_detail_list'>
              <div className='approveDetail_detail_list_name'>{cookie.load('sf') == 1 ? '所属单位' : '所属专业'}</div>
              <div className='approveDetail_detail_list_con'>{cookie.load('sf') == 1 ? data.dwmc : data.zymc}</div>
            </div>
            {
              cookie.load('sf') == 1 && <div className='approveDetail_detail_list'>
                <div className='approveDetail_detail_list_name'>职称</div>
                <div className='approveDetail_detail_list_con'>{data.zc}</div>
              </div>
            }
            {
              cookie.load('sf') == 1 && <div className='approveDetail_detail_list'>
                <div className='approveDetail_detail_list_name'>职务</div>
                <div className='approveDetail_detail_list_con'>{data.zhiwu}</div>
              </div>
            }
            {
              cookie.load('sf') == 2 && <div className='approveDetail_detail_list'>
                <div className='approveDetail_detail_list_name'>年制</div>
                <div className='approveDetail_detail_list_con'>{data.xz}年制</div>
              </div>
            }
          </div>
          {data.zp ? (<div className='gather_photo'>
            <img src={IP.fileHost + data.zp} alt="" />
          </div>) : ('')}

        </div>
        <div className='gather_footer'>
          <div className='gather_footer_btn' onClick={() => this.handleStart()}>开始采集</div>
          <div className='gather_footer_info'><Checkbox checked={checked} onChange={e => this.setState({ checked: e.target.checked })}>查看</Checkbox><a onClick={() => this.setState({ visible: true })}>《人脸照片采集协议》</a></div>
        </div>
        <Modal
          popup
          visible={visible}
          title="人脸照片采集协议"
          onClose={() => this.setState({ visible: false })}
          animationType="slide-up"
          className='gather_modal'
        >
          <div className='gather_modal_con'>
            <div className='gather_modal_one'>欢迎使用人脸照片采集应用！</div>
            <div className='gather_modal_one'>为了更好的保护您的个人隐私，请认真阅读<a>《人脸照片采集协议》</a>的全部内容</div>
            <div className='info_modal_desc' dangerouslySetInnerHTML={{ __html: dealData.agreement_content }}></div>
            <div className='info_modal_btn' onClick={() => this.handleOk()}>已阅</div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default Gather