import React, { Component } from 'react'
import { DatePicker, List } from 'antd-mobile'
import { getSf, getXqinfo, statisticsFbqk, statisticsYhlxByXq, statisticsYhlxByTime, statisticsYhfjByXq, statisticsYhfjByTime, statisticsXjqkByXq, statisticsXjqkByTime } from '../../../api/patrol'
import ReactEcharts from 'echarts-for-react'
import moment from 'moment'
import downImg from '../../../img/sj.png'
import './statistics.less'
const color = ['#08CEDF', '#FFC34E', '#F76964', '#00C2FF', '#FFDB68', '#7B83EA']
class PatrolStatistics extends Component {

  state = {
    role: 0,
    campusOrBuilding: 2,
    weekCurrentData: [],

    campusOneValue: 1,
    campusList: [],
    startDateOneValue: '',
    endDateOneValue: '',
    xunJianStateData: {},

    campusTwoValue: 1,
    startDateTwoValue: '',
    endDateTwoValue: '',
    patrolRoomData: [
      { value: 80, name: '一般隐患' },
      { value: 120, name: '严重隐患' },
    ],
    campusThreeValue: 1,
    startDateThreeValue: '',
    endDateThreeValue: '',
    xunJianItemData: [],
  }
  componentDidMount = () => {
    console.log(this.props.match.params.id)
    document.title = "巡检统计"
    const start = new Date(this.fun_date(-180))
    const end = new Date()
    this.setState({
      role: this.props.match.params.id,
      startDateOneValue: start,
      endDateOneValue: end,
      startDateTwoValue: start,
      endDateTwoValue: end,
      startDateThreeValue: start,
      endDateThreeValue: end,
    }, async () => {

      this._statisticsFbqk()
      //普通人员请求的接口
      if (this.state.role - 0) {
        this._getXqinfo(this.state.role)
      } else {
        //管理员请求的接口
        this._statisticsXjqkByTime()
        this._statisticsYhfjByTime()
        this._statisticsYhlxByTime()
      }

    })
  }
  getCampusORBuilding = async (val) => {
    this.setState({ campusOrBuilding: val }, () => {
      this._statisticsFbqk()
    })
  }
  _getXqinfo = async (role) => {
    const res = await getXqinfo()
    if (res.code === 20000) {
      const default_wz_id = res.data && res.data[0].wz_id
      console.log(default_wz_id)
      this.setState({
        campusList: res.data,
        campusOneValue: default_wz_id,
        campusTwoValue: default_wz_id,
        campusThreeValue: default_wz_id,
      })
      this._statisticsXjqkByXq(default_wz_id)
      this._statisticsYhfjByXq(default_wz_id)
      this._statisticsYhlxByXq(default_wz_id)
    }
  }
  _statisticsFbqk = async () => {
    const { campusOrBuilding } = this.state
    const res = await statisticsFbqk({ flag: campusOrBuilding })
    if (res.code === 20000) {
      this.setState({
        weekCurrentData: res.data.map(item => {
          item.name = item.spaceName + '' + item.num
          item.value = item.num
          return item
        })
      })
    }

  }
  _statisticsXjqkByXq = async (value) => {
    const res = await statisticsXjqkByXq({ xqid: value })
    if (res.code === 20000) {
      console.log(res.data)
      this.setState({
        xunJianStateData: res.data
      })
    }
  }
  _statisticsYhfjByXq = async (value) => {
    const res = await statisticsYhfjByXq({ xqid: value })
    if (res.code === 20000) {
      this.setState({
        patrolRoomData: res.data
      })
    }
  }
  _statisticsYhlxByXq = async (value) => {
    const res = await statisticsYhlxByXq({ xqid: value })
    if (res.code === 20000) {
      this.setState({
        xunJianItemData: res.data.map(p => {
          return {
            name: p.type_name,
            value: p.num || 0
          }
        })
      })
    }
  }
  _statisticsXjqkByTime = async () => {
    const res = await statisticsXjqkByTime({ startTime: moment(this.state.startDateOneValue).format('YYYY-MM-DD'), endTime: moment(this.state.endDateOneValue).format('YYYY-MM-DD') })
    if (res.code === 20000) {
      console.log(res.data)
      this.setState({
        xunJianStateData: res.data
      })
    }
  }
  _statisticsYhfjByTime = async () => {
    const res = await statisticsYhfjByTime({ startTime: moment(this.state.startDateTwoValue).format('YYYY-MM-DD'), endTime: moment(this.state.endDateTwoValue).format('YYYY-MM-DD') })
    if (res.code === 20000) {
      this.setState({
        patrolRoomData: res.data
      })
    }
  }
  _statisticsYhlxByTime = async () => {
    const res = await statisticsYhlxByTime({ startTime: moment(this.state.startDateThreeValue).format('YYYY-MM-DD'), endTime: moment(this.state.endDateThreeValue).format('YYYY-MM-DD') })
    if (res.code === 20000) {
      this.setState({
        xunJianItemData: res.data.map(p => {
          return {
            name: p.type_name,
            value: p.num || 0
          }
        })
      })
    }
  }
  //巡检情况统计校区切换
  changeCampusOne = (item) => {
    this.setState({
      campusOneValue: item.wz_id
    })
    this._statisticsXjqkByXq(item.wz_id)
  }
  //巡检情况统计校区切换
  changeCampusTwo = (item) => {
    this.setState({
      campusTwoValue: item.wz_id
    })
    this._statisticsYhfjByXq(item.wz_id)
  }
  //隐患类型统计校区切换
  changeCampusThree = (item) => {
    this.setState({
      campusThreeValue: item.wz_id
    })
    this._statisticsYhlxByXq(item.wz_id)
  }

  // 获取几天前的日期
  fun_date = (num) => {
    var date1 = new Date()
    var date2 = new Date(date1)
    date2.setDate(date1.getDate() + num)
    //num是正数表示之后的时间，num负数表示之前的时间，0表示今天
    var time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate()
    return time2
  }

  render () {
    const { role, campusOrBuilding, weekCurrentData, campusList,
      campusOneValue, startDateOneValue, endDateOneValue, xunJianStateData,
      campusTwoValue, startDateTwoValue, endDateTwoValue, patrolRoomData,
      campusThreeValue, startDateThreeValue, endDateThreeValue, xunJianItemData, } = this.state
    const CustomChildren = ({ extra, onClick, children }) => (
      <div
        onClick={onClick}
        style={{ display: 'flex', alignItems: 'center', background: '#F5F5F5', padding: '4px 8px', borderRadius: '4px' }}
      >
        {children}
        <span style={{}}>{extra}</span>
        <img src={downImg} alt="" />
      </div>
    )

    const option1 = {
      title: {
        text: weekCurrentData.reduce((prev, cur) => { return prev + cur.value }, 0),    // 主标题
        top: '39%',
        left: '29%',
        textStyle: {
          textAlign: 'center',
          color: '#292C33',
          fontSize: 20,
        },
        itemGap: 4,   // 主副标题间隔
        subtext: '总数(间)',   // 副标题
        subtextStyle: {
          textAlign: 'center',
          color: '#666666',
          fontSize: 12,

        },
        textAlign: 'center',
      },
      color: color,
      legend: {
        //纵向
        orient: 'vertical',
        x: '60%', //可设定图例在左、右、居中
        y: 'center', //可设定图例在上、下、居中
        icon: "circle",   //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 8, // 设置间距
        selectedMode: false, // 禁止点击
        textStyle: {
          color: '#666',
          fontSize: 12,
        }
      },
      series: [{
        name: '弱电间数量',
        type: 'pie',
        radius: ['60%', '80%'],
        center: ["30%", "50%"],
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        hoverAnimation: false, // 取消放大
        label: { // 饼图图形上的文本标签
          normal: {
            show: false,
            textStyle: {
              fontWeight: 700,
              fontSize: 13 // 文字的字体大小
            },
            formatter: '{b}',
          }
        },
        data: weekCurrentData
      }]
    }
    const option2 = {
      color: color,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          itemStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        bottom: 30,
        top: 30
      },
      xAxis: [{
        type: 'category',
        data: ['待巡检', '超时', '已巡'],
        axisLabel: {
          textStyle: {
            color: '#666666', // x轴字体颜色
            fontSize: 12,
          }
        },
        axisTick: {
          show: false
        },
        axisPointer: {
          type: 'shadow'
        },
        axisLine: {
          lineStyle: {
            color: '#DDDDDD',// X轴线的颜色
            width: 1 // x轴线的宽度
          }
        },
      }],
      yAxis: {
        type: 'value',
        name: '',
        axisLabel: {
          formatter: '{value}',
          textStyle: {  // 图例文字的样式
            color: '#919499',
            fontSize: 12
          },
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {    //网格线
          lineStyle: {
            type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
          },
          show: false //隐藏或显示
        },
      },
      series: [
        {
          name: '条数',
          type: 'bar',
          barWidth: 20, // 设置柱子粗细
          itemStyle: {
            normal: {
              //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
              barBorderRadius: [2, 2, 0, 0],
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                formatter: '{c}',
                textStyle: { //数值样式
                  color: '#666666',
                  fontSize: 12
                }
              }
            }
          },
          data: [{
            value: xunJianStateData.dxNum,
            itemStyle: {
              color: color[0]
            }
          }, {
            value: xunJianStateData.csNum,
            itemStyle: {
              color: color[1]
            }
          }, {
            value: xunJianStateData.yxNum,
            itemStyle: {
              color: color[2]
            }
          }
          ]
          // data: [xunJianStateData.dxNum, xunJianStateData.csNum, xunJianStateData.yxNum],
          // {
          //   value: 200,
          //   itemStyle: {
          //     color: '#a90000'
          //   }
          // },
        },
      ]
    }
    const option3 = {
      title: {
        text: (patrolRoomData.ybNum || 0) + (patrolRoomData.yzNum || 0),    // 主标题
        top: '39%',
        left: '49%',
        textStyle: {
          textAlign: 'center',
          color: '#292C33',
          fontSize: 20,
        },
        itemGap: 4,   // 主副标题间隔
        subtext: '总数(间)',   // 副标题
        subtextStyle: {
          textAlign: 'center',
          color: '#666666',
          fontSize: 12,

        },
        textAlign: 'center',
      },
      color: color,
      legend: {
        //纵向
        // orient: 'horizontal',
        x: 'center', //可设定图例在左、右、居中
        y: 'bottom', //可设定图例在上、下、居中
        icon: "circle",   //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 10, // 设置间距
        selectedMode: false, // 禁止点击
        textStyle: {
          color: '#666',
          fontSize: 12,
        }
      },
      series: [{
        name: '隐患房间数量',
        type: 'pie',
        radius: ['50%', '70%'],
        center: ["50%", "50%"],
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        hoverAnimation: false, // 取消放大
        labelLine: {
          length: 10
        },
        width: '100%',
        label: {
          alignTo: 'edge',
          margin: 0,
          edgeDistance: 0,
          // 饼图图形上的文本标签
          formatter: '{name|{b}} {num|{c} 间}\n {zhanbi|占比} {per|{d} %}',
          rich: {
            name: {
              fontSize: 12,
              color: '#999',
              padding: [6, 0],
            },
            zhanbi: {
              fontSize: 12,
              color: '#999',
              padding: [6, 0],
            },
            num: {
              fontSize: 12,
              fontWeight: 'bold',
              color: '#666',
            },
            per: {
              fontSize: 12,
              fontWeight: 'bold',
              color: '#292C33',
            },
          }
        },
        data: [
          { value: patrolRoomData.ybNum || 0, name: '一般隐患' },
          { value: patrolRoomData.yzNum || 0, name: '严重隐患' },
        ]
      }]
    }
    const option4 = {
      color: color,
      legend: {
        //纵向
        // orient: 'horizontal',
        x: 'center', //可设定图例在左、右、居中
        y: 'bottom', //可设定图例在上、下、居中
        icon: "circle",   //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 10, // 设置间距
        selectedMode: false, // 禁止点击
        textStyle: {
          color: '#666',
          fontSize: 12,
        }
      },
      series: [{
        name: '隐患类型占比',
        type: 'pie',
        radius: [0, '70%'],
        center: ["50%", "50%"],
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        hoverAnimation: false, // 取消放大
        labelLine: {
          length: 10
        },
        label: {
          // 饼图图形上的文本标签
          formatter: '{name|{b}} {num|{c} 间}\n {zhanbi|占比} {per|{d} %}',
          rich: {
            name: {
              fontSize: 12,
              color: '#999',
              padding: [6, 0],
            },
            zhanbi: {
              fontSize: 12,
              color: '#999',
              padding: [6, 0],
            },
            num: {
              fontSize: 12,
              fontWeight: 'bold',
              color: '#666'
            },
            per: {
              fontSize: 12,
              fontWeight: 'bold',
              color: '#292C33'
            },
          }
        },
        data: xunJianItemData
      }]
    }

    return (
      <div className='statistics patrol_stat'>
        <div className="ev_stat">
          <div className="stat_title">
            <div className="left">弱电间分布统计</div>
            <div className='right stat_card_radio'>
              <div className={campusOrBuilding === 2 ? 'stat_card_radio_checked' : ''} onClick={() => this.getCampusORBuilding(2)}>校区</div>
              <div className={campusOrBuilding === 1 ? 'stat_card_radio_checked' : ''} onClick={() => this.getCampusORBuilding(1)}>楼宇</div>
            </div>
          </div>
          <ReactEcharts option={option1} style={{ height: 170 }} />
        </div>
        <div className="ev_stat">
          <div className="stat_title">
            <div className="left">巡检情况统计</div>
            <div className='right stat_card_radio'>
              {
                campusList.map((campus, k) => {
                  return (<div
                    key={k}
                    className={campusOneValue === campus.wz_id ? 'stat_card_radio_checked' : ''}
                    onClick={() => this.changeCampusOne(campus)}>
                    {campus.wz_name}
                  </div>)
                })
              }
            </div>
          </div>
          {
            !(role - 0) ? <div className='datePickerStyl'>
              <DatePicker
                mode="date"
                title="开始日期"
                extra="开始日期"
                value={startDateOneValue}
                maxDate={endDateOneValue ? endDateOneValue : null}
                onChange={date => {
                  this.setState({
                    startDateOneValue: date
                  }, () => {
                    this._statisticsXjqkByTime()
                  })
                }}
              >
                <CustomChildren></CustomChildren>
              </DatePicker>
              <div className='mg-8'>至</div>
              <DatePicker
                mode="date"
                title="结束日期"
                extra="结束日期"
                value={endDateOneValue}
                minDate={startDateOneValue ? startDateOneValue : null}
                onChange={date => {
                  this.setState({
                    endDateOneValue: date
                  }, () => {
                    this._statisticsXjqkByTime()
                  })
                }}
              >
                <CustomChildren></CustomChildren>
              </DatePicker>
            </div> : null
          }

          <ReactEcharts option={option2} style={{ height: 170 }} />
          <div className='legend'>
            <i className='blue'></i>
            <span>待巡检</span>
            <i className='yellow'></i>
            <span>超时</span>
            <i className='red'></i>
            <span>已巡检</span>
          </div>
        </div>
        <div className="ev_stat">
          <div className="stat_title">
            <div className="left">隐患房间统计</div>
            <div className="right">
              <div className='stat_card_radio'>
                {
                  campusList.map((campus, k) => {
                    return (<div
                      key={k}
                      className={campusTwoValue === campus.wz_id ? 'stat_card_radio_checked' : ''}
                      onClick={() => this.changeCampusTwo(campus)}>
                      {campus.wz_name}
                    </div>)
                  })
                }
              </div>
              <div className='export'>导出</div>
            </div>
          </div>
          {!(role - 0) ? <div className='datePickerStyl'>
            <DatePicker
              mode="date"
              title="开始日期"
              extra="开始日期"
              value={startDateTwoValue}
              maxDate={endDateTwoValue ? endDateTwoValue : null}
              onChange={date => {
                this.setState({
                  startDateTwoValue: date
                }, () => {
                  this._statisticsYhfjByTime()

                })
              }}
            >
              <CustomChildren></CustomChildren>
            </DatePicker>
            <div className='mg-8'>至</div>
            <DatePicker
              mode="date"
              title="结束日期"
              extra="结束日期"
              value={endDateTwoValue}
              minDate={startDateTwoValue ? startDateTwoValue : null}
              onChange={date => {
                this.setState({
                  endDateTwoValue: date
                }, () => {
                  this._statisticsYhfjByTime()
                })
              }}
            >
              <CustomChildren></CustomChildren>
            </DatePicker>
          </div> : null}

          <ReactEcharts option={option3} style={{ height: 190, width: '100%' }} />
        </div>
        <div className="ev_stat">
          <div className="stat_title">
            <div className="left">隐患类型占比</div>
            <div className='right stat_card_radio'>
              {
                campusList.map((campus, k) => {
                  return (<div
                    key={k}
                    className={campusThreeValue === campus.wz_id ? 'stat_card_radio_checked' : ''}
                    onClick={() => this.changeCampusThree(campus)}>
                    {campus.wz_name}
                  </div>)
                })
              }
            </div>
          </div>
          {!(role - 0) ? <div className='datePickerStyl'>
            <DatePicker
              mode="date"
              title="开始日期"
              extra="开始日期"
              value={startDateThreeValue}
              maxDate={endDateThreeValue ? endDateThreeValue : null}
              onChange={date => {
                this.setState({
                  startDateThreeValue: date
                }, () => {
                  this._statisticsYhlxByTime()
                })
              }}
            >
              <CustomChildren></CustomChildren>
            </DatePicker>
            <div className='mg-8'>至</div>
            <DatePicker
              mode="date"
              title="结束日期"
              extra="结束日期"
              value={endDateThreeValue}
              minDate={startDateThreeValue ? startDateThreeValue : null}
              onChange={date => {
                this.setState({
                  endDateThreeValue: date
                }, () => {
                  this._statisticsYhlxByTime()
                })
              }}
            >
              <CustomChildren></CustomChildren>
            </DatePicker>
          </div> : null}
          <ReactEcharts option={option4} style={{ height: 190 }} />
        </div>
      </div >
    )
  }
}
export default PatrolStatistics