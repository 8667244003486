import React, { Component } from 'react'
import { SearchBar, Pagination, Icon, } from 'antd-mobile'
import { findRegisterRecord } from './../../../../api/fqw'
import moment from 'moment'

class List extends Component {
  state = {
    paramKey: '',
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    total: 0,
    data: [],
  }

  componentDidMount = () => {
    document.title = '登记列表'
    window.scrollTo(0, 0)
    this.fetch()
  }

  fetch = async () => {
    const { pageSize, pageNum, paramKey } = this.state
    const res = await findRegisterRecord({ size: pageSize, page: pageNum, paramkey: paramKey })
    if (res.code === 20000) {
      this.setState({
        data: res.data.list,
        pages: res.data ? (res.data.pages ? res.data.pages : 1) : 1,
        total: res.data.total,
      })
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className='subscription_list'>
        <div className='subscription_list_head'>
          <SearchBar placeholder="请输入登记单号" value={this.state.paramKey} onCancel={(e) => {
            this.setState({
              paramKey: ''
            }, () => {
              this.fetch()
            })
          }} onChange={e => {
            this.setState({
              paramKey: e
            }, () => {
              this.fetch()
            })
          }} />
        </div>
        <div className='subscription_list_record'>
          {data.map((item, key) => {
            return (
              <div className='subscription_list_item' key={key} onClick={() => {
                this.props.history.push(`/module/registration/record/${item.register_code}`)
              }}>
                <div className='subscription_list_item_head'>
                  <div>登记单号：{item.register_code}</div>
                  {item.register_state === 0 ? (<div className='wait'>草稿</div>) : ''}
                  {item.register_state === 1 ? (<div className='wait'>待审批</div>) : ''}
                  {item.register_state === 2 ? (<div className='wait'>审批中</div>) : ''}
                  {item.register_state === 3 ? (<div className='reject'>审批驳回</div>) : ''}
                  {item.register_state === 4 ? (<div className='pass_through'>待处理</div>) : ''}
                  {item.register_state === 5 ? (<div className='pass_through'>已处理</div>) : ''}
                  {item.register_state === 6 ? (<div className='reject'>取消处理</div>) : ''}
                </div>
                <div className='subscription_list_title'>
                  {item.ssxy_mc}-{item.sszx_mc}-{item.room_name}
                </div>
                <div className='subscription_list_tag'>
                  <div>{item.principal_name}</div>
                  <div>废弃物{item.waste_num}种</div>
                </div>
                <div className='subscription_list_foot'>
                  <div>
                    {item.create_time ? moment(item.create_time).format('YYYY-MM-DD') : ''}
                  </div>
                </div>
              </div>
            )
          })}

          {this.state.data.length === 0 ? <div className='not-yet'>--暂无数据--</div> : ''}

          {
            this.state.total > 20 ? (<div className='subscription_manager_page'>
              <Pagination total={this.state.pages}
                current={this.state.pageNum}
                onChange={e => {
                  this.setState({
                    pageNum: e
                  }, () => {
                    window.scrollTo(0, 0)
                    this.fetch()
                  })
                }}
                locale={{
                  prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                  nextText: (<span className="arrow-align"><Icon type="right" /></span>),
                }}
              />
            </div>) : ''
          }
        </div>
        <div className='subscription_list_but'>
          <div onClick={() => {
            this.props.history.push('/module/registration/add')
          }}>废弃物登记</div>
        </div>
      </div>
    )
  }
}

export default List