import React, { Component } from 'react'
import { SearchBar, Toast } from 'antd-mobile'
import { Cascader, Field } from 'react-vant'
import { findSpaceInfo, findAllBrand, kgCardBrand } from '../../../api/api'
import './index.less'

class Banpai extends Component {

  state = {
    searchContent: '', // 搜索值
    buildId: undefined, // 楼宇id
    floorId: undefined, // 楼层id
    roomId: undefined, // 房间id
    banpaiData: [], // 空调设备
    roomData: [], // 位置数据
    wt: 265,
  }

  componentDidMount = () => {
    document.title = "智慧班牌"
    this.fetch()
    this.fetchRoom()
    this.setState({
      wt: document.body.clientWidth - 110
    })
  }

  fetch = async () => {
    const { searchContent, buildId, floorId, roomId } = this.state
    const res = await findAllBrand({ spaceId: roomId ? roomId : floorId ? floorId : buildId ? buildId : '', roomName: searchContent })
    if (res.code === 20000) {
      this.setState({
        banpaiData: res.data
      })
    }
  }

  // 查询位置
  fetchRoom = async () => {
    const res = await findSpaceInfo()
    if (res.code === 20000) {
      let menuList = res.data.map(item => {
        return {
          text: item.dwmc,
          parentId: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
        }
      })
      menuList = menuList.map(item => {
        if (menuList.filter(p => p.parentId === item.value).length) {
          item.children = menuList.filter(p => p.parentId === item.value)
        }
        return item
      }).filter(p => p.parentId === '0')
      this.setState({
        roomData: menuList
      })
    }
  }

  // 搜索房间
  handleChange = e => {
    this.setState({
      searchContent: e
    }, () => {
      this.fetch()
    })
  }

  handleCancel = () => {
    this.setState({
      searchContent: ''
    }, () => {
      this.fetch()
    })
  }

  // 执行开关机
  handleChangeSwitch = async (e, record) => {
    e.stopPropagation()
    const { banpaiData } = this.state
    const res = await kgCardBrand({ cardNumbers: record.card_number, state: record.boot_state })
    if (res.code === 20000) {
      if (res.data && res.data.length) {
        Toast.info(res.data[0])
      } else {
        this.setState({
          banpaiData: banpaiData.map(x => {
            if (x.card_number === record.card_number) {
              x.boot_state = record.boot_state === 1 ? 0 : 1
            }
            return x
          })
        })
      }
    } else if (res.code === 20001) {
      Toast.info(res.message)
    } else {
      Toast.hide()
    }
  }

  // 开关机配置
  handleTimingConfig = (e, item) => {
    e.stopPropagation()
    this.props.history.push(`/module/banpai/openConfig/${item.device_room_id}`)
  }

  // 选中项变化时触发
  handleCascaderChange = (val, selectedRows, actions) => {
    // console.log(val, selectedRows, actions, 'xxxxxx')
  }

  // 全部选项选择完成
  handleCascaderFinish = (val, selectedRows, actions) => {
    this.setState({
      roomId: val[val.length - 1],
    }, () => {
      this.fetch()
    })
  }

  render () {
    const { roomData, banpaiData, wt } = this.state
    return (
      <div className='banpai'>
        <div className='banpai_img'>
          <img alt='' src={require('../../../images/bp.png')} />
          <div className='banpai_search'>
            <SearchBar placeholder="请输入房间信息" onCancel={() => this.handleCancel()} onChange={e => this.handleChange(e)} />
          </div>
        </div>
        <div className='banpai_title'>
          <span>设备列表</span>
          <div className='banpai_cascader'>
            <Cascader
              popup={{ round: true }}
              title='选择实验室'
              activeColor="#00B4C4"
              // value={value}
              // onChange={this.handleCascaderChange}
              options={roomData}
              onFinish={this.handleCascaderFinish}
            >
              {(_, selectedRows, actions) => (
                <Field
                  isLink
                  // value={selectedRows.map(el => el.text).join(',')}
                  value={selectedRows.length > 0 ? selectedRows[selectedRows.length - 1].text : ''}
                  readOnly
                  className='door_lock_cascader'
                  placeholder='全部房间'
                  onClick={() => actions.open()}
                />
              )}
            </Cascader>
          </div>
        </div>
        <div className='banpai_con'>
          {
            (banpaiData || []).length ? banpaiData.map(item => {
              return <div className='banpai_con_list' key={item.card_number} onClick={() => this.props.history.push(`/module/banpai/detail/${item.card_number}`)}>
                <div className='banpai_con_list_left' style={{ width: `${wt}px` }}>
                  <div className='banpai_con_list_left_name'>{item.card_name}</div>
                  <div className='banpai_con_list_left_addr'>
                    {item.device_building_name}/{item.device_floor_name}/{item.device_room_name}
                  </div>
                  <div className='banpai_con_list_left_desc'>
                    <div className={`banpai_con_list_left_desc_status ${item.net_state === 1 ? 'banpai_status_online' : 'banpai_status_offline'}`}>
                      <span></span>
                      <span>{item.net_state === 1 ? '设备在线' : '设备离线'}</span>
                    </div>
                    {
                      item.boot_state === 1 ? <div className='banpai_con_list_left_desc_con'>已开启</div> : ''
                    }
                  </div>
                </div>
                <div className='banpai_con_img' onClick={e => this.handleChangeSwitch(e, item)}>
                  {
                    item.boot_state === 1 ? <img alt='' src={require('../../../images/kai.png')} /> : <img alt='' src={require('../../../images/guan.png')} />
                  }
                </div>
              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
      </div>
    )
  }
}

export default Banpai