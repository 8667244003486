import React, { Component } from 'react';
import { List, InputItem } from 'antd-mobile'
import { findMyInfo } from '../../api/api'

class MyInfo extends Component {

  state = {
    userInfo: {},
  }

  componentDidMount = () => {
    window.document.title = '个人信息'
    this.fetch()
  }

  // 查询个人信息
  fetch = async () => {
    const res = await findMyInfo()
    if (res.code === 20000) {
      this.setState({
        userInfo: res.data || {},
      })
    }
  }

  render() {
    const { userInfo } = this.state
    return (
      <div className='myInfo'>
        <List>
          <InputItem value={userInfo.zh}>{userInfo.sf === 2 ? '学号' : '工号'}</InputItem>
          <InputItem value={userInfo.xm}>姓名</InputItem>
          <InputItem value={userInfo.xb === 1 ? '男' : '女'}>性别</InputItem>
          <InputItem value={userInfo.sf === 2 ? userInfo.yx : userInfo.dwmc}>{userInfo.sf === 2 ? '院系' : '单位'}</InputItem>
          <InputItem value={userInfo.lxfs}>联系方式</InputItem>
          {
            userInfo.sf === 2 ? <InputItem value={userInfo.zy}>专业</InputItem> : ''
          }
          {
            userInfo.sf === 2 ? <InputItem value={userInfo.bj}>班级</InputItem> : ''
          }
        </List>
      </div>
    );
  }
}

export default MyInfo;