import React, { Component } from 'react';
import { List, ImagePicker, WhiteSpace, TextareaItem, Button, Steps, Toast, Modal, Radio } from 'antd-mobile';
import { createForm } from 'rc-form';
import moment from 'moment'
import './../commen.less'
import { findOrderByOrderId, findWorkUser, appointOrder } from '../../../../api/repairApi'
import IP from '../../../../config/config'
import './index.less'
const Item = List.Item;
const Step = Steps.Step;
const RadioItem = Radio.RadioItem;

class Details extends Component {
  state = {
    data: {},
    modal1: false,
    reason: '',
    workUser: [],
    value: '',
    name: '',
    id: '',
    visible: false,
    imgUrl: '',
    repairInfo: {}, // 报修详情
    processInfo: [], // 工单流程
  }

  componentDidMount() {
    window.document.title = '维修工单'
    this.setState({
      id: this.props.match.params.id
    })
    findOrderByOrderId({ orderId: this.props.match.params.id }).then(res => {
      if (res.code === 20000) {
        this.setState({
          data: res.data ? res.data : {},
          repairInfo: res.data ? (res.data.repairOrderEntity || {}) : {},
          processInfo: res.data ? (res.data.approvalRecordEntities || []) : [],
        })
      }
    })
    this.findWorkUser()
  }
  findWorkUser = () => {
    findWorkUser().then(res => {
      if (res.code === 20000) {
        this.setState({
          workUser: res.data ? res.data : []
        })
      }
    })
  }
  onChange = (value, name) => {
    this.setState({
      value,
      name
    });
  };

  handleImageClick = (index, files) => {
    this.setState({
      visible: true,
      imgUrl: files.find(x => x.id === index).url
    })
  }
  render() {
    const { data, workUser, value, id, name, visible, imgUrl, repairInfo, processInfo } = this.state
    return (
      <div className="details">
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.user_name}>用户姓名</Item>
        </List>
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.user_phone_number}>联系方式</Item>
        </List>
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={(data.repairOrderEntity && data.repairOrderEntity.building_name ? data.repairOrderEntity.building_name : '') + ' ' + (data.repairOrderEntity && data.repairOrderEntity.room_name ? data.repairOrderEntity.room_name : '')}>维修地点</Item>
        </List>
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.fault_type_name}>故障类型</Item>
        </List>
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.equipment_name ? data.repairOrderEntity.equipment_name : ''}>设备名称</Item>
        </List>
        {/* <WhiteSpace size="sm" /><WhiteSpace size="sm" />
                    <List  className="my-list">
                        <Item extra={data.repairOrderEntity&&data.repairOrderEntity.equipment_product?data.repairOrderEntity.equipment_product:''}>设备厂家</Item>
                    </List>
                    <WhiteSpace size="sm" /><WhiteSpace size="sm" />
                    <List  className="my-list">
                        <Item extra={data.repairOrderEntity&&data.repairOrderEntity.equipment_type?data.repairOrderEntity.equipment_type:''}>设备类型</Item>
                    </List> */}
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.equipment_number ? data.repairOrderEntity.equipment_number : ''}>设备编号</Item>
        </List>
        {/* <WhiteSpace size="sm" /><WhiteSpace size="sm" />
                    <List  className="my-list">
                        <Item extra={data.repairOrderEntity&&data.repairOrderEntity.equipment_model?data.repairOrderEntity.equipment_model:''}>设备型号</Item>
                    </List>
                    <WhiteSpace size="sm" /><WhiteSpace size="sm" />
                    <List  className="my-list">
                        <Item extra={data.repairOrderEntity&&data.repairOrderEntity.equipment_brand?data.repairOrderEntity.equipment_brand:''}>设备品牌</Item>
                    </List> */}
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.money !== 0 ? data.repairOrderEntity.money : ''}>维修费用</Item>
        </List>
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.work_desc !== null ? data.repairOrderEntity.work_desc : ''}>维修内容</Item>
        </List>
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <div className="textarea">
          <div className="textarea_title">
            问题描述
          </div>
          <div>
            <TextareaItem
              value={data.repairOrderEntity && data.repairOrderEntity.description ? data.repairOrderEntity.description : ''}
              autoHeight
              labelNumber={5}
              disabled
            />
          </div>
          <div>
            <ImagePicker
              className="details-upload"
              disableDelete={true}
              selectable={false}
              onImageClick={this.handleImageClick}
              files={data.repairOrderEntity && data.repairOrderEntity.pic_url ? (data.repairOrderEntity && data.repairOrderEntity.pic_url.split(',').map((item, index) => { return { url: IP.fileHost + item, id: index } })) : []}
            />
          </div>
        </div>
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <div className="textarea">
          <div className="textarea_title">
            维修后图片
          </div>
          <ImagePicker
            className="details-upload"
            disableDelete={true}
            selectable={false}
            onImageClick={this.handleImageClick}
            files={data.repairOrderEntity && data.repairOrderEntity.repair_pic_url ? (data.repairOrderEntity && data.repairOrderEntity.repair_pic_url.split(',').map((item, index) => { return { url: IP.fileHost + item, id: index } })) : []}
          />
        </div>
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <div className='repair_approval_detail_desc'>
          <div className='repair_approval_detail_desc_title'>工单流程</div>
          <div className='repair_approval_process'>
            {
              (processInfo || []).length ? <Steps size="small" current={processInfo.length}>
                {
                  processInfo.map((item, index) => <Step
                    key={index}
                    icon={
                      item.state === 2 ? <img alt='' src={require('../../../../images/new/25.png')} /> : <img alt='' src={require('../../../../images/new/24.png')} />
                    }
                    title={
                      <div className='step_title'>
                        <span>审批人：{item.approval_name}</span>
                        {
                          item.approval_state === 1 ? <span className='step_title_tg'>通过</span> : (
                            item.approval_state === 0 ? <span className='step_title_jj'>拒绝</span> : ''
                          )
                        }
                      </div>
                    }
                    description={
                      <div className='step_desc'>
                        <div className='step_desc_time'>
                          {
                            item.approval_time ? (`审批时间：${moment(item.approval_time).format('YYYY-MM-DD HH:mm:ss')}`) : ''
                          }
                        </div>
                        {/* {
                          item.refuse_reason ? <div className='step_desc_bh'>{`审批意见：${item.refuse_reason}`}</div> : ''
                        } */}
                        {
                          item.sign_url ? <div className='step_desc_qm'>
                            <div>审批签名：</div>
                            <img alt='' src={IP.fileHost + item.sign_url} />
                          </div> : ''
                        }
                      </div>
                    } />)
                }
              </Steps> : <div style={{ textAlign: 'center' }}>--暂无审批--</div>
            }
            <Steps size="small" current={3}>
              {
                repairInfo.order_state >= 1 && repairInfo.order_state !== 2 ? <Step
                  icon={
                    repairInfo.work_name ? <img alt='' src={require('../../../../images/new/24.png')} /> : <img alt='' src={require('../../../../images/new/25.png')} />
                  }
                  title={
                    <div className='step_title'>
                      <span>维修人：{repairInfo.work_name ? repairInfo.work_name : '待指派'}</span>
                    </div>
                  }
                  description={
                    <div className='step_desc'>
                      <div className='step_desc_time'>
                        {
                          repairInfo.work_name && repairInfo.appoint_time ? (`指派时间：${moment(repairInfo.appoint_time).format('YYYY-MM-DD HH:mm:ss')}`) : ''
                        }
                      </div>
                    </div>
                  }
                /> : ''
              }
              {
                repairInfo.order_state >= 1 && repairInfo.order_state !== 2 && repairInfo.work_name ? <Step
                  icon={
                    repairInfo.order_state >= 3 ? <img alt='' src={require('../../../../images/new/24.png')} /> : <img alt='' src={require('../../../../images/new/25.png')} />
                  }
                  title={
                    <div className='step_title'>
                      <span>维修状态：{repairInfo.order_state >= 3 ? '已维修' : '待维修'}</span>
                    </div>
                  }
                  description={
                    <div className='step_desc'>
                      <div className='step_desc_time'>
                        {
                          repairInfo.order_state >= 3 && repairInfo.end_time ? (`维修时间：${moment(repairInfo.end_time).format('YYYY-MM-DD HH:mm:ss')}`) : ''
                        }
                      </div>
                    </div>
                  }
                /> : ''
              }
              {
                repairInfo.order_state >= 3 ? <Step
                  icon={
                    repairInfo.order_state === 4 ? <img alt='' src={require('../../../../images/new/24.png')} /> : <img alt='' src={require('../../../../images/new/25.png')} />
                  }
                  title={
                    <div className='step_title'>
                      <span>工单状态：{repairInfo.order_state === 4 ? '已完成' : '待确认'}</span>
                    </div>
                  }
                  description={<div></div>}
                /> : ''
              }
            </Steps>
          </div>
        </div>
        <WhiteSpace size="lg" />
        <div className="details_sub">
          <Button onClick={() => this.setState({ modal1: true })}>指派维修工</Button>
        </div>
        <Modal
          style={{ width: "90%" }}
          visible={this.state.modal1}
          transparent
          maskClosable={false}
          onClose={() => this.setState({ modal1: false })}
          title="选择维修工"
          footer={[{
            text: '取消', onPress: () => {
              this.setState({
                modal1: false
              })
            }
          }, {
            text: '确认', onPress: () => {
              if (value === '') {
                Toast.fail('请选择维修工', 1)
                return
              }
              Toast.loading('上传中！', 0)
              appointOrder({ orderId: id, workId: value, workName: name }).then(res => {
                if (res.code === 20000) {
                  Toast.hide()
                  Toast.success(res.message, 2)
                  setTimeout(() => {
                    this.props.history.goBack()
                  }, 3000);
                  // window.location.href = 'http://bx.xcu.edu.cn'
                }
              })
            }
          }]}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div style={{ height: 150, overflow: 'scroll' }}>
            <List >
              {workUser.length ? workUser.map(i => (
                <RadioItem key={i.zh} checked={value === i.zh} onChange={() => this.onChange(i.zh, i.xm)}>
                  {i.xm}
                </RadioItem>
              )) : '暂无维修工'}
            </List>
          </div>
        </Modal>
        <WhiteSpace size="lg" />
        {
          visible ? <div className='detailImg' onClick={() => this.setState({ visible: false })}>
            <img alt='' src={imgUrl} />
          </div> : ''
        }
      </div>
    );
  }
}

export default createForm()(Details);