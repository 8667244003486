import React, { Component } from 'react'
import { Pagination, Icon, } from 'antd-mobile'
import { findSecondModuleInfo } from '../../../../api/api'
import { listMyApprovalRecord } from '../../../../api/fqw'
import moment from 'moment'

class Approve extends Component {

  state = {
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    total: 0,
    configData: [],
    statusData: [
      { id: 1, name: '待审批', checked: true },
      { id: 2, name: '已审批', checked: false },
    ],
    status: 24, //审批类型 24开始
    type: 1, // 审批状态 （1待审批  2已审核）
    data: [],
  }

  componentDidMount = () => {
    document.title = '审批'
    window.scrollTo(0, 0)
    this.fetchModuleNum()
  }

  // 查询mobule_url数字模块
  fetchModuleNum = async () => {
    const res = await findSecondModuleInfo()
    if (res.code === 20000) {
      const arr = res.data.filter(x => x.module_type === '13')
      const newArr = arr.map((x, i) => {
        return {
          id: Number(x.module_url),
          name: x.module_name,
          checked: i ? false : true
        }
      })
      this.setState({
        configData: newArr,
        status: newArr[0].id,
      }, () => {
        if (this.props.location.state) {
          const record = this.props.location.state
          if (record.status === 24) {
            this.wasteFetch()
            this.setState({
              status: 20
            })
          }
        } else {
          if (newArr[0].id === 24) {
            this.wasteFetch()
          }
        }
      })
    }
  }

  // 废弃物审批
  wasteFetch = async () => {
    const { pageSize, pageNum, type } = this.state
    const res = await listMyApprovalRecord({ size: pageSize, page: pageNum, result: type })
    if (res.code === 20000) {
      this.setState({
        data: res.data.list || [],
        pages: res.data ? (res.data.pages ? res.data.pages : 1) : 1,
        total: res.data.total,
      })
    }
  }

  render() {
    const { statusData, data, configData, status, type } = this.state
    return (
      <div className='approve'>
        <div className='approve_header'>
          <div className='approve-select' style={{ height: '32px' }}>
            <div className='approve-select_width' style={{ height: '40px' }}>
              <div className='approve-select-con' style={{ width: 'max-content' }}>
                {
                  configData.map(item => {
                    return <div className={`approve-select-con-list ${status === item.id ? 'approve-select-con-list-active' : ''}`} key={item.id} onClick={() => {
                      this.setState({
                        status: item.id,
                        type: 1,
                      }, () => {
                        if (item.id === 24) {
                          this.wasteFetch()
                        }
                      })
                    }}>
                      <span className='approve-select-con-list-name'>{item.name}</span>
                      {
                        status === item.id ? <span className='approve-select-con-list-line-active'></span> : ''
                      }
                    </div>
                  })
                }
              </div>
            </div>
          </div>

          {/* 待审核，已审核 */}
          <div className='approve_header_config approve_manager_tabs'>
            <div className='approve_header_con'>
              {
                statusData.map(item => {
                  return <div className={`approve_header_con_list ${type === item.id ? 'approve_header_con_list_active' : ''}`} key={item.id} onClick={() => {
                    this.setState({
                      type: item.id
                    }, () => {
                      if (status === 24) {
                        this.wasteFetch()
                      }
                    })
                  }}>{item.name}</div>
                })
              }
            </div>
          </div>

        </div>

        <div className='approve_con'>

          {/* 废弃物审批 */}
          {status === 24 ? (<div className='approve_manager_top'>

            {data.map((item, key) => {
              return (
                <div className='subscription_list_item' key={key} onClick={() => {
                  this.props.history.push(`/module/waste/approveDetail/${item.pr_id}`)
                }}>
                  <div className='subscription_list_item_head'>
                    <div>登记单号：{item.register_code}</div>
                    {item.register_state === 0 ? (<div className='wait'>草稿</div>) : ''}
                    {item.register_state === 1 ? (<div className='wait'>待审批</div>) : ''}
                    {item.register_state === 2 ? (<div className='wait'>审批中</div>) : ''}
                    {item.register_state === 3 ? (<div className='reject'>审批驳回</div>) : ''}
                    {item.register_state === 4 ? (<div className='pass_through'>待处理</div>) : ''}
                    {item.register_state === 5 ? (<div className='pass_through'>已处理</div>) : ''}
                    {item.register_state === 6 ? (<div className='reject'>取消处理</div>) : ''}
                  </div>
                  <div className='subscription_list_title'>
                    {item.ssxy_mc}-{item.sszx_mc}-{item.room_name}
                  </div>
                  <div className='subscription_list_tag'>
                    <div>{item.principal_name}</div>
                    <div>废弃物种类：{item.waste_num}</div>
                  </div>
                  <div style={{ padding: '8px 12px 14px' }}>
                    {item.create_time ? moment(item.create_time).format('YYYY-MM-DD') : ''}
                  </div>
                </div>
              )
            })}

            {
              this.state.total > 20 ? (<div className='subscription_manager_page'>
                <Pagination total={this.state.pages}
                  current={this.state.pageNum}
                  onChange={e => {
                    this.setState({
                      pageNum: e
                    }, () => {
                      window.scrollTo(0, 0)
                      this.wasteFetch()
                    })
                  }}
                  locale={{
                    prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                    nextText: (<span className="arrow-align"><Icon type="right" /></span>),
                  }}
                />
              </div>) : ''
            }
          </div>) : ''}
          {/* 暂无数据 */}
          {data.length === 0 ? (<div className='not-yet'>--暂无数据--</div>) : ('')}
        </div>
      </div>
    )
  }
}

export default Approve