import React, { Component } from 'react'
import { Toast, Modal, TextareaItem } from 'antd-mobile'
import { req_findApprovalDetails, req_findApprovalBgDetails, req_submitApprovalResult } from '../../../api/api'
import moment from 'moment'
import './approve.less'

class ApproveDetail extends Component {

  state = {
    data: {},
    visible: false,
    opinion: '',
    type: this.props.match.params.typeId
  }

  componentDidMount = () => {
    if (this.props.match.params.typeId === '1') {
      this.sbfetch(this.props.match.params.id)
    } else {
      this.bgfetch(this.props.match.params.id)
    }
  }

  // 上报
  sbfetch = async prId => {
    const res = await req_findApprovalDetails({ prId })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }

  // 变更
  bgfetch = async prId => {
    const res = await req_findApprovalBgDetails({ prId })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }

  // 审核成功
  handleSuccess = async () => {
    const { data, type } = this.state
    const params = { pr_id: data.pr_id, pr_approval_result: 2, type: type }
    const res = await req_submitApprovalResult(params)
    if (res.code === 20000) {
      Toast.info(res.message)
      this.props.history.go(-1)
    }
  }

  // 审核驳回弹窗
  handleFailModal = () => {
    this.setState({
      visible: true
    })
  }

  // 输入审批意见
  onChangeOpinion = e => {
    this.setState({
      opinion: e
    })
  }

  // 提交审批驳回
  handleSubmit = async () => {
    const { data, type, opinion } = this.state
    if (!opinion) return Toast.info('请输入驳回意见！')
    const params = {
      pr_id: data.pr_id,
      type,
      pr_approval_result: 3,
      pr_approval_opinion: opinion
    }
    const res = await req_submitApprovalResult(params)
    if (res.code === 20000) {
      Toast.info(res.message)
      this.props.history.go(-1)
    }
  }

  render() {
    const { data, visible } = this.state
    return (
      <div className='approveDetail'  style={{ padding: '0px' }}>
        <div className='approve_detail_head'>
          <div className='approve_detail_left'>
            <div className='approve_detail_left_title'>
              {data.room_name}
            </div>
            <div className='approve_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='approve_risk_card' style={{ marginLeft: '0px' }}>
                <div>{data.grade_name}</div>
                <div>{data.type_name}</div>
              </div>
            </div>
          </div>
          <div className='approve_detail_right'>
            {data.pr_approval_result === 1 ? (<img src={require('./../../../images/new/21.png')} alt='' />) : ('')}
          </div>
        </div>
        <div className='approve_detail_cont'>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>房间号</div>
              <div>{data.room_number}</div>
            </div>
            <div className='approve_detail_item'>
              <div>实验室名称</div>
              <div>{data.room_name}</div>
            </div>
            <div className='approve_detail_item'>
              <div>危险等级</div>
              <div>{data.grade_name}</div>
            </div>
            <div className='approve_detail_item'>
              <div>危险分类</div>
              <div>{data.type_name}</div>
            </div>
            <div className='approve_detail_item_more'>
              <div>防护品</div>
              <div>{data.productsName}</div>
            </div>
            <div className='approve_detail_item_more'>
              <div>危险源</div>
              <div>{data.hazardName}</div>
            </div>
          </div>

          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>责任人</div>
              <div>{data.fzr}</div>
            </div>
            <div className='approve_detail_item'>
              <div>提交时间</div>
              <div>{
                data.create_time ? moment(data.create_time).format('YYYY/MM/DD HH:mm') : moment(new Date()).format('YYYY/MM/DD HH:mm')
              }</div>
            </div>
            {data.pr_approval_time ? (
              <div className='approve_detail_item'>
                <div>审批时间</div>
                <div>{moment(data.pr_approval_time).format('YYYY/MM/DD HH:mm')}</div>
              </div>
            ) : ('')}
            {
              data.pr_approval_result === 2 ? (<div className='approve_detail_item'>
                <div>审批操作</div>
                <div>
                  审核成功
                </div>
              </div>) : ('')
            }
            {
              data.pr_approval_result === 3 ? (<div className='approve_detail_item'>
                <div>审批操作</div>
                <div>
                  审核驳回
                </div>
              </div>) : ('')
            }
            {
              data.pr_approval_result === 3 ? (<div className='approve_detail_item'>
                <div>驳回原因</div>
                <div>
                  <TextareaItem
                    rows={5}
                    placeholder=""
                    value={data.pr_approval_opinion}
                  />
                </div>
              </div>) : ('')
            }
          </div>
        </div>
        {
          data.pr_approval_result === 1 ? <div className='approveDetail_btn'>
            <div className='approveDetail_btn_list' onClick={() => this.handleFailModal()}>审核驳回</div>
            <div className='approveDetail_btn_list' onClick={() => this.handleSuccess()}>审核通过</div>
          </div> : ''
        }
        <Modal
          visible={visible}
          transparent
          onClose={() => {
            this.setState({
              visible: false,
              opinion: '',
            })
          }}
          title="审批意见"
          footer={[{ text: <div className='approve_modal_btn'><div>确定</div></div>, onPress: () => this.handleSubmit() }]}
          className="approve_modal"
        >
          <div>
            <TextareaItem
              rows={5}
              placeholder="说点什么吧~"
              onChange={this.onChangeOpinion}
            />
          </div>
        </Modal>
      </div>
    )
  }
}

export default ApproveDetail
