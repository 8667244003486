import React, { Component } from 'react'
import { Popconfirm } from 'antd'
import { Toast } from 'antd-mobile'
import { getResourceInfo, findResourcesInfo, uploadResourcesFile, updateResourcesInfo, deleteResourcesInfo, downloadFilesToZip } from '../../../api/api'
import word from '../../../images/file/word.png'
import excel from '../../../images/file/excel.png'
import ppt from '../../../images/file/ppt.png'
import pdf from '../../../images/file/pdf.png'
import img from '../../../images/file/img.png'
import video from '../../../images/file/video.png'
import txt from '../../../images/file/txt.png'
import err from '../../../images/file/err.png'
import moment from 'moment'
import cookie from 'react-cookies'
import IP from '../../../config/config'
import './index.less'

class ResourceDetail extends Component {

  state = {
    wt: 250,
    resourceInfo: {},
    data: [],
    typeIcon: [], // 资源类型图片
    checked: false, // 是否全选
  }

  componentDidMount = () => {
    const recordId = this.props.match.params.id
    this.fetchCourse(recordId)
    this.fetchResources(recordId)
    this.setState({
      wt: document.body.clientWidth - 125,
      typeIcon: [word, excel, ppt, pdf, img, video, txt, err],
    })
  }

  // 查询资源课程详情信息
  fetchCourse = async recordId => {
    const res = await getResourceInfo({ recordId })
    if (res.code === 20000) {
      this.setState({
        resourceInfo: res.data
      })
    }
  }

  // 查询实验资源信息
  fetchResources = async recordId => {
    const res = await findResourcesInfo({ recordId })
    if (res.code === 20000) {
      this.setState({
        data: (res.data || []).map(x => {
          x.suffix = x.resources_name.substring(x.resources_name.lastIndexOf('.') + 1)
          x.checked = false
          return x
        }),
        checked: false,
      })
    }
  }

  // 上传实验资源
  onChangeFile = e => {
    const { resourceInfo } = this.state
    const { files } = e.target
    const maxFileSize = 1 * 1024 * 1024 * 1024; // 最大文件大小（以字节为单位）
    Toast.loading('上传中...', 0)
    if (files && files.length) {

      // 验证文件大小
      if (files[0].size > maxFileSize) {
        Toast.info('文件大小不能超过1G');
        return;
      }

      let formData = new FormData()
      formData.append("file", files[0])
      // formData.append("recordId", resourceInfo.record_id)
      uploadResourcesFile(formData).then(async res => {
        if (res.code === 20000) {
          const result = await updateResourcesInfo({
            record_id: resourceInfo.record_id,
            resources_file: res.data.fileurl,
            resources_name: res.data.filename,
            resources_size: res.data.filesize,
          })
          if (result.code === 20000) {
            Toast.info(result.message)
            this.fetchResources(result.data)
          }
        } else {
          Toast.info(res.message)
        }
      })
    }
  }

  // 是否全选
  handleAllChoose = checked => {
    const { data } = this.state
    this.setState({
      checked: !checked,
      data: data.map(x => {
        x.checked = checked ? false : true
        return x
      })
    })
  }

  // 单选选中or取消
  handleRadioChoose = item => {
    const { data } = this.state
    const array = data.map(x => {
      x.checked = item.resources_id === x.resources_id ? (x.checked ? false : true) : x.checked
      return x
    })
    this.setState({
      data: array,
      checked: array.filter(x => x.checked).length === array.length ? true : false
    })
  }

  // 多选删除
  handleDel = async () => {
    const { data, resourceInfo } = this.state
    const res = await deleteResourcesInfo({ resourcesId: data.filter(x => x.checked).map(t => t.resources_id).join(',') })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.fetchResources(resourceInfo.record_id)
    }
  }

  // 多选下载
  handleDown = async () => {
    const { data } = this.state
    const arr = data.filter(x => x.checked).map(x => {
      return {
        path: x.resources_file,
        name: x.resources_name
      }
    })
    const res = await downloadFilesToZip({
      zipName: '资源文件',
      filePaths: arr
    })
    if (res.code === 20000) {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
      console.log('是否是Android：', isAndroid); //true,false
      console.log('是否是iOS：', isIOS)
      if (isIOS) {
        window.location.href = IP.fileHost + res.data
      } else {
        window.location.href = `http://labmanage.sastc.cn/Zip.html?url=${IP.fileHost + res.data}`
      }
    }
  }

  // 文件类型图片 typeIcon: [word, excel, ppt, pdf, img, video, txt, err],
  handleTypeIcon = icon => {
    const { typeIcon } = this.state
    if (icon === 'doc' || icon === 'docx') return typeIcon[0]
    else if (icon === 'xls' || icon === 'xlsx') return typeIcon[1]
    else if (icon === 'ppt' || icon === 'pptx') return typeIcon[2]
    else if (icon === 'pdf') return typeIcon[3]
    else if (icon === 'jpg' || icon === 'jpeg' || icon === 'png' || icon === 'gif') return typeIcon[4]
    else if (icon === 'mp4' || icon === 'rmvb' || icon === 'avi' || icon === 'flv' || icon === 'mkv') return typeIcon[5]
    else if (icon === 'txt') return typeIcon[6]
    else return typeIcon[7]
  }

  render() {
    const { wt, resourceInfo, data, checked } = this.state
    return (
      <div className='resource_detail'>
        {
          cookie.load('sf') == 1 ? <div className='resource_detail_upload' onClick={() => {
            document.getElementById('files').click()
          }}>上传</div> : ''
        }
        <div className='resource_detail_course'>{resourceInfo.record_kcmc}</div>
        <div className='resource_detail_xnq'>{resourceInfo.jzg_xm}&nbsp;&nbsp;&nbsp;&nbsp;{resourceInfo.record_xn} {resourceInfo.record_xq === '1' ? '秋季' : resourceInfo.record_xq === '2' ? '春季' : '夏季'}学期</div>
        {/* <div className='resource_detail_detail' style={{ paddingBottom: `${cookie.load('sf') == 1 ? '60px' : '0px'}` }}> */}
        <div className='resource_detail_detail' style={{ paddingBottom: 60 }}>
          {
            (data || []).length ? <div className='resource_detail_con'>
              <div className='resource_detail_con_header'>
                <div className='resource_detail_con_header_title'>资源列表</div>
                {/* {
                  cookie.load('sf') == 1 ? <div className='resource_detail_con_header_choose' onClick={() => this.handleAllChoose(checked)}>{checked ? '取消全选' : '全选'}</div> : ''
                } */}
                <div className='resource_detail_con_header_choose' onClick={() => this.handleAllChoose(checked)}>{checked ? '取消全选' : '全选'}</div>
              </div>
              {
                data.map(item => {
                  return <div className='resource_detail_con_list' key={item.resources_id}>
                    <div className='resource_detail_con_list_left'>
                      <img alt='' src={this.handleTypeIcon(item.suffix)} />
                      {/* <div className='resource_detail_con_list_left_con' style={{ width: `${cookie.load('sf') == 1 ? wt : wt + 45}px` }}> */}
                      <div className='resource_detail_con_list_left_con' style={{ width: `${wt}px` }}>
                        <div className='resource_detail_con_list_left_con_name'>{item.resources_name}</div>
                        <div className='resource_detail_con_list_left_con_desc'>{item.resources_size}&nbsp;&nbsp;&nbsp;&nbsp;{item.upload_time ? moment(item.upload_time).format('YYYY.MM.DD HH:mm') : ''}</div>
                      </div>
                    </div>
                    {/* {
                      cookie.load('sf') == 1 ? <div className='resource_detail_con_list_xz' onClick={() => this.handleRadioChoose(item)}>
                        {
                          item.checked ? <img alt='' src={require('../../../images/choose.png')} /> : <img alt='' src={require('../../../images/noChoose.png')} />
                        }
                      </div> : ''
                    } */}
                    <div className='resource_detail_con_list_xz' onClick={() => this.handleRadioChoose(item)}>
                      {
                        item.checked ? <img alt='' src={require('../../../images/choose.png')} /> : <img alt='' src={require('../../../images/noChoose.png')} />
                      }
                    </div>
                  </div>
                })
              }
            </div> : <div className='not-yet'>--暂无资源--</div>
          }
        </div>
        <div className='resource_detail_btn'>
          {
            (data || []).filter(x => x.checked).length ? <div className='resource_detail_btn_list' onClick={() => this.handleDown()}>
              <img alt='' src={require('../../../images/down.png')} />
              <span className='resource_detail_btn_list_ok'>下载</span>
            </div> : <div className='resource_detail_btn_list'>
              <img alt='' src={require('../../../images/noDown.png')} />
              <span>下载</span>
            </div>
          }
          {
            cookie.load('sf') == 1 ? (
              (data || []).filter(x => x.checked).length ? <Popconfirm
                title="确定要删除选中资源么？"
                onConfirm={() => this.handleDel()}
                okText="确定"
                cancelText="取消"
              >
                <div className='resource_detail_btn_list'>
                  <img alt='' src={require('../../../images/delete.png')} />
                  <span className='resource_detail_btn_list_ok'>删除</span>
                </div>
              </Popconfirm> : <div className='resource_detail_btn_list'>
                <img alt='' src={require('../../../images/noDel.png')} />
                <span>删除</span>
              </div>
            ) : ''
          }
        </div>
        {/* {
          cookie.load('sf') == 1 ? <div className='resource_detail_btn'>
            {
              (data || []).filter(x => x.checked).length ? <Popconfirm
                title="确定要删除选中资源么？"
                onConfirm={() => this.handleDel()}
                okText="确定"
                cancelText="取消"
              >
                <div className='resource_detail_btn_list'>
                  <img alt='' src={require('../../../images/delete.png')} />
                  <span className='resource_detail_btn_list_ok'>删除</span>
                </div>
              </Popconfirm> : <div className='resource_detail_btn_list'>
                <img alt='' src={require('../../../images/noDel.png')} />
                <span>删除</span>
              </div>
            }
          </div> : ''
        } */}
        <input type='file' id='files' onChange={e => this.onChangeFile(e)} hidden onClick={(event) => { event.target.value = null }} />
      </div>
    )
  }
}

export default ResourceDetail
