import React, { Component } from 'react'
import { req_listMySbApproval, req_listMyBgApproval, listMyApproval, findSecondModuleInfo, listMyApprovalDevice } from '../../../api/api'
import { findAppApprovalOrderRecord, req_listMyApproval } from '../../../api/repairApi'
import moment from 'moment'
import cookie from 'react-cookies'
import './approve.less'

class Approve extends Component {

  state = {
    statusData: [
      { id: 1, name: '待审核', checked: true },
      { id: 2, name: '审核成功', checked: false },
      { id: 3, name: '审核失败', checked: false },
    ],
    statusData2: [
      { id: 1, name: '待审核', checked: true },
      { id: 2, name: '已审核', checked: false },
    ],
    status: 1, //审批类型 1风险上报 2风险变更 3实验室预约 4设备预约 5报修审批
    type: 1, // 审批状态 （1待审核 2审核成功 3审核失败） or （1待审批  2已审核）
    data: [],
  }

  componentDidMount = () => {
    if (this.props.location.state) {
      const record = this.props.location.state
      console.log(record, 'ttt')
      if (record.status === 1) {
        this.sbFetch()
        this.setState({
          status: 1
        })
      }
      if (record.status === 2) {
        this.bgFetch()
        this.setState({
          status: 2
        })
      }
      if (record.status === 3) {
        this.orederFetch()
        this.setState({
          status: 3
        })
      }
      if (record.status === 4) {
        this.deviceFetch()
        this.setState({
          status: 4
        })
      }
      if (record.status === 5) {
        this.repairFetch()
        this.setState({
          status: 5
        })
      }
      if (record.status === 6) {
        this.fetchListMyApproval()
        this.setState({
          status: 6
        })
      }
    }
  }

  // 上报
  sbFetch = async () => {
    const { type } = this.state
    const res = await req_listMySbApproval({ type })
    if (res.code === 20000) {
      this.setState({
        data: res.data || []
      })
    }
  }

  // 变更
  bgFetch = async () => {
    const { type } = this.state
    const res = await req_listMyBgApproval({ type })
    if (res.code === 20000) {
      this.setState({
        data: res.data || []
      })
    }
  }

  // 实验室预约
  orederFetch = async () => {
    const { type } = this.state
    const res = await listMyApproval({ type })
    if (res.code === 20000) {
      this.setState({
        data: res.data || []
      })
    }
  }

  // 设备预约
  deviceFetch = async () => {
    const { type } = this.state
    const res = await listMyApprovalDevice({ type })
    if (res.code === 20000) {
      this.setState({
        data: res.data || []
      })
    }
  }

  // 报修审批
  repairFetch = async () => {
    const { type } = this.state
    const res = await findAppApprovalOrderRecord({ flag: type, userId: cookie.load('userData').id })
    if (res.code === 20000) {
      this.setState({
        data: res.data || []
      })
    }
  }

  // 巡检审批
  fetchListMyApproval = async () => {
    const { type } = this.state
    const res = await req_listMyApproval({ type: type })
    if (res.code === 20000) {
      this.setState({
        data: res.data || []
      })
    }
  }

  getweekday = (date) => {
    var weekArray = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
    var week = weekArray[new Date(date).getDay()];
    return week;
  }

  render() {
    const { statusData, statusData2, data, status, type } = this.state
    return (
      <div className='approve'>
        <div className='approve_header'>
          {/* 待审核，审核成功，审核失败 */}
          {
            status !== 5 ? (
              <div className='approve_header_config'>
                <div className='approve_header_con'>
                  {
                    statusData.map(item => {
                      return <div className={`approve_header_con_list ${type === item.id ? 'approve_header_con_list_active' : ''}`} key={item.id} onClick={() => {
                        this.setState({
                          type: item.id
                        }, () => {
                          if (status === 1) {
                            this.sbFetch()
                          } else if (status === 2) {
                            this.bgFetch()
                          } else if (status === 3) {
                            this.orederFetch()
                          } else if (status === 4) {
                            this.deviceFetch()
                          } else if (status === 6) {
                            this.fetchListMyApproval()
                          }
                        })
                      }}>{item.name}</div>
                    })
                  }

                </div>
              </div>
            ) : ('')
          }

          {/* 报修审批（只有待审核，已审核） */}
          {
            status === 5 ? (
              <div className='approve_header_config'>
                <div className='approve_header_con'>
                  {
                    statusData2.map(item => {
                      return <div className={`approve_header_con_list ${type === item.id ? 'approve_header_con_list_active' : ''}`} key={item.id} onClick={() => {
                        this.setState({
                          type: item.id
                        }, () => {
                          this.repairFetch()
                        })
                      }}>{item.name}</div>
                    })
                  }
                </div>
              </div>
            ) : ('')
          }
        </div>

        <div className='approve_con'>
          {/* 风险上报 风险变更 */}
          {
            status < 3 ? data.map((item, key) => {
              return <div className='approve_risk_list' key={key} onClick={() => this.props.history.push(`/module/approve/detail/${item.pr_id}/${status}`)}>
                <div className='approve_risk'>
                  <div className='approve_risk_head'>
                    <div>{item.create_time ? moment(item.create_time).format('YYYY-MM-DD HH:mm') : moment(new Date()).format('YYYY-MM-DD HH:mm')}</div>
                    <div>{item.fzr}</div>
                  </div>
                  <div className='approve_risk_room'>
                    {item.room_name}
                  </div>
                  <div className='approve_risk_card'>
                    <div>{item.grade_name}</div>
                    <div>{item.type_name}</div>
                  </div>
                  <div className='approve_risk_desc'>
                    <div className='approve_risk_desc_item'>
                      <div>防护品</div>
                      <div>{item.productsName}</div>
                    </div>
                    <div className='approve_risk_desc_item'>
                      <div>危险源</div>
                      <div>{item.hazardName}</div>
                    </div>
                  </div>
                </div>
              </div>
            }) : ('')
          }

          {/* 实验室预约审批 */}
          {
            status === 3 ? data.map(item => {
              return <div className='approve_con_list' key={item.record_id} onClick={() => this.props.history.push(`/module/approve/orderDetail/${item.pr_id}`)}>
                <div className='approve_list_head'>
                  <div>
                    {item.submit_time ? moment(item.submit_time).format('YYYY-MM-DD HH:mm') : moment(new Date()).format('YYYY-MM-DD HH:mm')}
                  </div>
                  <div>
                    {item.reservationist}
                  </div>
                </div>
                <div className='approve_list_head_title'>
                  {item.room}
                </div>

                <div className='approve_list_head_desc'>
                  <div className='approve_list_head_date'>
                    {item.date_time} {this.getweekday(item.date_time)} 第{item.nodeInfo}节
                  </div>
                  {item.seat_number > 0 ? (<div className='approve_list_head_seat'>座位号:{item.seat_number}</div>) : ''}
                </div>
              </div>
            }) : ('')
          }

          {/* 设备预约审批 */}
          {
            status === 4 ? data.map(item => {
              return <div className='approve_con_list' key={item.xh} onClick={() => this.props.history.push(`/module/approve/deviceApprove/${item.pr_id}`)}>
                <div className='approve_list_head_student'>
                  <div className='approve_list_head_title'>
                    {item.reservationist}
                  </div>
                  <div>
                    {item.reservationist_number}
                  </div>
                </div>
                <div className='approve_list_head_desc' style={{ paddingBottom: '0px' }}>
                  <div className='approve_list_head_date'>
                    {item.dep_name}
                  </div>
                </div>
                <div className='approve_list_head' style={{ paddingTop: '12px' }}>
                  <div>
                    {item.equipment_name}
                  </div>
                </div>
              </div>
            }) : ('')
          }

          {/* 报修审批 */}
          {
            status === 5 ? data.map(item => {
              return <div className='approve_risk_list' key={item.order_id} onClick={() => this.props.history.push(`/module/smartRepair/approvalDetail/${item.order_id}`)}>
                <div className='approve_risk'>
                  <div className='approve_risk_head'>
                    <div>{item.create_time ? moment(item.create_time).format('YYYY-MM-DD HH:mm') : moment(new Date()).format('YYYY-MM-DD HH:mm')}</div>
                    <div>{item.user_name}</div>
                  </div>
                  <div className='approve_risk_room'>
                    {(item.building_name ? item.building_name : '') + ' ' + (item.room_name ? item.room_name : '')}
                  </div>
                  <div className='approve_risk_card'>
                    <div>{item.fault_type_name}</div>
                  </div>
                  <div className='approve_risk_desc'>
                    <div className='approve_risk_desc_item'>
                      <div>问题描述</div>
                      <div>{item.description}</div>
                    </div>
                  </div>
                </div>
              </div>
            }) : ''
          }

          {/* 巡检审批 */}
          {
            status === 6 ? data.map(item => {
              return <div className='approve_con_list' key={item.pr_id} onClick={() => this.props.history.push(`/module/approve/patrolDetail/${item.pr_id}`)}>
                <div className='approve_list_head'>
                  <div>
                    {item.create_time ? moment(item.create_time).format('YYYY-MM-DD HH:mm') : moment(new Date()).format('YYYY-MM-DD HH:mm')}
                  </div>
                  <div>
                    {item.user_name}
                  </div>
                </div>
                <div className='approve_list_head_title'>
                  {item.room_name}
                </div>
                <div className='approve_list_head_desc'>
                  <div className='approve_list_head_date'>
                    {item.building_name}{item.floor_name}
                  </div>
                </div>

              </div>
            }) : ('')
          }

          {/* 暂无数据 */}
          {data.length === 0 ? (<div className='not-yet'>--暂无数据--</div>) : ('')}
        </div>
      </div>
    )
  }
}

export default Approve