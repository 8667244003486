import React, { Component } from 'react'
import { Toast } from 'antd-mobile'
import { findAllBrand, kgCardBrand } from '../../../api/api'
import './room.less'

class RoomBanpai extends Component {

  state = {
    roomInfo: {},
    data: [],
    wt: 265,
  }

  componentDidMount = () => {
    document.title = "智慧班牌"
    this.fetch(this.props.match.params.id)
    this.setState({
      roomInfo: this.props.location.state.roomInfo,
      wt: document.body.clientWidth - 110
    })
  }

  fetch = async spaceId => {
    const res = await findAllBrand({ spaceId })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }
  // 执行开关机
  handleChangeSwitch = async (e, record) => {
    e.stopPropagation()
    const { data } = this.state
    const res = await kgCardBrand({ cardNumbers: record.card_number, state: record.boot_state })
    if (res.code === 20000) {
      if (res.data && res.data.length) {
        Toast.info(res.data[0])
      } else {
        this.setState({
          data: data.map(x => {
            if (x.card_number === record.card_number) {
              x.boot_state = record.boot_state === 1 ? 0 : 1
            }
            return x
          })
        })
      }
    } else if (res.code === 20001) {
      Toast.info(res.message)
    } else {
      Toast.hide()
    }
  }

  render () {
    const { roomInfo, data, wt } = this.state
    return (
      <div className='roomBanpai'>
        <div className='shortTerm_name'>{roomInfo.wzName}</div>
        <div className='shortTerm_addr'>{roomInfo.buildingName ? roomInfo.buildingName.split('/')[0] : ''}</div>
        <div className='roomEnvironment_con'>
          {
            (data || []).length ? data.map(item => {
              return <div className='banpai_con_list' key={item.card_number}>
                <div className='banpai_con_list_left' style={{ width: `${wt}px` }} onClick={() => this.props.history.push(`/module/banpai/detail/${item.card_number}`)}>
                  <div className='banpai_con_list_left_name'>{item.card_name}</div>
                  <div className='banpai_con_list_left_addr'>
                    {item.device_building_name}/{item.device_floor_name}/{item.device_room_name}
                  </div>
                  <div className='banpai_con_list_left_desc'>
                    <div className={`banpai_con_list_left_desc_status ${item.net_state === 1 ? 'banpai_status_online' : 'banpai_status_offline'}`}>
                      <span></span>
                      <span>{item.net_state === 1 ? '设备在线' : '设备离线'}</span>
                    </div>
                    {
                      item.boot_state === 1 ? <div className='banpai_con_list_left_desc_con'>已开启</div> : ''
                    }
                  </div>
                </div>
                <div className='banpai_con_img' onClick={e => this.handleChangeSwitch(e, item)}>
                  {
                    item.boot_state === 1 ? <img alt='' src={require('../../../images/kai.png')} /> : <img alt='' src={require('../../../images/guan.png')} />
                  }
                </div>
              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
      </div>
    )
  }
}

export default RoomBanpai