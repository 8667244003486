import React, { Component } from 'react'
import { TreeSelect, Select } from 'antd'
import { Picker, List, TextareaItem, Modal, Icon, Toast, InputItem } from 'antd-mobile';
import { listDict, req_findDepartmentInfo, findZxOrRoomByDwOrZx, findPageWhpInfo, findDaiRuKuSgInfo, findMasterProcess, findNextApprovalUser, findRepository, findRepositoryUser, saveRkInfo, findDaiRuKuSgWhpInfo } from './../../../../api/whp'
import './add.less'

const { TreeNode } = TreeSelect;

class Add extends Component {
  state = {
    data: [
      {
        label: '2013年',
        value: '2013',
      },
      {
        label: '2014年',
        value: '2014',
      },
    ],
    slideId: '',
    startX: '',
    visible: false,
    tab_id: '',
    sqfxData: [],//方向
    xydm: undefined,//所属学院
    zxid: undefined,//所属中心
    jsid: undefined,//所属实验室
    ckid: undefined,
    rkrgh: undefined,
    rkms: '',
    typeData: [],//类别
    whpFlbh: '',//化学品类别
    chemicalData: [],//化学品
    depTreeData: [],
    depData: [],
    centerData: [],//中心
    roomData: [],//房间
    masterProcess: 0, // 是否多人同时审批
    approveVisible: false,
    spry: undefined,
    warehouseData: [],//仓库
    userData: [],//入库人
    rkWhpInfoEntities: [],//入库物品
    drkData: [],//待入库
    sgdh: undefined
  }

  componentDidMount = () => {
    document.title = '入库申请'
    window.scrollTo(0, 0)
    this.fetchDrk()
    this.fetchDep()
    this.fetchType()
    this.fetchWarehouse()
    this.findPageWhpInfo()
  }

  // 查询待入库
  fetchDrk = async () => {
    const res = await findDaiRuKuSgInfo({ size: 99999, page: 1 })
    if (res.code === 20000) {
      this.setState({
        drkData: res.data.list.map(item => {
          return {
            label: item.sgrxm + '(' + item.sgdh + ')',
            value: item.sgdh,
          }
        }),
      })
    }
  }

  // 查询学院/部门
  fetchDep = async () => {
    const res = await req_findDepartmentInfo({ dwlx: 1 })
    if (res.code === 20000) {
      let menuList = res.data
      menuList = menuList.map(a => {
        return {
          title: a.dwmc,
          key: a.dwdm,
          parentId: a.sj_dm,
          value: a.dwdm,
          disabled: a.sj_dm === '0' ? true : false
        }
      })
      menuList = menuList.map(m => {
        m.children = menuList.filter(p => p.parentId === m.key)
        return m
      }).filter(x => !res.data.find(v => v.dwdm === x.parentId))
      this.setState({
        depTreeData: menuList,
        depData: res.data,
      })
    }
  }

  // 选择部门/学院
  handleChangeDep = async e => {
    const res = await findZxOrRoomByDwOrZx({ flag: 1, param: e })
    if (res.code === 20000) {
      this.setState({
        centerData: res.data.map(item => {
          return {
            label: item.room_name,
            value: item.room_id,
          }
        })
      })
    }
  }

  // 选择中心
  handleChangeCenter = async e => {
    const res = await findZxOrRoomByDwOrZx({ flag: 2, param: e })
    if (res.code === 20000) {
      this.setState({
        roomData: res.data.map(item => {
          return {
            label: item.room_name,
            value: item.room_id,
          }
        })
      })
    }
  }

  // 查询类别
  fetchType = async () => {
    const res = await listDict({ ZDLX: 'lb' })
    if (res.code === 20000) {
      const data = res.data.map(item => {
        return {
          label: item.zdmc,
          value: item.zdbh,
        }
      })
      this.setState({
        typeData: data
      })
    }
  }

  // 查询仓库
  fetchWarehouse = async () => {
    const res = await findRepository()
    if (res.code === 20000) {
      const data = res.data.map(item => {
        return {
          label: item.repository_name,
          value: item.repository_id,
        }
      })
      this.setState({
        warehouseData: data
      })
    }
  }

  // 选择仓库(查询仓库下的管理员)
  handleChangeCk = async e => {
    const res = await findRepositoryUser({ repositoryId: e.join(',') })
    if (res.code === 20000) {
      this.setState({
        userData: res.data.map(item => {
          return {
            label: item.repository_glyxm,
            value: item.repository_glygh,
          }
        })
      })
    }
  }

  // 化学品
  findPageWhpInfo = async () => {
    const res = await findPageWhpInfo({ page: 1, size: 999999, whpZt: 1 })
    if (res.code === 20000) {
      this.setState({
        chemicalData: res.data.info.list.map(item => {
          item.sgwhpsl = 0
          item.whp_sl = 0
          item.rkwhpsl = 1
          item.rkwhpzj = item.whp_dj
          item.whp_xcl = item.whp_bzsl
          return item
        })
      })
    }
  }

  // 求和
  sum(arr) {
    var sum = 0;
    for (var i = 0; i < arr.length; i++) {
      sum += arr[i];
    }
    return sum;
  }

  // 根据数量生成数组
  createNumData = (num, obj) => {
    var arr = []
    for (var i = 1; i <= num; i++) {
      obj.rk_key = Math.random() + new Date().getTime()
      arr.push({ ...obj })
    }
    return arr
  }

  // 危化品总数
  whpNum = () => {
    const { chemicalData } = this.state
    return this.sum(chemicalData.map(x => x.sgwhpsl))
  }

  // 添加危化品
  // handleAddWhp = () => {
  //   const { chemicalData, rkWhpInfoEntities } = this.state
  //   const newData = chemicalData.filter(x => x.sgwhpsl !== 0)
  //   newData.map(item => {
  //     const data = this.createNumData(item.sgwhpsl, item)
  //     rkWhpInfoEntities.push(...data)
  //     this.setState({
  //       rkWhpInfoEntities: rkWhpInfoEntities
  //     })
  //   })
  //   this.setState({
  //     visible: false,
  //   })
  // }

  // 选择待入库危化品
  handleOkDrk = async () => {
    const { sgdh } = this.state
    const res = await findDaiRuKuSgWhpInfo({ sgdh: sgdh.join(',') })
    if (res.code === 20000) {
      await this.handleChangeDep(res.data.xydm)
      await this.handleChangeCenter(res.data.zxid)
      const newArr = (res.data.sgWhpInfoVos || []).map(m => {
        m.rkwhpsl = m.sysl || 0
        m.rkwhpzj = (m.sysl || 0) * m.whp_dj
        m.rkwhpms = null
        return m
      })
      if (newArr.length === 0) {
        Toast.info('该申购单暂无危化品！')
        return false
      }

      await this.setState({
        rkWhpInfoEntities: newArr,
        xydm: res.data.xydm,
        zxid: res.data.zxid.split(','),
        jsid: res.data.jsid.split(','),
      })
    }
  }

  // 判断审批是多人共同审批还是单人审批
  handleMasterProcess = async () => {
    const { rkWhpInfoEntities, xydm, zxid, jsid, ckid, rkrgh } = this.state
    if (xydm === undefined || zxid === undefined || jsid === undefined || ckid === undefined || rkrgh === undefined) {
      Toast.info('请补全上述内容！')
      return false
    }
    if (rkWhpInfoEntities.length === 0) {
      Toast.info('请添加危化品！')
      return false
    }
    if (rkWhpInfoEntities.filter(x => x.rkwhpsl === '' || x.rkwhpsl === null).length > 0) {
      Toast.info('请填写危化品入库数量！')
      return false
    }
    const res = await findMasterProcess({ flag: 2 })
    if (res.code === 20000) {
      this.setState({
        masterProcess: res.data
      }, () => {
        this.handleNextApprovalUser(jsid.join(','))
      })
    }
  }

  // 获取下一个审批人员信息
  handleNextApprovalUser = async roomId => {
    const { masterProcess } = this.state
    const res = await findNextApprovalUser({ flag: 2, roomId })
    if (res.code === 20000) {
      this.setState({
        approveUser: res.data,
        approveVisible: true,
      }, () => {
        if (masterProcess) {
          this.setState({
            spry: (res.data || []).map(x => x.gh)
          })
        }
      })
    }
  }

  // 提交
  submit = async () => {
    const { spry, rkms, xydm, zxid, jsid, depData, centerData, roomData, masterProcess, approveUser, warehouseData, ckid, rkrgh, userData, rkWhpInfoEntities } = this.state
    if (spry === undefined || spry === '') {
      Toast.info('请选择审批人员！')
      return false;
    }
    if (xydm === undefined || zxid === undefined || jsid === undefined || ckid === undefined || rkrgh === undefined) {
      Toast.info('请补全上述内容！')
      return false
    }
    if (rkWhpInfoEntities.length === 0) {
      Toast.info('请添加危化品！')
      return false
    }
    if (rkWhpInfoEntities.filter(x => x.rkwhpsl === '' || x.rkwhpsl === null).length > 0) {
      Toast.info('请填写危化品入库数量！')
      return false
    }
    const values = {
      xydm: xydm,
      zxid: zxid.join(','),
      jsid: jsid.join(','),
      ckid: ckid.join(','),
      xymc: depData.find(x => x.dwdm === xydm).dwmc,
      zxmc: centerData.find(x => x.value === zxid.join(',')).label,
      jsmc: roomData.find(x => x.value === jsid.join(',')).label,
      ckmc: warehouseData.find(x => String(x.value) === ckid.join(',')).label,
      rklx: 1,
      rkzt: 1,
      rkms,
      rkrgh: rkrgh.join(','),
      rkrxm: userData.find(x => String(x.value) === rkrgh.join(',')).label,

    }
    const params = {
      ...values,
      spuserVos: masterProcess ? approveUser : approveUser.filter(x => x.gh === spry),
      rkWhpInfoEntities: rkWhpInfoEntities,
    }
    const res = await saveRkInfo(params)
    if (res.code === 20000) {
      Toast.info("提交成功！")
      setTimeout(() => {
        this.props.history.goBack()
      }, 2000);
    }
  }

  render() {
    const { slideId, visible, tab_id, rkms, typeData, chemicalData, depTreeData, xydm, centerData, zxid, roomData, jsid, approveVisible, masterProcess, approveUser, spry, warehouseData, ckid, rkrgh, userData, rkWhpInfoEntities, drkData, sgdh } = this.state
    const loop = data =>
      data.map(item => {
        if (item.children && item.children.length) {
          return (
            <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled} />;
      });
    return (
      <div className='subscription_add'>
        <div className='subscription_add_title'>
          基础信息
        </div>
        <div className='subscription_add_form'>
          <div className='subscription_add_picker'>
            <Picker
              data={drkData}
              value={sgdh}
              cols={1}
              onChange={(e) => {
                this.setState({
                  sgdh: e
                }, () => {
                  this.handleOkDrk()
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>关联申购单</List.Item>
            </Picker>
          </div>
          <div className='subscription_add_tree'>
            <div>
              <span style={{ color: 'red' }}>* </span>申请所属学院
            </div>
            <TreeSelect
              placeholder="请选择"
              style={{ width: '100%' }}
              value={xydm}
              onChange={(e) => {
                this.setState({
                  xydm: e,
                  zxid: undefined,
                  jsid: undefined
                })
                this.handleChangeDep(e)
              }}
            >
              {loop(depTreeData)}
            </TreeSelect>
          </div>
          <div className='subscription_add_picker'>
            <Picker
              data={centerData}
              value={zxid}
              cols={1}
              onChange={(e) => {
                this.setState({
                  zxid: e,
                  jsid: undefined
                })
                this.handleChangeCenter(e)
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>所属中心</List.Item>
            </Picker>
          </div>
          <div className='subscription_add_picker'>
            <Picker
              data={roomData}
              value={jsid}
              cols={1}
              onChange={(e) => {
                this.setState({
                  jsid: e
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>所属实验室</List.Item>
            </Picker>
          </div>
          <div className='subscription_add_picker'>
            <Picker
              data={warehouseData}
              value={ckid}
              cols={1}
              onChange={(e) => {
                this.setState({
                  ckid: e,
                  rkrgh: undefined
                }, () => {
                  this.handleChangeCk(e)
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>入库仓库</List.Item>
            </Picker>
          </div>
        </div>

        <div className='subscription_add_form'>
          <div className='subscription_add_picker'>
            <Picker
              data={userData}
              value={rkrgh}
              cols={1}
              onChange={(e) => {
                this.setState({
                  rkrgh: e
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>入库人</List.Item>
            </Picker>
          </div>

        </div>


        <div className='subscription_add_form'>
          <div className='subscription_add_text'>
            <div><span style={{ color: 'white' }}>* </span>备注</div>
            <TextareaItem
              placeholder="请输入"
              rows={5}
              value={rkms}
              onChange={(e) => {
                this.setState({
                  rkms: e
                })
              }}
            />
          </div>
        </div>

        {/* <div className='subscription_add_increase'>
          <div onClick={() => {
            this.findPageWhpInfo()
            this.setState({
              tab_id: '',
              visible: true
            })
          }}>
            <img src={require('./../../../../images/chemicals/02.png')} alt='' />
            <span>添加危化品</span>
          </div>
        </div> */}

        <div className='subscription_add_increase_record'>
          <div className='subscription_list_card' style={{ margin: '0' }}>
            {rkWhpInfoEntities.map((item, key) => {
              return (
                <div className='subscription_add_card_item' style={{ height: '260px' }} onTouchStart={(e) => {
                  this.setState({
                    startX: e.touches[0].clientX
                  })
                }} onTouchEnd={(e) => {
                  const endX = e.changedTouches[0].clientX
                  if (this.state.startX === endX) {
                    return
                  }

                  if (endX - this.state.startX > 100) { // 阈值可以根据需要调整
                    this.setState({
                      slideId: ''
                    })
                    return false;
                    // 左滑后的处理逻辑
                  }
                  if (endX + this.state.startX > 100) { // 阈值可以根据需要调整
                    this.setState({
                      slideId: item.whp_bh
                    })
                    // 右滑后的处理逻辑
                    return false
                  }
                }}>
                  <div className='subscription_add_card_cont  subscription_add_cont_float' style={{ margin: slideId === item.whp_bh ? '0px' : '0px 12px' }}>
                    <div className='subscription_list_card_item' style={{ width: slideId === item.whp_bh ? window.screen.width : window.screen.width - 24 }}>
                      <div className='subscription_list_card_head'>
                        <div className='subscription_list_card_head_left'>
                          <div>{key + 1}</div>
                          <div>{item.whp_mc}</div>
                        </div>
                        <div className='subscription_list_card_class'>
                          {item.whp_fl}
                        </div>
                      </div>
                      <div className='subscription_list_card_company'>
                        <div>{item.whp_cas}</div>
                        <div>{item.whp_gys}</div>
                      </div>
                      <div className='subscription_list_card_unit'>
                        <div>{item.whp_ggxh}</div>
                        <div>{item.whp_bzsl}{item.whp_bzdw}</div>
                        <div>{item.whp_jldw}</div>
                      </div>
                      <div className='subscription_list_card_input'>
                        <div className='subscription_add_input'>
                          <InputItem type='digit' placeholder="请输入" value={item.rkwhpsl} onChange={(e) => {
                            this.setState({
                              rkWhpInfoEntities: rkWhpInfoEntities.map(x => {
                                if (x.whp_bh === item.whp_bh) {
                                  if (e > x.sysl) {
                                    x.rkwhpsl = e > x.sysl ? x.sysl : e
                                    x.rkwhpzj = e ? e * x.whp_dj : 0
                                  } else {
                                    x.rkwhpsl = e < 1 ? 1 : e
                                    x.rkwhpzj = e < 1 ? x.whp_dj : e * x.whp_dj
                                  }

                                }
                                return x
                              })
                            })
                          }}>入库数量</InputItem>
                        </div>
                        <div className='subscription_add_text'>
                          <div>备注</div>
                          <TextareaItem
                            placeholder="请输入"
                            rows={3}
                            value={item.rkwhpms}
                            onChange={(e) => {
                              this.setState({
                                rkWhpInfoEntities: rkWhpInfoEntities.map(x => {
                                  if (x.whp_bh === item.whp_bh) {
                                    x.rkwhpms = e
                                  }
                                  return x
                                })
                              })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='subscription_add_card_del' style={{ display: slideId === item.whp_bh ? 'block' : 'none' }} onClick={() => {
                      this.setState({
                        rkWhpInfoEntities: rkWhpInfoEntities.filter(x => x.whp_bh !== item.whp_bh)
                      })
                    }}>
                      删除
                    </div>
                  </div>
                </div>
              )
            })}

          </div>
        </div>
        <div className='subscription_list_but'>
          <div onClick={() => {
            this.handleMasterProcess()
          }}>提交入库</div>
        </div>

        <Modal
          popup
          visible={visible}
          className='subscription_modal'
          onClose={() => {
            this.setState({
              visible: false
            })
          }}
          animationType="slide-up"
        >
          <div className='subscription_add_modal'>
            <div className='subscription_add_modal_locate'>
              <div className='subscription_add_modal_head'>
                <div>添加危化品</div>
                <div>
                  <Icon type='cross' onClick={() => {
                    this.setState({
                      visible: false
                    })
                  }} />
                </div>
              </div>
              <div className='subscription_add_modal_tabs'>
                <div className='subscription_add_modal_tabs_bg'>
                  <div className='subscription_add_modal_tabs_width'>
                    <div className={tab_id === '' ? 'subscription_add_modal_tabs_click' : 'subscription_add_modal_tabs_item'} onClick={() => {
                      this.setState({
                        tab_id: '',
                      }, () => {
                        // this.findPageWhpInfo()
                      })
                    }}>
                      全部
                    </div>
                    {typeData.map(item => {
                      return (<div key={item.value} className={tab_id === item.value ? 'subscription_add_modal_tabs_click' : 'subscription_add_modal_tabs_item'} onClick={() => {
                        this.setState({
                          tab_id: item.value,
                        }, () => {
                          // this.findPageWhpInfo()
                        })
                      }}>
                        {item.label}
                      </div>)
                    })}

                  </div>
                </div>
              </div>
            </div>
            <div className='subscription_add_modal_card'>
              {(tab_id !== '' ? chemicalData.filter(x => x.whp_flbh === tab_id) : chemicalData).map((item, key) => {
                return (<div className='subscription_list_card_item' key={item.whp_bh}>
                  <div className='subscription_list_card_head'>
                    <div className='subscription_list_card_head_left'>
                      <div>{key + 1}</div>
                      <div>{item.whp_mc}</div>
                    </div>
                    <div className='subscription_list_card_class'>
                      {item.whp_fl}
                    </div>
                  </div>
                  <div className='subscription_list_card_company'>
                    <div>{item.whp_cas}</div>
                    <div>{item.whp_gys}</div>
                  </div>
                  <div className='subscription_list_card_foot'>
                    <div className='subscription_list_card_unit'>
                      <div>{item.whp_ggxh}</div>
                      <div>{item.whp_bzsl}{item.whp_bzdw}</div>
                      <div>{item.whp_jldw}</div>
                    </div>
                    <div className='subscription_list_card_count'>
                      <img src={require('./../../../../images/chemicals/03.png')} alt='' onClick={() => {
                        if (item.sgwhpsl !== 0) {
                          const data = chemicalData.map(x => {
                            if (item.whp_bh === x.whp_bh) {
                              x.sgwhpsl = item.sgwhpsl - 1
                            }
                            return x
                          })
                          this.setState({
                            chemicalData: data
                          })
                        }
                      }} />
                      <div>{item.sgwhpsl}</div>
                      <img src={require('./../../../../images/chemicals/04.png')} alt='' onClick={() => {
                        const data = chemicalData.map(x => {
                          if (item.whp_bh === x.whp_bh) {
                            x.sgwhpsl = item.sgwhpsl + 1
                          }
                          return x
                        })
                        this.setState({
                          chemicalData: data
                        })
                      }} />
                    </div>
                  </div>
                </div>)
              })}

            </div>
            <div className='subscription_list_but'>
              <div onClick={() => {
                this.handleAddWhp()
              }}>添加</div>
            </div>
          </div>
        </Modal>
        {/* 选择审批人 */}
        <Modal
          popup
          visible={approveVisible}
          className='subscription_modal'
          onClose={() => {
            this.setState({
              approveVisible: false,
              spry: undefined
            })
          }}
          animationType="slide-up"
        >
          <div className='subscription_add_modal subscription_add_modal_approve'>
            <div className='subscription_add_modal_head'>
              <div>选择审批人员</div>
              <div>
                <Icon type='cross' onClick={() => {
                  this.setState({
                    approveVisible: false
                  })
                }} />
              </div>
            </div>
            <div className='subscription_add_select'>
              <div>
                <span style={{ color: 'red' }}>* </span>审批人员
              </div>
              <Select mode={masterProcess ? 'multiple' : null} disabled={masterProcess ? true : false} allowClear showSearch optionFilterProp="children" value={spry} placeholder='请选择' onChange={(e) => {
                this.setState({
                  spry: e
                })
              }}>
                {
                  (approveUser || []).map(item => {
                    return <Select.Option key={item.gh} value={item.gh}>{item.xm}</Select.Option>
                  })
                }
              </Select>
            </div>

            <div className='subscription_list_but'>
              <div onClick={() => {
                this.submit()
              }}>提交审批</div>
            </div>
          </div>
        </Modal>
      </div >
    )
  }
}

export default Add
