import React, { Component } from 'react'
import { InputItem, Toast } from 'antd-mobile'
import { Checkbox, TimePicker } from 'antd'
import { findScheduledTaskBanpai, addScheduledBanpai, updateBanpai, deleteScheduledBanpai } from '../../../api/api'
import moment from 'moment'
import './index.less'

class TimingConfiguration extends Component {

  state = {
    id: '', // 配置id
    confName: '', // 配置名称
    startTime: undefined, // 开机时间
    endTime: undefined, // 关机时间
    confRule: [], // 是否包含周六周日节假日
    flag: 1,
  }

  componentDidMount = () => {
    document.title = "班牌-开关机配置"
    this.fetch(this.props.match.params.id)
  }

  // 查询是否有定时配置
  fetch = async roomId => {
    const res = await findScheduledTaskBanpai({ roomId })
    if (res.code === 20000) {
      if (res.data) {
        this.setState({
          id: res.data.id,
          confName: res.data.conf_name,
          startTime: res.data.conf_open_time ? moment(res.data.conf_open_time, 'HH:mm') : undefined,
          endTime: res.data.conf_close_time ? moment(res.data.conf_close_time, 'HH:mm') : undefined,
          confRule: res.data.conf_rule ? res.data.conf_rule.split(',') : [],
          flag: 2
        })
      } else {
        this.setState({
          flag: 1
        })
      }
    }
  }

  // 保存
  handleSubmit = async () => {
    const { id, confName, startTime, endTime, confRule } = this.state
    if (!confName) return Toast.info('请输入配置名称')
    if (!startTime) return Toast.info('请选择每日开机时间')
    if (!endTime) return Toast.info('请选择每日关机时间')
    const req = {
      id,
      conf_name: confName,
      conf_open_time: startTime ? moment(startTime).format('HH:mm') : '',
      conf_close_time: endTime ? moment(endTime).format('HH:mm') : '',
      conf_rule: confRule.join(','),
      roomIds: this.props.match.params.id,
    }
    const method = req.id ? updateBanpai : addScheduledBanpai;
    const res = await method(req)
    if (res.code === 20000) {
      Toast.success(res.message)
      setTimeout(() => {
        this.props.history.go(-1)
      }, 2000)
    }
  }

  // 删除配置
  handleDel = async () => {
    const { id } = this.state
    const res = await deleteScheduledBanpai({ configId: id, roomId: this.props.match.params.id })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.props.history.go(-1)
    }
  }

  // 禁用前10分钟和最后10分钟时间
  disabledMinutes10 = h => {
    if (h === 0) {
      return this.newArray(0, 10)
    }
    if (h === 23) {
      return this.newArray(50, 60)
    }
  }

  // 禁用小时
  disabledHours = () => {
    const { startTime } = this.state
    let hours = this.newArray(0, 60);
    let min10StartTime = this.getMin10(new Date(startTime))
    let startHours = Number(min10StartTime.split(':')[0] || 0)
    let surplusHours = 24 - startHours;
    hours.splice(startHours, surplusHours);
    return hours;
  };

  // 禁用分钟
  disabledMinutes = h => {
    const { startTime } = this.state
    let min10StartTime = this.getMin10(new Date(startTime))
    let startMinutes = Number(min10StartTime.split(':')[1] || 0)
    let endHours = Number(min10StartTime.split(':')[0] || 0)
    if (h === endHours) {
      return this.newArray(0, startMinutes);
    } else if (h < endHours) {
      return this.newArray(0, 60);
    }
  };

  // 获取10分钟后的时间
  getMin10 = (date) => {
    var min = date.getMinutes();  //2. 获取当前分钟
    date.setMinutes(min + 10);  //3. 设置当前时间+10分钟：把当前分钟数+10后的值重新设置为date对象的分钟数
    var h = date.getHours() < 10 ? ('0' + date.getHours()) : date.getHours()
    var f = date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes()
    var formatDate = h + ":" + f;
    return formatDate
  }

  // 禁用不大于第一次的时间
  newArray = (start, end) => {
    let result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  render() {
    const { confName, startTime, endTime, confRule, flag } = this.state
    const options = [
      { label: '周六', value: '1' },
      { label: '周日', value: '2' },
      { label: '节假日', value: '3' },
    ]
    return (
      <div className='TimingConfiguration'>
        <div className='TimingConfiguration_main'>
          <div className='TimingConfiguration_item_input'>
            <InputItem value={confName} placeholder="请输入配置名称" onChange={e => {
              this.setState({ confName: e })
            }}>配置名称</InputItem>
          </div>
          <div className='TimingConfiguration_item_time openConfig_time'>
            <div className='openConfig_time_title'>每日开机时间</div>
            <TimePicker
              format='HH:mm'
              placeholder='开机时间'
              value={startTime}
              disabledMinutes={selectedHour => this.disabledMinutes10(selectedHour)}
              onChange={time => {
                this.setState({
                  startTime: time,
                  endTime: undefined,
                })
              }}
            />
          </div>
          <div className='TimingConfiguration_item_time openConfig_time'>
            <div className='openConfig_time_title'>每日关机时间</div>
            <TimePicker
              format='HH:mm'
              placeholder='关机时间'
              value={endTime}
              disabledHours={() => this.disabledHours()}
              disabledMinutes={selectedHour => this.disabledMinutes(selectedHour)}
              onChange={time => {
                this.setState({
                  endTime: time
                });
              }}
            />
          </div>
          <div className='TimingConfiguration_item_checkbox' style={{ paddingBottom: 12 }}>
            <div className='TimingConfiguration_item_checkbox_name'>
              是否包含
            </div>
            <div className='TimingConfiguration_item_checkbox_value'>
              <Checkbox.Group value={confRule} options={options} onChange={e => {
                this.setState({
                  confRule: e
                })
              }} />
            </div>
          </div>
        </div>
        <div className='openConfig_btn'>
          {
            flag === 2 ? <div className='openConfig_del' onClick={() => this.handleDel()}>
              删除
            </div> : ''
          }
          <div className='openConfig_submit' onClick={() => this.handleSubmit()}>
            保存
          </div>
        </div>
      </div>
    )
  }
}

export default TimingConfiguration
