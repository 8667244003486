import React, { Component } from 'react';
import { InputItem, Toast, List } from 'antd-mobile'
import { getDeviceLockById, getDeviceAuth, lockIssuePassWord } from '../../../api/api'
import copy from 'copy-to-clipboard'
import cookie from 'react-cookies'
import './door_lock.less'

class OpenLock extends Component {

  state = {
    password: '暂无密码',
    roomData: {}, // 房间信息
    changePwd: '', // 修改密码
    changeQrPwd: '', // 确认密码
    pwdLength: 6,
  }

  componentDidMount = () => {
    window.addEventListener('focusin', this.setScrollTop)
    window.addEventListener('focusout', this.setScrollTop)
    this.fetchRoom(this.props.match.params.id)
    this.setState({
      pwdLength: cookie.load('pwd')
    })
  }

  setScrollTop = () => {
    let scrollTop = document.body.scrollTop + document.documentElement.scrollTop
    window.scrollTo(0, scrollTop)
  }

  // 获取房间详情信息
  fetchRoom = async deviceId => {
    const res = await getDeviceLockById({ deviceId })
    if (res.code === 20000) {
      this.fetchPassword(res.data.device_id)
      this.setState({
        roomData: res.data
      })
    }
  }

  // 获取密码
  fetchPassword = async deviceId => {
    const res = await getDeviceAuth({ deviceId, type: 1 })
    if (res.code === 20000) {
      this.setState({
        password: res.data.auth_content
      })
    }
  }

  // 复制分享
  handleCopy = () => {
    const { password } = this.state
    copy(password);
    Toast.success('复制成功');
  }

  // 修改密码
  handleChangePwd = e => {
    const { pwdLength } = this.state
    let value = e
    if (e.length > pwdLength) {
      value = value.slice(0, pwdLength)
    }
    this.setState({
      changePwd: value
    })
  }

  // 确认密码
  handleChangeQrPwd = e => {
    const { pwdLength } = this.state
    let value = e
    if (e.length > pwdLength) {
      value = value.slice(0, pwdLength)
    }
    this.setState({
      changeQrPwd: value
    })
  }

  // 保存
  handleSubmit = async () => {
    const { roomData, changePwd, changeQrPwd, pwdLength } = this.state;
    if (changePwd !== changeQrPwd) return Toast.info('两次输入密码不一致！')
    if (changePwd.length !== Number(pwdLength)) return Toast.info(`请输入${pwdLength}位数的密码！`)
    const res = await lockIssuePassWord({
      deviceId: roomData.device_id,
      password: changePwd
    })
    if (res.code === 20000) {
      Toast.success('密码修改成功')
      this.setState({
        password: changePwd
      })
    }
  }

  render() {
    const { roomData, password, changePwd, changeQrPwd, pwdLength } = this.state
    return (
      <div className='temporary'>
        <div className='temporary-content'>
          <div className='temporary-content-name'>{roomData.device_room_name}-{roomData.device_door_name}</div>
          <div className='temporary-content-room'>{roomData.device_building_name}/{roomData.device_floor_name}/{roomData.device_room_name}</div>
          <div className='temporary-content-detail'>
            <InputItem value={password} />
            <div onClick={() => this.handleCopy()}>复制分享</div>
          </div>
        </div>
        <div className='temporary-time'>
          <List>
            <InputItem type='number' placeholder={`请输入${pwdLength}位数密码`} value={changePwd} onChange={e => this.handleChangePwd(e)}>修改密码</InputItem>
            <InputItem type='number' placeholder={`请输入${pwdLength}位数密码`} value={changeQrPwd} onChange={e => this.handleChangeQrPwd(e)}>确认密码</InputItem>
          </List>
        </div>
        <div className='temporary-btn'>
          <div className='temporary-btn-content' onClick={() => this.handleSubmit()}>保 存</div>
        </div>
      </div>
    );
  }
}

export default OpenLock;