import React, { Component } from 'react'
import { Toast, Modal, TextareaItem } from 'antd-mobile'
import { req_processDetails, req_submitApprovalResult } from '../../../api/repairApi'
import IP from './../../../config/config'
import moment from 'moment'

class PatrolDetail extends Component {

  state = {
    newData: {},
    data: {},
    visible: false,
    opinion: '',
    imgVisible: false,
    fileUrl: '',
    type: 1,
  }

  componentDidMount = () => {
    this.fetch(this.props.match.params.id)
  }

  fetch = async id => {
    const res = await req_processDetails({ id })
    if (res.code === 20000) {
      this.setState({
        newData: res.data,
        data: res.data.pollingTaskDetailsVO || {}
      })
    }
  }

  // 审核成功
  handleSuccess = async () => {
    const { newData } = this.state
    const res = await req_submitApprovalResult({ pr_id: newData.pr_id, pr_approval_result: 2 })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.props.history.go(-1)
    }
  }

  // 审核驳回弹窗
  handleFailModal = () => {
    this.setState({
      visible: true
    })
  }

  // 输入审批意见
  onChangeOpinion = e => {
    this.setState({
      opinion: e
    })
  }

  // 提交审批驳回
  handleSubmit = async () => {
    const { newData, opinion } = this.state
    if (!opinion) return Toast.info('请输入驳回意见！')
    const res = await req_submitApprovalResult({
      pr_id: newData.pr_id,
      pr_approval_result: 3,
      pr_approval_opinion: opinion
    })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.props.history.go(-1)
    }
  }

  // 放大图片或者视频
  handleImageClick = (fileUrl, type) => {
    this.setState({
      imgVisible: true,
      fileUrl,
      type,
    })
  }

  render() {
    const { data, visible } = this.state
    return (
      <div className='approveDetail' style={{ padding: '0px 0px 71px' }}>
        <div className='approve_detail_head'>
          <div className='approve_detail_left'>
            <div className='approve_detail_left_title'>
              {data.room_name}
            </div>
            <div className='approve_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='approve_list_head_date'>
                {data.building_name}{data.floor_name}
              </div>
              {data.pollingTaskDetailsContentEntities && data.pollingTaskDetailsContentEntities.map(item => {
                return (<div className='approve_list_head_seat_err' key={item.content_id}>{item.type_name}</div>)
              })}
            </div>
          </div>
          <div className='approve_detail_right hidden_handle_type'>
            {data.hidden_result === 1 ? (<img src={require('./../../../images/new/21.png')} alt='' />) : ('')}
          </div>
        </div>
        <div className='approve_detail_cont'>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item patrol_approve_detail_item'>
              <div>巡检人</div>
              <div>{data.user_name}</div>
            </div>
            <div className='approve_detail_item patrol_approve_detail_item'>
              <div>隐患描述</div>
              <div>{data.details_content}</div>
            </div>
            <div className='approve_detail_item patrol_approve_detail_item'>
              <div>提交时间</div>
              <div>
                <div>{moment(data.create_time).format('YYYY-MM-DD HH:mm')}</div>
              </div>
            </div>
            <div className='approve_detail_textarea approve_detail_textarea2' style={{ padding: '0px 0px 4px' }}>
              <div>隐患文件</div>
              <div className='approve_detail_textarea_img'>
                {data && data.pollingTaskDetailsFileEntities && data.pollingTaskDetailsFileEntities.map(item => {
                  return item.file_type === 1 ? (<img src={IP.fileHost + item.file_url} alt='' style={{ width: '72px', height: '72px' }} onClick={() => {
                    this.handleImageClick(item.file_url, 1)
                  }} key={item.file_id} />) : (<video width="72px" height="72px" src={IP.fileHost + item.file_url} poster={IP.fileHost + item.file_url} controls="controls" controlslist="nodownload noremoteplayback" disablePictureInPicture key={item.file_id}>
                    your browser does not support the video tag
                  </video>)
                })}
              </div>

            </div>
          </div>

          <div className='approve_detail_bar'>
            <div className='approve_detail_textarea' style={{ padding: '0px 0px 12px' }}>
              <div>驳回意见</div>
              <div>{data.sp_content}</div>
            </div>
            <div className='approve_detail_textarea'>
              <div>整改方案</div>
              <div>{data.dispose_content}</div>
            </div>
            <div className='approve_detail_textarea approve_detail_textarea2'>
              <div>方案附件</div>
              {data && data.disposeFileVOList && data.disposeFileVOList.map(item => {
                return (
                  <div className='approve_detail_textarea_img' key={item.dispose_file_id} onClick={() => {
                    window.open(IP.fileHost + item.dispose_file_url)
                  }} style={{ color: '#220022' }}>
                    <img src={require('./../../../img/file_type.png')} alt='' />
                    {item.dispose_file_name}
                  </div>
                )
              })}

            </div>
          </div>

        </div>

        {
          data.hidden_result === 1 ? <div className='approveDetail_btn'>
            <div className='approveDetail_btn_list' onClick={() => this.handleFailModal()}>审核驳回</div>
            <div className='approveDetail_btn_list' onClick={() => this.handleSuccess()}>审核通过</div>
          </div> : ''
        }
        <Modal
          visible={visible}
          transparent
          onClose={() => {
            this.setState({
              visible: false,
              opinion: '',
            })
          }}
          title="审批意见"
          footer={[{ text: <div className='approve_modal_btn'><div>确定</div></div>, onPress: () => this.handleSubmit() }]}
          className="approve_modal"
        >
          <div>
            <TextareaItem
              rows={5}
              placeholder="说点什么吧~"
              onChange={this.onChangeOpinion}
            />
          </div>
        </Modal>
        {
          this.state.imgVisible ? <div className='detailImg' onClick={() => this.setState({ imgVisible: false })}>
            {this.state.type === 1 ? (<img alt='' src={IP.fileHost + this.state.fileUrl} style={{ width: '80%' }} />) : (<video width="80%" src={IP.fileHost + this.state.fileUrl} poster={IP.fileHost + this.state.fileUrl} controls="controls" controlslist="nodownload noremoteplayback" disablePictureInPicture>
              your browser does not support the video tag
            </video>)}

          </div> : ''
        }
      </div>
    )
  }
}

export default PatrolDetail
