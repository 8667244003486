import React, { Component } from 'react'
import { Result, Icon, Toast } from 'antd-mobile'
import { userLogin, req_wxLogin } from '../../api/api'
import cookie from 'react-cookies'
import './index.less'

class Webs extends Component {

  state = {
    status: 1,
  }

  componentDidMount = () => {

  }

  fetch = async (code, imei) => {
    Toast.loading('登录中...', 0)
    const res = await userLogin({ code, imei })
    if (res.code === 20000) {
      Toast.hide()
      cookie.save('token', res.data.token)
      cookie.save('openId', res.data.openId)
      cookie.save('status', res.data.state)
      cookie.save('userData', res.data)
      if (res.data.state) {
        this.setState({
          status: 2
        })
      } else {
        Toast.info('用户信息未注册，请注册信息！！')
        setTimeout(() => {
          this.props.history.push('/attestation')
        }, 1500)
      }
    }
  }

  // 取消登录
  fetchClose = async code => {
    const result = await req_wxLogin({ code })
    if (result.code === 20000) {
      cookie.save('token', result.data.token)
      cookie.save('openId', result.data.openId)
      cookie.save('status', result.data.state)
      cookie.save('userData', result.data)
      cookie.save('sf', result.data.sf)
      this.setState({
        status: 3
      })
    }
  }

  render() {
    const { status } = this.state
    const myImg = src => <img src={src} className="spe am-icon am-icon-md" alt="" />
    return (
      <div className='webs'>
        <div className='webs_img'>
          <img alt='' src={require('../../images/sqdlpt.png')} />
        </div>
        {
          status === 1 ? <div>
            <div className='webs_js'>实验室安全教育学习系统</div>
            <div className='webs_dl'>桌面端登录确认</div>
            <div className='webs_btn'>
              <div className='webs_log_on' onClick={() => this.fetch(this.props.location.state.code, this.props.match.params.id)}>登录</div>
              <div className='webs_cancel' onClick={() => this.fetchClose(this.props.location.state.code)}>取消登录</div>
            </div>
          </div> : (
            status === 2 ? <Result
              img={<Icon type="check-circle" className="spe" style={{ fill: '#1F90E6' }} />}
              title="操作成功"
              message="恭喜你，你已经成功登录"
            /> : <Result
              img={myImg('https://gw.alipayobjects.com/zos/rmsportal/GIyMDJnuqmcqPLpHCSkj.svg')}
              title="无法完成操作"
              message="由于你选择了取消，所以此次登录失败"
            />
          )
        }
      </div>
    )
  }
}

export default Webs
