import React, { Component } from 'react'
import { Grid } from 'antd-mobile'
import { findModuleInfo } from './../../../api/api'
import { tjMobile, countRkNum } from './../../../api/whp'
import IP from './../../../config/config'
import './index.less'

class Chemicals extends Component {

  state = {
    moduleData: [],
    tjData: '',
    countNum: 0
  }

  componentDidMount = () => {
    document.title = '气体钢瓶管理'
    window.scrollTo(0, 0)
    this.tjMobile()
    this._countRkNum()
    this.fetchModule()
  }

  _countRkNum = async () => {
    const res = await countRkNum()
    if (res.code === 20000) {
      this.setState({
        countNum: res.data
      })
    }
  }

  tjMobile = async () => {
    const res = await tjMobile()
    if (res.code === 20000) {
      this.setState({
        tjData: res.data
      })
    }
  }

  // 查询模块
  fetchModule = async () => {
    const res = await findModuleInfo()
    if (res.code === 20000) {
      const data = (res.data || []).filter(x => x.module_type === '11')
      this.setState({
        moduleData: data
      })
    }
  }

  handleSkipModule = item => {
    this.props.history.push(item.module_url)
  }

  render() {
    const { moduleData, tjData } = this.state
    return (
      <div className='gas_cylinder'>
        <div className='gas_cylinder_title'>气体钢瓶管理</div>
        <div className='gas_cylinder_desc'>钢瓶管理和使用监测</div>
        <div className='gas_cylinder_con'>
          <div className='gas_cylinder_con_intro'>
            <div className='gas_cylinder_con_intro_header'>
              <div className='gas_cylinder_intro_header_list'>
                <div className='gas_cylinder_intro_header_list_num'>{tjData.whpzl}</div>
                <div className='gas_cylinder_intro_header_list_title'>气体钢瓶数量</div>
              </div>
              <div className='gas_cylinder_intro_header_list'>
                <div className='gas_cylinder_intro_header_list_num'>{tjData.totalNum}</div>
                <div className='gas_cylinder_intro_header_list_title'>入库总数</div>
              </div>
              <div className='gas_cylinder_intro_header_list'>
                <div className='gas_cylinder_intro_header_list_num'>{tjData.cksl}</div>
                <div className='gas_cylinder_intro_header_list_title'>领用总数</div>
              </div>
            </div>
          </div>
        </div>
        <div className='gas_cylinder_grid'>
          <Grid
            columnNum={3}
            data={moduleData}
            hasLine={false}
            renderItem={item => (
              <div className='gas_cylinder_con_grid_list' onClick={() => this.handleSkipModule(item)}>
                <img src={IP.fileHost + item.module_imge} alt="" style={{ width: '44px' }} />
                <span>{item.module_name}</span>
              </div>
            )}
          />
        </div>
      </div>
    )
  }
}

export default Chemicals
