import React, { Component } from 'react'
import { findSafetyVideo } from '../../api/api'
import IP from '../../config/config'

class Safety extends Component {

  state = {
    data: []
  }

  componentDidMount = () => {
    this.fetch()
  }

  fetch = async () => {
    const res = await findSafetyVideo({ flag: 2 })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className='safety'>
        <div className='safety_con'>
          {
            (data || []).map(item => {
              return <div className='safety-list' key={item.video_id}>
                <div className='safety-list-video'>
                  <video src={IP.fileHost + item.video_url} poster={IP.fileHost + item.video_image} controls="controls" controlslist="nodownload noremoteplayback" disablePictureInPicture>
                    your browser does not support the video tag
                  </video>
                </div>
                <div className='safety-list-title'>{item.video_name}</div>
                {/* <div className='safety-list-eyes'>5.4万次观看</div> */}
              </div>
            })
          }
        </div>
      </div>
    )
  }
}
export default Safety
