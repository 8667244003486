import React, { Component } from 'react'
import { DatePicker, List, Pagination, Icon, } from 'antd-mobile'
import { findPageSgWhpInfo, findPageRkWhpInfo, findPageLysqWhpInfo, findPageGhWhpInfo, findPageCkWhpInfo } from './../../../../api/yhp'
import moment from 'moment'
import './list.less'

class Stat extends Component {
  state = {
    tabs_id: 1,
    tabsData: [
      {
        value: 1,
        label: '申购',
      },
      {
        value: 2,
        label: '入库',
      },
      {
        value: 3,
        label: '领用',
      },
      {
        value: 4,
        label: '归还',
      },
      {
        value: 5,
        label: '出库',
      },
    ],
    startTime: '',
    endTime: '',
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    total: 0,
    data: [],
  }
  componentDidMount = () => {
    document.title = '查询统计'
    window.scrollTo(0, 0)
    this.setState({
      startTime: new Date(this.fun_date(-6)),
      endTime: new Date(),
    }, () => {
      this._findPageSgWhpInfo()
    })
  }

  // 申购
  _findPageSgWhpInfo = async () => {
    const { pageSize, pageNum, paramKey, startTime, endTime } = this.state
    const res = await findPageSgWhpInfo({
      size: pageSize, page: pageNum, paramKey,
      startTime: moment(startTime).format('YYYY-MM-DD'),
      endTime: moment(endTime).format('YYYY-MM-DD'),
    })
    if (res.code === 20000) {
      window.scrollTo(0, 0)
      this.setState({
        data: res.data.sgMxVos.list,
        pages: res.data.sgMxVos ? (res.data.sgMxVos.pages ? res.data.sgMxVos.pages : 1) : 1,
        total: res.data.sgMxVos.total,
      })
    }
  }

  // 入库
  _findPageRkWhpInfo = async () => {
    const { pageSize, pageNum, paramKey, startTime, endTime } = this.state
    const res = await findPageRkWhpInfo({
      size: pageSize, page: pageNum, paramKey,
      startTime: moment(startTime).format('YYYY-MM-DD'),
      endTime: moment(endTime).format('YYYY-MM-DD'),
    })
    if (res.code === 20000) {
      window.scrollTo(0, 0)
      this.setState({
        data: res.data.rkMxVos.list,
        pages: res.data.rkMxVos ? (res.data.rkMxVos.pages ? res.data.rkMxVos.pages : 1) : 1,
        total: res.data.rkMxVos.total,
      })
    }
  }

  // 领用
  _findPageLysqWhpInfo = async () => {
    const { pageSize, pageNum, paramKey, startTime, endTime } = this.state
    const res = await findPageLysqWhpInfo({
      size: pageSize, page: pageNum, paramKey,
      startTime: moment(startTime).format('YYYY-MM-DD'),
      endTime: moment(endTime).format('YYYY-MM-DD'),
    })
    if (res.code === 20000) {
      window.scrollTo(0, 0)
      this.setState({
        data: res.data.lysqMxVos.list,
        pages: res.data.lysqMxVos ? (res.data.lysqMxVos.pages ? res.data.lysqMxVos.pages : 1) : 1,
        total: res.data.lysqMxVos.total,
      })
    }
  }

  // 归还
  _findPageGhWhpInfo = async () => {
    const { pageSize, pageNum, paramKey, startTime, endTime } = this.state
    const res = await findPageGhWhpInfo({
      size: pageSize, page: pageNum, paramKey,
      startTime: moment(startTime).format('YYYY-MM-DD'),
      endTime: moment(endTime).format('YYYY-MM-DD'),
    })
    if (res.code === 20000) {
      window.scrollTo(0, 0)
      this.setState({
        data: res.data.ghMxVos.list,
        pages: res.data.ghMxVos ? (res.data.ghMxVos.pages ? res.data.ghMxVos.pages : 1) : 1,
        total: res.data.ghMxVos.total,
      })
    }
  }

  // 出库
  _findPageCkWhpInfo = async () => {
    const { pageSize, pageNum, paramKey, startTime, endTime } = this.state
    const res = await findPageCkWhpInfo({
      size: pageSize, page: pageNum, paramKey,
      startTime: moment(startTime).format('YYYY-MM-DD'),
      endTime: moment(endTime).format('YYYY-MM-DD'),
    })
    if (res.code === 20000) {
      window.scrollTo(0, 0)
      this.setState({
        data: res.data.ckMxVoList.list,
        pages: res.data.ckMxVoList ? (res.data.ckMxVoList.pages ? res.data.ckMxVoList.pages : 1) : 1,
        total: res.data.ckMxVoList.total,
      })
    }
  }

  // 获取几天前的日期
  fun_date = (num) => {
    var date1 = new Date()
    var date2 = new Date(date1)
    date2.setDate(date1.getDate() + num)
    //num是正数表示之后的时间，num负数表示之前的时间，0表示今天
    var time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate()
    return time2
  }

  render () {
    const { startTime, endTime, tabsData, tabs_id, data } = this.state
    return (
      <div className='subscription_list'>
        <div className='subscription_list_head query_stat_head'>
          <div className='inventory_manager_record_tabs'>
            <div className='inventory_manager_record_tabs_cont'>
              <div className='inventory_manager_record_tabs_width' style={{ justifyContent: 'space-between' }}>
                {tabsData.map(item => {
                  return <div className={item.value === tabs_id ? 'inventory_manager_record_tabs_item inventory_manager_record_tabs_item_active' : 'inventory_manager_record_tabs_item'} key={item.value} style={{ marginRight: '0px' }} onClick={() => {
                    this.setState({
                      tabs_id: item.value
                    }, () => {
                      if (this.state.tabs_id === 1) {
                        this._findPageSgWhpInfo()
                      } else if (this.state.tabs_id === 2) {
                        this._findPageRkWhpInfo()
                      } else if (this.state.tabs_id === 3) {
                        this._findPageLysqWhpInfo()
                      } else if (this.state.tabs_id === 4) {
                        this._findPageGhWhpInfo()
                      } else if (this.state.tabs_id === 5) {
                        this._findPageCkWhpInfo()
                      }
                    })
                  }}>
                    <div>{item.label}</div>
                    <div></div>
                  </div>
                })}
              </div>
            </div>
          </div>
          <div className='stat_card2_time query_stat_search'>
            <DatePicker
              mode="date"
              title="开始时间"
              extra="开始时间"
              value={startTime}
              maxDate={endTime ? endTime : null}
              onChange={date => {
                this.setState({
                  startTime: date
                }, () => {
                  if (this.state.tabs_id === 1) {
                    this._findPageSgWhpInfo()
                  } else if (this.state.tabs_id === 2) {
                    this._findPageRkWhpInfo()
                  } else if (this.state.tabs_id === 3) {
                    this._findPageLysqWhpInfo()
                  } else if (this.state.tabs_id === 4) {
                    this._findPageGhWhpInfo()
                  } else if (this.state.tabs_id === 5) {
                    this._findPageCkWhpInfo()
                  }
                })
              }}
            >
              <List.Item arrow="horizontal"></List.Item>
            </DatePicker>
            <div style={{ color: '#292c33', fontSize: '14px' }}>至</div>
            <DatePicker
              mode="date"
              title="结束时间"
              extra="结束时间"
              value={endTime}
              minDate={startTime ? startTime : null}
              onChange={date => {
                this.setState({
                  endTime: date
                }, () => {
                  if (this.state.tabs_id === 1) {
                    this._findPageSgWhpInfo()
                  } else if (this.state.tabs_id === 2) {
                    this._findPageRkWhpInfo()
                  } else if (this.state.tabs_id === 3) {
                    this._findPageLysqWhpInfo()
                  } else if (this.state.tabs_id === 4) {
                    this._findPageGhWhpInfo()
                  } else if (this.state.tabs_id === 5) {
                    this._findPageCkWhpInfo()
                  }
                })
              }}
            >
              <List.Item arrow="horizontal"></List.Item>
            </DatePicker>
          </div>
        </div>

        {/* 申购 */}
        {tabs_id === 1 ? (<div className='query_stat_record'>
          {data.map((item, key) => {
            return (
              <div className='subscription_list_item' key={key}>
                <div className='subscription_list_item_head'>
                  <div>编号：{item.whp_cas}</div>
                  <div className='pass_through'>{item.sgrxm}</div>
                </div>
                <div className='subscription_list_title'>
                  {item.whp_sgzlb}-{item.whp_mc}
                </div>
                <div className='subscription_list_tag'>
                  <div>{item.whp_ggxh}</div>
                  <div>{item.whp_bzsl}{item.whp_bzdw}</div>
                  <div>{item.whp_jldw}</div>
                  <div>单价{item.whp_dj}元</div>
                </div>
                <div className='subscription_list_foot'>
                  <div>
                    {item.sgsj ? moment(item.sgsj).format('YYYY-MM-DD HH:mm:ss') : ''}
                  </div>
                  <div>总金额{item.sgwhpzj}</div>
                </div>

              </div>
            )
          })}
        </div>) : ''}
        {/* 入库 */}
        {tabs_id === 2 ? (<div className='query_stat_record'>
          {data.map((item, key) => {
            return (
              <div className='subscription_list_item' key={key}>
                <div className='subscription_list_item_head'>
                  <div>编号：{item.whp_cas}</div>
                  <div className='pass_through'>{item.rkrxm}</div>
                </div>
                <div className='subscription_list_title'>
                  {item.whp_sgzlb}-{item.whp_mc}
                </div>
                <div className='subscription_list_tag'>
                  <div>{item.whp_ggxh}</div>
                  <div>{item.whp_bzsl}{item.whp_bzdw}</div>
                  <div>{item.whp_jldw}</div>
                  <div>单价{item.whp_dj}元</div>
                </div>
                <div className='subscription_list_foot'>
                  <div>
                    {item.rksj ? moment(item.rksj).format('YYYY-MM-DD HH:mm:ss') : ''}
                  </div>
                  <div>总金额{item.rkzje}</div>
                </div>

              </div>
            )
          })}
        </div>) : ''}

        {/* 领用 */}
        {tabs_id === 3 ? (<div className='query_stat_record'>
          {data.map((item, key) => {
            return (
              <div className='subscription_list_item' key={key}>
                <div className='subscription_list_item_head'>
                  <div>{item.whp_cas}</div>
                  <div className='pass_through'>{item.lyrmc1}，{item.lyrmc2}</div>
                </div>
                <div className='subscription_list_title'>
                  {item.whp_sgzlb}-{item.whp_mc}
                </div>
                <div className='subscription_list_tag'>
                  <div>{item.whp_ggxh}</div>
                  <div>{item.whp_bzsl}{item.whp_bzdw}</div>
                  <div>{item.whp_jldw}</div>
                  <div>单价{item.whp_dj}元</div>
                </div>
                <div className='subscription_list_foot'>
                  <div>
                    {item.lysqsj ? moment(item.lysqsj).format('YYYY-MM-DD HH:mm:ss') : ''}
                  </div>
                  <div></div>
                </div>

              </div>
            )
          })}
        </div>) : ''}

        {/* 归还 */}
        {tabs_id === 4 ? (<div className='query_stat_record'>
          {data.map((item, key) => {
            return (
              <div className='subscription_list_item' key={key}>
                <div className='subscription_list_item_head'>
                  <div>编号：{item.whp_cas}</div>
                  <div className='pass_through'>{item.sqrmc}</div>
                </div>
                <div className='subscription_list_title'>
                  {item.whp_sgzlb}-{item.whp_mc}
                </div>
                <div className='subscription_list_tag'>
                  <div>{item.whp_ggxh}</div>
                  <div>{item.whp_bzsl}{item.whp_bzdw}</div>
                  <div>{item.whp_jldw}</div>
                  <div>归还{item.zghsl}{item.whp_bzdw}</div>
                  <div>归还{item.zjls}{item.whp_jldw}</div>
                </div>
                <div className='subscription_list_foot'>
                  <div>
                    {item.sqsj ? moment(item.sqsj).format('YYYY-MM-DD HH:mm:ss') : ''}
                  </div>
                  <div></div>
                </div>

              </div>
            )
          })}
        </div>) : ''}

        {/* 出库 */}
        {tabs_id === 5 ? (<div className='query_stat_record'>
          {data.map((item, key) => {
            return (
              <div className='subscription_list_item' key={key}>
                <div className='subscription_list_item_head'>
                  <div>编号：{item.whp_cas}</div>
                  <div className='pass_through'>{item.ckrmc}</div>
                </div>
                <div className='subscription_list_title'>
                  {item.whp_sgzlb}-{item.whp_mc}
                </div>
                <div className='subscription_list_tag'>
                  <div>{item.whp_ggxh}</div>
                  <div>{item.whp_bzsl}{item.whp_bzdw}</div>
                  <div>出库{item.lysl}{item.whp_bzdw}</div>
                  <div>出库{item.whp_sl}{item.whp_jldw}</div>
                  <div>{item.whp_dj}元</div>
                </div>
                <div className='subscription_list_foot'>
                  <div>
                    {item.lysj ? moment(item.lysj).format('YYYY-MM-DD HH:mm:ss') : ''}
                  </div>
                  <div></div>
                </div>

              </div>
            )
          })}
        </div>) : ''}

        {this.state.data.length === 0 ? <div className='not-yet'>--暂无数据--</div> : ''}

        {
          this.state.total > 20 ? (<div className='subscription_manager_page'>
            <Pagination total={this.state.pages}
              current={this.state.pageNum}
              onChange={e => {
                this.setState({
                  pageNum: e
                }, () => {

                  if (this.state.tabs_id === 1) {
                    this._findPageSgWhpInfo()
                  } else if (this.state.tabs_id === 2) {
                    this._findPageRkWhpInfo()
                  } else if (this.state.tabs_id === 3) {
                    this._findPageLysqWhpInfo()
                  } else if (this.state.tabs_id === 4) {
                    this._findPageGhWhpInfo()
                  } else if (this.state.tabs_id === 5) {
                    this._findPageCkWhpInfo()
                  }
                })
              }}
              locale={{
                prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                nextText: (<span className="arrow-align"><Icon type="right" /></span>),
              }}
            />
          </div>) : ''
        }
      </div>
    )
  }
}

export default Stat
