import React, { Component } from 'react'
import { DatePicker, List } from 'antd-mobile'
import { statisticsLabWeek, statisticsAllLabCourse, statisticsLabCourse, statisticsLabCourseTop10, req_statistNum } from '../../../api/api'
import ReactEcharts from 'echarts-for-react'
import moment from 'moment'
import './index.less'

class useState extends Component {

  state = {
    weekDayState: 2,
    halfYearState: 1,
    fillNum: [],
    semesterClassNum: [],
    roomClassNum_xAxis: [],
    roomClassNum_skj_num: [],
    roomClassNum_use_num: [],
    top10_xAxis: [],
    top10_jc_num: [],
    top10_zb_num: [],
    numData: [],
    startTime: '',
    endTime: '',
  }
  componentDidMount = () => {
    this._statisticsLabWeek(this.state.weekDayState)
    this._statisticsAllLabCourse()
    this._statisticsLabCourse()
    this._statisticsLabCourseTop10()
    this.setState({
      startTime: new Date(this.fun_date(-6)),
      endTime: new Date(),
    }, () => {
      this.findStatistNum(moment(new Date(this.fun_date(-6))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
    })
  }
  _statisticsLabCourseTop10 = async () => {
    const res = await statisticsLabCourseTop10()
    const xAxis = []
    const jc_num = []
    const zb_num = []
    res.data.forEach(item => {
      xAxis.push(item.jsmc)
      jc_num.push(item.jcNum)
      zb_num.push(Math.round(item.zbNumber * 100))
    })
    this.setState({
      top10_xAxis: xAxis,
      top10_skj_num: jc_num,
      top10_zb_num: zb_num
    })
  }
  _statisticsLabCourse = async () => {
    const res = await statisticsLabCourse()
    const xAxis = []
    const skj_num = []
    const use_num = []
    res.data.forEach(item => {
      xAxis.push(item.jsmc)
      skj_num.push(item.jcNum)
      use_num.push(Math.round(item.zbNumber * 100))
    })
    this.setState({
      roomClassNum_xAxis: xAxis,
      roomClassNum_skj_num: skj_num,
      roomClassNum_use_num: use_num
    })
  }
  _statisticsAllLabCourse = async () => {
    const res = await statisticsAllLabCourse()
    this.setState({
      semesterClassNum: res.data
    })
  }

  _statisticsLabWeek = async (flag) => {
    const res = await statisticsLabWeek({ flag })
    this.setState({
      fillNum: res.data
    })
  }
  _changeDateWeek = val => {
    this.setState({
      weekDayState: val
    }, () => {
      this._statisticsLabWeek(this.state.weekDayState)
    })
  }

  // 统计数量
  findStatistNum = async (startTime, endTime) => {
    const res = await req_statistNum({ startTime, endTime })
    if (res.code === 20000) {
      this.setState({
        numData: [
          res.data.jiaoshi || 0,
          res.data.kecheng || 0,
          res.data.banji || 0,
          res.data.zuanye || 0,
          res.data.yuanxi || 0,
          res.data.xuesheng || 0,
        ],
      })
    }

  }

  // 获取几天前的日期
  fun_date = (num) => {
    var date1 = new Date()
    var date2 = new Date(date1)
    date2.setDate(date1.getDate() + num)
    //num是正数表示之后的时间，num负数表示之前的时间，0表示今天
    var time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate()
    return time2
  }

  render() {
    const { weekDayState, halfYearState, numData, startTime, endTime } = this.state
    const option1 = {
      tooltip: {
        trigger: 'item',
      },
      color: ['#33D0BD', '#FFC34E'],
      legend: {
        orient: 'horizontal',
        icon: 'circle',
        x: 'center',
        y: 'bottom',
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 16, // 设置间距
        textStyle: {  // 图例文字的样式
          color: '#666666',
          fontSize: 12
        },
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: '72%',
          center: ['50%', '46%'],
          silent: 'ture',//图形是否不响应和触发鼠标事件，默认为 false，即响应和触发鼠标事件。
          label: {
            formatter: '{name|{b}} {num|{c}节}\n{uname|占比} {scale|{d}%}',
            rich: {
              name: {
                fontSize: 12,
                color: '#666666',
                align: 'left'
              },
              uname: {
                fontSize: 12,
                color: '#666666',
                align: 'left'
              },
              num: {
                fontSize: 12,
                color: '#292C33',
                align: 'left'
              },
              scale: {
                fontSize: 12,
                color: '#292C33',
                align: 'left'
              }
            }
          },
          data: [
            { value: this.state.fillNum.yxNum, name: '已填' },
            { value: this.state.fillNum.wxNum, name: '未填' },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    }

    const option2 = {
      title: {
        text: this.state.semesterClassNum.totalNum,    // 主标题
        top: '34%',
        left: '48%',
        textStyle: {
          textAlign: 'center',
          color: '#292C33',
          fontSize: 20,
        },
        itemGap: 10,   // 主副标题间隔
        subtext: '总课时数\n（节）',   // 副标题
        subtextStyle: {
          textAlign: 'center',
          color: '#666666',
          fontSize: 12,
        },
        textAlign: 'center',
      },
      tooltip: {
        trigger: 'item',
      },
      color: ['#33D0BD', '#FFC34E'],
      legend: {
        orient: 'horizontal',
        icon: 'circle',
        x: 'center',
        y: 'bottom',
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 16, // 设置间距
        textStyle: {  // 图例文字的样式
          color: '#666666',
          fontSize: 12
        },
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['50%', '46%'],
          silent: 'true',//图形是否不响应和触发鼠标事件，默认为 false，即响应和触发鼠标事件。
          label: {
            // formatter: '{name|{b}}\n{time|{c} 小时}',
            formatter: '{name|{b}} {num|{c}节}\n{uname|占比} {scale|{d}%}',
            rich: {
              name: {
                fontSize: 12,
                color: '#666666',
                align: 'left'
              },
              uname: {
                fontSize: 12,
                color: '#666666',
                align: 'left'
              },
              num: {
                fontSize: 12,
                color: '#292C33',
                align: 'left'
              },
              scale: {
                fontSize: 12,
                color: '#292C33',
                align: 'left'
              }
            }
          },
          data: [
            { value: this.state.semesterClassNum.yyNum, name: '已排课时' },
            { value: this.state.semesterClassNum.wyNum, name: '未排课时' },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          labelLine: {
            normal: {
              length: 4,
              length2: 4,
            }
          },
        }
      ]
    }

    const option3 = {
      color: ['#5EE9D8', '#008FFF'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          itemStyle: {
            color: '#999'
          }
        }
      },
      dataZoom: [
        {
          type: "slider", // 在底部
          showDetail: false,
          show: true, // 是否显示 组件。如果设置为 false，不会显示，但是数据过滤的功能还存在。
          start: 0, // 数据窗口范围的起始百分比。范围是：0 ~ 100。表示 0% ~ 100%。
          end: 1, // 数据窗口范围的结束百分比。范围是：0 ~ 100。
          bottom: 0, // 控制滚动条距离底部的位置;
          xAxisIndex: [0],
          backgroundColor: '#fff',//滚到颜色
          fillerColor: 'rgba(0,143,255,0.3)',
        }
      ],
      legend: {
        data: ['上课数(节)', '使用率'],
        orient: 'horizontal',
        icon: 'circle',
        x: 'right',
        y: 'top',
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 16, // 设置间距
        textStyle: {  // 图例文字的样式
          color: '#666666',
          fontSize: 12
        },
      },
      xAxis: [
        {
          type: 'category',
          data: this.state.roomClassNum_xAxis,
          axisLabel: {
            formatter: function (value) {
              if (value !== undefined) {
                if (value.length > 4) {      // 超出5个字符显示省略号
                  return `${value.slice(0, 4)}...`
                }
                return value
              }
            },
            textStyle: {
              color: '#292C33', // y轴字体颜色
              fontSize: 12,
            }
          },
          axisTick: {
            show: false

          },
          axisPointer: {
            type: 'shadow'
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD',// X轴线的颜色
              width: 1 // x轴线的宽度
            }
          },
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '',
          min: 0,
          max: 1000,
          axisLabel: {
            formatter: '{value}',
            textStyle: {  // 图例文字的样式
              color: '#919499',
              fontSize: 12
            },
          },

          splitLine: {    //网格线
            lineStyle: {
              type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
            },
            show: false //隐藏或显示
          },
        },
        {
          type: 'value',
          name: '',
          min: 0,
          max: 100,
          axisLabel: {
            formatter: '{value}%',
            textStyle: {  // 图例文字的样式
              color: '#919499',
              fontSize: 12
            },
          },
          splitLine: {    //网格线
            lineStyle: {
              type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
            },
            show: false //隐藏或显示
          },
        }
      ],
      series: [
        {
          name: '上课数(节)',
          type: 'bar',
          barWidth: 20, // 设置柱子粗细
          itemStyle: {
            normal: {
              //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
              barBorderRadius: [2, 2, 0, 0],
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                formatter: '{c}',
                textStyle: { //数值样式
                  color: '#666666',
                  fontSize: 12
                }
              }
            }
          },
          tooltip: {
            valueFormatter: function (value) {
              return value + '节'
            }
          },
          data: this.state.roomClassNum_skj_num
        },
        {
          name: '使用率',
          type: 'line',
          areaStyle: {},
          smooth: true,
          symbol: 'circle',
          symbolSize: 6, //小圆点的大小
          yAxisIndex: 1,
          lineStyle: {
            width: 2,
            color: '#008FFF'
          },
          itemStyle: {
            normal: {
              color: '#008FFF', // 拐点的颜色
              borderColor: '#fff', // 拐点边框颜色
              borderWidth: 2, // 拐点边框大小
            },
          },
          tooltip: {
            valueFormatter: function (value) {
              return value + ' %'
            }
          },
          data: this.state.roomClassNum_use_num
        }
      ]
    }

    const option4 = {
      color: ['#5EE9D8', '#008FFF'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          itemStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        left: '3%',
        right: '3%',
        top: '8%',
        containLabel: true
      },
      dataZoom: [
        {
          type: "slider", // 在底部
          showDetail: false,
          show: true, // 是否显示 组件。如果设置为 false，不会显示，但是数据过滤的功能还存在。
          start: 0, // 数据窗口范围的起始百分比。范围是：0 ~ 100。表示 0% ~ 100%。
          end: 30, // 数据窗口范围的结束百分比。范围是：0 ~ 100。
          bottom: 0, // 控制滚动条距离底部的位置;
          xAxisIndex: [0],
          backgroundColor: '#fff',//滚到颜色
          fillerColor: 'rgba(0,143,255,0.3)',
        }
      ],
      legend: {
        // show: false
        data: ['上课数(节)', '占比'],
        orient: 'horizontal',
        icon: 'circle',
        x: 'right',
        y: 'top',
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 16, // 设置间距
        textStyle: {  // 图例文字的样式
          color: '#666666',
          fontSize: 12
        },
      },
      xAxis: [
        {
          type: 'category',
          data: this.state.top10_xAxis,
          axisLabel: {
            formatter: function (value) {
              if (value !== undefined) {
                if (value.length > 4) {      // 超出5个字符显示省略号
                  return `${value.slice(0, 4)}...`
                }
                return value
              }
            },
            textStyle: {
              color: '#292C33', // y轴字体颜色
              fontSize: 12,
            }
          },
          axisTick: {
            show: false

          },
          axisPointer: {
            type: 'shadow'
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD',// X轴线的颜色
              width: 1 // x轴线的宽度
            }
          },
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '',
          min: 0,
          max: 600,
          axisLabel: {
            formatter: '{value}',
            textStyle: {  // 图例文字的样式
              color: '#919499',
              fontSize: 12
            },
          },

          splitLine: {    //网格线
            lineStyle: {
              type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
            },
            show: false //隐藏或显示
          },
        },
        {
          type: 'value',
          name: '',
          min: 0,
          max: 100,
          axisLabel: {
            formatter: '{value}%',
            textStyle: {  // 图例文字的样式
              color: '#919499',
              fontSize: 12
            },
          },
          splitLine: {    //网格线
            lineStyle: {
              type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
            },
            show: false //隐藏或显示
          },
        }
      ],
      series: [
        {
          name: '上课数(节)',
          type: 'bar',
          barWidth: 20, // 设置柱子粗细
          itemStyle: {
            normal: {
              //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
              barBorderRadius: [2, 2, 0, 0],
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                formatter: '{c}',
                textStyle: { //数值样式
                  color: '#666666',
                  fontSize: 12
                }
              }
            }
          },
          tooltip: {
            valueFormatter: function (value) {
              return value + ' 节'
            }
          },
          data: this.state.top10_jc_num
        },
        {
          name: '占比',
          type: 'line',
          areaStyle: {},
          smooth: true,
          symbol: 'circle',
          symbolSize: 6, //小圆点的大小
          yAxisIndex: 1,
          lineStyle: {
            width: 2,
            color: '#008FFF'
          },
          itemStyle: {
            normal: {
              color: '#008FFF', // 拐点的颜色
              borderColor: '#fff', // 拐点边框颜色
              borderWidth: 2, // 拐点边框大小
            },
          },
          tooltip: {
            valueFormatter: function (value) {
              return value + ' %'
            }
          },
          data: this.state.top10_zb_num
        }
      ]
    }

    const option5 = {
      color: ['#5EE9D8', '#008FFF'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          itemStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        left: '3%',
        right: '3%',
        top: '8%',
        bottom: '8%',
        containLabel: true
      },
      legend: {
        data: ['任课教师', '上课课程', '上课班级', '上课专业', '上课院系', '上课人次'],
        orient: 'horizontal',
        icon: 'circle',
        x: 'right',
        y: 'top',
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 16, // 设置间距
        textStyle: {  // 图例文字的样式
          color: '#666666',
          fontSize: 12
        },
      },
      xAxis: [
        {
          type: 'category',
          data: ['任课教师', '上课课程', '上课班级', '上课专业', '上课院系', '上课人次'],
          axisLabel: {
            interval: 0, //使x轴文字显示全
            textStyle: {
              color: '#292C33', // y轴字体颜色
              fontSize: 12,
            }
          },
          axisTick: {
            show: false

          },
          axisPointer: {
            type: 'shadow'
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD',// X轴线的颜色
              width: 1 // x轴线的宽度
            }
          },
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '',
          axisLabel: {
            formatter: '{value}',
            textStyle: {  // 图例文字的样式
              color: '#919499',
              fontSize: 12
            },
          },

          splitLine: {    //网格线
            lineStyle: {
              type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
            },
            show: false //隐藏或显示
          },
        },
      ],
      series: [
        {
          type: 'bar',
          barWidth: 20, // 设置柱子粗细
          itemStyle: {
            normal: {
              //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
              barBorderRadius: [2, 2, 0, 0],
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                formatter: '{c}',
                textStyle: { //数值样式
                  color: '#666666',
                  fontSize: 12
                }
              }
            }
          },
          data: numData
        }
      ]
    }

    return (
      <div className='stat'>
        <div className='stat_card2'>
          <div className='stat_card2_head'>
            <div className='stat_card2_time'>
              <DatePicker
                mode="date"
                title="开始时间"
                extra="开始时间"
                value={startTime}
                maxDate={endTime ? endTime : null}
                onChange={date => {
                  this.setState({
                    startTime: date
                  }, () => {
                    this.findStatistNum(moment(this.state.startTime).format('YYYY-MM-DD'), moment(this.state.endTime).format('YYYY-MM-DD'))
                  })
                }}
              >
                <List.Item arrow="horizontal"></List.Item>
              </DatePicker>
              <div style={{ background: '#f4f6f8' }}>~</div>
              <DatePicker
                mode="date"
                title="结束时间"
                extra="结束时间"
                value={endTime}
                minDate={startTime ? startTime : null}
                onChange={date => {
                  this.setState({
                    endTime: date
                  }, () => {
                    this.findStatistNum(moment(this.state.startTime).format('YYYY-MM-DD'), moment(this.state.endTime).format('YYYY-MM-DD'))
                  })
                }}
              >
                <List.Item arrow="horizontal"></List.Item>
              </DatePicker>
            </div>
          </div>
          <div className='stat_card_head'>
            <div className='stat_card_head_title'>
              <div></div>
              <div>实验室使用统计</div>
            </div>
            <div></div>
          </div>
          <div className='stat_pie' style={{ padding: '0px' }}>
            <ReactEcharts option={option5} style={{ height: '100%', width: '100%' }} />
          </div>
        </div>
        <div className='stat_card'>
          <div className='stat_card_head'>
            <div className='stat_card_head_title'>
              <div></div>
              <div>实验室记录统计</div>
            </div>
            <div className='stat_card_radio'>
              <div className={weekDayState === 1 ? 'stat_card_radio_checked' : ''} onClick={() => this._changeDateWeek(1)}>当日</div>
              <div className={weekDayState === 2 ? 'stat_card_radio_checked' : ''} onClick={() => this._changeDateWeek(2)}>本周</div>
            </div>
          </div>
          <div className='stat_pie'>
            <ReactEcharts option={option1} style={{ height: '100%', width: '100%' }} />
          </div>
        </div>
        <div className='stat_card2'>
          <div className='stat_card_head'>
            <div className='stat_card_head_title'>
              <div></div>
              <div>实验室上课数以及使用率</div>
            </div>
            <div className='stat_card_radio'>
              <div className={halfYearState === 1 ? 'stat_card_radio_checked' : ''} onClick={() => {
                this.setState({
                  halfYearState: 1
                })
              }}>本学期</div>
            </div>
          </div>
          <div className='stat_pie'>
            <ReactEcharts option={option2} style={{ height: '100%', width: '100%' }} />
          </div>
          <div className='stat_subtitle'>
            <div>
              <img src={require('../../../images/20.png')} alt='' />
            </div>
            <div>每间实验室上课数及使用率</div>
          </div>
          <div className='stat_bar'>
            <ReactEcharts option={option3} style={{ height: '100%', width: '100%' }} />
          </div>
          <div className='stat_subtitle'>
            <div>
              <img src={require('../../../images/21.png')} alt='' />
            </div>
            <div>实验室使用前10排名</div>
          </div>
          <div className='stat_bar'>
            <ReactEcharts option={option4} style={{ height: '100%', width: '100%' }} />
          </div>
        </div>
      </div >)
  }
}
export default useState