import React, { Component } from 'react'
import { Modal, Icon, Toast } from 'antd-mobile'
import { req_listDeviceByRoomId, req_getRelayRoomById, issueKgZnkzq, issueAllKgZnkzq } from '../../../api/api'
import './controller.less'
const alert = Modal.alert;

class ControllerSwitch extends Component {

  state = {
    data: [], // 所有设备
    numData: null,//统计数量
    fetchSuccNum: 0,//需要操作的命令数
    choosedAirSwitchItemList: [],//需要操作的内容
    cmdTypeTitle: '',
    modal: false,
  }

  componentDidMount = () => {
    document.title = "智能终端-设备列表"
    this.fetch(this.props.match.params.id)
    this.getRelayRoomById(this.props.match.params.id)
  }

  fetch = async roomId => {
    const res = await req_listDeviceByRoomId({ roomId })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  // 查询设备状态数量
  getRelayRoomById = async roomId => {
    const res = await req_getRelayRoomById({ roomId })
    if (res.code === 20000) {
      res.data.count = res.data.devNum3 + res.data.devNum4
      this.setState({
        numData: res.data
      })
    }
  }

  // 单个开关
  handleChangeSwitch = async item => {
    const { numData, data } = this.state
    if (item.relay_state === 1) {
      alert('提示', item.device_state === 1 ? '确定关闭吗?' : '确定开启吗!', [
        {
          text: '取消', onPress: () => {

          }
        },
        {
          text: '确定', onPress: async () => {
            Toast.loading("正在执行中...")
            const res = await issueKgZnkzq({ relayId: item.relay_id, deviceNumber: item.device_number, deviceState: item.device_state === 1 ? 2 : 1 })
            if (res.code === 20000) {
              Toast.success(item.device_state === 1 ? '关闭成功!' : '开启成功!')
              // 手动改统计数量
              if (item.device_state === 2) {
                // 开
                numData.devNum1 = numData.devNum1 + 1
                numData.devNum2 = numData.devNum2 - 1
              } else {
                // 关
                numData.devNum1 = numData.devNum1 - 1
                numData.devNum2 = numData.devNum2 + 1
              }
              this.setState({
                numData,
                // 手动改列表状态
                data: data.map(val => {
                  if (item.device_id === val.device_id) {
                    val.device_state = val.device_state === 1 ? 2 : 1
                  }
                  return val;
                })
              })
            }
          }
        },
      ]);



    } else {
      Toast.info("离线设备无法操作!")
    }

  }

  // 一键开关
  handleControllerSwitch = async (relayState) => {
    alert('提示', relayState === 1 ? '确定一键开启吗?' : '确定一键关闭吗!', [
      {
        text: '取消', onPress: () => {

        }
      },
      {
        text: '确定', onPress: async () => {
          const { data } = this.state
          const newData = data.filter(x => x.relay_state === 1)
          if(newData.length===0){
            Toast.info("所有设备都离线，无法操作!")
            return false
          }
          newData.map((item, index) => {
            item.status = 0
            setTimeout(async () => {
              this.handleChangeSwitchOne(item, relayState)
            }, 1000 + index * 1000)
            return item
          })

          this.setState({
            modal: true,
            cmdTypeTitle: relayState === 1 ? '开启' : '关闭',
            choosedAirSwitchItemList: newData,
            fetchSuccNum: 0
          })
        }
      }])

  }

  // 单个执行命令
  handleChangeSwitchOne = async (item, state) => {
    const { numData, data } = this.state
    const res = await issueKgZnkzq({ relayId: item.relay_id, deviceNumber: item.device_number, deviceState: state })
    if (res.code === 20000) {
      // 手动改统计数量
      if (state === 1) {
        // 开
        if (item.device_state === 2) {
          numData.devNum1 = numData.devNum1 + 1
          numData.devNum2 = numData.devNum2 - 1
        }
      } else {
        // 关
        if (item.device_state === 1) {
          numData.devNum1 = numData.devNum1 - 1
          numData.devNum2 = numData.devNum2 + 1
        }
      }
      this.setState({
        fetchSuccNum: this.state.fetchSuccNum + 1,
        numData,
        // 手动改列表状态
        data: data.map(val => {
          if (item.device_id === val.device_id) {
            val.status = 1
            val.message = res.message
            val.device_state = state
          }
          return val;
        })
      })
    }
  }

  onClose = key => () => {
    if (this.state.fetchSuccNum !== this.state.choosedAirSwitchItemList.length) {
      Toast.info('命令正在执行中！')
      return false
    }
    this.setState({
      modal: false,
    }, () => {
      this.setState({
        fetchSuccNum: 0,
        choosedAirSwitchItemList: []
      })
    })
  }

  // 操作记录
  handleOperate = (e, item) => {
    e.stopPropagation()
    this.props.history.push(`/module/controllerSwitch/controllerOperation/${this.state.controllerSwitch.relay_mac}/${item.device_number}`)
  }

  // 颜色样式
  itemStyle = (item) => {
    if (item.relay_state === 1) {
      // 在线
      if (item.device_state === 1) {
        return 'cont_switch_on'
      } else if (item.device_state === 2) {
        return 'cont_switch_off'
      }
    } else {
      // 离线
      return 'cont_switch_offline'
    }
  }

  handleRoomName = (numData) => {
    if (numData) {
      return numData.building_name + '/' + numData.floor_name + '/' + (numData.room_number ? numData.room_number + '/' + numData.room_name : numData.room_name)
    } else {
      return '--'
    }
  }

  render() {
    const { numData, data, fetchSuccNum, choosedAirSwitchItemList, cmdTypeTitle } = this.state
    return (
      <div className="cont_switch">
        <div className='cont_switch_head'>
          <div className='cont_switch_banner'>
            <div className='cont_switch_roomName'>
              {this.handleRoomName(numData)}
            </div>
            <div className='cont_switch_row'>
              <div className='cont_switch_row_item'>
                <div>{numData ? numData.count : 0}</div>
                <div>总设备数</div>
              </div>
              <div className='cont_switch_row_right'>
                <div className='cont_switch_row_item'>
                  <div>{numData ? numData.devNum3 : 0}</div>
                  <div>在线</div>
                </div>
                <div className='cont_switch_row_item'>
                  <div>{numData ? numData.devNum4 : 0}</div>
                  <div>离线</div>
                </div>
                <div className='cont_switch_row_item'>
                  <div>{numData ? numData.devNum1 : 0}</div>
                  <div>已开启</div>
                </div>
                <div className='cont_switch_row_item'>
                  <div>{numData ? numData.devNum2 : 0}</div>
                  <div>已关闭</div>
                </div>
              </div>
            </div>
          </div>
          <div className='cont_switch_legend'>
            <div className='cont_switch_legend_item'>
              <div>
                <img src={require('./../../../img/controll/7.png')} alt='' />
              </div>
              <div>已关闭</div>
            </div>
            <div className='cont_switch_legend_item'>
              <div>
                <img src={require('./../../../img/controll/6.png')} alt='' />
              </div>
              <div>已开启</div>
            </div>
            <div className='cont_switch_legend_item'>
              <div>
                <img src={require('./../../../img/controll/8.png')} alt='' />
              </div>
              <div>离线</div>
            </div>
          </div>
        </div>

        <div className='cont_switch_list_main'>
          <div className='cont_switch_list'>
            {
              data.map(item => {
                return <div className='cont_switch_list_item' key={item.device_id}>
                  <div className={this.itemStyle(item)} onClick={() => {
                    this.handleChangeSwitch(item)
                  }}>
                    {item.device_name}
                  </div>
                </div>
              })
            }

          </div>
        </div>

        <div className='cont_switch_but_width'>
          <div onClick={() => {
            this.handleControllerSwitch(2)
          }}>一键关闭</div>
          <div onClick={() => {
            this.handleControllerSwitch(1)
          }}>一键开启</div>
        </div>

        <Modal
          className='modal_cont_wrap'
          closable
          visible={this.state.modal}
          transparent
          maskClosable={false}
          onClose={this.onClose('modal')}
          title={`一键${cmdTypeTitle}`}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div style={{ maxHeight: 310, overflow: 'auto', marginRight: '-10px' }}>
            {fetchSuccNum === choosedAirSwitchItemList.length ? <p className='totalStatus'>命令执行完毕！</p> : <p className='totalStatus'>命令正在执行中...</p>}
            <div style={{ marginRight: '15px' }}>
              {choosedAirSwitchItemList.length ?
                choosedAirSwitchItemList.map((item, k) => {
                  return <div className='modal_cont_wrap_item' key={item.device_id}>
                    <div className='sortNum'>{k + 1}</div>
                    <div className='sortDesc'>
                      <p>{item.device_name}</p>
                      <div><span className='process' style={{ width: `${item.status ? 100 : 0}%` }}></span></div>
                      <span>{item.message || '命令执行中...'}</span>
                    </div>
                    {item.status === 1 ? <img className='sortStatus' src={require('../../../images/open_success_icon.png')} alt="" /> : <div className='sortStatus'></div>}
                  </div>
                }) : <Icon type='loading' size='lg' />}
            </div>
          </div>
        </Modal>

      </div>

    )
  }
}

export default ControllerSwitch