import React, { Component } from 'react'
import { InputItem, Toast } from 'antd-mobile'
import { Radio } from 'antd'
import { authUser } from '../../api/api'
import { createForm } from 'rc-form';
import cookie from 'react-cookies'
import './attestation.less'

class Attestation extends Component {

  state = {
    flag: 1,
  }

  validateIdCard = (rule, value, callback) => {
    if (value && value.length === 6) {
      callback();
    } else {
      callback(new Error('请输入身份证后六位'));
    }
  }

  // 确认
  handleSubmit = async () => {
    const { form } = this.props
    const { flag } = this.state
    form.validateFields(async (error, value) => {
      if (!error) {
        value.openId = cookie.load('openId')
        value.flag = flag
        const res = await authUser(value)
        if (res.code === 20000) {
          cookie.save('token', res.data.token)
          cookie.save('status', res.data.state)
          cookie.save('userData', res.data)
          this.props.history.push('/home')
        }
      } else {
        if (error.idCard) {
          Toast.info(error.idCard.errors[error.idCard.errors.length - 1].message)
        } else {
          Toast.info("请补全相关信息")
        }
      }
    })
  }

  render() {
    const { form } = this.props;
    const { flag } = this.state
    return (
      <div className='attestation'>
        <div className='attestation-con'>
          <div className='attestation-con-title'>身份类型</div>
          <div className='attestation-con-check'>
            <div className={flag === 1 ? 'attestation-con-checked' : ''} onClick={() => {
              this.setState({
                flag: 1,
              })
            }}>我是教师</div>
            <div className={flag === 2 ? 'attestation-con-checked' : ''} onClick={() => {
              this.setState({
                flag: 2,
              })
            }}>我是学生</div>
          </div>

          <div className='attestation-con-list'>
            <InputItem
              className="attestation-con-number"
              placeholder="请输入您的学号/工号"
              {...form.getFieldProps('zh', {
                rules: [{ required: true }]
              })}
            >
              学号工号
            </InputItem>
            <InputItem
              className="attestation-con-number"
              placeholder="请输入身份证后六位"
              {...form.getFieldProps('idCard', {
                rules: [
                  { required: true },
                  { validator: this.validateIdCard }
                ]
              })}
              error={!!form.getFieldError('idCard')}
              onErrorClick={() => {
                Toast.info(form.getFieldError('idCard'), 2);
              }}
            >
              身份证
            </InputItem>
          </div>
          <div className='attestation-btn' onClick={() => this.handleSubmit()}>提交实名信息</div>
        </div>
      </div>
    )
  }
}

export default createForm()(Attestation);
