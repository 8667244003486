import React, { Component } from 'react'
import { ImagePicker, TextareaItem, Steps, Toast, Button, Modal } from 'antd-mobile'
import { findOrderByOrderId, saveApprovalRecord } from '../../../../api/repairApi'
import IP from '../../../../config/config'
import cookie from 'react-cookies'
import moment from 'moment'
import './index.less'

const Step = Steps.Step;

class ApprovalDetail extends Component {

  state = {
    repairInfo: {}, // 报修详情
    processInfo: [], // 工单流程
    reason: '', // 审批意见
    visible: false,
    base64: '',
    width: window.innerWidth,
    height: window.innerHeight,
    visible2: false,
    index: 1, // 审批状态 1通过 0驳回
  }

  componentDidMount = () => {
    this.fetch(this.props.match.params.id)
  }

  fetch = async orderId => {
    const res = await findOrderByOrderId({ orderId })
    if (res.code === 20000) {
      this.setState({
        repairInfo: res.data ? (res.data.repairOrderEntity || {}) : {},
        processInfo: res.data ? (res.data.approvalRecordEntities || []) : [],
      })
    }
  }

  // 审批
  handleApprove = index => {
    const { reason } = this.state
    if (!reason) return Toast.info('请填写审批意见')
    this.setState({ visible: true, index });
    setTimeout(() => {
      eval("PageReady()")
    }, 500)
  }

  // 清除签字
  handleClearBtnClick = () => {
    eval('clearCanvas()')
  }

  // 签字确认
  handleOk = () => {
    const data = eval('saveSignature()');
    if (!this.isCanvasBlank()) {
      this.setState({
        visible2: false
      })
      this.rotateBase64Img(data, 270)
    } else {
      this.setState({
        visible2: false,
        base64: '',
      }, () => {
        Toast.fail('签字不能为空！')
      })
    }
  }

  // 审批确认
  handleSubmit = () => {
    const { base64, index } = this.state
    if (!base64) return Toast.fail('签字不能为空！')
    this.saveApprovalRecord(index, base64)
  }

  saveApprovalRecord = async (index, base64) => {
    const { repairInfo, reason } = this.state
    var data1 = {
      order_id: repairInfo.order_id,
      approval_state: index,
      approval_number: cookie.load('userData').id,
      approval_id: cookie.load('userData').id,
      approval_name: cookie.load('userData').name,
      order_user_id: repairInfo.user_id ? repairInfo.user_id : '',
      order_department_id: repairInfo.department_id ? repairInfo.department_id : '',
      config_id: repairInfo.approval_config_id,
      refuse_reason: reason,
      base64String: base64,
    }
    Toast.loading('上传中！', 0)
    const res = await saveApprovalRecord(data1)
    if (res.code === 20000) {
      Toast.hide()
      Toast.success(res.message)
      this.props.history.goBack()
    }
  }

  // 确认画布是否有更新
  isCanvasBlank = () => {
    const canvas = document.querySelector('.js-signature > canvas');
    var blank = document.createElement('canvas');//系统获取一个空canvas对象
    blank.width = canvas.width;
    blank.height = canvas.height;
    return canvas.toDataURL() === blank.toDataURL();//比较值相等则为空
  }

  rotateBase64Img = (src, edg, callback) => {
    const This = this
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var imgW;//图片宽度
    var imgH;//图片高度
    var size;//canvas初始大小
    if (edg % 90 != 0) {
      console.error("旋转角度必须是90的倍数!");
      throw '旋转角度必须是90的倍数!';
    }
    (edg < 0) && (edg = (edg % 360) + 360)
    const quadrant = (edg / 90) % 4; //旋转象限
    const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 }; //裁剪坐标
    var image = new Image();
    image.crossOrigin = "anonymous"
    image.src = src;
    image.onload = function () {
      imgW = image.width;
      imgH = image.height;
      size = imgW > imgH ? imgW : imgH;
      canvas.width = size * 2;
      canvas.height = size * 2;
      switch (quadrant) {
        case 0:
          cutCoor.sx = size;
          cutCoor.sy = size;
          cutCoor.ex = size + imgW;
          cutCoor.ey = size + imgH;
          break;
        case 1:
          cutCoor.sx = size - imgH;
          cutCoor.sy = size;
          cutCoor.ex = size;
          cutCoor.ey = size + imgW;
          break;
        case 2:
          cutCoor.sx = size - imgW;
          cutCoor.sy = size - imgH;
          cutCoor.ex = size;
          cutCoor.ey = size;
          break;
        case 3:
          cutCoor.sx = size;
          cutCoor.sy = size - imgW;
          cutCoor.ex = size + imgH;
          cutCoor.ey = size + imgW;
          break;
      }
      ctx.translate(size, size);
      ctx.rotate(edg * Math.PI / 180);
      ctx.drawImage(image, 0, 0);
      var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
      if (quadrant % 2 == 0) {
        canvas.width = imgW;
        canvas.height = imgH;
      } else {
        canvas.width = imgH;
        canvas.height = imgW;
      }
      ctx.putImageData(imgData, 0, 0);

      //获取旋转后的base64图片
      This.setState({
        base64: canvas.toDataURL(),
      })
    };
  }

  render() {
    const { repairInfo, processInfo, base64, width, height, visible, visible2 } = this.state
    return (
      <div className='repair_approval_detail'>
        <div className='repair_approval_detail_list'>
          <div className='r_a_d_l_title'>用户姓名</div>
          <div className='r_a_d_l_desc'>{repairInfo.user_name}</div>
        </div>
        <div className='repair_approval_detail_list'>
          <div className='r_a_d_l_title'>联系方式</div>
          <div className='r_a_d_l_desc'>{repairInfo.user_phone_number}</div>
        </div>
        <div className='repair_approval_detail_list'>
          <div className='r_a_d_l_title'>维修地点</div>
          <div className='r_a_d_l_desc'>{(repairInfo.building_name ? repairInfo.building_name : '') + ' ' + (repairInfo.room_name ? repairInfo.room_name : '')}</div>
        </div>
        <div className='repair_approval_detail_list'>
          <div className='r_a_d_l_title'>故障类型</div>
          <div className='r_a_d_l_desc'>{repairInfo.fault_type_name}</div>
        </div>
        <div className='repair_approval_detail_list'>
          <div className='r_a_d_l_title'>设备名称</div>
          <div className='r_a_d_l_desc'>{repairInfo.equipment_name}</div>
        </div>
        <div className='repair_approval_detail_list'>
          <div className='r_a_d_l_title'>设备编号</div>
          <div className='r_a_d_l_desc'>{repairInfo.equipment_number}</div>
        </div>
        <div className='repair_approval_detail_list'>
          <div className='r_a_d_l_title'>维修费用</div>
          <div className='r_a_d_l_desc'>{repairInfo.money || 0}</div>
        </div>
        <div className='repair_approval_detail_desc'>
          <div className='repair_approval_detail_desc_title'>问题描述</div>
          <div className='repair_approval_detail_desc_con'>{repairInfo.description}</div>
          <div className='repair_approval_detail_desc_img'>
            <ImagePicker
              className="details-upload"
              disableDelete={true}
              selectable={false}
              files={repairInfo.pic_url ? (repairInfo.pic_url.split(',').map((item, index) => { return { url: IP.fileHost + item, id: index } })) : []}
            />
          </div>
        </div>
        {
          cookie.load('userData').id === repairInfo.next_user_id ? <div className='repair_approval_detail_desc'>
            <div className='repair_approval_detail_desc_title'>审批意见</div>
            <div className='repair_approval_detail_desc_con'>
              <TextareaItem
                rows={3}
                placeholder="请输入审批意见"
                onChange={e => this.setState({ reason: e })}
              />
            </div>
          </div> : ''
        }
        <div className='repair_approval_detail_desc'>
          <div className='repair_approval_detail_desc_title'>工单流程</div>
          <div className='repair_approval_process'>
            {
              (processInfo || []).length ? <Steps size="small" current={processInfo.length}>
                {
                  processInfo.map((item, index) => <Step
                    key={index}
                    icon={
                      item.state === 2 ? <img alt='' src={require('../../../../images/new/25.png')} /> : <img alt='' src={require('../../../../images/new/24.png')} />
                    }
                    title={
                      <div className='step_title'>
                        <span>审批人：{item.approval_name}</span>
                        {
                          item.approval_state === 1 ? <span className='step_title_tg'>通过</span> : (
                            item.approval_state === 0 ? <span className='step_title_jj'>拒绝</span> : ''
                          )
                        }
                      </div>
                    }
                    description={
                      <div className='step_desc'>
                        <div className='step_desc_time'>
                          {
                            item.approval_time ? (`审批时间：${moment(item.approval_time).format('YYYY-MM-DD HH:mm:ss')}`) : ''
                          }
                        </div>
                        {/* {
                          item.refuse_reason ? <div className='step_desc_bh'>{`审批意见：${item.refuse_reason}`}</div> : ''
                        } */}
                        {
                          item.sign_url ? <div className='step_desc_qm'>
                            <div>审批签名：</div>
                            <img alt='' src={IP.fileHost + item.sign_url} />
                          </div> : ''
                        }
                      </div>
                    } />)
                }
              </Steps> : <div style={{ textAlign: 'center' }}>--暂无审批--</div>
            }
            <Steps size="small" current={3}>
              {
                repairInfo.order_state >= 1 && repairInfo.order_state !== 2 ? <Step
                  icon={
                    repairInfo.work_name ? <img alt='' src={require('../../../../images/new/24.png')} /> : <img alt='' src={require('../../../../images/new/25.png')} />
                  }
                  title={
                    <div className='step_title'>
                      <span>维修人：{repairInfo.work_name ? repairInfo.work_name : '待指派'}</span>
                    </div>
                  }
                  description={
                    <div className='step_desc'>
                      <div className='step_desc_time'>
                        {
                          repairInfo.work_name && repairInfo.appoint_time ? (`指派时间：${moment(repairInfo.appoint_time).format('YYYY-MM-DD HH:mm:ss')}`) : ''
                        }
                      </div>
                    </div>
                  }
                /> : ''
              }
              {
                repairInfo.order_state >= 1 && repairInfo.order_state !== 2 && repairInfo.work_name ? <Step
                  icon={
                    repairInfo.order_state >= 3 ? <img alt='' src={require('../../../../images/new/24.png')} /> : <img alt='' src={require('../../../../images/new/25.png')} />
                  }
                  title={
                    <div className='step_title'>
                      <span>维修状态：{repairInfo.order_state >= 3 ? '已维修' : '待维修'}</span>
                    </div>
                  }
                  description={
                    <div className='step_desc'>
                      <div className='step_desc_time'>
                        {
                          repairInfo.order_state >= 3 && repairInfo.end_time ? (`维修时间：${moment(repairInfo.end_time).format('YYYY-MM-DD HH:mm:ss')}`) : ''
                        }
                      </div>
                    </div>
                  }
                /> : ''
              }
              {
                repairInfo.order_state >= 3 ? <Step
                  icon={
                    repairInfo.order_state === 4 ? <img alt='' src={require('../../../../images/new/24.png')} /> : <img alt='' src={require('../../../../images/new/25.png')} />
                  }
                  title={
                    <div className='step_title'>
                      <span>工单状态：{repairInfo.order_state === 4 ? '已完成' : '待确认'}</span>
                    </div>
                  }
                  description={<div></div>}
                /> : ''
              }
            </Steps>
          </div>
        </div>
        {
          cookie.load('userData').id === repairInfo.next_user_id ? <div className='approveDetail_btn'>
            <div className='approveDetail_btn_list' onClick={() => this.handleApprove(0)}>审核驳回</div>
            <div className='approveDetail_btn_list' onClick={() => this.handleApprove(1)}>审核通过</div>
          </div> : ''
        }
        <Modal
          visible={visible}
          transparent
          onClose={() => this.setState({ visible: false })}
          title={<div style={{ textAlign: 'left' }}>审批签字</div>}
          style={{ width: '90%', }}
        >
          <div>
            <div className='imageModal'>
              {
                base64 ? <img src={base64} alt="" width={`${width * 0.9 - 40}px`} style={{ width: `${width * 0.9 - 40}px` }} /> : <div style={{ lineHeight: '150px', textAlign: 'center', fontSize: '18px', fontWeight: 'bolder', color: '#b1b0b0', minHeight: '150px' }}
                  onClick={() => {
                    this.setState({ visible2: true })
                    setTimeout(() => {
                      eval("PageReady()")
                    }, 500)
                  }}>点击签字</div>
              }
            </div>
            <div className='autograph_btn'>
              <div className='approveDetail_btn_list' onClick={() => this.setState({ base64: '' })}>重签</div>
              <div className='approveDetail_btn_list' onClick={() => this.handleSubmit()}>确认</div>
            </div>
          </div>
        </Modal>

        <Modal
          key="dadsfsdg"
          visible={visible2}
          transparent
          onClose={() => this.setState({ visible2: false })}
          style={{ width: '100%', height: '100%', background: '#fff' }}
        >
          <div>
            <section className="signature-component" >
              <div id='signature-pad' className="js-signature" data-width={width * 0.7} data-height={height * 0.8} data-border="1px solid black" data-line-color="#000" data-auto-fit="true"></div>
            </section>
            <div className='sign_btn' style={{ height: `${height * 0.2 - 60}px` }}>
              <div className='approveDetail_btn_list' style={{ width: `${height * 0.2 - 60}px` }} onClick={() => this.handleClearBtnClick()}>清除</div>
              <div className='approveDetail_btn_list' style={{ width: `${height * 0.2 - 60}px` }} onClick={() => this.handleOk()}>确认</div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default ApprovalDetail
