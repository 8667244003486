import React, { Component } from 'react'
import { findRkInfoById } from '../../../../api/gas'
import moment from 'moment'
import './record.less'

class deviceDetail extends Component {

  state = {
    data: {},
    visible: false,
    opinion: '',
  }

  componentDidMount = () => {
    document.title = '入库详情'
    window.scrollTo(0, 0)
    this.fetch(this.props.match.params.id)
  }

  fetch = async rkdh => {
    const res = await findRkInfoById({ rkdh })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }


  render() {
    const { data } = this.state
    return (
      <div className='approveDetail' style={{ padding: '0px 0px 76px' }}>
        <div className={data.rkzt > 2 && data.rkzt !== 8 ? 'gas_warehousing_record_head' : 'gas_warehousing_record_head gas_warehousing_record_head_no'}>
          <div className='gas_warehousing_record_left'>
            <div className='gas_warehousing_record_left_title'>
              {data.xymc}-{data.zxmc}-{data.jsmc}
            </div>
            <div className='gas_warehousing_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='gas_warehousing_list_head_date'>
                {data.xymc}
              </div>
              <div className='gas_warehousing_list_head_seat'>气体种类{data.rkzls}种</div>
            </div>
          </div>
          <div className='gas_warehousing_record_right'>
            {data.rkzt === 1 ? (<img src={require('./../../../../images/chemicals/06.png')} style={{ width: '90px' }} alt='' />) : ('')}
            {data.rkzt === 2 ? (<img src={require('./../../../../images/chemicals/07.png')} style={{ width: '90px' }} alt='' />) : ('')}
            {data.rkzt > 2 && data.rkzt !== 8 ? (<img src={require('./../../../../images/chemicals/08.png')} style={{ width: '90px' }} alt='' />) : ('')}
          </div>
        </div>
        <div className='gas_warehousing_record_cont'>
          <div className='gas_warehousing_record_bar'>
            <div className='gas_warehousing_record_item'>
              <div>入库单号</div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {data.rkdh}
              </div>
            </div>
            <div className='gas_warehousing_record_item'>
              <div>申请所属学院</div>
              <div>{data.xymc}</div>
            </div>
            <div className='gas_warehousing_record_item'>
              <div>所属中心</div>
              <div>{data.zxmc}</div>
            </div>
            <div className='gas_warehousing_record_item'>
              <div>所属实验室</div>
              <div>{data.jsmc}</div>
            </div>
            <div className='gas_warehousing_record_item'>
              <div>入库人</div>
              <div>{data.rksqrxm}</div>
            </div>
          </div>


          <div className='gas_warehousing_record_bar'>
            <div className='gas_warehousing_record_item'>
              <div>申请人</div>
              <div>
                {data.rksqrxm}
              </div>
            </div>
            <div className='gas_warehousing_record_item'>
              <div>备注</div>
              <div>
                {data.rkms}
              </div>
            </div>
          </div>

          <div className='gas_warehousing_card'>
            {data.rkWhpInfoEntities && data.rkWhpInfoEntities.map((item, key) => {
              return (
                <div className='gas_warehousing_card_item' style={{ background: '#fff' }} key={key}>
                  <div className='gas_warehousing_card_head'>
                    <div className='gas_warehousing_card_head_left'>
                      <div>{key + 1}</div>
                      <div>{item.gas_name}</div>
                    </div>
                    <div className='gas_warehousing_card_class'>
                    </div>
                  </div>
                  <div className='gas_warehousing_card_unit'>
                    <div>{item.bottle_number}</div>
                    <div>共 {item.bottle_capacity}L</div>
                    <div>当前量 {item.gas_number}L</div>
                  </div>
                  <div className='gas_manage_list_item_tag'>
                    {item.gas_type_name}
                  </div>


                </div>
              )
            })}


          </div>

          <div className='gas_warehousing_order_order'>
            <div className='gas_warehousing_order_order_title'>
              审批流程
            </div>
            <div className='gas_warehousing_order_order_cont'>
              {
                data && data.approvalProcessRecordEntities && data.approvalProcessRecordEntities.map((item, key) => {
                  return (
                    <div className='gas_warehousing_order_order_item' key={key}>
                      <div className='gas_warehousing_order_order_item_left'>
                        <div className='gas_warehousing_order_order_item_icon'>
                          {item.pr_approval_result === 0 ? (<img src={require('./../../../../images/chemicals/12.png')} alt='' />) : ''}
                          {item.pr_approval_result === 1 ? (<img src={require('./../../../../images/chemicals/11.png')} alt='' />) : ''}
                          {item.pr_approval_result === 2 ? (<img src={require('./../../../../images/chemicals/10.png')} alt='' />) : ''}

                        </div>
                        <div className='gas_warehousing_order_order_item_line'>

                        </div>
                      </div>
                      <div className='gas_warehousing_order_order_item_right'>
                        <div className='gas_warehousing_order_order_item_name'>
                          <div>
                            {item.pr_approval_username ? item.pr_approval_username + '(' + item.pr_auth_name + ')' : item.pr_auth_name}
                          </div>
                          <div>{item.pr_approval_result !== 0 ? moment(item.pr_submit_time).format('YYYY-MM-DD HH:mm') : ''}</div>
                        </div>
                        {item.pr_approval_result === 0 ? (<div className='gas_warehousing_order_status'>
                          未审批
                        </div>) : ''}
                        {item.pr_approval_result === 1 ? (<div className='gas_warehousing_order_status_wait'>
                          待审批
                        </div>) : ''}
                        {item.pr_approval_result === 2 ? (<div className='gas_warehousing_order_status_pass_through'>
                          已审批
                        </div>) : ''}
                      </div>
                    </div>
                  )
                })
              }

            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default deviceDetail