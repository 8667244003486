import React, { Component } from 'react'
import { Tabs } from 'antd-mobile'
import { req_listMyApproval } from '../../../api/cgyy'
import moment from 'moment'
import './cgyy_my.css'

export default class componentName extends Component {
  state = {
    visible: false,
    data: [],
    type: 1
  }

  componentDidMount = () => {
    document.title = "我的审批"
    this.fetch()
  }

  fetch = async () => {
    const { type } = this.state
    const res = await req_listMyApproval({ type })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  render () {
    const { type, data } = this.state
    const tabs = [
      { title: '待审批', value: 1 },
      { title: '审批成功', value: 2 },
      { title: '审批驳回', value: 3 },
    ]
    return (
      <div className="cgyy_my">
        <Tabs tabs={tabs}
          initialPage={0}
          onChange={(tab) => {
            this.setState({
              type: tab.value
            }, () => {
              this.fetch()
            })
          }}
        >
        </Tabs>

        {
          data.length ?
            (data || []).map((item, key) => {
              return (
                <div className='cgyy_my_item' key={key} onClick={() => {
                  this.props.history.push(`/module/cgyy/approval/view/${item.pr_id}`)
                }}>
                  <div className='cgyy_my_head'>
                    <div>
                      <img src={require('../../../images/cgyy/06.png')} alt='' />
                      {moment(item.yyrq).format('YYYY-MM:DD')} {item.yysj}
                    </div>

                    {/* 0待审批 1审批中 2审批通过 3审批不通过 4预约取消 */}

                    {/* {item.yyzt === 0 ? (<div className='cgyy_my_wait'>待审批</div>) : ''}
                                {item.yyzt === 1 ? (<div className='cgyy_my_wait'>审批中</div>) : ''}
                                {item.yyzt === 2 ? (<div>审批通过</div>) : ''}
                                {item.yyzt === 3 ? (<div className='cgyy_my_wait'>审批驳回</div>) : ''}
                                {item.yyzt === 4 ? (<div className='cgyy_my_cancel'>预约取消</div>) : ''} */}


                    {type === 1 ? (<div className='cgyy_my_wait'>待审批</div>) : ''}

                    {type === 2 ? (<div>审批通过</div>) : ''}
                    {type === 3 ? (<div className='cgyy_my_wait'>审批驳回</div>) : ''}
                  </div>
                  <div className='cgyy_my_cont'>
                    <div className='cgyy_my_name'>{item.yymc}</div>
                    <div className='cgyy_my_name_tag'>
                      <div>{item.yylx === 1 ? '校内团队' : '校外团队'}</div>
                      {/* <div>随行{item.yyscrs}人</div> */}
                    </div>
                  </div>
                  <div className='cgyy_my_desc'>
                    <div className='cgyy_my_desc_item'>
                      <div>申请人单位</div>
                      <div>{item.yydw}</div>
                    </div>
                    <div className='cgyy_my_desc_item'>
                      <div>随行人数</div>
                      <div>{item.yyscrs}</div>
                    </div>
                    {item.yylx === 2 ? (<div className='cgyy_my_desc_item'>
                      <div>校内对接单位</div>
                      <div>{item.xndjdw}</div>
                    </div>) : ''}
                    <div className='cgyy_my_desc_item'>
                      <div>联系方式</div>
                      <div>{item.yysjh}</div>
                    </div>
                    <div className='cgyy_my_desc_item'>
                      <div>备注</div>
                      <div>{item.yybz}</div>
                    </div>
                  </div>
                  <div className='cgyy_my_but'>
                  </div>
                </div>
              )
            }) : <div className='not-yet'>--暂无数据--</div>}
      </div>
    )
  }
}
