import React, { Component } from 'react'
import { selectOneEquipment } from '../../../api/api'
import './index.less'
import IP from '../../../config/config'

class DeviceDetail extends Component {

  state = {
    data: {}
  }

  componentDidMount = () => {
    this.fetch(this.props.match.params.id)
  }

  fetch = async id => {
    const res = await selectOneEquipment({ id })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className='deviceDetail'>
        <div className='deviceDetail_img'>
          {
            data.imageurl ? <img alt='' src={IP.fileHost + data.imageurl} /> :
            <img alt='' src={require('../../../images/008.png')} />
          }
        </div>
        <div className='deviceDetail_con'>
          <div className='deviceDetail_con_top'>
            <div className='deviceDetail_con_name'>{data.yqmc}</div>
            <div className='deviceDetail_con_addr'>{data.sssysmc}</div>
            <div className='deviceDetail_con_type'>
              <div className='deviceDetail_con_type_fl'>{data.yqflmc}</div>
              <div className='deviceDetail_con_type_bh'>{data.yqbh}</div>
            </div>
          </div>
          <div className='deviceDetail_con_middle'>
            <div className='deviceDetail_con_list'>
              <div className='deviceDetail_con_list_title'>设备名称</div>
              <div className='deviceDetail_con_list_name'>{data.yqmc}</div>
            </div>
            <div className='deviceDetail_con_list'>
              <div className='deviceDetail_con_list_title'>设备编号</div>
              <div className='deviceDetail_con_list_name'>{data.yqbh}</div>
            </div>
            <div className='deviceDetail_con_list'>
              <div className='deviceDetail_con_list_title'>型号</div>
              <div className='deviceDetail_con_list_name'>{data.yqxh}</div>
            </div>
            <div className='deviceDetail_con_list'>
              <div className='deviceDetail_con_list_title'>规格型号</div>
              <div className='deviceDetail_con_list_name'>{data.yqgg}</div>
            </div>
            <div className='deviceDetail_con_list'>
              <div className='deviceDetail_con_list_title'>出厂日期</div>
              <div className='deviceDetail_con_list_name'>{data.ccrq}</div>
            </div>
          </div>
          <div className='deviceDetail_con_footer'>
            <div className='deviceDetail_con_list'>
              <div className='deviceDetail_con_list_title'>国资编号</div>
              <div className='deviceDetail_con_list_name'>{data.zcbh}</div>
            </div>
            <div className='deviceDetail_con_list'>
              <div className='deviceDetail_con_list_title'>购置日期</div>
              <div className='deviceDetail_con_list_name'>{data.gzrq}</div>
            </div>
            <div className='deviceDetail_con_list'>
              <div className='deviceDetail_con_list_title'>所属位置</div>
              <div className='deviceDetail_con_list_name'>{data.sssysmc}</div>
            </div>
          </div>
        </div>
        <div className='deviceDetail_btn'>
          <div className='deviceDetail_btn_list' onClick={() => this.props.history.push(`/module/appoDetail/${data.id}`)}>设备预约</div>
        </div>
      </div>
    )
  }
}

export default DeviceDetail
