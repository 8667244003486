import React, { Component } from 'react'
import { SearchBar, Picker, List } from 'antd-mobile'
import { Cascader, Field } from 'react-vant'
import { findSpaceInfo, req_findAllyuanxi } from '../../../api/api'
import { req_findAppHidden } from '../../../api/repairApi'
import './hidden_danger.less'
import moment from 'moment'

class HiddenDanger extends Component {

  state = {
    searchContent: '', // 搜索值
    buildId: undefined, // 楼宇id
    floorId: undefined, // 楼层id
    roomId: undefined, // 房间id
    listData: [], // 空调设备
    roomData: [], // 位置数据
    wt: 265,
    hiddeState: '',
    roomValue: [],
    depData: [],
    dept: ''
  }

  componentDidMount = () => {
    document.title = "随手报隐患整改"
    this.fetch()
    this.fetchRoom()
    this._findAllyuanxi()
    this.setState({
      wt: document.body.clientWidth - 190
    })
  }

  _findAllyuanxi = async () => {
    const res = await req_findAllyuanxi({ task_level: 6 })
    if (res.code === 20000) {
      console.log(res.data)
      this.setState({
        depData: res.data.map(item => {
          return {
            label: item.dwmc,
            value: item.dwdm,
          }
        })
      })
    }
  }

  fetch = async () => {
    const { searchContent, roomId, hiddeState, dept } = this.state
    const res = await req_findAppHidden({
      roomId: roomId !== '-1' ? roomId : '', roomName: searchContent, hiddeState, task_level: 6,
      dept: dept ? dept.join(',') : ''
    })
    if (res.code === 20000) {
      this.setState({
        listData: res.data
      })
    }
  }

  // 查询位置
  fetchRoom = async () => {
    const res = await findSpaceInfo()
    if (res.code === 20000) {
      let menuList = res.data.map(item => {
        return {
          text: item.dwmc,
          parentId: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
        }
      })
      menuList = menuList.map(item => {
        if (menuList.filter(p => p.parentId === item.value).length) {
          item.children = menuList.filter(p => p.parentId === item.value)
        }
        return item
      }).filter(p => p.parentId === '0')
      this.setState({
        roomData: [{
          children: menuList,
          parentId: "-1",
          text: "全部校区",
          value: "-1"
        }]
      })
    }
  }

  // 搜索房间
  handleChange = e => {
    this.setState({
      searchContent: e
    }, () => {
      this.fetch()
    })
  }

  handleCancel = () => {
    this.setState({
      searchContent: ''
    }, () => {
      this.fetch()
    })
  }

  // 选中项变化时触发
  handleCascaderChange = (val, selectedRows, actions) => {
    // console.log(val, selectedRows, actions, 'xxxxxx')
  }

  // 全部选项选择完成
  handleCascaderFinish = (val, selectedRows, actions) => {
    this.setState({
      roomId: val[val.length - 1],
      roomValue: val,
    }, () => {
      this.fetch()
    })
  }
  // 全部选项选择完成
  handleCascaderType = (val, selectedRows, actions) => {
    this.setState({
      hiddeState: val[0],
    }, () => {
      this.fetch()
    })
  }

  render() {
    const { roomData, listData, wt } = this.state
    return (
      <div className='banpai'>
        <div className='banpai_img'>
          <div className='banpai_img_title'>
            <div>随手报隐患整改</div>
            <div>隐患库发现隐患、管理隐患、解决隐患</div>
          </div>
          <img alt='' src={require('../../../img/hidden_danger.png')} />
          <div className='banpai_search'>
            <SearchBar placeholder="请输入实验室名称" onCancel={() => this.handleCancel()} onChange={e => this.handleChange(e)} />
          </div>
        </div>
        <div className='banpai_title'>
          <span>隐患列表</span>
          <div className='task_head_info_select'>
            <div className='task_head_info_select_picker task_picker_width' style={{ marginRight: '12px' }}>
              <Picker
                data={this.state.depData}
                cols={1}
                extra="请选择院系"
                value={this.state.dept}
                onChange={val => {
                  this.setState({
                    dept: val,
                  }, () => {
                    this.fetch(this.props.match.params.id)
                  })
                }}
              >
                <List.Item arrow="horizontal"></List.Item>
              </Picker>
            </div>
            {/* <div className='banpai_cascader'>
            <Cascader
              popup={{ round: true }}
              title='选择实验室'
              activeColor="#00B4C4"
              options={roomData}
              onChange={this.handleCascaderFinish}
              value={this.state.roomValue}
            >
              {(_, selectedRows, actions) => (
                <Field
                  isLink
                  // value={selectedRows.map(el => el.text).join(',')}
                  value={selectedRows.length > 0 ? selectedRows[selectedRows.length - 1].text : ''}
                  readOnly
                  className='door_lock_cascader'
                  placeholder='全部房间'
                  onClick={() => actions.open()}
                />
              )}
            </Cascader>
          </div> */}
            <div className='banpai_cascader'>
              <Cascader
                popup={{ round: true }}
                title='选择处理结果'
                activeColor="#00B4C4"
                options={[
                  {
                    text: '不属实隐患',
                    value: -2
                  },
                  {
                    text: '待核实隐患',
                    value: -1
                  },
                  {
                    text: '待整改',
                    value: 0
                  },
                  {
                    text: '已整改待确认',
                    value: 1
                  },
                  {
                    text: '整改完成',
                    value: 2
                  },
                  {
                    text: '整改不合格',
                    value: 3
                  },
                  {
                    text: '暂无法整改',
                    value: 4
                  },
                  {
                    text: '隐患撤回',
                    value: 5
                  },
                ]}
                onFinish={this.handleCascaderType}
              >
                {(_, selectedRows, actions) => (
                  <Field
                    isLink
                    readOnly
                    value={selectedRows.length === 1 ? selectedRows[0].text : ''}
                    className='door_lock_cascader'
                    placeholder='全部结果'
                    onClick={() => actions.open()}
                  />
                )}
              </Cascader>
            </div>
          </div>
        </div>
        <div className='hidden_danger_list'>
          {(listData || []).length ? listData.map(item => {
            return (
              <div className='hidden_danger_item' key={item.details_id} onClick={() => {
                if (item.hidden_result === 0 || item.hidden_result === 3 || item.hidden_result === 4) {
                  // 处理隐患
                  this.props.history.push(`/module/patrol/briefing_danger_detail/${item.details_id}`)
                } else if (item.hidden_result === -1) {
                  this.props.history.push(`/module/patrol/briefing_danger_detail/${item.details_id}`)
                } else {
                  this.props.history.push(`/module/patrol/briefing_hidden_detail/${item.details_id}`)
                }
              }}>
                {item.hidden_result === -2 ? <div className='hidden_danger_type hidden_danger_no'>不属实隐患</div> : ''}

                {item.hidden_result === -1 ? <div className='hidden_danger_type hidden_danger_not'>待核实隐患</div> : ''}

                {item.hidden_result === 0 ? <div className='hidden_danger_type hidden_danger_not'>待整改</div> : ''}

                {item.hidden_result === 1 ? <div className='hidden_danger_type hidden_danger_wait'>已整改待确认</div> : ''}

                {item.hidden_result === 2 ? <div className='hidden_danger_type hidden_danger_ok'>整改完成</div> : ''}

                {item.hidden_result === 3 ? <div className='hidden_danger_type hidden_danger_wait'>整改不合格</div> : ''}

                {item.hidden_result === 4 ? <div className='hidden_danger_type hidden_danger_no'>暂无法整改</div> : ''}

                {item.hidden_result === 5 ? <div className='hidden_danger_type hidden_danger_no'>隐患撤回</div> : ''}

                <div className='hidden_danger_cont'>
                  <div className='hidden_danger_left'>
                    <div className='hidden_danger_icon'>
                      {item.details_state === 1 ? (<img src={require('./../../../img/一般隐患.png')} alt='' />) : (<img src={require('./../../../img/严重隐患.png')} alt='' />)}
                    </div>
                    <div className='hidden_danger_title'>
                      <div style={{ width: wt + 'px' }}>{item.room_name}</div>
                      <div className='hidden_danger_desc'>
                        <div>{item.building_name}/{item.floor_name}</div>
                        {item.details_state === 1 ? (<div className='hidden_danger_sort'>
                          无隐患
                        </div>) :
                          (item.details_state === 2 ? <div className='hidden_danger_serious'>
                            一般隐患
                          </div> :
                            <div className='hidden_danger_noDangers'>
                              {
                                item.details_state === 3 ? '重大隐患' : '严重隐患'
                              }
                            </div>)}
                      </div>
                    </div>
                  </div>
                  {item.hidden_result === 0 ? (<div className='hidden_danger_sub'>整改</div>) : ''}
                  {item.hidden_result === -1 ? (<div className='hidden_danger_sub'>核实</div>) : ''}
                </div>
                <div className='hidden_danger_date'>
                  整改截止时间：{moment(item.dispose_end_time).format('YYYY-MM-DD HH:mm')}
                </div>
              </div>
            )
          })
            : (<div className='not-yet'>--暂无数据--</div>)}
        </div>
      </div>
    )
  }
}

export default HiddenDanger