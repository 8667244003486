import React, { Component } from 'react'
import { TabBar, Toast } from 'antd-mobile'
import './index.less'

class Tabbar extends Component {

  state = {
    selectedTab: 'homeTab',
  }

  componentDidMount = () => {
    const path = this.props.match.path
    if (path === '/') {
      this.setState({
        selectedTab: 'homeTab'
      })
    }
    if (path === '/scan') {
      this.setState({
        selectedTab: 'scanTab'
      })
    }
    if (path === '/notify') {
      this.setState({
        selectedTab: 'newsTab'
      })
    }
    if (path === '/my') {
      this.setState({
        selectedTab: 'myTab'
      })
    }
    // if (path === '/module/approve') {
    //   this.setState({
    //     selectedTab: 'approve'
    //   })
    // }
  }

  showView = path => {
    // if (path !== '/') return Toast.info('模块开发中...')
    this.props.history.push(path)
  }

  render() {
    const { selectedTab } = this.state
    return (
      <div style={{ position: 'fixed', bottom: 0, width: '100%' }}>
        <TabBar
          unselectedTintColor="#111111"
          tintColor="#06bfb9"
          barTintColor="#fff"
        >
          <TabBar.Item
            title="首页"
            key="home"
            icon={<div style={{ width: 22, height: 22, background: 'url(' + require("../img/首页1.png") + ') center center /  21px 21px no-repeat' }} />}
            selectedIcon={<div style={{ width: 22, height: 22, background: 'url(' + require("../img/首页.png") + ') center center /  21px 21px no-repeat' }} />}
            selected={selectedTab === 'homeTab'}
            onPress={() => this.showView('/')}
            data-seed="logId"
          />

          {/* <TabBar.Item
            title="扫一扫"
            key="scan"
            icon={<div style={{ width: 22, height: 22, background: 'url(' + require("../img/扫一扫1.png") + ') center center /  21px 21px no-repeat' }} />}
            selectedIcon={<div style={{ width: 22, height: 22, background: 'url(' + require("../img/扫一扫.png") + ') center center /  21px 21px no-repeat' }} />}
            selected={selectedTab === 'scanTab'}
            onPress={() => this.showView('/scan')}
            data-seed="logId1"
          /> */}

          {/* <TabBar.Item
            title="待办"
            key="approve"
            icon={<div style={{ width: 22, height: 22, background: 'url(' + require("../img/消息1.png") + ') center center /  21px 21px no-repeat' }} />}
            selectedIcon={<div style={{ width: 22, height: 22, background: 'url(' + require("../img/消息.png") + ') center center /  21px 21px no-repeat' }} />}
            selected={selectedTab === 'approve'}
            onPress={() => this.showView('/module/approve')}
            data-seed="logId1"
          /> */}

          <TabBar.Item
            title="消息"
            key="news"
            icon={<div style={{ width: 22, height: 22, background: 'url(' + require("../img/消息1.png") + ') center center /  21px 21px no-repeat' }} />}
            selectedIcon={<div style={{ width: 22, height: 22, background: 'url(' + require("../img/消息.png") + ') center center /  21px 21px no-repeat' }} />}
            selected={selectedTab === 'newsTab'}
            onPress={() => this.showView('/notify')}
            data-seed="logId2"
          />

          <TabBar.Item
            title="我的"
            key="my"
            icon={<div style={{ width: 22, height: 22, background: 'url(' + require("../img/我的1.png") + ') center center /  21px 21px no-repeat' }} />}
            selectedIcon={<div style={{ width: 22, height: 22, background: 'url(' + require("../img/我的.png") + ') center center /  21px 21px no-repeat' }} />}
            selected={selectedTab === 'myTab'}
            onPress={() => this.showView('/my')}
            data-seed="logId3"
          />
        </TabBar>
      </div >
    )
  }
}

export default Tabbar