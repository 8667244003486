import React, { Component } from 'react';
import { createForm } from 'rc-form';
import { List, ImagePicker, WhiteSpace, TextareaItem, Button, Steps, Toast, Modal } from 'antd-mobile';
import { saveApprovalRecord, findOrderByOrderId } from '../../../../api/repairApi'
import moment from 'moment'
import cookie from 'react-cookies'
import './../commen.less'
import IP from '../../../../config/config'

const Item = List.Item;
const Step = Steps.Step;
class approvalDetails extends Component {
  state = {
    data: {},
    visible: false,
    visible2: false,
    index: 1,
    reason: '',
    base64: '',
    width: window.innerWidth,
    height: window.innerHeight,
    show: false,
    imgUrl: ''
  }
  componentDidMount() {
    window.document.title = '工单审批'
    this.setState({
      id: this.props.match.params.id
    })
    console.log(1111)
    console.log(this.props.match.params.id)
    findOrderByOrderId({ orderId: this.props.match.params.id }).then(res => {
      if (res.code === 20000) {
        this.setState({
          data: res.data ? res.data : {}
        })
      }
    })
  }

  saveApprovalRecord = (index, base64) => {
    const { id, data, reason } = this.state
    var data1 = {
      order_id: id,
      approval_state: index,
      approval_number: cookie.load('userData').id,
      approval_id: cookie.load('userData').id,
      approval_name: cookie.load('userData').name,
      order_user_id: data.repairOrderEntity.user_id ? data.repairOrderEntity.user_id : '',
      order_department_id: data.repairOrderEntity.department_id ? data.repairOrderEntity.department_id : '',
      config_id: data.repairOrderEntity.approval_config_id != null ? data.repairOrderEntity.approval_config_id : '',
      refuse_reason: reason,
      base64String: base64,
    }
    Toast.loading('上传中！', 0)
    saveApprovalRecord(data1).then(res => {
      if (res.code === 20000) {
        Toast.hide()
        Toast.success(res.message, 2)
        this.props.history.goBack()
      }
    })
  }

  // 确认画布是否有更新
  isCanvasBlank = () => {
    const canvas = document.querySelector('.js-signature > canvas');
    var blank = document.createElement('canvas');//系统获取一个空canvas对象
    blank.width = canvas.width;
    blank.height = canvas.height;
    return canvas.toDataURL() === blank.toDataURL();//比较值相等则为空
  }

  // 清除签字
  handleClearBtnClick = () => {
    eval('clearCanvas()')
    // Toast.info('签名清除成功！')
  }

  // 确认
  handleSubmit = () => {
    const data = eval('saveSignature()');
    if (!this.isCanvasBlank()) {
      this.setState({
        visible2: false
      })
      this.rotateBase64Img(data, 270)
    } else {
      this.setState({
        visible2: false,
        base64: '',
      }, () => {
        Toast.fail('签字不能为空！', 2)
      })
    }
  }
  //提交
  save = () => {
    const { base64, index } = this.state
    if (!base64) {
      Toast.fail('签字不能为空！', 2)
      return
    }
    this.saveApprovalRecord(index, base64)
  }
  rotateBase64Img = (src, edg, callback) => {
    const This = this
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var imgW;//图片宽度
    var imgH;//图片高度
    var size;//canvas初始大小
    if (edg % 90 != 0) {
      console.error("旋转角度必须是90的倍数!");
      throw '旋转角度必须是90的倍数!';
    }
    (edg < 0) && (edg = (edg % 360) + 360)
    const quadrant = (edg / 90) % 4; //旋转象限
    const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 }; //裁剪坐标
    var image = new Image();
    image.crossOrigin = "anonymous"
    image.src = src;
    image.onload = function () {
      imgW = image.width;
      imgH = image.height;
      size = imgW > imgH ? imgW : imgH;
      canvas.width = size * 2;
      canvas.height = size * 2;
      switch (quadrant) {
        case 0:
          cutCoor.sx = size;
          cutCoor.sy = size;
          cutCoor.ex = size + imgW;
          cutCoor.ey = size + imgH;
          break;
        case 1:
          cutCoor.sx = size - imgH;
          cutCoor.sy = size;
          cutCoor.ex = size;
          cutCoor.ey = size + imgW;
          break;
        case 2:
          cutCoor.sx = size - imgW;
          cutCoor.sy = size - imgH;
          cutCoor.ex = size;
          cutCoor.ey = size;
          break;
        case 3:
          cutCoor.sx = size;
          cutCoor.sy = size - imgW;
          cutCoor.ex = size + imgH;
          cutCoor.ey = size + imgW;
          break;
      }
      ctx.translate(size, size);
      ctx.rotate(edg * Math.PI / 180);
      ctx.drawImage(image, 0, 0);
      var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
      if (quadrant % 2 == 0) {
        canvas.width = imgW;
        canvas.height = imgH;
      } else {
        canvas.width = imgH;
        canvas.height = imgW;
      }
      ctx.putImageData(imgData, 0, 0);

      //获取旋转后的base64图片
      This.setState({
        base64: canvas.toDataURL(),
      })
    };
  }

  handleImageClick = (index, files) => {
    this.setState({
      show: true,
      imgUrl: files.find(x => x.id === index).url
    })
  }
  render() {
    const { data, visible, reason, base64, visible2, width, height, show, imgUrl } = this.state
    return (
      <div className="details">
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.user_name}>用户姓名</Item>
        </List>
        <WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.user_phone_number}>联系方式</Item>
        </List>
        <WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={(data.repairOrderEntity && data.repairOrderEntity.building_name ? data.repairOrderEntity.building_name : '') + ' ' + (data.repairOrderEntity && data.repairOrderEntity.room_name ? data.repairOrderEntity.room_name : '')}>维修地点</Item>
        </List>
        <WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.fault_type_name}>故障类型</Item>
        </List>
        <WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.equipment_name ? data.repairOrderEntity.equipment_name : ''}>设备名称</Item>
        </List>
        {/* <WhiteSpace size="sm" />
                <List className="my-list">
                    <Item extra={data.repairOrderEntity && data.repairOrderEntity.equipment_product ? data.repairOrderEntity.equipment_product : ''}>设备厂家</Item>
                </List>
                <WhiteSpace size="sm" />
                <List className="my-list">
                    <Item extra={data.repairOrderEntity && data.repairOrderEntity.equipment_type ? data.repairOrderEntity.equipment_type : ''}>设备类型</Item>
                </List> */}
        <WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.equipment_number ? data.repairOrderEntity.equipment_number : ''}>设备编号</Item>
        </List>
        {/* <WhiteSpace size="sm" />
                <List className="my-list">
                    <Item extra={data.repairOrderEntity && data.repairOrderEntity.equipment_model ? data.repairOrderEntity.equipment_model : ''}>设备型号</Item>
                </List>
                <WhiteSpace size="sm" />
                <List className="my-list">
                    <Item extra={data.repairOrderEntity && data.repairOrderEntity.equipment_brand ? data.repairOrderEntity.equipment_brand : ''}>设备品牌</Item>
                </List> */}
        <WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.money !== 0 ? data.repairOrderEntity.money : ''}>维修费用</Item>
        </List>
        <WhiteSpace size="sm" />
        <div className="textarea">
          <div className="textarea_title">
            问题描述
          </div>
          <div>
            <TextareaItem
              value={data.repairOrderEntity && data.repairOrderEntity.description ? data.repairOrderEntity.description : ''}
              autoHeight
              labelNumber={5}
              disabled
            />
          </div>
          <div>
            <ImagePicker
              className="details-upload"
              disableDelete={true}
              selectable={false}
              onImageClick={this.handleImageClick}
              files={data.repairOrderEntity && data.repairOrderEntity.pic_url ? (data.repairOrderEntity && data.repairOrderEntity.pic_url.split(',').map((item, index) => { return { url: IP.fileHost + item, id: index } })) : []}
            />
          </div>
        </div>
        <WhiteSpace size="sm" />
        <div className="textarea">
          <div className="textarea_title">
            审批意见
          </div>
          <div>
            <TextareaItem
              rows={3}
              placeholder="请输入审批意见"
              onChange={e => this.setState({ reason: e })}
            />
          </div>
        </div>
        <WhiteSpace size="sm" />
        <div className="stepApproval">
          <div className="stepTitle">工单流程</div>
          {data.approvalRecordEntities && data.approvalRecordEntities.length ? <Steps size="small" current={data.approvalRecordEntities.length} >

            {
              data.approvalRecordEntities.map((item, index) => <Step key={index} status={item.state === 0 ? 'error' : item.state === 2 ? 'wait' : 'process'} title={<div>{`审批人 : ${item.approval_name}`} <span style={{ paddingLeft: '3px' }}>{item.approval_state == 1 ? '  通过' : item.approval_state == 0 ? "  不通过" : ''}</span></div>} description={<div>
                <div>{item.approval_time ? (`审批时间 : ${moment(item.approval_time).format('YYYY-MM-DD HH:mm:ss')}`) : ''}</div>
                {
                  item.refuse_reason != null ? <div>{`驳回原因 : ${item.refuse_reason}`}</div> : ''
                }

                {item.sign_url ? <div className="signPic"><span>审批签名 : </span><img src={IP.fileHost + item.sign_url} ></img> </div> : ''}
              </div>} />)
            }
          </Steps> : <div className='appreverNo'>暂无审批</div>}
          <Steps size="small" current={3}>
            {
              data.repairOrderEntity && data.repairOrderEntity.order_state !== 2 && data.repairOrderEntity.order_state >= 1 ? <Step status={data.repairOrderEntity && data.repairOrderEntity.work_name !== '' && data.repairOrderEntity.work_name !== null ? 'process' : 'wait'} title={<div>{`维修人 : ${data.repairOrderEntity && data.repairOrderEntity.work_name !== '' && data.repairOrderEntity.work_name !== null ? data.repairOrderEntity.work_name : '待指派'}`} </div>} description={<div>{data.repairOrderEntity && data.repairOrderEntity.work_name !== '' && data.repairOrderEntity.work_name !== null ? `指派时间 : ${moment(data.repairOrderEntity.appoint_time).format('YYYY-MM-DD HH:mm:ss')}` : ''}</div>} /> : ''
            }
            {
              data.repairOrderEntity && data.repairOrderEntity.order_state >= 1 && data.repairOrderEntity.order_state !== 2 && data.repairOrderEntity.work_name !== '' && data.repairOrderEntity.work_name !== null ? <Step status={data.repairOrderEntity.order_state >= 3 ? 'process' : 'wait'} title={<div>{`维修状态 : ${data.repairOrderEntity.order_state >= 3 ? '已维修' : '待维修'}`} </div>} description={<div>{data.repairOrderEntity && data.repairOrderEntity.order_state >= 3 ? `维修时间 : ${moment(data.repairOrderEntity.end_time).format('YYYY-MM-DD HH:mm:ss')}` : ''}</div>} /> : ''
            }
            {
              data.repairOrderEntity && data.repairOrderEntity.order_state >= 3 ? <Step status={data.repairOrderEntity.order_state === 4 ? 'process' : 'wait'} title={<div>{`工单状态 : ${data.repairOrderEntity.order_state === 4 ? '已完成' : '待确认'}`} </div>} description={<div></div>} /> : ''
            }
          </Steps>
        </div>
        <WhiteSpace size="lg" />
        <div className="buttonBtn">
          <Button type='primary' onClick={() => {
            if (!reason) return Toast.info('请填写审批意见')
            this.setState({ visible: true, index: 1 });
            setTimeout(() => {
              eval("PageReady()")
            }, 500)
          }}>通过</Button>
          <Button
            onClick={() => {
              if (!reason) return Toast.info('请填写审批意见')
              this.setState({ visible: true, index: 0 });
              setTimeout(() => {
                eval("PageReady()")
              }, 500)
            }}
            style={{ color: '#ff3357', background: '#fff', border: '1px solid #dee0e3' }}
          >驳回</Button>
        </div>

        <WhiteSpace size="lg" />

        <Modal
          visible={visible}
          transparent
          onClose={() => this.setState({ visible: false })}
          title={<div style={{ textAlign: 'left' }}>审批签字</div>}
          style={{ width: '90%', }}
        >
          <div>
            <div className='imageModal'>
              {
                base64 ? <img src={base64} alt="" width={`${width * 0.9 - 40}px`} style={{ width: `${width * 0.9 - 40}px` }} /> : <div style={{ lineHeight: '150px', textAlign: 'center', fontSize: '18px', fontWeight: 'bolder', color: '#b1b0b0', minHeight: '150px' }}
                  onClick={() => {
                    this.setState({ visible2: true })
                    setTimeout(() => {
                      eval("PageReady()")
                    }, 500)
                  }}>点击签字</div>
              }
            </div>

            <div className='autograph-btn01'>
              <Button style={{ color: '#ff3357', background: '#fff', border: '1px solid #dee0e3' }} onClick={() => this.setState({ base64: '' })} className='autograph-btn-list01'>重签</Button>
              <Button type="primary" onClick={() => this.save()} className='autograph-btn-list01'>确认</Button>
            </div>
          </div>
        </Modal>
        <Modal
          key="dadsfsdg"
          visible={visible2}
          transparent
          onClose={() => this.setState({ visible2: false })}
          style={{ width: '100%', height: '100%' }}
        >
          <div>
            <section className="signature-component" >
              <div id='signature-pad' className="js-signature" data-width={width * 0.7} data-height={height * 0.8} data-border="1px solid black" data-line-color="#000" data-auto-fit="true"></div>
            </section>
            <div className='autograph-btn' style={{ height: `${height * 0.2 - 80}px` }}>
              {/* 按钮旋转90度 以高来定义宽*/}
              <Button style={{ width: `${height * 0.2 - 60}px`, color: '#ff3357', background: '#fff', border: '1px solid #dee0e3' }} onClick={() => this.handleClearBtnClick()} className='autograph-btn-list'>清除</Button>
              <Button style={{ width: `${height * 0.2 - 60}px`, }} type="primary" onClick={() => this.handleSubmit()} className='autograph-btn-list'>确认</Button>
            </div>
          </div>
        </Modal>
        {
          show ? <div className='detailImg' onClick={() => this.setState({ show: false })}>
            <img alt='' src={imgUrl} />
          </div> : ''
        }
      </div>
    );
  }
}

export default createForm()(approvalDetails);