import React, { Component } from 'react'
import { Icon, ListView } from 'antd-mobile'
import { findMyIntegrateRecord } from '../../../api/safty'
import moment from 'moment'
const ds = new ListView.DataSource({
  rowHasChanged: (r1, r2) => r1 !== r2
})
export default class componentName extends Component {
  state = {
    page: 1, //页码
    size: 20, // 每页条数
    totalPage: 0, //总页数
    dataSource: ds, //长列表加载数据源
    billData: [],  //每页数据
    preBillData: [], //原始的订单列表
    allBillData: [], //原始的+每一页新的数据
    isLoading: false,  //是否加载中
    isHasMore: false, //是否还有更多数据
    flag: null,
    myScore: {},
    totalIntegrate: 0
  }

  componentDidMount () {
    this.getJfList()
  }
  getJfList = async () => {
    const { preBillData, page, size } = this.state
    const res = await findMyIntegrateRecord({ page, size })
    if (res.code === 20000) {
      let allBillData = [...preBillData, ...res.data.record.list]
      this.setState({
        billData: res.data,
        preBillData: allBillData,
        allBillData: allBillData,
        totalPage: Math.ceil(res.data.record.total / size),
        dataSource: this.state.dataSource.cloneWithRows(allBillData),
        isHasMore: res.data.record.hasNextPage,
        totalIntegrate: res.data.totalIntegrate
      })
    }
  }
  onEndReached = () => {
    const { page, totalPage } = this.state
    //当前已加载的条数小于total总条数 请求下一页数据，否则停止请求数据
    if ((Number(page)) < Number(totalPage)) {
      this.setState({
        isLoading: true,
        page: page + 1
      }, () => {
        this.getJfList()
      })
    } else {
      this.setState({
        isLoading: false,
        isHasMore: false
      })
    }
  }
  render () {
    const { dataSource, allBillData, size, billData, isLoading, isHasMore, flag } = this.state
    // 渲染每一行数据
    const row = (rowData, sectionID, rowID) => {
      return (
        <div className="ev_jf_item" key={rowID}>
          {rowData.jflx === '1' ? <img src={require('../../../images/safety_exam/jf_detail_study.png')} alt="" /> : ''}
          {rowData.jflx === '2' ? <img src={require('../../../images/safety_exam/jf_detail_lianxi.png')} alt="" /> : ''}
          {rowData.jflx === '3' ? <img src={require('../../../images/safety_exam/js_detail_exam.png')} alt="" /> : ''}
          <div>
            <p>
              完成
              {rowData.jflx === '1' ? '安全学习' : ''}
              {rowData.jflx === '2' ? '安全练习' : ''}
              {rowData.jflx === '3' ? '安全考试' : ''}
            </p>
            <span>{moment(rowData.cjsj).format('YYYY-MM-DD HH:mm')}</span>
          </div>
          <span className='right'>+{rowData.jf}</span>
        </div>
      )
    }
    return (
      <div className='saftyModule jf_detail'>
        <div className="jf_dashboard_content">
          <div className="jf_dashboard_content_floor1">
            <div>
              <div>安全积分</div>
              <b>{this.state.totalIntegrate ? Math.round(this.state.totalIntegrate) : 0}</b>
            </div>
            <div className='guize_link' onClick={() => { this.props.history.push('/module/safty/jfrule') }}>
              积分规则
              <Icon type='right' />
            </div>
          </div>
        </div>
        <div className="jf_dashboard_content_list">
          {allBillData.length > 0 ? <ListView
            ref={el => this.lv = el}
            dataSource={dataSource}
            renderFooter={() => (<div style={{ paddingTop: 5, textAlign: 'center', fontSize: '14px' }}>
              {
                isHasMore && (isLoading ? <span className='list_loading'>加载中...</span> : '')
              }
              {
                billData.length != 0 && (!isHasMore && '没有更多数据了')
              }
            </div>)}
            renderRow={row} //每行数据渲染
            style={{
              height: 'calc(100vh - 120px)', //高度需要定义
              overflow: 'auto',
            }}
            pageSize={size}  //一次渲染几条数据
            onEndReached={this.onEndReached}
            onEndReachedThreshold={20}
          /> : <div className='not-yet'>--暂无数据--</div>}
        </div>
      </div>
    )
  }
}
