import React, { Component } from 'react'
import { SearchBar } from 'antd-mobile'
import { Cascader, Field } from 'react-vant'
import { findSpaceInfo, req_findDtuDevice, req_yongdianRoomList } from '../../../api/api'
import './electricity.less'

class Electricity extends Component {

  state = {
    roomName: '', // 搜索值
    data: [],// list数据
    roomData: [], // 位置数据
    buildId: undefined, // 楼宇id
    floorId: undefined, // 楼层id
    roomId: undefined, // 房间id
  }

  componentDidMount = () => {
    document.title = "智慧用电-DTU"
    this.fetch()
    this.fetchBuild()
  }

  fetch = async () => {
    const { roomName, buildId, floorId, roomId } = this.state
    const res = await req_yongdianRoomList({ roomName, spaceId: roomId ? roomId : floorId ? floorId : buildId ? buildId : '' })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  // 查询位置
  fetchBuild = async () => {
    const res = await findSpaceInfo()
    if (res.code === 20000) {
      let menuList = res.data.map(item => {
        return {
          text: item.dwmc,
          parentId: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
        }
      })
      menuList = menuList.map(item => {
        if (menuList.filter(p => p.parentId === item.value).length) {
          item.children = menuList.filter(p => p.parentId === item.value)
        }
        return item
      }).filter(p => p.parentId === '0')
      this.setState({
        roomData: menuList
      })
    }
  }

  // 搜索房间
  handleChange = e => {
    this.setState({
      roomName: e
    }, () => {
      this.fetch()
    })
  }
  // 搜索房间 取消
  handleCancel = () => {
    this.setState({
      roomName: ''
    }, () => {
      this.fetch()
    })
  }

  // 全部选项选择完成
  handleCascaderFinish = (val, selectedRows, actions) => {
    this.setState({
      roomId: val[val.length - 1],
    }, () => {
      this.fetch()
    })
  }

  render () {
    const { roomData, data } = this.state
    return (
      <div className='electricity'>
        <div className='banpai_img'>
          <img alt='' src={require('../../../images/zhyd_new.png')} />
          <div className='banpai_search'>
            <SearchBar placeholder="请输入房间信息" onCancel={() => this.handleCancel()} onChange={e => this.handleChange(e)} />
          </div>
        </div>
        <div className='banpai_title'>
          <span>房间列表</span>
          <div className='banpai_cascader'>
            <Cascader
              popup={{ round: true }}
              title='选择实验室'
              activeColor="#00B4C4"
              options={roomData}
              onFinish={this.handleCascaderFinish}
            >
              {(_, selectedRows, actions) => (
                <Field
                  isLink
                  value={selectedRows.length > 0 ? selectedRows[selectedRows.length - 1].text : ''}
                  readOnly
                  className='door_lock_cascader'
                  placeholder='全部房间'
                  onClick={() => actions.open()}
                />
              )}
            </Cascader>
          </div>
        </div>
        <div className='electricity_con'>
          {
            (data || []).length ? data.map(item => {
              return <div className='electricity_con_list' key={item.room_id} onClick={() => this.props.history.push({ pathname: `/module/electricity/airSwitch/${item.room_id}`, state: { item } })}>
                <div className='electricity_con_list_left'>
                  <img src={require('../../../images/room_icon.png')} alt="" />
                  <div>
                    <span className='electricity_con_list_left_roomName'>{item.room_name}</span>
                    <span className='electricity_con_list_left_pos'>
                      {item.building_name}/{item.floor_name}/{item.room_number}
                    </span>
                  </div>
                </div>
                <div className='electricity_con_list_right'>
                  已开启
                  <span className='electricity_con_list_right_online'>{item.openNum}</span>
                  /
                  <span>{item.openNum + item.closeNum}</span>
                </div>
              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
      </div>
    )
  }
}

export default Electricity
