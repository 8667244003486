function Rad (d) {
  return d * Math.PI / 180.0//经纬度转换成三角函数中度分表形式。
}



const Utils = {
  GetQueryString: (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)")
    var r = window.location.search.substr(1).match(reg)
    if (r != null)
      return decodeURI(r[2])
    return null
  },
  getUUID: function uuid () {
    var s = []
    var hexDigits = "0123456789abcdef"
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = "4"  // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-"

    var uuid = s.join("")
    return uuid
  },
  // 计算距离，参数分别为第一点的纬度，经度；第二点的纬度，经度
  GetDistance: (lat1, lng1, lat2, lng2) => {

    const radLat1 = Rad(lat1)
    const radLat2 = Rad(lat2)
    const a = radLat1 - radLat2
    const b = Rad(lng1) - Rad(lng2)
    let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
      Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)))
    s = s * 6378.137// EARTH_RADIUS;
    s = Math.round(s * 10000) / 10 //输出为公里
    //s=s.toFixed(4);
    return s
  },
  //根据children 格式转换list
  flattenTree: (tree) => {
    let result = [] // 存放最终结果的数组
    function traverseNode (node) {
      if (Array.isArray(node)) {
        node.forEach((child) => {
          traverseNode(child)
        })
      } else {
        result.push(node)
        if ('children' in node && Array.isArray(node['children'])) {
          node['children'].forEach((child) => {
            traverseNode(child)
          })
        }
      }
    }
    tree.forEach((root) => {
      traverseNode(root)
    })
    return result
  },
  secondsToHMS: (seconds) => {
    let hours = Math.floor(seconds / 3600)
    let minutes = Math.floor((seconds % 3600) / 60)
    let secs = seconds % 60

    hours = hours < 10 ? '0' + hours : hours
    minutes = minutes < 10 ? '0' + minutes : minutes
    secs = secs < 10 ? '0' + secs : secs

    return `${hours}:${minutes}:${secs}`
  }
}

export default Utils