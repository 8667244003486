import React, { Component } from 'react'
import { SearchBar, Toast } from 'antd-mobile'
import { Cascader, Field } from 'react-vant'
import { findSpaceInfo, findAppMyRecord } from '../../../api/api'
import './hidden_danger.less'
import moment from 'moment'

class SpecialList extends Component {

  state = {
    searchContent: '', // 搜索值
    buildId: undefined, // 楼宇id
    floorId: undefined, // 楼层id
    roomId: undefined, // 房间id
    listData: [], // 空调设备
    roomData: [], // 位置数据
    wt: 265,
    hiddeState: '',
    roomValue: []
  }

  componentDidMount = () => {
    this.fetch()
    this.fetchRoom()
    this.setState({
      wt: document.body.clientWidth - 110
    })
  }

  fetch = async () => {
    const { searchContent, buildId, floorId, roomId, hiddeState } = this.state
    const res = await findAppMyRecord({ roomId: roomId !== '-1' ? roomId : '', roomName: searchContent, flag: 1, clzt: hiddeState })
    if (res.code === 20000) {
      this.setState({
        listData: res.data
      })
    }
  }

  // 查询位置
  fetchRoom = async () => {
    const res = await findSpaceInfo()
    if (res.code === 20000) {
      let menuList = res.data.map(item => {
        return {
          text: item.dwmc,
          parentId: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
        }
      })
      menuList = menuList.map(item => {
        if (menuList.filter(p => p.parentId === item.value).length) {
          item.children = menuList.filter(p => p.parentId === item.value)
        }
        return item
      }).filter(p => p.parentId === '0')
      this.setState({
        roomData: [{
          children: menuList,
          parentId: "-1",
          text: "全部校区",
          value: "-1"
        }]
      })
    }
  }

  // 搜索房间
  handleChange = e => {
    this.setState({
      searchContent: e
    }, () => {
      this.fetch()
    })
  }

  handleCancel = () => {
    this.setState({
      searchContent: ''
    }, () => {
      this.fetch()
    })
  }

  // 全部选项选择完成
  handleCascaderFinish = (val, selectedRows, actions) => {
    this.setState({
      roomId: val[val.length - 1],
      roomValue: val,
    }, () => {
      this.fetch()
    })
  }
  // 全部选项选择完成
  handleCascaderType = (val, selectedRows, actions) => {
    this.setState({
      hiddeState: val[0],
    }, () => {
      this.fetch()
    })
  }

  render() {
    const { roomData, listData, wt } = this.state
    return (
      <div className='banpai'>
        <div className='banpai_img'>
          <div className='banpai_img_btn' onClick={() => this.props.history.push('/module/patrol/specialPatrol')}>专项巡检</div>
          <img alt='' src={require('../../../img/patrol_special_list.png')} />
          <div className='banpai_search'>
            <SearchBar placeholder="请输入巡检位置" onCancel={() => this.handleCancel()} onChange={e => this.handleChange(e)} />
          </div>
        </div>
        <div className='banpai_title'>
          <span>巡检列表</span>
          <div className='banpai_cascader'>
            <Cascader
              popup={{ round: true }}
              title='选择实验室'
              activeColor="#00B4C4"
              options={roomData}
              onChange={this.handleCascaderFinish}
              value={this.state.roomValue}
            >
              {(_, selectedRows, actions) => (
                <Field
                  isLink
                  // value={selectedRows.map(el => el.text).join(',')}
                  value={selectedRows.length > 0 ? selectedRows[selectedRows.length - 1].text : ''}
                  readOnly
                  className='door_lock_cascader'
                  placeholder='全部房间'
                  onClick={() => actions.open()}
                />
              )}
            </Cascader>
          </div>
          <div className='banpai_cascader'>
            <Cascader
              popup={{ round: true }}
              title='选择处理结果'
              activeColor="#00B4C4"
              options={[
                {
                  text: '无隐患',
                  value: 0
                },
                {
                  text: '待整改',
                  value: 1
                },
                {
                  text: '已处理',
                  value: 2
                },
              ]}
              onFinish={this.handleCascaderType}
            >
              {(_, selectedRows, actions) => (
                <Field
                  isLink
                  readOnly
                  value={selectedRows.length === 1 ? selectedRows[0].text : ''}
                  className='door_lock_cascader'
                  placeholder='全部结果'
                  onClick={() => actions.open()}
                />
              )}
            </Cascader>
          </div>
        </div>
        <div className='hidden_danger_list'>
          {(listData || []).length ? listData.map(item => {
            return (
              <div className='hidden_danger_item' key={item.details_id} onClick={() => this.props.history.push({ pathname: `/module/patrol/patrol_detail/${item.details_id}`, state: 2 })}>
                {
                  item.details_state ? <>
                    {item.hidden_result === -2 ? <div className='hidden_danger_type hidden_danger_no'>不属实隐患</div> : ''}

                    {item.hidden_result === -1 ? <div className='hidden_danger_type hidden_danger_wait'>待核实隐患</div> : ''}

                    {item.hidden_result === 0 ? <div className='hidden_danger_type hidden_danger_not'>待整改</div> : ''}

                    {item.hidden_result === 1 ? <div className='hidden_danger_type hidden_danger_ok'>已整改待确认</div> : ''}

                    {item.hidden_result === 2 ? <div className='hidden_danger_type hidden_danger_ok'>整改完成</div> : ''}

                    {item.hidden_result === 3 ? <div className='hidden_danger_type hidden_danger_wait'>整改不合格</div> : ''}

                    {item.hidden_result === 4 ? <div className='hidden_danger_type hidden_danger_not'>暂无法整改</div> : ''}

                    {item.hidden_result === 5 ? <div className='hidden_danger_type hidden_danger_not'>隐患撤回</div> : ''}

                  </> : <div className='hidden_danger_type hidden_danger_no'>无隐患</div>
                }

                <div className='hidden_danger_cont'>
                  <div className='hidden_danger_left'>
                    <div className='hidden_danger_icon'>
                      {item.details_state === 2 ? (<img src={require('./../../../img/严重隐患.png')} alt='' />) : (<img src={require('./../../../img/一般隐患.png')} alt='' />)}
                    </div>
                    <div className='hidden_danger_title'>
                      <div style={{ width: wt + 'px' }}>{item.room_name}</div>
                      <div className='hidden_danger_desc'>
                        <div>{item.building_name}/{item.floor_name}</div>
                        {item.details_state === 1 ? (<div className='hidden_danger_sort'>
                          无隐患
                        </div>) : (item.details_state === 2 ? <div className='hidden_danger_serious'>
                          一般隐患
                        </div> : <div className='hidden_danger_noDangers'>
                          {
                            item.details_state === 3 ? '重大隐患' : '严重隐患'
                          }
                        </div>)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='hidden_danger_date'>
                  {moment(item.create_time).format('YYYY-MM-DD HH:mm')}
                </div>
              </div>
            )
          })
            : (<div className='not-yet'>--暂无数据--</div>)}
        </div>
      </div>
    )
  }
}

export default SpecialList