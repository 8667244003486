import React, { Component } from 'react'
import { Toast, Modal, TextareaItem, PullToRefresh, InputItem, Pagination, Icon } from 'antd-mobile'
import { listAppUserTimeTable, submitAppLabRecord } from '../../../api/api'
import cookie from 'react-cookies'
import './index.less'

class UseRecord extends Component {

  state = {
    pageNum: 1,
    pages: 1, // 总页数
    data: [],
    typeData: [
      { id: 2, name: '本周', checked: true },
      { id: 1, name: '本学期', checked: false },
    ],
    statusData: [
      { id: '', name: '全部', checked: true },
      { id: 1, name: '未填', checked: false },
      { id: 2, name: '已填', checked: false },
    ],
    typeId: 2,
    statusId: '',
    recordId: '',
    visible: false,
    content: '', // 实验内容
    stuNum: 0, // 上课人数
    bjmc: '', // 上课班级
    ht: 0,
  }

  componentDidMount = () => {
    this.fetch()
    this.setState({
      ht: document.body.clientHeight - 169
    })
  }

  fetch = async () => {
    Toast.loading('加载中...', 0)
    const { statusId, typeId, pageNum, data } = this.state
    const res = await listAppUserTimeTable({ type: 1, flag: typeId, labState: statusId, pageNum, pageSize: 10 })
    if (res.code === 20000) {
      Toast.hide()
      this.setState({
        // data: data.concat(res.data ? res.data.list : []),
        data: res.data ? res.data.list : [],
        // data: res.data,
        pages: res.data ? (res.data.pages ? res.data.pages : 1) : 1,
      })
    }
  }

  // 修改类型
  handleType = item => {
    const { typeData } = this.state
    this.setState({
      typeData: typeData.map(x => {
        x.checked = x.id === item.id ? true : false
        return x
      }),
      typeId: item.id,
      data: [],
      pageNum: 1,
    }, () => {
      this.fetch()
    })
  }

  // 修改状态
  handleStatus = item => {
    const { statusData } = this.state
    this.setState({
      statusData: statusData.map(x => {
        x.checked = x.id === item.id ? true : false
        return x
      }),
      statusId: item.id,
      data: [],
      pageNum: 1,
    }, () => {
      this.fetch()
    })
  }

  // 上报记录弹窗
  handleModal = item => {
    this.setState({
      recordId: item.record_id,
      visible: true,
      bjmc: item.banji,
    })
  }

  // 上报
  handleSubmit = async () => {
    const { recordId, content, stuNum, bjmc } = this.state
    if (!stuNum) return Toast.info('请输入上课人数')
    if (!content) return Toast.info('请输入实验内容')
    const res = await submitAppLabRecord({ recordId, content, stu_num: stuNum, bjmc })
    if (res.code === 20000) {
      this.setState({
        visible: false,
        data: [],
      })
      Toast.info(res.message)
      setTimeout(() => {
        this.fetch()
      }, 1500)

    }
  }

  // 刷新
  onRefresh = () => {
    const { pages, pageNum } = this.state
    if (pageNum < pages) {
      this.setState({
        pageNum: pageNum + 1
      }, () => {
        this.fetch()
      })
    }
  }

  render() {
    const { typeData, statusData, data, visible, bjmc, ht, pages, pageNum } = this.state
    return (
      <div className='curriculum'>
        <div className='curriculum_header'>
          <div className='curriculum_header_t'>
            {
              (typeData || []).map(item => {
                return <div className={`curriculum_header_t_l ${item.checked ? 'curriculum_header_t_l_active' : ''}`} key={item.id} onClick={() => this.handleType(item)}>
                  <div />
                  <div className='curriculum_header_t_l_name'>{item.name}</div>
                  <div className='curriculum_header_t_l_line' />
                </div>
              })
            }
          </div>
          <div className='curriculum_header_b'>
            {
              (statusData || []).map(item => {
                return <div className={`curriculum_header_b_l ${item.checked ? 'curriculum_header_b_l_active' : ''}`} key={item.id} onClick={() => this.handleStatus(item)}>{item.name}</div>
              })
            }
          </div>
        </div>
        <div className='curriculum_con'>
          <PullToRefresh
            direction='up'
            damping={200}
            onRefresh={this.onRefresh}
            distanceToRefresh={80}
            style={{
              height: ht,
              overflow: 'auto',
            }}
          >
            {
              (data || []).length ? data.map(item => {
                return <div className='curriculum_con_list' key={item.record_id}>
                  {
                    item.kclb === '必修课' && <div className='curriculum_con_list_status required_course'>必修课</div>
                  }

                  {
                    item.kclb === '选修课' && <div className='curriculum_con_list_status elective_courses'>选修课</div>
                  }
                  {
                    item.kclb === '专业任选课' && <div className='curriculum_con_list_status optional_courses'>专业任选课</div>
                  }
                  {
                    item.kclb === '专业限选课' && <div className='curriculum_con_list_status limit_course'>专业限选课</div>
                  }
                  <div className='curriculum_con_list_top' style={{ paddingRight: `${(item.kclb === '必修课' || item.kclb === '选修课') ? '66px' : '94px'}` }}>
                    <img alt='' src={require('../../../images/wdkctp.png')} />
                    <div className='curriculum_con_list_top_course'>{item.teacher_kecheng}</div>
                  </div>
                  <div className='curriculum_con_list_item'>
                    <div className='curriculum_con_list_item_title'>教&nbsp;师&nbsp;工&nbsp;号&nbsp;：</div>
                    <div className='curriculum_con_list_item_con'>{item.user_account}</div>
                  </div>
                  <div className='curriculum_con_list_item'>
                    <div className='curriculum_con_list_item_title'>任&nbsp;课&nbsp;老&nbsp;师&nbsp;：</div>
                    <div className='curriculum_con_list_item_con'>{item.user_name}</div>
                  </div>
                  <div className='curriculum_con_list_item'>
                    <div className='curriculum_con_list_item_title'>实验室名称：</div>
                    <div className='curriculum_con_list_item_con'>{item.jsmc}</div>
                  </div>
                  <div className='curriculum_con_list_item'>
                    <div className='curriculum_con_list_item_title'>学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;年：</div>
                    <div className='curriculum_con_list_item_con'>{item.xn}</div>
                  </div>
                  <div className='curriculum_con_list_item'>
                    <div className='curriculum_con_list_item_title'>学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;期：</div>
                    <div className='curriculum_con_list_item_con'>第{item.xq}学期</div>
                  </div>
                  <div className='curriculum_con_list_item'>
                    <div className='curriculum_con_list_item_title'>班&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;级：</div>
                    <div className='curriculum_con_list_item_con'>{item.banji}</div>
                  </div>
                  <div className='curriculum_con_list_item'>
                    <div className='curriculum_con_list_item_title'>星&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;期：</div>
                    <div className='curriculum_con_list_item_con'>{item.stu_week}</div>
                  </div>
                  <div className='curriculum_con_list_item'>
                    <div className='curriculum_con_list_item_title'>周&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;次：</div>
                    <div className='curriculum_con_list_item_con'>第{item.skzc}周</div>
                  </div>
                  <div className='curriculum_con_list_item'>
                    <div className='curriculum_con_list_item_title'>节&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;次：</div>
                    <div className='curriculum_con_list_item_con'>{item.jieci}</div>
                  </div>
                  {
                    item.stu_num ? <div className='curriculum_con_list_item'>
                      <div className='curriculum_con_list_item_title'>实&nbsp;到&nbsp;人&nbsp;数&nbsp;：</div>
                      <div className='curriculum_con_list_item_con'>{item.stu_num}</div>
                    </div> : ''
                  }
                  <div className='curriculum_con_list_item' style={{ paddingBottom: `${item.shiyan_content ? '0px' : '12px'}` }}>
                    <div className='curriculum_con_list_item_title'>上&nbsp;课&nbsp;记&nbsp;录&nbsp;：</div>
                    {
                      item.lab_state === 1 ? <div className='curriculum_con_list_item_main not_filled_in'>未填写</div> : <div className='curriculum_con_list_item_main completed'>已填写</div>
                    }
                  </div>
                  {
                    item.shiyan_content ? <div className='curriculum_con_list_synr'>
                      <div className='curriculum_con_list_item'>
                        <div className='curriculum_con_list_item_title'>实&nbsp;验&nbsp;内&nbsp;容&nbsp;：</div>
                      </div>
                      <div className='curriculum_con_list_item_nr'>
                        <div className='curriculum_con_list_item_nr_con'>{item.shiyan_content}</div>
                      </div>
                    </div> : ''
                  }
                  {/* is_sys_manager是否管理员 0是管理员  lab_state 1未填 2已填 */}
                  {/* {
                    cookie.load('userData').is_sys_manager === 0 ? (item.lab_state === 1 ? <div className='curriculum_con_list_btn'>
                      <div className='curriculum_con_list_btn_item' onClick={() => this.handleModal(item)}>上报记录</div>
                    </div> : '') : (item.state ? (item.lab_state === 1 ? <div className='curriculum_con_list_btn'>
                      <div className='curriculum_con_list_btn_item' onClick={() => this.handleModal(item)}>上报记录</div>
                    </div> : '') : '')
                  } */}
                  {
                    item.lab_state === 1 ? <div className='curriculum_con_list_btn'>
                      <div className='curriculum_con_list_btn_item' onClick={() => this.handleModal(item)}>上报记录</div>
                    </div> : ''
                  }
                </div>
              }) : <div className='yet-not'>--暂无课程信息--</div>
            }
          </PullToRefresh>
        </div>
        <div className='curriculum_page'>
          <Pagination total={pages}
            current={pageNum}
            onChange={e => {
              this.setState({
                pageNum: e
              }, () => {
                this.fetch()
              })
            }}
            locale={{
              prevText: (<span className="arrow-align"><Icon type="left" /></span>),
              nextText: (<span className="arrow-align"><Icon type="right" /></span>),
            }}
          />
        </div>
        <Modal
          visible={visible}
          transparent
          onClose={() => {
            this.setState({
              visible: false,
              content: '',
            })
          }}
          title="上报信息"
          footer={[{ text: <div className='curriculum_modal_btn'><div>确定</div></div>, onPress: () => this.handleSubmit() }]}
          className="curriculum_modal"
        >
          <div>
            <InputItem
              placeholder="请输入上课人数"
              clear
              type='number'
              onChange={(v) => this.setState({ stuNum: v })}
            >上课人数</InputItem>
            <InputItem
              placeholder="请输入上课班级"
              clear
              value={bjmc}
              onChange={(v) => this.setState({ bjmc: v })}
            >上课班级</InputItem>
            <div className='curriculum_modal_desc'>
              <div className='curriculum_modal_title'>实验内容</div>
              <TextareaItem
                rows={5}
                placeholder="请输入实验内容~"
                onChange={e => this.setState({ content: e })}
              />
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default UseRecord