import React, { Component } from 'react'
import { Toast, Modal, TextareaItem } from 'antd-mobile'
import { findApprovalDetails, submitApprovalResult } from '../../../api/api'
import moment from 'moment'
import './approve.less'

class ApproveDetail extends Component {

  state = {
    data: {},
    visible: false,
    opinion: '',
  }

  componentDidMount = () => {
    this.fetch(this.props.match.params.id)
  }

  fetch = async prId => {
    const res = await findApprovalDetails({ prId })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }

  // 审核成功
  handleSuccess = async () => {
    const { data } = this.state
    const res = await submitApprovalResult({ pr_id: data.pr_id, pr_approval_result: 2 })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.props.history.go(-1)
    }
  }

  // 审核驳回弹窗
  handleFailModal = () => {
    this.setState({
      visible: true
    })
  }

  // 输入审批意见
  onChangeOpinion = e => {
    this.setState({
      opinion: e
    })
  }

  // 提交审批驳回
  handleSubmit = async () => {
    const { data, opinion } = this.state
    if (!opinion) return Toast.info('请输入驳回意见！')
    const res = await submitApprovalResult({
      pr_id: data.pr_id,
      pr_approval_result: 3,
      pr_approval_opinion: opinion
    })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.props.history.go(-1)
    }
  }

  getweekday = (date) => {
    var weekArray = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
    var week = weekArray[new Date(date).getDay()];
    return week;
  }


  render() {
    const { data, visible } = this.state
    return (
      <div className='approveDetail' style={{ padding: '0px' }}>
        <div className='approve_detail_head'>
          <div className='approve_detail_left'>
            <div className='approve_detail_left_title'>
              {data.room}
            </div>
            <div className='approve_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='approve_list_head_date'>
                {data.date_time} {this.getweekday(data.date_time)} 第{data.nodeInfo}节
              </div>
              {
                data.seat_name && data.seat_number ? (<div className='approve_list_head_seat'>座位号：{data.seat_name}{data.seat_number}</div>) : ''
              }

            </div>
          </div>
          <div className='approve_detail_right'>
            {data.pr_approval_result === 1 ? (<img src={require('./../../../images/new/21.png')} alt='' />) : ('')}
          </div>
        </div>
        <div className='approve_detail_cont'>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>实验室名称</div>
              <div>{data.room}</div>
            </div>
            <div className='approve_detail_item'>
              <div>预约时间</div>
              <div>
                <div>{data.date_time} 第{data.nodeInfo}节</div>
              </div>
            </div>
            {
              data.seat_name && data.seat_number ? (<div className='approve_detail_item'>
                <div>座位号</div>
                <div>{data.seat_name}{data.seat_number}</div>
              </div>) : ''
            }

          </div>

          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>实验用途</div>
              <div>{data.syyt_name}</div>
            </div>
            <div className='approve_detail_item'>
              <div>实验目的</div>
              <div>{data.symd}</div>
            </div>
            <div className='approve_detail_item'>
              <div>实验内容</div>
              <div>{data.synr}</div>
            </div>
            <div className='approve_detail_item'>
              <div>实验要求</div>
              <div>{data.syyq}</div>
            </div>
          </div>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>姓名</div>
              <div>{data.reservationist}</div>
            </div>
            <div className='approve_detail_item'>
              <div>性别</div>
              <div>{data.reservationist_sex ? '男' : '女'}</div>
            </div>
            <div className='approve_detail_item'>
              <div>学号</div>
              <div>{data.reservationist_number}</div>
            </div>
            {
              data.yxmc ? (<div className='approve_detail_item'>
                <div>院系</div>
                <div>{data.yxmc}</div>
              </div>) : (<div className='approve_detail_item'>
                <div>部门</div>
                <div>{data.dep_name}</div>
              </div>)
            }
            {
              data.zymc ? (<div className='approve_detail_item'>
                <div>专业</div>
                <div>{data.zymc}</div>
              </div>) : ('')
            }
            {
              data.bjmc ? (<div className='approve_detail_item'>
                <div>班级</div>
                <div>{data.bjmc}</div>
              </div>) : ('')
            }

          </div>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>提交时间</div>
              <div>
                {
                  data.submit_time ? moment(data.submit_time).format('YYYY/MM/DD HH:mm') : moment(new Date()).format('YYYY/MM/DD HH:mm')
                }
              </div>
            </div>
            {data.pr_approval_time ? (<div className='approve_detail_item'>
              <div>审批时间</div>
              <div>
                {moment(data.pr_approval_time).format('YYYY/MM/DD HH:mm')}
              </div>
            </div>) : ('')}

            {
              data.pr_approval_result === 2 ? (<div className='approve_detail_item'>
                <div>审批操作</div>
                <div>
                  审核成功
                </div>
              </div>) : ('')
            }
            {
              data.pr_approval_result === 3 ? (<div className='approve_detail_item'>
                <div>审批操作</div>
                <div>
                  审核驳回
                </div>
              </div>) : ('')
            }
            {
              data.pr_approval_result === 3 ? (<div className='approve_detail_item'>
                <div>驳回原因</div>
                <div>
                  <TextareaItem
                    rows={5}
                    placeholder=""
                    value={data.pr_approval_opinion}
                  />
                </div>
              </div>) : ('')
            }

          </div>
        </div>

        {
          data.pr_approval_result === 1 ? <div className='approveDetail_btn'>
            <div className='approveDetail_btn_list' onClick={() => this.handleFailModal()}>审核驳回</div>
            <div className='approveDetail_btn_list' onClick={() => this.handleSuccess()}>审核通过</div>
          </div> : ''
        }
        <Modal
          visible={visible}
          transparent
          onClose={() => {
            this.setState({
              visible: false,
              opinion: '',
            })
          }}
          title="审批意见"
          footer={[{ text: <div className='approve_modal_btn'><div>确定</div></div>, onPress: () => this.handleSubmit() }]}
          className="approve_modal"
        >
          <div>
            <TextareaItem
              rows={5}
              placeholder="说点什么吧~"
              onChange={this.onChangeOpinion}
            />
          </div>
        </Modal>
      </div>
    )
  }
}

export default ApproveDetail
