import React, { Component } from 'react'
import { SearchBar } from 'antd-mobile'
import { findMobileNoticeInfo } from '../../api/api'
import moment from 'moment'
import IP from '../../config/config'
import './index.less'

class Notice extends Component {

  state = {
    data: [],
    title: ''
  }

  componentDidMount = () => {
    this.fetch()
  }

  fetch = async () => {
    const { title } = this.state
    const res = await findMobileNoticeInfo({ flag: 2, title })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className='notice'>
        <div className='notice-header'>
          <SearchBar placeholder="搜索通知公告" onChange={e => {
            this.setState({
              title: e
            }, () => {
              this.fetch()
            })
          }} />
        </div>
        {
          (data || []).length ? data.map(item => {
            return <div className='notice-list' key={item.notice_id} onClick={() => this.props.history.push(`/notice/detail/${item.notice_id}`)}>
              {
                item.notice_url ? <img src={IP.fileHost + item.notice_url} alt='' /> : <img src={require('../../images/008.png')} alt='' />
              }
              <div className='home-notice-list-con'>
                <div className='notice-list-desc'>{item.notice_title}</div>
                <div className='notice-list-time'>{moment(item.release_time).format('YYYY-MM-DD')}</div>
              </div>
            </div>
          }) : <div className='not-yet'>--暂无数据--</div>
        }
      </div>
    )
  }
}

export default Notice
