import React, { Component } from 'react'
import { TreeSelect, Select } from 'antd'
import { createForm } from 'rc-form';
import { Picker, List, TextareaItem, Modal, Icon, Toast, InputItem } from 'antd-mobile'
import { req_findDepartmentInfo, findZxOrRoomByDwOrZx, findMasterProcess, findNextApprovalUser, req_findDepartmentInfoToTeacher, listSteelBottleByRoom, saveRkInfo, gasInfoList } from './../../../../api/gas'
import './add.less'

const { TreeNode } = TreeSelect

class Add extends Component {
  state = {
    data: [
      {
        label: '2013年',
        value: '2013',
      },
      {
        label: '2014年',
        value: '2014',
      },
    ],
    slideId: '',
    startX: '',
    visible: false,
    tab_id: '',
    sqfxData: [],//方向
    xydm: undefined,//所属学院
    zxid: undefined,//所属中心
    jsid: undefined,//所属实验室
    ckid: undefined,
    rkrgh: undefined,
    rkms: '',
    whpFlbh: '',//化学品类别
    gasGangpingData: [],//化学品
    depTreeData: [],
    depData: [],
    centerData: [],//中心
    roomData: [],//房间
    masterProcess: 0, // 是否多人同时审批
    approveVisible: false,
    spry: undefined,
    userData: [],//入库人
    rkWhpInfoEntities: [],//入库物品
    depTeacherData: [],
    gasData: [],
    sourceData: [
      {
        label: '校级',
        value: 1,
      },
      {
        label: '院级',
        value: 2,
      },
      {
        label: '赠送',
        value: 3,
      },
      {
        label: '其他',
        value: 4,
      },
    ],
    typeData: [
      {
        label: '999',
        value: '999',
      },
      {
        label: '99999',
        value: '99999',
      },
    ],
  }

  componentDidMount = () => {
    document.title = '入库申请'
    window.scrollTo(0, 0)
    this._gasInfoList()
    this.fetchDep()
    this.fetchFzrUser()
  }

  _gasInfoList = async () => {
    const res = await gasInfoList()
    if (res.code === 20000) {
      this.setState({
        gasData: res.data.map(item => {
          return {
            ...item,
            label: item.gas_name,
            value: item.id,
          }
        })
      })
    }
  }

  // 查询学院/部门
  fetchDep = async () => {
    const res = await req_findDepartmentInfo({ dwlx: 1 })
    if (res.code === 20000) {
      let menuList = res.data
      menuList = menuList.map(a => {
        return {
          title: a.dwmc,
          key: a.dwdm,
          parentId: a.sj_dm,
          value: a.dwdm,
          disabled: a.sj_dm === '0' ? true : false
        }
      })
      menuList = menuList.map(m => {
        m.children = menuList.filter(p => p.parentId === m.key)
        return m
      }).filter(x => !res.data.find(v => v.dwdm === x.parentId))
      this.setState({
        depTreeData: menuList,
        depData: res.data,
      })
    }
  }

  // 选择部门/学院
  handleChangeDep = async e => {
    const res = await findZxOrRoomByDwOrZx({ flag: 1, param: e })
    if (res.code === 20000) {
      this.setState({
        centerData: res.data.map(item => {
          return {
            label: item.room_name,
            value: item.room_id,
          }
        })
      })
    }
  }

  // 选择中心
  handleChangeCenter = async e => {
    const res = await findZxOrRoomByDwOrZx({ flag: 2, param: e.join(',') })
    if (res.code === 20000) {
      this.setState({
        roomData: res.data.map(item => {
          return {
            label: item.room_name,
            value: item.room_id,
          }
        })
      })
    }
  }

  // 查询用户信息
  fetchFzrUser = async () => {
    const res = await req_findDepartmentInfoToTeacher()
    if (res.code === 20000) {
      let menuList = res.data.map(item => {
        return {
          key: item.dw_id,
          title: item.dwmc,
          value: item.dw_id,
          parentId: item.sj_dm,
          disabled: true,
          children: item.teacherInfoEntities.map(val => {
            return {
              key: val.gh,
              title: val.xm,
              value: val.gh,
              parentId: val.dwdm,
            }
          })
        }
      })
      menuList = menuList.map(item => {
        item.children = item.children.length ? item.children : menuList.filter(p => p.parentId === item.key)
        return item
      }).filter(p => p.parentId === '0')
      this.setState({
        depTeacherData: menuList,
      })
    }
  }

  // 钢瓶列表
  findPageGasInfo = async () => {
    const { jsid } = this.state
    if (!jsid) {
      Toast.info('请先选择实验室！')
      return
    }
    const res = await listSteelBottleByRoom({ page: 1, size: 999999, roomId: jsid[0] })
    if (res.code === 20000) {
      this.setState({
        gasGangpingData: res.data.list.map(x => {
          x.select_state = false
          return x
        })
      })
    }
  }

  // 添加钢瓶气体信息
  handleAddGas = () => {
    const { gasData, gasGangpingData, rkWhpInfoEntities } = this.state
    const { form } = this.props
    form.validateFields(async (error, values) => {
      if (!error) {
        values.bottle_source = values.bottle_source.join(',')
        values.bottle_type = values.bottle_type.join(',')
        values.gas_id = values.gas_id.join(',')
        values.gas_name = gasData.find(x => String(x.value) === values.gas_id).label
        values.gas_type_name = gasData.find(x => String(x.value) === values.gas_id).gas_type_name
        values.keys = rkWhpInfoEntities.length
        rkWhpInfoEntities.push(values)
        this.setState({
          visible: false,
          rkWhpInfoEntities,
        }, () => {
          form.resetFields()
        })

      } else {
        Toast.info("请补全钢瓶信息")
      }
    })


  }


  // 判断审批是多人共同审批还是单人审批
  handleMasterProcess = async () => {
    const { rkWhpInfoEntities, xydm, zxid, jsid, ckid, rkrgh } = this.state
    if (xydm === undefined || zxid === undefined || jsid === undefined || rkrgh === undefined) {
      Toast.info('请补全上述内容！')
      return false
    }
    if (rkWhpInfoEntities.length === 0) {
      Toast.info('请添加钢瓶气体信息！')
      return false
    }
    const res = await findMasterProcess({ flag: 2 })
    if (res.code === 20000) {
      this.setState({
        masterProcess: res.data
      }, () => {
        this.handleNextApprovalUser(jsid.join(','))
      })
    }
  }

  // 获取下一个审批人员信息
  handleNextApprovalUser = async roomId => {
    const { masterProcess } = this.state
    const res = await findNextApprovalUser({ flag: 2, roomId })
    if (res.code === 20000) {
      this.setState({
        approveUser: res.data,
        approveVisible: true,
      }, () => {
        if (masterProcess) {
          this.setState({
            spry: (res.data || []).map(x => x.gh)
          })
        }
      })
    }
  }

  // 提交
  submit = async () => {
    const { spry, xydm, zxid, jsid, depData, centerData, roomData, masterProcess, approveUser, rkrgh, rkWhpInfoEntities, rkms } = this.state
    if (xydm === undefined || zxid === undefined || jsid === undefined || rkrgh === undefined) {
      Toast.info('请补全上述内容！')
      return false
    }
    if (rkWhpInfoEntities.length === 0) {
      Toast.info('请添加钢瓶气体信息！')
      return false
    }
    const values = {
      xydm: xydm,
      zxid: zxid.join(','),
      jsid: jsid.join(','),
      xymc: depData.find(x => x.dwdm === xydm).dwmc,
      zxmc: centerData.find(x => x.value === zxid.join(',')).label,
      jsmc: roomData.find(x => x.value === jsid.join(',')).label,
      rkzt: 1,
      rkrgh: rkrgh,
      rkms
    }
    const params = {
      ...values,
      spuserVos: masterProcess ? approveUser : approveUser.filter(x => x.gh === spry),
      rkWhpInfoEntities,
    }
    const res = await saveRkInfo(params)
    if (res.code === 20000) {
      Toast.info("提交成功！")
      setTimeout(() => {
        this.props.history.goBack()
      }, 2000)
    }
  }

  render() {
    const { slideId, visible, rkms, gasGangpingData, depTreeData, xydm, centerData, zxid, roomData, jsid, approveVisible, masterProcess, approveUser, spry, rkrgh, userData, rkWhpInfoEntities, depTeacherData, gasData, sourceData, typeData } = this.state
    const loop = data =>
      data.map(item => {
        if (item.children && item.children.length) {
          return (
            <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled}>
              {loop(item.children)}
            </TreeNode>
          )
        }
        return <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled} />
      })
    const { form } = this.props;
    return (
      <div className='gas_manage_add'>
        <div className='gas_manage_add_title'>
        </div>
        <div className='gas_manage_add_form'>
          <div className='gas_manage_add_tree'>
            <div>
              <span style={{ color: 'red' }}>* </span>申请所属学院
            </div>
            <TreeSelect
              showSearch
              treeNodeFilterProp='title'
              placeholder="请选择"
              style={{ width: '100%' }}
              value={xydm}
              onChange={(e) => {
                this.setState({
                  xydm: e,
                  zxid: undefined,
                  jsid: undefined
                })
                this.handleChangeDep(e)
              }}
            >
              {loop(depTreeData)}
            </TreeSelect>
          </div>
          <div className='gas_manage_add_picker'>
            <Picker
              data={centerData}
              value={zxid}
              cols={1}
              onChange={(e) => {
                this.setState({
                  zxid: e,
                  jsid: undefined
                })
                this.handleChangeCenter(e)
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>所属中心</List.Item>
            </Picker>
          </div>
          <div className='gas_manage_add_picker'>
            <Picker
              data={roomData}
              value={jsid}
              cols={1}
              onChange={(e) => {
                this.setState({
                  jsid: e
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>所属实验室</List.Item>
            </Picker>
          </div>
          <div className='gas_manage_add_tree'>
            <div>
              <span style={{ color: 'red' }}>* </span>入库人
            </div>
            <TreeSelect
              showSearch
              treeNodeFilterProp='title'
              placeholder="请选择"
              style={{ width: '100%' }}
              value={rkrgh}
              onChange={(e) => {
                this.setState({
                  rkrgh: e,
                })
              }}
            >
              {loop(depTeacherData)}
            </TreeSelect>
          </div>
        </div>
        <div className='gas_manage_add_form'>
          <div className='gas_manage_add_text'>
            <div><span style={{ color: 'white' }}>* </span>备注</div>
            <TextareaItem
              placeholder="请输入"
              rows={5}
              value={rkms}
              onChange={(e) => {
                this.setState({
                  rkms: e
                })
              }}
            />
          </div>
        </div>

        <div className='gas_manage_add_increase'>
          <div onClick={() => {
            if (!jsid) {
              Toast.info('请先选择实验室！')
              return
            }
            this.findPageGasInfo()
            this.setState({
              tab_id: '',
              visible: true
            })
          }}>
            <img src={require('./../../../../images/gas/06.png')} alt='' />
            <span>新增钢瓶</span>
          </div>
        </div>

        <div className='gas_manage_add_increase_record'>
          <div className='subscription_list_card' style={{ margin: '0' }}>
            {rkWhpInfoEntities.map((item, key) => {
              return (
                <div className='gas_manage_add_card_item' key={key} style={{ height: '120px' }} onTouchStart={(e) => {
                  this.setState({
                    startX: e.touches[0].clientX
                  })
                }} onTouchEnd={(e) => {
                  const endX = e.changedTouches[0].clientX
                  if (this.state.startX === endX) {
                    return
                  }

                  if (endX - this.state.startX > 100) { // 阈值可以根据需要调整
                    this.setState({
                      slideId: ''
                    })
                    return false
                    // 左滑后的处理逻辑
                  }
                  if (endX + this.state.startX > 100) { // 阈值可以根据需要调整
                    this.setState({
                      slideId: item.keys
                    })
                    // 右滑后的处理逻辑
                    return false
                  }
                }}>
                  <div className='gas_manage_add_card_cont  gas_manage_add_cont_float' style={{ margin: slideId === item.keys ? '0px' : '0px 12px' }}>
                    <div className='subscription_list_card_item' style={{ width: slideId === item.keys ? window.screen.width : window.screen.width - 24 }}>
                      <div className='subscription_list_card_head'>
                        <div className='subscription_list_card_head_left'>
                          <div>{key + 1}</div>
                          <div>{item.gas_name}</div>
                        </div>
                        <div className='gas_manage_list_item_tag'>
                          {item.gas_type_name}
                        </div>
                      </div>
                      <div className='subscription_list_card_unit' style={{ marginTop: '12px' }}>
                        <div>{item.bottle_number}</div>
                        <div>{item.bottle_type}</div>
                        <div>{item.bottle_color}</div>
                        <div>{item.bottle_specs}</div>
                      </div>
                      <div className='flex_c'>
                        <div className='flex_c_item'>
                          <div>{item.bottle_source ? this.state.sourceData.find(x => x.value === Number(item.bottle_source)).label : ''}L</div>
                          <div>钢瓶来源</div>
                        </div>
                        <div className='flex_c_item'>
                          <div>{item.bottle_capacity}L</div>
                          <div>容量</div>
                        </div>
                        <div className='flex_c_item'>
                          <div>{item.gas_number}L</div>
                          <div>气量</div>
                        </div>
                      </div>
                    </div>
                    <div className='gas_manage_add_card_del' style={{ display: slideId === item.keys ? 'block' : 'none' }} onClick={() => {
                      this.setState({
                        rkWhpInfoEntities: rkWhpInfoEntities.filter(x => x.keys !== item.keys)
                      })
                    }}>
                      删除
                    </div>
                  </div>
                </div>
              )
            })}

          </div>
        </div>
        <div className='gas_manage_list_but gas_manage_list_but_two'>
          <div onClick={() => {
            window.history.go(-1);
          }}>取消</div>
          <div onClick={() => {
            this.handleMasterProcess()
          }}>确定</div>
        </div>

        <Modal
          popup
          visible={visible}
          className='subscription_modal'
          onClose={() => {
            this.setState({
              visible: false
            })
          }}
          animationType="slide-up"
        >
          <div className='gas_manage_add_modal'>
            <div className='gas_manage_add_modal_locate' style={{ padding: '6px 12px 4px' }}>
              <div className='gas_manage_add_modal_head'>
                <div>添加钢瓶气体</div>
                <div>
                  <Icon type='cross' onClick={() => {
                    this.setState({
                      visible: false
                    })
                  }} />
                </div>
              </div>
            </div>
            <div className='intoHouse_card warehousing_modal_add' style={{ paddingTop: '24px' }}>
              <div className='gas_manage_add_form'>
                <div className='gas_manage_add_picker'>
                  <Picker
                    data={gasData}
                    cols={1}
                    {...form.getFieldProps('gas_id', {
                      rules: [{ required: true }]
                    })}
                  >
                    <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>气体</List.Item>
                  </Picker>
                </div>
                <div className='gas_manage_add_input'>
                  <InputItem placeholder="请输入"
                    {...form.getFieldProps('bottle_number', {
                      rules: [{ required: true }]
                    })}><span style={{ color: 'red' }}>* </span>钢瓶编号</InputItem>
                </div>
                <div className='gas_manage_add_input'>
                  <InputItem placeholder="请输入"
                    {...form.getFieldProps('bottle_specs', {
                      rules: [{ required: true }]
                    })}><span style={{ color: 'red' }}>* </span>钢瓶规格</InputItem>
                </div>
                <div className='gas_manage_add_picker'>
                  <Picker
                    data={typeData}
                    cols={1}
                    {...form.getFieldProps('bottle_type', {
                      rules: [{ required: true }]
                    })}
                  >
                    <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>钢瓶分类</List.Item>
                  </Picker>
                </div>
                <div className='gas_manage_add_input'>
                  <InputItem placeholder="请输入"
                    {...form.getFieldProps('bottle_color', {
                      rules: [{ required: true }]
                    })}><span style={{ color: 'red' }}>* </span>钢瓶颜色</InputItem>
                </div>
                <div className='gas_manage_add_picker'>
                  <Picker
                    data={sourceData}
                    cols={1}
                    {...form.getFieldProps('bottle_source', {
                      rules: [{ required: true }]
                    })}
                  >
                    <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>钢瓶来源</List.Item>
                  </Picker>
                </div>
                <div className='gas_manage_add_input'>
                  <InputItem placeholder="请输入" type="digit"
                    {...form.getFieldProps('bottle_capacity', {
                      rules: [{ required: true }]
                    })}><span style={{ color: 'red' }}>* </span>容量（L）</InputItem>
                </div>
                <div className='gas_manage_add_input'>
                  <InputItem placeholder="请输入"
                    {...form.getFieldProps('gas_number', {
                      rules: [{ required: true }]
                    })}><span style={{ color: 'red' }}>* </span>气量（L）</InputItem>
                </div>
              </div>
            </div>

            <div className='gas_manage_list_but'>
              <div onClick={() => {
                this.handleAddGas()
              }}>新增钢瓶</div>
            </div>
          </div>
        </Modal>
        {/* 选择审批人 */}
        <Modal
          popup
          visible={approveVisible}
          className='subscription_modal'
          onClose={() => {
            this.setState({
              approveVisible: false,
              spry: undefined
            })
          }}
          animationType="slide-up"
        >
          <div className='gas_manage_add_modal gas_manage_add_modal_approve'>
            <div className='gas_manage_add_modal_head'>
              <div>选择审批人员</div>
              <div>
                <Icon type='cross' onClick={() => {
                  this.setState({
                    approveVisible: false
                  })
                }} />
              </div>
            </div>
            <div className='gas_manage_add_select'>
              <div>
                <span style={{ color: 'red' }}>* </span>审批人员
              </div>
              <Select mode={masterProcess ? 'multiple' : null} disabled={masterProcess ? true : false} allowClear showSearch optionFilterProp="children" value={spry} placeholder='请选择' onChange={(e) => {
                this.setState({
                  spry: e
                })
              }}>
                {
                  (approveUser || []).map(item => {
                    return <Select.Option key={item.gh} value={item.gh}>{item.xm}</Select.Option>
                  })
                }
              </Select>
            </div>

            <div className='gas_approve_record_but'>
              <div onClick={() => {
                this.submit()
              }}>提交审批</div>
            </div>
          </div>
        </Modal>
      </div >
    )
  }
}

export default createForm()(Add);