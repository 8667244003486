import React, { Component } from 'react'
import { SearchBar, Pagination, Icon, Toast, } from 'antd-mobile'
import { findPdInfo, deletePdInfo } from './../../../../api/whp'
import moment from 'moment'
import './list.less'

class List extends Component {
  state = {
    paramKey: '',
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    total: 0,
    data: [],
  }

  componentDidMount = () => {
    document.title = '盘点列表'
    window.scrollTo(0, 0)
    this.fetch()
  }

  fetch = async () => {
    const { pageSize, pageNum, paramKey } = this.state
    const res = await findPdInfo({ size: pageSize, page: pageNum, paramKey })
    if (res.code === 20000) {
      this.setState({
        data: res.data.list,
        pages: res.data ? (res.data.pages ? res.data.pages : 1) : 1,
        total: res.data.total,
      })
    }
  }


  // 删除
  handleDel = async (pdid) => {
    const res = await deletePdInfo({ pdid })
    if (res.code === 20000) {
      this.fetch()
      Toast.success(res.message)
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className='subscription_list'>
        <div className='subscription_list_head'>
          <SearchBar placeholder="请输入盘点名称" value={this.state.paramKey} onCancel={(e) => {
            this.setState({
              paramKey: ''
            }, () => {
              this.fetch()
            })
          }} onChange={e => {
            this.setState({
              paramKey: e
            }, () => {
              this.fetch()
            })
          }} />
        </div>
        <div className='subscription_list_record'>
          {data.map((item, key) => {
            return (
              <div className='subscription_list_item' key={key} onClick={() => {
                this.props.history.push(`/module/stocktakingManager/record/${item.pdid}`)
              }}>
                <div className='subscription_list_item_head'>
                  <div>盘点名称：{item.pdmc}</div>
                  <div className='pass_through'>{item.pdckmc}</div>
                </div>
                <div className='subscription_list_title'>
                  {item.pdrxm}-{item.pdrzh}
                </div>
                <div className='subscription_list_foot'>
                  <div>
                    {item.pdsj ? moment(item.pdsj).format('YYYY-MM-DD HH:mm:ss') : ''}
                  </div>
                  <div style={{ color: '#f25c5c' }} onClick={(e) => {
                    e.stopPropagation()
                    this.handleDel(item.pdid)
                  }}>删除</div>
                </div>

              </div>
            )
          })}

          {this.state.data.length === 0 ? <div className='not-yet'>--暂无数据--</div> : ''}

          {
            this.state.total > 20 ? (<div className='subscription_manager_page'>
              <Pagination total={this.state.pages}
                current={this.state.pageNum}
                onChange={e => {
                  this.setState({
                    pageNum: e
                  }, () => {
                    window.scrollTo(0, 0)
                    this.fetch()
                  })
                }}
                locale={{
                  prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                  nextText: (<span className="arrow-align"><Icon type="right" /></span>),
                }}
              />
            </div>) : ''
          }

        </div>



        <div className='subscription_list_but'>
          <div onClick={() => {
            this.props.history.push('/module/stocktakingManager/add')
          }}>新增盘点</div>
        </div>
      </div>
    )
  }
}

export default List
