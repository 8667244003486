import React, { Component } from 'react'
import { Flex, ImagePicker, Toast, TextareaItem, Button } from 'antd-mobile'
import { Picker, Field } from 'react-vant'
import { req_submitJb, req_listPollingType, req_selectOne, ssbPollingBuilding } from '../../../api/api'
import { uploadPatrolImages } from '../../../api/patrol'
import { uploadDisposeFile } from '../../../api/repairApi'
import cookie from 'react-cookies'
import moment from 'moment'
import IP from './../../../config/config'
import './create_patrol.less'

class CreatePatrol extends Component {
  state = {
    patrolType: 0,
    // checkDesc: '',
    detailsContent: '',
    width: window.innerWidth,
    height: window.innerHeight,
    visible: false,
    base64: '',
    detailInfo: {},
    urls: [],
    patrolImgs: [],
    patrolVideo: [],
    addVisible: false,
    dangerType: '',
    dangerEntityList: [],// 隐患信息
    pollingKey: '',//默认隐患点
    pollingTypeList: [],//巡更点信息
    parentData1: [],
    parentData2: [],
    parentData3: [],
    parentData4: [],
    positionId: [],
    position: [],
    selectPosDetail: [],
    roomData: []
  }
  componentDidMount = () => {
    document.title = '隐患上报'
    this._findAllSpaceInfo()
    if (this.props.match.params.id !== 'null') {
      this.fetchTaskDetail(this.props.match.params.id)
    }
  }

  _findAllSpaceInfo = async () => {
    const res = await ssbPollingBuilding()
    if (res.code === 20000) {
      const data = (res.data || []).map(item => {
        return {
          children: null,
          bs: item.bs,
          text: item.dwmc,
          parentId: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
        }
      })
      const newData = data.map(item => {
        const children = data.filter(x => x.parentId === item.value)
        if (children.length) { item.children = children }
        return item
      }).filter(x => x.parentId === '0')
      console.log(newData)
      this.setState({
        position: newData,
        roomData: res.data
      })
    }
  }


  //进入页面获取该房间的巡检配置情况
  fetchTaskDetail = async (id) => {
    const res = await req_selectOne({ id })
    if (res.code === 20000) {
      this.setState({
        detailInfo: res.data,
      }, () => {
        this.listPollingType(0, 1)
      })
    }
  }

  // 查询巡更项
  listPollingType = async (parentId, key) => {
    const { detailInfo } = this.state
    const res = await req_listPollingType({
      configId: detailInfo.config_id,
      roomId: detailInfo.room_id,
      parentId: parentId
    })
    if (res.code === 20000) {
      const data = res.data
      if (key === 1) {
        this.setState({
          parentData1: data
        })
      }
      if (key === 2) {
        this.setState({
          parentData2: data
        })
      }
      if (key === 3) {
        this.setState({
          parentData3: data
        })
      }
      if (key === 4) {
        this.setState({
          parentData4: data
        })
      }

    }
  }

  //巡检后，输入内容，提交到隐患库
  taskSubmit = async () => {
    let roomId = this.props.match.params.id
    const { detailsContent, urls, positionId, roomData } = this.state
    if (roomId === 'null') {
      if (positionId.length === 0) return Toast.info("请选择巡检点!", 1)
      const room = positionId[positionId.length - 1]
      if (roomData.find(x => x.dwdm === room).bs !== 3) {
        return Toast.info("请选择巡检点到实验室!")
      }
      roomId = room
    }

    if (detailsContent === '') {
      Toast.info("请输入隐患描述", 1)
      return false
    }
    if (urls.length === 0) {
      Toast.info("请上传文件", 1)
      return false
    }

    const values = {
      roomId,
      detailsContent,
      files: urls,
    }
    const res = await req_submitJb(values)
    if (res.code === 20000) {
      Toast.info("提交成功", 1)
      setTimeout(() => {
        this.props.history.goBack()
      }, 2000);
    }
  }

  //隐患类型
  onChangeType = e => {
    this.setState({
      patrolType: e.target.value
    })
  }

  // 图片上传
  upload = (files, type, index) => {
    const { urls, patrolImgs } = this.state
    if (type === "add") {
      Toast.loading("图片上传中,请稍等", 0)
      const imgSize = files[files.length - 1].file.size
      if (imgSize > 10 * 1024 * 1024) return Toast.info('请上传小于10M的图片', 2)
      let formData = new FormData()
      formData.append("multipartFile", files[files.length - 1].file)
      uploadPatrolImages(formData).then(res => {
        this.setState({
          patrolImgs: files,
        })
        if (res.code === 20000) {
          Toast.hide()
          Toast.info("上传成功", 1)
          urls.push({ file_name: files[files.length - 1].file.name, file_url: res.data, file_type: 1 })
          this.setState({ urls })
        } else {
          Toast.hide()
          Toast.fail(res.message)
        }
      })
    }
    if (type === "remove") {
      this.setState({
        urls: urls.filter((x, indexs) => indexs !== index),
        patrolImgs: patrolImgs.filter((x, indexs) => indexs !== index),
      })
    }
  }

  // 上传视频文件
  onChangeFile = async (e) => {
    const { urls, patrolVideo } = this.state
    const { files } = e.target
    Toast.loading("视频上传中,请稍等", 0)
    const imgSize = files[files.length - 1].size
    if (imgSize > 20 * 1024 * 1024) return Toast.info('请上传小于20M的附件', 2)
    let formData = new FormData()
    formData.append("multipartFile", files[files.length - 1])
    const res = await uploadDisposeFile(formData)
    if (res.code === 20000) {
      Toast.hide()
      Toast.info("上传成功", 1)
      urls.push({ file_name: files[files.length - 1].name, file_url: res.data, file_type: 2 })
      patrolVideo.push({ file_name: files[files.length - 1].name, file_url: res.data })
      this.setState({ urls, patrolVideo })
    } else {
      Toast.hide()
      Toast.fail(res.message)
    }
  }
  // 删除视频
  handleRemoveVideo = item => {
    const { urls, patrolVideo } = this.state
    this.setState({
      urls: urls.filter(x => x.file_url !== item.file_url),
      patrolVideo: patrolVideo.filter(x => x.file_url !== item.file_url),
    })
  }
  render() {
    const { patrolVideo, detailInfo, patrolImgs, positionId, position } = this.state

    return (
      <div className='createPatrol'>
        <img src={require('../../../img/patrol/随手报头图.png')} width='100%' alt="" />
        <div className="listWrap mt-12">
          {this.props.match.params.id === 'null' ? (<div className='listStylP create_report_picker'>
            <Picker
              popup={{
                round: true,
              }}
              value={positionId}
              title='选择隐患地点'
              columns={position}
              onConfirm={(val, row, index) => {
                const rowData = row.filter(r => r !== undefined)
                if (rowData.length === 0) {
                  return
                }
                const room = this.state.roomData.find(x => x.dwdm === rowData[rowData.length - 1].value)
                if (room.bs === 3) {
                  this.fetchTaskDetail(room.dwdm)
                }
                this.setState({
                  selectPosDetail: row.filter(r => r !== undefined),
                  positionId: val.filter(v => v !== undefined)
                })
              }}
            >
              {(val, _, actions) => {
                return (
                  <Field
                    readOnly
                    clickable
                    label={<div><span style={{ color: 'red' }}>* </span>隐患地点</div>}
                    value={_ && _.length ? (_).map(r => r && r.text).join(',') : ''}
                    placeholder='请选择隐患地点'
                    onClick={() => actions.open()}
                  />
                )
              }}
            </Picker>
          </div>) : ''}

          {this.props.match.params.id !== 'null' ? <div className='listStyl'>
            <div className="left"><span style={{ color: 'red' }}>* </span>隐患地点</div>
            <div className="right">{detailInfo.lymc + detailInfo.lcmc + detailInfo.room_name}</div>
          </div> : ''}

          <div className='listStyl'>
            <div className="left"><span style={{ color: 'red' }}>* </span>隐患发现人</div>
            <div className="right">{cookie.load('userData').name}</div>
          </div>
          <div className='listStyl'>
            <div className="left"><span style={{ color: 'red' }}>* </span>所属院系</div>
            <div className="right">{detailInfo.bmmc}</div>
          </div>
        </div>

        <div className="listWrap pb-16 mt-12">
          <div className='listStyl'>
            <div className="left"><span style={{ color: 'red' }}>* </span>上传图片/视频</div>
          </div>
          <div className='pd-16'>请检查拍摄的图片或视频，至少一张（若图片或视频模糊或在对应点视为作废）</div>
          <ImagePicker
            className='potral-upload'
            length="3"
            files={patrolImgs}
            selectable={patrolImgs.length < 3}
            onChange={(files, type, index) => {
              this.upload(files, type, index)
            }}
          />
          <div className='listStyl_video'>
            <div className='listStyl_video_list' onClick={() => {
              document.getElementById('handle_video').click()
            }}>上传视频</div>
          </div>
          <div className='potral_video'>
            {
              (patrolVideo || []).map(item => {
                return <div className='potral_video_list' key={item.file_url}>
                  <div className='potral_video_list_name'>{item.file_name}</div>
                  <img alt='' src={require('../../../images/del.png')} onClick={() => this.handleRemoveVideo(item)} />
                </div>
              })
            }
          </div>

          <div className='listStyl'>
            <div className="left"><span style={{ color: 'red' }}>* </span>隐患描述</div>
          </div>
          <TextareaItem
            rows={5}
            placeholder="请输入隐患描述"
            onChange={e => this.setState({ detailsContent: e })}
          />
        </div>

        <div className="listWrap mt-12">
          <div className='listStyl'>
            <div className="left">检查时间</div>
            <div className="right">{moment(new Date()).format('YYYY-MM-DD HH:mm')}</div>
          </div>
        </div>

        <Flex className='btn-group mt-12'>
          <Flex.Item onClick={this.taskSubmit}><Button className='green-bg-button'>提交</Button></Flex.Item>
        </Flex>


        <input id='handle_video' type="file" accept="video/*" capture="camcorder" hidden onChange={e => this.onChangeFile(e)} onClick={(event) => { event.target.value = null }} />

      </div>
    )
  }
}
export default CreatePatrol