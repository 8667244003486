import React, { Component } from 'react'
import { Steps, Toast, Modal, Picker, List } from 'antd-mobile'
import { req_findUserFunction, req_findOrderDetails, req_findOrderProgress, req_cancelOrder, req_refuseOrder, req_acceptOrder, req_findRepairUser, req_sendOrder, req_transferOrder, req_endOrder, req_finishOrder } from '../../../api/api'
import './details.less'
import cookie from 'react-cookies'
import IP from './../../../config/config'
import moment from 'moment'
const Step = Steps.Step;
const alert = Modal.alert;
const prompt = Modal.prompt;

class Home extends Component {
  state = {
    funcData: [], // 按钮权限
    data: {}, // 工单详情
    tabs_id: 'null',
    tabs_type_id: 0,
    sendVisible: false, //派单弹窗状态
    transferVisible: false, //转派弹窗状态
    repairUserData: [], //维修工Data
    sendId: [],  //被派单人ID
    transferId: [], //被转单人ID
    // 0待接单 1已接单,维修中 2已完工,待评价 3已评价 4已拒单 5 已终止 6 已取消 7 转派 8 派单
    tabsData: [
      {
        label: '待接单',
        value: 0,
      },
      {
        label: '已接单',
        value: 1,
      },
      {
        label: '已完工',
        value: 2,
      },
      {
        label: '已评价',
        value: 3,
      },
      {
        label: '已拒单',
        value: 4,
      },
      {
        label: '已终止',
        value: 5,
      },
      {
        label: '已取消',
        value: 6,
      },
      {
        label: '转派',
        value: 7,
      },
      {
        label: '派单',
        value: 8,
      }
    ],
    orderProgress: [], // 进度 Data
  }

  componentDidMount = () => {
    this.findUserFunction()
    this.setState({
      tabs_id: this.props.match.params.tabs_id,
      tabs_type_id: Number(this.props.match.params.tabs_type_id)
    })
    this.findOrderDetails(this.props.match.params.id)
    this.findOrderProgress(this.props.match.params.id)
    let bool = true;
    const that = this
    window.addEventListener("popstate", function (e) {
      if (bool) {
        that.props.history.push(`/module/order/${that.state.tabs_id}/${that.state.tabs_type_id}`)
        bool = false
      }
    }, false);
  }

  // 获取用户按钮权限
  findUserFunction = async () => {
    const res = await req_findUserFunction()
    if (res.code === 20000) {
      this.setState({
        funcData: res.data
      })
    }
  }

  // 详情
  findOrderDetails = async (orderId) => {
    const res = await req_findOrderDetails({ orderId })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  // 进度
  findOrderProgress = async (orderId) => {
    const res = await req_findOrderProgress({ orderId })
    if (res.code === 20000) {
      this.setState({
        orderProgress: res.data
      })
    }
  }

  // 取消工单
  orderCancel = async (orderId) => {
    alert('提示', '您确定该取消工单吗?', [
      {
        text: '取消', onPress: () => {
        }
      },
      {
        text: '确定', onPress: async () => {
          const res = await req_cancelOrder({ orderId })
          if (res.code === 20000) {
            Toast.info('取消工单成功')
            this.findOrderDetails(this.props.match.params.id)
            this.findOrderProgress(this.props.match.params.id)
          }
        }
      },
    ])
  }


  // 拒单
  orderRefuse = async (orderId) => {
    prompt('提示', '您确定拒绝该工单吗？',
      [
        {
          text: '取消',
          onPress: () => new Promise((resolve) => {
            resolve();
          }),
        },
        {
          text: '确定',
          onPress: value => new Promise(async (resolve, reject) => {
            if (!value) {
              Toast.info("请输入拒单原因")
              return;
            }
            const res = await req_refuseOrder({ orderId, reason: value })
            if (res.code === 20000) {
              Toast.hide('取消工单成功')
              this.findOrderDetails(this.props.match.params.id)
              this.findOrderProgress(this.props.match.params.id)
              resolve();
            }
          }),
        },
      ], 'default', null, ['请输入拒单原因'])
  }

  // 接单
  acceptOrder = async (orderId) => {
    alert('提示', '您确定接取该工单吗?', [
      {
        text: '取消', onPress: () => {
        }
      },
      {
        text: '确定', onPress: async () => {
          const res = await req_acceptOrder({ orderId })
          if (res.code === 20000) {
            Toast.info('接单成功')
            this.findOrderDetails(this.props.match.params.id)
            this.findOrderProgress(this.props.match.params.id)
          }
        }
      },
    ])
  }

  // 根据工单id查询维修人员信息
  findRepairUser = async (orderId) => {
    const res = await req_findRepairUser({ orderId })
    if (res.code === 20000) {
      if (res.data) {
        const data = res.data.map(val => {
          return {
            label: val.xm,
            value: val.zh
          }
        })
        this.setState({
          repairUserData: data
        })
      }
    }
  }


  // 派单
  orderSend = async () => {
    const { orderId, sendId, repairUserData } = this.state
    if (!sendId) {
      Toast.info("请选择维修工姓名")
      return
    }
    const params = {
      orderId,
      sendId: sendId.join(','),
      sendName: repairUserData.find(x => x.value === String(sendId.join(','))).label
    }
    const res = await req_sendOrder(params)
    if (res.code === 20000) {
      Toast.info('派单成功')
      this.findOrderDetails(this.props.match.params.id)
      this.findOrderProgress(this.props.match.params.id)
      this.setState({
        sendVisible: false,
        sendId: []
      })
    }
  }

  //转派
  transferSend = async () => {
    const { orderId, transferId, repairUserData } = this.state
    if (!transferId) {
      Toast.info("请选择维修工姓名")
      return
    }
    const params = {
      orderId,
      transferId: transferId.join(','),
      transferName: repairUserData.find(x => x.value === String(transferId.join(','))).label
    }
    const res = await req_transferOrder(params)
    if (res.code === 20000) {
      Toast.info('转派成功')
      this.findOrderDetails(this.props.match.params.id)
      this.findOrderProgress(this.props.match.params.id)
      this.setState({
        sendVisible: false,
        sendId: []
      })
    }
  }

  // 终止
  endOrder = async (orderId) => {
    prompt('提示', '您确定终止该工单吗？',
      [
        {
          text: '取消',
          onPress: () => new Promise((resolve) => {
            resolve();
          }),
        },
        {
          text: '确定',
          onPress: value => new Promise(async (resolve, reject) => {
            if (!value) {
              Toast.info("请输入终止原因")
              return;
            }
            const res = await req_endOrder({ orderId, reason: value })
            if (res.code === 20000) {
              Toast.hide('终止工单成功')
              this.findOrderDetails(this.props.match.params.id)
              this.findOrderProgress(this.props.match.params.id)
              resolve();
            }
          }),
        },
      ], 'default', null, ['请输入终止原因'])
  }

  // 完工
  finishOrder = async (orderId) => {
    alert('提示', '您确定完工该工单吗?', [
      {
        text: '取消', onPress: () => {
        }
      },
      {
        text: '确定',
        onPress: value => new Promise(async (resolve, reject) => {
          const res = await req_finishOrder({ orderId })
          if (res.code === 20000) {
            Toast.info('完工成功')
            this.findOrderDetails(this.props.match.params.id)
            this.findOrderProgress(this.props.match.params.id)
            resolve();
          }
        })
      },
    ])
  }

  // map
  orderProgressMap = (val) => {
    // 工单状态 0待接单 1已接单,维修中 2已完工,待评价 3已评价 4已拒单 5 已终止 6 已取消 7 转派 8 派单
    switch (val.order_status) {
      case 0:
        // 0待接单
        return <Step key={val.progress_id} title={'[' + val.user_name + '] 提交了工单'} icon={<img alt='' src={require('../../../images/work/gdlc.png')} />} description={moment(val.create_time).format('YYYY-MM-DD HH:mm:ss')} />
      case 1:
        // 1已接单
        return <Step key={val.progress_id} title={'[' + val.user_name + '] 接了 [' + val.other_name + '] 的工单'} icon={<img alt='' src={require('../../../images/work/gdlc.png')} />} description={moment(val.create_time).format('YYYY-MM-DD HH:mm:ss')} />
      case 2:
        // 2已完工,待评价
        return <Step key={val.progress_id} title={'[' + val.user_name + '] 完工了 [' + val.other_name + '] 的工单'} icon={<img alt='' src={require('../../../images/work/gdlc.png')} />} description={moment(val.create_time).format('YYYY-MM-DD HH:mm:ss')} />
      case 3:
        // 3已评价
        return <Step key={val.progress_id} title={'[' + val.user_name + '] 评价了 [' + val.other_name + '] 的工单'} icon={<img alt='' src={require('../../../images/work/gdlc.png')} />} description={moment(val.create_time).format('YYYY-MM-DD HH:mm:ss')} />
      case 4:
        // 4已拒单
        return <Step key={val.progress_id} title={'[' + val.user_name + '] 拒绝了 [' + val.other_name + '] 的工单'} icon={<img alt='' src={require('../../../images/work/gdlc.png')} />} description={moment(val.create_time).format('YYYY-MM-DD HH:mm:ss')} />
      case 5:
        // 5已终止
        return <Step key={val.progress_id} title={'[' + val.user_name + '] 终止了 [' + val.other_name + '] 的工单'} icon={<img alt='' src={require('../../../images/work/gdlc.png')} />} description={moment(val.create_time).format('YYYY-MM-DD HH:mm:ss')} />
      case 6:
        // 6已取消
        return <Step key={val.progress_id} title={'[' + val.user_name + '] 取消了工单'} icon={<img alt='' src={require('../../../images/work/gdlc.png')} />} description={moment(val.create_time).format('YYYY-MM-DD HH:mm:ss')} />
      case 7:
        // 7转派
        return <Step key={val.progress_id} title={'[' + val.user_name + '] 转派给了 [' + val.other_name + ']'} icon={<img alt='' src={require('../../../images/work/gdlc.png')} />} description={moment(val.create_time).format('YYYY-MM-DD HH:mm:ss')} />
      default:
        // 8派单
        return <Step key={val.progress_id} title={'[' + val.user_name + '] 派单给了 [' + val.other_name + ']'} icon={<img alt='' src={require('../../../images/work/gdlc.png')} />} description={moment(val.create_time).format('YYYY-MM-DD HH:mm:ss')} />
    }

  }


  render() {
    const { funcData, data, tabs_type_id, sendVisible, transferVisible, repairUserData, sendId, transferId, orderProgress, tabs_id } = this.state
    return (
      <div className='order-details'>
        <div className='order-details-back'></div>
        <div className='order-details-img'>
          {data.order_status === 0 && <img alt="" src={require('../../../images/work/djd.png')} />}
          {data.order_status === 1 && <img alt="" src={require('../../../images/work/yjied.png')} />}
          {data.order_status === 2 && <img alt="" src={require('../../../images/work/ywg.png')} />}
          {data.order_status === 3 && <img alt="" src={require('../../../images/work/ypj.png')} />}
          {data.order_status === 4 && <img alt="" src={require('../../../images/work/yjud.png')} />}
          {data.order_status === 5 && <img alt="" src={require('../../../images/work/yzz.png')} />}
          {data.order_status === 6 && <img alt="" src={require('../../../images/work/yqx.png')} />}
        </div>
        <div className='order-details-con'>
          <div className='order-details-con-addr'>{data.area_name}-{data.room}</div>
          <div className='order-details-con-type'>{data.fix_type_name}</div>
          <div className='order-details-con-d'>
            <div className='order-details-con-d-o'>
              <div className='order-details-con-d-o-l'>
                <div className='order-details-con-d-o-l-title'>预约时间</div>
                <div className='order-details-con-d-o-l-con'>
                  {data.advance_start_time ? moment(data.advance_start_time).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm')}至{data.advance_finish_time ? moment(data.advance_finish_time).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm')}
                </div>
              </div>
              <div className='order-details-con-d-o-l'>
                <div className='order-details-con-d-o-l-title'>问题描述</div>
                <div className='order-details-con-d-o-l-con'>{data.order_desc}</div>
              </div>
            </div>
            <div className='order-details-con-d-t'>
              <div className='order-details-con-d-t-l'>
                <div className='order-details-con-d-t-l-title'>姓名</div>
                <div className='order-details-con-d-t-l-con'>{data.bx_name}</div>
              </div>
              <div className='order-details-con-d-t-l'>
                <div className='order-details-con-d-t-l-title'>联系方式</div>
                <div className='order-details-con-d-t-l-con'>{data.bx_phone}</div>
              </div>
            </div>
            <div className='order-details-con-d-l'>
              <Steps>
                {
                  orderProgress.map(val => {
                    return this.orderProgressMap(val)
                  })
                }
              </Steps>
            </div>
          </div>
        </div>

        {/* 操作按钮 */}
        <div className='order-details-but' id='orderDetailBtn'>
          {/* 
                  * 待接单页面：取消，接单，拒接，派单（自己报修的不能自己拒接，也不能自己接）
                  * 已接单页面：完工，转派，终止（只有接单人拥有相应权限才可以进行这些操作）
                  * 待评价页面：评价 
                */}
          {/* 判断 */}
          {
            data.order_status === 0 ? <div className='order-details-list-but'>
              {
                cookie.load('userData').id === data.bx_id && tabs_type_id === 0 ? (<div className='order-reject' onClick={(e) => {
                  e.stopPropagation();
                  this.orderCancel(data.order_id)
                }}>取消</div>) : ''
              }

              {
                funcData.find(x => x === 4) ? (<div className='order-reject' onClick={(e) => {
                  e.stopPropagation();
                  this.orderRefuse(data.order_id)
                }}>拒单</div>) : ''
              }

              {
                funcData.find(x => x === 7) && data.send_status === 0 ? (<div className='order-send' onClick={(e) => {
                  e.stopPropagation();

                  this.findRepairUser(data.order_id)
                  this.setState({
                    sendVisible: true,
                    orderId: data.order_id
                  })
                }}>派单</div>) : ''
              }

              {
                funcData.find(x => x === 2) && tabs_type_id === 1 ? (<div className='order-take' onClick={(e) => {
                  e.stopPropagation();
                  this.acceptOrder(data.order_id)
                }}>接单</div>) : ''
              }
            </div> : ''
          }

          {
            data.order_status === 1 && tabs_type_id === 1 ? <div className='order-details-list-but'>
              {
                funcData.find(x => x === 5) ? (<div className='order-reject' onClick={(e) => {
                  e.stopPropagation();
                  this.endOrder(data.order_id)
                }}>终止</div>) : ''
              }

              {
                funcData.find(x => x === 6) && data.transfer_status === 0 && tabs_type_id === 1 ? (<div className='order-send' onClick={(e) => {
                  e.stopPropagation();
                  this.findRepairUser(data.order_id)
                  this.setState({
                    transferVisible: true,
                    orderId: data.order_id
                  })
                }}>转派</div>) : ''
              }
              {
                cookie.load('userData').id === data.worker_id && tabs_type_id === 1 ? (<div className='order-take' onClick={(e) => {
                  e.stopPropagation();
                  this.finishOrder(data.order_id)
                }}>完工</div>) : ''
              }
            </div> : ''
          }

          {
            data.order_status === 2 && tabs_type_id === 0 ? <div className='order-details-list-but'>
              {
                funcData.find(x => x === 3) ? (<div className='order-take' onClick={(e) => {
                  e.stopPropagation();
                  this.props.history.push(`/module/order/evaluate/${data.order_id}/${tabs_id}/${tabs_type_id}`)
                }}>评价</div>) : ''
              }
            </div> : ''
          }
        </div>


        {/* 派单 */}
        <Modal
          visible={sendVisible}
          transparent
          className='order-modal'
          maskClosable={false}
          onClose={() => {
            this.setState({
              sendVisible: false
            })
          }}
        >
          <div className='order-modal-title'>请选择派单的维修工</div>
          <div className='order-modal-picker'>
            <Picker
              data={repairUserData}
              cols={1}
              value={sendId}
              onChange={(val) => {
                this.setState({
                  sendId: val,
                })
              }}
            >
              <List.Item arrow="horizontal">维修工姓名</List.Item>
            </Picker>
          </div>
          <div className='order-modal-foot'>
            <div onClick={() => {
              this.setState({
                sendVisible: false
              }, () => {
                this.setState({
                  sendId: [],
                })
              })
            }}>取消</div>
            <div onClick={() => {
              this.orderSend()
            }}>确定</div>
          </div>
        </Modal>

        {/* 转派 */}
        <Modal
          visible={transferVisible}
          transparent
          className='order-modal'
          maskClosable={false}
          onClose={() => {
            this.setState({
              transferVisible: false
            })
          }}
        >
          <div className='order-modal-title'>请选择转派的维修工</div>
          <div className='order-modal-picker'>
            <Picker
              data={repairUserData}
              cols={1}
              value={transferId}
              onChange={(val) => {
                this.setState({
                  transferId: val,
                })
              }}
            >
              <List.Item arrow="horizontal">维修工姓名</List.Item>
            </Picker>
          </div>
          <div className='order-modal-foot'>
            <div onClick={() => {
              this.setState({
                transferVisible: false
              }, () => {
                this.setState({
                  transferId: [],
                })
              })
            }}>取消</div>
            <div onClick={() => {
              this.transferSend()
            }}>确定</div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default Home
