import React, { Component } from 'react'
import { List, Picker, DatePicker, Toast } from 'antd-mobile'
import { findReportSemesterTime, findUserCourseInfo, findStudentTemplateInfo, findUserStudentRecord, deleteStudentReord, saveStudentReord } from '../../../api/api'
import moment from 'moment'
import IP from '../../../config/config'
import './index.less'

class TestStu extends Component {

  state = {
    tabs: [
      { id: 1, name: '实验报告模板', checked: true },
      { id: 2, name: '实验报告记录', checked: false }
    ],
    tabsId: 1,
    courseData: [],
    courseId: [],
    xqData: [
      { value: '1', label: '秋季学期' },
      { value: '2', label: '春季学期' },
      { value: '3', label: '夏季学期' },
    ],
    xqId: [],
    xn: '',
    data: [],
    wt: 279,
    recordId: '', // 需要上传的record_id
    weekData: [
      {
        label: '周一',
        value: 1,
      },
      {
        label: '周二',
        value: 2,
      },
      {
        label: '周三',
        value: 3,
      },
      {
        label: '周四',
        value: 4,
      },
      {
        label: '周五',
        value: 5,
      },
      {
        label: '周六',
        value: 6,
      },
      {
        label: '周日',
        value: 7,
      },
    ],
  }

  componentDidMount = () => {
    this.fetchXnXq()
    this.setState({
      wt: document.body.clientWidth - 96
    })
  }

  // 查询最近的学年学期
  fetchXnXq = async () => {
    const res = await findReportSemesterTime()
    if (res.code === 20000) {
      this.fetchCourse(res.data.xn, res.data.xq)
      this.fetchTemplate(res.data.xn, res.data.xq)
      this.setState({
        xqId: [res.data.xq],
        xn: res.data.xn
      })
    }
  }

  // 查询课程
  fetchCourse = async (xn, xq) => {
    const res = await findUserCourseInfo({ xn, xq })
    if (res.code === 20000) {
      this.setState({
        courseData: this.deduplication(res.data || []).map(x => {
          return {
            value: x.template_kcmc,
            label: x.template_kcmc,
          }
        })
      })
    }
  }

  // 查询实验报告模板
  fetchTemplate = async (xn, xq) => {
    const { courseId } = this.state
    const res = await findStudentTemplateInfo({ flag: 2, xn, xq, kcdm: courseId[0] })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  // 查询实验报告记录
  fetchRecord = async (xn, xq) => {
    const { courseId } = this.state
    const res = await findUserStudentRecord({ flag: 2, xn, xq, kcdm: courseId[0] })
    if (res.code === 20000) {
      this.setState({
        data: res.data,
      })
    }
  }

  // 切换类型
  handleChangeTabs = item => {
    const { tabs, xn, xqId } = this.state
    this.setState({
      tabs: tabs.map(x => {
        x.checked = x.id === item.id ? true : false
        return x
      }),
      tabsId: item.id,
      courseId: []
    }, () => {
      if (item.id === 1) {
        this.fetchTemplate(xn, xqId[0])
      }
      if (item.id === 2) {
        this.fetchRecord(xn, xqId[0])
      }
    })
  }

  // 删除报告记录
  handleDel = async item => {
    const { xn, xqId } = this.state
    const res = await deleteStudentReord({ recordId: item.record_id })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.fetchRecord(xn, xqId[0])
    }
  }

  // 编辑报告记录
  handleEdit = item => {
    this.props.this.props.history.push(`/module/testBg/${item.template_id}`)
  }

  // 下载模板
  handleDownTemplate = item => {
    window.location.href = IP.fileHost + item.template_url
  }

  // 下载记录
  handleDownRecord = item => {
    window.location.href = IP.fileHost + item.template_url
  }

  // 数组去重
  deduplication(arr) {
    let list = [];
    return arr.filter((item) => !list.includes(item.template_kcmc) && list.push(item.template_kcmc))
  }

  render() {
    const { tabs, tabsId, courseData, courseId, xqData, xqId, xn, data, wt, weekData } = this.state
    return (
      <div className='testReport'>
        <div className='testReport_header'>
          <div className='testReport_header_title'>实验室电子报告上传系统</div>
          <div className='testReport_header_desc'>学生可在此下载所上课程的实验报告模板</div>
          <div className='testReport_header_btn'>
            {
              (tabs || []).map(item => {
                return <div key={item.id} className={`testReport_header_btn_list ${item.checked ? 'testReport_header_btn_list_active' : ''}`} onClick={() => this.handleChangeTabs(item)}>{item.name}</div>
              })
            }
          </div>
        </div>
        <div className='testReport_tj_cx'>
          <div className='testReport_search'>
            <div className='testReport_search_list'>
              <DatePicker
                mode="year"
                extra={xn ? xn + '年' : '学年'}
                onChange={date => {
                  this.fetchCourse(moment(date).format('YYYY'), xqId[0])
                  this.setState({ xn: moment(date).format('YYYY'), courseId: [] })
                  if (tabsId === 1) {
                    this.fetchTemplate(moment(date).format('YYYY'), xqId[0])
                  }
                  if (tabsId === 2) {
                    this.fetchRecord(moment(date).format('YYYY'), xqId[0])
                  }
                }}
              >
                <List.Item arrow="horizontal"></List.Item>
              </DatePicker>
            </div>
            <div className='testReport_search_list'>
              <Picker
                extra="学期"
                data={xqData}
                cols={1}
                value={xqId}
                onChange={e => {
                  this.fetchCourse(xn, e[0])
                  this.setState({ xqId: e, courseId: [] })
                  if (tabsId === 1) {
                    this.fetchTemplate(xn, e[0])
                  }
                  if (tabsId === 2) {
                    this.fetchRecord(xn, e[0])
                  }
                }}
              >
                <List.Item arrow="horizontal"></List.Item>
              </Picker>
            </div>
            <div className='testReport_search_list'>
              <Picker
                extra="课程"
                data={courseData}
                cols={1}
                value={courseId}
                onChange={e => {
                  this.setState({ courseId: e }, () => {
                    if (tabsId === 1) {
                      this.fetchTemplate(xn, xqId[0])
                    }
                    if (tabsId === 2) {
                      this.fetchRecord(xn, xqId[0])
                    }
                  })
                }}
              >
                <List.Item arrow="horizontal"></List.Item>
              </Picker>
            </div>
          </div>
        </div>
        {
          tabsId === 1 ? <div className='testReport_con'>
            {
              (data || []).length ? data.map((item, index) => {
                return <div className='testReport_con_list' key={index + 1}>
                  <div className='testReport_con_list_header'>
                    <img alt='' src={require('../../../images/muban.png')} />
                    <div className='testReport_con_list_header_con' style={{ width: `${wt}px` }}>
                      <div className='testReport_con_list_header_course header_omit'>{item.template_kcmc}</div>
                      <div className='testReport_con_list_header_nq'>{item.template_xn} {item.template_xq === '1' ? '秋季' : item.template_xq === '2' ? '春季' : '夏季'}学期</div>
                    </div>
                  </div>
                  <div className='testReport_con_list_con'>
                    <div className='testReport_con_list_con_list'>
                      <div className='testReport_con_list_con_list_title'>任课教师</div>
                      <div className='testReport_con_list_con_list_detail'>{item.jzg_xm}</div>
                    </div>
                    <div className='testReport_con_list_con_list'>
                      <div className='testReport_con_list_con_list_title'>上课时间</div>
                      <div className='testReport_con_list_con_list_detail'>
                        {item.kc_week ? weekData.find(x => x.value === item.kc_week).label : ''} &nbsp;
                        {item.kc_ksjc ? '第' + item.kc_ksjc + '节' : ''}-{item.kc_jsjc ? '第' + item.kc_jsjc + '节' : ''}
                      </div>
                    </div>
                    <div className='testReport_con_list_con_list'>
                      <div className='testReport_con_list_con_list_title'>截止时间</div>
                      <div className='testReport_con_list_con_list_detail'>{item.end_time ? moment(item.end_time).format('YYYY-MM-DD HH:mm') : '--'}</div>
                    </div>
                  </div>
                  <div className='testReport_con_list_footer'>
                    <div className='testReport_con_list_footer_btn testReport_btn_down' onClick={() => this.handleDownTemplate(item)}>下载</div>
                  </div>
                </div>
              }) : <div className='not-yet'>--暂无数据--</div>
            }
          </div> : <div className='testReport_con' style={{ paddingBottom: 87 }}>
            {
              (data || []).length ? data.map((item, index) => {
                return <div className='testReport_con_list' key={'a' + index}>
                  <div className='testReport_con_list_header'>
                    <img alt='' src={require('../../../images/baogao.png')} />
                    <div className='testReport_con_list_header_con' style={{ width: `${wt}px` }}>
                      <div className='testReport_con_list_header_course'>{item.xs_xm}</div>
                      <div className='testReport_con_list_header_nq header_omit'>{item.record_kcmc}</div>
                    </div>
                  </div>
                  <div className='testReport_con_list_con'>
                    <div className='testReport_con_list_con_list'>
                      <div className='testReport_con_list_con_list_title'>学号</div>
                      <div className='testReport_con_list_con_list_detail'>{item.xs_xh}</div>
                    </div>
                    <div className='testReport_con_list_con_list'>
                      <div className='testReport_con_list_con_list_title'>教学班代码</div>
                      <div className='testReport_con_list_con_list_detail'>{item.xs_bjdm}</div>
                    </div>
                    <div className='testReport_con_list_con_list'>
                      <div className='testReport_con_list_con_list_title'>学年学期</div>
                      <div className='testReport_con_list_con_list_detail'>{item.record_xn} {item.record_xq === '1' ? '秋季' : item.record_xq === '2' ? '春季' : '夏季'}学期</div>
                    </div>
                    <div className='testReport_con_list_con_list'>
                      <div className='testReport_con_list_con_list_title'>提交时间</div>
                      <div className='testReport_con_list_con_list_detail'>{item.submit_time ? moment(item.submit_time).format('YYYY-MM-DD') : '--'}</div>
                    </div>
                  </div>
                  <div className='testReport_con_list_footer'>
                    <div className='testReport_con_list_footer_btn testReport_btn_down' onClick={() => this.handleDownRecord(item)}>下载</div>
                    {
                      new Date(moment(item.end_time).format('YYYY/MM/DD') + ' 23:59:59') > new Date() ? <div className='testReport_con_list_footer_btn testReport_btn_del' onClick={() => this.handleDel(item)}>删除</div> : ''
                    }
                    {
                      new Date(moment(item.end_time).format('YYYY/MM/DD') + ' 23:59:59') > new Date() ? <div className='testReport_con_list_footer_btn testReport_btn_down' onClick={() => this.handleEdit(item)}>编辑</div> : ''
                    }
                  </div>
                </div>
              }) : <div className='not-yet'>--暂无数据--</div>
            }
          </div>
        }
        {
          tabsId === 2 ? <div className='testReport_btn'>
            <div className='testReport_btn_list' onClick={() => this.props.this.props.history.push('/module/testBg/0')}>新增实验报告</div>
          </div> : ''
        }
      </div>
    )
  }
}

export default TestStu
