import React, { Component } from 'react'
import { InputItem, TextareaItem } from 'antd-mobile'
import moment from 'moment'
import './success.css'

export default class componentName extends Component {
  state = {
  }

  componentDidMount = () => {
    document.title = "预约成功"
  }

  render () {
    const { } = this.state
    return (
      <div className="success">
        <div className='success_icon'>
          <img src={require('../../../images/cgyy/05.png')} alt='' />
        </div>
        <div className='success_title'>
          预约成功
        </div>
        <div className='success_desc'>
          将在24小时内完成审核
          <br></br>
          {moment(this.props.match.params.date).format('YYYY-MM-DD')} {this.props.match.params.time}
        </div>
        <div className='success_details' onClick={() => {
          this.props.history.push('/module/cgyy/my')
        }}>
          查看预约
        </div>

      </div>
    )
  }
}
