import React, { Component } from 'react'

export default class CanvasComponent extends Component {
  componentDidMount () {
    console.log(this.refs.canvas)
    var ctx = this.refs.canvas.getContext('2d')
    var img = new Image()
    img.onload = () => {
      ctx.drawImage(img, 0, 0, this.props.width, this.props.height)
      // 设置字体样式
      ctx.font = 'bold 18px sans-serif'
      // 填充文本
      ctx.fillStyle = '#C58A27' // 设置填充颜色
      ctx.textAlign = "center"
      ctx.fillText(this.props.text1, this.props.width / 2, 50)

      // 设置字体样式
      ctx.font = '12px sans-serif'
      // 填充文本
      ctx.fillStyle = '#C58A27' // 设置填充颜色
      ctx.fillText(this.props.text2, this.props.width * 0.15, this.props.height * 0.5)
    }
    img.src = this.props.imageSrc
    console.log(ctx)
  }
  render () {

    return (
      <canvas ref="canvas" id="myCanvas" width={this.props.width} height={this.props.height} />
    )


  }
}
