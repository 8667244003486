import React, { Component } from 'react'
import { SearchBar, Pagination, Icon, } from 'antd-mobile'
import { findPageSyjlInfo } from './../../../../api/gas'
import './list.less'

class List extends Component {
  state = {
    paramKey: '',
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    total: 0,
    data: [],
    tabs_id: 1,
  }

  componentDidMount = () => {
    document.title = '常用位置监测分析'
    window.scrollTo(0, 0)
    this.fetch()
  }

  fetch = async () => {
    const { pageSize, pageNum, paramKey } = this.state
    const res = await findPageSyjlInfo({ size: pageSize, page: pageNum, paramKey })
    if (res.code === 20000) {
      this.setState({
        data: res.data.list,
        pages: res.data.info ? (res.data.pages ? res.data.pages : 1) : 1,
        total: res.data.total,
      })
    }
  }

  render() {
    const { data, tabs_id } = this.state
    return (
      <div className='gas_manage_list'>
        <div className='gas_manage_list_head_top gas_manage_list_head_manage' style={{ padding: '12px' }}>
          <SearchBar placeholder="请输入使用单号" value={this.state.paramKey} onCancel={(e) => {
            this.setState({
              paramKey: ''
            }, () => {
              this.fetch()
            })
          }} onChange={e => {
            this.setState({
              paramKey: e
            }, () => {
              this.fetch()
            })
          }} />

        </div>

        <div className='sha_list_record' style={{ padding: '74px 12px 82px' }}>
          {data.map((item, key) => {
            return (
              <div className='sha_list_item' key={key} onClick={() => {
                this.props.history.push(`/module/gasCylinder/analysisRecord/${item.syjldh}`)
              }}>
                <div className='sha_item_head'>
                  <div>氧气</div>
                  <div className='sha_item_tag'>助燃气体</div>
                </div>
                <div className='sha_item_gas'>
                  <div style={{ color: "#878787" }}>气瓶编号 15545</div>
                  <div style={{ color: "#878787" }}>铝合金钢瓶</div>
                </div>
                <div className='sha_line'>
                </div>
                <div className='analysis_param'>
                  <div className='analysis_param_item analysis_param_item_go'>
                    <div>
                      使&nbsp;&nbsp;用&nbsp;&nbsp;量<span>5L</span>
                    </div>
                    <div>
                      剩&nbsp;&nbsp;余&nbsp;&nbsp;量<span>5L</span>
                    </div>
                  </div>
                  <div className='analysis_param_item'>
                    使用时间<span>2024-10-12 12:22</span>
                  </div>
                  <div className='analysis_param_item'>
                    备&emsp;&emsp;注<span>无</span>
                  </div>
                </div>
              </div>
            )
          })}

          {this.state.data.length === 0 ? <div className='not-yet'>--暂无数据--</div> : ''}

          {
            this.state.total > 20 ? (<div className='subscription_manager_page'>
              <Pagination total={this.state.pages}
                current={this.state.pageNum}
                onChange={e => {
                  this.setState({
                    pageNum: e
                  }, () => {
                    window.scrollTo(0, 0)
                    this.fetch()
                  })
                }}
                locale={{
                  prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                  nextText: (<span className="arrow-align"><Icon type="right" /></span>),
                }}
              />
            </div>) : ''
          }

        </div>

        <div className='gas_manage_list_but'>
          <div onClick={() => {
            this.props.history.push('/module/gasCylinder/analysisAdd')
          }}>新增位置监测</div>
        </div>
      </div>
    )
  }
}

export default List
