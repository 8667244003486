


import React, { Component } from 'react'
import { findSyjlnfoDetails } from '../../../../api/gas'
import moment from 'moment'

class deviceDetail extends Component {

  state = {
    data: {},
    visible: false,
    opinion: '',
  }

  componentDidMount = () => {
    document.title = '使用详情'
    window.scrollTo(0, 0)
    this.fetch(this.props.match.params.id)
  }

  fetch = async syjldh => {
    const res = await findSyjlnfoDetails({ syjldh })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }


  render() {
    const { data } = this.state
    return (
      <div className='approveDetail' style={{ padding: '0px 0px 76px' }}>
        <div className={data.lyzt > 2 && data.lyzt !== 8 ? 'gas_warehousing_record_head' : 'gas_warehousing_record_head gas_warehousing_record_head_no'}>
          <div className='gas_warehousing_record_left'>
            <div className='gas_warehousing_record_left_title'>
              {data.xymc}-{data.zxmc}-{data.jsmc}
            </div>
            <div className='gas_warehousing_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='gas_warehousing_list_head_date'>
                {data.xymc}
              </div>
              <div className='gas_warehousing_list_head_seat'>气体种类{data.rkzls}种</div>
            </div>
          </div>
          <div className='gas_warehousing_record_right'>
            {data.lyzt === 1 ? (<img src={require('./../../../../images/chemicals/06.png')} style={{ width: '90px' }} alt='' />) : ('')}
            {data.lyzt === 2 ? (<img src={require('./../../../../images/chemicals/07.png')} style={{ width: '90px' }} alt='' />) : ('')}
            {data.lyzt > 2 && data.lyzt !== 8 ? (<img src={require('./../../../../images/chemicals/08.png')} style={{ width: '90px' }} alt='' />) : ('')}
          </div>
        </div>
        <div className='gas_warehousing_record_cont'>
          <div className='gas_warehousing_record_bar'>
            <div className='gas_warehousing_record_item'>
              <div>领用单号</div>
              <div style={{ display: 'flex', alignItems: 'cente' }}>
                {data.lybh}
              </div>
            </div>
            <div className='gas_warehousing_record_item'>
              <div>申请所属学院</div>
              <div>{data.xymc}</div>
            </div>
            <div className='gas_warehousing_record_item'>
              <div>所属中心</div>
              <div>{data.zxmc}</div>
            </div>
            <div className='gas_warehousing_record_item'>
              <div>所属实验室</div>
              <div>{data.jsmc}</div>
            </div>
          </div>

          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>备注</div>
              <div>
                {data.syjlms}
              </div>
            </div>
          </div>


          <div className='gas_warehousing_card'>
            {data.syjlWhpInfoEntities && data.syjlWhpInfoEntities.map((item, key) => {
              return (
                <div className='gas_warehousing_card_item' style={{ background: '#fff' }} key={key}>
                  <div className='gas_warehousing_card_head'>
                    <div className='gas_warehousing_card_head_left'>
                      <div>{key + 1}</div>
                      <div>{item.gas_name}-{item.bottle_number}</div>
                    </div>
                    <div className='gas_warehousing_card_class'>
                    </div>
                  </div>
                  <div className='gas_warehousing_card_unit'>
                    <div>共 {item.bottle_capacity}L</div>
                    <div>当前量 {item.gas_number}L</div>
                    <div>领用量 {item.lysl}L</div>
                    <div>使用量 {item.sysl}L</div>
                  </div>
                  <div className='gas_manage_list_item_tag'>
                    {item.gas_type_name}
                  </div>


                </div>
              )
            })}


          </div>


        </div>

      </div>
    )
  }
}

export default deviceDetail