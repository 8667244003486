import React, { Component } from 'react'
import { TreeSelect, Radio } from 'antd'
import { InputItem, Picker, List, Button, Toast } from 'antd-mobile'
import { findSpaceByLab, req_getLockByRoomId, saveCardInfo } from '../../api/api'
import { createForm } from 'rc-form';
import './binding.less'

const { TreeNode } = TreeSelect;

class Binding extends Component {

  state = {
    imei: '',
    buildData: [], // 班牌可绑定房间树
    spaceData: [], // 班牌可绑定房间
    lockData: [],
    deviceVisible: 0, // 是否关联门锁 1是 0否
  }

  componentDidMount = () => {
    this.fetchSpace()
    this.setState({
      imei: this.props.match.params.id
    })
  }

  fetchSpace = async () => {
    const res = await findSpaceByLab()
    if (res.code === 20000) {
      let menuList = (res.data || []).map(item => {
        return {
          key: item.dwdm,
          title: item.dwmc,
          parentId: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
          disabled: item.bs === 3 ? false : true
        }
      })
      menuList = menuList.map(item => {
        item.children = menuList.filter(p => p.parentId === item.key)
        return item
      }).filter(p => p.parentId === '0')
      this.setState({
        buildData: menuList,
        spaceData: res.data
      })
    }
  }

  // 获取锁信息
  getLockByRoomId = async (roomId) => {
    const res = await req_getLockByRoomId({ roomId })
    if (res.code === 20000) {
      this.setState({
        lockData: res.data.map(x => {
          return {
            value: x.device_id,
            label: `${x.device_room_name}-${x.device_door_name}`
          }
        })
      })
    }
  }

  // 提交
  handleSubmit = () => {
    const { form } = this.props
    const { spaceData } = this.state
    form.validateFields(async (error, value) => {
      if (!error) {
        value.lock_device_id = value.lock_device_id && value.lock_device_id.length ? value.lock_device_id[0] : ''
        value.device_room_id = value.device_space_id ? spaceData.find(x => x.dwdm === value.device_space_id).dwdm : ''
        value.device_room_name = value.device_space_id ? spaceData.find(x => x.dwdm === value.device_space_id).dwmc : ''
        const res = await saveCardInfo(value)
        if (res.code === 20000) {
          form.resetFields()
          Toast.info(res.message)
          this.setState({
            deviceVisible: 0
          })
          setTimeout(() => {
            this.props.history.push('/home')
          }, 1000)
        }
      } else {
        if (error.card_name) return Toast.info('请输入班牌名称')
        if (error.device_space_id)  return Toast.info('请选择所属房间')
        if (error.lock_device_id) return Toast.info('请选择所关联的门锁')
      }
    })
  }

  render() {
    let that = this
    const { form } = this.props;
    const { imei, buildData, lockData, deviceVisible } = this.state
    const loop = data =>
      data.map(item => {
        if (item.children && item.children.length) {
          return (
            <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled} />;
      });
    return (
      <div className='binding'>
        <InputItem
          className="binding-con-number"
          placeholder="请输入序列号"
          disabled
          {...form.getFieldProps('card_number', {
            initialValue: imei,
            rules: [{ required: true }]
          })}
        >
          序列号
        </InputItem>
        <InputItem
          className="binding-con-number"
          placeholder="请输入班牌名称"
          {...form.getFieldProps('card_name', {
            rules: [{ required: true }]
          })}
        >
          班牌名称
        </InputItem>
        <div className='binding-con-tree'>
          <div className='binding-con-tree-title'>所属房间</div>
          <TreeSelect
            placeholder="请选择所属房间"
            style={{ width: '100%' }}
            {...form.getFieldProps('device_space_id', {
              rules: [{ required: true }],
              onChange(e) { that.getLockByRoomId(e) }
            })}
          >
            {loop(buildData)}
          </TreeSelect>
        </div>
        {/* <InputItem
          className="binding-con-number"
          placeholder="请输入安卓版本"
          {...form.getFieldProps('android_versions', {
            rules: [{ required: false }]
          })}
        >
          安卓版本
        </InputItem> */}
        <InputItem
          className="binding-con-number"
          placeholder="请输入型号"
          {...form.getFieldProps('card_model', {
            rules: [{ required: false }]
          })}
        >
          型号
        </InputItem>
        {/* <InputItem
          className="binding-con-number"
          placeholder="请输入版本号"
          {...form.getFieldProps('versions', {
            rules: [{ required: false }]
          })}
        >
          版本号
        </InputItem>
        <InputItem
          className="binding-con-number"
          placeholder="请输入IP地址"
          {...form.getFieldProps('card_ip', {
            rules: [{ required: false }]
          })}
        >
          IP地址
        </InputItem>
        <InputItem
          className="binding-con-number"
          placeholder="请输入MAC地址"
          {...form.getFieldProps('card_mac', {
            rules: [{ required: false }]
          })}
        >
          MAC地址
        </InputItem>
        <div className='binding-con-radio'>
          <div className='binding-con-radio-title'>开机状态</div>
          <Radio.Group
            {...form.getFieldProps('boot_state', {
              initialValue: 1,
              rules: [{ required: true }]
            })}
          >
            <Radio value={1}>开机</Radio>
            <Radio value={0}>关机</Radio>
          </Radio.Group>
        </div>
        <div className='binding-con-radio'>
          <div className='binding-con-radio-title'>网络状态</div>
          <Radio.Group
            {...form.getFieldProps('net_state', {
              initialValue: 1,
              rules: [{ required: true }]
            })}
          >
            <Radio value={1}>在线</Radio>
            <Radio value={0}>离线</Radio>
          </Radio.Group>
        </div> */}
        <div className='binding-con-radio'>
          <div className='binding-con-radio-title'>是否关联门锁</div>
          <Radio.Group
            {...form.getFieldProps('lock_device_ids', {
              initialValue: 0,
              rules: [{ required: true }],
              onChange(e) { that.setState({ deviceVisible: e.target.value }) }
            })}
          >
            <Radio value={1}>是&nbsp;&nbsp;&nbsp;&nbsp;</Radio>
            <Radio value={0}>否&nbsp;&nbsp;&nbsp;&nbsp;</Radio>
          </Radio.Group>
        </div>
        {
          deviceVisible ? <List className='binding_lock'>
            <Picker
              cols={1}
              data={lockData}
              placeholder="请选择关联门锁"
              {...form.getFieldProps('lock_device_id', {
                rules: [{ required: true }]
              })}>
              <List.Item arrow="horizontal" >关联门锁</List.Item>
            </Picker>
          </List> : ''
        }
        <div className='binding_btn' onClick={() => this.handleSubmit()}>
          绑定班牌
        </div>
      </div>
    )
  }
}

export default createForm()(Binding);
