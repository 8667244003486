import React, { Component } from 'react'
import { Toast, TextareaItem, Picker, List } from 'antd-mobile'
import { req_getDetailsById, req_disposalZdHidden, uploadDisposeFile } from '../../../api/repairApi'
import IP from './../../../config/config'
import moment from 'moment'

class ApproveDetail extends Component {

  state = {
    data: {},
    visible: false,
    opinion: '',
    disposalContent: '',//隐患描述
    fileInfo: [],//上传附件
    fileUrl: '',
    type: 1,
    typeData: [
      {
        label: '无隐患',
        value: 0
      },
      {
        label: '一般隐患',
        value: 1
      },
      {
        label: '重大隐患',
        value: 2
      },
    ],
    detailsState: ''
  }

  componentDidMount = () => {
    this.fetch(this.props.match.params.id)
  }

  fetch = async detailsId => {
    const res = await req_getDetailsById({ id: detailsId })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }

  // 提交审批驳回
  handleSubmit = async (result) => {
    const { data, disposalContent, fileInfo } = this.state
    if (result === 1) {
      // 随手报
      if (data.details_type === 3) {
        if (data.details_state === '') {
          return Toast.info('请选择隐患类型！')
        }
      }
      if (disposalContent === '') {
        return Toast.info('请输入整改方案！')
      }
      if (fileInfo.length === 0) {
        return Toast.info('请上传附件！')
      }
    }

    const res = await req_disposalZdHidden({
      result,
      detailsState: data.details_state,
      detailsId: this.props.match.params.id,
      disposalContent,
      fileInfo: JSON.stringify(fileInfo)
    })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.props.history.go(-1)
    }
  }

  // 上传文件
  onChangeFile = async (e) => {
    const { fileInfo } = this.state
    const { files } = e.target
    Toast.loading("附件上传中,请稍等", 0)
    const imgSize = files[files.length - 1].size
    const type = files[files.length - 1].type
    if (type.split('/')[0] !== 'image') return Toast.info('请上传图片', 2)
    if (imgSize > 20 * 1024 * 1024) return Toast.info('请上传小于20M的附件', 2)
    let formData = new FormData()
    formData.append("multipartFile", files[files.length - 1])
    const res = await uploadDisposeFile(formData)
    if (res.code === 20000) {
      Toast.hide()
      Toast.info("上传成功", 1)
      fileInfo.push({ file_name: files[files.length - 1].name, file_url: res.data })
      this.setState({ fileInfo })
    } else {
      Toast.hide()
      Toast.fail(res.message)
    }
  }

  // 放大图片或者视频
  handleImageClick = (fileUrl, type) => {
    this.setState({
      visible: true,
      fileUrl,
      type,
    })
  }

  render() {
    const { data, disposalContent, fileInfo, typeData, detailsState } = this.state
    return (
      <div className='approveDetail hidden_handle' style={{ padding: '0px' }}>
        <div className='approve_detail_head'>
          <div className='approve_detail_left'>
            <div className='approve_detail_left_title'>
              {data.room_name}（{data.class_name}）
            </div>
            <div className='approve_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='approve_list_head_date'>
                {data.building_name}{data.floor_name}
              </div>
            </div>
          </div>
          <div className='approve_detail_right hidden_handle_type'>
            <img src={require('./../../../img/patrol/待处理.png')} alt='' />
          </div>
        </div>
        <div className='approve_detail_cont'>
          <div className='approve_detail_bar' style={{ padding: '0px' }}>
            <div className={data.task_level === 1 ? 'patrol_type_xiao' : data.task_level === 2 ? 'patrol_type_yuan' : ''} style={{ padding: '12px 16px 0px' }}>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>巡检标题</div>
                <div>{data.task_title}</div>
              </div>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>巡检批次</div>
                <div>{data.task_name}</div>
              </div>

              {data.dept_name ? (<div className='approve_detail_item patrol_approve_detail_item'>
                <div>所属院系</div>
                <div>{data.dept_name}</div>
              </div>) : ''}

              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>巡检总负责人</div>
                <div>{data.fzr_name}</div>
              </div>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>巡检人</div>
                <div>{data.user_name}</div>
              </div>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>隐患类型</div>
                <div>
                  {data.details_state === 1 ? '正常' : ''}
                  {data.details_state === 2 ? '一般隐患' : ''}
                  {data.details_state === 3 ? '重大隐患' : ''}
                  {data.details_state === 4 ? '严重隐患' : ''}
                </div>
              </div>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>整改状态</div>
                <div>
                  {data.hidden_result === -2 ? '不属实隐患' : ''}
                  {data.hidden_result === -1 ? '待核实隐患' : ''}
                  {data.hidden_result === 0 ? '待整改' : ''}
                  {data.hidden_result === 1 ? '已整改待确认' : ''}
                  {data.hidden_result === 2 ? '整改完成' : ''}
                  {data.hidden_result === 3 ? '整改不合格' : ''}
                  {data.hidden_result === 4 ? '暂无法整改' : ''}
                  {data.hidden_result === 5 ? '隐患撤回' : ''}
                </div>
              </div>


              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>巡检人签字</div>
                <div>
                  {data.details_qz && data.details_qz.split('/').length > 1 ? (<img src={IP.fileHost + data.details_qz} alt='' style={{ height: '40px' }} onClick={() => {
                    this.setState({
                      visible: true,
                      fileUrl: data.details_qz,
                    })
                  }} />) : data.details_qz}
                </div>
              </div>

            </div>
          </div>


          <div className='patrol_danger'>
            <div className='patrol_danger_head'>
              <div>
                <img src={require('./../../../images/patrol/6.png')} alt='' />
                整改前
              </div>
              <div>
                {moment(data.complete_time).format('YYYY-MM-DD HH:mm')} 提交
              </div>
            </div>
            <div className='patrol_danger_main'>
              {data && data.dangerEntityList && data.dangerEntityList.map((item, key) => {
                return <div className='patrol_danger_item' key={key}>
                  <div className='patrol_danger_title'>
                    <span>隐患{key + 1}：</span>{item.danger_title}
                  </div>
                  <div className='patrol_danger_desc'>
                    {item.danger_content}
                  </div>
                  <div className='patrol_danger_pic'>
                    <img src={IP.fileHost + item.danger_file} alt='' onClick={() => {
                      this.setState({
                        visible: true,
                        fileUrl: item.danger_file,
                        type: 1,
                      })
                    }} />
                  </div>
                </div>
              })}
            </div>
          </div>


          <div className='approve_detail_bar' style={{ padding: '12px 16px 12px' }}>
            {data.details_type === 3 ? (
              <div className='approve_detail_picker'>
                <Picker
                  extra='请选择隐患类型'
                  data={typeData}
                  value={detailsState}
                  cols={1}
                  onChange={val => {
                    data.details_state = Number(val)
                    this.setState({
                      detailsState: val,
                      data
                    })
                  }}
                >
                  <List.Item arrow="horizontal">隐患类型</List.Item>
                </Picker>
              </div>
            ) : ('')}


            <div className='approve_detail_textarea'>
              <div><span style={{ color: 'red' }}>* </span>整改方案</div>
              <div className='approve_detail_textareaItem'>
                <TextareaItem
                  rows={3}
                  placeholder="请输入整改方案"
                  value={disposalContent}
                  onChange={e => this.setState({ disposalContent: e })}
                />
              </div>
            </div>
            {/* 只重大隐患上传附件 */}
            <div className='approve_detail_upload_file'>
              <div className='approve_detail_upload'>
                <div><span style={{ color: 'red' }}>* </span>上传图片</div>
                <div onClick={() => {
                  document.getElementById('handle_file').click()
                }}>上传</div>
              </div>
              <div className='approve_detail_tips'>
                请上传整改方案相关图片，图片可上传多个。
              </div>
              {
                fileInfo.length ? (<div className='approve_detail_file'>
                  {
                    fileInfo.map((item, key) => {
                      return (
                        <div className='approve_detail_textarea_file' key={key}>
                          <div>
                            <img src={require('./../../../img/file_type.png')} alt='' />
                            {item.file_name}
                          </div>
                          <div className='approve_detail_textarea_file_del'>
                            <img src={require('./../../../img/patrol/del.png')} alt='' onClick={() => {
                              this.setState({
                                fileInfo: fileInfo.filter((x, y) => y !== key)
                              })
                            }} />
                          </div>
                        </div>
                      )
                    })
                  }

                </div>) : ('')
              }

            </div>
          </div>
        </div>

        <div className='approveDetail_btn'>
          <div className='approveDetail_btn_list' onClick={() => this.handleSubmit(2)}>暂不整改</div>
          <div className='approveDetail_btn_list' onClick={() => this.handleSubmit(1)}>提交整改</div>
        </div>

        <input type='file' id='handle_file' accept="image/*" onChange={e => this.onChangeFile(e)} hidden onClick={(event) => { event.target.value = null }} />

        {
          this.state.visible ? <div className='detailImg' onClick={() => this.setState({ visible: false })}>
            {this.state.type === 1 ? (<img alt='' src={IP.fileHost + this.state.fileUrl} style={{ width: '80%' }} />) : (<video width="80%" src={IP.fileHost + this.state.fileUrl} poster={IP.fileHost + this.state.fileUrl} controls="controls" controlslist="nodownload noremoteplayback" disablePictureInPicture>
              your browser does not support the video tag
            </video>)}

          </div> : ''
        }


      </div >
    )
  }
}

export default ApproveDetail
