import React, { Component } from 'react'
import { TreeSelect } from 'antd'
import { Picker, List, DatePicker, Toast, InputItem } from 'antd-mobile';
import { gasInfoList, bottleInsert, req_findDepTreeInfo } from './../../../../api/gas'
import './add.less'
import moment from 'moment'

const { TreeNode } = TreeSelect;

class Add extends Component {
  state = {
    bottle_number: '',
    bottle_specs: '',
    bottle_type: '',
    typeData: [
      {
        label: '999',
        value: '999',
      },
      {
        label: '99999',
        value: '99999',
      },
    ],
    bottle_color: '',
    sourceData: [
      {
        label: '校级',
        value: 1,
      },
      {
        label: '院级',
        value: 2,
      },
      {
        label: '赠送',
        value: 3,
      },
      {
        label: '其他',
        value: 4,
      },
    ],
    bottle_source: '',
    gas_id: '',
    gasData: [],
    bottle_capacity: '',
    gas_number: '',
    bottle_aerate_time: '',
    room_id: undefined,
    roomData: []
  }

  componentDidMount = () => {
    document.title = '新增气体钢瓶'
    window.scrollTo(0, 0)
    this._gasInfoList()
    this.findSelectLabRoom()
  }

  _gasInfoList = async () => {
    const res = await gasInfoList()
    if (res.code === 20000) {
      this.setState({
        gasData: res.data.map(item => {
          return {
            label: item.gas_name,
            value: item.id,
          }
        })
      })
    }
  }

  // 查询所有实验室分室信息
  findSelectLabRoom = async () => {
    const res = await req_findDepTreeInfo()
    if (res.code === 20000) {
      let menuList = res.data.map(item => {
        return {
          key: item.dwdm,
          title: item.dwmc,
          parentid: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
          disabled: item.bs === 3 ? false : true
        }
      })
      menuList = menuList.map(item => {
        item.children = menuList.filter(p => p.parentid === item.key)
        return item
      }).filter(p => p.parentid === '0')
      this.setState({
        roomData: menuList,
      })
    }
  }

  // 提交
  submit = async () => {
    let { bottle_number,
      bottle_specs,
      bottle_type,
      bottle_color,
      bottle_source,
      gas_id,
      bottle_capacity,
      gas_number,
      bottle_aerate_time,
      room_id,
      gasData } = this.state
    if (bottle_number === '' || bottle_specs === '' || bottle_type === '' || bottle_color === '' || bottle_source === '' || gas_id === '' || bottle_capacity === '' || gas_number === '' || bottle_aerate_time === '' || room_id === undefined) {
      Toast.info('请补全上述内容！')
      return false
    }
    bottle_source = bottle_source.join(',')
    bottle_type = bottle_type.join(',')
    gas_id = gas_id.join(',')
    const params = {
      bottle_number,
      bottle_specs,
      bottle_type,
      bottle_color,
      bottle_source,
      gas_id,
      bottle_capacity,
      gas_number,
      bottle_aerate_time,
      room_id,
      bottle_aerate_time: moment(bottle_aerate_time).format('YYYY-MM-DD'),
      gas_name: gasData.find(x => String(x.value) === gas_id).label,
      gas_type_name: gasData.find(x => String(x.value) === gas_id).label
    }
    const res = await bottleInsert(params)
    if (res.code === 20000) {
      Toast.info("提交成功！")
      setTimeout(() => {
        this.props.history.goBack()
      }, 2000);
    }
  }

  render() {
    const { depTreeData, bottle_number, bottle_specs, bottle_type, typeData, bottle_color, sourceData, bottle_source, gas_id, gasData, bottle_capacity, gas_number, room_id, roomData } = this.state
    const loop = data =>
      data.map(item => {
        if (item.children && item.children.length) {
          return (
            <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled} />;
      });
    return (
      <div className='gas_manage_add'>
        <div className='gas_manage_add_title'>

        </div>
        <div className='gas_manage_add_form'>
          <div className='gas_manage_add_picker'>
            <Picker
              data={gasData}
              value={gas_id}
              cols={1}
              onChange={(e) => {
                this.setState({
                  gas_id: e
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>气体</List.Item>
            </Picker>
          </div>
        </div>
        <div className='gas_manage_add_form'>
          <div className='gas_manage_add_input'>
            <InputItem placeholder="请输入" value={bottle_number} onChange={(e) => {
              this.setState({
                bottle_number: e
              })
            }}><span style={{ color: 'red' }}>* </span>钢瓶编号</InputItem>
          </div>
          <div className='gas_manage_add_input'>
            <InputItem placeholder="请输入" value={bottle_specs} onChange={(e) => {
              this.setState({
                bottle_specs: e
              })
            }}><span style={{ color: 'red' }}>* </span>钢瓶规格</InputItem>
          </div>
          <div className='gas_manage_add_picker'>
            <Picker
              data={typeData}
              value={bottle_type}
              cols={1}
              onChange={(e) => {
                this.setState({
                  bottle_type: e
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>钢瓶分类</List.Item>
            </Picker>
          </div>
          <div className='gas_manage_add_input'>
            <InputItem placeholder="请输入" value={bottle_color} onChange={(e) => {
              this.setState({
                bottle_color: e
              })
            }}><span style={{ color: 'red' }}>* </span>钢瓶颜色</InputItem>
          </div>
          <div className='gas_manage_add_picker'>
            <Picker
              data={sourceData}
              value={bottle_source}
              cols={1}
              onChange={(e) => {
                this.setState({
                  bottle_source: e
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>钢瓶来源</List.Item>
            </Picker>
          </div>

        </div>
        <div className='gas_manage_add_form'>


          <div className='gas_manage_add_input'>
            <InputItem placeholder="请输入" value={bottle_capacity} type="digit" onChange={(e) => {
              this.setState({
                bottle_capacity: e < 0 ? 0 : e
              })
            }}><span style={{ color: 'red' }}>* </span>容量（L）</InputItem>
          </div>
          <div className='gas_manage_add_input'>
            <InputItem placeholder="请输入" value={gas_number} onChange={(e) => {
              this.setState({
                gas_number: e < 0 ? 0 : e
              })
            }}><span style={{ color: 'red' }}>* </span>气量（L）</InputItem>
          </div>

          <div className='gas_manage_add_picker gas_manage_add_picker_date'>
            <DatePicker
              mode="date"
              title="选择时间"
              extra="请选择"
              value={this.state.bottle_aerate_time}
              onChange={date => this.setState({ bottle_aerate_time: date })}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>充装气体日期</List.Item>
            </DatePicker>
          </div>

          <div className='gas_manage_add_tree'>
            <div>
              <span style={{ color: 'red' }}>* </span>所属实验室
            </div>
            <TreeSelect
              showSearch
              treeNodeFilterProp='title'
              placeholder="请选择"
              style={{ width: '100%' }}
              value={room_id}
              onChange={(e) => {
                this.setState({
                  room_id: e,
                })
              }}
            >
              {loop(roomData)}
            </TreeSelect>
          </div>
        </div>


        <div className='gas_manage_list_but gas_manage_list_but_two'>
          <div onClick={() => {
            window.history.go(-1);
          }}>取消</div>
          <div onClick={() => {
            this.submit()
          }}>确定</div>
        </div>

      </div >
    )
  }
}

export default Add
