import React, { Component } from 'react'
import { DatePicker, List, Toast } from 'antd-mobile'
import { listCmdRecordZnkt } from '../../../api/api'
import moment from 'moment'
import './operation_records.less'

class operationRecords extends Component {

  state = {
    start_date: '',
    end_date: '',
    deviceImei: '',
    data: [],
  }

  componentDidMount = () => {
    document.title = "操作记录"
    this.setState({
      start_date: new Date(this.fun_date(-2)),
      end_date: new Date(),
      deviceImei: this.props.match.params.id,
    }, () => {
      this.fetch()
    })
  }

  fetch = async () => {
    Toast.loading('', 0)
    const { deviceImei, start_date, end_date } = this.state
    const res = await listCmdRecordZnkt({ deviceImei, startTime: start_date ? moment(start_date).format('YYYY-MM-DD') : '', endTime: end_date ? moment(end_date).format('YYYY-MM-DD') : '' })
    if (res.code === 20000) {
      Toast.hide()
      this.setState({
        data: res.data
      })
    }
  }

  // 获取几天前的日期
  fun_date = (num) => {
    var date1 = new Date()
    var date2 = new Date(date1)
    date2.setDate(date1.getDate() + num)
    //num是正数表示之后的时间，num负数表示之前的时间，0表示今天
    var time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate()
    return time2
  }

  render () {
    const { start_date, end_date, data } = this.state
    return (
      <div className='operation_records'>
        <div className='operation_records_head'>
          <div className='operation_records_head_date'>
            <DatePicker
              mode="date"
              title="开始时间"
              extra="开始时间"
              value={start_date}
              maxDate={end_date ? end_date : null}
              onChange={date => {
                this.setState({
                  start_date: date
                }, () => {
                  this.fetch()
                })
              }}
            >
              <List.Item arrow="horizontal"></List.Item>
            </DatePicker>
            <div style={{ background: '#f4f6f8' }}>至</div>
            <DatePicker
              mode="date"
              title="结束时间"
              extra="结束时间"
              value={end_date}
              minDate={start_date ? start_date : null}
              onChange={date => {
                this.setState({
                  end_date: date
                }, () => {
                  this.fetch()
                })
              }}
            >
              <List.Item arrow="horizontal"></List.Item>
            </DatePicker>
          </div>

        </div>
        <div className='operation_records_body ac'>
          {
            (data || []).length ? data.map(item => {
              return <div className='operation_records_item' key={item.record_id}>
                <div class="top">
                  <div>{item.cmd_operate_time ? moment(item.cmd_operate_time).format('YYYY-MM-DD HH:mm:ss') : ''}</div>
                  <div>{item.cmd_operate_user}</div>
                </div>
                <div className='content'>
                  <div className='cmd_data_status'>
                    {item.cmd_data === 'on' && '开机'}
                    {item.cmd_data === 'off' && '关机'}
                    {item.cmd_data === 'col' && '制冷'}
                    {item.cmd_data === 'hea' && '制热'}
                    {item.cmd_data === 'plus' && '温度加'}
                    {item.cmd_data === 'reduce' && '温度减'}
                    {item.cmd_data === 'wind' && '风速'}
                  </div>
                  <div className='other_status'>
                    <div className=''>
                      {item.cmd_state === 1 ? '制冷' : item.cmd_state === 2 ? '制热' : '--'}
                    </div>
                    <div className=''>
                      {
                        item.cmd_wind === 0 ? '自动' : item.cmd_wind === 1 ? '低速' : item.cmd_wind === 2 ? '中速' : item.cmd_wind === 3 ? '高速' : '--'
                      }
                    </div>
                    <div className=''>
                      {item.cmd_temp !== -1 ? `${item.cmd_temp}℃` : '--'}
                    </div>
                  </div>
                </div>

              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
      </div>
    )
  }
}

export default operationRecords
