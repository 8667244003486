import React, { Component } from 'react'
import { InputItem, TextareaItem, Toast } from 'antd-mobile'
import { req_findApprovalDetailsl, req_submitApprovalResult } from '../../../api/cgyy'
import moment from 'moment'
import './submit.css'

export default class componentName extends Component {
  state = {
    yyrq: '',
    yysj: '',
    yylx: 1,//状态
    yydw: '',//单位
    yymc: '',//姓名
    yysjh: '',//手机号
    yyscrs: 0,//随行人数
    yybz: '',//备注
    yyzt: '',
    yyztState: '',
    pr_id: '',
    pr_approval_opinion: '',
    pr_approval_result: '',
    xndjdw: '',
    yylxState: '',
  }

  componentDidMount = () => {
    document.title = "审批详情"
    this.fetch(this.props.match.params.id)
  }

  fetch = async (prId) => {
    const res = await req_findApprovalDetailsl({ prId })
    if (res.code === 20000) {
      const data = res.data
      let yyzt = ''
      if (data.yyzt === 0) {
        yyzt = '待审批'
      }
      if (data.yyzt === 1) {
        yyzt = '审批中'
      }
      if (data.yyzt === 2) {
        yyzt = '审批通过'
      }
      if (data.yyzt === 3) {
        yyzt = '审批驳回'
      }
      if (data.yyzt === 4) {
        yyzt = '预约取消'
      }
      this.setState({
        yyrq: data.yyrq,
        yysj: data.yysj,
        yylx: data.yylx === 1 ? '校内团队' : '校外团队',//状态
        yydw: data.yydw,//单位
        yymc: data.yymc,//姓名
        yysjh: data.yysjh,//手机号
        yyscrs: data.yyscrs,//随行人数
        yybz: data.yybz,//备注
        yyzt: yyzt,
        yyztState: data.yyzt,
        pr_id: data.pr_id,
        pr_approval_opinion: data.pr_approval_opinion,
        pr_approval_result: data.pr_approval_result,
        yylxState: data.yylx,
        xndjdw: data.xndjdw,
      })
    }
  }

  // 审批
  submitApprovalResult = async (pr_approval_result) => {
    const { pr_id, pr_approval_opinion } = this.state
    if (pr_approval_result === 3 && pr_approval_opinion === '') {
      Toast.info("请输入驳回意见！")
      return
    }
    const res = await req_submitApprovalResult({
      pr_id,
      pr_approval_result,
      pr_approval_opinion
    })
    if (res.code === 20000) {
      Toast.success(res.message)
      // this.fetch(this.props.match.params.id)
      setTimeout(() => {
        this.props.history.push('/module/cgyy/approval')
      }, 3000)
    }
  }

  render () {
    const { yyrq, yysj, yylx, yydw, yymc, yysjh, yyscrs, yybz, yyzt, yyztState, pr_approval_opinion, pr_approval_result, xndjdw, yylxState } = this.state
    return (
      <div className="submit">
        <div className='sub_form'>
          <div className='sub_form_item sub_form_item_view'>
            <InputItem disabled value={yyrq + ' ' + yysj} >参观时间</InputItem>
          </div>
          <div className='sub_form_item sub_form_item_view'>
            <InputItem disabled value={yylx}>团队类型</InputItem>
          </div>

          <div className='sub_form_item sub_form_item_view'>
            <InputItem disabled value={yymc}>申请人姓名</InputItem>

          </div>

          <div className='sub_form_item sub_form_item_view'>
            <InputItem disabled value={yydw}>申请人单位</InputItem>
          </div>
          <div className='sub_form_item sub_form_item_view'>
            <InputItem disabled value={yysjh}>联系电话</InputItem>
          </div>
          <div className='sub_form_item sub_form_item_view'>
            <InputItem disabled value={yyzt}>审批状态</InputItem>
          </div>

          <div className='sub_form_item sub_form_item_view'>
            <InputItem disabled value={yyscrs + '人'}>随行人数</InputItem>
          </div>
          {yylxState === 2 ? (<div className='sub_form_item sub_form_item_view'>
            <InputItem disabled value={xndjdw}>校内对接单位</InputItem>
          </div>
          ) : ''}

          <div className='sub_form_item_text sub_form_item_view' style={{ marginBottom: '0px' }}>
            <div>备注</div>
            <TextareaItem
              disabled
              rows={4}
              value={yybz}
            />
          </div>

          <div className='sub_form_item_text'>
            <div>审批意见</div>
            <TextareaItem
              placeholder={pr_approval_result === 1 ? '请输入审批意见' : ''}
              disabled={pr_approval_result !== 1}
              rows={4}
              value={pr_approval_opinion}
              onChange={(item) => {
                this.setState({
                  pr_approval_opinion: item
                })
              }}
            />
          </div>
        </div>

        {pr_approval_result === 1 ? (<div className='cgyy_approval_but'>
          <div onClick={() => {
            this.submitApprovalResult(3)
          }}>
            审批驳回
          </div>
          <div onClick={() => {
            this.submitApprovalResult(2)
          }}>
            审批通过
          </div>
        </div>
        ) : ''}

      </div >
    )
  }
}
