import React, { Component } from 'react'
import { Select } from 'antd'
import { SearchBar } from 'antd-mobile'
import { listAppSpace, findAppSpaceDevice } from '../../../api/api'
import './room.less'

class RoomManager extends Component {

  state = {
    searchContent: '', // 搜索值
    data: [], // 房间信息
    buildData: [], // 所有楼宇
    floorData: [], // 楼宇下的楼层
    roomData: [], // 楼层下的房间
    buildId: undefined, // 楼宇id
    floorId: undefined, // 楼层id
    roomId: undefined, // 房间id
  }

  componentDidMount = () => {
    document.title = "房间管理"
    this.fetch()
    this.fetchBuild()
  }

  fetch = async () => {
    const { searchContent, buildId, floorId, roomId } = this.state
    const res = await findAppSpaceDevice({ spaceId: roomId ? roomId : floorId ? floorId : buildId ? buildId : '', keys: searchContent })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  // 查询楼宇
  fetchBuild = async () => {
    const res = await listAppSpace({ bs: 1 })
    if (res.code === 20000) {
      this.setState({
        buildData: res.data
      })
    }
  }

  // 查询楼层
  fetchFloor = async sjid => {
    const res = await listAppSpace({ bs: 2, sjid })
    if (res.code === 20000) {
      this.setState({
        floorData: res.data
      })
    }
  }

  // 查询房间
  fetchRoom = async sjid => {
    const res = await listAppSpace({ bs: 3, sjid })
    if (res.code === 20000) {
      this.setState({
        roomData: res.data
      })
    }
  }

  // 搜索房间
  handleChange = e => {
    this.setState({
      searchContent: e
    }, () => {
      this.fetch()
    })
  }

  handleCancel = () => {
    this.setState({
      searchContent: ''
    }, () => {
      this.fetch()
    })
  }

  // 选择楼宇
  handChangeBuild = e => {
    this.setState({
      buildId: e,
      floorId: undefined,
      roomId: undefined,
      floorData: [],
      roomData: []
    }, () => {
      this.fetch()
      this.fetchFloor(e)
    })
  }

  // 选择楼层
  handleChangeFloor = e => {
    this.setState({
      floorId: e,
      roomId: undefined,
      roomData: [],
    }, () => {
      this.fetch()
      this.fetchRoom(e)
    })
  }

  // 选择房间
  handleChangeRoom = e => {
    this.setState({
      roomId: e,
    }, () => {
      this.fetch()
    })
  }

  render() {
    const { roomData, data, buildData, floorData, buildId, floorId, roomId } = this.state
    return (
      <div className='roomManager'>
        <div className='roomManager_header'>
          <div className='roomManager_search'>
            <SearchBar placeholder="请输入房间信息" onCancel={() => this.handleCancel()} onChange={e => this.handleChange(e)} />
          </div>
          <div className='roomManager_cascader'>
            <Select value={buildId} placeholder='选择楼宇' onChange={e => this.handChangeBuild(e)} allowClear>
              {
                (buildData || []).map(item => {
                  return <Select.Option key={item.wz_id} value={item.wz_id}>{item.wz_name}</Select.Option>
                })
              }
            </Select>
            <Select value={floorId} placeholder='选择楼层' onChange={e => this.handleChangeFloor(e)} allowClear>
              {
                (floorData || []).map(item => {
                  return <Select.Option key={item.wz_id} value={item.wz_id}>{item.wz_name}</Select.Option>
                })
              }
            </Select>
            <Select value={roomId} placeholder='选择房间' onChange={e => this.handleChangeRoom(e)} allowClear>
              {
                (roomData || []).map(item => {
                  return <Select.Option key={item.wz_id} value={item.wz_id}>{item.wz_name}</Select.Option>
                })
              }
            </Select>
          </div>
        </div>
        <div className='roomManager_con'>
          {
            (data || []).length ? data.map(item => {
              return <div className='roomManager_con_list' key={item.wzId} onClick={() => this.props.history.push({ pathname: '/module/roomManager', state: { wzId: item.wzId, wzName: item.wzName } })}>
                <div className='roomManager_con_list_left'>
                  <div className='roomManager_con_list_left_name'>{item.wzName}</div>
                  <div className='roomManager_con_list_left_addr'>{item.buildingName}</div>
                </div>
                <div className='roomManager_con_list_right'>
                  <div className='roomManager_con_list_right_num'>{item.totalNum || 0}</div>
                  <div className='roomManager_con_list_right_desc'>设备总数</div>
                </div>
              </div>
            }) : <div className='not-yet'>--暂无房间信息--</div>
          }
        </div>
      </div>
    )
  }
}

export default RoomManager
