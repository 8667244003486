import http from '../config/httpconfig'

// 申购
/**
 * 查询危化品申请数据
 */
export const findPageSgInfo = params => http.get('/whp/sginfo/findPageSgInfo', params)

/**
 * 查询中心或实验室
 */
export const findZxOrRoomByDwOrZx = params => http.get('/system/laboratoryInfo/findZxOrRoomByDwOrZx', params)

/**
 * 查询字典类型下的字典项（不分页）
 */
export const listDict = params => http.get('/whp/dictionaryData/list', params)

/**
 * 查询组织架构信息
 */
export const req_findDepartmentInfo = params => http.get('/system/departmentinfo/findDepartmentInfo', params)

/**
 * 查询化学品库(分页)
 */
export const findPageWhpInfo = params => http.get('/whp/whpinfo/findPageWhpInfo', params)

/**
 * 新增危化品申请信息
 */
export const saveSgInfo = params => http.jsonPost('/whp/sginfo/saveSgInfo', params)

/**
 * 根据申购单号查询申购详情
 */
export const findSgInfoById = params => http.get('/whp/sginfo/findSgInfoById', params)

/**
 * 根据入库单号查询申购详情
 */
export const findRgInfoDetailsById = params => http.get('/whp/rkinfo/findRgInfoDetailsById', params)

/**
 * 获取审批是多人共同审批还是单人审批
 */
export const findMasterProcess = params => http.get('/whp/approvalmaster/findMasterProcess', params)

/**
 * 获取下一个审批人员信息
 */
export const findNextApprovalUser = params => http.get('/whp/approvalprocessrecord/findNextApprovalUser', params)

/**
 * 查询危化品审批列表
 */
export const listMyApprovalRecord = params => http.get('/whp/approvalprocessrecord/listMyApprovalRecord', params)

/**
 * 查询记录及审批明细
 */
export const listApprovalRecordById = params => http.get('/whp/approvalprocessrecord/listApprovalRecordById', params)

/**
 * 提交审批
 */
export const submitApprovalResult = params => http.post('/whp/approvalprocessrecord/submitApprovalResult', params)

/**
 * 会签审批判断是否是最后一个人审批（共同审批）
 */
export const estimateCountersign = params => http.get('/whp/approvalprocessrecord/estimateCountersign', params)


/**
 * 订单确认、确认收货
 */
export const submitSgqr = params => http.get('/whp/sginfo/submitSgqr', params)

/**
 * 查询入库信息
 */
export const findPageRkInfo = params => http.get('/whp/rkinfo/findPageRkInfo', params)

/**
 * 查询仓库信息（不分页）
 */
export const findRepository = params => http.get('/whp/repositoryinfo/findRepository', params)

/**
 * 查询仓库下的管理员
 */
export const findRepositoryUser = params => http.get('/whp/repositoryinfo/findRepositoryUser', params)

/**
 * 新增入库信息
 */
export const saveRkInfo = params => http.jsonPost('/whp/rkinfo/saveRkInfo', params)

/**
 * 查询待入库申购单信息
 */
export const findDaiRuKuSgInfo = params => http.get('/whp/sginfo/findDaiRuKuSgInfo', params)


/**
 * 查询待入库申购危化品信息
 */
export const findDaiRuKuSgWhpInfo = params => http.get('/whp/sginfo/findDaiRuKuSgWhpInfo', params)


/**
 * 查询领用登记信息
 */
export const findPageLyInfo = params => http.get('/whp/lysqInfo/findPageLyInfo', params)

/**
 * 查询教师
 */
export const req_findDepartmentInfoToTeacher = params => http.get('/system/departmentinfo/findAllDepartmentInfoToTeacher', params)


/**
 * 新增领用登记信息
 */
export const addLysqInfo = params => http.jsonPost('/whp/lysqInfo/addLysqInfo', params)


/**
 * 查询仓库库存信息
 */
export const findPageKcInfoByCk = params => http.get('/whp/kcinfo/findPageKcInfoByCk', params)

/**
 * 根据领用单号查询领用详情（详情）
 */
export const findLyInfoDetailsById = params => http.get('/whp/lysqInfo/findLyInfoDetailsById', params)

/**
 * 查询库存信息
 */
export const findPageKcInfo = params => http.get('/whp/kcinfo/findPageKcInfo', params)

/**
 * 根据id查询库存详细信息
 */
export const findKcDetailInfoById = params => http.get('/whp/kcinfo/findKcDetailInfoById', params)

/**
 * 查询申购明细表
 */
export const findPageSgWhpInfo = params => http.get('/whp/sgwhpinfo/findPageSgWhpInfo', params)

/**
 * 查询入库明细表
 */
export const findPageRkWhpInfo = params => http.get('/whp/rkwhpinfo/findPageRkWhpInfo', params)


/**
 * 查询可领用出库的数据
 */
export const listWaitCk = params => http.get('/whp/lysqInfo/listWaitCk', params)

/**
 * 取消领用
 */
export const cancelLysq = params => http.get('/whp/lysqInfo/cancelLysq', params)

/**
 * 查询领用明细表
 */
export const findPageLysqWhpInfo = params => http.get('/whp/lysqInfo/findPageLysqWhpInfo', params)

/**
 * 查询仓库下某个危化品的明细
 */
export const findKcmxInfo = params => http.get('/whp/kcmxinfo/findKcmxInfo', params)

/**
 * 提交出库
 */
export const lysqWhpCkInfoLyck = params => http.jsonPost('/whp/lysqWhpCkInfo/lyck', params)


/**
 * 查询待归还领用单
 */
export const findDghLyinfo = params => http.get('/whp/lysqInfo/findDghLyinfo', params)


/**
 * 查询待归还领用单详情
 */
export const findDghDetails = params => http.get('/whp/lysqInfo/findDghDetails', params)

/**
 * 新增归还信息
 */
export const addGhsqInfo = params => http.jsonPost('/whp/ghsqInfo/addGhsqInfo', params)

/**
 * 查询归还信息
 */
export const findPageGhInfo = params => http.get('/whp/ghsqInfo/findPageGhInfo', params)

/**
 * 根据归还单号查询归还详情（详情）
 */
export const findGhInfoDetailsById = params => http.get('/whp/ghsqInfo/findGhInfoDetailsById', params)

/**
 * 查询归还明细表
 */
export const findPageGhWhpInfo = params => http.get('/whp/ghsqInfo/findPageGhWhpInfo', params)

/**
 * 查询出库明细表
 */
export const findPageCkWhpInfo = params => http.get('/whp/lysqWhpCkInfo/findPageCkWhpInfo', params)

/**
 * 查询使用记录
 */
export const findPageSyjlInfo = params => http.get('/whp/syjlinfo/findPageSyjlInfo', params)

/**
 * 根据使用单号查询详情信息
 */
export const findSyjlnfoDetails = params => http.get('/whp/syjlinfo/findSyjlnfoDetails', params)

/**
 * 查询待填写领用单信息
 */
export const findCkInfo = params => http.get('/whp/syjlinfo/findCkInfo', params)


/**
 * 根据领用单号查询出库危化品信息
 */
export const findCkWhpInfoDetails = params => http.get('/whp/syjlinfo/findCkWhpInfoDetails', params)

/**
 * 新增使用记录
 */
export const saveSyjlInfo = params => http.jsonPost('/whp/syjlinfo/saveSyjlInfo', params)

/**
 * 查询报废危化品信息
 */
export const findPageBfWhpInfo = params => http.get('/whp/bfwhpinfo/findPageBfWhpInfo', params)

/**
 * 根据仓库id查询仓库下的危化品
 */
export const findKcInfoByCk = params => http.get('/whp/kcinfo/findKcInfoByCk', params)

/**
 * 新增报废危化品信息
 */
export const saveBfWhpInfo = params => http.jsonPost('/whp/bfwhpinfo/saveBfWhpInfo', params)

/**
 * 危化品报废详情
 */
export const findBfWhpInfoDetails = params => http.post('/whp/bfwhpinfo/findBfWhpInfoDetails', params)

/**
 * 首页统计
 */
export const tjMobile = params => http.get('/whp/tj/mobile', params)

/**
 * 保存签字信息
 */
export const saveQzInfo = params => http.jsonPost('/whp/lysqqzinfo/saveQzInfo', params)

/**
 * 申购通过数量
 */
export const countRkNum = params => http.get('/whp/sginfo/countRkNum', params)

/**
 * 查询是否有签字权限
 */
export const findAuthQz = params => http.get('/whp/lysqqzinfo/findAuthQz', params)

/**
 * 上传文件
 */
export const uploadSgFile = params => {
    return http.jsonPost('/whp/sginfo/uploadSgFile', params)
}

// 盘点管理

/**
 * 查询盘点信息
 */
export const findPdInfo = params => http.get('/whp/pdinfo/findPdInfo', params)

/**
 * 新增盘点信息
 */
export const addPdInfo = params => http.jsonPost('/whp/pdinfo/addPdInfo', params)

/**
 * 删除盘点信息
 */
export const deletePdInfo = params => http.get('/whp/pdinfo/deletePdInfo', params)

/**
 * 查询盘点报告
 */
export const findPdInfoDetails = params => http.post('/whp/pdinfo/findPdInfoDetails', params)

// 轨迹

/**
 * 查询盘点报告
 */
export const findKcbdGjInfo = params => http.get('/whp/kcbdinfo/findKcbdGjInfo', params)

/**
 * 查询库存变动轨迹详细信息
 */
export const findKcbdGjDetailsInfo = params => http.get('/whp/kcbdinfo/findKcbdGjDetailsInfo', params)

/**
 * 获取签名
 */
export const getSign = params => http.get('/mobile/mobilelogin/getSign', params)

/**
 * 根据库存明细id查询库存详情
 */
export const findKcmxDetails = params => http.get('/whp/kcmxinfo/findKcmxDetails', params)