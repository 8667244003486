import React, { Component } from 'react';
import Tabbar from '../../components/tabbar'
import { findMyInfo, findModuleInfo, findSecondModuleInfo } from '../../api/api'
import './index.less'
import IP from '../../config/config';
import cookie from 'react-cookies'

class My extends Component {

  state = {
    userInfo: {},
    moduleList: [],
    moduleListNum: [],
  }

  componentDidMount = () => {
    window.document.title = '我的'
    this.fetch()
    this.fetchModule()
    this.fetchModuleNum()
  }

  // 查询个人信息
  fetch = async () => {
    const res = await findMyInfo()
    if (res.code === 20000) {
      this.setState({
        userInfo: res.data || {}
      })
    }
  }

  // 查询模块
  fetchModule = async () => {
    const res = await findModuleInfo()
    if (res.code === 20000) {
      this.setState({
        moduleList: res.data
      })
    }
  }

  // 查询mobule_url数字模块
  fetchModuleNum = async () => {
    const res = await findSecondModuleInfo()
    if (res.code === 20000) {
      this.setState({
        moduleListNum: res.data
      })
    }
  }

  render() {
    const { userInfo, moduleList, moduleListNum } = this.state
    return (
      <div className='my'>
        <div className='my_header'>
          <div className='my_header_logo'>
            {
              userInfo.zp ? <img src={IP.fileHost + userInfo.zp} alt="" /> :
                <img src={require('../../images/avatar.png')} alt="" />
            }
          </div>
          <div className='my_header_name'>{userInfo.xm ? userInfo.xm : userInfo.user_nick}</div>
          <div className='my_header_dep'>{cookie.load('sf') == 2 ? userInfo.yxmc : userInfo.dwmc}</div>
        </div>
        <div className='my_con'>
          {/* <div className='my_con_list' onClick={() => cookie.load('sf') == 2 ? this.props.history.push('/my/studentInfo') : this.props.history.push('/myInfo')}> */}
          <div className='my_con_list' onClick={() => this.props.history.push('/myInfo')}>
            <div className='my_con_list_left'>
              <img alt='' src={require('../../images/grxx.png')} />
              <span>个人信息</span>
            </div>
            <img alt='' src={require('../../images/more2.png')} />
          </div>
          {
            moduleList.find(x => x.module_url === '/module/appointment') ? <div className='my_con_list' onClick={() => this.props.history.push({ pathname: '/module/appointmentRecord' })}>
              <div className='my_con_list_left'>
                <img alt='' src={require('../../images/jsyy2.png')} />
                <span>实验室预约</span>
              </div>
              <img alt='' src={require('../../images/more2.png')} />
            </div> : ''
          }
          {
            moduleListNum.find(x => x.module_url === '10') ? <div className='my_con_list' onClick={() => this.props.history.push({ pathname: '/my/riskReport' })}>
              <div className='my_con_list_left'>
                <img alt='' src={require('../../images/myfxsb.png')} />
                <span>风险上报</span>
              </div>
              <img alt='' src={require('../../images/more2.png')} />
            </div> : ''
          }
          {
            moduleList.find(x => x.module_url === '/module/deviceAppointment') ? <div className='my_con_list' onClick={() => this.props.history.push({ pathname: '/module/deviceRecord' })}>
              <div className='my_con_list_left'>
                <img alt='' src={require('../../images/mysbyy.png')} />
                <span>设备预约</span>
              </div>
              <img alt='' src={require('../../images/more2.png')} />
            </div> : ''
          }
        </div>
        <div className='block_50' />
        { /* 标签栏 */}
        <Tabbar {...this.props} />
      </div>
    );
  }
}

export default My;