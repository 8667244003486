
import React, { Component } from 'react';
import { Icon, SearchBar, List, Picker, InputItem, Button, WhiteSpace, Toast, Modal, DatePicker } from 'antd-mobile';
import { findOrder } from '../../../../api/repairApi'
import { createForm } from 'rc-form';
import moment from 'moment'
import './index.less'

class WorkTracking extends Component {
    state = {
        visible: false,
        dataList: [],
        state: 0,
        userAccount: '',
        userName: '',
        startTime: '',
        endTime: '',
        pickerDataVal: [],
        pickerData: [
            { label: '审批中', value: '0' },
            { label: '审批完成', value: '1' },
            { label: '审批拒接', value: '2' },
            { label: '维修完成', value: '3' },
            { label: '工单完成', value: '4' },
        ],
    }
    componentDidMount() {
        window.document.title = '工单跟踪'
        this.findOrder()
    }

    //查询所有任务
    findOrder = () => {
        Toast.loading('加载中...', 0)
        const { userAccount, userName, startTime, endTime, pickerDataVal } = this.state
        const sTime = startTime ? moment(startTime).format('YYYY-MM-DD') : ''
        const eTime = endTime ? moment(endTime).format('YYYY-MM-DD') : ''
        findOrder({ userAccount, userName, startTime: sTime, endTime: eTime, state: pickerDataVal[0] }).then(res => {
            if (res.code === 20000) {
                Toast.hide()
                this.setState({
                    dataList: res.data || [],
                    visible: false
                })
            }
        })
    }
    onSelect = async (otp) => {
        await this.findAppWorkTracking(otp.props.value)
        await this.setState({
            visible: false
        })
    }
    render() {
        const { dataList, state, pickerData, visible, userAccount, userName, startTime, endTime, pickerDataVal } = this.state
        const style = (state) => {
            if (state === 0) {//0待保养 1 保养中 2 已完成
                return 'content_title_botton_dai'
            } else if (state === 2) {
                return 'content_title_botton_yi'
            } else {
                return 'content_title_botton_over'
            }
        }
        const orderState = (index) => {
            switch (index) {
                case 0:
                    return '审批中';
                    break;
                case 1:
                    return '审批完成';
                    break;
                case 2:
                    return '审批拒绝';
                    break;
                case 3:
                    return '维修完成';
                    break;
                case 4:
                    return '工单完成';
                    break;
                default:
                    return '';
                    break;

            }
        }
        return (
            <div className="WorkTracking">
                <div style={{ zIndex: '100' }}
                    onClick={(e) => {
                        e.preventDefault()
                        this.setState({
                            visible: true,
                            userAccount: '',
                            userName: '',
                            startTime: '',
                            endTime: '',
                            pickerDataVal: []
                        })
                    }}>
                    <SearchBar disabled placeholder="搜索工单" />
                </div>

                <div className="WorkTracking_box">
                    <div className="WorkTracking_content">
                        {
                            (dataList || []).map((item, index) => {
                                return <div key={index} onClick={() => { this.props.history.push(`/module/smartRepair/workTrackingOrders/${item.order_id}`) }} className="content">
                                    <div className="content_title">
                                        <div className="content_title_code">{item.order_id}</div>
                                        <div className={style(item.order_state)}>
                                            <span className="span_02">{orderState(item.order_state)}</span>
                                            {
                                                item.order_jianyi ? <img className='span_img' alt='' src={require('../../../../images/smartRepair/star.png')} /> : ''
                                            }
                                            <span className="span_03"><Icon type='right' /></span>
                                        </div>
                                    </div>
                                    <div className='maintenanceTask_cont'>
                                        <div className='maintenanceTask_cont_item'>
                                            <div>类型</div>
                                            <div>{item.fault_type_name}</div>
                                        </div>
                                        <div className='maintenanceTask_cont_item'>
                                            <div>问题描述</div>
                                            <div>{item.description}</div>
                                        </div>
                                        <div className='maintenanceTask_cont_item'>
                                            <div>位置</div>
                                            <div>{(item.building_name ? item.building_name : '') + ' ' + (item.room_name ? item.room_name : '')}</div>
                                        </div>
                                        <div className='maintenanceTask_cont_item'>
                                            <div>报修人</div>
                                            <div>{item.user_name}</div>
                                        </div>

                                    </div>
                                    <div className="maintenanceTask_time">
                                        {moment(item.create_time).format('YYYY-MM-DD ')}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
                <Modal
                    popup
                    visible={visible}
                    onClose={() => {
                        this.setState({
                            visible: false
                        })
                    }}
                    className='modal'
                    animationType="slide-up"
                    afterClose={() => {
                        this.setState({
                            visible: false
                        })
                    }}
                >
                    <List renderHeader={() => <div>查询</div>} className="popup-list">
                        <InputItem placeholder="请输入工号" value={userAccount} style={{ textAlign: 'right', paddingRight: 8 }} onChange={(e) => {
                            this.setState({
                                userAccount: e
                            })
                        }}>
                            <span className="blank-fillFormServer">工号</span>
                        </InputItem>
                        <WhiteSpace size="sm" />
                        <InputItem placeholder="请输入姓名" value={userName} style={{ textAlign: 'right', paddingRight: 8 }} onChange={(e) => {
                            this.setState({
                                userName: e
                            })
                        }}>
                            <span className="blank-fillFormServer">姓名</span>
                        </InputItem>
                        <WhiteSpace size="sm" />
                        <DatePicker
                            mode="date"
                            title="日期"
                            extra="请选择时间"
                            value={startTime}
                            onChange={date => {
                                this.setState({ startTime: date })
                            }}
                        >
                            <List.Item arrow="horizontal" style={{ paddingLeft: '20px' }}>开始时间</List.Item>
                        </DatePicker>
                        <WhiteSpace size="sm" />
                        <DatePicker
                            mode="date"
                            title="日期"
                            extra="请选择时间"
                            value={endTime}
                            onChange={date => {
                                this.setState({ endTime: date })
                            }}
                        >
                            <List.Item arrow="horizontal" style={{ paddingLeft: '20px' }}>结束时间</List.Item>
                        </DatePicker>
                        <WhiteSpace size="sm" />
                        <Picker extra="请选择状态" data={pickerData} cols={1} value={pickerDataVal} onChange={(val) => {
                            this.setState({
                                pickerDataVal: val
                            })
                        }} className="forss">
                            <List.Item arrow="horizontal">状态</List.Item>
                        </Picker>
                        <List.Item>
                            <Button type="primary" style={{ marginTop: '34px', width: '96%', background: '#2bc17b', border: 'none' }} onClick={() => {
                                this.findOrder()
                            }}>确认</Button>
                        </List.Item>
                    </List>
                </Modal>
                {dataList.length === 0 ? (<div className="title-bottom">--暂无工单--</div>) : ''}
            </div>
        );
    }
}

export default createForm()(WorkTracking);