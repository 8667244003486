import React, { Component } from 'react';
import { InputItem, List, DatePicker, Toast } from 'antd-mobile'
import { lockLsIssuePassWord } from '../../../api/api'
import copy from 'copy-to-clipboard'
import cookie from 'react-cookies'
import moment from 'moment'
import './door_lock.less'

class TemporaryAuthorization extends Component {

  state = {
    startTime: '',
    endTime: '',
    password: '',
    pwdLength: 6,
  }

  componentDidMount = () => {
    window.addEventListener('focusin', this.setScrollTop)
    window.addEventListener('focusout', this.setScrollTop)
    this.setState({
      startTime: new Date(),
      endTime: new Date(new Date().getTime() + 1800000),
      password: this.mathRand(),
      pwdLength: cookie.load('pwd')
    })
  }

  setScrollTop = () => {
    let scrollTop = document.body.scrollTop + document.documentElement.scrollTop
    window.scrollTo(0, scrollTop)
  }

  // 输入密码
  handleChangePwd = e => {
    const { pwdLength } = this.state
    let value = e
    if (e.length > pwdLength) {
      value = value.slice(0, pwdLength)
    }
    this.setState({
      password: value
    })
  }

  // 随机生成六位数字
  handleMathRand = () => {
    this.setState({
      password: this.mathRand()
    })
  }

  // 随机生成六位数字
  mathRand = () => {
    const { pwdLength } = this.state
    var num = "";
    for (var i = 0; i < pwdLength; i++) {
      num += Math.floor(Math.random() * 10);
    }
    return num
  }

  // 复制分享
  handleCopy = () => {
    const { password } = this.state
    copy(password);
    Toast.success('复制成功');
  }

  // 保存
  handleSubmit = async () => {
    const { startTime, endTime, password } = this.state;
    const res = await lockLsIssuePassWord({
      deviceId: this.props.match.params.id,
      password,
      startTime: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
      endTime: moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
    })
    if (res.code === 20000) {
      Toast.success('密码设置成功')
    }
  }

  render() {
    const { password, startTime, endTime } = this.state
    return (
      <div className='temporary'>
        <div className='temporary-content'>
          <div className='temporary-content-detail'>
            <InputItem type='number' value={password} onChange={e => this.handleChangePwd(e)} />
            <div onClick={() => this.handleMathRand()}>随机生成</div>
          </div>
        </div>
        <div className='temporary-copy' onClick={() => this.handleCopy()}>复制分享</div>
        <div className='temporary-time'>
          <List>
            <DatePicker
              mode="datetime"
              minDate={new Date()}
              value={startTime}
              onChange={date => this.setState({ startTime: date })}
            >
              <List.Item arrow="horizontal">生效时间</List.Item>
            </DatePicker>
            <DatePicker
              mode="datetime"
              minDate={new Date()}
              value={endTime}
              onChange={date => this.setState({ endTime: date })}
            >
              <List.Item arrow="horizontal">失效时间</List.Item>
            </DatePicker>
          </List>
        </div>
        <div className='temporary-btn'>
          <div className='temporary-btn-content' onClick={() => this.handleSubmit()}>保 存</div>
        </div>
      </div>
    );
  }
}

export default TemporaryAuthorization;