import React, { Component } from 'react'
import { SearchBar, Toast } from 'antd-mobile'
import { Badge } from 'antd'
import { mobileFindLogo, findSlideshowInfo, findModuleInfo, findMobileNoticeInfo, findSafetyVideo, countMessageFxgk, countMessageJsyy, findSecondModuleInfo } from '../../api/api'
import Tabbar from '../../components/tabbar'
import IP from '../../config/config'
import moment from 'moment'
import './index.less'

class Home extends Component {
  state = {
    imgUrl: '', // logo
    tabs: [],
    moduleList: [], // 模块列表
    noticeData: [], // 通知公告列表
    videoList: [], // 安全视频列表
    checkedTab: 1,
    fxgkSum: 0,
    jsyySum: 0,
    imgTheme: '',
    serviceModuleList: [],
    equipModuleList: []
  }

  componentDidMount = () => {
    document.title = "星向晨物联"
    this.fetchLogo()
    this.fetchModule()
    this.fetchModuleNum()
    this.fetchImgTheme()
  }

  // 查询logo
  fetchLogo = async () => {
    const res = await mobileFindLogo()
    if (res.code === 20000) {
      this.setState({
        imgUrl: res.data.school_logo
      })
    }
  }

  // 查询实验室预约数据值
  fetchJsyy = async () => {
    const res = await countMessageJsyy()
    if (res.code === 20000) {
      this.setState({
        jsyySum: res.data || 0
      })
    }
  }

  // 查询风险管控数据值
  fetchFxgk = async () => {
    const res = await countMessageFxgk()
    if (res.code === 20000) {
      this.setState({
        fxgkSum: res.data || 0
      })
    }
  }


  // 查询主题图
  fetchImgTheme = async () => {
    const res = await findSlideshowInfo()
    if (res.code === 20000) {
      if (res.data && res.data.slideshow_url) {
        this.setState({
          imgTheme: IP.fileHost + res.data.slideshow_url
        })
        console.log(this.state.imgTheme, 'imgtheme')
      } else {
      }
    }
  }

  // 查询模块
  fetchModule = async () => {
    const res = await findModuleInfo()
    let service = []
    let equip = []
    if (res.code === 20000) {
      res.data.forEach(item => {
        if (item.module_type - 0 === 1) {
          service.push(item)
        } else if (item.module_type - 0 === 2)
          equip.push(item)
      })
      this.setState({
        serviceModuleList: service,
        equipModuleList: equip
      })
    }
  }

  // 查询mobule_url数字模块
  fetchModuleNum = async () => {
    const res = await findSecondModuleInfo()
    if (res.code === 20000) {
      const arr = res.data
      if (arr.find(x => x.module_url === '1' || x.module_url === '2')) {
        this.fetchFxgk()
      }
      if (arr.find(x => x.module_url === '3')) {
        this.fetchJsyy()
      }

      if (arr.find(x => x.module_url === '11')) {
        this.fetchNotice()
        this.setState({
          tabs: [
            { id: 1, name: '通知公告', checked: true },
            { id: 2, name: '安全视频', checked: false }
          ],
          checkedTab: 1
        })
      } else {
        this.fetchVideo()
        this.setState({
          tabs: [
            { id: 2, name: '安全视频', checked: true }
          ],
          checkedTab: 2
        })
      }
      // 1风险上报  2风险变更 3实验室预约 4风险上报 5通知公告
    }
  }

  // 查询通知公告
  fetchNotice = async () => {
    const res = await findMobileNoticeInfo({ flag: 1 })
    if (res.code === 20000) {
      this.setState({
        noticeData: res.data.slice(0, 2)
      })
    }
  }

  // 安全视频
  fetchVideo = async () => {
    const res = await findSafetyVideo({ flag: 1 })
    if (res.code === 20000) {
      this.setState({
        videoList: res.data
      })
    }
  }

  // 切换类型
  handleChangeTab = item => {
    const { tabs } = this.state
    this.setState({
      tabs: tabs.map(x => {
        x.checked = x.id === item.id ? true : false
        return x
      }),
      checkedTab: item.id
    })
    if (item.id === 1) {
      this.fetchNotice()
    }
    if (item.id === 2) {
      this.fetchVideo()
    }
  }

  // 跳转模块
  handleSkipModule = item => {
    if (item.module_url.indexOf("module") !== -1) {
      this.props.history.push(item.module_url)
    } else {
      Toast.info('模块开发中...')
    }
  }

  render () {
    const { imgUrl, moduleList, serviceModuleList, equipModuleList, videoList, tabs, noticeData, checkedTab, fxgkSum, jsyySum, imgTheme } = this.state
    return (
      <div className='home' id='test'>
        <div className='home-header'>
          <img className='home-header-imgTheme' src={imgTheme} />
          <SearchBar placeholder="搜索通知公告" onFocus={() => {
            this.props.history.push('/notice')
          }} />
          {
            noticeData.length ? <div className="home-header-notice" onClick={() => {
              this.props.history.push(`/notice/detail/${noticeData[0].notice_id}`)
            }}>
              <div className='left'>
                <img src={require('../../img/notice.png')} alt="通知公告" />
                <span>{noticeData[0].notice_title}</span>
              </div>
              <span className='notice-icon'></span>
            </div> : ''
          }
        </div>
        {
          (serviceModuleList || []).length ?
            <div className="home-service">
              {
                (serviceModuleList || []).map(item => {
                  return <div className="ev" key={item.module_id} onClick={() => this.handleSkipModule(item)}>
                    <div className='func-block'>
                      <div className="block-left">
                        <p className='module-title'>{item.module_name}</p>
                        <span className='module-desc'>{item.module_desc}</span>
                      </div>
                      <img className='block-right' src={IP.fileHost + item.module_imge} alt="" />
                    </div>
                  </div>
                })
              }
            </div> : ''
        }
        {
          (equipModuleList || []).length ? <div className='home-module'>
            <div className='home-module-con'>
              {
                (equipModuleList || []).map(item => {
                  return <div className='home-module-list' key={item.module_id} onClick={() => this.handleSkipModule(item)}>
                    <img alt='' src={IP.fileHost + item.module_imge} />
                    <span>{item.module_name}</span>
                  </div>
                })
              }
            </div>
          </div> : ''
        }

        <div className='home-safe'>
          <div className='home-safe-con'>
            <div className='home-safe-con-header'>
              <div className='home-safe-con-header-left'>
                {
                  (tabs || []).map(item => {
                    return <div className={`home-safe-con-header-left-list ${item.checked ? 'home-safe-con-header-left-list-active' : ''}`} key={item.id} onClick={() => this.handleChangeTab(item)}>
                      <span>{item.name}</span>
                      <div className={`${item.checked ? 'home-safe-con-header-left-list-line' : ''}`}></div>
                    </div>
                  })
                }
              </div>
              <div className='home-safe-con-header-right' onClick={() => checkedTab === 1 ? this.props.history.push('/notice') : this.props.history.push('/safety')}>
                <span>查看全部</span>
                <img alt='' src={require('../../img/drop-right.png')} />
              </div>
            </div>
            {checkedTab === 1 && !noticeData.length && <div className='not-yet' style={{ paddingTop: "20px", paddingBottom: "20px" }}>--暂无数据--</div>}
            {checkedTab === 2 && !videoList.length && <div className='not-yet' style={{ paddingTop: "20px", paddingBottom: "20px" }}>--暂无数据--</div>}
            {
              checkedTab === 1 && noticeData.length ? <div className='home-notice'>
                {
                  noticeData.map(item => {
                    return <div className='home-notice-list' key={item.notice_id} onClick={() => this.props.history.push(`/notice/detail/${item.notice_id}`)}>
                      <div className='home-notice-list-con'>
                        <div className='home-notice-list-desc'>{item.notice_title}</div>
                        <div className='home-notice-list-time'>{moment(item.release_time).format('YYYY年MM月DD日 HH:mm')}</div>
                      </div>
                      {
                        item.notice_url ? <img src={IP.fileHost + item.notice_url} alt='' /> : <img src={require('../../images/008.png')} alt='' />
                      }
                    </div>
                  })
                }
              </div> : ''
            }
            {
              checkedTab === 2 && videoList.length ? <div className='home-safe-con-detail'>
                {
                  (videoList || []).map(item => {
                    return <div className='home-safe-con-detail-list' key={item.video_id}>
                      <div className='home-safe-con-detail-list-video'>
                        <video src={IP.fileHost + item.video_url} poster={IP.fileHost + item.video_image} controls="controls" controlslist="nodownload noremoteplayback" disablePictureInPicture>
                          your browser does not support the video tag
                        </video>
                      </div>
                      <div className='home-safe-con-detail-list-title'>{item.video_name}</div>
                      {/* <div className='home-safe-con-detail-list-eyes'>5.4万次观看</div> */}
                    </div>
                  })
                }
              </div> : ''
            }
          </div>
        </div>
        { /* 标签栏 */}
        <Tabbar {...this.props} />
      </div>
    )
  }
}

export default Home
