import React, { Component } from 'react'
import { Switch } from 'antd'
import { findAirSwitchDevice, issueKgRelay, issueAllKgRelay } from '../../../api/api'
import '../controller/controller.less'

class LightSwitch extends Component {

  state = {
    airSwitch: [], // 空开设备
  }

  componentDidMount = () => {
    document.title = "智慧照明-设备列表"
    this.fetch(this.props.match.params.id)
  }

  fetch = async relayId => {
    const res = await findAirSwitchDevice({ relayId })
    if (res.code === 20000) {
      this.setState({
        airSwitch: res.data
      })
    }
  }
  // 一键开关
  handleAirSwitch = async (e, item, relayState) => {
    e.stopPropagation()
    const res = await issueAllKgRelay({ relayId: item.relay_id, mac: item.relay_mac, relayAddress: item.relay_address, relayState })
    if (res.code === 20000) {
      this.fetch(this.props.match.params.id)
    }
  }
  // 开关
  handleChangeSwitch = async item => {
    const res = await issueKgRelay({ relayId: this.state.airSwitch.relay_id, mac: this.state.airSwitch.relay_mac, deviceNumber: item.device_number, deviceState: item.device_state === 1 ? 2 : 1 })
    if (res.code === 20000) {
      this.fetch(this.props.match.params.id)
    }
  }

  // 操作记录
  handleOperate = (e, item) => {
    e.stopPropagation()
    this.props.history.push(`/module/airSwitch/lightOperation/${this.state.airSwitch.relay_mac}/${item.device_number}`)
  }

  render () {
    const { airSwitch } = this.state
    const { relay_name, building_name, floor_name, room_name, relay_state, startNum, totalNum, deviceInfoEntities } = airSwitch
    return (
      <div className='controller light_switch'>
        <div className={`controller_switch_bg ${relay_state === 1 ? 'on' : 'off'}`}>
          <div className='top'>
            <img src={require('../../../img/controll/light-top.png')} alt="" />
          </div>
        </div>
        <div className="whole_switch">
          <div className='controller_list_item'>
            <div className='controller_item_title'>
              {relay_name}
            </div>
            <div className='controller_item_room'>
              {building_name}-{floor_name}-{room_name}
            </div>
            <div className='controller_item_status'>
              <div className={relay_state === 1 ? 'controller_item_status_onLine' : 'controller_item_status_offLine'} >
                <div></div>
                <div>{relay_state === 1 ? '设备在线' : '设备离线'}</div>
              </div>
              <div className='controller_item_line'></div>
              <div className={'controller_item_status_use ' + (startNum ? 'hasOpen' : 'noOpen')}>
                <span >{startNum ? "已开启" : "未开启"}</span>
                <span>{startNum}/{totalNum}</span>
              </div>
            </div>

            <div className='controller_item_but'>
              <div onClick={e => this.handleAirSwitch(e, airSwitch, 2)}>
                <img alt='' src={require('../../../img/controll/OFF.png')} />
                一键关闭
              </div>
              <div onClick={e => this.handleAirSwitch(e, airSwitch, 1)}>
                <img alt='' src={require('../../../img/controll/ON.png')} />
                一键开启
              </div>
            </div>
          </div>
          {(deviceInfoEntities || []).length ?
            deviceInfoEntities.map(i => {
              return <div className="switch_list_item " key={i.device_id}>
                <div className="operate">
                  <div className="left">
                    <div>{i.device_name}</div>
                    <span className={i.device_state === 1 ? 'hasOpen' : ''}>{i.device_state === 1 ? '已开启' : i.device_state === 2 ? "未开启" : 0}</span>
                  </div>
                  <div className="right" onClick={() => this.handleChangeSwitch(i)}>
                    <img src={require(`../../../img/controll/switch_${i.device_state === 1 ? 'open' : i.device_state === 2 ? "close" : ''}.png`)} alt="" />
                  </div>
                </div>
                <div className="record" onClick={e => this.handleOperate(e, i)}>
                  <div className='left'>操作记录</div>
                  <span className="right">
                    <img src={require('../../../img/drop-right.png')} alt="" />
                  </span>
                </div>
              </div>
            }) : ''}
        </div>
        {/* {
          (airSwitch || []).length ? airSwitch.map(item => {
            return <div className='airSwitch_list' key={item.device_id}>
              <div className='airSwitch_list_header'>
                <div className='airSwitch_list_header_left'>
                  <div className='airSwitch_list_header_top_name'>{item.device_name}</div>
                </div>
                <div className='airSwitch_list_header_right' onClick={e => e.stopPropagation()}>
                  <Switch checkedChildren="开" unCheckedChildren="关" disabled={item.relay_state === 2 ? true : false} checked={item.device_state === 1 ? true : false} onChange={() => this.handleChangeSwitch(item)} />
                </div>
              </div>
              <div className='airSwitch_list_desc'>{item.roomName}</div>
              <div className='airSwitch_list_footer'>
                <div className='airSwitch_list_footer_list' onClick={e => this.handleOperate(e, item)}>
                  <img alt='' src={require('../../../images/czjl.png')} />
                  <span>操作记录</span>
                </div>
              </div>
            </div>
          }) : <div className='not-yet'>--暂无数据--</div>
        } */}
      </div >



    )
  }
}

export default LightSwitch