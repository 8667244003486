import React, { Component } from 'react';
import { List, ImagePicker, WhiteSpace, TextareaItem, Button, Steps, Toast, Modal } from 'antd-mobile';
import { createForm } from 'rc-form';
import moment from 'moment'
import cookie from 'react-cookies'
import './../commen.less'
import { saveApprovalRecord, findOrderByOrderId, req_updateOrderInvoice, completeOrder, orderJianyi, uploadRepairOrderImgae } from '../../../../api/repairApi'
import IP from '../../../../config/config'
const Item = List.Item;
const Step = Steps.Step;
const alert = Modal.alert;

class Details extends Component {
    state = {
        data: {},
        price: '',
        id: '',
        description: '',
        money: '',
        visable2: false,
        visible: false,
        imgUrl: '',
        order_jianyi: '', // 维修意见
        repairInfo: {}, // 报修详情
        processInfo: [], // 工单流程
        fileInfo: []
    }
    componentDidMount() {
        window.document.title = '维修工单'
        this.setState({
            id: this.props.match.params.id
        })
        findOrderByOrderId({ orderId: this.props.match.params.id }).then(res => {
            if (res.code === 20000) {
                this.setState({
                    data: res.data ? res.data : {},
                    repairInfo: res.data ? (res.data.repairOrderEntity || {}) : {},
                    processInfo: res.data ? (res.data.approvalRecordEntities || []) : [],
                })
            }
        })
    }

    // 提交
    handleSubmit = async () => {
        const { fileInfo } = this.state
        if (fileInfo.length === 0) {
            Toast.fail('请上传发票!', 2)
            return
        }
        const invoice_url = fileInfo.map(x => x.file_url).join(',')
        const res = await req_updateOrderInvoice({
            order_id: this.props.match.params.id,
            invoice_url,
        })
        if (res.code === 20000) {
            Toast.success(res.message, '2')
            this.props.history.goBack()
        } else {
            Toast.fail(res.message, '2')
        }
        // const { description, money } = this.state
        // if (this.state.money != '') {
        //     if (isNaN(this.state.money)) {
        //         Toast.fail('输入费用格式错误,重新输入!', 2)
        //         return
        //     }
        //     updateOrderMoney({ orderId: this.state.id, money: money, description }).then(res => {
        //         if (res.code === 20000) {
        //             Toast.success(res.message, '2')
        //             this.props.history.goBack()
        //         } else {
        //             Toast.fail(res.message, '2')
        //         }
        //     })
        // } else {
        //     Toast.offline("请输入您的维修费用！！！")
        // }
    }
    saveApprovalRecord = (index) => {
        const { id, reason, data } = this.state
        var data1 = {
            order_id: id,
            approval_state: index,
            approval_number: cookie.load('userData').id,
            approval_id: cookie.load('userData').id,
            approval_name: cookie.load('userData').name,
            order_user_id: data.user_id ? data.user_id : '',
            order_department_id: data.department_id ? data.department_id : '',
        }
        if (index === 0) {
            data1.refuse_reason = reason
        }
        saveApprovalRecord(data1).then(res => {
            if (res.code === 20000) {
                Toast.success(res.message, 2)
                this.props.history.goBack()
            }
        })
    }
    handleImageClick = (index, files) => {
        this.setState({
            visible: true,
            imgUrl: files.find(x => x.id === index).url
        })
    }
    handleChangejianyi = e => {
        this.setState({
            order_jianyi: e
        })
    }

    // 上传文件
    onChangeFile = async (e) => {
        let { files } = e.target
        Toast.loading("附件上传中,请稍等", 0)
        let formData = new FormData()
        formData.append("multipartFile", files[files.length - 1])
        formData.append("flag", 3)
        const res = await uploadRepairOrderImgae(formData)
        if (res.code === 20000) {
            Toast.hide()
            Toast.info("上传成功", 1)
            const url = [{ file_name: files[files.length - 1].name, file_url: res.data }]
            this.setState({ fileInfo: url })
        } else {
            Toast.hide()
            Toast.fail(res.message)
        }
    }

    render() {
        const { data, visible, imgUrl, repairInfo, processInfo, visable2, fileInfo } = this.state
        return (
            <div className="details">
                <List className="my-list">
                    <Item extra={data.repairOrderEntity && data.repairOrderEntity.user_name}>用户姓名</Item>
                </List>
                <WhiteSpace size="sm" /><WhiteSpace size="sm" />
                <List className="my-list">
                    <Item extra={data.repairOrderEntity && data.repairOrderEntity.user_phone_number}>联系方式</Item>
                </List>
                <WhiteSpace size="sm" /><WhiteSpace size="sm" />
                <List className="my-list">
                    <Item extra={(data.repairOrderEntity && data.repairOrderEntity.building_name ? data.repairOrderEntity.building_name : '') + ' ' + (data.repairOrderEntity && data.repairOrderEntity.room_name ? data.repairOrderEntity.room_name : '')}>维修地点</Item>
                </List>
                <WhiteSpace size="sm" /><WhiteSpace size="sm" />
                <List className="my-list">
                    <Item extra={data.repairOrderEntity && data.repairOrderEntity.fault_type_name}>故障类型</Item>
                </List>
                <WhiteSpace size="sm" /><WhiteSpace size="sm" />
                <List className="my-list">
                    <Item extra={data.repairOrderEntity && data.repairOrderEntity.equipment_name ? data.repairOrderEntity.equipment_name : ''}>设备名称</Item>
                </List>
                {/* <WhiteSpace size="sm" /><WhiteSpace size="sm" />
                    <List  className="my-list">
                        <Item extra={data.repairOrderEntity&&data.repairOrderEntity.equipment_product?data.repairOrderEntity.equipment_product:''}>设备厂家</Item>
                    </List>
                    <WhiteSpace size="sm" /><WhiteSpace size="sm" />
                    <List  className="my-list">
                        <Item extra={data.repairOrderEntity&&data.repairOrderEntity.equipment_type?data.repairOrderEntity.equipment_type:''}>设备类型</Item>
                    </List> */}
                <WhiteSpace size="sm" /><WhiteSpace size="sm" />
                <List className="my-list">
                    <Item extra={data.repairOrderEntity && data.repairOrderEntity.equipment_number ? data.repairOrderEntity.equipment_number : ''}>设备编号</Item>
                </List>
                {/* <WhiteSpace size="sm" /><WhiteSpace size="sm" />
                    <List  className="my-list">
                        <Item extra={data.repairOrderEntity&&data.repairOrderEntity.equipment_model?data.repairOrderEntity.equipment_model:''}>设备型号</Item>
                    </List>
                    <WhiteSpace size="sm" /><WhiteSpace size="sm" />
                    <List  className="my-list">
                        <Item extra={data.repairOrderEntity&&data.repairOrderEntity.equipment_brand?data.repairOrderEntity.equipment_brand:''}>设备品牌</Item>
                    </List> */}
                <WhiteSpace size="sm" /><WhiteSpace size="sm" />
                <List className="my-list">
                    <Item extra={data.repairOrderEntity && data.repairOrderEntity.money !== 0 ? data.repairOrderEntity.money : ''}>维修费用</Item>
                </List>
                <WhiteSpace size="sm" /><WhiteSpace size="sm" />
                <List className="my-list">
                    <Item extra={data.repairOrderEntity && data.repairOrderEntity.work_desc !== null ? data.repairOrderEntity.work_desc : ''}>维修内容</Item>
                </List>
                <WhiteSpace size="sm" /><WhiteSpace size="sm" />
                <div className="textarea">
                    <div className="textarea_title">
                        问题描述
                    </div>
                    <div>
                        <TextareaItem
                            value={data.repairOrderEntity && data.repairOrderEntity.description ? data.repairOrderEntity.description : ''}
                            autoHeight
                            labelNumber={5}
                            disabled
                        />
                    </div>
                    {
                        data.repairOrderEntity && data.repairOrderEntity.order_jianyi ? <div>
                            <div className="textarea_title">
                                建议反馈
                            </div>
                            <div>
                                <TextareaItem
                                    value={data.repairOrderEntity && data.repairOrderEntity.order_jianyi ? data.repairOrderEntity.order_jianyi : ''}
                                    autoHeight
                                    labelNumber={5}
                                    disabled
                                />
                            </div>
                        </div> : ''
                    }
                    <div>
                        <ImagePicker
                            className="details-upload"
                            disableDelete={true}
                            selectable={false}
                            onImageClick={this.handleImageClick}
                            files={data.repairOrderEntity && data.repairOrderEntity.pic_url ? (data.repairOrderEntity && data.repairOrderEntity.pic_url.split(',').map((item, index) => { return { url: IP.fileHost + item, id: index } })) : []}
                        />
                    </div>
                </div>

                <WhiteSpace size="sm" /><WhiteSpace size="sm" />

                <div className="textarea">
                    <div className="textarea_title">
                        维修后图片
                    </div>
                    <ImagePicker
                        className="details-upload"
                        disableDelete={true}
                        selectable={false}
                        onImageClick={this.handleImageClick}
                        files={data.repairOrderEntity && data.repairOrderEntity.repair_pic_url ? (data.repairOrderEntity && data.repairOrderEntity.repair_pic_url.split(',').map((item, index) => { return { url: IP.fileHost + item, id: index } })) : []}
                    />
                </div>

                <WhiteSpace size="sm" /><WhiteSpace size="sm" />

                <div className='approve_detail_upload_file' style={{ padding: '12px 16px' }}>
                    <div className='approve_detail_upload' style={{ marginBottom: '12px' }}>
                        <div><span style={{ color: '#fff' }}>* </span>发票</div>
                        <div style={{ display: 'none' }}></div>
                    </div>
                    {
                        data.repairOrderEntity ? (<div className='approve_detail_file'>
                            {
                                data.repairOrderEntity.invoice_url ? (
                                    <div className='approve_detail_textarea_file' onClick={() => {
                                        window.open(IP.fileHost + data.repairOrderEntity.invoice_url)
                                    }}>
                                        <div>
                                            <img src={require('./../../../../img/file_type.png')} alt='' />
                                            维修发票
                                        </div>
                                    </div>
                                ) : ''
                            }

                        </div>) : ('')
                    }

                </div>

                <WhiteSpace size="sm" /><WhiteSpace size="sm" />
                <div className='repair_approval_detail_desc'>
                    <div className='repair_approval_detail_desc_title'>工单流程</div>
                    <div className='repair_approval_process'>
                        {
                            (processInfo || []).length ? <Steps size="small" current={processInfo.length}>
                                {
                                    processInfo.map((item, index) => <Step
                                        key={index}
                                        icon={
                                            item.state === 2 ? <img alt='' src={require('../../../../images/new/25.png')} /> : <img alt='' src={require('../../../../images/new/24.png')} />
                                        }
                                        title={
                                            <div className='step_title'>
                                                <span>审批人：{item.approval_name}</span>
                                                {
                                                    item.approval_state === 1 ? <span className='step_title_tg'>通过</span> : (
                                                        item.approval_state === 0 ? <span className='step_title_jj'>拒绝</span> : ''
                                                    )
                                                }
                                            </div>
                                        }
                                        description={
                                            <div className='step_desc'>
                                                <div className='step_desc_time'>
                                                    {
                                                        item.approval_time ? (`审批时间：${moment(item.approval_time).format('YYYY-MM-DD HH:mm:ss')}`) : ''
                                                    }
                                                </div>
                                                {/* {
                          item.refuse_reason ? <div className='step_desc_bh'>{`审批意见：${item.refuse_reason}`}</div> : ''
                        } */}
                                                {
                                                    item.sign_url ? <div className='step_desc_qm'>
                                                        <div>审批签名：</div>
                                                        <img alt='' src={IP.fileHost + item.sign_url} />
                                                    </div> : ''
                                                }
                                            </div>
                                        } />)
                                }
                            </Steps> : <div style={{ textAlign: 'center' }}>--暂无审批--</div>
                        }
                        <Steps size="small" current={3}>
                            {
                                repairInfo.order_state >= 1 && repairInfo.order_state !== 2 ? <Step
                                    icon={
                                        repairInfo.work_name ? <img alt='' src={require('../../../../images/new/24.png')} /> : <img alt='' src={require('../../../../images/new/25.png')} />
                                    }
                                    title={
                                        <div className='step_title'>
                                            <span>维修人：{repairInfo.work_name ? repairInfo.work_name : '待指派'}</span>
                                        </div>
                                    }
                                    description={
                                        <div className='step_desc'>
                                            <div className='step_desc_time'>
                                                {
                                                    repairInfo.work_name && repairInfo.appoint_time ? (`指派时间：${moment(repairInfo.appoint_time).format('YYYY-MM-DD HH:mm:ss')}`) : ''
                                                }
                                            </div>
                                        </div>
                                    }
                                /> : ''
                            }
                            {
                                repairInfo.order_state >= 1 && repairInfo.order_state !== 2 && repairInfo.work_name ? <Step
                                    icon={
                                        repairInfo.order_state >= 3 ? <img alt='' src={require('../../../../images/new/24.png')} /> : <img alt='' src={require('../../../../images/new/25.png')} />
                                    }
                                    title={
                                        <div className='step_title'>
                                            <span>维修状态：{repairInfo.order_state >= 3 ? '已维修' : '待维修'}</span>
                                        </div>
                                    }
                                    description={
                                        <div className='step_desc'>
                                            <div className='step_desc_time'>
                                                {
                                                    repairInfo.order_state >= 3 && repairInfo.end_time ? (`维修时间：${moment(repairInfo.end_time).format('YYYY-MM-DD HH:mm:ss')}`) : ''
                                                }
                                            </div>
                                        </div>
                                    }
                                /> : ''
                            }
                            {
                                repairInfo.order_state >= 3 ? <Step
                                    icon={
                                        repairInfo.order_state === 4 ? <img alt='' src={require('../../../../images/new/24.png')} /> : <img alt='' src={require('../../../../images/new/25.png')} />
                                    }
                                    title={
                                        <div className='step_title'>
                                            <span>工单状态：{repairInfo.order_state === 4 ? '已完成' : '待确认'}</span>
                                        </div>
                                    }
                                    description={<div></div>}
                                /> : ''
                            }
                        </Steps>
                    </div>
                </div>
                <WhiteSpace size="lg" />
                <div className="details_sub">
                    {
                        // 判断没有维修工权限（role_state!==1和3）,并且当前用户是此工单报修人，并且工单状态为3，已维修
                        data.repairOrderEntity && data.repairOrderEntity.user_id === cookie.load('userData').id && data.repairOrderEntity.order_state == 3 && cookie.load('userData').dwdm != '999' ? <Button type="primary" onClick={() => {
                            alert('工单确认', '', [
                                { text: '取消', onPress: () => { } },
                                {
                                    text: '确认', onPress: () => {
                                        Toast.loading('上传中！', 0)
                                        completeOrder({ orderId: this.state.id }).then(res => {
                                            if (res.code === 20000) {
                                                Toast.hide()
                                                Toast.success(res.message, 2)
                                                this.props.history.goBack()
                                            }
                                        })
                                    }
                                },
                            ])
                        }}>工单确认</Button> : (data.repairOrderEntity && data.repairOrderEntity.order_state === 4 && (!data.repairOrderEntity.order_jianyi) ? <Button type="primary" onClick={() => {
                            alert('建议反馈', <div className='repair_device'>
                                <TextareaItem
                                    count={255}
                                    rows={8}
                                    placeholder="请输入建议反馈"
                                    onChange={e => this.handleChangejianyi(e)}
                                />
                            </div>, [
                                { text: '取消', onPress: () => { } },
                                {
                                    text: '确认', onPress: () => {
                                        if (this.state.order_jianyi == '') {
                                            Toast.offline("请输入建议反馈")
                                            return
                                        }
                                        Toast.loading('上传中！', 0)
                                        orderJianyi({ orderId: this.state.id, orderJainyi: this.state.order_jianyi }).then(res => {
                                            if (res.code === 20000) {
                                                Toast.hide()
                                                Toast.success(res.message, 2)
                                                this.props.history.goBack()
                                            }
                                        })
                                    }
                                },
                            ])
                        }}>建议反馈</Button> : data.repairOrderEntity && data.repairOrderEntity.invoice_url === '' ? <Button type="primary" onClick={() => {
                            this.setState({
                                visable2: true,
                                fileInfo: []
                            })
                        }}>上传发票</Button> : <Button type="primary" onClick={() => this.props.history.goBack()}>返回</Button>)
                    }
                </div>
                <WhiteSpace size="lg" />
                {
                    visible ? <div className='detailImg' onClick={() => this.setState({ visible: false })}>
                        <img alt='' src={imgUrl} />
                    </div> : ''
                }



                <input type='file' id='handle_file' onChange={e => this.onChangeFile(e)} hidden onClick={(event) => { event.target.value = null }} />

                <Modal
                    visible={visable2}
                    popup
                    animationType="slide-up"
                    onClose={() =>
                        this.setState({ visable2: false, money: "", description: "" })
                    }
                    title={<div style={{ textAlign: "center", paddingTop: '12px' }}>上传发票</div>}
                >
                    <div className="inputModal theDetails3_modal">
                        <div className='approve_detail_upload_file'>
                            <div className='approve_detail_upload'>
                                <div><span style={{ color: '#fff' }}>* </span>上传附件</div>
                                <div onClick={() => {
                                    document.getElementById('handle_file').click()
                                }}>上传</div>
                            </div>
                            <div className='approve_detail_tips'>
                                请上传发票，发票只允许上传一个。
                            </div>
                            {
                                fileInfo.length ? (<div className='approve_detail_file'>
                                    {
                                        fileInfo.map((item, key) => {
                                            return (
                                                <div className='approve_detail_textarea_file' key={key}>
                                                    <div>
                                                        <img src={require('./../../../../img/file_type.png')} alt='' />
                                                        {item.file_name}
                                                    </div>
                                                    <div className='approve_detail_textarea_file_del'>
                                                        <img src={require('./../../../../img/patrol/del.png')} alt='' onClick={() => {
                                                            this.setState({
                                                                fileInfo: fileInfo.filter((x, y) => y !== key)
                                                            })
                                                        }} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>) : ('')
                            }

                        </div>

                        <div
                            className="theDetails_sub"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "30px",
                                padding: "0 14px 20px",
                            }}
                        >
                            <Button
                                type="primary"
                                onClick={() => this.handleSubmit()}
                                style={{
                                    width: "100%",
                                    height: "40px",
                                    lineHeight: "40px",
                                    padding: "0 10px",
                                    fontSize: "14px",
                                }}
                            >
                                提交
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default createForm()(Details);