import React, { Component } from 'react'
import { DatePicker, List } from 'antd-mobile'
import { statisticsOrderNumByDept, statisticsOrderNumByType, statisticsOrderMoneyByDept } from '../../../../api/repairApi'
import ReactEcharts from 'echarts-for-react';
import moment from 'moment'
import './index.less'

class operationRecords extends Component {

  state = {
    start_date: '',
    end_date: '',
    yxData: [],
    typeTotal: 0,
    typeData: [],
    jeData: [],
    jeSum: 0,
  }

  componentDidMount = () => {
    this.setState({
      start_date: new Date(this.fun_date(-180)),
      end_date: new Date(),
    }, () => {
      this.fetchYx()
      this.fetchType()
      this.fetchJe()
    })
  }

  // 获取几天前的日期
  fun_date = (num) => {
    var date1 = new Date();
    var date2 = new Date(date1);
    date2.setDate(date1.getDate() + num);
    //num是正数表示之后的时间，num负数表示之前的时间，0表示今天
    var time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();
    return time2;
  }

  // 按院系统计工单数量
  fetchYx = async () => {
    const { start_date, end_date } = this.state
    const res = await statisticsOrderNumByDept({ startDate: moment(start_date).format('YYYY-MM-DD'), endDate: moment(end_date).format('YYYY-MM-DD') })
    if (res.code === 20000) {
      this.setState({
        yxData: res.data.map(x => {
          x.name = x.department_name.split("(")[0]
          return x
        })
      })
    }
  }

  // 按类型查询工单比例
  fetchType = async () => {
    const { start_date, end_date } = this.state
    const res = await statisticsOrderNumByType({ startDate: moment(start_date).format('YYYY-MM-DD'), endDate: moment(end_date).format('YYYY-MM-DD') })
    if (res.code === 20000) {
      const data = res.data.map(val => {
        return {
          name: val.fault_type_name,
          value: val.count || 0,
        }
      })
      this.setState({
        typeTotal: this.sum(data.map(x => x.value)),
        typeData: data
      })
    }
  }

  // 按部分查询工单费用
  fetchJe = async () => {
    const { start_date, end_date } = this.state
    const res = await statisticsOrderMoneyByDept({ startDate: moment(start_date).format('YYYY-MM-DD'), endDate: moment(end_date).format('YYYY-MM-DD') })
    if (res.code === 20000) {
      this.setState({
        jeData: res.data.map(x => {
          x.name = x.department_name.split("(")[0]
          return x
        }),
        jeSum: this.sum(res.data.map(x => x.totalMoney)),
      })
    }
  }

  sum(arr) {
    var sum = 0;
    for (var i = 0; i < arr.length; i++) {
      sum += arr[i];
    }
    return sum;
  }

  render() {
    const { start_date, end_date, yxData, typeTotal, typeData, jeData, jeSum } = this.state
    const option1 = {
      color: ['#5EE9D8'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          itemStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        left: '3%',
        right: '3%',
        top: '10%',
        containLabel: true
      },
      dataZoom: [
        {
          type: "slider", // 在底部
          showDetail: false,
          show: true, // 是否显示 组件。如果设置为 false，不会显示，但是数据过滤的功能还存在。
          start: 0, // 数据窗口范围的起始百分比。范围是：0 ~ 100。表示 0% ~ 100%。
          end: 42, // 数据窗口范围的结束百分比。范围是：0 ~ 100。
          bottom: 0, // 控制滚动条距离底部的位置;
          xAxisIndex: [0],
          backgroundColor: '#fff',//滚到颜色
          fillerColor: 'rgba(0,143,255,0.3)',
          backgroundColor: '#fff',
          start: 0,
          end: 30,
        }
      ],
      legend: {
        // show: false
        data: ['上课数(节)', '占比'],
        orient: 'horizontal',
        icon: 'circle',
        x: 'right',
        y: 'top',
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 16, // 设置间距
        textStyle: {  // 图例文字的样式
          color: '#666666',
          fontSize: 12
        },
      },
      xAxis: [{
        type: 'category',
        data: yxData.map(x => x.name),
        axisLabel: {
          formatter: function (value) {
            if (value !== undefined) {
              if (value.length > 4) {      // 超出5个字符显示省略号
                return `${value.slice(0, 4)}...`
              }
              return value
            }
          },
          textStyle: {
            color: '#292C33', // y轴字体颜色
            fontSize: 12,
          }
        },
        axisTick: {
          show: false

        },
        axisPointer: {
          type: 'shadow'
        },
        axisLine: {
          lineStyle: {
            color: '#DDDDDD',// X轴线的颜色
            width: 1 // x轴线的宽度
          }
        },
      }],
      yAxis: {
        type: 'value',
        name: '',
        axisLabel: {
          formatter: '{value}',
          textStyle: {  // 图例文字的样式
            color: '#919499',
            fontSize: 12
          },
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {    //网格线
          lineStyle: {
            type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
          },
          show: false //隐藏或显示
        },
      },
      series: [
        {
          name: '报修次数',
          type: 'bar',
          // barCategoryGap: "30%",
          barWidth: 20, // 设置柱子粗细
          itemStyle: {
            normal: {
              //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
              barBorderRadius: [2, 2, 0, 0],
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                formatter: '{c}',
                textStyle: { //数值样式
                  color: '#666666',
                  fontSize: 12
                }
              }
            }
          },
          tooltip: {
            valueFormatter: function (value) {
              return value + ' 次'
            }
          },
          data: yxData.map(x => x.count)
        },
      ]
    }

    const option2 = {
      title: {
        text: typeTotal,    // 主标题
        top: '34%',
        left: '48%',
        textStyle: {
          textAlign: 'center',
          color: '#292C33',
          fontSize: 20,
        },
        itemGap: 10,   // 主副标题间隔
        subtext: '工单总数',   // 副标题
        subtextStyle: {
          textAlign: 'center',
          color: '#666666',
          fontSize: 12,
        },
        textAlign: 'center',
      },
      tooltip: {
        trigger: 'item',
      },
      color: ['#33D0BD', '#FFC34E', '#279cf7', '#33d0bd', '#fe706e', '#f7ba27', '#335cd0', '#f26efe', '#8a2be2'],
      legend: {
        show: true,
        orient: 'horizontal',
        icon: 'circle',
        x: 'center',
        y: 'bottom',
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 16, // 设置间距
        textStyle: {  // 图例文字的样式
          color: '#666666',
          fontSize: 12
        },
      },
      series: [
        {
          name: '',
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['50%', '46%'],
          silent: 'ture',//图形是否不响应和触发鼠标事件，默认为 false，即响应和触发鼠标事件。
          label: {
            formatter: '{name|{b}} {num|{c}个}',
            rich: {
              name: {
                fontSize: 12,
                color: '#666666',
                align: 'left'
              },
              num: {
                fontSize: 12,
                color: '#292C33',
                align: 'left'
              },
            }
          },
          data: typeData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          labelLine: {
            normal: {
              length: 4,
              length2: 4,
            }
          },
        }
      ]
    }

    const option3 = {
      color: ['#5EE9D8'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          itemStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        left: '3%',
        right: '3%',
        top: '10%',
        containLabel: true
      },
      dataZoom: [
        {
          type: "slider", // 在底部
          showDetail: false,
          show: true, // 是否显示 组件。如果设置为 false，不会显示，但是数据过滤的功能还存在。
          start: 0, // 数据窗口范围的起始百分比。范围是：0 ~ 100。表示 0% ~ 100%。
          end: 42, // 数据窗口范围的结束百分比。范围是：0 ~ 100。
          bottom: 0, // 控制滚动条距离底部的位置;
          xAxisIndex: [0],
          backgroundColor: '#fff',//滚到颜色
          fillerColor: 'rgba(0,143,255,0.3)',
          backgroundColor: '#fff',
          start: 0,
          end: 30,
        }
      ],
      legend: {
        // show: false
        data: ['上课数(节)', '占比'],
        orient: 'horizontal',
        icon: 'circle',
        x: 'right',
        y: 'top',
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 16, // 设置间距
        textStyle: {  // 图例文字的样式
          color: '#666666',
          fontSize: 12
        },
      },
      xAxis: [{
        type: 'category',
        data: jeData.map(x => x.name),
        axisLabel: {
          formatter: function (value) {
            if (value !== undefined) {
              if (value.length > 4) {      // 超出5个字符显示省略号
                return `${value.slice(0, 4)}...`
              }
              return value
            }
          },
          textStyle: {
            color: '#292C33', // y轴字体颜色
            fontSize: 12,
          }
        },
        axisTick: {
          show: false

        },
        axisPointer: {
          type: 'shadow'
        },
        axisLine: {
          lineStyle: {
            color: '#DDDDDD',// X轴线的颜色
            width: 1 // x轴线的宽度
          }
        },
      }],
      yAxis: {
        type: 'value',
        name: '',
        axisLabel: {
          formatter: '{value}',
          textStyle: {  // 图例文字的样式
            color: '#919499',
            fontSize: 12
          },
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {    //网格线
          lineStyle: {
            type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
          },
          show: false //隐藏或显示
        },
      },
      series: [
        {
          name: '报修次数',
          type: 'bar',
          // barCategoryGap: "30%",
          barWidth: 20, // 设置柱子粗细
          itemStyle: {
            normal: {
              //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
              barBorderRadius: [2, 2, 0, 0],
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                formatter: '{c}',
                textStyle: { //数值样式
                  color: '#666666',
                  fontSize: 12
                }
              }
            }
          },
          tooltip: {
            valueFormatter: function (value) {
              return value + ' 次'
            }
          },
          data: jeData.map(x => x.totalMoney)
        },
      ]
    }

    return (
      <div className='operation_records'>
        <div className='operation_records_head'>
          <div className='operation_records_head_date'>
            <DatePicker
              mode="date"
              title="开始时间"
              extra="开始时间"
              value={start_date}
              maxDate={end_date ? end_date : null}
              onChange={date => {
                this.setState({
                  start_date: date
                }, () => {
                  this.fetchYx()
                  this.fetchType()
                  this.fetchJe()
                })
              }}
            >
              <List.Item arrow="horizontal"></List.Item>
            </DatePicker>
            <div style={{ background: '#f4f6f8' }}>~</div>
            <DatePicker
              mode="date"
              title="结束时间"
              extra="结束时间"
              value={end_date}
              minDate={start_date ? start_date : null}
              onChange={date => {
                this.setState({
                  end_date: date
                }, () => {
                  this.fetchYx()
                  this.fetchType()
                  this.fetchJe()
                })
              }}
            >
              <List.Item arrow="horizontal"></List.Item>
            </DatePicker>
          </div>

        </div>
        <div className='operation_records_body'>
          <div className='tj'>
            <div className='tj_title'>
              <span></span>
              <span>各院系报修次数统计</span>
            </div>
            <div className='tj_echarts'>
              <ReactEcharts option={option1} style={{ height: '200px', width: '100%' }} />
            </div>
          </div>
          <div className='tj'>
            <div className='tj_title'>
              <span></span>
              <span>各类型设备工单占比统计</span>
            </div>
            <div className='tj_echarts'>
              <ReactEcharts option={option2} style={{ height: '200px', width: '100%' }} />
            </div>
          </div>
          <div className='tj'>
            <div className='tj_title'>
              <span></span>
              <span>设备报修金额统计&nbsp;&nbsp;&nbsp;<span>总金额：{jeSum}</span></span>
            </div>
            <div className='tj_echarts'>
              <ReactEcharts option={option3} style={{ height: '200px', width: '100%' }} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default operationRecords