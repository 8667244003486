import React, { Component } from 'react'
import { Select } from 'antd'
import { DatePicker, List, Toast } from 'antd-mobile'
import { findMobileAlarmRecord, updateStateAlarm } from '../../../api/api'
import moment from 'moment'
import './environment_alarm.less'

const { Option } = Select

class environmentAlarm extends Component {

  state = {
    start_date: '',
    end_date: '',
    status: '',
    parameterId: '',
    data: [],
  }

  componentDidMount = () => {
    document.title = "环境监控-告警数据"
    this.setState({
      start_date: new Date(this.fun_date(-2)),
      end_date: new Date(),
      parameterId: this.props.match.params.id,
    }, () => {
      this.fetch()
    })
  }

  fetch = async () => {
    Toast.loading('', 0)
    const { parameterId, start_date, end_date, status } = this.state
    const res = await findMobileAlarmRecord({ parameterId, startTime: start_date ? moment(start_date).format('YYYY-MM-DD') : '', endTime: end_date ? moment(end_date).format('YYYY-MM-DD') : '', alarmState: status })
    if (res.code === 20000) {
      Toast.hide()
      this.setState({
        data: res.data.map(x => {
          x.checked = false
          return x
        })
      })
    }
  }

  // 点击查看
  handleLookOver = async item => {
    const { data } = this.state
    const res = await updateStateAlarm({ alarmId: item.alarm_id })
    if (res.code === 20000) {
      let arr = data.map(x => {
        if (x.alarm_id === item.alarm_id) {
          x = res.data
        }
        return x
      })
      this.setState({
        data: arr.map(x => {
          x.checked = x.alarm_id === item.alarm_id ? true : false
          return x
        })
      })
    }
  }

  // 点击详情
  handleInfo = item => {
    const { data } = this.state
    this.setState({
      data: data.map(x => {
        if (x.alarm_id === item.alarm_id) {
          x.checked = !x.checked
        } else {
          x.checked = false
        }
        return x
      })
    })
  }

  // 获取几天前的日期
  fun_date = (num) => {
    var date1 = new Date();
    var date2 = new Date(date1);
    date2.setDate(date1.getDate() + num);
    //num是正数表示之后的时间，num负数表示之前的时间，0表示今天
    var time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();
    return time2;
  }

  render() {
    const { start_date, end_date, data } = this.state
    return (
      <div className='environment_alarm'>
        <div className='environment_alarm_head'>
          <div className='environment_alarm_head_date'>
            <DatePicker
              mode="date"
              title="开始时间"
              extra="开始时间"
              value={start_date}
              maxDate={end_date ? end_date : null}
              onChange={date => {
                this.setState({
                  start_date: date
                }, () => {
                  Toast.loading('', 0)
                  this.fetch()
                })
              }}
            >
              <List.Item arrow="horizontal"></List.Item>
            </DatePicker>
            <div style={{ background: '#f4f6f8' }}>~</div>
            <DatePicker
              mode="date"
              title="结束时间"
              extra="结束时间"
              value={end_date}
              minDate={start_date ? start_date : null}
              onChange={date => {
                this.setState({
                  end_date: date
                }, () => {
                  Toast.loading('', 0)
                  this.fetch()
                })
              }}
            >
              <List.Item arrow="horizontal"></List.Item>
            </DatePicker>
          </div>
          <div className='environment_alarm_head_state'>
            <Select placeholder='查看状态' onSelect={e => {
              this.setState({
                status: e
              }, () => {
                Toast.loading('', 0)
                this.fetch()
              })
            }}>
              <Option value={1}>已看</Option>
              <Option value={0}>未看</Option>
            </Select>
          </div>
        </div>
        <div className='environment_alarm_body'>
          <div className='environment_alarm_list'>
            {
              (data || []).length ? data.map(item => {
                return <div className='environment_alarm_item' key={item.alarm_id}>
                  <div className='environment_alarm_item_head'>
                    {
                      item.alarm_state ? <div className='environment_alarm_status_online'>已看</div> : <div className='environment_alarm_status_offline'>未看</div>
                    }
                    <div className='environment_alarm_item_head_title'>{item.alarm_content}</div>
                    {
                      item.alarm_state ? <div className='environment_alarm_but' onClick={() => this.handleInfo(item)}>详情</div> : <div className='environment_alarm_but' onClick={() => this.handleLookOver(item)}>查看</div>
                    }
                  </div>
                  {
                    item.checked ? <div className='environment_alarm_item_main'>
                      <div className='environment_alarm_item_main_top'>
                        <div className='environment_alarm_item_main_bar'>
                          <div>当&nbsp;&nbsp;前&nbsp;&nbsp;值：</div>
                          <div>{item.parameter_name}:{item.alarm_value}</div>
                        </div>
                        {/* <div className='environment_alarm_item_main_bar'>
                          <div>告警限值：</div>
                          <div>30</div>
                        </div> */}
                      </div>
                      <div className='environment_alarm_item_main_bar'>
                        <div>告警时间：</div>
                        <div>{item.create_time ? moment(item.create_time).format('YYYY-MM-DD HH:mm:ss') : moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}</div>
                      </div>
                      <div className='environment_alarm_item_main_bar'>
                        <div>查&nbsp;&nbsp;看&nbsp;&nbsp;人：</div>
                        <div>{item.dispose_admin_name}</div>
                      </div>
                      <div className='environment_alarm_item_main_bar'>
                        <div>查看时间：</div>
                        <div>{item.dispose_time ? moment(item.dispose_time).format('YYYY-MM-DD HH:mm:ss') : ''}</div>
                      </div>
                    </div> : ''
                  }
                </div>
              }) : <div className='not-yet'>--暂无数据--</div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default environmentAlarm
