import React, { Component } from 'react'
import { SearchBar, Popover, Toast, Modal } from 'antd-mobile'
import { Radio, Icon } from 'antd'
import { Cascader, Field } from 'react-vant'
import { req_listUserRoomInfo, listUserBuildingInfo, findSpaceInfo, listUserRoomLock, findPasswordLength, setUserRoomSort, downloadFingerprintToDevice, getQrcodeStr, getCmdCount, ycOpenLock } from '../../../api/api'
import cookie from 'react-cookies'
import IP from '../../../config/config'
import './door_lock.less'

class DoorLock extends Component {

  state = {
    data: [],
    visible: false, // 气泡状态
    searchContent: '', // 搜索的房间
    roomData: [], // 楼层下的房间
    buildingId: '', // 选中的楼宇id
    floorId: '', // 选中的楼层id
    deviceId: '', // 选中的锁序列号
    jmQrVisible: false, // 二维码开锁弹窗
    jmQrStr: '', // 二维码字符串
    cacheVisible: false, // 缓存命令提示
    wt: 190,
  }

  componentDidMount = () => {
    this.fetch()
    this.fetchAllRoom()
    this.fetchPassword()
    this.setState({
      wt: document.body.clientWidth - 185
    })
  }


  fetch = async () => {
    const { searchContent, roomId } = this.state
    const res = await listUserRoomLock({ deviceName: searchContent, roomId })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }
  //查询所有房间
  fetchAllRoom = async () => {
    const res = await findSpaceInfo()
    if (res.code === 20000) {
      let menuList = res.data.map(item => {
        return {
          text: item.dwmc,
          parentId: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
        }
      })
      menuList = menuList.map(item => {
        if (menuList.filter(p => p.parentId === item.value).length) {
          item.children = menuList.filter(p => p.parentId === item.value)
        }
        return item
      }).filter(p => p.parentId === '0')
      this.setState({
        roomData: menuList
      })
    }
  }

  // 获取密码位数
  fetchPassword = async () => {
    const res = await findPasswordLength()
    if (res.code === 20000) {
      cookie.save('pwd', res.data)
    }
  }

  // 搜索房间
  handleChange = e => {
    this.setState({
      searchContent: e
    }, () => {
      this.fetch()
    })
  }

  handleCancel = () => {
    this.setState({
      searchContent: ''
    }, () => {
      this.fetch()
    })
  }

  // 批量授权
  handleBatch = () => {
    this.props.history.push({ pathname: '/module/batch' })
  }

  // 批量授权记录
  handleBatchRecord () {
    this.props.history.push({ pathname: '/module/batchRecord' })
  }

  // 临时授权
  handleTemporary = item => {
    this.props.history.push({ pathname: `/module/temporaryAuthorization/${item.device_id}` })
  }

  // 开锁记录
  handleGoksjl = item => {
    this.props.history.push({ pathname: `/module/lockRecord/${item.device_id}` })
  }

  // 快速报修
  handleGoksbx = item => {
    this.props.history.push({ pathname: `/repair/${item.device_id}` })
  }

  // 离线密码
  goLxmm = item => {
    this.props.history.push({ pathname: `/module/linePassword/${item.device_id}` })
  }

  // 下发指纹
  handleDownZw = async item => {
    Toast.loading('指纹下发中...', 0)
    const res = await downloadFingerprintToDevice({ deviceId: item.device_id })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.setState({
        visible: false
      })
    } else {
      Toast.hide()
    }
  }

  // 二维码开锁弹窗
  handleQrModal = async deviceId => {
    const res = await getQrcodeStr({ deviceId, function: 'AES' })
    if (res.code === 20000 && res.data) {
      this.setState({
        deviceId,
        jmQrVisible: true,
        jmQrStr: IP.host + '/znmk/appLock/qrcode?content=' + res.data.data
      })
    }
  }

  // 关闭二维码弹窗
  handleCloseJmQr = () => {
    this.setState({ jmQrVisible: false })
  }

  // 设为常用房间
  handlRoomSort = async (deviceId, flag) => {
    const res = await setUserRoomSort({ deviceId, flag })
    if (res.code === 20000) {
      this.fetch()
      Toast.success(res.message)
    }
  }

  // 刷新缓存条数
  handleReload = async deviceId => {
    Toast.loading('', 0)
    const { data } = this.state
    const res = await getCmdCount({ deviceId })
    if (res.code === 20000) {
      this.setState({
        data: data.map(x => {
          x.device_cmd_count = x.device_id === deviceId ? res.data : x.device_cmd_count
          return x
        })
      })
      Toast.hide()
    } else {
      Toast.hide()
    }
  }

  // 密码开锁
  handleOpenLockmm = item => {
    const array = item.auth_type ? item.auth_type.split(',') : []
    if (array.find(x => x === 'mm')) {
      this.props.history.push({ pathname: `/module/openLock/${item.device_id}` })
    } else {
      Toast.info('暂未授权密码开锁')
    }
  }

  // 一键开锁
  ycOpenLock = async deviceId => {
    Toast.loading('命令下发中...', 0)
    const res = await ycOpenLock({ deviceId })
    if (res.code === 20000) {
      Toast.success(res.message)
      setTimeout(() => {
        this.handleReload(deviceId)
      }, 2000)
    } else if (res.code === 20001) {
      Toast.info(res.message)
    } else {
      Toast.hide()
    }
  }

  // 全部选项选择完成
  handleCascaderFinish = (val, selectedRows, actions) => {
    this.setState({
      roomId: val[val.length - 1],
    }, () => {
      this.fetch()
    })
  }

  render () {
    const { data, roomData, visible, deviceId, jmQrStr, jmQrVisible, cacheVisible, wt } = this.state
    return (
      <div className='doorLock'>
        <div className='doorLock_head'>
          <img src={require('./../../../images/new/06.png')} alt='' />
          {
            data.length ? (<div className='doorLock_empower_but' onClick={() => this.handleBatch()}>
              <img alt='' src={require('../../../images/new/07.png')} />
              <div>批量授权</div>
            </div>) : ('')
          }

          <div className='doorLock_search'>
            <SearchBar placeholder="搜索设备" onCancel={() => this.handleCancel()} onChange={e => this.handleChange(e)} />
          </div>
        </div>

        <div className='doorLock_main'>
          <div className='doorLock_main_head'>
            <div>设备列表</div>
            <Cascader
              popup={{ round: true }}
              title='选择房间'
              activeColor="#00B4C4"
              options={roomData}
              onFinish={this.handleCascaderFinish}
            >
              {(_, selectedRows, actions) => (
                <Field
                  isLink
                  className='door_lock_cascader'
                  value={selectedRows.length > 0 ? selectedRows[selectedRows.length - 1].text : ''}
                  readOnly
                  placeholder='全部房间'
                  onClick={() => actions.open()}
                />
              )}
            </Cascader>
          </div>
          <div className='doorLock_list'>
            {
              (data || []).length ? data.map(item => {
                return <div key={item.device_id} className='doorLock_list_item' onClick={() => {
                  this.props.history.push(`/module/lockDetails/${item.device_id}`)
                }}>
                  <div className='doorLock_item_title'>
                    <div className='doorLock_item_title_name'>
                      {item.device_door_name}
                    </div>
                    {
                      item.device_type === 'mj' ? <div className='doorLock_item_xh status_gray'>门禁</div> : (
                        item.device_status === 2 ? <div className='doorLock_item_xh status_gray'>离线</div> : (
                          item.device_dsignal > 15 ? <div className='doorLock_item_xh status_green'>信号强</div> : item.device_dsignal > 5 ? <div className='doorLock_item_xh status_orange'>信号中</div> : <div className='doorLock_item_xh status_red'>信号差</div>
                        )
                      )
                    }
                    {
                      item.device_type === 'mj' ? ('') : (
                        item.device_battery > 80 ? <div className='doorLock_item_xh status_green'>
                          <img alt='' src={require('../../../images/electricGreen.png')} />
                          <span>{item.device_battery}%</span>
                        </div> : item.device_battery > 50 ? <div className='doorLock_item_xh status_orange'>
                          <img alt='' src={require('../../../images/electricOrange.png')} />
                          <span>{item.device_battery}%</span>
                        </div> : <div className='doorLock_item_xh status_red'>
                          <img alt='' src={require('../../../images/electricRed.png')} />
                          <span>{item.device_battery}%</span>
                        </div>
                      )
                    }
                    {
                      item.device_type === 'mj' ? ('') : <div>
                        {
                          item.device_open_state === '-1' && <div className='doorLock_item_xh status_gray'>--</div>
                        }
                        {
                          item.device_open_state === '1' && <div className='doorLock_item_xh status_green'>开</div>
                        }
                        {
                          item.device_open_state === '0' && <div className='doorLock_item_xh status_red'>关</div>
                        }
                      </div>
                    }
                  </div>
                  <div className='doorLock_item_room'>
                    {item.device_building_name}-{item.device_floor_name}-{item.device_room_name}
                  </div>
                  <div className='doorLock_item_status'>
                    <div className={item.device_status === 1 ? 'doorLock_item_status_onLine' : 'doorLock_item_status_offLine'}>
                      <div></div>
                      <div>{item.device_status === 1 ? '设备在线' : '设备离线'}</div>
                    </div>
                    <div className='doorLock_item_line'></div>
                    <div className='doorLock_item_status_use'>
                      {item.auth_sort === '0' ? '未设置为常用房间' : '已设置为常用房间'}
                    </div>
                  </div>
                  <div className='doorLock_item_num' onClick={(e) => {
                    e.stopPropagation()
                    this.handleReload(item.device_id)
                  }}>
                    <div>缓存命令{item.device_cmd_count}条</div>
                    <div onClick={(e) => {
                      e.stopPropagation()
                      this.setState({ cacheVisible: true })
                    }}>
                      <img alt='' src={require('../../../images/new/09.png')} />
                    </div>
                  </div>
                  <div className='doorLock_item_but'>
                    {
                      item.auth_type && item.auth_type.split(',').find(x => x === 'mm') ? (<div onClick={(e) => {
                        e.stopPropagation()
                        this.handleOpenLockmm(item)
                      }}>
                        <img alt='' src={require('../../../images/new/10.png')} />
                        密码开锁
                      </div>) : ('')
                    }
                    {item.auth_type && item.auth_type.split(',').find(x => x === 'ycks') ? (<div onClick={(e) => {
                      e.stopPropagation()
                      this.ycOpenLock(item.device_id)
                    }}>
                      <img alt='' src={require('../../../images/new/11.png')} />
                      一键开锁
                    </div>) : ('')}
                    {item.auth_type && item.auth_type.split(',').find(x => x === 'qr') ? (<div onClick={(e) => {
                      e.stopPropagation()
                      this.handleQrModal(item.device_id)
                    }}>
                      <img alt='' src={require('../../../images/new/012.png')} />
                      二维码开锁
                    </div>) : ('')}
                  </div>
                </div>
              }) : (<div className='yet-not' style={{ margin: 0 }}>--暂无数据--</div>)
            }
          </div>
        </div>
        {/* <div className='doorLock-header'>
          <div className='doorLock-search'>
            <SearchBar placeholder="请输入房间信息" onCancel={() => this.handleCancel()} onChange={e => this.handleChange(e)} />
            {
              data.length ? <div className='home_search_fun'>
                <div className='home_fun_item' onClick={() => this.handleBatch()}>
                  <div><img alt='' src={require('../../../images/authorization.png')} /></div>
                  <div>批量授权</div>
                </div>
                <div className='home_fun_item' onClick={() => this.handleBatchRecord()}>
                  <div><img alt='' src={require('../../../images/record.png')} /></div>
                  <div>授权记录</div>
                </div>
              </div> : ''
            }
          </div>
          {
            data.length ? <div className='doorLock-select'>
              <div className='doorLock-select-con'>
                {
                  (buildData || []).map(item => {
                    return <div className={`doorLock-select-con-list ${item.checked ? 'doorLock-select-con-list-active' : ''}`} key={item.id} onClick={() => this.handleChangeBuild(item)}>
                      <span className='doorLock-select-con-list-name'>{item.title}</span>
                      {
                        item.checked ? <span className='doorLock-select-con-list-line-active'></span> : ''
                      }
                    </div>
                  })
                }
              </div>
            </div> : ''
          }
          {
            data.length && (floorData || []).length ? <div className='doorLock-select'>
              <div className='doorLock-select-con'>
                {
                  floorData.map(item => {
                    return <div className={`doorLock-select-con-list ${item.checked ? 'doorLock-select-con-list-active' : ''}`} key={item.id} onClick={() => this.handleChangeFloor(item)}>
                      <span className='doorLock-select-con-list-name'>{item.title}</span>
                      {
                        item.checked ? <span className='doorLock-select-con-list-line-active'></span> : ''
                      }
                    </div>
                  })
                }
              </div>
            </div> : ''
          }
        </div> */}
        {/* <div className='doorLock-con' style={{ paddingTop: `${floorData.length ? 152 : 112}px` }}>
          {
            (data || []).length ? data.map(item => {
              return <div className='doorLock-con-list' key={item.device_id}>
                <div className='doorLock-con-list-header'>
                  <div className='doorLock-con-list-header-left'>
                    <div className='doorLock-con-list-header-left-name' style={{ maxWidth: `${wt}px` }}>{item.device_room_name}-{item.device_door_name}</div>
                    {
                      item.device_type === 'mj' ? <div className='doorLock-con-list-header-left-status status-orange'>门禁</div> : item.device_status === 2 ? <div className='doorLock-con-list-header-left-status status-orange'>离线</div> : <div style={{ display: 'flex', alignItems: 'center' }}>
                        {
                          item.device_dsignal > 15 ? <div className='doorLock-con-list-header-left-status status-green'>信号强</div> : item.device_dsignal > 5 ? <div className='doorLock-con-list-header-left-status status-orange'>信号中</div> : <div className='doorLock-con-list-header-left-status status-red'>信号差</div>
                        }
                        {
                          item.device_battery > 80 ? <div className='doorLock-con-list-header-left-status status-green'>
                            <img alt='' src={require('../../../images/electricGreen.png')} />
                            <span>{item.device_battery}%</span>
                          </div> : item.device_battery > 50 ? <div className='doorLock-con-list-header-left-status status-orange'>
                            <img alt='' src={require('../../../images/electricOrange.png')} />
                            <span>{item.device_battery}%</span>
                          </div> : <div className='doorLock-con-list-header-left-status status-red'>
                            <img alt='' src={require('../../../images/electricRed.png')} />
                            <span>{item.device_battery}%</span>
                          </div>
                        }
                        {
                          item.device_open_state === '-1' && <div className='doorLock-con-list-header-left-status status-grey'>--</div>
                        }
                        {
                          item.device_open_state === '1' && <div className='doorLock-con-list-header-left-status status-green'>开</div>
                        }
                        {
                          item.device_open_state === '0' && <div className='doorLock-con-list-header-left-status status-red'>关</div>
                        }
                      </div>
                    }
                  </div>
                  <div className='doorLock-con-list-header-right'>
                    <Popover mask
                      className="popover"
                      overlayClassName="fortest"
                      overlayStyle={{ color: 'currentColor' }}
                      visible={visible}
                      overlay={[
                        ((item.auth_type && item.auth_type.split(',').find(x => x === 'mm') && item.device_type === 'aep') ? <Popover.Item key="1" value="scan">
                          <span onClick={() => this.handleTemporary(item)}>临时授权</span>
                        </Popover.Item> : ''),
                        (<Popover.Item key="2" value="open">
                          <span onClick={() => this.handleGoksjl(item)}>开锁记录</span>
                        </Popover.Item>),
                        // (<Popover.Item key="3" value="repair">
                        //   <span onClick={() => this.handleGoksbx(item)}>快速报修</span>
                        // </Popover.Item>),
                        ((item.auth_type && item.auth_type.split(',').find(x => x === 'mm') && item.device_type === 'aep') ? <Popover.Item key="4" value="mm">
                          <span onClick={() => this.goLxmm(item)}>离线密码</span>
                        </Popover.Item> : ''),
                        ((item.auth_type && item.auth_type.split(',').find(x => x === 'zw')) ? <Popover.Item key="5" value="zw">
                          <span onClick={() => this.handleDownZw(item)}>下发指纹</span>
                        </Popover.Item> : ''),
                        ((item.auth_type && item.auth_type.split(',').find(x => x === 'qr')) ? <Popover.Item key="6" value="qr">
                          <span onClick={() => this.handleQrModal(item.device_id)}>二维码开锁</span>
                        </Popover.Item> : ''),
                      ]}
                      align={{ overflow: { adjustY: 0, adjustX: 0 }, offset: [10, 0] }}
                      onVisibleChange={this.handleVisibleChange}
                    >
                      <img alt='' className="doorLock-con-list-header-right-img" src={require('../../../images/02.png')} />
                    </Popover>
                  </div>
                </div>
                <div className='doorLock-con-list-build'>
                  {item.device_building_name}/{item.device_floor_name}/{item.device_room_name}
                </div>
                <div className='doorLock-con-list-stock'>
                  <div>
                    <Radio checked={item.auth_sort === '0' ? false : true} onClick={() => this.handlRoomSort(item.device_id, item.auth_sort === '0' ? 1 : 2)} />
                    <span style={{ color: '#21c6b0', marginRight: 6 }}>已设置</span>为常用房间
                  </div>
                  {
                    item.device_cmd_count ? <div style={{ color: '#3d80fc', display: 'flex', alignItems: 'center' }}>
                      缓存命令{item.device_cmd_count}条
                      <Icon className='doorLock-reload' type="reload" onClick={() => this.handleReload(item.device_id)} />
                      <Icon className='doorLock-icon' type="question-circle" theme="filled" onClick={() => this.setState({ cacheVisible: true })} />
                    </div> : <Icon className='doorLock-reload' type="reload" onClick={() => this.handleReload(item.device_id)} />
                  }
                </div>
                <div className="doorLock-con-list-btn">
                  {
                    item.auth_type && item.auth_type.split(',').find(x => x === 'mm') ? <div onClick={() => this.handleOpenLockmm(item)}>密码开锁</div> : ''
                  }
                  {
                    item.auth_type && item.auth_type.split(',').find(x => x === 'ycks') ? <div onClick={() => this.ycOpenLock(item.device_id)}>一键开锁</div> : ''
                  }

                </div>
              </div>
            }) : <div className='yet-not' style={{ margin: 0 }}>--暂无数据--</div>
          }
        </div> */}

        {/* 开锁二维码 */}
        <Modal
          visible={jmQrVisible}
          transparent
          onClose={() => this.handleCloseJmQr()}
          title="开锁二维码"
        >
          <div className='doorLock-modal'>
            <img alt='' src={jmQrStr} onClick={() => this.handleQrModal(deviceId)} />
          </div>
        </Modal>
        {/* 缓存提示弹窗 */}
        <Modal
          visible={cacheVisible}
          transparent
          closable={true}
          onClose={() => this.setState({ cacheVisible: false })}
          title="提示"
        >
          <div className='doorLock-dynamic'>
            待下发命令条数，按8#或点亮屏幕可激活设备联网下发命令。
          </div>
        </Modal>
      </div>
    )
  }
}

export default DoorLock
