import React, { Component } from 'react'
import { Toast, Modal } from 'antd-mobile'
import { listUserRoomLock, findPasswordLength, getQrcodeStr, getCmdCount, ycOpenLock } from '../../../api/api'
import cookie from 'react-cookies'
import IP from '../../../config/config'
import './room.less'

class RoomLock extends Component {

  state = {
    roomInfo: {},
    data: [],
    jmQrVisible: false, // 二维码开锁弹窗
    jmQrStr: '', // 二维码字符串
    cacheVisible: false, // 缓存命令提示
    wt: 190,
  }

  componentDidMount = () => {
    document.title = "智慧门锁"
    this.fetch(this.props.match.params.id)
    this.fetchPassword()
    this.setState({
      roomInfo: this.props.location.state.roomInfo,
      wt: document.body.clientWidth - 185
    })
  }

  fetch = async roomId => {
    const res = await listUserRoomLock({ roomId })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  // 获取密码位数
  fetchPassword = async () => {
    const res = await findPasswordLength()
    if (res.code === 20000) {
      cookie.save('pwd', res.data)
    }
  }

  // 二维码开锁弹窗
  handleQrModal = async deviceId => {
    const res = await getQrcodeStr({ deviceId, function: 'AES' })
    if (res.code === 20000 && res.data) {
      this.setState({
        deviceId,
        jmQrVisible: true,
        jmQrStr: IP.host + '/znmk/appLock/qrcode?content=' + res.data.data
      })
    }
  }

  // 关闭二维码弹窗
  handleCloseJmQr = () => {
    this.setState({ jmQrVisible: false })
  }

  // 刷新缓存条数
  handleReload = async deviceId => {
    Toast.loading('', 0)
    const { data } = this.state
    const res = await getCmdCount({ deviceId })
    if (res.code === 20000) {
      this.setState({
        data: data.map(x => {
          x.device_cmd_count = x.device_id === deviceId ? res.data : x.device_cmd_count
          return x
        })
      })
      Toast.hide()
    } else {
      Toast.hide()
    }
  }

  // 密码开锁
  handleOpenLockmm = item => {
    const array = item.auth_type ? item.auth_type.split(',') : []
    if (array.find(x => x === 'mm')) {
      this.props.history.push({ pathname: `/module/openLock/${item.device_id}` })
    } else {
      Toast.info('暂未授权密码开锁')
    }
  }

  // 一键开锁
  ycOpenLock = async deviceId => {
    Toast.loading('命令下发中...', 0)
    const res = await ycOpenLock({ deviceId })
    if (res.code === 20000) {
      Toast.success(res.message)
      setTimeout(() => {
        this.handleReload(deviceId)
      }, 2000)
    } else if (res.code === 20001) {
      Toast.info(res.message)
    } else {
      Toast.hide()
    }
  }

  render() {
    const { data, cacheVisible, roomInfo, jmQrVisible, deviceId, jmQrStr } = this.state
    return (
      <div className='roomLock'>
        <div className='shortTerm_name'>{roomInfo.wzName}</div>
        <div className='shortTerm_addr'>{roomInfo.buildingName ? roomInfo.buildingName.split('/')[0] : ''}</div>
        <div className='roomEnvironment_con'>
          {
            (data || []).length ? data.map(item => {
              return <div key={item.device_id} className='doorLock_list_item' onClick={() => {
                this.props.history.push(`/module/lockDetails/${item.device_id}`)
              }}>
                <div className='doorLock_item_title'>
                  <div className='doorLock_item_title_name'>
                    {item.device_door_name}
                  </div>
                  {
                    item.device_type === 'mj' ? <div className='doorLock_item_xh status_gray'>门禁</div> : (
                      item.device_status === 2 ? <div className='doorLock_item_xh status_gray'>离线</div> : (
                        item.device_dsignal > 15 ? <div className='doorLock_item_xh status_green'>信号强</div> : item.device_dsignal > 5 ? <div className='doorLock_item_xh status_orange'>信号中</div> : <div className='doorLock_item_xh status_red'>信号差</div>
                      )
                    )
                  }
                  {
                    item.device_type === 'mj' ? ('') : (
                      item.device_battery > 80 ? <div className='doorLock_item_xh status_green'>
                        <img alt='' src={require('../../../images/electricGreen.png')} />
                        <span>{item.device_battery}%</span>
                      </div> : item.device_battery > 50 ? <div className='doorLock_item_xh status_orange'>
                        <img alt='' src={require('../../../images/electricOrange.png')} />
                        <span>{item.device_battery}%</span>
                      </div> : <div className='doorLock_item_xh status_red'>
                        <img alt='' src={require('../../../images/electricRed.png')} />
                        <span>{item.device_battery}%</span>
                      </div>
                    )
                  }
                  {
                    item.device_type === 'mj' ? ('') : <div>
                      {
                        item.device_open_state === '-1' && <div className='doorLock_item_xh status_gray'>--</div>
                      }
                      {
                        item.device_open_state === '1' && <div className='doorLock_item_xh status_green'>开</div>
                      }
                      {
                        item.device_open_state === '0' && <div className='doorLock_item_xh status_red'>关</div>
                      }
                    </div>
                  }
                </div>
                <div className='doorLock_item_room'>
                  {item.device_building_name}-{item.device_floor_name}-{item.device_room_name}
                </div>
                <div className='doorLock_item_status'>
                  <div className={item.device_status === 1 ? 'doorLock_item_status_onLine' : 'doorLock_item_status_offLine'}>
                    <div></div>
                    <div>{item.device_status === 1 ? '设备在线' : '设备离线'}</div>
                  </div>
                  <div className='doorLock_item_line'></div>
                  <div className='doorLock_item_status_use'>
                    {item.auth_sort === '0' ? '未设置为常用房间' : '已设置为常用房间'}
                  </div>
                </div>
                <div className='doorLock_item_num' onClick={(e) => {
                  e.stopPropagation()
                  this.handleReload(item.device_id)
                }}>
                  <div>缓存命令{item.device_cmd_count}条</div>
                  <div onClick={(e) => {
                    e.stopPropagation()
                    this.setState({ cacheVisible: true })
                  }}>
                    <img alt='' src={require('../../../images/new/09.png')} />
                  </div>
                </div>
                <div className='doorLock_item_but'>
                  {
                    item.auth_type && item.auth_type.split(',').find(x => x === 'mm') ? (<div onClick={(e) => {
                      e.stopPropagation()
                      this.handleOpenLockmm(item)
                    }}>
                      <img alt='' src={require('../../../images/new/10.png')} />
                      密码开锁
                    </div>) : ('')
                  }
                  {item.auth_type && item.auth_type.split(',').find(x => x === 'ycks') ? (<div onClick={(e) => {
                    e.stopPropagation()
                    this.ycOpenLock(item.device_id)
                  }}>
                    <img alt='' src={require('../../../images/new/11.png')} />
                    一键开锁
                  </div>) : ('')}
                  {item.auth_type && item.auth_type.split(',').find(x => x === 'qr') ? (<div onClick={(e) => {
                    e.stopPropagation()
                    this.handleQrModal(item.device_id)
                  }}>
                    <img alt='' src={require('../../../images/new/012.png')} />
                    二维码开锁
                  </div>) : ('')}
                </div>
              </div>
            }) : (<div className='yet-not' style={{ margin: 0 }}>--暂无数据--</div>)
          }
        </div>

        {/* 开锁二维码 */}
        <Modal
          visible={jmQrVisible}
          transparent
          onClose={() => this.handleCloseJmQr()}
          title="开锁二维码"
        >
          <div className='doorLock-modal'>
            <img alt='' src={jmQrStr} onClick={() => this.handleQrModal(deviceId)} />
          </div>
        </Modal>

        {/* 缓存提示弹窗 */}
        <Modal
          visible={cacheVisible}
          transparent
          closable={true}
          onClose={() => this.setState({ cacheVisible: false })}
          title="提示"
        >
          <div className='doorLock-dynamic'>
            待下发命令条数，按8#或点亮屏幕可激活设备联网下发命令。
          </div>
        </Modal>
      </div >
    )
  }
}

export default RoomLock