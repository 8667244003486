import React, { Component } from 'react'
import { Icon, Progress } from 'antd-mobile'
import echarts from 'echarts/lib/echarts'
import ReactEcharts from 'echarts-for-react'
import { gpqtyl, qtfl, kptj, qtsyl, qtsycs, gprksl, wzfb_yx } from './../../../api/gas'
import './tj.less'

export default class componentName extends Component {

  state = {

  }

  componentDidMount = async () => {
    this._gpqtyl()
    this._qtfl()
    this._kptj()
    this._qtsyl()
  }

  // 剩余量预警
  _gpqtyl = async () => {
    const res = await gpqtyl()
    if (res.code === 20000) {
      console.log(res.data)
    }
  }

  // 气体分类
  _qtfl = async () => {
    const res = await qtfl()
    if (res.code === 20000) {
      console.log(res.data)
    }
  }

  // 气体分类
  _kptj = async () => {
    const res = await kptj()
    if (res.code === 20000) {
      console.log(res.data)
    }
  }

  // 气体分类
  _qtsyl = async () => {
    const res = await qtsyl()
    if (res.code === 20000) {
      console.log(res.data)
    }
  }


  render() {
    const option1 = {
      grid: {
        top: '10%',
        left: '20%',
        right: '2%',
        bottom: '12%',
      },
      color: ['#58BDFF'],
      tooltip: {
        trigger: 'axis'
      },
      yAxis: {
        type: 'category',
        axisLine: {    // x轴线的宽度
          show: true,
          lineStyle: {
            width: 1,
            type: "solid",
            color: '#DAE0EC', // x轴字体颜色
          }
        },
        axisTick: {
          show: false // 不显示坐标轴刻度线
        },
        axisLabel: {
          textStyle: {
            color: '#6E7079', // x轴字体颜色
            fontSize: 12,
          }
        },
        // data: typeData.map(x => x.type_name)
        data: ['易燃气体', '助燃气体', '有毒气体', '不燃气体', '惰性气体']
      },
      xAxis: {
        type: 'value',
        minInterval: 1,
        axisLine: {      // 不显示y轴0坐标轴线
          show: false
        },
        axisTick: {
          show: false // 不显示坐标轴刻度线
        },
        splitLine: {
          lineStyle: {
            color: "#DAE0EC",
            type: 'dashed' // 虚线
          },
        },
        axisLabel: {
          textStyle: {
            color: '#6E7079', // y轴字体颜色
            fontSize: 12,
          },
          formatter: function (value) {
            // 返回整数部分，忽略小数部分
            return parseInt(value)
          }
        },
      },
      series: [
        {
          name: '气体种数',
          type: 'bar',
          barWidth: 8,
          // data: typeData.map(x => x.num),
          data: [3, 6, 4, 8, 20],
          itemStyle: {
            normal: {
              barBorderRadius: [8, 8, 8, 8]
            }
          }
        }
      ]
    }
    const option2 = {
      grid: {
        top: '15%',
        left: '10%',
        right: '2%',
        bottom: '15%',
      },
      legend: {
        itemWidth: 14,
        itemHeight: 14,
        data: ['气体钢瓶数量'],
        top: 0,
        right: 0
      },
      color: ['#91CC75'],
      tooltip: {
        trigger: 'axis',
        formatter: function (data) {
          var tip = '<h5 class="echarts-tip-h5">' + data[0].name + '</h5>'
          data.forEach((item) => {
            let unit = ''
            if (item.seriesType === 'bar') {
              tip += '<div>'
              tip += "<span style='display:inline-block;border-radius:10px;width:9px;height:9px;background-color:#91CC75'></span>"
              tip += item.marker + item.seriesName + '：' + item.value + unit
              tip += '</div>'
            }
          })
          return tip
        }
      },

      xAxis: {
        type: 'category',
        axisLine: {    // x轴线的宽度
          show: true,
          lineStyle: {
            width: 1,
            type: "solid",
            color: '#DAE0EC', // x轴字体颜色
          }
        },
        axisTick: {
          show: false // 不显示坐标轴刻度线
        },
        axisLabel: {
          textStyle: {
            color: '#6E7079', // x轴字体颜色
            fontSize: 12,
          },
          interval: 0,
          rotate: 45,
          formatter: function (value) {
            var str = ""
            var num = 10 //每行显示字数 
            var valLength = value.length //该项x轴字数  
            var rowNum = Math.ceil(valLength / num) // 行数 
            if (rowNum > 1) {
              for (var i = 0; i < rowNum; i++) {
                var temp = ""
                var start = i * num
                var end = start + num
                temp = value.substring(start, end) + "\n"
                str += temp
              }
              return str
            } else {
              return value
            }
          }
        },
        // data: labFenBuData.map(x => x.name)
        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
      },
      yAxis: {
        name: '单位：个',
        type: 'value',
        minInterval: 1,
        axisLine: {      // 不显示y轴0坐标轴线
          show: false
        },
        axisTick: {
          show: false // 不显示坐标轴刻度线
        },
        splitLine: {
          lineStyle: {
            color: "#DAE0EC",
            type: 'dashed' // 虚线
          },
        },
        axisLabel: {
          textStyle: {
            color: '#6E7079', // y轴字体颜色
            fontSize: 12,
          },
          formatter: function (value) {
            // 返回整数部分，忽略小数部分
            return parseInt(value)
          }
        },
      },
      series: [
        {
          name: '气体钢瓶数量',
          type: 'bar',
          barGap: '-100%',
          barWidth: 12,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: 'rgba(145, 204, 117, 1)' },
              { offset: 1, color: 'rgba(145, 204, 117, .3)' }
            ])
          },
          z: 10,
          // data: labFenBuData.map(x => x.num)
          data: [10, 20, 30, 20, 40, 70, 50, 60, 6, 30, 27, 56]
        },
        {
          type: 'pictorialBar',
          symbol: 'rect',
          itemStyle: {
            color: '#fff'
          },
          label: {
            show: false,
            position: 'top'
          },
          symbolRepeat: true,
          symbolSize: [16, 2],
          symbolMargin: 3,
          z: 12,
          // data: labFenBuData.map(x => x.num)
          data: [10, 20, 30, 20, 40, 70, 50, 60, 6, 30, 27, 56]
        }
      ]
    }
    const option3 = {
      grid: {
        top: '15%',
        left: '10%',
        right: '2%',
        bottom: '30%',
      },
      legend: {
        icon: 'roundRect',
        itemWidth: 14,
        itemHeight: 14,
        data: ['气体钢瓶数量'],
        top: 0,
        right: 0
      },
      color: ['#75DDF1'],
      tooltip: {
        trigger: 'axis',
        formatter: function (data) {
          var tip = '<h5 class="echarts-tip-h5">' + data[0].name + '</h5>'
          data.forEach((item) => {
            let unit = ''
            if (item.seriesType === 'bar') {
              tip += '<div>'
              tip += "<span style='display:inline-block;border-radius:10px;width:9px;height:9px;background-color:#75DDF1'></span>"
              tip += item.marker + item.seriesName + '：' + item.value + unit
              tip += '</div>'
            }
          })
          return tip
        }
      },
      dataZoom: [
        {
          type: 'slider',
          startValue: 0,
          endValue: 4,
          handleSize: '100%',
          backgroundColor: 'rgba(47,69,84,0)', // 组件的背景颜色
          dataBackgroundColor: '#dfecf3', // 数据区域的背景颜色
          fillerColor: 'rgba(117, 221, 241, 0.20)', // 选中范围的填充颜色
          handleColor: '#75DDF1', // 手柄的颜色
        },
        {
          type: 'inside'
        },
      ],
      xAxis: {
        type: 'category',
        axisLine: {    // x轴线的宽度
          show: true,
          lineStyle: {
            width: 1,
            type: "solid",
            color: '#DAE0EC', // x轴字体颜色
          }
        },
        axisTick: {
          // alignWidthLabel: true,
          show: false // 不显示坐标轴刻度线
        },

        axisLabel: {
          textStyle: {
            color: '#6E7079', // x轴字体颜色
            fontSize: 12,
          },
          interval: 0,
          rotate: 30,
          formatter: function (value) {
            var str = ""
            var num = 10 //每行显示字数 
            var valLength = value.length //该项x轴字数  
            var rowNum = Math.ceil(valLength / num) // 行数 
            if (rowNum > 1) {
              for (var i = 0; i < rowNum; i++) {
                var temp = ""
                var start = i * num
                var end = start + num
                temp = value.substring(start, end) + "\n"
                str += temp
              }
              return str
            } else {
              return value
            }
          }
        },
        // data: labFenBuData.map(x => x.name)
        data: ['大学科技园', '文史传媒学院', '化学学院', '社会学院', '大学科技园', '文史传媒学院', '化学学院', '社会学院', '化学学院', '社会学院']
      },
      yAxis: {
        name: '单位：个',
        type: 'value',
        minInterval: 1,
        axisLine: {      // 不显示y轴0坐标轴线
          show: false
        },
        axisTick: {
          show: false // 不显示坐标轴刻度线
        },
        splitLine: {
          lineStyle: {
            color: "#DAE0EC",
            type: 'dashed' // 虚线
          },
        },
        axisLabel: {
          textStyle: {
            color: '#6E7079', // y轴字体颜色
            fontSize: 12,
          },
          formatter: function (value) {
            // 返回整数部分，忽略小数部分
            return parseInt(value)
          }
        },
      },
      series: [
        {
          name: '气体钢瓶数量',
          type: 'line',
          areaStyle: {  //区域填充颜色
            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
              { offset: 0, color: 'rgba(117, 221, 241, 0.20)' },
              { offset: 1, color: 'rgba(117, 221, 241, 0.80)' },
            ])
          },

          // data: labFenBuData.map(x => x.num)
          data: [10, 20, 30, 20, 10, 20, 30, 20, 30, 20]
        }]
    }
    return (
      <div className='gas_tj'>
        <div className="block block1">
          <div className="flex_b h3">
            <span className='block_flag'></span>
            <h3>使用记录最频繁排行</h3>
            <div className='flex_s more' onClick={() => { }}>
              更多<Icon type="right" />
            </div>
          </div>
          <div className="yj_content">
            <div className='flex_b'>
              <div className="flex_s left red">
                即将用完
              </div>
              <div className="middle">
                <span className='span2'>XCXY202408170023</span>
                <div className='gas_desc'>
                  <span >氧气(O2)</span>
                  <span >铝合金钢瓶</span>
                  <span >高纯度</span>
                  <span >气量 5L</span>
                </div>
              </div>
              <div className='right red'>
                <div className='shang_progress'>
                  <Progress
                    barStyle={{
                      border: '3px solid #f94f4f',
                    }}
                    percent={50}
                    position="normal" />
                  <div className='rect' style={{ left: '50%' }}></div>
                </div>
                <div className='xia_progress'>
                  剩余量 0.3L
                </div>
              </div>
            </div>
            <div className='flex_b'>
              <div className="flex_s left yellow">
                余额不足
              </div>
              <div className="middle">
                <span className='span2'>XCXY202408170023</span>
                <div className='gas_desc'>
                  <span >氧气(O2)</span>
                  <span >铝合金钢瓶</span>
                  <span >高纯度</span>
                  <span >气量 5L</span>
                </div>
              </div>
              <div className='right yellow'>
                <div className='shang_progress'>
                  <Progress
                    barStyle={{
                      border: '3px solid #e6ad16',
                    }}
                    percent={50}
                    position="normal" />
                  <div className='rect' style={{ left: '50%' }}></div>
                </div>
                <div className='xia_progress'>
                  剩余量 0.3L
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block block2">
          <div className="flex_b h3">
            <span className='block_flag'></span>
            <h3>空瓶统计</h3>
            <div className='flex_s more' onClick={() => { }}>
              更多<Icon type="right" />
            </div>
          </div>
          <div className='kp_content'>
            <div>
              <div className="bh">
                XCXY202408170023
              </div>
              <div className='flex_b'>
                <div className='gas_desc'>
                  <span >氧气(O2)</span>
                  <span >铝合金钢瓶</span>
                  <span >高纯度</span>
                  <span >气量 5L</span>
                </div>
                <div>
                  空瓶(正常使用)
                </div>
              </div>
              <div className='flex_b'>
                <span>
                  2024-08-14 17:13
                </span>
                <span>
                  王年逸
                </span>
              </div>
            </div>
            <div>
              <div className="bh">
                XCXY202408170023
              </div>
              <div className='flex_b'>
                <div className='gas_desc'>
                  <span >氧气(O2)</span>
                  <span >铝合金钢瓶</span>
                  <span >高纯度</span>
                  <span >气量 5L</span>
                </div>
                <div>
                  空瓶(正常使用)
                </div>
              </div>
              <div className='flex_b'>
                <span>
                  2024-08-14 17:13
                </span>
                <span>
                  王年逸
                </span>
              </div>
            </div>
            <div>
              <div className="bh">
                XCXY202408170023
              </div>
              <div className='flex_b'>
                <div className='gas_desc'>
                  <span >氧气(O2)</span>
                  <span >铝合金钢瓶</span>
                  <span >高纯度</span>
                  <span >气量 5L</span>
                </div>
                <div>
                  空瓶(正常使用)
                </div>
              </div>
              <div className='flex_b'>
                <span>
                  2024-08-14 17:13
                </span>
                <span>
                  王年逸
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="block block3">
          <div className="flex_b h3">
            <span className='block_flag'></span>
            <h3>使用气体最多排行</h3>
            <div className='flex_s more' onClick={() => { }}>
              更多<Icon type="right" />
            </div>
          </div>
          <div className="use_amount">
            <div className="flex_b ev_amount">
              <span>No.1</span>
              <div>
                <div>氧气</div>
                <span>XCXY202408170023 </span>
              </div>
              <div>
                <Progress
                  barStyle={{
                    borderWidth: '3px',
                    borderImage: 'linear-gradient( 90deg, #DFFAFF 4%,  rgba(55,222,255,0.8) 100%) 1',
                  }}
                  percent={50}
                  position="normal" />
                <div className='rect' style={{ left: '50%' }}></div>
              </div>
              <b>85</b>
            </div>
            <div className="flex_b ev_amount">
              <span>No.2</span>
              <div>
                <div>氮气</div>
                <span>XCXY202408170023 </span>
              </div>
              <div>
                <Progress
                  barStyle={{
                    borderWidth: '3px',
                    borderImage: 'linear-gradient( 90deg, #DFFAFF 4%,  rgba(55,222,255,0.8) 100%) 1',
                  }}
                  percent={50}
                  position="normal" />
                <div className='rect' style={{ left: '50%' }}></div>
              </div>
              <b>85</b>
            </div>
            <div className="flex_b ev_amount">
              <span>No.3</span>
              <div>
                <div>一氧化氮</div>
                <span>XCXY202408170023 </span>
              </div>
              <div>
                <Progress
                  barStyle={{
                    borderWidth: '3px',
                    borderImage: 'linear-gradient( 90deg, #DFFAFF 4%,  rgba(55,222,255,0.8) 100%) 1',
                  }}
                  percent={50}
                  position="normal" />
                <div className='rect' style={{ left: '50%' }}></div>
              </div>
              <b>85</b>
            </div>
            <div className="flex_b ev_amount">
              <span>No.4</span>
              <div>
                <div>甲烷</div>
                <span>XCXY202408170023 </span>
              </div>
              <div>
                <Progress
                  barStyle={{
                    borderWidth: '3px',
                    borderImage: 'linear-gradient( 90deg, #DFFAFF 4%,  rgba(55,222,255,0.8) 100%) 1',
                  }}
                  percent={50}
                  position="normal" />
                <div className='rect' style={{ left: '50%' }}></div>
              </div>
              <b>85</b>
            </div>
          </div>
        </div>
        <div className="block block4">
          <div className="flex_b h3">
            <span className='block_flag'></span>
            <h3>使用记录最频繁排行</h3>
            <div className='flex_s more' onClick={() => { }}>
              更多<Icon type="right" />
            </div>
          </div>
          <div className="use_frequency">
            <div className='ev_frequency'>
              <div className='sort_icon'>1</div>
              <div className="flex_b">
                <div className='flex_s'>
                  <div>氧气瓶</div>
                  <span className='bh'>XCXY202408170023 </span>
                </div>
                <b>209</b>
              </div>
            </div>
            <div className='ev_frequency'>
              <div className='sort_icon'>2</div>
              <div className="flex_b">
                <div className='flex_s'>
                  <div>氧气瓶</div>
                  <span className='bh'>XCXY202408170023 </span>
                </div>
                <b>209</b>
              </div>
            </div>
            <div className='ev_frequency'>
              <div className='sort_icon'>3</div>
              <div className="flex_b">
                <div className='flex_s'>
                  <div>氧气瓶</div>
                  <span className='bh'>XCXY202408170023 </span>
                </div>
                <b>209</b>
              </div>
            </div>
            <div className='ev_frequency'>
              <div className='sort_icon'>4</div>
              <div className="flex_b">
                <div className='flex_s'>
                  <div>氧气瓶</div>
                  <span className='bh'>XCXY202408170023 </span>
                </div>
                <b>209</b>
              </div>
            </div>
            <div className='ev_frequency'>
              <div className='sort_icon'>5</div>
              <div className="flex_b">
                <div className='flex_s'>
                  <div>氧气瓶</div>
                  <span className='bh'>XCXY202408170023 </span>
                </div>
                <b>209</b>
              </div>
            </div>
          </div>
        </div>
        <div className="block block5">
          <div className="flex_b h3">
            <span className='block_flag'></span>
            <h3>使用时长最长排行</h3>
            <div className='flex_s more' onClick={() => { }}>
              更多<Icon type="right" />
            </div>
          </div>
          <div className="use_time">
            <div className='one-three'>
              <div className='two'>
                <p>氢气瓶</p>
                <span>12h</span>
              </div>
              <div className='one'>
                <p>氢气瓶</p>
                <span>12h</span>
              </div>
              <div className='three'>
                <p>氢气瓶</p>
                <span>12h</span>
              </div>
            </div>
            <div className='flex_b ev_time'>
              <div className='flex_s'>
                <i>04</i>
                <span>乙炔瓶</span>
              </div>
              <div>
                <span className='use_time_text'>使用时长</span>
                <b className='use_time_value'>6h</b>
              </div>
            </div>
            <div className='flex_b ev_time'>
              <div className='flex_s'>
                <i>05</i>
                <span>乙炔瓶</span>
              </div>
              <div>
                <span className='use_time_text'>使用时长</span>
                <b className='use_time_value'>6h</b>
              </div>
            </div>
          </div>
        </div>
        <div className="block block6">
          <div className="flex_b h3">
            <span className='block_flag'></span>
            <h3>气体分类</h3>
          </div>
          <ReactEcharts option={option1} style={{ height: 220 }} />
        </div>
        <div className="block block7">
          <div className="flex_b h3">
            <span className='block_flag'></span>
            <h3>气体钢瓶数量</h3>
            <div className='flex_s conditionBtn'>
              <span className='selected'>半年</span>
              <span>一年</span>
            </div>
          </div>
          <ReactEcharts option={option2} style={{ height: 220 }} />
        </div>
        <div className="block block8">
          <div className="flex_b h3">
            <span className='block_flag'></span>
            <h3>位置分布</h3>
          </div>
          <ReactEcharts option={option3} style={{ height: 280 }} />
        </div>
      </div>
    )
  }
}
