import React, { Component } from 'react'
import { List, Picker, Toast } from 'antd-mobile'
import { findAllPaper } from '../../../api/repairApi'
import { findMyGradeInfo } from '../../../api/api'
import './grades.less'

class Grades extends Component {

  state = {
    examData: [],
    examId: [],
  }

  componentDidMount = () => {
    this.fetch()
  }

  fetch = async () => {
    const res = await findAllPaper()
    if (res.code === 20000) {
      this.setState({
        examData: (res.data || []).map(x => {
          return {
            label: x.paper_name,
            value: x.paper_id,
          }
        }),
      })
    }
  }

  // 查询
  handleSearch = async () => {
    const { examId } = this.state
    if (!examId.length) return Toast.info('请先选择需要查询的试卷名称！')
    const res = await findMyGradeInfo({ paperId: examId[0] })
    if (res.code === 20000) {
      if (res.data && res.data.length) {
        this.props.history.push(`/module/safty/grades/detail/${examId[0]}`)
      } else {
        Toast.info('暂无考试信息！！')
      }
    }
  }

  render() {
    const { examData, examId } = this.state
    return (
      <div className='saftyModule examStudy'>
        <div className='content_wrap'>
          <div className="floor">
            <List className='grade_name'>
              <Picker cols={1} value={examId} data={examData} extra="请选择考试名称" onChange={val => this.setState({ examId: val })}>
                <List.Item arrow="horizontal"></List.Item>
              </Picker>
            </List>
            <div className='grade_btn' onClick={() => this.handleSearch()}>查询</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Grades
