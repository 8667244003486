import React, { Component } from 'react'
import { Table } from 'antd'
import { Toast } from 'antd-mobile'
import { findSlideshowInfo, findLabNumByInfo, findNetDeviceNumByType, countByYx, countDzgAndZsHidden, countDetailsRecord, countDspDevice, countEquipment, findEquipmentByDept, dbspWhp, countDspSys, countByDeptSafety, countKfSys, countXueYuan, findAbnormalRecordLock, findAbnormalRecordBanPai, examCount } from '../../../api/api'
import ReactEcharts from 'echarts-for-react'
import IP from '../../../config/config'
import cookie from 'react-cookies'
import $ from 'jquery'
import './workbench.less'

class Workbench extends Component {

  state = {
    wt1: 0,
    logo: '',
    labInfo: {}, // 基础信息
    data1: [],
    deviceDsp: 0,
    whpObj: {},
    sysDsp: 0,
    riskGradeNum: {
      yiNum: 0,
      erNum: 0,
      sanNum: 0,
      siNum: 0,
    }, // 实验室分级分类
    data2: [],
    timeAqxjList: [
      { id: 1, name: '近7天', checked: true },
      { id: 2, name: '近30天', checked: false }
    ],
    timeYyqkList: [
      { id: 1, name: '近7天', checked: true },
      { id: 2, name: '近30天', checked: false }
    ],
    typeList: [
      { id: 1, name: '实验室', checked: false },
      { id: 2, name: '设备', checked: true }
    ],
    aqxjData: {},
    data3: [],
    yyqkTimeId: 1,
    yyqkTypeId: 2,
    deviceObj: {},
    data41: [],
    data42: [],
    kfSys: {},
    deviceData: [],
    alarmData: {},
    examCountData: {},
  }

  componentDidMount = () => {
    this.fetchLogo()
    this.fetchInfo()
    this.fetchAlarm()
    this.fetchRiskGrade()
    this.fetchNetDeviceNum()
    this.fetchYxSys()
    this.fetchYxYhDzg(1)
    this.fetchAqxj(1)
    this.fetchDevice()
    this.fetchDeviceDsp()
    this.fetchDeviceByDept(1)
    this.fetchWhp()
    this.fetchSysDsp()
    this.fetchSafety()
    this.fetchKfSys()
    this.fetchSysDep(1)
    this.fetchExamCount()
    this.setState({
      wt1: document.body.clientWidth - 138
    })
  }

  // 查询logo
  fetchLogo = async () => {
    const res = await findSlideshowInfo()
    if (res.code === 20000) {
      this.setState({
        logo: res.data.slideshow_url
      })
    }
  }

  // 查询基础信息
  fetchInfo = async () => {
    const res = await findLabNumByInfo()
    if (res.code === 20000) {
      this.setState({
        labInfo: res.data || {}
      })
    }
  }

  // 查询院系下实验室信息
  fetchYxSys = async () => {
    const res = await countByYx()
    if (res.code === 20000) {
      this.setState({
        data1: res.data
      })
    }
  }

  // 查询门锁报警记录
  fetchAlarm = async () => {
    const res = await findAbnormalRecordLock({ num: 9999 })
    const resu = await findAbnormalRecordBanPai({ num: 9999 })
    if (res.code === 20000 && resu.code === 20000) {
      this.setState({
        alarmData: (res.data || []).concat(resu.data || [])[0] || {}
      })
    }
  }

  // 获取实验室分级分类数量
  fetchRiskGrade = () => {
    const that = this
    $.ajax({
      url: IP.host + '/safety/basicSafetydata/statisticsAppGradeNum',
      type: 'GET',
      headers: { 'Authorization': 'sastc ' + cookie.load('token') },
      success: function (res) {
        // 请求成功时的处理逻辑
        if (res.code === 20000) {
          that.setState({
            riskGradeNum: res.data || {}
          })
        } else {
          Toast.fail(res.message)
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        if (jqXHR.status === 503) {
          console.log('503 Error: 未找到请求的资源');
          // 503错误的处理逻辑
        } else {
          console.log('其他错误: ' + textStatus);
          Toast.fail('请求失败！')
          // 其他错误处理逻辑
        }
      }
    });
  }

  // 查询部门下实验室安全等级对应情况
  fetchSafety = async () => {
    const res = await countByDeptSafety()
    if (res.code === 20000) {
      this.setState({
        data2: (res.data || []).map((x, key) => {
          x.key = key
          return x
        })
      })
    }
  }

  // 选择安全巡检近几天状态
  handleChangeAqxjTimeList = item => {
    const { timeAqxjList } = this.state
    this.setState({
      timeAqxjList: timeAqxjList.map(x => {
        x.checked = x.id === item.id ? true : false
        return x
      }),
    })
    this.fetchYxYhDzg(item.id)
    this.fetchAqxj(item.id)
  }

  // 查询安全巡检数
  fetchAqxj = async flag => {
    const res = await countDetailsRecord({ flag })
    if (res.code === 20000) {
      this.setState({
        aqxjData: res.data || {}
      })
    }
  }

  // 查询学院下的隐患及待整改数
  fetchYxYhDzg = async flag => {
    const res = await countDzgAndZsHidden({ flag })
    if (res.code === 20000) {
      this.setState({
        data3: (res.data || []).map((x, i) => {
          x.id = i + 1
          return x
        })
      })
    }
  }

  // 查询设备预约待审批数量
  fetchDeviceDsp = async () => {
    const res = await countDspDevice()
    if (res.code === 20000) {
      this.setState({
        deviceDsp: res.data || 0
      })
    }
  }

  // 查询危化品申购、领用待审批数量
  fetchWhp = async () => {
    const res = await dbspWhp()
    if (res.code === 20000) {
      this.setState({
        whpObj: res.data || {}
      })
    }
  }

  // 查询实验室预约待审批数量
  fetchSysDsp = async () => {
    const res = await countDspSys()
    if (res.code === 20000) {
      this.setState({
        sysDsp: res.data || 0
      })
    }
  }

  // 查询设备数/已开放设备数
  fetchDevice = async () => {
    const res = await countEquipment()
    if (res.code === 20000) {
      this.setState({
        deviceObj: res.data || {}
      })
    }
  }

  // 查询开放实验室数
  fetchKfSys = async () => {
    const res = await countKfSys()
    if (res.code === 20000) {
      this.setState({
        kfSys: res.data || {}
      })
    }
  }

  // 根据部门查询实验室预约情况
  fetchSysDep = async flag => {
    const res = await countXueYuan({ flag })
    if (res.code === 20000) {
      this.setState({
        data41: res.data || []
      })
    }
  }

  // 根据部门查询设备预约情况
  fetchDeviceByDept = async flag => {
    const res = await findEquipmentByDept({ flag })
    if (res.code === 20000) {
      this.setState({
        data42: res.data || []
      })
    }
  }

  // 选择预约情况近几天状态
  handleChangeYyqkTimeList = item => {
    const { timeYyqkList } = this.state
    this.setState({
      timeYyqkList: timeYyqkList.map(x => {
        x.checked = x.id === item.id ? true : false
        return x
      }),
      yyqkTimeId: item.id
    })
    this.fetchDeviceByDept(item.id)
    this.fetchSysDep(item.id)
  }

  // 选择预约情况实验室或设备
  handleChangeYyqkTypeList = item => {
    const { typeList, yyqkTimeId } = this.state
    this.setState({
      typeList: typeList.map(x => {
        x.checked = x.id === item.id ? true : false
        return x
      }),
      yyqkTypeId: item.id
    })
    if (item.id === 1) {
      this.fetchSysDep(yyqkTimeId)
    } else {
      this.fetchDeviceByDept(yyqkTimeId)
    }
  }

  // 查询实验室安全准备信息
  fetchExamCount = async () => {
    const res = await examCount()
    if (res.code === 20000) {
      this.setState({
        examCountData: res.data || {}
      })
    }
  }

  // 查询各类型物联网设备个数
  fetchNetDeviceNum = async () => {
    const res = await findNetDeviceNumByType()
    if (res.code === 20000) {
      this.setState({
        deviceData: res.data
      })
    }
  }

  sum(arr) {
    var sum = 0;
    for (var i = 0; i < arr.length; i++) {
      sum += arr[i];
    }
    return sum;
  }

  render() {
    const { wt1, logo, labInfo, data1, deviceDsp, riskGradeNum, data2, timeAqxjList, data3, timeYyqkList, typeList, data41, data42, deviceData, aqxjData, yyqkTypeId, deviceObj, whpObj, sysDsp, kfSys, alarmData, examCountData } = this.state
    const columns1 = [
      {
        title: '学院名称',
        dataIndex: 'dwmc',
        ellipsis: true,
      },
      {
        title: '实验室数量',
        dataIndex: 'roomNum',
        ellipsis: true,
      },
      {
        title: '专职人员',
        dataIndex: 'zzry',
        ellipsis: true,
      },
      {
        title: '课程数',
        dataIndex: 'kcs',
        ellipsis: true,
      },
      {
        title: '利用率',
        dataIndex: 'lyv',
        ellipsis: true,
        render: lyv => `${lyv}%`
      },
    ]
    const option1 = {
      title: {
        text: (riskGradeNum.yiNum || 0) + (riskGradeNum.erNum || 0) + (riskGradeNum.sanNum || 0) + (riskGradeNum.siNum || 0),    // 主标题
        top: '37%',
        left: '23.5%',
        textStyle: {
          textAlign: 'center',
          color: '#444',
          fontSize: 26,
        },
        itemGap: 4,   // 主副标题间隔
        subtext: '风险源总数',   // 副标题
        subtextStyle: {
          textAlign: 'center',
          color: '#999',
          fontSize: 12,
        },
        textAlign: 'center',
      },
      color: ['#ff0200', '#febf00', '#e8e800', '#30b5fd'],
      legend: {
        //纵向
        orient: 'vertical',
        x: '54%', //可设定图例在左、右、居中
        y: 'center', //可设定图例在上、下、居中
        icon: "circle",   //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
        itemWidth: 9,  // 设置宽度
        itemHeight: 9, // 设置高度
        itemGap: 16, // 设置间距
        selectedMode: false, // 禁止点击
        textStyle: {
          color: 'auto',
          fontSize: 12,
          rich: {
            a: {
              align: "left",  // 图例文字的对齐方式
              width: 80      // 文字宽度
            },
            b: {
              align: "left",
              width: 40,
            }
          }
        },
        formatter: (name) => {  // 图例后面加数据
          var data = option1.series[0].data;//获取series中的data
          var total = 0;
          var tarValue;
          for (var i = 0, l = data.length; i < l; i++) {
            total += data[i].value;
            if (data[i].name == name) {
              tarValue = data[i].value;
            }
          }
          return `{a|${name}}{b|${tarValue}}`;
        },
      },
      series: [
        {
          type: 'pie',
          radius: ['48%', '78%'],
          center: ["25%", "50%"],
          itemStyle: {  // 饼图边框
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          hoverAnimation: false, // 取消放大
          label: {
            show: false,
          },
          data: [
            {
              value: (riskGradeNum.yiNum || 0),
              name: 'Ⅰ级风险',
            },
            {
              value: (riskGradeNum.erNum || 0),
              name: 'Ⅱ级风险',
            },
            {
              value: (riskGradeNum.sanNum || 0),
              name: 'Ⅲ级风险',
            },
            {
              value: (riskGradeNum.siNum || 0),
              name: 'Ⅳ级风险',
            },
          ]
        }
      ]
    }
    const columns2 = [
      {
        title: '学院名称',
        dataIndex: 'dwmc',
        ellipsis: true,
      },
      {
        title: <div className='gzt4'><div className='gtz4_one'></div><div>Ⅰ级</div></div>,
        dataIndex: 'yiNum',
        ellipsis: true,
      },
      {
        title: <div className='gzt4'><div className='gtz4_two'></div><div>Ⅱ级</div></div>,
        dataIndex: 'erNum',
        ellipsis: true,
      },
      {
        title: <div className='gzt4'><div className='gtz4_three'></div><div>Ⅲ级</div></div>,
        dataIndex: 'sanNum',
        ellipsis: true,
      },
      {
        title: <div className='gzt4'><div className='gtz4_four'></div><div>Ⅳ级</div></div>,
        dataIndex: 'siNum',
        ellipsis: true,
      },
    ]
    const columns3 = [
      {
        title: '学院名称',
        dataIndex: 'deptName',
        ellipsis: true,
      },
      {
        title: '隐患总数',
        dataIndex: 'totalNum',
        ellipsis: true,
      },
      {
        title: '待整改数',
        dataIndex: 'dzgNum',
        ellipsis: true,
      },
    ]
    const columns41 = [
      {
        title: '学院名称',
        dataIndex: 'dwmc',
        ellipsis: true,
      },
      {
        title: '开放实验室数',
        dataIndex: 'roomNum',
        ellipsis: true,
      },
      {
        title: '预约次数',
        dataIndex: 'recordNum',
        ellipsis: true,
      },
      {
        title: '预约人数',
        dataIndex: 'peopleNum',
        ellipsis: true,
      },
    ]
    const columns42 = [
      {
        title: '学院名称',
        dataIndex: 'dept_name',
        ellipsis: true,
      },
      {
        title: '开放设备数',
        dataIndex: 'equipmentNum',
        ellipsis: true,
      },
      {
        title: '预约次数',
        dataIndex: 'recordNum',
        ellipsis: true,
      },
      {
        title: '预约人数',
        dataIndex: 'peopleNum',
        ellipsis: true,
      },
    ]
    const option2 = {
      title: {
        text: (examCountData.tongGuoLv || 0) + '%',
        textStyle: {
          color: '#00B4C4',
          fontSize: 24
        },
        left: 'center',
        top: '36%',
        itemGap: 6,
        subtext: '准入通过率',
        subtextStyle: {
          color: '#919499',
          fontSize: 12,
        }
      },
      series: [
        {
          name: 'outside_circle', // 外环
          type: 'gauge',
          splitNumber: 80,
          radius: '80%',
          center: ['50%', '50%'],
          startAngle: 90,
          endAngle: -269.9999,
          axisLine: {
            show: false,
            lineStyle: {
              color: [
                [0, 'rgb(203,242,246)'],
                [1, 'rgb(203,242,246)'],
              ],
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            length: 3,
            lineStyle: {
              color: 'auto',
              width: 2.5,
            },
          },
          axisLabel: {
            show: false,
          },
          detail: {
            show: false,
          },
        },
        {
          name: 'inside_circle', // 内环
          type: 'gauge',
          splitNumber: 50,
          radius: '51%',
          center: ['50%', '50%'],
          startAngle: 90,
          endAngle: -269.9999,
          axisLine: {
            show: false,
            lineStyle: {
              color: [
                [0, 'rgb(203,242,246)'],
                [1, 'rgb(203,242,246)'],
              ],
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            length: 2,
            lineStyle: {
              color: 'auto',
              width: 2,
            },
          },
          axisLabel: {
            show: false,
          },
          detail: {
            show: false,
          },
        },
        {
          name: 'cover_circle',
          type: 'pie',
          radius: ['58%', '72%'],
          center: ['50%', '50%'],
          avoidLabelOverlap: true,
          hoverAnimation: false,
          label: {
            normal: {
              show: false,
              position: 'center',
            },
            emphasis: {
              show: false,
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: [
            {
              value: (examCountData.tongGuoLv || 0),
              itemStyle: {
                color: '#00B4C4',
              },
            },
            {
              value: 100 - (examCountData.tongGuoLv || 0),
              itemStyle: {
                color: 'rgb(241,251,253)',
              },
            },
          ],
        },
      ],
    };
    return (
      <div className='gzt'>
        <div className='gzt_img'>
          <img alt='' src={IP.fileHost + logo} />
        </div>
        <div className='gzt_con'>
          <div className='gzt_one'>
            <div className='gzt_one_top'>
              <div className='gzt_one_top_list'>
                <div className='gzt_one_top_list_num'>{labInfo.totalNum || 0}</div>
                <div className='gzt_one_top_list_name'>实验室总数</div>
              </div>
              <div className='gzt_one_top_line'></div>
              <div className='gzt_one_top_list'>
                <div className='gzt_one_top_list_num'>{labInfo.zxNum || 0}</div>
                <div className='gzt_one_top_list_name'>中心总数</div>
              </div>
              <div className='gzt_one_top_line'></div>
              <div className='gzt_one_top_list'>
                <div className='gzt_one_top_list_num'>{labInfo.totalMj || 0}m²</div>
                <div className='gzt_one_top_list_name'>总面积</div>
              </div>
            </div>
            <div className='gzt_table'>
              <Table
                locale={{
                  emptyText: <div>
                    <img alt='' height={72} src='http://znmk.sastc.cn:7710/file/targets/system/icon/empty.png' />
                    <p>无满足当前筛选条件的数据</p>
                  </div>
                }}
                columns={columns1}
                rowKey={record => record.dwdm}
                dataSource={data1}
                pagination={false}
              />
            </div>
          </div>
          <div className='gzt_two'>
            <div className='gzt_two_left'>
              <img alt='' src={require('../../../images/gzt/yjxx.png')} />
              <div className='gzt_two_left_desc' style={{ width: `${wt1}px` }}>
                {alarmData.sapce_name} <span>{ alarmData.abnormal_flag === 1 ? '离线' : '低电量' }</span>
              </div>
            </div>
            <img alt='' src={require('../../../images/gzt/more.png')} />
          </div>
          <div className='gzt_three'>
            <div className='gzt_title'>
              <div className='gzt_title_point'></div>
              <div className='gzt_title_name'>待办审批</div>
            </div>
            <div className='gzt_three_con'>
              <div className='gzt_three_con_list'>
                <div className='gzt_three_con_list_num'>{sysDsp}</div>
                <div className='gzt_three_con_list_title'>实验室预约</div>
              </div>
              <div className='gzt_three_con_list_line'></div>
              <div className='gzt_three_con_list'>
                <div className='gzt_three_con_list_num'>{deviceDsp}</div>
                <div className='gzt_three_con_list_title'>设备预约</div>
              </div>
              <div className='gzt_three_con_list_line'></div>
              <div className='gzt_three_con_list'>
                <div className='gzt_three_con_list_num'>{whpObj.sg || 0}</div>
                <div className='gzt_three_con_list_title'>危化品申购</div>
              </div>
              <div className='gzt_three_con_list_line'></div>
              <div className='gzt_three_con_list'>
                <div className='gzt_three_con_list_num'>{whpObj.ly || 0}</div>
                <div className='gzt_three_con_list_title'>危化品领用</div>
              </div>
            </div>
          </div>
          <div className='gzt_four'>
            <div className='gzt_title'>
              <div className='gzt_title_point'></div>
              <div className='gzt_title_name'>实验室安全等级</div>
            </div>
            <div className='gzt_echarts'>
              <ReactEcharts option={option1} style={{ height: 180 }} />
            </div>
            <div className='gzt_table'>
              <Table
                locale={{
                  emptyText: <div>
                    <img alt='' height={72} src='http://znmk.sastc.cn:7710/file/targets/system/icon/empty.png' />
                    <p>无满足当前筛选条件的数据</p>
                  </div>
                }}
                columns={columns2}
                rowKey={record => record.key}
                dataSource={data2}
                pagination={false}
              />
            </div>
          </div>
          <div className='gzt_five'>
            <div className='gzt_title'>
              <div className='gzt_title_point'></div>
              <div className='gzt_title_name'>安全巡检</div>
            </div>
            <div className='gzt_status'>
              <div className='gzt_status_left'>
                {
                  (timeAqxjList || []).map(item => {
                    return <div className={`gzt_status_left_list ${item.checked ? 'gzt_status_left_list_active' : ''}`} key={item.id} onClick={() => this.handleChangeAqxjTimeList(item)}>{item.name}</div>
                  })
                }
              </div>
              <div></div>
            </div>
            <div className='gzt_five_con'>
              <div className='gzt_five_con_list'>
                <div className='gzt_five_con_list_num'>{(aqxjData.schoolNum || 0) + (aqxjData.deptNum || 0) + (aqxjData.dailyNum || 0)}</div>
                <div className='gzt_five_con_list_title'>巡检总数</div>
              </div>
              <div className='gzt_five_con_list'>
                <div className='gzt_five_con_list_num'>{aqxjData.schoolNum || 0}</div>
                <div className='gzt_five_con_list_title'>校级巡检</div>
              </div>
              <div className='gzt_five_con_list'>
                <div className='gzt_five_con_list_num'>{aqxjData.deptNum || 0}</div>
                <div className='gzt_five_con_list_title'>院级巡检</div>
              </div>
              <div className='gzt_five_con_list'>
                <div className='gzt_five_con_list_num'>{aqxjData.dailyNum || 0}</div>
                <div className='gzt_five_con_list_title'>日常巡检</div>
              </div>
            </div>
            <div className='gzt_table'>
              <Table
                locale={{
                  emptyText: <div>
                    <img alt='' height={72} src='http://znmk.sastc.cn:7710/file/targets/system/icon/empty.png' />
                    <p>无满足当前筛选条件的数据</p>
                  </div>
                }}
                columns={columns3}
                rowKey={record => record.id}
                dataSource={data3}
                pagination={false}
              />
            </div>
          </div>
          <div className='gzt_six'>
            <div className='gzt_title'>
              <div className='gzt_title_point'></div>
              <div className='gzt_title_name'>预约情况</div>
            </div>
            <div className='gzt_status'>
              <div className='gzt_status_left'>
                {
                  (timeYyqkList || []).map(item => {
                    return <div className={`gzt_status_left_list ${item.checked ? 'gzt_status_left_list_active' : ''}`} key={item.id} onClick={() => this.handleChangeYyqkTimeList(item)}>{item.name}</div>
                  })
                }
              </div>
              <div className='gzt_status_right'>
                {
                  (typeList || []).map(item => {
                    return <div className={`gzt_status_right_list ${item.checked ? 'gzt_status_right_list_active' : ''}`} key={item.id} onClick={() => this.handleChangeYyqkTypeList(item)}>{item.name}</div>
                  })
                }
              </div>
            </div>
            {
              yyqkTypeId === 1 ? <div className='gzt_six_con'>
                <div className='gzt_six_con_list'>
                  <div className='gzt_six_con_list_num'>{kfSys.totalNum || 0}</div>
                  <div className='gzt_six_con_list_title'>实验室数</div>
                </div>
                <div className='gzt_six_con_list'>
                  <div className='gzt_six_con_list_num'>{kfSys.kfNum || 0}</div>
                  <div className='gzt_six_con_list_title'>开放实验室数</div>
                </div>
              </div> : <div className='gzt_six_con'>
                <div className='gzt_six_con_list'>
                  <div className='gzt_six_con_list_num'>{deviceObj.totalNum || 0}</div>
                  <div className='gzt_six_con_list_title'>设备数</div>
                </div>
                <div className='gzt_six_con_list'>
                  <div className='gzt_six_con_list_num'>{deviceObj.subscribeNum || 0}</div>
                  <div className='gzt_six_con_list_title'>开放设备数</div>
                </div>
              </div>
            }
            <div className='gzt_table'>
              {
                yyqkTypeId === 1 ? <Table
                  locale={{
                    emptyText: <div>
                      <img alt='' height={72} src='http://znmk.sastc.cn:7710/file/targets/system/icon/empty.png' />
                      <p>无满足当前筛选条件的数据</p>
                    </div>
                  }}
                  columns={columns41}
                  rowKey={record => record.dwdm}
                  dataSource={data41}
                  pagination={false}
                /> : <Table
                  locale={{
                    emptyText: <div>
                      <img alt='' height={72} src='http://znmk.sastc.cn:7710/file/targets/system/icon/empty.png' />
                      <p>无满足当前筛选条件的数据</p>
                    </div>
                  }}
                  columns={columns42}
                  rowKey={record => record.dept_code}
                  dataSource={data42}
                  pagination={false}
                />
              }
            </div>
          </div>
          <div className='gzt_seven'>
            <div className='gzt_title'>
              <div className='gzt_title_point'></div>
              <div className='gzt_title_name'>实验室准入管理</div>
            </div>
            <div className='gzt_seven_con'>
              <div className='gzt_echarts'>
                <ReactEcharts option={option2} style={{ width: 200, height: 180 }} />
              </div>
              <div className='gzt_seven_con_right'>
                <div className='gzt_seven_con_right_list'>
                  <div className='gzt_seven_con_right_list_title'>课程数</div>
                  <div className='gzt_seven_con_right_list_num'>{ examCountData.keChengShu || 0 }</div>
                </div>
                <div className='gzt_seven_con_right_list'>
                  <div className='gzt_seven_con_right_list_title'>题库数</div>
                  <div className='gzt_seven_con_right_list_num'>{ examCountData.problemNum || 0 }</div>
                </div>
                <div className='gzt_seven_con_right_list'>
                  <div className='gzt_seven_con_right_list_title'>试卷数</div>
                  <div className='gzt_seven_con_right_list_num'>{ examCountData.shiJuanShu || 0 }</div>
                </div>
                <div className='gzt_seven_con_right_list'>
                  <div className='gzt_seven_con_right_list_title'>考试参与率</div>
                  <div className='gzt_seven_con_right_list_num'>{ examCountData.canYuLv || 0 }%</div>
                </div>
              </div>
            </div>
          </div>
          <div className='gzt_eight'>
            <div className='gzt_title'>
              <div className='gzt_title_point'></div>
              <div className='gzt_title_name'>实验室物联网管控</div>
            </div>
            <div className='gzt_eight_con'>
              {
                (deviceData || []).map(item => {
                  return <div className='gzt_eight_con_list' key={item.devices_type}>
                    <div className='gzt_eight_con_item'>
                      {
                        item.devices_type === 1 && <div className='gzt_eight_con_item_left'>
                          <img alt='' src={require('../../../images/gzt/1.png')} />
                          <span>智慧班牌</span>
                        </div>
                      }
                      {
                        item.devices_type === 2 && <div className='gzt_eight_con_item_left'>
                          <img alt='' src={require('../../../images/gzt/2.png')} />
                          <span>智慧门锁</span>
                        </div>
                      }
                      {
                        item.devices_type === 3 && <div className='gzt_eight_con_item_left'>
                          <img alt='' src={require('../../../images/gzt/3.png')} />
                          <span>智能传感器</span>
                        </div>
                      }
                      {
                        item.devices_type === 4 && <div className='gzt_eight_con_item_left'>
                          <img alt='' src={require('../../../images/gzt/4.png')} />
                          <span>智慧微断</span>
                        </div>
                      }
                      {
                        item.devices_type === 5 && <div className='gzt_eight_con_item_left'>
                          <img alt='' src={require('../../../images/gzt/5.png')} />
                          <span>智慧空调</span>
                        </div>
                      }
                      {
                        item.devices_type === 6 && <div className='gzt_eight_con_item_left'>
                          <img alt='' src={require('../../../images/gzt/6.png')} />
                          <span>智慧照明</span>
                        </div>
                      }
                      {
                        item.devices_type === 7 && <div className='gzt_eight_con_item_left'>
                          <img alt='' src={require('../../../images/gzt/7.png')} />
                          <span>智慧终端</span>
                        </div>
                      }
                      {
                        item.devices_type === 8 && <div className='gzt_eight_con_item_left'>
                          <img alt='' src={require('../../../images/gzt/8.png')} />
                          <span>智慧窗帘</span>
                        </div>
                      }
                      {
                        item.devices_type === 10 && <div className='gzt_eight_con_item_left'>
                          <img alt='' src={require('../../../images/gzt/1.png')} />
                          <span>管控一体机</span>
                        </div>
                      }
                      <div className='gzt_eight_con_item_num'>{item.devices_num}</div>
                    </div>
                  </div>
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Workbench