import React, { Component } from 'react'
import { TreeSelect, Select } from 'antd'
import { Picker, List, Modal, Icon, Toast, InputItem } from 'antd-mobile';
import { req_findDepartmentInfo, findZxOrRoomByDwOrZx, req_findDepartmentInfoToTeacher, findMasterProcess, findNextApprovalUser, addZyInfo, listKcByPage, listZyLysq, findZyLysqDetails } from './../../../../api/gas'

const { TreeNode } = TreeSelect;

class Add extends Component {
  state = {
    data: [
      {
        label: '2013年',
        value: '2013',
      },
      {
        label: '2014年',
        value: '2014',
      },
    ],
    slideId: '',
    startX: '',
    visible: false,
    tab_id: '',
    sqfxData: [],//方向
    jsxydm: undefined,//所属学院
    jszxid: undefined,//所属中心
    jsjsid: undefined,//所属实验室
    lyckbh: undefined,
    jsrgh: undefined,
    lyrgh2: undefined,
    lyyt: '',
    typeData: [],//类别
    whpFlbh: '',//化学品类别
    chemicalData: [],//化学品
    depTreeData: [],
    depData: [],
    centerData: [],//中心
    roomData: [],//房间
    masterProcess: 0, // 是否多人同时审批
    approveVisible: false,
    spry: undefined,
    warehouseData: [],//仓库
    userData: [],//领用人
    chemicalData: [],//领用物品
    depUserData: [],
    depArr: [],
    flbh: '',
    lyData: [],
    rkxz: [],
    lyNewData: []
  }

  componentDidMount = () => {
    document.title = '共享转移'
    window.scrollTo(0, 0)
    this._listZyLysq()
    this.fetchDepUser()
    this.fetchDep()
  }

  // 查询待入库
  _listZyLysq = async () => {
    const res = await listZyLysq({ size: 99999, page: 1 })
    if (res.code === 20000) {
      this.setState({
        lyNewData: res.data.list,
        lyData: res.data.list.map(item => {
          return {
            label: item.lyrmc1 + ',' + item.lyrmc2 + '(' + item.lybh + ')',
            value: item.lybh,
          }
        }),
      })
    }
  }

  // 数组转变
  deep = (node) => {
    let stack = node,
      data = []
    while (stack.length !== 0) {
      let pop = stack.pop()
      data.push({
        title: pop.title,
        parentId: pop.parentId,
        value: pop.value,
      })
      let children = pop.children
      if (children) {
        for (let i = children.length - 1; i >= 0; i--) {
          stack.push(children[i])
        }
      }
    }
    return data
  }

  // 查询部门和人员
  fetchDepUser = async () => {
    const res = await req_findDepartmentInfoToTeacher()
    if (res.code === 20000) {
      let menuList = (res.data || []).map(item => {
        return {
          title: item.dwmc,
          value: item.dw_id,
          parentId: item.sj_dm,
          disabled: true,
          children: (item.teacherInfoEntities || []).map(val => {
            return {
              title: val.xm,
              value: val.gh,
              parentId: val.dwdm,
              disabled: false,
            }
          })
        }
      })
      menuList = menuList.map(item => {
        item.children = item.children.length ? item.children : menuList.filter(p => p.parentId === item.value)
        return item
      }).filter(p => p.parentId === '0')
      const a = []
      const c = a.concat(menuList)
      const depArr = this.deep(c)
      this.setState({
        depUserData: menuList,
        depArr
      })
    }
  }

  // 查询学院/部门
  fetchDep = async () => {
    const res = await req_findDepartmentInfo({ dwlx: 1 })
    if (res.code === 20000) {
      let menuList = res.data
      menuList = menuList.map(a => {
        return {
          title: a.dwmc,
          key: a.dwdm,
          parentId: a.sj_dm,
          value: a.dwdm,
          disabled: a.sj_dm === '0' ? true : false
        }
      })
      menuList = menuList.map(m => {
        m.children = menuList.filter(p => p.parentId === m.key)
        return m
      }).filter(x => !res.data.find(v => v.dwdm === x.parentId))
      this.setState({
        depTreeData: menuList,
        depData: res.data,
      })
    }
  }

  // 选择部门/学院
  handleChangeDep = async e => {
    const res = await findZxOrRoomByDwOrZx({ flag: 1, param: e })
    if (res.code === 20000) {
      this.setState({
        centerData: res.data.map(item => {
          return {
            label: item.room_name,
            value: item.room_id,
          }
        })
      })
    }
  }

  // 选择中心
  handleChangeCenter = async e => {
    const res = await findZxOrRoomByDwOrZx({ flag: 2, param: e.join(',') })
    if (res.code === 20000) {
      this.setState({
        roomData: res.data.map(item => {
          return {
            label: item.room_name,
            value: item.room_id,
          }
        })
      })
    }
  }

  // 查询库存
  fetchkc = async () => {
    const { lyckbh, jsjsid } = this.state
    const res = await listKcByPage({ size: 9999999, page: 1, roomId: jsjsid[0] })
    if (res.code === 20000) {
      this.setState({
        chemicalData: res.data.list.map(item => {
          return {
            ...item,
            select_state: false,
            state: false
          }
        }),
      })
    }
  }

  // 求和
  sum(arr) {
    var sum = 0;
    for (var i = 0; i < arr.length; i++) {
      sum += arr[i];
    }
    return sum;
  }

  // 钢瓶气体总数
  whpNum = () => {
    const { chemicalData } = this.state
    return this.sum(chemicalData.map(x => x.sgwhpsl))
  }

  // 选择的领用单（气体）
  handleOkLy = async lybh => {
    const { form } = this.props
    const res = await findZyLysqDetails({ lydh: lybh.join(',') })
    if (res.code === 20000) {
      const newArr = (res.data.lysqWhpCkInfoEntities || []).map(m => {
        m.yl = m.gas_number
        m.sysl = 0
        return m
      })
      await this.setState({
        chemicalData: newArr,
      }, () => {
        res.data.zysqrgh = res.data.lysqrgh
        res.data.zysqrmc = res.data.lysqrmc
      })
    }
  }

  // 提交
  submit = async () => {
    const { spry, lyyt, jsxydm, jszxid, jsjsid, depData, centerData, roomData, chemicalData, depArr, jsrgh, lyrgh2, lyNewData, rkxz } = this.state
    if (rkxz.length === 0) {
      Toast.info('请先关联领用单！')
      return false
    }
    const obj = lyNewData.find(x => x.lybh === rkxz.join(','))
    const values = {}
    values.zysqrgh = obj.lysqrgh
    values.zysqrmc = obj.lysqrmc
    if (jsxydm === undefined || jszxid === undefined || jsjsid === undefined || jsrgh === undefined) {
      Toast.info('请补全上述内容！')
      return false
    }
    if (chemicalData.length === 0) {
      Toast.info('请选择需要转移的钢瓶！')
      return false
    }
    values.jsxydm = jsxydm
    values.jszxid = jszxid.join(',')
    values.jsjsid = jsjsid.join(',')
    values.jsxymc = depData.find(x => x.dwdm === values.jsxydm).dwmc
    values.jszxmc = centerData.find(x => x.value === values.jszxid).label
    values.jsjsmc = roomData.find(x => x.value === values.jsjsid).label

    values.whpzlsl = chemicalData.length

    values.jsrgh = jsrgh
    values.jsmc = obj.jsmc
    values.lybh = obj.lybh
    values.xydm = obj.xydm
    values.xymc = obj.xymc
    values.zxmc = obj.zxmc
    values.zysqrgh = obj.lysqrgh
    values.zysqrmc = obj.lysqrmc

    console.log(values)
    const params = {
      ...values,
      zyGasInfoEntities: chemicalData,
    }
    const res = await addZyInfo(params)
    if (res.code === 20000) {
      Toast.info("提交成功！")
      setTimeout(() => {
        this.props.history.goBack()
      }, 2000);
    }
  }

  render() {
    const { slideId, visible, tab_id, lyyt, typeData, chemicalData, depTreeData, jsxydm, centerData, jszxid, roomData, jsjsid, approveVisible, masterProcess, approveUser, spry, depUserData, jsrgh, lyrgh2, lyData } = this.state
    const loop = data =>
      data.map(item => {
        if (item.children && item.children.length) {
          return (
            <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled} />;
      });
    return (
      <div className='gas_manage_add'>
        <div className='gas_manage_add_title'>

        </div>
        <div className='gas_manage_add_form'>
          <div className='gas_manage_add_picker'>
            <Picker
              data={lyData}
              value={this.state.rkxz}
              cols={1}
              onChange={(e) => {
                this.setState({
                  rkxz: e
                }, () => {
                  this.handleOkLy(e)
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>关联领用单</List.Item>
            </Picker>
          </div>
        </div>
        <div className='gas_manage_add_form'>

          <div className='gas_manage_add_tree'>
            <div>
              <span style={{ color: 'red' }}>* </span>转移所属学院
            </div>
            <TreeSelect
              showSearch
              treeNodeFilterProp='title'
              placeholder="请选择"
              style={{ width: '100%' }}
              value={jsxydm}
              onChange={(e) => {
                this.setState({
                  jsxydm: e,
                  jszxid: undefined,
                  jsjsid: undefined
                })
                this.handleChangeDep(e)
              }}
            >
              {loop(depTreeData)}
            </TreeSelect>
          </div>
          <div className='gas_manage_add_picker'>
            <Picker
              data={centerData}
              value={jszxid}
              cols={1}
              onChange={(e) => {
                this.setState({
                  jszxid: e,
                  jsjsid: undefined
                })
                this.handleChangeCenter(e)
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>转移所属中心</List.Item>
            </Picker>
          </div>
          <div className='gas_manage_add_picker'>
            <Picker
              data={roomData}
              value={jsjsid}
              cols={1}
              onChange={(e) => {
                this.setState({
                  jsjsid: e
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>转移所属实验室</List.Item>
            </Picker>
          </div>


        </div>

        <div className='gas_manage_add_form'>
          <div className='gas_manage_add_tree'>
            <div>
              <span style={{ color: 'red' }}>* </span>转移人
            </div>
            <TreeSelect
              placeholder="请选择"
              showSearch
              treeNodeFilterProp='title'
              style={{ width: '100%' }}
              value={jsrgh}
              onChange={(e) => {
                this.setState({
                  jsrgh: e,
                })
              }}
            >
              {loop(depUserData)}
            </TreeSelect>
          </div>
        </div>

        {/* <div className='gas_manage_add_increase'>
          <div onClick={() => {
            if (!jsjsid) {
              Toast.info('请先选择实验室！')
              return
            }
            this.fetchkc()
            this.setState({
              tab_id: '',
              visible: true
            })
          }}>
            <img src={require('./../../../../images/gas/06.png')} alt='' />
            <span>选择库存</span>
          </div>
        </div> */}

        <div className='gas_manage_add_increase_record'>
          <div className='subscription_list_card' style={{ margin: '0' }}>
            {chemicalData.map((item, key) => {
              return (
                <div className='gas_manage_add_card_item' style={{ height: '130px' }} onTouchStart={(e) => {
                  this.setState({
                    startX: e.touches[0].clientX
                  })
                }} onTouchEnd={(e) => {
                  const endX = e.changedTouches[0].clientX
                  if (this.state.startX === endX) {
                    return
                  }

                  if (endX - this.state.startX > 100) { // 阈值可以根据需要调整
                    this.setState({
                      slideId: ''
                    })
                    return false;
                    // 左滑后的处理逻辑
                  }
                  if (endX + this.state.startX > 100) { // 阈值可以根据需要调整
                    this.setState({
                      slideId: item.bottle_number
                    })
                    // 右滑后的处理逻辑
                    return false
                  }
                }}>
                  <div className='gas_manage_add_card_cont  gas_manage_add_cont_float' style={{ margin: slideId === item.bottle_number ? '0px' : '0px 12px' }}>
                    <div className='subscription_list_card_item' style={{ width: slideId === item.bottle_number ? window.screen.width : window.screen.width - 24 }}>
                      <div className='subscription_list_card_head'>
                        <div className='subscription_list_card_head_left'>
                          <div>{key + 1}</div>
                          <div>{item.gas_name}</div>
                        </div>
                        <div className='gas_manage_list_item_tag'>
                          {item.gas_type_name}
                        </div>
                      </div>
                      <div className='subscription_list_card_unit' style={{ marginTop: '12px' }}>
                        <div style={{ border: 'none' }}>{item.bottle_number}</div>
                      </div>
                      <div className='flex_c'>
                        <div className='flex_c_item'>
                          <div>{item.bottle_type}</div>
                          <div>钢瓶分类</div>
                        </div>
                        <div className='flex_c_item'>
                          <div>{item.bottle_specs}</div>
                          <div>钢瓶规格</div>
                        </div>
                        <div className='flex_c_item'>
                          <div>{item.gas_number}L</div>
                          <div>当前气量</div>
                        </div>
                      </div>
                    </div>
                    <div className='gas_manage_add_card_del' style={{ display: slideId === item.bottle_number ? 'block' : 'none' }} onClick={() => {
                      this.setState({
                        chemicalData: chemicalData.filter(x => x.bottle_number !== item.bottle_number)
                      })
                    }}>
                      删除
                    </div>
                  </div>
                </div>
              )
            })}

          </div>
        </div>
        <div className='gas_manage_list_but gas_manage_list_but_two'>
          <div onClick={() => {
            window.history.go(-1);
          }}>取消</div>
          <div onClick={() => {
            this.submit()
          }}>确定</div>
        </div>

      </div >
    )
  }
}

export default Add
