import http from '../config/httpconfig'

// 气体分类
/**
 * 分页查询所有数据
 */
export const gasTypeAll = params => {
    return http.get('/qtgp/gasType/selectAll', params)
}

/**
 * 不分页查询所有数据
 */
export const gasTypeList = params => {
    return http.get('/qtgp/gasType/list', params)
}

/**
 * 新增数据
 */
export const gasTypeInsert = params => {
    return http.jsonPost('/qtgp/gasType/insert', params)
}

/**
 * 修改数据
 */
export const gasTypeUpdate = params => {
    return http.jsonPost('/qtgp/gasType/update', params)
}

/**
 * 删除数据
 */
export const gasTypeDelete = params => {
    return http.get('/qtgp/gasType/delete', params)
}

// 气体信息

/**
 * 分页查询所有数据
 */
export const gasInfoAll = params => {
    return http.get('/qtgp/gasInfo/selectAll', params)
}

/**
 * 不分页查询所有数据
 */
export const gasInfoList = params => {
    return http.get('/qtgp/gasInfo/list', params)
}

/**
 * 新增数据
 */
export const gasInfoInsert = params => {
    return http.jsonPost('/qtgp/gasInfo/insert', params)
}

/**
 * 修改数据
 */
export const gasInfoUpdate = params => {
    return http.jsonPost('/qtgp/gasInfo/update', params)
}

/**
 * 删除数据
 */
export const gasInfoDelete = params => {
    return http.get('/qtgp/gasInfo/delete', params)
}

// 钢瓶管理

/**
 * 分页查询所有数据
 */
export const bottlefoAll = params => {
    return http.get('/qtgp/steelBottleInfo/selectAll', params)
}

/**
 * 新增数据
 */
export const bottleInsert = params => {
    return http.jsonPost('/qtgp/steelBottleInfo/insert', params)
}

/**
 * 修改数据
 */
export const bottleUpdate = params => {
    return http.jsonPost('/qtgp/steelBottleInfo/update', params)
}

/**
 * 删除数据
 */
export const bottleDelete = params => {
    return http.get('/qtgp/steelBottleInfo/delete', params)
}

// 审批配置
/**
 * 查询审批配置
 */
export const gasApproveConfigList = params => http.get('/qtgp/approvalmaster/findList', params)

/**
 * 新增审批配置
 */
export const gasApproveConfigSave = params => http.jsonPost('/qtgp/approvalmaster/save', params)

/**
 * 编辑审批配置
 */
export const gasApproveConfigUpdate = params => http.jsonPost('/qtgp/approvalmaster/update', params)

/**
 * 根据ID查询审批详情
 */
export const gasApproveFindById = params => http.get('/qtgp/approvalmaster/findById', params)

/**
 * 删除审批配置
 */
export const gasApproveConfigDel = params => http.post('/qtgp/approvalmaster/delete', params)

/**
 * 开启/关闭审批流程
 */
export const gasUpdateMasterState = params => http.get('/qtgp/approvalmaster/updateMasterState', params)

/**
 * 根据ID查询审批详情
 */
export const gasApprovalFindById = params => http.get('/qtgp/approvalmaster/findById', params)

// 入库管理
/**
 * 查询中心或实验室
 */
export const findZxOrRoomByDwOrZx = params => http.get('/system/laboratoryInfo/findZxOrRoomByDwOrZx', params)

/**
 * 查询入库信息
 */
export const findPageRkInfo = params => http.get('/qtgp/rkinfo/findPageRkInfo', params)

/**
 * 新增入库信息
 */
export const saveRkInfo = params => http.jsonPost('/qtgp/rkinfo/saveRkInfo', params)

/**
 * 编辑入库信息
 */
export const updateRkInfo = params => http.jsonPost('/qtgp/rkinfo/updateRkInfo', params)

/**
 * 删除入库信息
 */
export const removeRkInfo = params => http.get('/qtgp/rkinfo/removeRkInfo', params)

/**
 * 查询教师
 */
export const req_findDepartmentInfoToTeacher = params => http.get('/system/departmentinfo/findDepartmentInfoToTeacher', params)

/**
 * 查询实验室拥有的气体钢瓶
 */
export const listSteelBottleByRoom = params => http.get('/qtgp/steelBottleInfo/listSteelBottleByRoom', params)

/**
 * 获取下一个审批人员信息
 */
export const findNextApprovalUser = params => http.get('/qtgp/approvalprocessrecord/findNextApprovalUser', params)


/**
 * 获取审批是多人共同审批还是单人审批
 */
export const findMasterProcess = params => http.get('/qtgp/approvalmaster/findMasterProcess', params)

/**
 * 提交审批入库信息
 */
export const submitRksp = params => http.jsonPost('/qtgp/rkinfo/submitRksp', params)

/**
 * 根据入库单号查询入库详情（草稿）
 */
export const findRkInfoById = params => http.get('/qtgp/rkinfo/findRkInfoById', params)

/**
 * 根据入库单号查询入库详情（详情）
 */
export const findRgInfoDetailsById = params => http.get('/qtgp/rkinfo/findRgInfoDetailsById', params)


/**
 * 查询危化品审批列表
 */
export const listMyApprovalRecord = params => http.get('/qtgp/approvalprocessrecord/listMyApprovalRecord', params)

/**
 * 提交审批
 */
export const submitApprovalResult = params => http.post('/qtgp/approvalprocessrecord/submitApprovalResult', params)

/**
 * 会签审批判断是否是最后一个人审批（共同审批）
 */
export const estimateCountersign = params => http.get('/qtgp/approvalprocessrecord/estimateCountersign', params)

/**
 * 查询审批详情
 */
export const listApprovalRecordById = params => http.get('/qtgp/approvalprocessrecord/listApprovalRecordById', params)


/**
 * 查询领用登记信息
 */
export const findPageLyInfo = params => http.get('/qtgp/lysqInfo/findPageLyInfo', params)

/**
 * 新增领用登记信息
 */
export const addLysqInfo = params => http.jsonPost('/qtgp/lysqInfo/addLysqInfo', params)

/**
 * 编辑领用登记信息
 */
export const updateLysqInfo = params => http.jsonPost('/qtgp/lysqInfo/updateLysqInfo', params)

/**
 * 删除领用登记信息
 */
export const removeLyInfo = params => http.get('/qtgp/lysqInfo/removeLyInfo', params)

/**
 * 根据领用单号查询领用详情（草稿）
 */
export const selectOneLy = params => http.get('/qtgp/lysqInfo/selectOne', params)

/**
 * 根据领用单号查询领用详情（详情）
 */
export const findLyInfoDetailsById = params => http.get('/qtgp/lysqInfo/findLyInfoDetailsById', params)

/**
 * 提交领用审批信息
 */
export const submitLysp = params => http.jsonPost('/qtgp/lysqInfo/submitLysp', params)

/**
 * 查询仓库库存信息
 */
export const findPageKcInfoByCk = params => http.get('/qtgp/kcinfo/findPageKcInfoByCk', params)

/**
 * 查询库存
 */
export const listKcByPage = params => http.get('/qtgp/steelBottleInfo/listKcByPage', params)

/*****************领用出库******************* */

/**
 * 查询可领用出库的数据
 */
export const listWaitCk = params => http.get('/qtgp/lysqInfo/listWaitCk', params)

/**
 * 查询仓库下某个危化品的明细
 */
export const findKcmxInfo = params => http.get('/qtgp/kcmxinfo/findKcmxInfo', params)

/**
 * 提交出库
 */
export const lysqWhpCkInfoLyck = params => http.jsonPost('/qtgp/lysqWhpCkInfo/lyck', params)

/**
 * 取消领用
 */
export const cancelLysq = params => http.get('/qtgp/lysqInfo/cancelLysq', params)


/**
 * 查询出库记录
 */
export const findCkjl = params => http.get('/qtgp/lysqWhpCkInfo/findCkjl', params)

/**
 * 查询出库记录详情
 */
export const getCkDetails = params => http.get('/qtgp/lysqWhpCkInfo/getCkDetails', params)

/**
 * 查询归还信息
 */
export const findPageGhInfo = params => http.get('/qtgp/ghsqInfo/findPageGhInfo', params)

/**
 * 查询待归还领用单
 */
export const findDghLyinfo = params => http.get('/qtgp/lysqInfo/findDghLyinfo', params)

/**
 * 查询待归还领用单详情
 */
export const findDghDetails = params => http.get('/qtgp/lysqInfo/findDghDetails', params)

/**
 * 新增归还信息
 */
export const addGhsqInfo = params => http.jsonPost('/qtgp/ghsqInfo/addGhsqInfo', params)

/**
 * 编辑归还信息
 */
export const updateGhsqInfo = params => http.jsonPost('/qtgp/ghsqInfo/updateGhsqInfo', params)

/**
 * 删除归还信息
 */
export const removeGhInfo = params => http.get('/qtgp/ghsqInfo/removeGhInfo', params)

/**
 * 提交归还审批信息
 */
export const submitGhsp = params => http.jsonPost('/qtgp/ghsqInfo/submitGhsp', params)

/**
 * 根据归还单号查询归还详情（草稿）
 */
export const ghsqInfoSelectOne = params => http.get('/qtgp/ghsqInfo/selectOne', params)

/**
 * 根据归还单号查询归还详情（详情）
 */
export const findGhInfoDetailsById = params => http.get('/qtgp/ghsqInfo/findGhInfoDetailsById', params)

//#region 使用管理

/**
 * 查询使用记录
 */
export const findPageSyjlInfo = params => http.get('/qtgp/syjlinfo/findPageSyjlInfo', params)

/**
 * 新增使用记录
 */
export const saveSyjlInfo = params => http.jsonPost('/qtgp/syjlinfo/saveSyjlInfo', params)

/**
 * 编辑使用记录
 */
export const updateSyjlInfo = params => http.jsonPost('/qtgp/syjlinfo/updateSyjlInfo', params)

/**
 * 删除使用记录
 */
export const removeSyjlInfo = params => http.get('/qtgp/syjlinfo/removeSyjlInfo', params)

/**
 * 查询待填写领用单信息
 */
export const findCkInfo = params => http.get('/qtgp/syjlinfo/findCkInfo', params)

/**
 * 根据领用单号查询出库危化品信息
 */
export const findCkWhpInfoDetails = params => http.get('/qtgp/syjlinfo/findCkWhpInfoDetails', params)

/**
 * 根据使用单号查询详情信息
 */
export const findSyjlnfoDetails = params => http.get('/qtgp/syjlinfo/findSyjlnfoDetails', params)

//#endregion

/**
 * 查询所有库存明细信息
 */
export const findAllKcmxInfo = params => http.get('/qtgp/kcmxinfo/findAllKcmxInfo', params)

/**
 * 查询调拨信息
 */
export const findPageTbInfo = params => http.get('/qtgp/tbinfo/findPageTbInfo', params)

/**
 * 根据调拨单号查询详情（查询记录详情）
 */
export const findTbInfoDetailsById = params => http.get('/qtgp/tbinfo/findTbInfoDetailsById', params)

/**
 * 保存调拨信息(jsonpost)
 */
export const saveTbInfo = params => http.jsonPost('/qtgp/tbinfo/saveTbInfo', params)

/**
* 根据权限查询院部/中心/实验室树型结构
*/
export const req_findDepTreeInfo = params => http.get('/system/departmentinfo/findDepTreeInfo', params)

/**
* 通过主键查询单条数据
*/
export const req_selectOne = params => http.get('/qtgp/steelBottleInfo/selectOne', params)


/**
 * 查询组织架构信息
 */
export const req_findDepartmentInfo = params => http.get('/system/departmentinfo/findDepartmentInfo', params)

/**
 * 查询转移记录列表信息
 */
export const findPageZyInfo = params => http.get('/qtgp/zy/findPageZyInfo', params)

/**
 * 通过主键查询单条数据
 */
export const selectOne = params => http.get('/qtgp/zy/selectOne', params)

/**
 * 查询待填写领用单信息
 */
export const listZyLysq = params => http.get('/qtgp/lysqInfo/listZyLysq', params)


/**
 * 根据领用单号查询出库危化品信息
 */
export const findZyLysqDetails = params => http.get('/qtgp/lysqInfo/findZyLysqDetails', params)


/**
 * 新增转移申请
 */
export const addZyInfo = params => http.jsonPost('/qtgp/zy/addZyInfo', params)


// 统计

/**
 * 钢瓶气体余量预警
 */
export const gpqtyl = params => http.get('/qtgp/tj/gpqtyl', params)

/**
 * 气体分类统计
 */
export const qtfl = params => http.get('/qtgp/tj/qtfl', params)

/**
 * 空瓶统计
 */
export const kptj = params => http.get('/qtgp/tj/kptj', params)

/**
 * 使用气体最多排行
 */
export const qtsyl = params => http.get('/qtgp/tj/qtsyl', params)

/**
 * 使用频繁气体排行
 */
export const qtsycs = params => http.get('/qtgp/tj/qtsycs', params)

/**
 * 近半年/一年钢瓶数量
 */
export const gprksl = params => http.get('/qtgp/tj/gprksl', params)

/**
 * 近半年/钢瓶位置分布、院系
 */
export const wzfb_yx = params => http.get('/qtgp/tj/wzfb_yx', params)