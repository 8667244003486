import http from '../config/httpconfig'

// 微信登录
export const req_wxLogin = params => http.get('/mobile/mobilelogin/wxLogin', params)

export const findMyInfo = params => http.get('/mobile/mobilelogin/findMyInfo', params)

// 微信扫码网页端登录
export const userLogin = params => http.get('/mobile/mobilelogin/userLogin', params)

//#region 班牌绑定房间

/**
 * 查询可绑定的房间
 */
export const findSpaceByLab = params => http.get('/brand/remote/findAppSpaceByLab', params)

/**
* 获取绑定锁信息
*/
export const req_getLockByRoomId = params => http.get('/znmk/devices/getLockByRoomId', params)

/**
 * 新增班牌信息
 */
export const saveCardInfo = params => http.jsonPost('/brand/dlcardinfo/saveCardInfo', params)

//#endregion

//#region 工号认证

/**
 * 查询用户信息
 */
export const findUserInfo = params => http.get('/mobile/mobilelogin/findUserInfo', params)

/**
 * 认证用户信息
 */
export const authUser = params => http.post('/mobile/mobilelogin/authUser', params)

//#endregion

//#region 首页

/**
 * 查询logo
 */
export const mobileFindLogo = params => http.get('/mobile/schoollogo/findLogo', params)

/**
 * 查询海报
 */
export const findSlideshowInfo = params => http.get('/mobile/mobileindex/findSlideshowInfo', params)

/**
 * 查询模块
 */
export const findModuleInfo = params => http.get('/mobile/mobileindex/findModuleInfo', params)

/**
 * 查询module_url数字模块
 */
export const findSecondModuleInfo = params => http.get('/mobile/mobileindex/findSecondModuleInfo', params)

/**
 * 查询通知公告
 */
export const findMobileNoticeInfo = params => http.get('/brand/dlnoticeinfo/findMobileNoticeInfo', params)
/**
 * 查询通知公告
 */
export const req_findNoticeInfo = params => http.get('/brand/dlnoticeinfo/findNoticeInfo', params)

/**
 * 查询通知公告详情
 */
export const findNoticeInfoById = params => http.get('/brand/dlnoticeinfo/findNoticeInfoById', params)

/**
 * 查询安全视频
 */
export const findSafetyVideo = params => http.get('/mobile/mobileindex/findSafetyVideo', params)

/**
 * 查询实验室预约消息通知
 */
export const findNewestMessage = params => http.get('/subscribe/messagenotification/findNewestMessage', params)

/**
 * 查询设备预约消息通知
 */
export const findNewestMessageDevice = params => http.get('/devicesub/messagenotification/findNewestMessage', params)

/**
 * 修改实验室预约消息为已读
 */
export const updateMessageState = params => http.get('/subscribe/messagenotification/updateMessageState', params)

/**
 * 修改设备预约消息为已读
 */
export const updateMessageStateDevice = params => http.get('/devicesub/messagenotification/updateMessageState', params)

/**
 * 查询风险上报消息通知
 */
export const findSbNewestMessage = params => http.get('/safety/messagenotification/findSbNewestMessage', params)

/**
 * 查询风险变更消息通知
 */
export const findBgNewestMessage = params => http.get('/safety/messagenotification/findBgNewestMessage', params)

/**
 * 查询我的风险上报
 */
export const findMobileSafetyInfo = params => http.get('/safety/safetymobile/findMobileSafetyInfo', params)

/**
 * 查询我的风险上报详情
 */
export const findSafetyInfoByID = params => http.get('/safety/safetymobile/findSafetyInfoByID', params)

/**
 * 查询风险管控消息通知数据值
 */
export const countMessageFxgk = params => http.get('/safety/messagenotification/countMessage', params)

/**
 * 查询实验室预约消息通知数据值
 */
export const countMessageJsyy = params => http.get('/subscribe/messagenotification/countMessage', params)

//#endregion

//#region 智能门锁

/**
 * 查询已授权楼宇楼层信息
 */
export const req_listUserRoomInfo = params => http.get('/znmk/appLock/listUserRoomInfo', params)

/**
 * 查询已授权楼宇/楼层信息
 */
export const listUserBuildingInfo = params => http.get('/znmk/appLock/listUserBuildingInfo', params)

/**
 * 获取已授权的房间列表
 */
export const listUserRoomLock = params => http.get('/znmk/appLock/listUserAuthLock', params)

/**
 * 获取密码位数
 */
export const findPasswordLength = params => http.get('/znmk/appLock/findPasswordLength', params)

/**
 * 下发指纹
 */
export const downloadFingerprintToDevice = params => http.get('/znmk/appLock/downloadFingerprintToDevice', params)

/**
 * 获取二维码字符串
 */
export const getQrcodeStr = params => http.get('/znmk/appLock/getQrcodeStr', params)

/**
 * 设置常用房间
 */
export const setUserRoomSort = params => http.get('/znmk/appLock/setUserRoomSort', params)

/**
 * 刷新缓存条数
 */
export const getCmdCount = params => http.get('/znmk/appLock/getCmdCount', params)

/**
 * 一键开锁
 */
export const ycOpenLock = params => http.post('/znmk/appLock/ycOpenLock', params)

/**
 * 锁下发临时密码
 */
export const lockLsIssuePassWord = params => http.post('/znmk/gd/lockLsIssuePassWord', params)

/**
 * 获取房间详情信息
 */
export const getDeviceLockById = params => http.get('/znmk/appLock/getDeviceLockById', params)

/**
 * 生成离线密码
 */
export const req_offonlinePwd = params => http.get('/znmk/gd/offonlinePwd', params)

/**
 * 查询授权信息（内有密码）
 */
export const getDeviceAuth = params => http.get('/znmk/gd/getDeviceAuth', params)

/**
 * 锁下发长期密码
 */
export const lockIssuePassWord = params => http.post('/znmk/gd/lockIssuePassWord', params)

/**
 * 房间批量授权
 */
export const req_batchAuthRoom = params => http.jsonPost('/znmk/appLock/batchAuthRoom', params)

/**
 * 查询已授权密码的房间列表
 */
export const req_listUserAuthMmRoom = params => http.get('/znmk/appLock/listUserAuthMmLock', params)

/**
 * 查询用户已授权楼宇/楼层信息
 */
export const req_listUserBuildingInfo = params => http.get('/znmk/appLock/listUserBuildingInfo', params)

/**
 * 查询用户批量授权记录
 */
export const req_findUserBatchAuth = params => http.get('/znmk/appLock/findUserBatchAuth', params)

/**
 * 根据设备ID查询设备
 */
// export const req_findByDeviceId = params => http.get('/znmk/devices/findByDeviceId', params)
export const getDeviceLockByUser = params => http.get('/znmk/appLock/getDeviceLockByUser', params)

/**
 * 查询用户开锁记录
 */
export const listUserOpenLockRecord = params => http.get('/znmk/appLock/listUserOpenLockRecord', params)

/**
 * 查询临时授权记录
 */
export const getUserDeviceTemporaryAuthRecord = params => http.get('/znmk/gd/getUserDeviceTemporaryAuthRecord', params)

/**
 * 取消临时授权
 */
export const deleteTemporaryAuth = params => http.post('/znmk/gd/deleteTemporaryAuth', params)

//#endregion

//#region 预约实验室

/**
 * 查询可预约的楼宇楼层房间
 */
export const findSubRoomIds = params => http.get('/subscribe/subscribeConfig/findSubRoomIds', params)

export const findSubSpaceInfo = params => http.get('/mobile/remote/findSubSpaceInfo', params)

/**
 * 查询当前课程表的时间范围
 */
export const getCourseSemesterByTime = params => http.get('/system/courseSemester/getCourseSemesterByTime', params)

/**
 * 获取星期
 */
export const findClientDate = params => http.get('/system/courseScheduleRecord/findClientDate', params)

/**
 * 获取节次
 */
export const getNodeBySemester = params => http.get('/system/courseSemesterNode/getNodeBySemester', params)

/**
 * 查询课表信息
 */
export const findScheduleRecord = params => http.get('/system/courseScheduleRecord/findScheduleRecord', params)

/**
 * 查询预约信息
 */
export const findUserClientRecord = params => http.get('/subscribe/websubscribe/findUserClientRecord', params)

/**
 * 查询座位信息
 */
export const findSeatInfo = params => http.jsonPost('/subscribe/subscribeconfigseat/findSeatInfo2', params)

/**
 * 查询我的预约记录
 */
export const findMySubscribeRecord = params => http.get('/subscribe/subscribereservationrecord/findMySubscribeRecord', params)

/**
 * 查询预约记录详情
 */
export const findSubByID = params => http.get('/subscribe/subscribereservationrecord/findSubByID', params)

/**
 * 提交预约
 */
export const submitSubscribeRoom = params => http.post('/subscribe/subscribereservationrecord/submitSubscribeRoom', params)

/**
 * 取消预约
 */
export const cancelSubByID = params => http.get('/subscribe/subscribereservationrecord/cancelSubByID', params)

/**
 * 查询字典信息
 */
export const listDictionary = params => http.get('/system/dictionaryData/list', params)

/**
 * 根据房间id查询房间详情信息
 */
export const selectOneLab = params => http.get('/system/laboratoryInfo/selectOne', params)

//#endregion

//#region 审批中心

/**
 * 查询我的预约实验室审批
 */
export const listMyApproval = params => http.get('/subscribe/approval/listMyApproval', params)

/**
 * 查询我的预约设备审批
 */
export const listMyApprovalDevice = params => http.get('/devicesub/approval/listMyApproval', params)

/**
 * 查询实验室审批详情
 */
export const findApprovalDetails = params => http.get('/subscribe/approval/findApprovalDetails', params)

/**
 * 提交房间预约审批结果
 */
export const submitApprovalResult = params => http.post('/subscribe/approval/submitApprovalResult', params)

/**
 * 查询设备预约审批详情
 */
export const approvalDetailsDevice = params => http.get('/devicesub/approval/findApprovalDetails', params)

/**
 * 提交设备预约审批结果
 */
export const submitResultDevice = params => http.post('/devicesub/approval/submitApprovalResult', params)

//#endregion

//#region 智慧空调

/**
 * 查询所有房间信息
 */
export const findSpaceInfo = params => http.get('/system/departmentinfo/findDepTreeInfo', params)

/**
 * 分楼宇楼层房间查询接口
 */
export const listAppSpace = params => http.get('/system/spaceinfo/listAppSpace', params)

/**
 * 查询所有空调设备信息
 */
export const findAllZnkt = params => http.get('/znkt/deviceInfoMobile/findAll', params)

/**
 * 查询空调详情信息
 */
export const selectOneZnkt = params => http.get('/znkt/deviceInfo/selectOne', params)

/**
 * 空调操作
 */
export const sendCmdZnkt = params => http.get('/znkt/deviceInfoMobile/sendCmd', params)

/**
 * 查询空调操作记录
 */
export const listCmdRecordZnkt = params => http.get('/znkt/deviceInfoMobile/listCmdRecord', params)

/**
 * 查询定时配置信息
 */
export const findScheduledTaskZnkt = params => http.get('/znkt/deviceInfoMobile/findScheduledTask', params)

/**
 * 更改定时配置的状态
 */
export const updateScheduledState = params => http.get('/znkt/deviceInfoMobile/updateScheduledState', params)

/**
 * 查询定时配置详情
 */
export const findScheduledById = params => http.get('/znkt/deviceInfoMobile/findScheduledById', params)

/**
 * 新增定时配置
 */
export const addScheduled = params => http.jsonPost('/znkt/deviceInfoMobile/addScheduled', params)

/**
 * 编辑定时配置
 */
export const updateScheduled = params => http.jsonPost('/znkt/deviceInfoMobile/update', params)

/**
 * 删除定时配置
 */
export const deleteScheduled = params => http.get('/znkt/deviceInfoMobile/deleteScheduled', params)

//#endregion

//#region 智慧照明

/**
 * 查询所有继电器信息
 */
export const findRelayInfoDevice = params => http.get('/relay/relaymobile/findRelayInfoDevice', params)

/**
 * 一键开关用电设备
 */
export const issueAllKgRelay = params => http.post('/relay/devicecmd/issueAllKg', params)

/**
 * 查询照明设备信息
 */
export const findAirSwitchDevice = params => http.get('/relay/relaymobile/findAirSwitchDevice', params)

/**
 * 单个开关用电设备
 */
export const issueKgRelay = params => http.post('/relay/devicecmd/issueKg', params)

/**
 * 查询用电设备操作记录
 */
export const findCmdRecordRelay = params => http.get('/relay/relaymobile/findCmdRecord', params)


//#endregion

//#region 智慧用电
/**
 * 移动端 用电--房间列表
 */
export const req_yongdianRoomList = params => {
  return http.get('/znyd/mobiledevice/listDeviceRoom', params)
}
/**
 * 移动端 用电--房间内设备的分组
 */
export const req_yongdianRoomDeviceGroupList = params => {
  return http.get('/znyd/mobiledevice/listByRoomId', params)
}
/**
 * 移动端 用电--房间内分组下的空开设备
 */
export const req_yongdianRoomKKList = params => {
  return http.get('/znyd/mobiledevice/listDeviceByRoom', params)
}

/**
 * 移动端 用电--根据房间id查询房间信息
 */
export const req_fetchRoomInfo = params => {
  return http.get('/system/laboratoryInfo/selectOne', params)
}
/**
 * 移动端设备管理查询所有dtu信息
 */
export const req_findDtuDevice = params => {
  return http.get('/znyd/mobiledevice/findDtuDevice', params)
}
/**
 * 移动端设备管理查询空开设备信息
 */
export const req_findAirSwitchDevice = params => {
  return http.get('/znyd/mobiledevice/findAirSwitchDevice', params)
}
/**
 * 分闸，合闸
 */
export const req_sendCmd = params => {
  return http.get('/znyd/device/sendCmd', params)
}
/**
 * 移动端查询操作记录
 */
export const req_findCmdRecord = params => {
  return http.get('/znyd/mobiledevice/findCmdRecord', params)
}

/**
 * 查询空开参数配置
 */
export const req_getConfigBySwitchImei = params => {
  return http.get('/znyd/airswitchdeviceinfo/getById', params)
}

/**
 * 修改空开报警使能开关配置
 */
export const updateDeviceAlarm = params => http.jsonPost('/znyd/airswitchdeviceinfo/updateDeviceAlarm', params)

/**
 * 修改空开预警使能开关配置
 */
export const updateDeviceWarn = params => http.jsonPost('/znyd/airswitchdeviceinfo/updateDeviceWarn', params)

/**
 * 修改空开定时开关/使能控制
 */
export const req_updateSwitchConfig2 = params => http.jsonPost('/znyd/airSwutchParamConfig/updateSwitchConfig2', params)

/**
 * 移动端查询告警数据
 */
export const req_findAlarmRecord = params => http.get('/znyd/mobiledevice/findAlarmRecord', params)

/**
 * 移动端查看报警记录
 */
export const req_findUpdateState = params => http.get('/znyd/mobiledevice/findUpdateState', params)


//#endregion

//#region 环境监测

/**
 * 查询环境监测信息
 */
export const findEnvironmentInfo = params => http.get('/environmental/environmentalmobile/findRelayInfoDevice', params)

/**
 * 查询房间下环境监测信息
 */
export const findDeviceParameterByRoomId = params => http.get('/environmental/environmentalmobile/findDeviceParameterByRoomId', params)

/**
 * 查询环境监测预警数据
 */
export const findMobileAlarmRecord = params => http.get('/environmental/environmentalmobile/findMobileAlarmRecord', params)

/**
 * 查看环境监测预警数据
 */
export const updateStateAlarm = params => http.post('/environmental/environmentalmobile/updateState', params)

/**
 * 查询数据曲线
 */
export const findParamDataRecord = params => http.get('/environmental/environmentalmobile/findParamDataRecord', params)

//#endregion

//#region 智能终端

/**
 * 查询所有智能终端信息
 */
export const findZnkzqInfoDevice = params => http.get('/znkzq/relaymobile/findRelayInfoDevice', params)

/**
 * 一键开关用电设备
 */
export const issueAllKgZnkzq = params => http.post('/znkzq/devicecmd/issueAllKg', params)

/**
 * 查询用电设备信息
 */
export const findZnkzqSwitchDevice = params => http.get('/znkzq/relaymobile/findAirSwitchDevice', params)

/**
 * 单个开关用电设备
 */
export const issueKgZnkzq = params => http.post('/znkzq/devicecmd/issueKg', params)

/**
 * 查询用电设备操作记录
 */
export const findCmdRecordZnkzq = params => http.get('/znkzq/relaymobile/findCmdRecord', params)

//#endregion

//#region 智慧窗帘

/**
 * 查询所有窗帘信息
 */
export const findAllCurtain = params => http.get('/zncl/deviceInfoMobile/findAll', params)

/**
 * 查询操作记录
 */
export const listCmdRecordCurtain = params => http.get('/zncl/deviceInfoMobile/listCmdRecord', params)

/**
 * 查询窗帘详情信息
 */
export const selectOneCurtain = params => http.get('/zncl/deviceInfo/selectOne', params)

/**
 * 执行窗帘操作
 */
export const sendCmdCurtain = params => http.get('/zncl/deviceInfo/sendCmd', params)

//#endregion



//#region 快速报修
/**
 * 查询报修工单类型
 */
export const findAppOrderType = params => http.get('/repair/ordertype/findAppOrderType', params)


/**
 * 上传报修照片
 */
export const uploadOrderPic = params => http.upload('/repair/order/uploadOrderPic', params)

/**
 * 查询故障区域
 */
export const findFaultBuilding = params => http.get('/repair/repairrolearea/findFaultBuilding', params)

/**
 * 提交工单
 */
export const req_commitOrder = params => http.jsonPost('/repair/order/commitOrder', params)

/**
 * 查询报修区域下的维修工
 */
export const findPhoneRepairUser = params => http.get('/repair/order/findPhoneRepairUser', params)

/**
 * 电话报修
 */
export const phoneRepairOrder = params => http.jsonPost('/repair/order/phoneRepairOrder', params)


/**
 * 查询登录用户拥有的按钮权限
 */
export const req_findUserFunction = params => http.get('/repair/repairrolefunction/findUserFunction', params)



/**
 * 手机端根据订单状态查询用户的工单信息
 */
export const req_appFindOrderByOrderStatus = params => http.get('/repair/order/appFindOrderByOrderStatus', params)

/**
 * 取消工单
 */
export const req_cancelOrder = params => http.get('/repair/order/cancelOrder', params)

/**
 * 拒接工单
 */
export const req_refuseOrder = params => http.get('/repair/order/refuseOrder', params)

/**
 * 维修工接单
 */
export const req_acceptOrder = params => http.get('/repair/order/acceptOrder', params)

/**
 * 根据工单id查询维修人员信息
 */
export const req_findRepairUser = params => http.get('/repair/order/findRepairUser', params)

/**
 * 派发工单
 */
export const req_sendOrder = params => http.get('/repair/order/sendOrder', params)

/**
 * 转派工单
 */
export const req_transferOrder = params => http.get('/repair/order/transferOrder', params)

/**
 * 终止工单
 */
export const req_endOrder = params => http.get('/repair/order/endOrder', params)

/**
 * 工单完工
 */
export const req_finishOrder = params => http.get('/repair/order/finishOrder', params)

/**
 * 评价
 */
export const req_commentOrder = params => http.post('/repair/order/commentOrder', params)

/**
 * 根据工单ID查询工单详情
 */
export const req_findOrderDetails = params => http.get('/repair/order/findOrderDetails', params)

/**
 * 根据工单id查询工单进度
 */
export const req_findOrderProgress = params => http.get('/repair/order/findOrderProgress', params)


//#endregion

//#region 巡检管理

/**
 * 查询巡检信息
 */
export const findAppTask = params => http.get('/polling/apppollingtask/findAppTask', params)

/**
 * 开始巡检
 */
export const startChecking = params => http.get('/polling/apppollingtask/startChecking', params)

/**
 * 查询巡检详情
 */
export const finPollingTaskById = params => http.get('/polling/apppollingtask/finPollingTaskById', params)

/**
 * 查询巡检房间
 */
export const finAppPollingTaskDetails = params => http.get('/polling/apppollingtask/finAppPollingTaskDetails', params)

/**
 * 开始巡检房间
 */
export const startCheckingTask = params => http.post('/polling/apppollingtask/startCheckingTask', params)

/**
 * 根据任务id查询房间信息
 */
export const listRoomByTaskId = params => http.get('/polling/apppollingtask/listRoomByTaskId', params)

/**
 * 查询巡检项
 */
export const findAllMobileType = params => http.get('/polling/dlpollingtype/findAllMobileType', params)

/**
 * 提交专项巡检信息
 */
export const submitZxxj = params => http.post('/polling/apppollingtask/submitZxxj', params)

/**
 * 提交专项巡检信息
 */
export const submitSsb = params => http.post('/polling/apppollingtask/submitSsb', params)

/**
 * 查询我的专项巡检/随手报巡检记录
 */
export const findAppMyRecord = params => http.get('/polling/apppollingtask/findAppMyRecord', params)

//#endregion


//#region 房间管理

/**
 * 查询所有房间信息
 */
export const findAppSpaceDevice = params => http.get('/system/spacedevice/findAppSpaceDevice', params)

/**
 * 查询温湿度、空气、设备总数
 */
export const findAppParamInfo = params => http.get('/environmental/environmentalmobile/findAppParamInfo', params)

/**
 * 查询智能传感器
 */
export const findAppDeviceEnvironmental = params => http.get('/environmental/environmentalmobile/findAppDeviceInfo', params)
export const findAppDeviceEnvironmental2 = params => http.get('/environmental/environmentalmobile/findAppDeviceInfo2', params)

/**
 * 查询智能空开（智慧用电）
 */
export const findAppDeviceZnyd = params => http.get('/znyd/mobiledevice/findAppDeviceInfo', params)
export const findAppDeviceZnyd2 = params => http.get('/znyd/mobiledevice/findAppDeviceInfo2', params)

/**
 * 查询智能空调
 */
export const findAppDeviceZnkt = params => http.get('/znkt/deviceInfoMobile/findAppDeviceInfo', params)
export const findAppDeviceZnkt2 = params => http.get('/znkt/deviceInfoMobile/findAppDeviceInfo2', params)

/**
 * 查询智慧照明
 */
export const findAppDeviceRelay = params => http.get('/relay/relaymobile/findAppDeviceInfo', params)
export const findAppDeviceRelay2 = params => http.get('/relay/relaymobile/findAppDeviceInfo2', params)

/**
 * 查询智能窗帘
 */
export const findAppDeviceZncl = params => http.get('/zncl/deviceInfoMobile/findAppDeviceInfo', params)
export const findAppDeviceZncl2 = params => http.get('/zncl/deviceInfoMobile/findAppDeviceInfo2', params)

/**
 * 查询智能终端
 */
export const findAppDeviceZnzd = params => http.get('/znkzq/relaymobile/findAppDeviceInfo', params)
export const findAppDeviceZnzd2 = params => http.get('/znkzq/relaymobile/findAppDeviceInfo2', params)

/**
 * 查询监控
 */
export const findAppDeviceJk = params => http.get('/brand/dbmonitoringinfo/findAppDeviceInfo', params)
export const findAppDeviceJk2 = params => http.get('/system/dbmonitoringinfo/findAppDeviceInfo', params)
export const findAppDeviceJk3 = params => http.get('/system/dbmonitoringinfo/findAppMonitoringInfo', params)

/**
 * 查询智慧班牌
 */
export const findAppDeviceZhbp = params => http.get('/brand/brandMobile/findAppDeviceInfo', params)
export const findAppDeviceZhbp2 = params => http.get('/brand/brandMobile/findAppDeviceInfo2', params)

/**
 * 查询智慧门锁
 */
export const findAppDeviceZhms = params => http.get('/znmk/devices/findAppDeviceInfo', params)
export const findAppDeviceZhms2 = params => http.get('/znmk/devices/findAppDeviceInfo2', params)

/**
 * 查询综合管控智能用电信息
 */
export const findDtuSwitchDevice = params => http.get('/znyd/mobiledevice/findDtuSwitchDevice', params)

/**
 * 查询综合管控智慧照明信息
 */
export const findRelayDevice = params => http.get('/relay/relaymobile/findRelayDevice', params)

/**
 * 查询综合管控智能终端信息
 */
export const findControllerDevice = params => http.get('/znkzq/relaymobile/findRelayDevice', params)

//#endregion




//#region 审批中心

/**
 * 查询我的上报审批列表
 */
export const req_listMySbApproval = params => http.get('/safety/approval/listMySbApproval', params)

/**
 * 查询我的变更审批列表
 */
export const req_listMyBgApproval = params => http.get('/safety/approval/listMyBgApproval', params)

/**
 * 根据记录id查询上报审批详情
 */
export const req_findApprovalDetails = params => http.get('/safety/approval/findApprovalDetails', params)

/**
 * 根据记录id查询变更审批详情
 */
export const req_findApprovalBgDetails = params => http.get('/safety/approval/findApprovalBgDetails', params)

/**
 * 提交审批结果
 */
export const req_submitApprovalResult = params => http.post('/safety/approval/submitApprovalResult', params)




//#endregion

//#region 仪器设备

/**
 * 查询可预约仪器设备
 */
export const findSubEquipment = params => http.get('/devicesub/subscribeConfig/findSubEquipment', params)

/**
 * 查询仪器预约星期
 */
export const devicesubDate = params => http.get('/devicesub/websubscribe/findClientDate', params)

/**
 * 查询仪器预约信息
 */
export const devicesubRecord = params => http.get('/devicesub/websubscribe/findUserClientRecord', params)

/**
 * 查询设备详情
 */
export const selectOneEquipment = params => http.get('/system/equipmentInfo/selectOne', params)
/**
 * 查询设备详情
 */
export const findSubEquipmentById = params => http.get('/devicesub/subscribeinfo/findSubEquipmentById', params)

/**
 * 提交预约
 */
export const submitSubscribedevicesub = params => http.post('/devicesub/subscribereservationrecord/submitSubscribeRoom', params)

/**
 * 查询我的预约记录
 */
export const findMyDevicesubRecord = params => http.get('/devicesub/subscribereservationrecord/findMySubscribeRecord', params)

/**
 * 查询预约记录详情
 */
export const findDveSubByID = params => http.get('/devicesub/subscribereservationrecord/findSubByID', params)

//#endregion

//#region 智慧班牌

/**
 * 查询班牌信息
 */
export const findAllBrand = params => http.get('/brand/brandMobile/findAll', params)

/**
 * 查询班牌详情信息
 */
export const findCardInfoById = params => http.get('/brand/dlcardinfo/findCardInfoById', params)

/**
 * 远程执行班牌开关操作
 */
export const kgCardBrand = params => http.get('/brand/dlcardinfo/kgCard', params)

/**
 * 查询定时配置信息
 */
export const findScheduledTaskBanpai = params => http.get('/brand/brandMobile/findScheduledTask', params)

/**
 * 新增定时器配置
 */
export const addScheduledBanpai = params => http.jsonPost('/brand/brandMobile/addScheduled', params)

/**
 * 编辑定时器配置
 */
export const updateBanpai = params => http.jsonPost('/brand/brandMobile/update', params)

/**
 * 删除定时器配置
 */
export const deleteScheduledBanpai = params => http.get('/brand/brandMobile/deleteScheduled', params)

//#endregion


/**
 * 智能控制器查询房间列表
 */
export const req_listRelayRoom = params => http.get('/znkzq/relaymobile/listRelayRoom', params)

/**
 * 查询房间下的所有线路(设备)
 */
export const req_listDeviceByRoomId = params => http.get('/znkzq/relaymobile/listDeviceByRoomId', params)
/**
 * 查询房间下的所有线路(设备数量)
 */
export const req_getRelayRoomById = params => http.get('/znkzq/relaymobile/getRelayRoomById', params)

//#region 使用管理

/**
 * 查询教室课程实验记录信息
 */
export const listAppUserTimeTable = params => http.get('/usagerecord/labrecord/listAppUserTimeTable', params)

/**
 * 上报实验记录
 */
export const submitAppLabRecord = params => http.post('/usagerecord/labrecord/submitAppLabRecord', params)

/**
 * 查询实验室使用记录
 */
export const statisticsRoomData = params => http.get('/usagerecord/statisticsapplabrecord/statisticsRoomData', params)

/**
 * 统计当天/当周实验记录填写次数
*/
export const statisticsLabWeek = params => http.get('/usagerecord/statisticsapplabrecord/statisticsLabWeek', params)
/**
 * 统计本学期实验室排课数
*/
export const statisticsAllLabCourse = params => http.get('/usagerecord/statisticsapplabrecord/statisticsAllLabCourse', params)
/**
 * 统计每间教室上课数和占比
*/
export const statisticsLabCourse = params => http.get('/usagerecord/statisticsapplabrecord/statisticsLabCourse', params)
/**
 * 统计教室使用率前十名
*/
export const statisticsLabCourseTop10 = params => http.get('/usagerecord/statisticsapplabrecord/statisticsLabCourseTop10', params)
/**
 *统计
*/
export const req_statistNum = params => http.get('/usagerecord/statisticsapplabrecord/statistNum', params)

//#endregion


/**
 * 查询所有房间
 */
export const findAllSpaceInfo = params => http.get('/system/departmentinfo/findAllDepTreeInfo', params)

/**
 * 随手报查询所有房间
 */
export const ssbPollingBuilding = params => http.get('/polling/dlpollingconfig/ssbPollingBuilding', params)

/**
 * 查询练习题
 */
export const exam_loadOnlineMokExercise = params => http.get('/exam/examweb/loadOnlineMokExercise', params)

/**
 * 查询考试题
 */
export const startExam = params => http.get('/exam/examweb/startExam', params)

/**
 * 提交考试
 */
export const saveAnswerRecord = params => http.post('/exam/answerrecord/saveAnswerRecord', params)

/**
 * 查询试卷信息
 */
export const findPaperConfigId = params => http.get('/exam/paperconfig/findPaperConfigId', params)

/**
 * 查询我的考试记录详情
 */
export const findMyAnswerRecord = params => http.get('/exam/answerrecord/findMyAnswerRecord', params)

/**
 * 查询试卷对应的成绩信息
 */
export const findMyGradeInfo = params => http.get('/exam/examweb/findMyGradeInfo', params)

/**
 * 用户端提交练习记录
 */
export const savePractiveRecord = params => http.jsonPost('/exam/practiverecord/savePractiveRecord', params)

/**
 * 统计考试信息
 */
export const statisticsExam = params => http.get('/exam/exammobile/statisticsExam', params)

/**
 * 统计练习信息
 */
export const statisticsPractiveRecord = params => http.get('/exam/exammobile/statisticsPractiveRecord', params)

/**
 * 统计学习信息
 */
export const statisticsLearnRecord = params => http.get('/exam/exammobile/statisticsLearnRecord', params)


//#region 实验报告

/**
 * 查询最近的学年学期信息
 */
export const findReportSemesterTime = params => http.get('/report/reportremote/findReportSemesterTime', params)

/**
 * 教师端查询实验报告模板
 */
export const findTeacherTemplateInfo = params => http.get('/report/templateinfo/findTeacherTemplateInfo', params)

/**
 * 查询学年学期对应的课程(实验报告)
 */
export const findUserCourseInfo = params => http.get('/report/reportremote/findUserCourseInfo', params)

/**
 * 查询学年学期对应的课程(实验资源)
 */
export const findResourcesCourseInfo = params => http.get('/report/resourcesrecord/findResourcesCourseInfo', params)

/**
 * 教师上传模板
 */
export const uploadTeacherTemplateInfo = params => http.jsonPost('/report/templateinfo/uploadTeacherTemplateInfo', params)

/**
 * 教师删除报告模板
 */
export const deleteTemplateInfo = params => http.get('/report/templateinfo/deleteTemplateInfo', params)

/**
 * 教师查询报告记录
 */
export const findUserTeacherRecord = params => http.get('/report/reportrecord/findUserTeacherRecord', params)

/**
 * 学生查询实验报告模板
 */
export const findStudentTemplateInfo = params => http.get('/report/templateinfo/findStudentTemplateInfo', params)

/**
 * 学生查询实验报告记录
 */
export const findUserStudentRecord = params => http.get('/report/reportrecord/findUserStudentRecord', params)

/**
 * 学生删除实验报告记录
 */
export const deleteStudentReord = params => http.get('/report/reportrecord/deleteStudentReord', params)

/**
 * 学生上传实验报告记录
 */
export const saveStudentReord = params => http.post('/report/reportrecord/saveStudentReord', params)

/**
 * 学生修改实验报告记录
 */
export const updateStudentReord = params => http.post('/report/reportrecord/updateStudentReord', params)

/**
 * 查询模板详情
 */
export const findTemplateId = params => http.get('/report/templateinfo/findTemplateId', params)

/**
 * 查询学生未上报实验记录的课程
 */
export const findStudentCourseInfo = params => http.get('/report/reportremote/findStudentCourseInfo', params)

/**
 * 判断学生是否提交过实验报告
 */
export const findStudentReordId = params => http.get('/report/reportrecord/findStudentReordId', params)

/**
 * 查询学生上传实验报告详情信息
 */
export const findRecordId = params => http.get('/report/templateinfo/findRecordId', params)

//#endregion

//#region 实验资源

/**
 * 教师查询实验资源
 */
export const findTeacherResourcesInfo = params => http.get('/report/resourcesrecord/findTeacherResourcesInfo', params)

/**
 * 教师删除课程下的所有资源
 */
export const deleteAllResourcesInfo = params => http.get('/report/resourcesrecord/deleteAllResourcesInfo', params)

/**
 * 查询资源课程详情信息
 */
export const getResourceInfo = params => http.get('/report/resourcesrecord/getResourceInfo', params)

/**
 * 查询实验资源详情信息
 */
export const findResourcesInfo = params => http.get('/report/resourcesinfo/findResourcesInfo', params)

/**
 * 教师新增上传实验资源
 */
export const saveResourcesInfo = params => http.jsonPost('/report/resourcesrecord/saveResourcesInfo', params)

/**
 * 教师编辑上传资源文件
 */
export const uploadResourcesFile = params => http.upload('/report/resourcesrecord/uploadResourcesFile', params)

/**
 * 教师编辑上传实验资源
 */
export const updateResourcesInfo = params => http.jsonPost('/report/resourcesrecord/updateResourcesInfo', params)

/**
 * 教师多选删除实验资源
 */
export const deleteResourcesInfo = params => http.get('/report/resourcesrecord/deleteResourcesInfo', params)

/**
 * 学生查询实验资源
 */
export const findStudentResourcesInfo = params => http.get('/report/resourcesrecord/findStudentResourcesInfo', params)

/**
 * 下载压缩包文件
 */
export const downloadFilesToZip = params => http.jsonPost('/file/sysfile/downloadFilesToZip', params)

/**
 * 根据模板id获取班级和助教信息
 */
export const getBanZj = params => http.get('/report/templateinfo/findZjInfoById', params)
/**
 * 根据模板id保存教学班和助教
 */
export const saveBanZj = params => http.post('/report/templateinfo/saveTemplateZjInfo', params)

/**
 * 用户根据学年学期查询对应的课程序号
 */
export const findUserCourseNumber = params => http.get('/report/reportremote/findUserCourseNumber', params)

/**
 * 获取上课节次
 */
export const req_findJcInfo = params => http.get('/report/reportremote/findJcInfo', params)

/**
 * 下载pdf
 */
export const req_findReportFileInfo = params => http.get('/report/reportrecord/findReportFileInfo', params)

/**
 * 根据模板id查询提交报告详情
 */
export const req_findTemplateRecordId = params => http.get('/report/templateinfo/findTemplateRecordId', params)

/**
 * 按实验课程统计实验报告班级信息
 */
export const statisticsProjectBj = params => http.get('/report/repoortstatistics/statisticsProjectBj', params)

/**
 * 按实验课程统计实验报告信息
 */
export const statisticsReport = params => http.get('/report/repoortstatistics/statisticsReport', params)

//#endregion


/**
 * 巡检次数
 */
export const req_findByTaskId = params => http.get('/polling/dlpollingtaskrecord/findByTaskId', params)


/**
 * listPollingType
 */
export const req_listPollingType = params => http.get('/polling/dlpollingtype/listPollingType', params)

/**
 * 提交巡检结果
 */
export const req_submitCheckResult = params => http.jsonPost('/polling/apppollingtask/submitCheckResult', params)

/**
 * 提交随手报信息
 */
export const req_submitSsb = params => http.jsonPost('/polling/apppollingtask/submitSsb', params)

/**
 * 提交自查信息
 */
export const req_submitZc = params => http.jsonPost('/polling/apppollingtask/submitZc', params)

/**
 * 通过主键查询单条数据
 */
export const req_selectOne = params => http.get('/system/laboratoryInfo/selectOne', params)

/**
 * 实验室负责人核实隐患信息
 */
export const req_verifyHiddenDangers = params => http.get('/polling/apppollingtask/verifyHiddenDangers', params)

/**
 * 隐患结果整改验收
 */
export const req_checkHiddenDangers = params => http.get('/polling/apppollingtask/checkHiddenDangers', params)



// 根据年度统计校级巡检、院级巡检、日常巡检各自的计划总数
export const req_countTask = params => {
  return http.get('/polling/statistics/countTask', params)
}

// 根据年度统计校级巡检、院级巡检、日常巡检各自的隐患总数
export const req_countHidden = params => {
  return http.get('/polling/statistics/countHidden', params)
}

// 根据年度统计各个院系的隐患总数
export const req_countHiddenByDept = params => {
  return http.get('/polling/statistics/countHiddenByDept', params)
}

// 根据年度统计全校隐患总数、已整改总数、待整改的总数的比较
export const req_countHiddenByState = params => {
  return http.get('/polling/statistics/countHiddenByState', params)
}

// 根据年度统计各个院系的待整改的占隐患总数的比例；
export const req_countDzgHidden = params => {
  return http.get('/polling/statistics/countDzgHidden', params)
}

// 根据年度统计隐患实验室总数排行榜，前五位；
export const req_countHiddenRoom = params => {
  return http.get('/polling/statistics/countHiddenRoom', params)
}

// 根据年度统计隐患总数前五的检查项排名；
export const req_countHiddenType = params => {
  return http.get('/polling/statistics/countHiddenType', params)
}

// 查询待验收的隐患列表
export const req_findCheckHiddenList = params => {
  return http.get('/polling/apppollingtask/findCheckHiddenList', params)
}

// 判断是否可以根据二维码进入自查页面
export const req_isZcAuth = params => {
  return http.get('/polling/apppollingtask/isZcAuth', params)
}
//#region 工作台

/**
 * 统计实验室基础数据
 */
export const findLabNumByInfo = params => http.get('/system/appworkbench/findLabNumByInfo', params)

/**
 * 查询各类型实验室个数
 */
export const findLabNumByType = params => http.get('/system/appworkbench/findLabNumByType', params)

/**
 * 查询各院系实验室分布情况
 */
export const findLabNumByDistribution = params => http.get('/system/appworkbench/findLabNumByDistribution', params)

/**
 * 查询各类型物联网设备个数
 */
export const findNetDeviceNumByType = params => http.get('/system/appworkbench/findNetDeviceNumByType', params)

//#endregion
// 8.查询校级 院级 日常 巡检任务完成率
export const req_countVerify = params => {
  return http.get('/polling/statistics/countVerify', params)
}

// 9.查询每个学院校级 院级 日常 巡检任务完成率及整改完成率
export const req_countVerifyByDept = params => {
  return http.get('/polling/statistics/countVerifyByDept', params)
}

// 部门
export const req_findAllyuanxi = params => {
  return http.get('/polling/dlpollingtaskdetails/getTaskDept', params)
}

// 查询当前试卷配置
export const req_findMyPaperUndertakRecord = params => {
  return http.post('/exam/undertakrecord/findMyPaperUndertakRecord ', params)
}

// 新增承诺书记录信息
export const req_addUndertakRecord = params => {
  return http.post('/exam/undertakrecord/addUndertakRecord ', params)
}

/**
 * 查询上报未审批数量
 */
export const countMySbApproval = params => http.get('/safety/approval/countMySbApproval', params)

/**
 * 查询变更未审批数量
 */
export const countMyBgApproval = params => http.get('/safety/approval/countMyBgApproval', params)

/**
 * 查询实验室预约未审批数量
 */
export const countMyApproval = params => http.get('/subscribe/approval/countMyApproval', params)

/**
 * 查询设备预约未审批数量
 */
export const countMyApprovalDevice = params => http.get('/devicesub/approval/countMyApproval', params)

/**
 * 查询报修未审批数量
 */
export const countAppApprovalOrderRecord = params => http.get('/equipmentrepair/approvalrecord/countAppApprovalOrderRecord', params)

/**
 * 提交简报隐含（不带隐患点）
 */
export const req_submitJb = params => http.jsonPost('/polling/apppollingtask/submitJb', params)

/**
 * 查询实验室基本信息
 */
export const findQrCodeInfoBySafety = params => http.get('/system/laboratoryInfo/findQrCodeInfoBySafety', params)

/**
 * 查询院系下实验室信息
 */
export const countByYx = params => http.get('/system/appworkbench/countByYx', params)

/**
 * 查询安全巡检数
 */
export const countDetailsRecord = params => http.get('/polling/statistics/countDetailsRecord', params)

/**
 * 查询学院下的隐患及待整改信息
 */
export const countDzgAndZsHidden = params => http.get('/polling/statistics/countDzgAndZsHidden', params)

/**
 * 查询设备预约待审批数量
 */
export const countDspDevice = params => http.get('/devicesub/tj/countDsp', params)

/**
 * 查询设备总数/已开放设备数
 */
export const countEquipment = params => http.get('/devicesub/tj/countEquipment', params)

/**
 * 根据部门查询设备预约开放情况
 */
export const findEquipmentByDept = params => http.get('/devicesub/tj/findEquipmentByDept', params)

/**
 * 查询危化品申购、领用待审批数量
 */
export const dbspWhp = params => http.get('/whp/tj/dbsp', params)

/**
 * 查询实验室预约待审批
 */
export const countDspSys = params => http.get('/subscribe/tj/countDsp', params)

/**
 * 查询部门下实验室安全等级对应情况
 */
export const countByDeptSafety = params => http.get('/safety/tj/countByDept', params)

/**
 * 统计开放实验室数
 */
export const countKfSys = params => http.get('/subscribe/tj/countKfSys', params)

/**
 * 根据部门查询实验室预约开放情况
 */
export const countXueYuan = params => http.get('/subscribe/tj/countXueYuan', params)

/**
 * 查询门锁报警记录
 */
export const findAbnormalRecordLock = params => http.get('/znmk/dlwarnrecord/findAbnormalRecord', params)

/**
 * 查询班牌报警记录
 */
export const findAbnormalRecordBanPai = params => http.get('/brand/dlrecordupinfo/findAbnormalRecord', params)

/**
 * 查询实验室准入统计（安全教育）
 */
export const examCount = params => http.get('/exam/tj/count', params)

/**
 * 查询设备承诺书信息
 */
export const findCnsByEquipmentId = params => http.get('/devicesub/subscribeinfo/findCnsByEquipmentId', params)

//#region 人脸采集

/**
 * 查询教师信息
 */
export const req_findTeacherInfo = params => http.get('/mobile/mobilelogin/findTeacherInfo', params)

/**
 * 查询学生信息
 */
export const req_findStudentInfo = params => http.get('/mobile/mobilelogin/findStudentInfo', params)


/**
 * 查询人脸采集协议
 */
export const findRlcj = params => http.get('/mobile/agreementinfo/findRlcj', params)

/**
 * 上传照片
 */
export const uploadRlcj = params => http.post('/system/file/uploadRlcj', params)

//#endregion