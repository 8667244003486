import React, { Component } from 'react'
import { Select } from 'antd'
import { DatePicker, List, Toast } from 'antd-mobile'
import { req_findAlarmRecord, req_findUpdateState } from '../../../api/api'
import moment from 'moment'
import './alarm_data.less'

const { Option } = Select

class alarmData extends Component {

  state = {
    start_date: '',
    end_date: '',
    airSwitchImei: '',
    alarmState: '',
    data: [],
    checkedData: [],
  }

  componentDidMount = () => {
    Toast.loading('', 0)
    document.title = "智慧用电-告警数据"
    this.setState({
      start_date: new Date(this.fun_date(-2)),
      end_date: new Date(),
      airSwitchImei: this.props.match.params.id,
    }, () => {
      this.fetch()
    })
  }

  fetch = async () => {
    const { airSwitchImei, start_date, end_date, alarmState } = this.state
    const res = await req_findAlarmRecord({ airSwitchImei, startTime: start_date ? moment(start_date).format('YYYY-MM-DD') : '', endTime: end_date ? moment(end_date).format('YYYY-MM-DD') : '', alarmState })
    if (res.code === 20000) {
      Toast.hide()
      this.setState({
        data: res.data
      })
    }
  }

  handleLookOver = async (recordId) => {
    const res = await req_findUpdateState({ recordId });
    if (res.code === 20000) {
      Toast.success(res.message)
      setTimeout(() => {
        this.fetch();
      }, 1000);

    }
  }

  // 获取几天前的日期
  fun_date = (num) => {
    var date1 = new Date();
    var date2 = new Date(date1);
    date2.setDate(date1.getDate() + num);
    //num是正数表示之后的时间，num负数表示之前的时间，0表示今天
    var time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();
    return time2;
  }

  render() {
    const { start_date, end_date, data, checkedData } = this.state
    return (
      <div className='alarm_data'>
        <div className='alarm_data_head'>
          <div className='alarm_data_head_date'>
            <DatePicker
              mode="date"
              title="开始时间"
              extra="开始时间"
              value={start_date}
              maxDate={end_date ? end_date : null}
              onChange={date => {
                this.setState({
                  start_date: date,
                  checkedData: []
                }, () => {
                  this.fetch()
                })
              }}
            >
              <List.Item arrow="horizontal"></List.Item>
            </DatePicker>
            <div style={{ background: '#f4f6f8' }}>~</div>
            <DatePicker
              mode="date"
              title="结束时间"
              extra="结束时间"
              value={end_date}
              minDate={start_date ? start_date : null}
              onChange={date => {
                this.setState({
                  end_date: date,
                  checkedData: []
                }, () => {
                  this.fetch()
                })
              }}
            >
              <List.Item arrow="horizontal"></List.Item>
            </DatePicker>
          </div>
          <div className='alarm_data_head_state'>
            <Select allowClear placeholder='查看状态' onChange={e => {
              this.setState({
                alarmState: e,
                checkedData: [],
              }, () => {
                this.fetch()
              })
            }}>
              <Option value={1}>已看</Option>
              <Option value={0}>未看</Option>
            </Select>
          </div>
        </div>
        <div className='alarm_data_body'>
          <div className='alarm_data_list'>
            {
              (data || []).length ? data.map(item => {
                return (
                  <div className='alarm_data_item' key={item.record_id}>
                    <div className='alarm_data_item_head'>
                      {
                        item.alarm_state === 1 ? <div className='alarm_data_status_online'>已看</div> : item.alarm_state === 0 ? <div className='alarm_data_status_offline'>未看</div> : ''
                      }
                      <div className='alarm_data_item_head_title'>{item.alarm_reason_name}</div>
                      <div className='alarm_data_but' onClick={() => {
                        if (item.alarm_state === 0) {
                          let arrData = []
                          checkedData.push(item.record_id)
                          this.handleLookOver(item.record_id);
                          this.setState({
                            checkedData: arrData
                          })
                        } else {
                          let arrData = []
                          if (checkedData.filter(x => x === item.record_id).length === 1) {
                            arrData = checkedData.filter(x => x !== item.record_id)
                          } else {
                            checkedData.push(item.record_id)
                            arrData = checkedData
                          }
                          this.setState({
                            checkedData: arrData
                          })

                        }
                      }}>{item.alarm_state === 1 ? '详情' : '查看'}</div>
                    </div>

                    {checkedData.filter(x => x === item.record_id).length === 1 ? (<div className='alarm_data_item_main'>
                      <div className='alarm_data_item_main_top'>
                        <div className='alarm_data_item_main_bar'>
                          <div>A相报警时电流：</div>
                          <div>{item.a_curr_alarm}A</div>
                        </div>
                        <div className='alarm_data_item_main_bar'>
                          <div>B相报警时电流：</div>
                          <div>{item.b_curr_alarm}A</div>
                        </div>
                      </div>
                      <div className='alarm_data_item_main_bar'>
                        <div>告警时间：</div>
                        <div>{moment(item.alarm_time).format('YYYY-MM-DD HH:mm:ss')}</div>
                      </div>
                      {/* <div className='alarm_data_item_main_bar'>
                      <div>查&nbsp;&nbsp;看&nbsp;&nbsp;人：</div>
                      <div>黄小一</div>
                    </div>
                    <div className='alarm_data_item_main_bar'>
                      <div>查看时间：</div>
                      <div>2023-02-12 12:22:21</div>
                    </div> */}
                    </div>) : ('')}
                  </div>
                )
              }) : <div className='not-yet'>--暂无数据--</div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default alarmData
