import React, { Component } from 'react'
import { req_findMyPaperUndertakRecord, req_addUndertakRecord } from './../../../api/api'
import { Toast, Modal } from 'antd-mobile';
import { Checkbox } from "antd";
import './exam.less'

export default class exam extends Component {
  state = {
    userInfo: '',
    btnStatus: false,
    paperList: [],
    curIdx: 0,
    paperData: {},
    luVisible: false,
    checked: false,
    width: window.innerWidth,
    height: window.innerHeight,
    visible: false,
    base64: '',
    undertakData: {}
  }


  componentDidMount = () => {
    document.title = "安全考试"
    this._findMyPaperUndertakRecord()
  }

  _findMyPaperUndertakRecord = async () => {
    const res = await req_findMyPaperUndertakRecord({ paperId: this.props.match.params.id })
    if (res.code === 20000) {
      this.setState({
        undertakData: res.data ? res.data : {}
      }, () => {
        this.setState({
          luVisible: true
        })
      })
    }
  }

  // 点击签字
  goSign = () => {
    this.setState({
      visible: true
    })
    setTimeout(() => {
      eval("PageReady()")
    }, 500)
  }

  // 清除签字
  handleClearBtnClick = () => {
    eval('clearCanvas()')
  }

  // 签字确认
  handleOk = () => {
    const data = eval('saveSignature()')
    this.setState({
      visible: false
    })
    this.rotateBase64Img(data, 270)
  }

  //将canvas图片转为base64图片
  rotateBase64Img = (src, edg, callback) => {
    const This = this
    var canvas = document.createElement("canvas")
    var ctx = canvas.getContext("2d")
    var imgW//图片宽度
    var imgH//图片高度
    var size//canvas初始大小
    if (edg % 90 != 0) {
      throw '旋转角度必须是90的倍数!'
    }
    (edg < 0) && (edg = (edg % 360) + 360)
    const quadrant = (edg / 90) % 4 //旋转象限
    const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 } //裁剪坐标
    var image = new Image()
    image.crossOrigin = "anonymous"
    image.src = src
    image.onload = function () {
      imgW = image.width
      imgH = image.height
      size = imgW > imgH ? imgW : imgH
      canvas.width = size * 2
      canvas.height = size * 2
      switch (quadrant) {
        case 0:
          cutCoor.sx = size
          cutCoor.sy = size
          cutCoor.ex = size + imgW
          cutCoor.ey = size + imgH
          break
        case 1:
          cutCoor.sx = size - imgH
          cutCoor.sy = size
          cutCoor.ex = size
          cutCoor.ey = size + imgW
          break
        case 2:
          cutCoor.sx = size - imgW
          cutCoor.sy = size - imgH
          cutCoor.ex = size
          cutCoor.ey = size
          break
        case 3:
          cutCoor.sx = size
          cutCoor.sy = size - imgW
          cutCoor.ex = size + imgH
          cutCoor.ey = size + imgW
          break
      }
      ctx.translate(size, size)
      ctx.rotate(edg * Math.PI / 180)
      ctx.drawImage(image, 0, 0)
      var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey)
      if (quadrant % 2 == 0) {
        canvas.width = imgW
        canvas.height = imgH
      } else {
        canvas.width = imgH
        canvas.height = imgW
      }
      ctx.putImageData(imgData, 0, 0)

      //获取旋转后的base64图片
      This.setState({
        base64: canvas.toDataURL(),
      })
    }
  }

  //重签
  reSign = () => {
    this.setState({
      base64: '',
      visible: true,
    })
    setTimeout(() => {
      eval("PageReady()")
    }, 500)
  }

  submit = async () => {
    if (!this.state.checked) {
      return Toast.info("请先勾选同意承诺书！")
    }
    const { undertakData, base64 } = this.state
    const res = await req_addUndertakRecord({
      paperId: this.props.match.params.id,
      undertakId: undertakData.undertak_id,
      signUrl: base64
    })
    if (res.code === 20000) {
      Toast.info(res.message)
      setTimeout(() => {
        window.history.go(-1)
      }, 2000);
    }
  }

  render() {
    const { visible, width, height, luVisible, undertakData } = this.state
    return (
      <div className='saftyExam'>
        {/* 承诺书 */}

        <Modal
          popup
          visible={luVisible}
          onClose={() => {
            this.setState({
              luVisible: false
            })
          }}
          animationType="slide-up"
        >
          <div className='exam_lu'>
            <div className='exam_lu_title'>
              请仔细阅读《{undertakData.undertak_name}》并签字
            </div>
            <div className='exam_lu_desc' dangerouslySetInnerHTML={{ __html: undertakData.undertak_desc }}>
            </div>

            <div className='exam_lu_checkbox'>
              <Checkbox onChange={(e) => {
                this.setState({
                  checked: e.target.checked
                })
              }}>我已阅读并同意以下承诺书</Checkbox>

            </div>
            {this.state.base64 ? <div>
              <img src={this.state.base64} alt="" height='100px' />
            </div> : ''}

            {this.state.base64 ? <div className='exam_lu_sub_bg' onClick={() => {
              this.submit()
            }}>
              提交签字
            </div> : <div className='exam_lu_sub' onClick={() => {
              if (!this.state.checked) {
                return Toast.info("请先勾选同意承诺书！")
              }
              this.goSign()
            }}>
              签字确认
            </div>}

          </div>
        </Modal>

        <Modal
          visible={visible}
          transparent
          onClose={() => this.setState({ visible: false })}
          style={{ width: '100%', height: '100%', background: '#fff' }}
        >
          <div>
            <section className="signature-component" >
              <div id='signature-pad' className="js-signature" data-width={width * 0.7} data-height={height * 0.8} data-border="1px solid black" data-line-color="#000" data-auto-fit="true"></div>
            </section>
            <div className='sign_btn' style={{ height: `${height * 0.2 - 60}px` }}>
              <div className='approveDetail_btn_list' style={{ width: `${height * 0.2 - 60}px` }} onClick={() => this.handleClearBtnClick()}>清除</div>
              <div className='approveDetail_btn_list' style={{ width: `${height * 0.2 - 60}px` }} onClick={() => this.handleOk()}>确认</div>
            </div>
          </div>
        </Modal>

      </div>
    )
  }
}
