import React, { Component } from 'react'
import { Upload, Select, Icon, Button } from 'antd'
import { List, Picker, DatePicker, Toast, Modal, Grid } from 'antd-mobile'
import { findReportSemesterTime, findTeacherTemplateInfo, findUserCourseInfo, deleteTemplateInfo, findUserTeacherRecord, findUserCourseNumber } from '../../../api/api'
import moment from 'moment'
import copy from 'copy-to-clipboard'
import IP from '../../../config/config'
import cookie from 'react-cookies'
import del from '../../../images/del2.png'
import fx from '../../../images/fx.png'
import './index.less'

class TestTer extends Component {

  state = {
    tabs: [
      { id: 1, name: '实验报告模板', checked: true },
      { id: 2, name: '实验报告记录', checked: false }
    ],
    tabsId: 1,
    courseData: [],
    courseId: [],
    classData: [],
    kcxhId: [],
    xqData: [
      { value: '1', label: '秋季学期' },
      { value: '2', label: '春季学期' },
      { value: '3', label: '夏季学期' },
    ],
    xqId: [],
    xn: '',
    data: [],
    wt: 279,
    templateInfo: {}, // 选中的模板
    assistantsf: false, // 是否是助教
    visible: false,
    item: {}, // 选中的列表
    weekData: [
      {
        label: '周一',
        value: 1,
      },
      {
        label: '周二',
        value: 2,
      },
      {
        label: '周三',
        value: 3,
      },
      {
        label: '周四',
        value: 4,
      },
      {
        label: '周五',
        value: 5,
      },
      {
        label: '周六',
        value: 6,
      },
      {
        label: '周日',
        value: 7,
      },
    ],
  }

  componentDidMount = () => {
    this.fetchXnXq()
    this.setState({
      wt: document.body.clientWidth - 96,
      assistantsf: cookie.load('userData').userGroupIds ? cookie.load('userData').userGroupIds.split(',').find(x => x === '5') : false
    })
  }

  // 查询最近的学年学期
  fetchXnXq = async () => {
    const res = await findReportSemesterTime()
    if (res.code === 20000) {
      this.fetchCourse(res.data.xn, res.data.xq)
      this.fetchTemplate(res.data.xn, res.data.xq)
      this.setState({
        xqId: [res.data.xq],
        xn: res.data.xn
      })
    }
  }

  // 查询课程
  fetchCourse = async (xn, xq) => {
    const res = await findUserCourseInfo({ xn, xq })
    if (res.code === 20000) {
      this.setState({
        courseData: this.deduplication(res.data || []).map(x => {
          return {
            value: x.template_kcmc,
            label: x.template_kcmc,
            template_id: x.template_id,
          }
        })
      })
    }
  }

  // 查询课程序号信息
  fetchCourseNumber = async name => {
    const { courseData } = this.state
    const res = await findUserCourseNumber({ templateId: courseData.find(x => x.value === name[0]).template_id })
    if (res.code === 20000) {
      this.setState({
        classData: (res.data || []).map(x => {
          return {
            label: x,
            value: x
          }
        })
      })
    }
  }

  // 查询实验报告模板
  fetchTemplate = async (xn, xq) => {
    const { courseId } = this.state
    const res = await findTeacherTemplateInfo({ flag: 2, xn, xq, kcdm: courseId[0] })
    if (res.code === 20000) {
      const data = res.data.map(item=>{
				item.sksjList = item.sksjList?item.sksjList.split(',').map(val=>{
					return '周'+val.substring(0, 1)+' 第'+val.substring(2)+'节'
				}).join(','):'-'
				return item
			})
      this.setState({
        data: data
      })
    }
  }

  // 查询实验报告记录
  fetchRecord = async (xn, xq) => {
    const { courseId, kcxhId } = this.state
    const res = await findUserTeacherRecord({ flag: 2, xn, xq, kcdm: courseId[0], kcxh: kcxhId[0] })
    if (res.code === 20000) {
      this.setState({
        data: res.data,
      })
    }
  }

  // 切换类型
  handleChangeTabs = item => {
    const { tabs, xn, xqId } = this.state
    this.setState({
      tabs: tabs.map(x => {
        x.checked = x.id === item.id ? true : false
        return x
      }),
      tabsId: item.id,
      courseId: []
    }, () => {
      if (item.id === 1) {
        this.fetchTemplate(xn, xqId[0])
      }
      if (item.id === 2) {
        this.fetchRecord(xn, xqId[0])
      }
    })
  }

  // 删除模板
  handleDel = async item => {
    const { xn, xqId } = this.state
    const res = await deleteTemplateInfo({ templateId: item.template_id })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.fetchTemplate(xn, xqId[0])
      this.setState({
        visible: false,
        item: {}
      })
    }
  }

  // 复制
  handleCopy = item => {
    copy(`http://labmanage.sastc.cn/report.html?templateId=${item.template_id}`)
    Toast.info('复制成功！')
    this.setState({
      visible: false,
      item: {}
    })
  }

  // 编辑模板
  handleEdit = item => {
    this.props.this.props.history.push(`/module/testMb/${item.template_id}`)
  }

  // 导出上传实验记录的学生信息
  handleExport = item => {
    window.location.href = `${IP.host}/report/templateinfo/excelExport?templateId=${item.template_id}&zh=${cookie.load('userData').id}`
  }

  // 下载记录
  handleDownRecord = item => {
    window.location.href = IP.fileHost + item.template_url
  }

  // 数组去重
  deduplication(arr) {
    let list = []
    return arr.filter((item) => !list.includes(item.template_kcmc) && list.push(item.template_kcmc))
  }

  // 点击
  handleGrid = el => {
    const { item } = this.state
    if (el.id === 1) {
      this.handleCopy(item)
    }
    if (el.id === 2) {
      this.handleDel(item)
    }
  }

  render() {
    const { tabs, tabsId, courseData, courseId, xqData, xqId, xn, data, wt, classData, kcxhId, assistantsf, visible, weekData } = this.state
    const GridData = [
      { id: 1, icon: fx, text: '分享' },
      { id: 2, icon: del, text: '删除' },
    ]
    return (
      <div className='testReport'>
        <div className='testReport_header'>
          <div className='testReport_header_title'>实验室电子报告上传系统</div>
          <div className='testReport_header_desc'>任课教师可在此查看自己所带课程下学生的实验报告</div>
          <div className='testReport_header_btn'>
            {
              (tabs || []).map(item => {
                return <div key={item.id} className={`testReport_header_btn_list ${item.checked ? 'testReport_header_btn_list_active' : ''}`} onClick={() => this.handleChangeTabs(item)}>{item.name}</div>
              })
            }
          </div>
        </div>
        <div className='testReport_tj_cx'>
          <div className='testReport_tj_cx_con'>
            <div className='testReport_search'>
              <div className='testReport_search_list'>
                <DatePicker
                  mode="year"
                  extra={xn ? xn + '年' : '学年'}
                  onChange={date => {
                    this.fetchCourse(moment(date).format('YYYY'), xqId[0])
                    this.setState({ xn: moment(date).format('YYYY'), courseId: [], kcxhId: [] })
                    if (tabsId === 1) {
                      this.fetchTemplate(moment(date).format('YYYY'), xqId[0])
                    }
                    if (tabsId === 2) {
                      this.fetchRecord(moment(date).format('YYYY'), xqId[0])
                    }
                  }}
                >
                  <List.Item arrow="horizontal"></List.Item>
                </DatePicker>
              </div>
              <div className='testReport_search_list'>
                <Picker
                  extra="学期"
                  data={xqData}
                  cols={1}
                  value={xqId}
                  onChange={e => {
                    this.fetchCourse(xn, e[0])
                    this.setState({ xqId: e, courseId: [], kcxhId: [] })
                    if (tabsId === 1) {
                      this.fetchTemplate(xn, e[0])
                    }
                    if (tabsId === 2) {
                      this.fetchRecord(xn, e[0])
                    }
                  }}
                >
                  <List.Item arrow="horizontal"></List.Item>
                </Picker>
              </div>
              <div className='testReport_search_list'>
                <Picker
                  extra="课程"
                  data={courseData}
                  cols={1}
                  value={courseId}
                  onChange={e => {
                    this.fetchCourseNumber(e)
                    this.setState({ courseId: e, kcxhId: [] }, () => {
                      if (tabsId === 1) {
                        this.fetchTemplate(xn, xqId[0])
                      }
                      if (tabsId === 2) {
                        this.fetchRecord(xn, xqId[0])
                      }
                    })
                  }}
                >
                  <List.Item arrow="horizontal"></List.Item>
                </Picker>
              </div>
              {
                tabsId === 2 ? <div className='testReport_search_list'>
                  <Picker
                    extra="教学班代码"
                    data={classData}
                    cols={1}
                    value={kcxhId}
                    onChange={e => {
                      this.setState({ kcxhId: e }, () => {
                        if (tabsId === 2) {
                          this.fetchRecord(xn, xqId[0])
                        }
                      })
                    }}
                  >
                    <List.Item arrow="horizontal"></List.Item>
                  </Picker>
                </div> : ''
              }
            </div>
          </div>
        </div>
        {
          tabsId === 1 ? <div className='testReport_con' style={{ paddingBottom: `${assistantsf ? '12px' : '87px'}` }}>
            {
              (data || []).length ? data.map((item, index) => {
                return <div className='testReport_con_list' key={index + 1}>
                  <div className='testReport_con_list_header'>
                    <img alt='' src={require('../../../images/muban.png')} />
                    <div className='testReport_con_list_header_con' style={{ width: `${wt}px` }}>
                      <div>
                        <div className='testReport_con_list_header_course header_omit'>{item.template_kcmc}</div>
                        <div className='testReport_con_list_header_nq'>{item.template_xn} {item.template_xq === '1' ? '秋季' : item.template_xq === '2' ? '春季' : '夏季'}学期</div>
                      </div>
                      <Icon type="more" onClick={() => this.setState({ visible: true, item })} />
                    </div>
                  </div>
                  <div className='testReport_con_list_con'>
                    <div className='testReport_con_list_con_list'>
                      <div className='testReport_con_list_con_list_title'>任课教师</div>
                      <div className='testReport_con_list_con_list_detail'>{item.jzg_xm}</div>
                    </div>
                    <div className='testReport_con_list_con_list'>
                      <div className='testReport_con_list_con_list_title'>上课时间</div>
                      <div className='testReport_con_list_con_list_detail'>
                        {item.sksjList}
                      </div>
                    </div>
                    <div className='testReport_con_list_con_list'>
                      <div className='testReport_con_list_con_list_title'>截止时间</div>
                      <div className='testReport_con_list_con_list_detail'>{item.end_time ? moment(item.end_time).format('YYYY-MM-DD HH:mm') : '--'}</div>
                    </div>
                  </div>
                  <div className='testReport_con_list_footer'>
                  <div className='testReport_con_list_footer_btn testReport_btn_down' onClick={() => {
                      this.props.this.props.history.push(`/module/testState/${item.template_id}`)
                    }}>统计</div>
                    <div className='testReport_con_list_footer_btn testReport_btn_down' onClick={() => {
                      this.props.this.props.history.push(`/module/testTerDetails/${item.template_id}`)
                    }}>详情</div>
                    {
                      assistantsf ? '' :
                        item.templateStatus ? <div className='testReport_con_list_footer_btn testReport_btn_down' onClick={() => this.props.this.props.history.push(`/module/testBan/${item.template_id}/1`)}>修改教学班</div>
                          :
                          <div className='testReport_con_list_footer_btn testReport_btn_down' onClick={() => this.props.this.props.history.push(`/module/testBan/${item.template_id}/0`)}>新增教学班</div>

                    }
                    {
                      assistantsf ? '' : <div className='testReport_con_list_footer_btn testReport_btn_down testReport_btn_sub' onClick={() => this.handleEdit(item)}>编辑</div>
                    }


                  </div>
                </div>
              }) : <div className='not-yet'>--暂无数据--</div>
            }
          </div> : <div className='testReport_con'>
            {
              (data || []).length ? data.map((item, index) => {
                return <div className='testReport_con_list' key={'a' + index}>
                  <div className='testReport_con_list_header'>
                    <img alt='' src={require('../../../images/baogao.png')} />
                    <div className='testReport_con_list_header_con' style={{ width: `${wt}px` }}>
                      <div className='testReport_con_list_header_course'>{item.xs_xm}</div>
                      <div className='testReport_con_list_header_nq header_omit'>{item.record_kcmc}</div>
                    </div>
                   
                  </div>
                  <div className='testReport_con_list_con'>
                    <div className='testReport_con_list_con_list'>
                      <div className='testReport_con_list_con_list_title'>学号</div>
                      <div className='testReport_con_list_con_list_detail'>{item.xs_xh}</div>
                    </div>
                    <div className='testReport_con_list_con_list'>
                      <div className='testReport_con_list_con_list_title'>教学班代码</div>
                      <div className='testReport_con_list_con_list_detail'>{item.xs_bjdm}</div>
                    </div>
                    <div className='testReport_con_list_con_list'>
                      <div className='testReport_con_list_con_list_title'>学年学期</div>
                      <div className='testReport_con_list_con_list_detail'>{item.record_xn} {item.record_xq === '1' ? '秋季' : item.record_xq === '2' ? '春季' : '夏季'}学期</div>
                    </div>
                    <div className='testReport_con_list_con_list'>
                      <div className='testReport_con_list_con_list_title'>提交时间</div>
                      <div className='testReport_con_list_con_list_detail'>{item.submit_time ? moment(item.submit_time).format('YYYY-MM-DD') : '--'}</div>
                    </div>
                  </div>
                  <div className='testReport_con_list_footer'>
                    <div className='testReport_con_list_footer_btn testReport_btn_down' onClick={() => this.handleDownRecord(item)}>下载</div>
                  </div>
                </div>
              }) : <div className='not-yet'>--暂无数据--</div>
            }
          </div>
        }
        {
          tabsId === 1 ? (
            assistantsf ? '' : <div className='testReport_btn'>
              <div className='testReport_btn_list' onClick={() => this.props.this.props.history.push('/module/testMb/0')}>新增实验课程</div>
            </div>
          ) : ''
        }

        <Modal
          popup
          visible={visible}
          onClose={() => this.setState({ visible: false })}
          animationType="slide-up"
        >
          <div className='testTer_modal'>
            <Grid data={GridData} hasLine={false} onClick={(el) => this.handleGrid(el)} />
            <div className='testTer_close' onClick={() => this.setState({ visible: false })}>取消</div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default TestTer
