import React, { Component } from 'react'
import { TextareaItem, ImagePicker, InputItem, Toast, Picker, List, DatePicker, Modal } from 'antd-mobile'
import { Cascader, Field } from 'react-vant'
import { findAppOrderType, uploadOrderPic, findSpaceInfo, req_commitOrder, findPhoneRepairUser, phoneRepairOrder } from '../../../api/api'
import cookie from 'react-cookies'
import './index.less'

class Repair extends Component {
  state = {
    fault: [], // 选择的故障地点
    faultData: [], // 故障区域
    roomData: [], // 房间
    userInfo: {}, // 用户信息
    typeData: [], // 报修类型
    typeId: [], // 选择的报修类型
    advance_start_time: '', // 预约开始时间
    advance_finish_time: '', // 预约结束时间
    order_desc: '', // 问题描述
    urls: [], // 图片地址
    files: [], // 显示的图片地址
    visible: false,
    workUser: [], // 维修工
  }

  componentDidMount = () => {
    this.fetchType()
    this.fetchFault()
    this.setState({
      userInfo: cookie.load('userData')
    })
  }

  // 查询故障区域
  fetchFault = async () => {
    const res = await findSpaceInfo()
    if (res.code === 20000) {
      const menuList = res.data.map(item => {
        return {
          bs: item.bs,
          text: item.dwmc,
          parentId: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
          // disabled: false
        }
      })
      const faultData = menuList.map(item => {
        const result = menuList.filter(p => p.parentId === item.value).length ? menuList.filter(p => p.parentId === item.value) : null
        // if (item.bs === 1 || item.bs === 2) {
        //   item.disabled = result ? false : true
        // }
        item.children = result
        return item
      }).filter(p => p.parentId === '0')

      this.setState({
        faultData: faultData,
        roomData: menuList
      })
    }
  }

  // 查询维修工信息
  fetchWorkUser = async (areaId, typeId) => {
    const res = await findPhoneRepairUser({ areaId, typeId })
    if (res.code === 20000) {
      this.setState({
        workUser: res.data
      })
    }
  }

  // 报修类型
  fetchType = async () => {
    const res = await findAppOrderType()
    if (res.code === 20000) {
      this.setState({
        typeData: res.data.map(val => {
          return {
            label: val.type_name,
            value: val.type_id
          }
        })
      })
    }
  }

  // 上传图片
  upload = (files, type, index) => {
    const { urls } = this.state
    if (type === "add") {
      Toast.loading("图片上传中", 0)
      let formData = new FormData();
      formData.append("file", files[files.length - 1].file);
      uploadOrderPic(formData).then(res => {
        if (res.code === 20000) {
          Toast.info(res.message)
          this.setState({
            urls: urls.concat(res.data)
          })
        }
      })
    }
    if (type === "remove") {
      this.setState({
        urls: urls.filter((x, indexS) => indexS !== index)
      })
    }
    this.setState({
      files,
    });
  }

  // 提交
  handleSubmit = async () => {
    const { fault, roomData, order_desc, urls, typeData, typeId, advance_start_time, advance_finish_time } = this.state
    console.log(fault[2])
    if (fault.length === 0) return Toast.info('请选择故障位置！')
    if (!typeId.length) return Toast.info('请选择报修类型！')
    if (!advance_start_time) return Toast.info('请输入预约开始时间！')
    if (!advance_finish_time) return Toast.info('请输入预约结束时间！')
    if (!order_desc) return Toast.info('请输入问题描述！')
    const buildName = roomData.find(x => x.value == fault[0]).text
    const areaName = roomData.find(x => x.value == fault[1]).text
    const roomName = roomData.find(x => x.value == fault[2]).text
    // 处理提交数据
    const params = {
      room_id: fault[2],
      area_name: buildName + areaName,
      room: roomName,
      fix_type_id: typeId.join(','), // 报修类型id
      fix_type_name: typeData.find(x => x.value === Number(typeId.join(','))).label, // 报修类型名称
      order_desc, // 问题描述
      urls: urls.join(','), // 图片地址
      advance_start_time,
      advance_finish_time
    }
    const res = await req_commitOrder(params)
    if (res.code === 20000) {
      Toast.info(res.message)
      this.props.history.go(-1)
    }
  }

  // 是否可以进行电话报修
  handlePhone = () => {
    const { fault, typeId, advance_start_time, advance_finish_time } = this.state
    if (fault.length === 0) return Toast.info('请选择故障位置')
    if (!typeId.length) return Toast.info('请选择报修类型！')
    if (!advance_start_time) return Toast.info('请输入预约开始时间！')
    if (!advance_finish_time) return Toast.info('请输入预约结束时间！')
    this.setState({
      visible: true
    }, () => {
      this.fetchWorkUser(fault[1], typeId[0])
    })
  }

  // 电话报修
  handlePhoneSubmit = async item => {
    const { fault, roomData, typeData, typeId, advance_start_time, advance_finish_time } = this.state
    if (fault.length === 0) return Toast.info('请选择故障位置！')
    if (!typeId.length) return Toast.info('请选择报修类型！')
    if (!advance_start_time) return Toast.info('请输入预约开始时间！')
    if (!advance_finish_time) return Toast.info('请输入预约结束时间！')
    const buildName = roomData.find(x => x.value == fault[0]).text
    const areaName = roomData.find(x => x.value == fault[1]).text
    const roomName = roomData.find(x => x.value == fault[2]).text

    // 处理提交数据
    const params = {
      area_id: fault[1],
      area_name: buildName + areaName,
      room: roomName,
      fix_type_id: typeId.join(','), // 报修类型id
      fix_type_name: typeData.find(x => x.value === Number(typeId.join(','))).label, // 报修类型名称
      advance_start_time,
      advance_finish_time,
      worker_id: item.zh,
      work_name: item.xm,
    }
    const res = await phoneRepairOrder(params)
    if (res.code === 20000) {
      Toast.success(res.message)
      this.setState({
        visible: false
      })
      this.props.history.go(-1)
    }
  }

  render() {
    const { userInfo, faultData, files, typeData, typeId, advance_start_time, advance_finish_time, visible, workUser } = this.state
    return (
      <div className='repair'>
        <div className='repair-bg'>
          <div className='repair-title'>
            填写报修信息
          </div>
          <div className='repair-desc'>
            有问题 找我行
          </div>
          <div className='repair-position-icon'>
            <img src={require("./../../../images/029.png")} alt='' />
          </div>
        </div>
        <div className='repair-content'>
          <div className='repair-form'>
            <div className='repair-input'>
              <List>
                <div className='fast-info-cascader'>
                  <Cascader
                    popup={{ round: true }}
                    title='选择故障位置'
                    activeColor="#00B4C4"
                    options={faultData}
                    onFinish={e => this.setState({ fault: e })}
                  >
                    {(_, selectedRows, actions) => (
                      <Field
                        isLink
                        value={selectedRows.map(el => el.text).join('/')}
                        readOnly
                        label='故障位置'
                        placeholder='请选择故障位置'
                        onClick={() => actions.open()}
                      />
                    )}
                  </Cascader>
                  {/* <span className='fast-info-cascader-title'>故障位置</span> */}
                  {/* <Cascader allowClear={false} options={faultData} value={fault} onChange={e => {
                    this.setState({ fault: e })
                  }} placeholder="请选择故障位置" /> */}
                </div>
                <InputItem placeholder="" value={userInfo.name} disabled>报修人</InputItem>
                <Picker
                  data={typeData}
                  value={typeId}
                  cols={1}
                  onChange={(val) => {
                    this.setState({
                      typeId: val
                    })
                  }}
                >
                  <List.Item arrow="horizontal">报修类型</List.Item>
                </Picker>
                <DatePicker
                  value={advance_start_time}
                  onChange={date => {
                    this.setState({
                      advance_start_time: date
                    })
                  }}
                >
                  <List.Item arrow="horizontal">预约开始时间</List.Item>
                </DatePicker>
                <DatePicker
                  value={advance_finish_time}
                  onChange={date => {
                    this.setState({
                      advance_finish_time: date
                    })
                  }}
                >
                  <List.Item arrow="horizontal">预约结束时间</List.Item>
                </DatePicker>
              </List>
            </div>
            <div className='repair-text-content'>
              <div className='repair-text-foot'>
                <div className='repair-problem'>
                  <div className='repair-problem-title'>
                    <div>
                      <img src={require("./../../../images/014.png")} alt='' />
                    </div>
                    <div>问题描述</div>
                  </div>
                  <div className='repair-textarea'>
                    <TextareaItem
                      placeholder='请输入'
                      rows={4}
                      onChange={e => this.setState({ order_desc: e })}
                    />
                  </div>
                </div>
                <div className='repair-upload'>
                  <ImagePicker
                    files={files}
                    onChange={(files, type, index) => {
                      this.upload(files, type, index)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='fast-btn'>
              <div className='fast-btn-phone' onClick={() => this.handlePhone()}>电话报修</div>
              <div className='fast-btn-sub' onClick={() => this.handleSubmit()}>提交报修</div>
            </div>
          </div>
        </div>
        <Modal
          popup
          visible={visible}
          onClose={() => this.setState({ visible: false })}
          animationType="slide-up"
        >
          <div className='fast-modal'>
            <div className='fast-modal-title'>维修工电话</div>
            <div className='fast-modal-phone'>
              {
                (workUser || []).map(item => {
                  return <a key={item.zh} className="fast-modal-phone-a" onClick={() => this.handlePhoneSubmit(item)}>{item.xm}:{item.lxfs}</a>
                })
              }
            </div>
            <div className='fast-moadl-close'>
              <div className='fast-moadl-close-btn' onClick={() => this.setState({ visible: false })}>取消</div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default Repair
