import React, { Component } from 'react';
import { req_findUserBatchAuth } from '../../../api/api'
import './door_lock.less'

class LockRecord extends Component {

  state = {
    authorizationData: [], // 临时授权记录
  }

  componentDidMount = () => {
    this.fetch()
    window.addEventListener('focusin', this.setScrollTop)
    window.addEventListener('focusout', this.setScrollTop)
  }

  setScrollTop = () => {
    let scrollTop = document.body.scrollTop + document.documentElement.scrollTop
    window.scrollTo(0, scrollTop)
  }

  // 获取授权记录
  fetch = async () => {
    const res = await req_findUserBatchAuth()
    if (res.code === 20000) {
      this.setState({
        authorizationData: res.data
      })
    }
  }

  render() {
    const { authorizationData } = this.state
    return (
      <div className="recording">
        <div className="mainSq">
          {
            (authorizationData || []).length ? authorizationData.map(item => {
              return (<div className="mainKs-item del-item" key={item.b_id}>
                <div className="mainKs-item-info del-item-info">
                  <div className="mainKs-item-info-name">授权给{item.b_sy_user_name}使用 {item.b_password}
                    {item.b_state === '1' ? (<span className="mainKs-item-info-sf">授权成功</span>) : (<span className="mainKs-item-info-sf mainKs-item-info-sc">已取消</span>)}
                  </div>
                  <div className="mainKs-item-info-time">生效时间：{item.b_start_time}</div>
                  <div className="mainKs-item-info-time">失效时间：{item.b_end_time}</div>
                </div>
              </div>
              )
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
      </div>
    );
  }
}

export default LockRecord;