import React, { Component } from 'react'
import { findAllCurtain } from '../../../api/api'
import './room.less'

class RoomCurtain extends Component {

  state = {
    roomInfo: {},
    data: [],
  }

  componentDidMount = () => {
    document.title = "窗帘"
    this.fetch(this.props.match.params.id)
    this.setState({
      roomInfo: this.props.location.state.roomInfo
    })
  }

  fetch = async spaceId => {
    const res = await findAllCurtain({ spaceId })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  // 显示文字
  handleWord = val => {
    // 设备状态：1 在线 2 离线
    if (val.device_state === 1) {
      return val.parameter_value ? val.parameter_value : '--'
    } else {
      return '离线'
    }
  }

  render() {
    const { roomInfo, data } = this.state
    return (
      <div className='room_curtain'>
        <div className='shortTerm_name'>{roomInfo.wzName}</div>
        <div className='shortTerm_addr'>{roomInfo.buildingName ? roomInfo.buildingName.split('/')[0] : ''}</div>
        <div className='roomEnvironment_con'>
          {
            (data || []).length ? data.map(item => {
              return <div className='roomEnvironment_con_list' key={item.device_id} onClick={() => this.props.history.push(`/module/curtain/detail/${item.device_id}`)}>
                <div className='roomEnvironment_con_list_top'>
                  <div className='roomEnvironment_con_list_l'>
                    <div className='roomEnvironment_con_list_l_name'>{item.device_name}</div>
                    <div className='roomEnvironment_con_list_l_addr'>{item.device_building_name}-{item.device_room_name}</div>
                  </div>
                  <div className='roomEnvironment_con_list_r'>

                  </div>
                </div>
                {
                  item.device_state === 1 ? <div className='roomEnvironment_con_list_state roomEnvironment_status_online'>
                    <span></span>
                    <span>设备在线</span>
                  </div> : <div className='roomEnvironment_con_list_state roomEnvironment_status_offline'>
                    <span></span>
                    <span>设备离线</span>
                  </div>
                }
              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
      </div>
    )
  }
}

export default RoomCurtain
