import React, { Component } from 'react'
import { TreeSelect } from 'antd'
import { Picker, List, TextareaItem, Toast, InputItem } from 'antd-mobile'
import { findCkWhpInfoDetails, req_findDepartmentInfo, findZxOrRoomByDwOrZx, saveSyjlInfo, findCkInfo } from './../../../../api/gas'

const { TreeNode } = TreeSelect

class Add extends Component {
  state = {
    data: [
      {
        label: '2013年',
        value: '2013',
      },
      {
        label: '2014年',
        value: '2014',
      },
    ],
    slideId: '',
    startX: '',
    visible: false,
    tab_id: '',
    sqfxData: [],//方向
    sgdh: undefined,//
    xydm: undefined,//所属学院
    zxid: undefined,//所属中心
    jsid: undefined,//所属实验室
    lyckbh: undefined,
    syfxbh: undefined,
    lyrgh1: undefined,
    lyrgh2: undefined,
    ghms: '',
    typeData: [],//类别
    whpFlbh: '',//化学品类别
    depTreeData: [],
    depData: [],
    centerData: [],//中心
    roomData: [],//房间
    masterProcess: 0, // 是否多人同时审批
    approveVisible: false,
    spry: undefined,
    warehouseData: [],//仓库
    userData: [],//领用人
    whpInfoEntityList: [],//领用物品
    depUserData: [],
    depArr: [],
    flbh: '',
    drkData: [],
  }

  componentDidMount = () => {
    document.title = '填写使用'
    window.scrollTo(0, 0)
    this._findCkInfo()
    this.fetchDep()
  }

  // 查询待入库
  _findCkInfo = async () => {
    const res = await findCkInfo({ size: 99999, page: 1 })
    if (res.code === 20000) {
      this.setState({
        drkData: res.data.list.map(item => {
          return {
            label: item.lyrmc1 + ',' + item.lyrmc2 + '(' + item.lybh + ')',
            value: item.lybh,
          }
        }),
      })
    }
  }

  handleOkDrk = async () => {
    const { sgdh } = this.state
    if (sgdh.length === 0) {
      return false
    }
    this.handleOkLy(sgdh.join(','))
  }

  // 选择的领用单（易耗品）
  handleOkLy = async (lydh) => {
    const res = await findCkWhpInfoDetails({ lydh })
    if (res.code === 20000) {
      const newArr = (res.data.lysqWhpCkInfoEntities || []).map(m => {
        m.whp_yl = m.lysl
        m.syjlms = null
        return m
      })
      await this.handleChangeDep(res.data.xydm)
      await this.handleChangeCenter(res.data.zxid.split(','))
      this.setState({
        whpInfoEntityList: newArr,
        xydm: res.data.xydm,
        zxid: res.data.zxid.split(','),
        jsid: res.data.jsid.split(','),
      })
    }
  }

  // 数组转变
  deep = (node) => {
    let stack = node,
      data = []
    while (stack.length !== 0) {
      let pop = stack.pop()
      data.push({
        title: pop.title,
        parentId: pop.parentId,
        value: pop.value,
      })
      let children = pop.children
      if (children) {
        for (let i = children.length - 1; i >= 0; i--) {
          stack.push(children[i])
        }
      }
    }
    return data
  }

  // 查询学院/部门
  fetchDep = async () => {
    const res = await req_findDepartmentInfo({ dwlx: 1 })
    if (res.code === 20000) {
      let menuList = res.data
      menuList = menuList.map(a => {
        return {
          title: a.dwmc,
          key: a.dwdm,
          parentId: a.sj_dm,
          value: a.dwdm,
          disabled: a.sj_dm === '0' ? true : false
        }
      })
      menuList = menuList.map(m => {
        m.children = menuList.filter(p => p.parentId === m.key)
        return m
      }).filter(x => !res.data.find(v => v.dwdm === x.parentId))
      this.setState({
        depTreeData: menuList,
        depData: res.data,
      })
    }
  }

  // 选择部门/学院
  handleChangeDep = async e => {
    const res = await findZxOrRoomByDwOrZx({ flag: 1, param: e })
    if (res.code === 20000) {
      this.setState({
        centerData: res.data.map(item => {
          return {
            label: item.room_name,
            value: item.room_id,
          }
        })
      })
    }
  }

  // 选择中心
  handleChangeCenter = async e => {
    const res = await findZxOrRoomByDwOrZx({ flag: 2, param: e.join(',') })
    if (res.code === 20000) {
      this.setState({
        roomData: res.data.map(item => {
          return {
            label: item.room_name,
            value: item.room_id,
          }
        })
      })
    }
  }

  // 提交
  submit = async () => {
    const { xydm, zxid, jsid, depData, centerData, roomData, whpInfoEntityList, sgdh, ghms } = this.state
    if (sgdh === undefined || xydm === undefined || zxid === undefined || jsid === undefined) {
      Toast.info('请补全上述内容！')
      return false
    }
    if (whpInfoEntityList.filter(x => !x.sysl || x.sysl === '').length > 0) {
      Toast.info('请填写使用量！')
      return
    }
    if (whpInfoEntityList.filter(x => x.sysl > 0).length !== whpInfoEntityList.length) {
      Toast.info('使用量必须大于0！')
      return
    }
    const params = {
      lybh: sgdh.join(','),
      xydm: xydm,
      zxid: zxid.join(','),
      jsid: jsid.join(','),
      xymc: depData.find(x => x.dwdm === xydm).dwmc,
      zxmc: centerData.find(x => x.value === zxid.join(',')).label,
      jsmc: roomData.find(x => x.value === jsid.join(',')).label,
      syjlms: ghms,
      ghzt: 0,
      syjlWhpInfoEntities: whpInfoEntityList,

    }
    const res = await saveSyjlInfo(params)
    if (res.code === 20000) {
      Toast.info("提交成功！")
      setTimeout(() => {
        this.props.history.goBack()
      }, 2000)
    }
  }

  render() {
    const { slideId, ghms, depTreeData, xydm, centerData, zxid, roomData, jsid, whpInfoEntityList, drkData, sgdh } = this.state
    const loop = data =>
      data.map(item => {
        if (item.children && item.children.length) {
          return (
            <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled}>
              {loop(item.children)}
            </TreeNode>
          )
        }
        return <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled} />
      })
    return (
      <div className='gas_manage_add'>
        <div className='gas_manage_add_title'>

        </div>
        <div className='gas_manage_add_form'>
          <div className='gas_manage_add_picker'>
            <Picker
              data={drkData}
              value={sgdh}
              cols={1}
              onChange={(e) => {
                this.setState({
                  sgdh: e
                }, () => {
                  this.handleOkDrk()
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>关联申购单</List.Item>
            </Picker>
          </div>

          <div className='gas_manage_add_tree'>
            <div>
              <span style={{ color: 'red' }}>* </span>所属学院
            </div>
            <TreeSelect
              showSearch
              treeNodeFilterProp='title'
              placeholder="请选择"
              style={{ width: '100%' }}
              value={xydm}
              onChange={(e) => {
                this.setState({
                  xydm: e,
                  zxid: undefined,
                  jsid: undefined
                })
                this.handleChangeDep(e)
              }}
            >
              {loop(depTreeData)}
            </TreeSelect>
          </div>
          <div className='gas_manage_add_picker'>
            <Picker
              data={centerData}
              value={zxid}
              cols={1}
              onChange={(e) => {
                this.setState({
                  zxid: e,
                  jsid: undefined
                })
                this.handleChangeCenter(e)
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>所属中心</List.Item>
            </Picker>
          </div>
          <div className='gas_manage_add_picker'>
            <Picker
              data={roomData}
              value={jsid}
              cols={1}
              onChange={(e) => {
                this.setState({
                  jsid: e
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>所属实验室</List.Item>
            </Picker>
          </div>
        </div>


        <div className='gas_manage_add_form'>
          <div className='gas_manage_add_text'>
            <div><span style={{ color: '#fff' }}>* </span>备注</div>
            <TextareaItem
              placeholder="请输入"
              rows={5}
              value={ghms}
              onChange={(e) => {
                this.setState({
                  ghms: e
                })
              }}
            />
          </div>
        </div>

        {whpInfoEntityList.length ? (<div className='gas_manage_add_increase_record'>
          <div className='subscription_list_card' style={{ margin: '0' }}>
            {whpInfoEntityList.map((item, key) => {
              return (
                <div className='gas_manage_add_card_item' style={{ height: 'auto' }} key={key}>
                  <div className='gas_manage_add_card_cont  gas_manage_add_cont_float' style={{ margin: '0px 12px', position: 'unset' }}>
                    <div className='subscription_list_card_item' style={{ width: window.screen.width - 24 }}>
                      <div className='subscription_list_card_head'>
                        <div className='subscription_list_card_head_left'>
                          <div>{key + 1}</div>
                          <div>{item.gas_name}</div>
                        </div>
                        <div className='gas_manage_list_item_tag'>
                          {item.gas_type_name}
                        </div>
                      </div>
                      <div className='subscription_list_card_unit' style={{ marginTop: '12px' }}>
                        <div>{item.bottle_number}</div>
                        <div>{item.bottle_type}</div>
                        <div>{item.bottle_specs}</div>
                      </div>
                      <div className='flex_c'>
                        <div className='flex_c_item'>
                          <div>{item.bottle_capacity}</div>
                          <div>容量</div>
                        </div>
                        <div className='flex_c_item'>
                          <div>{item.gas_number}L</div>
                          <div>当前气量</div>
                        </div>
                        <div className='flex_c_item'>
                          <div>{item.lysl}L</div>
                          <div>领用量</div>
                        </div>
                      </div>
                      <div className='subscription_list_card_input'>
                        <div className='gas_manage_add_input'>
                          <InputItem type='digit' placeholder="请输入" value={item.sysl} onChange={(e) => {
                            this.setState({
                              whpInfoEntityList: whpInfoEntityList.map(x => {
                                if (x.bottle_number === item.bottle_number) {
                                  if (e > 0) {
                                    x.sysl = e > x.gas_number ? x.gas_number : e
                                  } else {
                                    x.sysl = Number(e)
                                  }
                                }
                                return x
                              })
                            })
                          }}>使用量(L)</InputItem>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}

          </div>
        </div>) : ''}

        <div className='gas_manage_list_but gas_manage_list_but_two'>
          <div onClick={() => {
            window.history.go(-1);
          }}>取消</div>
          <div onClick={() => {
            this.submit()
          }}>提交使用</div>
        </div>

      </div >
    )
  }
}

export default Add
