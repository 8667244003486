import React, { Component } from 'react'
import { SearchBar, Toast } from 'antd-mobile'
import { Select, Switch } from 'antd'
import { findScheduledTaskZnkt, updateScheduledState, deleteScheduled } from '../../../api/api'
import moment from 'moment'
import './timing_list.less'
const { Option } = Select


class TimingList extends Component {

  state = {
    searchContent: '', // 搜索值
    status: '', // 状态
    deviceId: '',
    data: [],
    typeData: [
      { label: '周一', value: '1' },
      { label: '周二', value: '2' },
      { label: '周三', value: '3' },
      { label: '周四', value: '4' },
      { label: '周五', value: '5' },
      { label: '周六', value: '6' },
      { label: '周日', value: '7' },
    ],
  }

  componentDidMount = () => {
    document.title = "空调管理-定时列表"
    this.setState({
      deviceId: this.props.match.params.id
    }, () => {
      this.fetch()
    })
  }

  fetch = async () => {
    const { deviceId, searchContent, typeData, status } = this.state
    const res = await findScheduledTaskZnkt({ deviceId, taskName: searchContent, state: status })
    if (res.code === 20000) {
      this.setState({
        data: res.data.map(x => {
          x.weeks = (x.task_cycle.split(',') || []).map(t => {
            t = typeData.find(z => z.value === t)
            return t
          }).map(y => y.label).join('、')
          return x
        })
      })
    }
  }

  // 搜索房间
  handleChange = e => {
    this.setState({
      searchContent: e
    }, () => {
      this.fetch()
    })
  }

  handleCancel = () => {
    this.setState({
      searchContent: ''
    }, () => {
      this.fetch()
    })
  }

  // 选择启用状态
  handleSelectStatus = e => {
    this.setState({
      status: e
    }, () => {
      this.fetch()
    })
  }

  // 修改开关
  handleChangeSwitch = async taskId => {
    const { deviceId } = this.state
    const res = await updateScheduledState({ taskId, deviceId })
    if (res.code === 20000) {
      this.fetch()
    }
  }

  // 编辑
  handleEdit = (e, item) => {
    e.stopPropagation()
    const { deviceId } = this.state
    this.props.history.push(`/module/aircondition/timingConfiguration/${deviceId}/${item.task_id}`)
  }

  // 删除
  handleDel = async (e, item) => {
    e.stopPropagation()
    const { deviceId } = this.state
    const res = await deleteScheduled({ taskId: item.task_id, deviceId })
    if (res.code === 20000) {
      Toast.success(res.message)
      this.fetch()
    }
  }
  handleNew = deviceId => {
    this.props.history.push(`/module/aircondition/timingConfiguration/${deviceId}/${0}`)
  }

  render () {
    const { data, deviceId } = this.state
    return (
      <div className='timingList'>
        <div className='timingList_head'>
          <div className='timingList_input'>
            <SearchBar placeholder="请输入配置名称进行搜索" onCancel={() => this.handleCancel()} onChange={e => this.handleChange(e)} />
          </div>
          <div className='timingList_select'>
            <Select placeholder='启用状态' onSelect={e => this.handleSelectStatus(e)}>
              <Option value={1}>启用</Option>
              <Option value={0}>禁用</Option>
            </Select>
          </div>
        </div>
        <div className='timingList_body'>
          {
            (data || []).length ? data.map(item => {
              return (
                <div className='timingList_list' key={item.task_id}>
                  <div className='header'>
                    <div>{item.task_create_time ? moment(item.task_create_time).format('YYYY-MM-DD HH:mm:ss') : moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}</div>
                  </div>
                  <div className="content">
                    <div className='name'>{item.task_name}</div>
                    <div className='other'>
                      {item.task_action === 'off' && <div className='close_color' style={{ color: "#ff2654" }}>关机</div>}
                      {item.task_action === 'hea' && <div className='hot_color' style={{ color: "#ff6f10" }}>开机制热</div>}
                      {item.task_action === 'col' && <div className='cool_color'>开机制冷</div>}
                      <div className='cool_color'>执行时间&nbsp;{item.task_condition}</div>
                    </div>
                    <div className="week_date">
                      <div className='title'>执行周期</div>
                      <div className='result'>{item.weeks}</div>
                      <div className='title'>执行日期</div>
                      <div className='result'>{item.task_start_date} 至 {item.task_end_date}</div>
                    </div>
                    <div className="foot">
                      <div className='del' onClick={e => this.handleDel(e, item)}>
                        <span>删除</span>
                      </div>
                      <div className='edit' onClick={e => this.handleEdit(e, item)}>
                        <span>编辑</span>
                      </div>
                    </div>
                  </div>
                </div>
              )

            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
        <div className='timingList_list_new' onClick={() => this.handleNew(deviceId)}>
          <div>新建定时配置</div>
        </div>
      </div>
    )
  }
}

export default TimingList
