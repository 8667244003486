import React, { Component } from 'react'
import cookie from 'react-cookies'
import TestStu from './test_stu'
import TestTer from './test_ter'

class TestReport extends Component {

  componentDidMount = () => {

  }

  render() { 
    return (
      <div>
        {
          cookie.load('sf') == 1 ? <TestTer this={this} /> : <TestStu this={this} />
        }
      </div>
    )
  }
}

export default TestReport
