import React, { Component } from 'react'
import CanvasComponent from './CanvasComponent'
import html2canvas from 'html2canvas'
import IP from '../../../config/config'
import moment from 'moment'
import { Loading, Overlay } from 'react-vant'
import { findMyCert } from '../../../api/safty'

export default class Certificate extends Component {
  state = {
    certList: [],
    loading: true,
    visible: false,
    currentBase64: '',
    loadingImg: false
  }
  async componentDidMount () {
    const res = await findMyCert()
    if (res.code === 20000) {
      this.setState({
        certList: res.data
      })
    }
    this.setState({
      loading: false
    })
  }

  toCanvas = k => {
    this.setState({
      visible: true,
      loadingImg: true
    })
    html2canvas(document.getElementById(`img_${k}`), { allowTaint: true, useCORS: true }).then(canvas => {
      var screenshot = canvas.toDataURL("image/jpg") // 将canvas转换成img的src流
      this.setState({
        currentBase64: screenshot,
        loadingImg: false
      })
    })
  }
  // saveImg = () => {
  //   // 图片预览, 可选参数 saveable：是否显示保存图片按钮， index: 多图情况下指定当前浏览的序号
  //   // if (window.mbBridge) {
  //   //   window.mbBridge.mb.image({
  //   //     index: 1,
  //   //     saveable: true,
  //   //     urls: [imgBase64]
  //   //   });
  //   // }
  //   console.log(window.mbBridge)
  //   if (window.mbBridge && window.mbBridge.mb) {
  //     console.log('保存图片被触发了！！！')
  //     window.mbBridge.mb.saveImage(this.state.currentBase64)
  //   }
  // }
  closeOverlay = () => {
    this.setState({
      loadingImg: false,
      visible: false,
      currentBase64: '',
    })
  }
  render () {
    const { certList, loading, visible, currentBase64, loadingImg } = this.state
    return (
      <div className='saftyModule certificate'>
        <div className='content_wrap'>
          {
            loading ?
              <div className='load-center'>
                <Loading />
              </div> : certList.length ?
                certList.map((item, k) => {
                  return <div className="floor" key={item.record_id} onClick={() => this.toCanvas(k)}>
                    <div className="cont" id={`img_${k}`} style={{ display: 'block' }}>
                      <img src={IP.fileHost + item.template_url} alt="" />
                      <div className='pos_wrap'>
                        <div className='title'>实验室安全教育与考试</div>
                        <div className='sub_title'>结业证书</div>
                        <div className="content">
                          <span></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.record_user_name}&nbsp;&nbsp;同志（学号/工号）{item.record_user_number}&nbsp;&nbsp;参加了【{item.paper_name}】考试。成绩合格，特发此证。
                        </div>
                        <div className="footer">
                          <div className='name'>苏州萨思科技有限公司</div>
                          <div>颁发日期：{moment(item.record_end_time).format('YYYY年MM月DD日')}</div>
                        </div>
                      </div>

                    </div>
                  </div>
                }) : <div className='not-yet'>--暂无证书--</div>
          }
        </div>
        <Overlay visible={visible} onClick={this.closeOverlay}
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <div className="overlay-self">
            {loadingImg ? <div className='load-center'>
              <Loading />
            </div> : <img src={currentBase64} alt="" />}
            {/* <div className="bottom_btn">
              <div className="bottom_btn_list no_solid_bg" onClick={this.saveImg}>
                保存到相册
              </div>
            </div> */}
          </div>
        </Overlay>
      </div>
    )
  }
}
