import React, { Component } from 'react'
import { InputItem, TextareaItem, Steps } from 'antd-mobile'
import { req_findMyRecordById } from '../../../api/cgyy'
import moment from 'moment'
import './submit.css'
const Step = Steps.Step

export default class componentName extends Component {
  state = {
    yyrq: '',
    yysj: '',
    yylx: 1,//状态
    yydw: '',//单位
    yymc: '',//姓名
    yysjh: '',//手机号
    yyscrs: 0,//随行人数
    yybz: '',//备注
    yyzt: '',
    xndjdw: '',
    yylxState: '',
    data: []
  }

  componentDidMount = () => {
    document.title = "预约详情"
    this.fetch(this.props.match.params.id)
  }

  fetch = async (recordId) => {
    const res = await req_findMyRecordById({ recordId })
    if (res.code === 20000) {
      const data = res.data
      let yyzt = ''
      if (data.yyzt === 0) {
        yyzt = '待审核'
      }
      if (data.yyzt === 1) {
        yyzt = '审核中'
      }
      if (data.yyzt === 2) {
        yyzt = '审核通过'
      }
      if (data.yyzt === 3) {
        yyzt = '审核驳回'
      }
      if (data.yyzt === 4) {
        yyzt = '预约取消'
      }
      this.setState({
        yyrq: data.yyrq,
        yysj: data.yysj,
        yylx: data.yylx === 1 ? '校内团队' : '校外团队',//状态
        yydw: data.yydw,//单位
        yymc: data.yymc,//姓名
        yysjh: data.yysjh,//手机号
        yyscrs: data.yyscrs,//随行人数
        yybz: data.yybz,//备注
        yyzt: yyzt,
        yylxState: data.yylx,
        xndjdw: data.xndjdw,
        data: data.approvalProcessRecordEntities

      })
    }
  }

  render () {
    const { yyrq, yysj, yylx, yydw, yymc, yysjh, yyscrs, yybz, yyzt, data, xndjdw, yylxState } = this.state
    return (
      <div className="submit">
        <div className='sub_form' style={{ paddingBottom: '0' }}>
          <div className='sub_form_item sub_form_item_view'>
            <InputItem disabled value={yyrq + ' ' + yysj} >参观时间</InputItem>
          </div>
          <div className='sub_form_item sub_form_item_view'>
            <InputItem disabled value={yylx}>团队类型</InputItem>
          </div>

          <div className='sub_form_item sub_form_item_view'>
            <InputItem disabled value={yymc}>申请人姓名</InputItem>

          </div>

          <div className='sub_form_item sub_form_item_view'>
            <InputItem disabled value={yydw}>申请人单位</InputItem>
          </div>
          <div className='sub_form_item sub_form_item_view'>
            <InputItem disabled value={yysjh}>联系电话</InputItem>
          </div>
          <div className='sub_form_item sub_form_item_view'>
            <InputItem disabled value={yyzt}>审核状态</InputItem>
          </div>

          <div className='sub_form_item sub_form_item_view'>
            <InputItem disabled value={yyscrs + '人'}>随行人数</InputItem>
          </div>

          {yylxState === 2 ? (<div className='sub_form_item sub_form_item_view'>
            <InputItem disabled value={xndjdw}>校内对接单位</InputItem>
          </div>
          ) : ''}

          <div className='sub_form_item_text sub_form_item_view'>
            <div>备注</div>
            <TextareaItem
              disabled
              rows={4}
              value={yybz}
            />
          </div>
        </div>

        {
          (data || []).length ? <div className='cgyy_appointmentDetail_process'>
            <div className='cgyy_appointmentDetail_process_detail'>
              <div className='cgyy_appointmentDetail_process_title'>审批流程</div>
              <div className='cgyy_appointmentDetail_process_con'>
                {
                  data.map((item, index) => {
                    return <div className="cgyy_progess-bar" key={index + 1}>
                      <div className={`cgyy_progress ${index ? ((item.pr_approval_result !== 1) && (item.pr_approval_result !== 0)) ? 'cgyy_active' : 'cgyy_negative' : ''}`}></div>
                      <div className="cgyy_approval-detail">
                        <div className='cgyy_approval-detail-people'>
                          {
                            (item.pr_approval_result === 1 || item.pr_approval_result === 0) ? <span>{item.pr_auth_name}</span> : <span>{item.pr_auth_name}：{item.pr_approval_username}</span>
                          }
                        </div>
                        <div className='cgyy_approval-detail-status'>
                          {
                            item.pr_approval_result === 2 ? <span className='approval-success'>审批成功</span> : item.pr_approval_result === 3 ? <span className='approval-reject'>审批驳回</span> : <span className='approval-wait'>待审批</span>
                          }
                          <span>{item.pr_approval_time && moment(item.pr_approval_time).format('YYYY-MM-DD HH:mm')}</span>
                        </div>
                        <div className='cgyy_approval-detail-opinion'>{item.pr_approval_opinion}</div>
                      </div>
                      <div className={`cgyy_ball ${(item.pr_approval_result === 1 || item.pr_approval_result === 0) ? 'cgyy_negative' : 'cgyy_active'}`}></div>
                    </div>
                  })
                }
              </div>
            </div>
          </div> : ''
        }
      </div >
    )
  }
}
