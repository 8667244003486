import React, { Component } from 'react'
import { findMySubscribeRecord } from '../../../api/api'
import moment from 'moment'
import './appointment.less'

class AppointmentRecord extends Component {

  state = {
    statusData: [
      { id: 0, name: '待审核', checked: true },
      { id: 1, name: '已审核', checked: false },
    ],
    status: 0,
    data: [],
  }

  componentDidMount = () => {
    this.fetch('0')
  }

  fetch = async subscribeStatus => {
    const res = await findMySubscribeRecord({ subscribeStatus })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  // 切换状态
  handleStatus = item => {
    const { statusData } = this.state
    this.setState({
      statusData: statusData.map(x => {
        x.checked = x.id === item.id ? true : false
        return x
      })
    })
    this.fetch(item.id)
  }

  getweekday = (date) => {
    var weekArray = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
    var week = weekArray[new Date(date).getDay()];
    return week;
  }

  render() {
    const { statusData, data } = this.state
    return (
      <div className='appointmentRecord'>
        <div className='approve_header riskReport'>
          <div className='approve_header_config'>
            <div className='approve_header_con'>
              {
                statusData.map(item => {
                  return <div className={`approve_header_con_list ${item.checked ? 'approve_header_con_list_active' : ''}`} key={item.id} onClick={() => this.handleStatus(item)}>{item.name}</div>
                })
              }

            </div>
          </div>
        </div>
        <div className='appointmentRecord_con'>
          {
            (data || []).length ? data.map(item => {
              return <div className='approve_con_list' key={item.record_id} onClick={() => this.props.history.push(`/module/appointmentRecord/detail/${item.record_id}`)}>
                <div className='approve_list_head'>
                  <div>
                    {item.submit_time ? moment(item.submit_time).format('YYYY-MM-DD HH:mm') : moment(new Date()).format('YYYY-MM-DD HH:mm')}
                  </div>
                  <div>
                    {item.reservationist}
                  </div>
                </div>
                <div className='approve_list_head_title'>
                  {item.room}
                </div>

                <div className='approve_list_head_desc'>
                  <div className='approve_list_head_date'>
                    {item.date_time} {this.getweekday(item.date_time)} 第{item.nodeInfo}节
                  </div>
                  {item.seat_number > 0 ? <div className='approve_list_head_seat'>座位号:{item.seat_number}</div> : ''}

                </div>

              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
      </div>
    )
  }
}

export default AppointmentRecord
