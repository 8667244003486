import React, { Component } from 'react'
import { Toast } from 'antd-mobile'
import { exam_loadOnlineMokExercise } from '../../../api/api'
import './on_line.less'

const flagMap = {
  0: '单选题',
  1: '多选题',
  2: '判断题'
}

const questionNumber = [
  {id: 0, title: 'A'},
  {id: 1, title: 'B'},
  {id: 2, title: 'C'},
  {id: 3, title: 'D'},
  {id: 4, title: 'E'},
  {id: 5, title: 'F'},
  {id: 6, title: 'G'},
  {id: 7, title: 'H'},
  {id: 8, title: 'I'},
]

// 0：没有答题；1：答对了；2：答错了
const isErrMap = {
  0: '',
  1: 'doneSucc',
  2: 'doneErr'
}

class PracticeOnline extends Component {

  state = {
    allData: [],            //所有题
    choosedIndex: 1,        //选中的第几道题
    toggleAnswer: false,    //控制答案、解析是否显示
  }

  componentDidMount = () => {
    this.fetch()
  }

  fetch = async () => {
    Toast.loading('加载中', 0)
    const params = this.props.match.params
    const res = await exam_loadOnlineMokExercise({
      categoryIds: params.categoryIds === '0' ? '' : params.categoryIds,
      flag: params.flag,
      num: params.num - 0,
      radioNum: params.radioNum - 0,
      multiNum: params.multiNum - 0,
      estimateNum: params.estimateNum - 0,
    })
    if (res.code === 20000 && res.data) {
      Toast.hide()
      const arr = (res.data.danxuanze || []).concat(res.data.panduan || []).concat(res.data.duoxuanze || [])
      this.setState({
        allData: arr.map(x => {
          return ({
            ...x,
            "isErr": 0,
            "answer": [],
          })
        })
      })
    }
  }

  // 单选和判断确认
  submitDanxuanPanduan = (data, evP) => {
    const { allData } = this.state
    if (data.isErr > 0) {
      Toast.info('本题已做过！')
      return false
    }
    this.setState({
      toggleAnswer: true,
    })
    //做完题给题做个标记，已做，自己的答案，对与错
    this.setState({
      allData: allData.map(x => {
        if (x.problem_id === data.problem_id) {
          x.isErr = evP.result_flag === 2 ? 2 : evP.result_flag === 1 ? 1 : 0
          x.answer = [evP]
          x.resultConfigEntities = (x.resultConfigEntities || []).map(t => {
            if (t.result_id === evP.result_id) {
              if (t.result_flag === 1) {
                t.answer_title = 1 // 做正确
              } else {
                t.answer_title = 2 // 做错
              }
            } else {
              if (t.result_flag === 1) {
                t.answer_title = 1 // 正确答案
              }
            }
            return t
          })
        }
        return x
      })
    })
  }

  // 多选题选择
  handleDuoChoose = (data, p) => {
    const { allData } = this.state
    if (data.isErr > 0) {
      Toast.info('本题已做过！')
      return false
    }
    const newAllData = allData.map(x => {
      if (x.problem_id === data.problem_id) {
        if (x.answer.find(t => t.result_id === p.result_id)) {
          x.answer = x.answer.filter(y => y.result_id !== p.result_id)
        } else {
          x.answer = x.answer.concat([p])
        }
      }
      return x
    })
    this.setState({
      allData: newAllData.map(x => {
        if (x.problem_id === data.problem_id) {
          x.resultConfigEntities = (x.resultConfigEntities || []).map(t => {
            if (x.answer.find(y => y.result_id === t.result_id)) {
              t.answer_title = 1
            } else {
              t.answer_title = null
            }
            return t
          })
        }
        return x
      })
    })
  }

  // 多选题提交
  submitDuoChoose = data => {
    const { allData } = this.state
    this.setState({
      allData: allData.map(x => {
        if (x.problem_id === data.problem_id) {
          x.isErr = x.answer.length === 0 ? 0 : (x.answer.filter(x => x.result_flag === 1).length === data.resultConfigEntities.filter(x => x.result_flag === 1).length ? 1 : 2)
          x.resultConfigEntities = (x.resultConfigEntities || []).map(t => {
            if (x.answer.find(y => y.result_id === t.result_id)) {
              if (t.result_flag === 1) {
                t.answer_title = 1 // 做正确
              } else {
                t.answer_title = 2 // 做错
              }
            } else {
              if (t.result_flag === 1) {
                t.answer_title = 1 // 正确答案
              }
            }
            return t
          })
        }
        return x
      }),
      toggleAnswer: true
    })
  }

  //切换下一题
  nextData = () => {
    this.handleChooseData(this.state.choosedIndex)
  }

  //切换题
  handleChooseData = index => {
    if (index === this.state.allData.length) {
      Toast.info('已经是最后一题了')
      return false
    }
    this.setState({
      choosedIndex: index + 1,
      toggleAnswer: this.state.allData[index].isErr === 0 ? false : true
    })
  }

  nextPractice = () => {
    this.setState({
      choosedIndex: 1,
      toggleAnswer: false,
    }, () => {
      this.fetch()
    })
  }

  render () {
    const { allData, choosedIndex, toggleAnswer } = this.state
    const chooseData = allData[choosedIndex - 1]
    return (
      <div className='on_line'>
        <div className='on_line_card'>
          <div className='on_line_card_title'>
            <div className='card_title'>答题卡</div>
            <div className='card_num'>
              <span>{(allData || []).filter(x => x.isErr).length}</span><span>/{(allData || []).length}</span>
            </div>
          </div>
          {
            allData.length > 0 ?
              <div className='on_line_card_con'>
                {(allData || []).map((item, index) => {
                  return <a className={`${item.problem_id === chooseData.problem_id ? 'checked' : ''} ${isErrMap[item.isErr]}`} key={item.problem_id} onClick={() => this.handleChooseData(index)}>{index + 1}</a>
                })}
              </div> :
              <div className='on_line_question'>
                <div className='not-yet'>--暂无题目--</div>
              </div>
          }
        </div>
        {
          chooseData && JSON.stringify(chooseData) !== '{}' ? <div className="on_line_question">
            <div className='quest_title'>
              <span className='quest_title_lx'>{flagMap[chooseData.problem_flag]}</span>
              <span className='quest_title_name'>
                {choosedIndex}、{chooseData.problem_name}
              </span>
            </div>
            {(chooseData.problem_flag === 0 || chooseData.problem_flag === 2) && <div>
              <div className='all_answer'>
                {chooseData.resultConfigEntities && chooseData.resultConfigEntities.map((ev, i) =>
                  <div key={ev.result_id} className={`all_answer_list ${ev.answer_title === 1 ? 'answer_success' : ev.answer_title === 2 ? 'answer_error' : ''}`} onClick={() => this.submitDanxuanPanduan(chooseData, ev)}>
                    {questionNumber[i].title}.{ev.result_name}
                    {/* {ev.result_sort}.{ev.result_name} */}
                  </div>
                )}
              </div>
            </div>
            }
            {(chooseData.problem_flag === 1) && <div className='all_answer'>
              {chooseData.resultConfigEntities && chooseData.resultConfigEntities.map((p, i) =>
                <div key={p.result_id} className={`all_answer_list ${p.answer_title === 1 ? 'answer_success' : p.answer_title === 2 ? 'answer_error' : ''}`} onClick={() => this.handleDuoChoose(chooseData, p)}>
                  {/* {p.result_sort}.{p.result_name} */}
                  {questionNumber[i].title}.{p.result_name}
                </div>
              )}
              {
                !toggleAnswer && <div className='ans_btn' onClick={() => this.submitDuoChoose(chooseData)}>提交</div>
              }
            </div>
            }
          </div> : ''
        }
        {
          chooseData && JSON.stringify(chooseData) !== '{}' && toggleAnswer ? <div className="quest_foot">
            <div className='quest_foot_title'>答案解析</div>
            <div className='quest_foot_desc'>{chooseData.answer_desc}</div>
          </div> : ''
        }
        <div className='on_line_btn'>
          {
            choosedIndex < allData.length ? <div className='on_line_btn_next' onClick={() => this.nextData()}>下一题</div> : <div className='on_line_btn_practice' onClick={() => this.nextPractice()}>再次练习</div>
          }
        </div>
      </div>
    )
  }
}

export default PracticeOnline
