import React, { Component } from 'react'
import { Button } from 'antd-mobile'
import { req_selectOne, req_isZcAuth } from '../../../api/api'
import moment from 'moment'
import './create_patrol.less'

class PatrolDetail extends Component {
  state = {
    detailInfo: {},
    patrolImgs: [],
    status: false
  }

  componentDidMount = () => {
    document.title = '实验室信息'
    this.fetchPatrolDetail()
    this.isZcAuth(this.props.match.params.id)
  }

  //进入页面获取该房间的巡检配置情况
  fetchPatrolDetail = async () => {
    const id = this.props.match.params.id
    const res = await req_selectOne({ id })
    if (res.code === 20000) {
      this.setState({
        detailInfo: res.data,
      })
    }
  }

  isZcAuth = async (roomId) => {
    const res = await req_isZcAuth({ roomId })
    if (res.code === 20000) {
      console.log(res.data)
      this.setState({
        status: res.data
      })
    }
  }

  render() {
    const { detailInfo } = this.state
    return (
      <div className='createPatrol patrolDetail room_report'>
        <div className="listWrap mt-12" style={{ background: 'none', paddingTop: '6px', marginTop: '0px' }}>
          <div className='listStyl' style={{ background: '#fff', marginBottom: '6px', padding: '12px 16px' }}>
            <div className="left">实验室名称</div>
            <div className="right">{detailInfo.room_name}</div>
          </div>
          <div className='listStyl' style={{ background: '#fff', marginBottom: '6px', padding: '12px 16px' }}>
            <div className="left">实验室编号</div>
            <div className="right">{detailInfo.room_number}</div>
          </div>
          <div className='listStyl' style={{ background: '#fff', marginBottom: '6px', padding: '12px 16px' }}>
            <div className="left">所属位置</div>
            <div className="right">{detailInfo.lymc && detailInfo.lcmc ? `${detailInfo.lymc}/${detailInfo.lcmc}` : ''}</div>
          </div>
          <div className='listStyl' style={{ background: '#fff', marginBottom: '6px', padding: '12px 16px' }}>
            <div className="left">所属部门</div>
            <div className="right">{detailInfo.bmmc}</div>
          </div>
          <div className='listStyl' style={{ background: '#fff', marginBottom: '6px', padding: '12px 16px' }}>
            <div className="left">负责人</div>
            <div className="right">{detailInfo.fzr_name}</div>
          </div>
          <div className='listStyl' style={{ background: '#fff', marginBottom: '6px', padding: '12px 16px' }}>
            <div className="left">安全负责人</div>
            <div className="right">{detailInfo.aqfzr}</div>
          </div>
          <div className='listStyl' style={{ background: '#fff', marginBottom: '6px', padding: '12px 16px' }}>
            <div className="left">容纳人数</div>
            <div className="right">{detailInfo.rnrs}</div>
          </div>
          <div className='listStyl' style={{ background: '#fff', marginBottom: '6px', padding: '12px 16px' }}>
            <div className="left">面积</div>
            <div className="right">{detailInfo.mj}</div>
          </div>
          <div className='listStyl' style={{ background: '#fff', marginBottom: '6px', padding: '12px 16px' }}>
            <div className="left">设立时间</div>
            <div className="right">{moment(detailInfo.establish_time).format('YYYY-MM-DD')}</div>
          </div>

        </div>
        <div className='room_report_but'>
          <Button className='green-bg-button' onClick={() => {
            this.props.history.push(`/module/patrol/create_report/${this.props.match.params.id}`)
          }}>隐患上报</Button>
          {this.state.status === true ? <Button className='green-bg-button' style={{ marginTop: '12px' }} onClick={() => {
            this.props.history.push(`/module/patrol/create_my_check/${this.props.match.params.id}`)
          }}>自查</Button> : ''}
        </div>
      </div>
    )
  }
}
export default PatrolDetail