import React, { Component } from 'react'
import { Toast } from 'antd-mobile'
import { findCardInfoById, kgCardBrand } from '../../../api/api'

class BanpaiDetail extends Component {

  state = {
    data: {},
    wt: 265,
  }

  componentDidMount = () => {
    this.fetch(this.props.match.params.id)
    this.setState({
      wt: document.body.clientWidth - 110
    })
  }

  fetch = async cardNumber => {
    const res = await findCardInfoById({ cardNumber })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  // 执行开关机
  handleChangeSwitch = async () => {
    const { data } = this.state
    const res = await kgCardBrand({ cardNumbers: data.card_number, state: data.boot_state })
    if (res.code === 20000) {
      if (res.data && res.data.length) { 
        Toast.info(res.data[0])
      } else {
        this.fetch(this.props.match.params.id)
      }
    } else if (res.code === 20001) {
      Toast.info(res.message)
    } else {
      Toast.hide()
    }
  }

  // 刷新状态
  handleSx = () => {
    this.fetch(this.props.match.params.id)
  }

  // 开关机配置
  handleTimingConfig = () => {
    const { data } = this.state
    this.props.history.push(`/module/banpai/openConfig/${data.device_room_id}`)
  }

  render () {
    const { data, wt } = this.state
    return (
      <div className={`banpai_detail ${data.net_state === 1 ? 'banpai_detail_online' : 'banpai_detail_offline'}`}>
        <div className='banpai_detail_img'>
          <img alt='' src={require('../../../images/bpxq.png')} />
        </div>
        <div className='banpai_detail_main'>
          <div className='banpai_detail_con'>
            <div className='banpai_detail_con_left' style={{ width: `${wt}px` }}>
              <div className='banpai_detail_con_left_name'>{data.card_name}</div>
              <div className='banpai_detail_con_left_addr'>
                {data.device_building_name}/{data.device_floor_name}/{data.device_room_name}
              </div>
              <div className='banpai_detail_con_left_desc'>
                <div className={`banpai_detail_con_left_status ${data.net_state === 1 ? 'banpai_detail_status_online' : 'banpai_detail_status_offline'}`}>
                  <span></span>
                  <span>{data.net_state === 1 ? '设备在线' : '设备离线'}</span>
                </div>
                {
                  data.boot_state === 1 ? <div className='banpai_detail_con_left_desc_con'>已开启</div> : ''
                }
              </div>
            </div>
            {
              data.net_state === 1 ? <div className='banpai_detail_con_img' onClick={() => this.handleChangeSwitch()}>
                {
                  data.boot_state === 1 ? <img alt='' src={require('../../../images/kai.png')} /> : <img alt='' src={require('../../../images/guan.png')} />
                }
              </div> : <div></div>
            }
            {
              data.net_state === 0 ? <div className='banpai_detail_con_sx' onClick={() => this.handleSx()}>
                <img alt='' src={require('../../../images/sx.png')} />
                <span>刷新状态</span>
              </div> : ''
            }
          </div>
          <div className='banpai_detail_operation' onClick={() => this.handleTimingConfig()}>
            <div className='banpai_detail_operation_left'>
              <img alt='' src={require('../../../images/kgjpz.png')} />
              <span>开关机配置</span>
            </div>
            <img alt='' src={require('../../../images/more2.png')} />
          </div>
        </div>
      </div>
    )
  }
}

export default BanpaiDetail
