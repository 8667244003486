import React, { Component } from 'react'
import { Toast, TextareaItem } from 'antd-mobile'
import { req_verifyHiddenDangers } from '../../../api/api'
import { req_getDetailsById, req_completeZdHidden, uploadDisposeFile } from '../../../api/repairApi'
import IP from './../../../config/config'
import moment from 'moment'

class ApproveDetail extends Component {

  state = {
    data: {},
    visible: false,
    opinion: '',
    fileUrl: '',
    type: 1,
    disposalContent: '',//隐患描述
    fileInfo: [],//上传附件
  }

  componentDidMount = () => {
    document.title = "隐患详情"
    this.fetch(this.props.match.params.id)
  }

  fetch = async detailsId => {
    const res = await req_getDetailsById({ id: detailsId })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }

  // 审核成功
  handleSuccess = async (result) => {
    const { data, disposalContent, fileInfo } = this.state
    if (result === 1) {
      if (disposalContent === '') {
        return Toast.info('请输入整改方案！')
      }
      if (fileInfo.length === 0) {
        return Toast.info('请上传附件！')
      }
    }

    const res = await req_completeZdHidden({
      detailsId: data.details_id,
      result,
      disposalContent,
      fileInfo: JSON.stringify(fileInfo)
    })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.props.history.go(-1)
    }
  }

  // 放大图片或者视频
  handleImageClick = (fileUrl, type) => {
    this.setState({
      visible: true,
      fileUrl,
      type,
    })
  }

  // 确认隐患
  handVerifyHiddenDangers = async (state) => {
    const { data } = this.state
    const ids = data.details_id
    const res = await req_verifyHiddenDangers({ ids, state })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.fetch(this.props.match.params.id)
      setTimeout(() => {
        this.props.history.go(-1)
      }, 3000);
    }
  }

  // 上传文件
  onChangeFile = async (e) => {
    const { fileInfo } = this.state
    const { files } = e.target
    Toast.loading("附件上传中,请稍等", 0)
    const imgSize = files[files.length - 1].size
    const type = files[files.length - 1].type
    if (type.split('/')[0] !== 'image') return Toast.info('请上传图片', 2)
    if (imgSize > 20 * 1024 * 1024) return Toast.info('请上传小于20M的附件', 2)
    let formData = new FormData()
    formData.append("multipartFile", files[files.length - 1])
    const res = await uploadDisposeFile(formData)
    if (res.code === 20000) {
      Toast.hide()
      Toast.info("上传成功", 1)
      fileInfo.push({ file_name: files[files.length - 1].name, file_url: res.data })
      this.setState({ fileInfo })
    } else {
      Toast.hide()
      Toast.fail(res.message)
    }
  }

  render() {
    const { data, disposalContent, fileInfo } = this.state
    return (
      <div className='approveDetail' style={{ padding: '0px' }}>
        <div className='approve_detail_head'>
          <div className='approve_detail_left'>
            <div className='approve_detail_left_title'>
              {data.room_name}   {data.class_name ? '（' + data.class_name + '）' : ''}
            </div>
            <div className='approve_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='approve_list_head_date'>
                {data.building_name}{data.floor_name}
              </div>
            </div>
          </div>
          <div className='approve_detail_right hidden_handle_type'>
            {data.hidden_result === 1 ? (<img src={require('./../../../img/patrol/待处理.png')} alt='' />) : ('')}
            {data.hidden_result === 2 ? (<img src={require('./../../../img/patrol/已处理.png')} alt='' />) : ('')}
            {data.hidden_result === 4 ? (<img src={require('./../../../img/patrol/未处理.png')} alt='' />) : ('')}
          </div>
        </div>
        <div className='approve_detail_cont'>
          <div className='approve_detail_bar' style={{ padding: '0px' }}>
            <div className={data.task_level === 1 ? 'patrol_type_xiao' : data.task_level === 2 ? 'patrol_type_yuan' : ''} style={{ padding: '12px 16px 0px' }}>
              {data.dept_name ? (<div className='approve_detail_item patrol_approve_detail_item'>
                <div>所属院系</div>
                <div>{data.dept_name}</div>
              </div>) : ''}
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>隐患发现人</div>
                <div>{data.user_name}</div>
              </div>
            </div>

          </div>

          <div className='approve_detail_bar' style={{ padding: '0px' }}>
            <div className={data.task_level === 1 ? 'patrol_type_xiao' : data.task_level === 2 ? 'patrol_type_yuan' : ''} style={{ padding: '12px 16px 0px' }}>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>整改状态</div>
                <div>
                  {data.hidden_result === -2 ? '不属实隐患' : ''}
                  {data.hidden_result === -1 ? '待核实隐患' : ''}
                  {data.hidden_result === 0 ? '待整改' : ''}
                  {data.hidden_result === 1 ? '已整改待确认' : ''}
                  {data.hidden_result === 2 ? '整改完成' : ''}
                  {data.hidden_result === 3 ? '整改不合格' : ''}
                  {data.hidden_result === 4 ? '暂无法整改' : ''}
                  {data.hidden_result === 5 ? '隐患撤回' : ''}
                </div>
              </div>
              {data.hidden_result > -1 ? (<div className='approve_detail_item patrol_approve_detail_item'>
                <div>隐患是否属实</div>
                <div>
                  <div><span className='round-label green'>属实</span></div>
                </div>
              </div>) : ''}


            </div>

          </div>


          <div className='patrol_danger' style={{ marginTop: '12px' }}>
            <div className='patrol_danger_head'>
              <div>
                <img src={require('./../../../images/patrol/6.png')} alt='' />
                整改前
              </div>
              <div>
                {moment(data.complete_time).format('YYYY-MM-DD HH:mm')} 提交
              </div>
            </div>
            <div className='patrol_danger_main'>
              <div className='patrol_danger_item'>
                <div className='patrol_danger_desc'>
                  {data.details_content}
                </div>
                <div className='patrol_danger_pic'>
                  {data && data.hiddenFileList && data.hiddenFileList.map(item => {
                    return item.file_type === 1 ? (<img src={IP.fileHost + item.file_url} alt='' style={{ height: '72px', marginRight: '12px', marginBottom: '12px' }} onClick={() => {
                      this.handleImageClick(item.file_url, 1)
                    }} key={item.file_id} />) : (<video height="72px" style={{ marginRight: '12px', marginBottom: '12px' }} src={IP.fileHost + item.file_url} poster={IP.fileHost + item.file_url} controls="controls" controlslist="nodownload noremoteplayback" disablePictureInPicture key={item.file_id}>
                      your browser does not support the video tag
                    </video>)
                  })}
                </div>
              </div>
            </div>
          </div>





          {data.hidden_result === 2 ? (<div className='approve_detail_bar'>
            <div className='approve_detail_textarea'>
              <div>整改方案</div>
              <div>{data.dispose_content}</div>
            </div>

            <div className='approve_detail_textarea approve_detail_textarea2'>
              <div>方案图片</div>
              {data && data.disposeFileList && data.disposeFileList.map(item => {
                return (
                  <img src={IP.fileHost + item.file_url} alt='' key={item.file_id} style={{ height: '40px' }} onClick={() => {
                    this.setState({
                      visible: true,
                      type: 1,
                      fileUrl: item.file_url
                    })
                  }} />
                )
              })}

            </div>

          </div>) : ''}

          {data.hidden_result === 0 || data.hidden_result === 3 || data.hidden_result === 4 ? (<div className='approve_detail_bar' style={{ padding: '12px 16px 84px' }}>
            <div className='approve_detail_textarea'>
              <div><span style={{ color: 'red' }}>* </span>整改方案</div>
              <div className='approve_detail_textareaItem'>
                <TextareaItem
                  rows={3}
                  placeholder="请输入整改方案"
                  value={disposalContent}
                  onChange={e => this.setState({ disposalContent: e })}
                />
              </div>
            </div>
            {/* 只重大隐患上传附件 */}
            <div className='approve_detail_upload_file'>
              <div className='approve_detail_upload'>
                <div><span style={{ color: 'red' }}>* </span>上传图片</div>
                <div onClick={() => {
                  document.getElementById('handle_file').click()
                }}>上传</div>
              </div>
              <div className='approve_detail_tips'>
                请上传整改方案相关图片，图片可上传多个。
              </div>
              {
                fileInfo.length ? (<div className='approve_detail_file'>
                  {
                    fileInfo.map((item, key) => {
                      return (
                        <div className='approve_detail_textarea_file' key={key}>
                          <div>
                            <img src={require('./../../../img/file_type.png')} alt='' />
                            {item.file_name}
                          </div>
                          <div className='approve_detail_textarea_file_del'>
                            <img src={require('./../../../img/patrol/del.png')} alt='' onClick={() => {
                              this.setState({
                                fileInfo: fileInfo.filter((x, y) => y !== key)
                              })
                            }} />
                          </div>
                        </div>
                      )
                    })
                  }

                </div>) : ('')
              }

            </div>
          </div>) : ''}




        </div>

        {
          data.hidden_result === 0 || data.hidden_result === 3 || data.hidden_result === 4 ? <div className='approveDetail_btn'>
            <div className='approveDetail_btn_list' onClick={() => this.handleSuccess(2)}>暂不整改</div>
            <div className='approveDetail_btn_list' onClick={() => this.handleSuccess(1)}>提交整改</div>
          </div> : ''
        }

        <input type='file' id='handle_file' accept="image/*" onChange={e => this.onChangeFile(e)} hidden onClick={(event) => { event.target.value = null }} />

        {
          this.state.visible ? <div className='detailImg' onClick={() => this.setState({ visible: false })}>
            {this.state.type === 1 ? (<img alt='' src={IP.fileHost + this.state.fileUrl} style={{ width: '80%' }} />) : (<video width="80%" src={IP.fileHost + this.state.fileUrl} poster={IP.fileHost + this.state.fileUrl} controls="controls" controlslist="nodownload noremoteplayback" disablePictureInPicture>
              your browser does not support the video tag
            </video>)}

          </div> : ''
        }

        {
          data.hidden_result === -1 ? <div className='approveDetail_btn'>
            <div className='approveDetail_btn_list' onClick={() => this.handVerifyHiddenDangers(2)}>隐患误报</div>
            <div className='approveDetail_btn_list' onClick={() => this.handVerifyHiddenDangers(1)}>隐患属实</div>
          </div> : ''
        }
      </div>
    )
  }
}

export default ApproveDetail
