import React, { Component } from 'react'
import cookie from 'react-cookies'
import ResourceTer from './resource_ter'
import ResourceStu from './resource_stu'

class Resources extends Component {

  componentDidMount = () => {

  }

  render() { 
    return (
      <div>
        {
          cookie.load('sf') == 1 ? <ResourceTer this={this} /> : <ResourceStu this={this} />
        }
      </div>
    )
  }
}

export default Resources