import React, { Component } from 'react'
import { Toast } from 'antd-mobile'
import { findNoticeInfoById } from '../../api/api'
import moment from 'moment'

class NoticeDetail extends Component {

  state = {
    data: {}
  }

  componentDidMount = () => {
    Toast.loading('', 0)
    this.fetch(this.props.match.params.id)
  }

  fetch = async noticeId => {
    const res = await findNoticeInfoById({ noticeId })
    if (res.code === 20000) {
      Toast.hide()
      this.setState({
        data: res.data
      })
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className='notice_detail'>
        <div className='notice_detail_title'>{data.notice_title}</div>
        <div className='notice_detail_time'>{moment(data.release_time).format('YYYY-MM-DD')}</div>
        <div className='notice_detail_con' dangerouslySetInnerHTML={{ __html: data.notice_content }}></div>
      </div>
    )
  }
}

export default NoticeDetail