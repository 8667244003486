const IP = {
  // host: 'http://172.22.171.149:7710', // 本地服务器
  // host: 'http://172.22.54.48:7710', // 黄
  // host: 'http://192.168.31.114:7710', // 苗
  // host: 'http://49.4.95.232:7710',
  host: 'http://znmk.sastc.cn:7710', // 线上
  fileHost: 'http://znmk.sastc.cn:7710/file', // 文件地址
  appId: 'wx098b8d00c963e212',
  wxredirect: 'http://xiaowai.sastc.cn',
}
export default IP
