import React, { Component } from 'react'
import { DatePicker, List, Toast } from 'antd-mobile'
import { req_findCmdRecord } from '../../../api/api'
import moment from 'moment'
import './operation_log.less'

class operationLog extends Component {

  state = {
    start_date: '',
    end_date: '',
    airSwitchImei: '',
    data: [],
  }

  componentDidMount = () => {
    Toast.loading('', 0)
    document.title = "智慧用电-操作记录"
    this.setState({
      start_date: new Date(this.fun_date(-2)),
      end_date: new Date(),
      airSwitchImei: this.props.match.params.id,
    }, () => {
      this.fetch()
    })
  }

  fetch = async () => {
    const { airSwitchImei, start_date, end_date } = this.state
    const res = await req_findCmdRecord({ airSwitchImei, startTime: start_date ? moment(start_date).format('YYYY-MM-DD') : '', endTime: end_date ? moment(end_date).format('YYYY-MM-DD') : '' })
    if (res.code === 20000) {
      Toast.hide()
      this.setState({
        data: res.data
      })
    }
  }

  // 获取几天前的日期
  fun_date = (num) => {
    var date1 = new Date();
    var date2 = new Date(date1);
    date2.setDate(date1.getDate() + num);
    //num是正数表示之后的时间，num负数表示之前的时间，0表示今天
    var time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();
    return time2;
  }

  render() {
    const { start_date, end_date, data } = this.state
    return (
      <div className='operation_log'>
        <div className='operation_log_head'>
          <div className='operation_log_head_date'>
            <DatePicker
              mode="date"
              title="开始时间"
              extra="开始时间"
              value={start_date}
              maxDate={end_date ? end_date : null}
              onChange={date => {
                this.setState({
                  start_date: date
                }, () => {
                  this.fetch()
                })
              }}
            >
              <List.Item arrow="horizontal"></List.Item>
            </DatePicker>
            <div style={{ background: '#f4f6f8' }}>~</div>
            <DatePicker
              mode="date"
              title="结束时间"
              extra="结束时间"
              value={end_date}
              minDate={start_date ? start_date : null}
              onChange={date => {
                this.setState({
                  end_date: date
                }, () => {
                  this.fetch()
                })
              }}
            >
              <List.Item arrow="horizontal"></List.Item>
            </DatePicker>
          </div>
        </div>
        <div className='operation_log_body'>
          {
            (data || []).length ? <div className='operation_log_list'>
              {
                data.map(item => {
                  return (
                    <div className='operation_log_item' key={item.record_id}>
                      <div>{item.operate_cmd === "CMD_FZ" ? '关' : '开'}</div>
                      <div>{item.operate_user_name}</div>
                      <div>{moment(item.operate_time).format('YYYY-MM-DD HH:mm:ss')}</div>
                    </div>
                  )
                })
              }
            </div> : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
      </div>
    )
  }
}

export default operationLog
