import React, { Component } from 'react'
import { DatePicker } from 'antd-mobile'
import { statisticsFbqk, statisticsYhfjByTime, statisticsYhlxByTime } from '../../../api/patrol'
import ReactEcharts from 'echarts-for-react'
import moment from 'moment'
import downImg from '../../../img/sj.png'
import './statistics.less'
const color = ['#08CEDF', '#FFC34E', '#F76964', '#00C2FF', '#FFDB68', '#7B83EA']
class SpecialState extends Component {

  state = {
    campusOrBuilding: 2, // 校区楼宇选中状态
    weakCurrentData: [], // 弱电间
    patrolRoomData: {}, // 隐患房间
    startDateOneValue: '', // 隐患房间开始时间
    endDateOneValue: '', // 隐患房间结束时间
    hiddenDangerTypeData: [], // 隐患类型
    startDateTwoValue: '', // 隐患类型开始时间
    endDateTwoValue: '', // 隐患类型结束时间
  }

  componentDidMount = () => {
    const start = new Date(this.fun_date(-180))
    const end = new Date()
    this.setState({
      startDateOneValue: start,
      endDateOneValue: end,
      startDateTwoValue: start,
      endDateTwoValue: end,
    }, () => {
      this._statisticsFbqk()
      this._statisticsYhfjByTime()
      this._statisticsYhlxByTime()
    })
  }

  // 校区楼宇切换
  getCampusORBuilding = async val => {
    this.setState({
      campusOrBuilding: val
    }, () => {
      this._statisticsFbqk()
    })
  }

  // 弱电间分布
  _statisticsFbqk = async () => {
    const { campusOrBuilding } = this.state
    const res = await statisticsFbqk({ flag: campusOrBuilding, type: 2 })
    if (res.code === 20000) {
      this.setState({
        weakCurrentData: res.data.map(item => {
          item.name = item.spaceName + '' + item.num
          item.value = item.num
          return item
        })
      })
    }
  }

  // 隐患房间
  _statisticsYhfjByTime = async () => {
    const { startDateOneValue, endDateOneValue } = this.state
    const res = await statisticsYhfjByTime({ startTime: moment(startDateOneValue).format('YYYY-MM-DD'), endTime: moment(endDateOneValue).format('YYYY-MM-DD'), type: 2 })
    if (res.code === 20000) {
      this.setState({
        patrolRoomData: res.data
      })
    }
  }

  // 隐患类型
  _statisticsYhlxByTime = async () => {
    const { startDateTwoValue, endDateTwoValue } = this.state
    const res = await statisticsYhlxByTime({ startTime: moment(startDateTwoValue).format('YYYY-MM-DD'), endTime: moment(endDateTwoValue).format('YYYY-MM-DD'), type: 2 })
    if (res.code === 20000) {
      this.setState({
        hiddenDangerTypeData: res.data.map(p => {
          return {
            name: p.type_name,
            value: p.num || 0
          }
        })
      })
    }
  }

  // 获取几天前的日期
  fun_date = (num) => {
    var date1 = new Date()
    var date2 = new Date(date1)
    date2.setDate(date1.getDate() + num)
    //num是正数表示之后的时间，num负数表示之前的时间，0表示今天
    var time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate()
    return time2
  }

  render() {
    const { campusOrBuilding, weakCurrentData, startDateOneValue, endDateOneValue, startDateTwoValue, endDateTwoValue, patrolRoomData, hiddenDangerTypeData } = this.state
    const CustomChildren = ({ extra, onClick, children }) => (
      <div
        onClick={onClick}
        style={{ display: 'flex', alignItems: 'center', background: '#F5F5F5', padding: '4px 8px', borderRadius: '4px' }}
      >
        {children}
        <span>{extra}</span>
        <img src={downImg} alt="" />
      </div>
    )
    const option1 = {
      title: {
        text: weakCurrentData.reduce((prev, cur) => { return prev + cur.value }, 0),    // 主标题
        top: '39%',
        left: '29%',
        textStyle: {
          textAlign: 'center',
          color: '#292C33',
          fontSize: 20,
        },
        itemGap: 4,   // 主副标题间隔
        subtext: '总数(间)',   // 副标题
        subtextStyle: {
          textAlign: 'center',
          color: '#666666',
          fontSize: 12,

        },
        textAlign: 'center',
      },
      color: color,
      legend: {
        //纵向
        orient: 'vertical',
        x: '60%', //可设定图例在左、右、居中
        y: 'center', //可设定图例在上、下、居中
        icon: "circle",   //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 8, // 设置间距
        selectedMode: false, // 禁止点击
        textStyle: {
          color: '#666',
          fontSize: 12,
        }
      },
      series: [{
        name: '弱电间数量',
        type: 'pie',
        radius: ['60%', '80%'],
        center: ["30%", "50%"],
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        hoverAnimation: false, // 取消放大
        label: { // 饼图图形上的文本标签
          normal: {
            show: false,
            textStyle: {
              fontWeight: 700,
              fontSize: 13 // 文字的字体大小
            },
            formatter: '{b}',
          }
        },
        data: weakCurrentData
      }]
    }
    const option2 = {
      title: {
        text: (patrolRoomData.ybNum || 0) + (patrolRoomData.yzNum || 0),    // 主标题
        top: '32%',
        left: '49%',
        textStyle: {
          textAlign: 'center',
          color: '#292C33',
          fontSize: 20,
        },
        itemGap: 4,   // 主副标题间隔
        subtext: '总数(间)',   // 副标题
        subtextStyle: {
          textAlign: 'center',
          color: '#666666',
          fontSize: 12,

        },
        textAlign: 'center',
      },
      color: color,
      legend: {
        //纵向
        // orient: 'horizontal',
        x: 'center', //可设定图例在左、右、居中
        y: 'bottom', //可设定图例在上、下、居中
        icon: "circle",   //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 10, // 设置间距
        selectedMode: false, // 禁止点击
        textStyle: {
          color: '#666',
          fontSize: 12,
        }
      },
      series: [{
        name: '隐患房间数量',
        type: 'pie',
        radius: ['50%', '70%'],
        center: ["50%", "42%"],
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        hoverAnimation: false, // 取消放大
        labelLine: {
          length: 10
        },
        width: '100%',
        label: {
          alignTo: 'edge',
          margin: 0,
          edgeDistance: 0,
          // 饼图图形上的文本标签
          formatter: '{name|{b}} {num|{c} 间}\n {zhanbi|占比} {per|{d} %}',
          rich: {
            name: {
              fontSize: 12,
              color: '#999',
              padding: [6, 0],
            },
            zhanbi: {
              fontSize: 12,
              color: '#999',
              padding: [6, 0],
            },
            num: {
              fontSize: 12,
              fontWeight: 'bold',
              color: '#666',
            },
            per: {
              fontSize: 12,
              fontWeight: 'bold',
              color: '#292C33',
            },
          }
        },
        data: [
          { value: patrolRoomData.ybNum || 0, name: '一般隐患' },
          { value: patrolRoomData.yzNum || 0, name: '严重隐患' },
        ]
      }]
    }
    const option3 = {
      color: color,
      legend: {
        //纵向
        // orient: 'horizontal',
        x: 'center', //可设定图例在左、右、居中
        y: 'bottom', //可设定图例在上、下、居中
        icon: "circle",   //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 10, // 设置间距
        selectedMode: false, // 禁止点击
        textStyle: {
          color: '#666',
          fontSize: 12,
        }
      },
      series: [{
        name: '隐患类型占比',
        type: 'pie',
        radius: [0, '70%'],
        center: ["50%", "42%"],
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        hoverAnimation: false, // 取消放大
        labelLine: {
          length: 10
        },
        label: {
          // 饼图图形上的文本标签
          formatter: '{name|{b}} {num|{c} 间}\n {zhanbi|占比} {per|{d} %}',
          rich: {
            name: {
              fontSize: 12,
              color: '#999',
              padding: [6, 0],
            },
            zhanbi: {
              fontSize: 12,
              color: '#999',
              padding: [6, 0],
            },
            num: {
              fontSize: 12,
              fontWeight: 'bold',
              color: '#666'
            },
            per: {
              fontSize: 12,
              fontWeight: 'bold',
              color: '#292C33'
            },
          }
        },
        data: hiddenDangerTypeData
      }]
    }
    return (
      <div className='statistics patrol_stat'>
        <div className="ev_stat">
          <div className="stat_title">
            <div className="left">弱电间分布统计</div>
            <div className='right stat_card_radio'>
              <div className={campusOrBuilding === 2 ? 'stat_card_radio_checked' : ''} onClick={() => this.getCampusORBuilding(2)}>校区</div>
              <div className={campusOrBuilding === 1 ? 'stat_card_radio_checked' : ''} onClick={() => this.getCampusORBuilding(1)}>楼宇</div>
            </div>
          </div>
          <ReactEcharts option={option1} style={{ height: 170 }} />
        </div>
        <div className="ev_stat">
          <div className="stat_title">
            <div className="left">隐患房间统计</div>
            <div className="right">
              <div className='export'>导出</div>
            </div>
          </div>
          <div className='datePickerStyl'>
            <DatePicker
              mode="date"
              title="开始日期"
              extra="开始日期"
              value={startDateOneValue}
              maxDate={endDateOneValue ? endDateOneValue : null}
              onChange={date => {
                this.setState({
                  startDateOneValue: date
                }, () => {
                  this._statisticsYhfjByTime()
                })
              }}
            >
              <CustomChildren></CustomChildren>
            </DatePicker>
            <div className='mg-8'>至</div>
            <DatePicker
              mode="date"
              title="结束日期"
              extra="结束日期"
              value={endDateOneValue}
              minDate={startDateOneValue ? startDateOneValue : null}
              onChange={date => {
                this.setState({
                  endDateOneValue: date
                }, () => {
                  this._statisticsYhfjByTime()
                })
              }}
            >
              <CustomChildren></CustomChildren>
            </DatePicker>
          </div>
          <ReactEcharts option={option2} style={{ height: 190, width: '100%' }} />
        </div>
        <div className="ev_stat">
          <div className="stat_title">
            <div className="left">隐患类型占比</div>
          </div>
          <div className='datePickerStyl'>
            <DatePicker
              mode="date"
              title="开始日期"
              extra="开始日期"
              value={startDateTwoValue}
              maxDate={endDateTwoValue ? endDateTwoValue : null}
              onChange={date => {
                this.setState({
                  startDateTwoValue: date
                }, () => {
                  this._statisticsYhlxByTime()
                })
              }}
            >
              <CustomChildren></CustomChildren>
            </DatePicker>
            <div className='mg-8'>至</div>
            <DatePicker
              mode="date"
              title="结束日期"
              extra="结束日期"
              value={endDateTwoValue}
              minDate={startDateTwoValue ? startDateTwoValue : null}
              onChange={date => {
                this.setState({
                  endDateTwoValue: date
                }, () => {
                  this._statisticsYhlxByTime()
                })
              }}
            >
              <CustomChildren></CustomChildren>
            </DatePicker>
          </div>
          <ReactEcharts option={option3} style={{ height: 190 }} />
        </div>
      </div >
    )
  }
}
export default SpecialState