import http from '../config/httpconfig'


/**
 * 分页查询所有数据
 */
export const req_selectAll = params => http.get('/system/equipmentUseRecord/selectAll', params)

/**
 * 新增数据
 */
export const req_insert = params => http.jsonPost('/system/equipmentUseRecord/insert', params)


/**
 * 新增数据
 */
export const req_update = params => http.jsonPost('/system/equipmentUseRecord/update', params)


/**
 * 删除数据
 */
export const req_delete = params => http.get('/system/equipmentUseRecord/delete', params)

/**
 * 查询该实验室本学期所有课程
 */
export const req_findCourseRecord = params => http.get('/system/courseScheduleRecord/findCourseRecord', params)

/**
 * 查询已认领设备
 */
export const req_listEquipment = params => http.get('/system/equipmentInfo/listEquipment', params)

/**
 * 所属学院
 */
export const req_listAllDepartmentInfo = params => http.get('/system/departmentinfo/listAllDepartmentInfo', params)

/**
 * 查询我的使用记录
 */
export const req_listUserRecord = params => http.get('/system/equipmentUseRecord/listUserRecord', params)

/**
 * 通过主键查询单条数据
 */
export const req_selectOne = params => http.get('/system/equipmentUseRecord/selectOne', params)