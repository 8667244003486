import React, { Component } from 'react'
import { Modal } from 'antd-mobile'
import { req_selectOne } from '../../../../api/gas'
import moment from 'moment'

class deviceDetail extends Component {

  state = {
    data: {},
    visible: false,
    opinion: '',
    sourceData: [
      {
        label: '校级',
        value: 1,
      },
      {
        label: '院级',
        value: 2,
      },
      {
        label: '赠送',
        value: 3,
      },
      {
        label: '其他',
        value: 4,
      },
    ],
  }

  componentDidMount = () => {
    document.title = '钢瓶详情'
    window.scrollTo(0, 0)
    this.fetch(this.props.match.params.id)
  }

  fetch = async id => {
    const res = await req_selectOne({ id })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }


  render() {
    const { data } = this.state
    return (
      <div className='approveDetail' style={{ padding: '0px 0px 76px' }}>
        <div className='approve_detail_head'>
          <div className='approve_detail_left'>
            <div className='approve_detail_left_title'>
              {data.bottle_number}
            </div>
            <div className='approve_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='approve_list_head_seat'>{data.bottle_type}</div>
              <div className='approve_list_head_seat'>{data.bottle_color}</div>
              <div className='approve_list_head_seat'>{data.bottle_specs}</div>
              <div className='approve_list_head_seat'>{data.bottle_source ? this.state.sourceData.find(x => x.value === data.bottle_source).label : ''}</div>
            </div>
          </div>
          <div className='approve_detail_right'>
          </div>
        </div>
        <div className='approve_detail_cont'>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>是否有气体</div>
              <div>{data.gas_number > 0 ? '有气体' : '空瓶'}</div>
            </div>
            <div className='approve_detail_item'>
              <div>气体分类</div>
              <div>{data.gas_type_name}</div>
            </div>
            <div className='approve_detail_item'>
              <div>气体</div>
              <div>{data.gas_name}</div>
            </div>
            <div className='approve_detail_item'>
              <div>状态</div>
              <div>{data.state === 1 ? '在库' : '已出库'}</div>
            </div>
            <div className='approve_detail_item'>
              <div>容量</div>
              <div>{data.bottle_capacity}L</div>
            </div>
            <div className='approve_detail_item'>
              <div>气量</div>
              <div>{data.gas_number}L</div>
            </div>
          </div>

          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>所属部门</div>
              <div>{data.dept_name}</div>
            </div>
            <div className='approve_detail_item'>
              <div>位置信息</div>
              <div>{data.lymc + '/' + data.lcmc + '/' + data.room_name}</div>
            </div>
            <div className='approve_detail_item'>
              <div>充装气体日期</div>
              <div>{moment(data.bottle_aerate_time).format('YYYY-MM-DD')}</div>
            </div>
          </div>




          {/* <div className='approve_manager_order'>
            <div className='approve_manager_order_title'>
              审批流程
            </div>
            <div className='approve_manager_order_cont'>
              {
                data && data.approvalProcessRecordEntities && data.approvalProcessRecordEntities.map((item, key) => {
                  return (
                    <div className='approve_manager_order_item' key={key}>
                      <div className='approve_manager_order_item_left'>
                        <div className='approve_manager_order_item_icon'>
                          {item.pr_approval_result === 0 ? (<img src={require('./../../../../images/chemicals/12.png')} alt='' />) : ''}
                          {item.pr_approval_result === 1 ? (<img src={require('./../../../../images/chemicals/11.png')} alt='' />) : ''}
                          {item.pr_approval_result === 2 ? (<img src={require('./../../../../images/chemicals/10.png')} alt='' />) : ''}

                        </div>
                        <div className='approve_manager_order_item_line'>

                        </div>
                      </div>
                      <div className='approve_manager_order_item_right'>
                        <div className='approve_manager_order_item_name'>
                          <div>
                            {item.pr_approval_username ? item.pr_approval_username + '(' + item.pr_auth_name + ')' : item.pr_auth_name}
                          </div>
                          <div>{item.pr_approval_result !== 0 ? moment(item.pr_submit_time).format('YYYY-MM-DD HH:mm') : ''}</div>
                        </div>
                        {item.pr_approval_result === 0 ? (<div className='approve_manager_status'>
                          未审批
                        </div>) : ''}
                        {item.pr_approval_result === 1 ? (<div className='approve_manager_status_wait'>
                          待审批
                        </div>) : ''}
                        {item.pr_approval_result === 2 ? (<div className='approve_manager_status_pass_through'>
                          已审批
                        </div>) : ''}
                      </div>
                    </div>
                  )
                })
              }

            </div>
          </div> */}
        </div>

      </div>
    )
  }
}

export default deviceDetail