import React, { Component } from 'react'
import { findMyDevicesubRecord } from '../../../api/api'
import moment from 'moment'
import './index.less'

class DeviceRecord extends Component {

  state = {
    statusData: [
      { id: 0, name: '待审核', checked: true },
      { id: 1, name: '已审核', checked: false },
    ],
    status: 0,
    data: [],
  }

  componentDidMount = () => {
    this.fetch('0')
  }

  fetch = async subscribeStatus => {
    const res = await findMyDevicesubRecord({ subscribeStatus })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  // 切换状态
  handleStatus = item => {
    const { statusData } = this.state
    this.setState({
      statusData: statusData.map(x => {
        x.checked = x.id === item.id ? true : false
        return x
      })
    })
    this.fetch(item.id)
  }

  getweekday = (date) => {
    var weekArray = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
    var week = weekArray[new Date(date).getDay()];
    return week;
  }

  render() {
    const { statusData, data } = this.state
    return (
      <div className='appointmentRecord'>
        <div className='approve_header riskReport'>
          <div className='approve_header_config'>
            <div className='approve_header_con'>
              {
                statusData.map(item => {
                  return <div className={`approve_header_con_list ${item.checked ? 'approve_header_con_list_active' : ''}`} key={item.id} onClick={() => this.handleStatus(item)}>{item.name}</div>
                })
              }

            </div>
          </div>
        </div>
        <div className='appointmentRecord_con'>
          {
            (data || []).length ? data.map(item => {
              return <div className='approve_con_list' key={item.record_id} onClick={() => this.props.history.push(`/module/deviceRecord/detail/${item.record_id}`)}>
                <div className='approve_list_head_student'>
                  <div className='approve_list_head_title'>
                    {item.reservationist}
                  </div>
                  <div>
                    {item.reservationist_number}
                  </div>
                </div>
                <div className='approve_list_head_desc' style={{ paddingBottom: '0px' }}>
                  <div className='approve_list_head_date'>
                    {item.dep_name}
                  </div>
                </div>
                <div className='approve_list_head' style={{ paddingTop: '12px' }}>
                  <div>
                    {item.equipment_name}
                    {/* {item.yxmc}-{item.zymc} */}
                  </div>
                </div>




              </div>
            }) : ('')
          }
          {
            (data || []).length === 0 ? <div className='not-yet'>--暂无数据--</div> : ''
          }
          {/* {
            (data || []).length ? data.map(item => {
              return <div className='appointmentRecord_con_list' key={item.record_id} onClick={() => this.props.history.push(`/module/deviceRecord/detail/${item.record_id}`)}>
                <div className='appointmentRecord_con_list_title'>
                  <div className='appointmentRecord_con_list_title_name'>{item.room_name}</div>
                  <div className={`appointmentRecord_con_list_title_name_status ${item.subscribe_status === 2 ? 'status_success' : item.subscribe_status === 3 ? 'status_error' : 'status_wait'}`}>
                    {
                      item.subscribe_status === 2 ? '成功' : item.subscribe_status === 3 ? '失败' : item.subscribe_status === 4 ? '已取消' : item.subscribe_status === 1 ? '审批中' : '待审核'
                    }
                  </div>
                </div>
                <div className='appointmentRecord_con_list_desc'>
                  <div className='appointmentRecord_con_list_desc_list'>
                    {item.date_time} {this.getweekday(item.date_time)} {item.startTimes}-{item.endTimes}
                  </div>
                </div>
                <div className='appointmentRecord_con_list_con'>
                  <div className='appointmentRecord_con_list_con_item'>
                    <span>姓&nbsp;&nbsp;名</span>
                    <span>{item.reservationist}</span>
                  </div>
                  <div className='appointmentRecord_con_list_con_item'>
                    <span>学&nbsp;&nbsp;号</span>
                    <span>{item.reservationist_number}</span>
                  </div>
                  <div className='appointmentRecord_con_list_con_item'>
                    <span>性&nbsp;&nbsp;别</span>
                    <span>{item.reservationist_sex === 1 ? '男' : '女'}</span>
                  </div>
                  {
                    item.yxmc ? <div className='appointmentRecord_con_list_con_item'>
                      <span>院&nbsp;&nbsp;系</span>
                      <span>{item.yxmc}</span>
                    </div> : <div className='appointmentRecord_con_list_con_item'>
                      <span>部&nbsp;&nbsp;门</span>
                      <span>{item.dep_name}</span>
                    </div>
                  }
                  {
                    item.zymc ? <div className='appointmentRecord_con_list_con_item'>
                      <span>专&nbsp;&nbsp;业</span>
                      <span>{item.zymc}</span>
                    </div> : ''
                  }
                  {
                    item.yxmc ? <div className='appointmentRecord_con_list_con_item'>
                      <span>班&nbsp;&nbsp;级</span>
                      <span>{item.dep_name}</span>
                    </div> : ''
                  }
                </div>
                <div className='appointmentRecord_con_list_time'>
                  提交时间：{item.submit_time ? moment(item.submit_time).format('YYYY/MM/DD HH:mm') : moment(new Date()).format('YYYY/MM/DD HH:mm')}
                </div>
              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          } */}
        </div>
      </div>
    )
  }
}

export default DeviceRecord