import React, { Component } from 'react'
import { Toast, TextareaItem, Modal } from 'antd-mobile'
import { Radio, InputNumber } from 'antd'
import { Checkbox } from "antd"
import { Popup } from 'react-vant'
import { findSubEquipmentById, devicesubRecord, devicesubDate, submitSubscribedevicesub, listDictionary, findCnsByEquipmentId } from '../../../api/api'
import moment from 'moment'
import IP from '../../../config/config'
import $ from 'jquery'
import './index.less'

class AppoDetail extends Component {

  state = {
    deviceData: {},
    startTime: moment(new Date()).format('YYYY-MM-DD'),
    endTime: moment(new Date()).format('YYYY-MM-DD'),
    equipmentId: '',
    xqData: [],
    data: [],
    flattenData: [], // 扁平化数组
    selectedTime: [], // 已选中
    wt: 236,
    widths: 40,
    visible: false,
    ssytData: [], // 实验用途
    syyt_id: '',
    yszfz: '', // 实验目的
    symd: '', // 实验目的
    synr: '', // 实验内容
    syyq: '', // 实验要求
    luVisible: false,
    checked: false,
    width: window.innerWidth,
    height: window.innerHeight,
    base64: '',
    undertakData: {},
    signVisible: false,
  }

  componentDidMount = () => {
    this._findCnsByEquipmentId(this.props.match.params.id)
    this.fetchDetail(this.props.match.params.id)
    this.fetch(this.props.match.params.id)
    this.fetchXq(this.props.match.params.id)
    this.setState({
      equipmentId: this.props.match.params.id,
      wt: document.body.clientWidth - 139
    })
    this.tablesWidth()
    this.fetchSyyt()
  }

  _findCnsByEquipmentId = async (equipmentId) => {
    const res = await findCnsByEquipmentId({ equipmentId })
    if (res.code === 20000) {
      this.setState({
        undertakData: res.data
      })
    }
  }

  // table表格最小宽度样式设置
  tablesWidth = () => {
    setTimeout(() => {
      if (document.getElementById("tables")) {
        var a = document.getElementById("tables").offsetWidth;
        var list = $(".table_title");
        if (list.length > 1) {
          for (var i = 0; i < list.length; i++) {
            list[i].style.width = Math.floor((a / 8)) + "px"
          }
        }
        this.setState({
          widths: Math.floor((a / 8)) - 3
        })
      }
    }, 10)
  }

  // 查询实验用途
  fetchSyyt = async () => {
    const res = await listDictionary({ ZDLX: 'yy-syyt' })
    if (res.code === 20000) {
      this.setState({
        ssytData: res.data
      })
    }
  }

  // 获取设备详情
  fetchDetail = async equipmentId => {
    const res = await findSubEquipmentById({ equipmentId })
    if (res.code === 20000) {
      this.setState({
        deviceData: res.data
      })
    }
  }

  // 获取数据
  fetch = async (equipmentId, startDay, flag) => {
    const res = await devicesubRecord({ equipmentId, startDay, flag })
    if (res.code === 20000 && res.data) {
      this.setState({
        data: res.data || [],
        flattenData: this.flatten(res.data || []),
        startTime: res.data[0].length ? res.data[0][0].date : moment(new Date()).format('YYYY-MM-DD'),
        endTime: res.data[0].length ? res.data[0][res.data[0].length - 1].date : moment(new Date()).format('YYYY-MM-DD'),
      })
    } else {
      Toast.info('暂无预约权限！')
    }
  }

  // 获取星期
  fetchXq = async (equipmentId, startDate, flag) => {
    const res = await devicesubDate({ equipmentId, startDate, flag })
    if (res.code === 20000) {
      this.setState({
        xqData: res.data
      })
    }
  }

  // 上一周 flag 2
  handleLastWeek = () => {
    const { startTime, equipmentId } = this.state
    this.fetch(equipmentId, startTime, 2)
    this.fetchXq(equipmentId, startTime, 2)
  }

  // 下一周 flag 1
  handleNextWeek = () => {
    const { startTime, equipmentId } = this.state
    this.fetch(equipmentId, startTime, 1)
    this.fetchXq(equipmentId, startTime, 1)
  }

  // 数组扁平化
  flatten = (arr) => {
    // 递归实现数组扁平化
    let result = [];
    for (let i = 0; i < arr.length; i++) {
      if (Array.isArray(arr[i])) {
        result = result.concat(this.flatten(arr[i]));
      } else {
        result.push(arr[i]);
      }
    }
    return result;
  }

  handleAppointment = item => {
    const { selectedTime } = this.state
    if (selectedTime.find(x => x.count === item.count)) {
      if ((selectedTime[0].node === item.node) || selectedTime[selectedTime.length - 1].node === item.node) {
        this.setState({
          selectedTime: selectedTime.filter((t => t.count !== item.count))
        })
      } else {
        Toast.info('请按顺序取消预约时间段！')
      }
    } else {
      if (selectedTime.length) {
        const obj1 = selectedTime[0]
        const obj2 = selectedTime[selectedTime.length - 1]
        if (item.date !== selectedTime[0].date) {
          Toast.info('请选择同一天时间段！')
        } else if (item.node === (obj1.node - 1)) {
          selectedTime.unshift(item)
        } else if (item.node === (obj2.node + 1)) {
          selectedTime.push(item)
        } else {
          Toast.info('请选择相邻时间段！')
        }
        this.setState({
          selectedTime
        })
      } else {
        this.setState({
          selectedTime: selectedTime.concat([item])
        })
      }
    }
  }

  handleNoAppointment = () => {
    Toast.info('该时间段不可预约！')
  }

  //打开签字弹窗
  handleSignModal = async () => {
    const { yszfz, syyt_id, symd, synr, syyq } = this.state
    if (!yszfz) return Toast.info('请输入延时分钟！')
    if (!syyt_id) return Toast.info('请选择实验用途！')
    if (!symd) return Toast.info('请输入实验目的！')
    if (!synr) return Toast.info('请输入实验内容！')
    if (!syyq) return Toast.info('请输入实验要求！')
    this.setState({
      luVisible: true
    })
  }

  // 点击签字
  goSign = () => {
    this.setState({
      signVisible: true
    })
    setTimeout(() => {
      eval("PageReady()")
    }, 500)
  }

  // 清除签字
  handleClearBtnClick = () => {
    eval('clearCanvas()')
  }

  // 签字确认
  handleOk = () => {
    const data = eval('saveSignature()')
    this.setState({
      signVisible: false
    })
    this.rotateBase64Img(data, 270)
  }

  //将canvas图片转为base64图片
  rotateBase64Img = (src, edg, callback) => {
    const This = this
    var canvas = document.createElement("canvas")
    var ctx = canvas.getContext("2d")
    var imgW//图片宽度
    var imgH//图片高度
    var size//canvas初始大小
    if (edg % 90 != 0) {
      throw '旋转角度必须是90的倍数!'
    }
    (edg < 0) && (edg = (edg % 360) + 360)
    const quadrant = (edg / 90) % 4 //旋转象限
    const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 } //裁剪坐标
    var image = new Image()
    image.crossOrigin = "anonymous"
    image.src = src
    image.onload = function () {
      imgW = image.width
      imgH = image.height
      size = imgW > imgH ? imgW : imgH
      canvas.width = size * 2
      canvas.height = size * 2
      switch (quadrant) {
        case 0:
          cutCoor.sx = size
          cutCoor.sy = size
          cutCoor.ex = size + imgW
          cutCoor.ey = size + imgH
          break
        case 1:
          cutCoor.sx = size - imgH
          cutCoor.sy = size
          cutCoor.ex = size
          cutCoor.ey = size + imgW
          break
        case 2:
          cutCoor.sx = size - imgW
          cutCoor.sy = size - imgH
          cutCoor.ex = size
          cutCoor.ey = size
          break
        case 3:
          cutCoor.sx = size
          cutCoor.sy = size - imgW
          cutCoor.ex = size + imgH
          cutCoor.ey = size + imgW
          break
      }
      ctx.translate(size, size)
      ctx.rotate(edg * Math.PI / 180)
      ctx.drawImage(image, 0, 0)
      var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey)
      if (quadrant % 2 == 0) {
        canvas.width = imgW
        canvas.height = imgH
      } else {
        canvas.width = imgH
        canvas.height = imgW
      }
      ctx.putImageData(imgData, 0, 0)

      //获取旋转后的base64图片
      This.setState({
        base64: canvas.toDataURL(),
      })
    }
  }

  //重签
  reSign = () => {
    this.setState({
      base64: '',
      visible: true,
    })
    setTimeout(() => {
      eval("PageReady()")
    }, 500)
  }

  // 提交预约
  handleSubmit = async () => {
    const { selectedTime, equipmentId, syyt_id, ssytData, symd, synr, syyq, base64, undertakData, yszfz, deviceData } = this.state
    const selectTimes = selectedTime.map(x => {
      return {
        start_time: x.time.split('-')[0],
        end_time: x.time.split('-')[1],
        date_time: x.date,
        node: x.node,
      }
    })
    if (!yszfz) return Toast.info('请输入延时分钟！')
    if (!syyt_id) return Toast.info('请选择实验用途！')
    if (!symd) return Toast.info('请输入实验目的！')
    if (!synr) return Toast.info('请输入实验内容！')
    if (!syyq) return Toast.info('请输入实验要求！')
    const res = await submitSubscribedevicesub({
      cns: undertakData,
      qz: base64,
      equipment_id: equipmentId,
      timeInfo: JSON.stringify(selectTimes),
      syyt_id,
      syyt_name: syyt_id ? ssytData.find(x => x.id === syyt_id).zdmc : '',
      symd,
      synr,
      syyq,
      yszfz,
      info_id: deviceData.info_id
    })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.setState({
        visible: false,
        luVisible: false,
        seatNumber: '',
        syyt_id: '',
        symd: '',
        synr: '',
        syyq: '',
        selectedTime: [],
      })
      this.fetch(equipmentId)
      this.fetchXq(equipmentId)
    }
  }

  getweekday = (date) => {
    var weekArray = new Array("周日", "周一", "周二", "周三", "周四", "周五", "周六");
    var week = weekArray[new Date(date).getDay()];
    return week;
  }

  render() {
    const { wt, deviceData, startTime, endTime, xqData, data, selectedTime, widths, visible, syyt_id, ssytData, symd, synr, syyq, luVisible, undertakData, signVisible, width, height, yszfz } = this.state
    return (
      <div className='appoDetail'>
        <div className='appoDetail_info'>
          <div className='appoDetail_info_l' style={{ width: `${wt}px` }}>
            <div className='appoDetail_info_l_name'>{deviceData.equipment_name}</div>
            <div className='appoDetail_info_l_addr'>{deviceData.room_name}</div>
            <div className='appoDetail_info_l_type'>
              <div className='appoDetail_info_l_type_fl'>{deviceData.yqflmc}</div>
              <div className='appoDetail_info_l_type_bh'>{deviceData.equipment_number}</div>
            </div>
          </div>
          {
            deviceData.imageurl ? <img alt='' src={IP.fileHost + deviceData.imageurl} /> : <img alt='' src={require('../../../images/008.png')} />
          }
        </div>
        <div className='appoDetail_change'>
          <div className='appoDetail_change_one' onClick={() => data.length ? this.handleLastWeek() : {}}>上一周</div>
          <div className='appoDetail_change_two'>{startTime} 至 {endTime}</div>
          <div className='appoDetail_change_one' onClick={() => data.length ? this.handleNextWeek() : {}}>下一周</div>
        </div>
        <div className='appoDetail_con' id='tables'>
          <table border='1' width="100%" bordercolor="#EDEFF2">
            <thead>
              <tr>
                <th className='table_title table_th_desc' style={{ width: `${widths}px` }}>时间</th>
                {
                  (xqData || []).map((item, i) => {
                    return <th className='table_title table_th_week' key={i + 'C'} style={{ width: `${widths}px` }}>
                      <div>周{item && item.split('/')[1]}</div>
                      <div>{item && item.split('/')[0]}日</div>
                    </th>
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                (data || []).map((item, i) => {
                  return <tr key={i + 'B'}>
                    <td className='table_title table_jc' style={{ width: `${widths}px` }}>{item && item[0] ? item[0].time.slice(0, 5) : ''}</td>
                    {
                      (item || []).map((item1, key) => {
                        return <td key={key + 'A'} onClick={() => item1.flag === '1' ? this.handleAppointment(item1) : this.handleNoAppointment()} className={`table_title ${item1.flag === '0' ? 'appoDetail_no' : selectedTime.find(t => t.count === item1.count) ? 'appoDetail_at' : ''}`}></td>
                      })
                    }
                  </tr>
                })
              }
            </tbody>
          </table>
        </div>
        {
          selectedTime && selectedTime.length ? <div className='shortTerm_footer'>
            <div className='shortTerm_footer_time'>
              <div>已选择</div>
              <div>{selectedTime[0].date.substr(5, 5)} {this.getweekday(selectedTime[0].date)} {selectedTime[0].time.substr(0, 5)}-{selectedTime[selectedTime.length - 1].time.substr(6, 5)}时段</div>
            </div>
            <div className='shortTerm_footer_btn' onClick={() => this.setState({ visible: true })}>提交预约</div>
          </div> : ''
        }
        <Popup
          visible={visible}
          position='bottom'
          onClose={() => {
            this.setState({
              yszfz: '',
              syyt_id: '',
              symd: '',
              synr: '',
              syyq: '',
              visible: false,
            })
          }}
          title="预约信息"
          overlay
          round={true}
          closeable={true}
          isLink
        >
          <div className='appointment_modal_con'>
            <div className='appointment_modal_user'>
              <div className='appointment_modal_user_title'>实验用途</div>
              <div className='appointment_modal_con_radio'>
                <Radio.Group buttonStyle="solid" value={syyt_id} onChange={e => this.setState({ syyt_id: e.target.value })}>
                  {
                    (ssytData || []).map(item_ => {
                      return <div className='appointment_modal_seat_con_list' key={'C' + item_.id}>
                        <Radio value={item_.id}>{item_.zdmc}</Radio>
                      </div>
                    })
                  }
                </Radio.Group>
              </div>
            </div>
            <div className='appointment_modal_user appointment_modal_input'>
              <div className='appointment_modal_user_title'>延时分钟</div>
              <InputNumber
                value={yszfz}
                placeholder="请输入延时分钟"
                min={0}
                onChange={val => this.setState({ yszfz: val })}
              />
            </div>
            <div className='appointment_modal_user'>
              <div className='appointment_modal_user_title'>实验目的</div>
              <TextareaItem
                rows={4}
                value={symd}
                placeholder="请输入实验目的~"
                onChange={val => this.setState({ symd: val })}
              />
            </div>
            <div className='appointment_modal_user'>
              <div className='appointment_modal_user_title'>实验内容</div>
              <TextareaItem
                rows={4}
                value={synr}
                placeholder="请输入实验内容~"
                onChange={val => this.setState({ synr: val })}
              />
            </div>
            <div className='appointment_modal_user'>
              <div className='appointment_modal_user_title'>实验要求</div>
              <TextareaItem
                rows={4}
                value={syyq}
                placeholder="请输入实验要求~"
                onChange={val => this.setState({ syyq: val })}
              />
            </div>
          </div>
          <div className='appointment_popup'>
            <div className='appointment_popup_btn' onClick={() => {
              this.handleSignModal()
            }}>确定</div>
          </div>
        </Popup>

        {/* 责任书 */}

        <Modal
          popup
          visible={luVisible}
          onClose={() => {
            this.setState({
              luVisible: false,
              base64: '',
              checked: false
            })
          }}
          animationType="slide-up"
          className='device_modal'
        >
          <div className='exam_lu'>
            <div className='exam_lu_title'>
              请仔细阅读《安全责任书》并签字
            </div>
            <div className='exam_lu_desc' dangerouslySetInnerHTML={{ __html: undertakData }}>
            </div>

            <div className='exam_lu_checkbox'>
              <Checkbox onChange={(e) => {
                this.setState({
                  checked: e.target.checked
                })
              }}>我已阅读并同意以下责任书</Checkbox>

            </div>
            {this.state.base64 ? <div>
              <img src={this.state.base64} alt="" height='100px' />
            </div> : ''}

            {this.state.base64 ? <div className='exam_lu_sub_bg' onClick={() => {
              this.handleSubmit()
            }}>
              提交预约
            </div> : <div className='exam_lu_sub' onClick={() => {
              if (!this.state.checked) {
                return Toast.info("请先勾选同意责任书！")
              }
              this.goSign()
            }}>
              签字确认
            </div>}
          </div>
        </Modal>

        <Modal
          visible={signVisible}
          transparent
          onClose={() => this.setState({ signVisible: false })}
          style={{ width: '100%', height: '100%', background: '#fff' }}
        >
          <div>
            <section className="signature-component" >
              <div id='signature-pad' className="js-signature" data-width={width * 0.7} data-height={height * 0.8} data-border="1px solid black" data-line-color="#000" data-auto-fit="true"></div>
            </section>
            <div className='sign_btn' style={{ height: `${height * 0.2 - 60}px` }}>
              <div className='approveDetail_btn_list' style={{ width: `${height * 0.2 - 60}px` }} onClick={() => this.handleClearBtnClick()}>清除</div>
              <div className='approveDetail_btn_list' style={{ width: `${height * 0.2 - 60}px` }} onClick={() => this.handleOk()}>确认</div>
            </div>
          </div>
        </Modal>


      </div>
    )
  }
}

export default AppoDetail
