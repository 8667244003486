import React, { Component } from 'react'
import { Picker, List, TextareaItem, Toast, InputItem } from 'antd-mobile';
import { gasTypeList, gasInfoInsert } from './../../../../api/gas'

class Add extends Component {
  state = {
    gas_name: '',
    gas_formula: '',
    gas_type_id: '',
    gas_appearance: '',
    gas_melt: '',
    gas_boiling: '',
    typeData: []
  }

  componentDidMount = () => {
    document.title = '新增气体'
    window.scrollTo(0, 0)
    this._gasTypeList()
  }

  // 查询学院/部门
  _gasTypeList = async () => {
    const res = await gasTypeList()
    if (res.code === 20000) {
      this.setState({
        typeData: res.data.map(item => {
          return {
            label: item.type_name,
            value: item.type_id
          }
        })
      })
    }
  }

  // 提交
  submit = async () => {
    let {
      gas_name,
      gas_formula,
      gas_type_id,
      gas_appearance,
      gas_melt,
      gas_boiling,
      typeData
    } = this.state

    if (gas_name === '' || gas_formula === '' || gas_type_id === '' || gas_appearance === '' || gas_melt === '' || gas_boiling === '') {
      Toast.info('请补全上述内容！')
      return false
    }
    gas_type_id = gas_type_id.join(',')
    const params = {
      gas_name,
      gas_formula,
      gas_type_id,
      gas_appearance,
      gas_melt,
      gas_boiling,
      gas_type_name: typeData.find(x => String(x.value) === gas_type_id).label
    }
    const res = await gasInfoInsert(params)
    if (res.code === 20000) {
      Toast.info("提交成功！")
      setTimeout(() => {
        this.props.history.goBack()
      }, 2000);
    }
  }

  render() {
    const { gas_name, gas_formula, gas_type_id, gas_appearance, gas_melt, gas_boiling, typeData } = this.state
    return (
      <div className='gas_manage_add'>
        <div className='gas_manage_add_title'>

        </div>
        <div className='gas_manage_add_form'>
          <div className='gas_manage_add_input'>
            <InputItem placeholder="请输入" value={gas_name} onChange={(e) => {
              this.setState({
                gas_name: e
              })
            }}><span style={{ color: 'red' }}>* </span>气体名称</InputItem>
          </div>
          <div className='gas_manage_add_input'>
            <InputItem placeholder="请输入" value={gas_formula} onChange={(e) => {
              this.setState({
                gas_formula: e
              })
            }}><span style={{ color: 'red' }}>* </span>化学式</InputItem>
          </div>
        </div>
        <div className='gas_manage_add_form'>
          <div className='gas_manage_add_picker'>
            <Picker
              data={typeData}
              value={gas_type_id}
              cols={1}
              onChange={(e) => {
                this.setState({
                  gas_type_id: e
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>气体分类</List.Item>
            </Picker>
          </div>
        </div>
        <div className='gas_manage_add_form'>
          <div className='gas_manage_add_input'>
            <InputItem placeholder="请输入" value={gas_melt} onChange={(e) => {
              this.setState({
                gas_melt: e
              })
            }}><span style={{ color: 'red' }}>* </span>熔点</InputItem>
          </div>
          <div className='gas_manage_add_input'>
            <InputItem placeholder="请输入" value={gas_boiling} onChange={(e) => {
              this.setState({
                gas_boiling: e
              })
            }}><span style={{ color: 'red' }}>* </span>沸点</InputItem>
          </div>
        </div>
        <div className='gas_manage_add_form'>
          <div className='subscription_add_text'>
            <div><span style={{ color: 'red' }}>* </span>外观</div>
            <TextareaItem
              placeholder="请输入"
              rows={5}
              value={gas_appearance}
              onChange={(e) => {
                this.setState({
                  gas_appearance: e
                })
              }}
            />
          </div>

        </div>


        <div className='gas_manage_list_but gas_manage_list_but_two'>
          <div onClick={() => {
            window.history.go(-1);
          }}>取消</div>
          <div onClick={() => {
            this.submit()
          }}>确定</div>
        </div>

      </div >
    )
  }
}

export default Add
