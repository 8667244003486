import React, { Component } from 'react'
import { ListView } from 'antd-mobile'
import { findJFPaiHang } from '../../../api/safty'
import IP from '../../../config/config'
const ds = new ListView.DataSource({
  rowHasChanged: (r1, r2) => r1 !== r2
})
export default class componentName extends Component {
  constructor() {
    super()
    this.state = {
      page: 1, //页码
      size: 20, // 每页条数
      totalPage: 0, //总页数
      dataSource: ds, //长列表加载数据源
      billData: [],  //每页数据
      preBillData: [], //原始的订单列表
      allBillData: [], //原始的+每一页新的数据
      isLoading: false,  //是否加载中
      isHasMore: false, //是否还有更多数据
      flag: null,
      myScore: {}
    }
  }
  componentDidMount () {
    this.fetch()
  }

  fetch = async () => {
    const { preBillData, page, size, flag } = this.state
    let res = await findJFPaiHang({ page, size, flag })
    if (res.code === 20000) {
      let allBillData = [...preBillData, ...res.data.integrateVo.list]
      this.setState({
        billData: res.data,
        preBillData: allBillData,
        allBillData: allBillData,
        totalPage: Math.ceil(res.data.integrateVo.total / size),
        dataSource: this.state.dataSource.cloneWithRows(allBillData),
        isHasMore: res.data.integrateVo.hasNextPage,
        myScore: res.data.my
      })
    }
  }
  onEndReached = () => {
    const { page, totalPage } = this.state
    //当前已加载的条数小于total总条数 请求下一页数据，否则停止请求数据
    if ((Number(page)) < Number(totalPage)) {
      this.setState({
        isLoading: true,
        page: page + 1
      }, () => {
        this.fetch()
      })
    } else {
      this.setState({
        isLoading: false,
        isHasMore: false
      })
    }
  }
  transPaiMing = (sort) => {
    if (sort < 4) {
      return <img src={require(`../../../images/safety_exam/sort_${sort}.png`)} width={22} />
    } else if (sort >= 4 && sort < 10) {
      return "0" + sort
    } else {
      return sort
    }
  }
  render () {
    const { dataSource, allBillData, size, billData, isLoading, isHasMore, flag, myScore } = this.state
    // 渲染每一行数据
    const row = (rowData, sectionID, rowID) => {
      return (
        <div className='ev_row_body'>
          <div className='text-center'>{this.transPaiMing(Number(rowID) + 1)}</div>
          <div>
            {/* <img src={rowData.imgurl ? IP.fileHost + rowData.imgurl : require('../../../images/safety_exam/default_head_logo.png')} alt="" /> */}
            {rowData.xm}
          </div>
          <div>{rowData.totalJf ? Math.round(rowData.totalJf) : 0}分</div>
        </div>
      )
    }
    return (
      <div className='saftyModule jf jiaoyu_jf'>
        <div className='content_wrap'>
          <div className="floor">
            <div className="flex_b title">
              <p>排行榜</p>
              <div className='qiehuan_condition'>
                <span className={flag ? 'selected' : ''} onClick={() => {
                  this.setState({
                    flag: 1,
                    page: 1,
                    totalPage: 0,
                    dataSource: ds, //长列表加载数据源
                    billData: [],  //每页数据
                    preBillData: [], //原始的订单列表
                    allBillData: [], //原始的+每一页新的数据
                  }, () => {
                    this.fetch()
                  })
                }}>当前学年</span>
                <span className={!flag ? 'selected' : ''} onClick={() => {
                  this.setState({
                    flag: null,
                    page: 1,
                    totalPage: 0,
                    dataSource: ds, //长列表加载数据源
                    billData: [],  //每页数据
                    preBillData: [], //原始的订单列表
                    allBillData: [], //原始的+每一页新的数据
                  }, () => {
                    this.fetch()
                  })
                }}>所有学年</span>
              </div>
            </div>
            {allBillData.length > 0 ? <ListView
              ref={el => this.lv = el}
              dataSource={dataSource}
              renderFooter={() => (<div style={{ paddingTop: 5, textAlign: 'center', fontSize: '14px' }}>
                {
                  isHasMore && (isLoading ? <span className='list_loading'>加载中...</span> : '')
                }
                {
                  billData.length != 0 && (!isHasMore && '没有更多数据了')
                }
              </div>)}
              renderHeader={() => <div className='ev_row_head'>
                <div className='text-center'>排名</div>
                <div>姓名</div>
                <div>积分</div>
              </div>}
              renderRow={row} //每行数据渲染
              style={{
                height: 'calc(100vh - 260px)', //高度需要定义
                overflow: 'auto',
              }}
              pageSize={size}  //一次渲染几条数据
              onEndReached={this.onEndReached}
              onEndReachedThreshold={20}
            /> : <div className='not-yet'>--暂无数据--</div>}
          </div>
        </div>
        <div className='flex_b fixed-bottom'>
          <div className='flex_b'>
            {/* <img src={require('../../../images/safety_exam/default_head_logo.png')} alt="" /> */}
            <div>
              <div className='name'>{myScore.xm}</div>
              <div className='sort_num'>{myScore.rank ? `第${myScore.rank}名` : '暂无排名'}</div>
            </div>
          </div>
          <div className='right'>{myScore.totalJf ? Math.round(myScore.totalJf) : '--'}分</div>
        </div>
      </div>
    )
  }
}
