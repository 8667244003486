import React, { Component } from 'react'
import { SearchBar, Pagination, Icon, } from 'antd-mobile'
import { findPageZyInfo } from './../../../../api/gas'
import moment from 'moment'
import './list.less'

class List extends Component {
  state = {
    paramKey: '',
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    total: 0,
    data: [],
    tabs_id: 1,
  }

  componentDidMount = () => {
    document.title = '共享转移'
    window.scrollTo(0, 0)
    this.fetch()
  }

  fetch = async () => {
    const { pageSize, pageNum, paramKey } = this.state
    const res = await findPageZyInfo({ size: pageSize, page: pageNum, paramKey })
    if (res.code === 20000) {
      this.setState({
        data: res.data.info.list,
        pages: res.data.info.info ? (res.data.info.pages ? res.data.info.pages : 1) : 1,
        total: res.data.info.total,
      })
    }
  }

  render() {
    const { data, tabs_id } = this.state
    return (
      <div className='gas_manage_list'>
        <div className='gas_manage_list_head_top gas_manage_list_head_manage' style={{ padding: '12px' }}>
          <SearchBar placeholder="请输入转移单号" value={this.state.paramKey} onCancel={(e) => {
            this.setState({
              paramKey: ''
            }, () => {
              this.fetch()
            })
          }} onChange={e => {
            this.setState({
              paramKey: e
            }, () => {
              this.fetch()
            })
          }} />

        </div>

        <div className='sha_list_record' style={{ padding: '74px 12px 82px' }}>
          {data.map((item, key) => {
            return (
              <div className='sha_list_item' key={key} onClick={() => {
                // this.props.history.push(`/module/gasCylinder/shaRecord/${item.zybh}`)
              }}>
                <div className='sha_item_head'>
                  <div>{item.zybh}</div>
                  <div className='sha_item_tag'>钢瓶数{item.whpzlsl}</div>
                </div>
                {/* <div className='sha_item_gas'>
                  <div></div>
                </div> */}
                <div className='sha_item_date'>
                  {item.zysqsj ? moment(item.zysqsj).format('YYYY-MM-DD HH:mm:ss') : ''}
                </div>
                <div className='sha_line'>
                </div>
                <div className='sha_item_cont'>
                  <div className='sha_item_card'>
                    <div>使用人<span>{item.zysqrgh}</span></div>
                    <div>当前部门<span>{item.xymc}</span></div>
                    <div>当前中心<span>{item.zxmc}</span></div>
                    <div>当前位置<span>{item.jsmc}</span></div>
                    <img src={require('./../../../../images/gas/09.png')} alt='' />
                  </div>
                  <div className='sha_item_card_icon'>
                    <img src={require('./../../../../images/gas/08.png')} alt='' />
                  </div>
                  <div className='sha_item_card'>
                    <div>使用人<span>{item.jsrmc}</span></div>
                    <div>当前部门<span>{item.jsxymc}</span></div>
                    <div>当前中心<span>{item.jszxmc}</span></div>
                    <div>当前位置<span>{item.jsjsmc}</span></div>
                    <img src={require('./../../../../images/gas/07.png')} alt='' />
                  </div>
                </div>
              </div>
            )
          })}

          {this.state.data.length === 0 ? <div className='not-yet'>--暂无数据--</div> : ''}

          {
            this.state.total > 20 ? (<div className='subscription_manager_page'>
              <Pagination total={this.state.pages}
                current={this.state.pageNum}
                onChange={e => {
                  this.setState({
                    pageNum: e
                  }, () => {
                    window.scrollTo(0, 0)
                    this.fetch()
                  })
                }}
                locale={{
                  prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                  nextText: (<span className="arrow-align"><Icon type="right" /></span>),
                }}
              />
            </div>) : ''
          }

        </div>

        <div className='gas_manage_list_but'>
          <div onClick={() => {
            this.props.history.push('/module/gasCylinder/shaAdd')
          }}>新增共享气瓶</div>
        </div>
      </div>
    )
  }
}

export default List
