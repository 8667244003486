import React, { Component } from 'react'
import { findMyInfo, req_findMyPaperUndertakRecord, req_addUndertakRecord } from './../../../api/api'
import { findAllPaper2 } from './../../../api/repairApi'
import IP from './../../../config/config'
import { Toast, Modal } from 'antd-mobile'
import { Checkbox } from "antd"
import ReactHtmlParser from 'react-html-parser'
import CountDownSelf from './countdown'
import moment from 'moment'
import './exam.less'

export default class exam extends Component {
  state = {
    userInfo: {},
    userXxqk: {},
    btnStatus: false,
    paperList: [],
    curIdx: 0,
    paperData: {},
    luVisible: false,
    checked: false,
    width: window.innerWidth,
    height: window.innerHeight,
    visible: false,
    base64: '',
    undertakData: {}
  }


  componentDidMount = () => {
    document.title = "安全考试"
    this.fetchInfo()
    this.fetch()
  }

  // 查询个人信息
  fetchInfo = async () => {
    const res = await findMyInfo()
    if (res.code === 20000) {
      this.setState({
        userInfo: res.data || {}
      })
    }
  }

  fetch = async () => {
    const res = await findAllPaper2()
    if (res.code === 20000) {
      this.setState({
        paperList: res.data && res.data.paperUserClientVos.sort((x, y) => x.start_time - y.start_time),
        paperData: res.data && res.data.paperUserClientVos && res.data.paperUserClientVos.length ? (res.data.paperUserClientVos.sort((x, y) => x.start_time - y.start_time))[0] : {},
        userXxqk: res.data ? res.data.xxqk : {}
      })
    }
  }

  _findMyPaperUndertakRecord = async () => {
    const { paperData } = this.state
    const res = await req_findMyPaperUndertakRecord({ paperId: paperData.paper_id })
    if (res.code === 20000) {
      this.setState({
        undertakData: res.data ? res.data : {}
      }, () => {
        this.goExam()
      })
    }
  }

  goExam = () => {
    const { undertakData, paperData, btnStatus } = this.state
    if (JSON.stringify(undertakData) !== '{}') {
      this.setState({
        luVisible: true
      })
    } else {
      if (btnStatus) {
        this.props.history.push(`/module/safty/single/${paperData.paper_id}`)
      }
    }
  }

  prevPager = () => {
    const { curIdx, paperList } = this.state
    let newCurIdx = curIdx - 1
    if (newCurIdx === -1) {
      Toast.info('已返回第一套试卷了！')
      return false
    }
    Toast.loading('正在加载中...', 0)
    this.setState({
      curIdx: newCurIdx,
      paperData: paperList[newCurIdx] || {},
    }, () => {
      if (this.line) {
        this.line.clearTimer()
        this.line.showDayPanel()
      }
    })
    setTimeout(() => {
      Toast.hide()
    }, 1000)
  }

  nextPager = () => {
    const { curIdx, paperList } = this.state
    let newCurIdx = curIdx + 1
    if (newCurIdx >= paperList.length) {
      Toast.info('已经最后一套试卷了！')
      return false
    }
    Toast.loading('正在加载中...', 0)
    this.setState({
      curIdx: newCurIdx,
      paperData: paperList[newCurIdx] || {},
    }, () => {
      if (this.line) {
        this.line.clearTimer()
        this.line.showDayPanel()
      }
    })
    setTimeout(() => {
      Toast.hide()
    }, 1000)
  }

  toStand = () => {
    const { userXxqk, paperData } = this.state
    let result = (userXxqk.xxsc >= paperData.learing_astrict) &&
      (userXxqk.totalCs >= paperData.exercise_astrict) &&
      (userXxqk.totalTs >= paperData.problems_astrict) &&
      (
        (!paperData.require_learned) || (paperData.require_learned && (userXxqk.bxk === 0))
      )
    return result
  }

  overCallBack = (status) => {
    this.setState({
      btnStatus: status
    })
  }

  // 点击签字
  goSign = () => {
    this.setState({
      visible: true
    })
    setTimeout(() => {
      eval("PageReady()")
    }, 500)
  }
  // 清除签字
  handleClearBtnClick = () => {
    eval('clearCanvas()')
  }

  // 签字确认
  handleOk = () => {
    const data = eval('saveSignature()')
    this.setState({
      visible: false
    })
    this.rotateBase64Img(data, 270)
  }

  //将canvas图片转为base64图片
  rotateBase64Img = (src, edg, callback) => {
    const This = this
    var canvas = document.createElement("canvas")
    var ctx = canvas.getContext("2d")
    var imgW//图片宽度
    var imgH//图片高度
    var size//canvas初始大小
    if (edg % 90 != 0) {
      throw '旋转角度必须是90的倍数!'
    }
    (edg < 0) && (edg = (edg % 360) + 360)
    const quadrant = (edg / 90) % 4 //旋转象限
    const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 } //裁剪坐标
    var image = new Image()
    image.crossOrigin = "anonymous"
    image.src = src
    image.onload = function () {
      imgW = image.width
      imgH = image.height
      size = imgW > imgH ? imgW : imgH
      canvas.width = size * 2
      canvas.height = size * 2
      switch (quadrant) {
        case 0:
          cutCoor.sx = size
          cutCoor.sy = size
          cutCoor.ex = size + imgW
          cutCoor.ey = size + imgH
          break
        case 1:
          cutCoor.sx = size - imgH
          cutCoor.sy = size
          cutCoor.ex = size
          cutCoor.ey = size + imgW
          break
        case 2:
          cutCoor.sx = size - imgW
          cutCoor.sy = size - imgH
          cutCoor.ex = size
          cutCoor.ey = size
          break
        case 3:
          cutCoor.sx = size
          cutCoor.sy = size - imgW
          cutCoor.ex = size + imgH
          cutCoor.ey = size + imgW
          break
      }
      ctx.translate(size, size)
      ctx.rotate(edg * Math.PI / 180)
      ctx.drawImage(image, 0, 0)
      var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey)
      if (quadrant % 2 == 0) {
        canvas.width = imgW
        canvas.height = imgH
      } else {
        canvas.width = imgH
        canvas.height = imgW
      }
      ctx.putImageData(imgData, 0, 0)

      //获取旋转后的base64图片
      This.setState({
        base64: canvas.toDataURL(),
      })
    }
  }

  //重签
  reSign = () => {
    this.setState({
      base64: '',
      visible: true,
    })
    setTimeout(() => {
      eval("PageReady()")
    }, 500)
  }

  submit = async () => {
    if (!this.state.checked) {
      return Toast.info("请先勾选同意承诺书！")
    }
    const { paperData, undertakData, base64, btnStatus } = this.state
    const res = await req_addUndertakRecord({
      paperId: paperData.paper_id,
      undertakId: undertakData.undertak_id,
      signUrl: base64
    })
    if (res.code === 20000) {
      if (btnStatus) {
        this.props.history.push(`/module/safty/single/${paperData.paper_id}`)
      }
    }
  }

  render () {
    const { btnStatus, paperData, userXxqk, userInfo, visible, width, height, luVisible, undertakData } = this.state
    return (
      <div className='saftyExam'>
        <div className='saftyExam_head'>
          <img src={require('./../../../images/safty/01.png')} alt='' />
        </div>
        <div className='saftyExam_main'>
          <div className='saftyExam_info'>
            <div className='saftyExam_icon'>
              {userInfo.zp ? <img src={IP.fileHost + userInfo.zp} alt='' /> : <img src={require('./../../../images/avatar.png')} alt="" />}
            </div>
            <div className='saftyExam_info_right'>
              <div className='saftyExam_info_name'>
                <div>
                  {userInfo.xm}
                </div>
                <div>
                  {
                    userInfo.sf === 1 ?
                      '(老师)' : '(学生)'
                  }
                </div>
              </div>
              <div className='saftyExam_info_num'>
                剩余<span>{(paperData.restrict_num || 0) - (paperData.num || 0)}次</span>考试机会
              </div>
            </div>
          </div>

          <div className='saftyExam_config'>
            <div className='saftyExam_config_title'>
              {paperData.paper_name}
            </div>
            <div className='saftyExam_config_tag'>
              <div>限时{paperData.paper_duration || 0}分钟</div>
              <div>满分{paperData.paper_score || 0}分</div>
            </div>
            <div className='saftyExam_config_date_title'>
              考试时间
            </div>
            <div className='saftyExam_config_date'>
              {moment(paperData.start_time ? paperData.start_time : new Date()).format('YYYY-MM-DD hh:mm')}
              &nbsp;至&nbsp;
              {moment(paperData.end_time ? paperData.end_time : new Date()).format('YYYY-MM-DD hh:mm')}
            </div>
          </div>
          <div className='saftyExam_examBefore'>
            <div>
              <b>考前要求：</b>
              <span>{this.toStand() ? '达标' : '不达标'}</span>
            </div>

            <div>学习时长：不低于<span>{paperData.learing_astrict || 0}</span>小时，已学<span>{userXxqk.xxsc}</span>小时</div>
            <div>练习次数：不低于<span>{paperData.exercise_astrict || 0}</span>次，已做<span>{userXxqk.totalCs}</span>次</div>
            <div>练习题数：不低于<span>{paperData.problems_astrict || 0}</span>题，已练<span>{userXxqk.totalTs}</span>题</div>
            <div><span>{paperData.require_learned ? '要求' : '不要求'}</span>必修课程学完，&nbsp;&nbsp;{userXxqk.bxk > 0 ? <span>剩余{userXxqk.bxk}课未学</span> : <span>已学完</span>}</div>
          </div>
          <div className={(btnStatus && (paperData.num < paperData.restrict_num) && this.toStand()) ? 'saftyExam_but_start' : 'saftyExam_but_start saftyExam_but_start_grey'}
            onClick={() => (btnStatus && (paperData.num < paperData.restrict_num) && this.toStand()) ? this._findMyPaperUndertakRecord() : {}}>
            <CountDownSelf
              deadline={paperData.start_time || new Date()}
              changeParentState={this.overCallBack}
              ref={line => this.line = line}
            ></CountDownSelf>
            开始考试</div>
          <div className='saftyExam_but_next' onClick={() => {
            this.prevPager()
          }}>查看上一场考试</div>
          <div className='saftyExam_but_next' onClick={() => {
            this.nextPager()
          }}>查看下一场考试</div>
        </div>

        <div className='saftyExam_notice'>
          <div className='saftyExam_notice_title'>
            考试须知
          </div>
          <div className='saftyExam_notice_desc'>
            {ReactHtmlParser(paperData.exam_notes)}
          </div>
        </div>

        {/* 承诺书 */}

        <Modal
          popup
          visible={luVisible}
          onClose={() => {
            this.setState({
              luVisible: false
            })
          }}
          animationType="slide-up"
        >
          <div className='exam_lu'>
            <div className='exam_lu_title'>
              请仔细阅读《{undertakData.undertak_name}》并签字
            </div>
            <div className='exam_lu_desc' dangerouslySetInnerHTML={{ __html: undertakData.undertak_desc }}>
            </div>

            <div className='exam_lu_checkbox'>
              <Checkbox onChange={(e) => {
                this.setState({
                  checked: e.target.checked
                })
              }}>我已阅读并同意以下承诺书</Checkbox>

            </div>
            {this.state.base64 ? <div>
              <img src={this.state.base64} alt="" height='100px' />
            </div> : ''}

            {this.state.base64 ? <div className='exam_lu_sub_bg' onClick={() => {
              this.submit()
            }}>
              开始考试
            </div> : <div className='exam_lu_sub' onClick={() => {
              if (!this.state.checked) {
                return Toast.info("请先勾选同意承诺书！")
              }
              this.goSign()
            }}>
              签字确认
            </div>}

          </div>
        </Modal>

        <Modal
          visible={visible}
          transparent
          onClose={() => this.setState({ visible: false })}
          style={{ width: '100%', height: '100%', background: '#fff' }}
        >
          <div>
            <section className="signature-component" >
              <div id='signature-pad' className="js-signature" data-width={width * 0.7} data-height={height * 0.8} data-border="1px solid black" data-line-color="#000" data-auto-fit="true"></div>
            </section>
            <div className='sign_btn' style={{ height: `${height * 0.2 - 60}px` }}>
              <div className='approveDetail_btn_list' style={{ width: `${height * 0.2 - 60}px` }} onClick={() => this.handleClearBtnClick()}>清除</div>
              <div className='approveDetail_btn_list' style={{ width: `${height * 0.2 - 60}px` }} onClick={() => this.handleOk()}>确认</div>
            </div>
          </div>
        </Modal>

      </div>
    )
  }
}
