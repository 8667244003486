import React, { Component } from 'react';
import { Toast } from 'antd-mobile'
import { listUserOpenLockRecord, getUserDeviceTemporaryAuthRecord, deleteTemporaryAuth } from '../../../api/api'
import moment from 'moment'
import './door_lock.less'

class LockRecord extends Component {

  state = {
    active: 1,
    lockData: [], // 开锁记录
    authorizationData: [], // 临时授权记录
  }

  componentDidMount = () => {
    window.addEventListener('focusin', this.setScrollTop)
    window.addEventListener('focusout', this.setScrollTop)
    this.fetchLock(this.props.match.params.id)
  }

  setScrollTop = () => {
    let scrollTop = document.body.scrollTop + document.documentElement.scrollTop
    window.scrollTo(0, scrollTop)
  }

  // 获取开锁记录
  fetchLock = async deviceId => {
    const res = await listUserOpenLockRecord({ deviceId })
    if (res.code === 20000) {
      this.setState({
        lockData: res.data
      })
    }
  }

  // 获取授权记录
  fetchAuthorization = async deviceId => {
    const res = await getUserDeviceTemporaryAuthRecord({ deviceId })
    if (res.code === 20000) {
      this.setState({
        authorizationData: res.data
      })
    }
  }

  // 切换记录
  handleChangeRecord = num => {
    this.setState({
      active: num
    })
    if (num === 1) {
      this.fetchLock(this.props.match.params.id)
    }
    if (num === 2) {
      this.fetchAuthorization(this.props.match.params.id)
    }
  }

  // 取消授权
  handleClose = async id => {
    const res = await deleteTemporaryAuth({ id })
    if (res.code === 20000) {
      this.fetchAuthorization(this.props.match.params.id)
      Toast.info(res.message)
    }
  }

  render() {
    const { active, lockData, authorizationData } = this.state
    return (
      <div className='lockRecord'>
        <div className='lockRecord-change'>
          <div className={`${active === 1 ? 'active' : ''} lockRecord-change-list`} onClick={() => this.handleChangeRecord(1)}>开锁记录</div>
          <div className={`${active === 2 ? 'active' : ''} lockRecord-change-list`} onClick={() => this.handleChangeRecord(2)}>授权记录</div>
        </div>
        {
          active === 1 && <div className='lockRecord-content'>
            {
              (lockData || []).length ? lockData.map(item => {
                return <div className='lockRecord-content-lock-list' key={item.record_id}>
                  {/* 指纹 */}
                  {item.record_type === 'zw' && <img alt='' src={require('../../../images/zhiwenkaisuo.png')} />}
                  {/* 刷卡 */}
                  {item.record_type === 'sk' && <img alt='' src={require('../../../images/ka.png')} />}
                  {/* 密码 */}
                  {item.record_type === 'mm' && <img alt='' src={require('../../../images/mimakaisuo.png')} />}
                  {/* 二维码 */}
                  {item.record_type === 'qr' && <img alt='' src={require('../../../images/erweimakaisuo.png')} />}
                  {/* 蓝牙 */}
                  {item.record_type === 'ly' && <img alt='' src={require('../../../images/lanya.png')} />}
                  {/* 远程开锁 */}
                  {item.record_type === 'ycks' && <img alt='' src={require('../../../images/yijiankaisuo.png')} />}
                  {/* 离线密码 */}
                  {item.record_type === 'lxmm' && <img alt='' src={require('../../../images/mimakaisuo.png')} />}
                  {/* 人脸 */}
                  {item.record_type === 'rl' && <img alt='' src={require('../../../images/rl.png')} />}
                  {/* 其他 */}
                  {item.record_type !== 'zw' && item.record_type !== 'sk' && item.record_type !== 'mm' && item.record_type !== 'qr' && item.record_type !== 'ly' && item.record_type !== 'ycks' && item.record_type !== 'lxmm' && item.record_type !== 'rl' && <img alt='' src={require('../../../images/mimakaisuo.png')} />}
                  <div className='lockRecord-content-lock-list-detail'>
                    <div>{item.record_user_name}</div>
                    <div>{moment(item.record_open_time).format('YYYY/MM/DD HH:mm')} 开锁成功</div>
                  </div>
                </div>
              }) : <div className='not-yet'>--暂无数据--</div>
            }
          </div>
        }
        {
          active === 2 && <div className='lockRecord-content'>
            {
              (authorizationData || []).length ? authorizationData.map(item => {
                return <div className='lockRecord-content-authorization-list' key={item.t_id}>
                  <div className='lockRecord-content-authorization-list-content'>
                    <div className='lockRecord-content-authorization-list-content-detail'>
                      <div className='lockRecord-content-authorization-list-content-detail-name'>{item.t_auth_content}</div>
                      <div className='lockRecord-content-authorization-list-content-detail-state'>
                        {
                          item.t_state === '2' ? <span className='grayStatus'>已取消</span> : (moment() > moment(item.t_end_time) ? <span className='grayStatus'>已失效</span> : <span className='greenStatus'>正常</span>)
                        }
                      </div>
                    </div>
                    <div className='lockRecord-content-authorization-list-content-desc'>密码将于{moment(item.t_end_time).format('YYYY/MM/DD HH:mm')}失效，请及时使用</div>
                  </div>
                  {
                    (moment() < moment(item.t_end_time)) && item.t_state === '1' && <div className='lockRecord-content-authorization-list-btn'>
                      <div onClick={() => this.handleClose(item.t_id)}>取消</div>
                    </div>
                  }
                </div>
              }) : <div className='not-yet'>--暂无数据--</div>
            }
          </div>
        }
      </div>
    );
  }
}

export default LockRecord;