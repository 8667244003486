import React, { Component } from 'react';
import { Toast, Modal } from 'antd-mobile'
import { getDeviceLockByUser, ycOpenLock, getCmdCount, setUserRoomSort, getQrcodeStr, downloadFingerprintToDevice } from '../../../api/api'
import IP from '../../../config/config'
import './details.less'

class Details extends Component {

    state = {
        data: {},
        deviceId: '', // 选中的锁序列号
        jmQrVisible: false, // 二维码开锁弹窗
        jmQrStr: '', // 二维码字符串
        cacheVisible: false, // 缓存命令提示
    }

    componentDidMount = () => {
        this.findByDeviceId(this.props.match.params.id)
    }

    // 查询设备信息
    findByDeviceId = async (deviceId) => {
        const res = await getDeviceLockByUser({ deviceId })
        if (res.code === 20000) {
            this.setState({
                data: res.data
            })
        }
    }

    // 密码开锁
    handleOpenLockmm = item => {
        const array = item.auth_type ? item.auth_type.split(',') : []
        if (array.find(x => x === 'mm')) {
            this.props.history.push({ pathname: `/module/openLock/${item.device_id}` })
        } else {
            Toast.info('暂未授权密码开锁')
        }
    }

    // 一键开锁
    ycOpenLock = async deviceId => {
        Toast.loading('命令下发中...', 0)
        const res = await ycOpenLock({ deviceId })
        if (res.code === 20000) {
            Toast.success(res.message)
            setTimeout(() => {
                this.handleReload(deviceId)
            }, 2000)
        } else if (res.code === 20001) {
            Toast.info(res.message)
        } else {
            Toast.hide()
        }
    }

    // 刷新缓存条数
    handleReload = async deviceId => {
        Toast.loading('', 0)
        const { data } = this.state
        const res = await getCmdCount({ deviceId })
        if (res.code === 20000) {
            data.device_cmd_count = res.data
            this.setState({
                data: data
            })
            Toast.hide()
        } else {
            Toast.hide()
        }
    }

    // 开锁记录
    handleGoksjl = item => {
        this.props.history.push({ pathname: `/module/lockRecord/${item.device_id}` })
    }

    // 设为常用房间
    handlRoomSort = async (deviceId, flag) => {
        const res = await setUserRoomSort({ deviceId, flag })
        if (res.code === 20000) {
            this.findByDeviceId(this.props.match.params.id)
            Toast.success(res.message)
        }
    }

    // 临时授权
    handleTemporary = item => {
        this.props.history.push({ pathname: `/module/temporaryAuthorization/${item.device_id}` })
    }

    // 离线密码
    goLxmm = item => {
        this.props.history.push({ pathname: `/module/linePassword/${item.device_id}` })
    }

    // 二维码开锁弹窗
    handleQrModal = async deviceId => {
        const res = await getQrcodeStr({ deviceId, function: 'AES' })
        if (res.code === 20000 && res.data) {
            this.setState({
                deviceId,
                jmQrVisible: true,
                jmQrStr: IP.host + '/znmk/appLock/qrcode?content=' + res.data.data
            })
        }
    }

    // 关闭二维码弹窗
    handleCloseJmQr = () => {
        this.setState({ jmQrVisible: false })
    }

    // 下发指纹
    handleDownZw = async item => {
        Toast.loading('指纹下发中...', 0)
        const res = await downloadFingerprintToDevice({ deviceId: item.device_id })
        if (res.code === 20000) {
            Toast.info(res.message)
            this.setState({
                visible: false
            })
        } else {
            Toast.hide()
        }
    }

    render() {
        const { data, jmQrVisible, jmQrStr, cacheVisible, deviceId } = this.state
        return (
            <div className='lock_details'>
                <div className={data.device_status === 1 ? 'lock_details_on_bg' : 'lock_details_off_bg'}>
                    <img src={require('./../../../images/new/12.png')} alt='' />
                </div>
                <div className='lock_details_cont'>
                    <div className='doorLock_item_title'>
                        <div className='doorLock_item_title_name'>
                            {data.device_door_name}
                        </div>
                        {
                            data.device_type === 'mj' ? <div className='doorLock_item_xh status_gray'>门禁</div> : (
                                data.device_status === 2 ? <div className='doorLock_item_xh status_gray'>离线</div> : (
                                    data.device_dsignal > 15 ? <div className='doorLock_item_xh status_green'>信号强</div> : data.device_dsignal > 5 ? <div className='doorLock_item_xh status_orange'>信号中</div> : <div className='doorLock_item_xh status_red'>信号差</div>
                                )
                            )
                        }
                        {
                            data.device_type === 'mj' ? ('') : (
                                data.device_battery > 80 ? <div className='doorLock_item_xh status_green'>
                                    <img alt='' src={require('../../../images/electricGreen.png')} />
                                    <span>{data.device_battery}%</span>
                                </div> : data.device_battery > 50 ? <div className='doorLock_item_xh status_orange'>
                                    <img alt='' src={require('../../../images/electricOrange.png')} />
                                    <span>{data.device_battery}%</span>
                                </div> : <div className='doorLock_item_xh status_red'>
                                    <img alt='' src={require('../../../images/electricRed.png')} />
                                    <span>{data.device_battery}%</span>
                                </div>
                            )
                        }
                        {
                            data.device_type === 'mj' ? ('') : <div>
                                {
                                    data.device_open_state === '-1' && <div className='doorLock_item_xh status_gray'>--</div>
                                }
                                {
                                    data.device_open_state === '1' && <div className='doorLock_item_xh status_green'>开</div>
                                }
                                {
                                    data.device_open_state === '0' && <div className='doorLock_item_xh status_red'>关</div>
                                }
                            </div>
                        }
                        {
                            data.device_status === 2 ? (<div onClick={(e) => {
                                this.findByDeviceId(this.props.match.params.id)
                            }} className='doorLock_item_sx'>
                                <img alt='' src={require('../../../images/new/13.png')} />
                                刷新状态
                            </div>) : (<div></div>)
                        }

                    </div>
                    <div className='doorLock_item_room'>
                        {data.device_building_name}-{data.device_floor_name}-{data.device_room_name}
                    </div>
                    <div className='doorLock_item_status'>
                        <div className={data.device_status === 1 ? 'doorLock_item_status_onLine' : 'doorLock_item_status_offLine'}>
                            <div></div>
                            <div>{data.device_status === 1 ? '设备在线' : '设备离线'}</div>
                        </div>
                        <div className='doorLock_item_line'></div>
                        <div className='doorLock_item_status_use'>
                            {data.auth_sort === '0' ? '未设置为常用房间' : '已设置为常用房间'}
                        </div>
                    </div>
                    <div className='doorLock_item_num' onClick={(e) => {
                        e.stopPropagation()
                        this.handleReload(data.device_id)
                    }}>
                        <div>缓存命令{data.device_cmd_count}条</div>
                        <div onClick={(e) => {
                            e.stopPropagation()
                            this.setState({ cacheVisible: true })
                        }}>
                            <img alt='' src={require('../../../images/new/09.png')} />
                        </div>
                    </div>
                    <div className='doorLock_item_but'>
                        {
                            data.auth_type && data.auth_type.split(',').find(x => x === 'mm') ? (<div onClick={(e) => {
                                e.stopPropagation()
                                this.handleOpenLockmm(data)
                            }} style={{ width: data.auth_type && data.auth_type.split(',').find(x => x === 'ycks') ? '50%' : '100%' }}>
                                <img alt='' src={require('../../../images/new/10.png')} />
                                密码开锁
                            </div>) : ('')
                        }
                        {data.auth_type && data.auth_type.split(',').find(x => x === 'ycks') ? (<div style={{ width: data.auth_type && data.auth_type.split(',').find(x => x === 'mm') ? '50%' : '100%' }} onClick={(e) => {
                            e.stopPropagation()
                            this.ycOpenLock(data.device_id)
                        }}>
                            <img alt='' src={require('../../../images/new/11.png')} />
                            一键开锁
                        </div>) : ('')}

                    </div>
                </div>

                <div className='lock_details_bar'>
                    {
                        (data.auth_type && data.auth_type.split(',').find(x => x === 'zw')) ? (<div className='lock_details_bar_item' onClick={() => this.handleDownZw(data)}>
                            <div>
                                <img src={require('./../../../images/new/22.png')} alt='' />
                            </div>
                            <div>
                                下发指纹
                            </div>
                        </div>) : ('')
                    }
                    {
                        (data.auth_type && data.auth_type.split(',').find(x => x === 'mm')) ? (<div className='lock_details_bar_item' onClick={() => this.handleTemporary(data)}>
                            <div>
                                <img src={require('./../../../images/new/14.png')} alt='' />
                            </div>
                            <div>
                                临时授权
                            </div>
                        </div>) : ('')
                    }
                    {(data.auth_type && data.auth_type.split(',').find(x => x === 'mm') && data.device_type === 'aep') ? (<div className='lock_details_bar_item' onClick={() => this.goLxmm(data)}>
                        <div><img src={require('./../../../images/new/15.png')} alt='' /></div>
                        <div>
                            离线密码
                        </div>
                    </div>) : ('')}
                    {(data.auth_type && data.auth_type.split(',').find(x => x === 'qr')) ? (<div className='lock_details_bar_item' onClick={() => this.handleQrModal(data.device_id)}>
                        <div>
                            <img src={require('./../../../images/new/16.png')} alt='' />
                        </div>

                        <div>
                            二维码开锁
                        </div>
                    </div>) : ('')}

                </div>

                <div className='lock_details_but'>
                    <div className='lock_details_but_item' onClick={() => this.handlRoomSort(data.device_id, data.auth_sort === '0' ? 1 : 2)}>
                        <div className='lock_details_but_item_title'>
                            <div>
                                <img src={require('./../../../images/new/18.png')} alt='' />
                            </div>
                            <div>设为常用房间</div>
                        </div>
                        <div className='lock_details_but_item_cont'>
                            <div>{data.auth_sort === '0' ? '未设置' : '已设置'}</div>
                            <div>
                                <img src={require('./../../../images/new/19.png')} alt='' />
                            </div>
                        </div>
                    </div>
                    <div className='lock_details_but_item' onClick={() => this.handleGoksjl(data)}>
                        <div className='lock_details_but_item_title'>
                            <div>
                                <img src={require('./../../../images/new/20.png')} alt='' />
                            </div>
                            <div>操作记录</div>
                        </div>
                        <div className='lock_details_but_item_cont'>
                            <div>
                                <img src={require('./../../../images/new/19.png')} alt='' />
                            </div>
                        </div>
                    </div>
                </div>

                {/* 开锁二维码 */}
                <Modal
                    visible={jmQrVisible}
                    transparent
                    onClose={() => this.handleCloseJmQr()}
                    title="开锁二维码"
                >
                    <div className='doorLock-modal'>
                        <img alt='' src={jmQrStr} onClick={() => this.handleQrModal(deviceId)} />
                    </div>
                </Modal>
                {/* 缓存提示弹窗 */}
                <Modal
                    visible={cacheVisible}
                    transparent
                    closable={true}
                    onClose={() => this.setState({ cacheVisible: false })}
                    title="提示"
                >
                    <div className='doorLock-dynamic'>
                        待下发命令条数，按8#或点亮屏幕可激活设备联网下发命令。
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Details;