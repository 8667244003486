import React, { Component } from 'react'
import { findKcDetailInfoById } from '../../../../api/whp'
import moment from 'moment'

class deviceDetail extends Component {

  state = {
    data: {},
    visible: false,
    opinion: '',
  }

  componentDidMount = () => {
    document.title = '库存详情'
    window.scrollTo(0, 0)
    this.fetch(this.props.match.params.id)
  }

  fetch = async kcid => {
    const res = await findKcDetailInfoById({ kcid })
    if (res.code === 20000) {
      console.log(res.data)
      this.setState({
        data: res.data || {}
      })
    }
  }


  render() {
    const { data } = this.state
    return (
      <div className='approveDetail' style={{ padding: '0px 0px 76px' }}>
        <div className='approve_detail_head'>
          <div className='approve_detail_left'>
            <div className='approve_detail_left_title'>
              {data.whp_mc}-{data.whp_fl}
            </div>
            <div className='approve_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='approve_list_head_seat'>{data.whp_ggxh}</div>
              <div className='approve_list_head_seat'>{data.whp_bzsl}{data.whp_bzdw}</div>
              <div className='approve_list_head_seat'>{data.whp_jldw}</div>
            </div>
          </div>
          <div className='approve_detail_right'>

          </div>
        </div>
        <div className='approve_detail_cont'>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>仓库名称</div>
              <div>{data.kcck_mc}</div>
            </div>
            <div className='approve_detail_item'>
              <div>CAS</div>
              <div>{data.whp_cas}</div>
            </div>
          </div>

          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>库存量</div>
              <div>{data.whp_kcs}{data.whp_jldw}</div>
            </div>
            <div className='approve_detail_item'>
              <div>现存量</div>
              <div>{data.whp_xcsl}{data.whp_bzdw}</div>
            </div>
            <div className='approve_detail_item'>
              <div>供应商</div>
              <div>{data.whp_gys}</div>
            </div>
            <div className='approve_detail_item'>
              <div>受管制类别</div>
              <div>{data.whp_sgzlb}</div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default deviceDetail