import React, { Component } from 'react';
import { List, Picker, TextareaItem, InputItem, Button, WhiteSpace, WingBlank, Toast, ImagePicker } from 'antd-mobile'
import { findRepairSpaceInfo, findAllFaultType, saveRepairOrder, uploadRepairOrderImgae, } from '../../../../api/repairApi'
import { createForm } from 'rc-form';
import cookie from 'react-cookies'
import './index.less'

class index extends Component {
    state = {
        schoolGroup: [],
        BuildData: [],
        roomData: [],
        newDatas: [],
        equipmentData: [],
        equipmentData2: [],
        equipmentData1: [],
        equipmentCheck: {},
        datas: [],
        area_val: [],
        errorData: [],
        errorData1: [],
        BrandData: [],
        NameData: [],
        NameData1: [],
        TypeData: [],
        TypeData1: [],
        ModelData: [],
        ModelData1: [],
        ProductData: [],
        ProductData1: [],
        equipmentId1: [],
        urls: [],
        building_id: '',
        room_id: '',
        description: '',
        user_phone: '',
        area_rome: '',
        room_name: '',
        building_name: '',
        demoFiles: [],
        BrandData1: [],
        approval_name: "",
        approval_id: '',
        approval_number: "",
        approval_state: '',
        approval_time: "",
        config_id: '',
        department_id: '',
        department_name: "",
        next_user_id: '',
        order_department_id: '',
        order_id: "",
        order_user_id: '',
        procedure_id: '',
        record_id: '',
        refuse_reason: "",
        equipment_name: '',
        equipment_number: '',
        fault_type_name: '',
    }

    componentDidMount = async () => {
        window.document.title = '用户报修'
        this.renderUserGroup()
        this.findAllFaultType()
        this.setState({ user_phone: cookie.load('userData').lxfs || '' })
    }

    findAllFaultType = () => {
        findAllFaultType({ pageNum: 1, pageSize: 99999 }).then(res => {
            if (res.code === 20000) {
                this.setState({
                    errorData: res.data && res.data.list ? res.data.list.map(item => {
                        return {
                            label: item.fault_type_name,
                            value: item.fault_type_id,
                        }
                    }) : []
                })
            }
        })
    }

    // 渲染位置
    renderUserGroup = () => {
        findRepairSpaceInfo().then(res => {
            if (res.code === 20000) {
                const data = res.data.map(item => {
                    return {
                        children: [],
                        bs: item.bs,
                        label: item.dwmc,
                        parentId: item.bs === 1 ? '0' : item.sj_dm,
                        value: item.dwdm,
                    }
                })
                const newData = data.map(item => {
                    item.children = data.filter(x => x.parentId === item.value)
                    return item
                }).filter(x => x.bs === 1)
                console.log(newData)
                this.setState({
                    datas: newData,
                    newDatas: data
                })

            }
        });
    };

    // 图片上传
    upload = (files, type, index) => {
        const { urls, demoFiles } = this.state
        if (type === "add") {
            Toast.loading("图片上传中,请稍等", 0)
            const imgSize = files[files.length - 1].file.size;
            if (imgSize > 10 * 1024 * 1024) return Toast.info('请上传小于10M的图片', 2);
            let formData = new FormData();
            formData.append("multipartFile", files[files.length - 1].file);
            uploadRepairOrderImgae(formData).then(res => {
                this.setState({
                    demoFiles: files,
                });
                if (res.code === 20000) {
                    Toast.hide()
                    Toast.info("上传成功", 1)
                    this.setState({
                        urls: urls.concat(res.data)
                    })
                } else {
                    Toast.hide()
                    Toast.fail(res.message)
                }
            })
        }
        if (type === "remove") {
            this.setState({
                urls: urls.filter((x, indexs) => indexs !== index),
                demoFiles: demoFiles.filter((x, indexs) => indexs !== index),
            })
        }
    }

    submit = async () => {
        const { room_name, room_id, errorData1, user_phone, description, building_id, building_name, urls, area_val, errorData, equipment_name, equipment_number } = this.state
        console.log()


        // if (!(errorData1 && errorData1.length) || area_val.length !== 1 || !equipment_number || !equipment_name || !description || !urls.length) {
        if (area_val.length !== 3 || !equipment_name || !equipment_number || !(errorData1 && errorData1.length) || !user_phone || !description || !urls.length) {
            return Toast.info("请补充完整报修信息")
        } else if (user_phone.length !== 11) {
            return Toast.info("联系方式格式错误")
        } else {
            this.setState({
                click: true
            })
            let params = {
                fault_type_id: errorData1[0],
                fault_type_name: errorData.find(item => errorData1[0] == item.value).label,
                equipment_number: equipment_number,
                equipment_name: equipment_name,
                description: description,
                pic_url: urls.join(','),
                user_phone_number: user_phone,
                building_id: building_id,
                building_name: building_name,
                room_name,
                room_id,
            }
            Toast.loading('提交中！', 0)
            const res = await saveRepairOrder(params)
            if (res.code === 20000) {
                Toast.hide()
                Toast.success(res.message, 2)
                this.props.history.push('/module/smartRepair')
            } else {
                Toast.fail(res.message)
            }
        }
    }

    render() {
        const { user_phone, demoFiles, datas, area_val, errorData, errorData1, description, equipment_name, equipment_number, newDatas } = this.state
        return (
            <div className="fillFormServer">
                <Picker
                    extra={<div className='picker_place'>请选择维修地点</div>}
                    data={datas}
                    value={area_val}
                    cols={3}
                    onChange={(val) => {
                        const room_name = newDatas.find(x => x.value == val[2]).label
                        this.setState({
                            area_val: val,
                            room_id: val[2],
                            room_name
                        })
                    }}
                >
                    <List.Item arrow="horizontal"><span className="must_Filin">* </span>位置</List.Item>
                </Picker>
                <WhiteSpace size="sm" />
                <WhiteSpace size="sm" />
                <InputItem placeholder="请输入设备名称" value={equipment_name} style={{ textAlign: 'right', paddingRight: 8 }} onChange={(e) => {
                    this.setState({
                        equipment_name: e
                    })
                }}>
                    <span className="blank-fillFormServer"><span className="must_Filin">* </span>设备名称</span>
                </InputItem>
                <InputItem placeholder="请输入设备编号" value={equipment_number} style={{ textAlign: 'right', paddingRight: 8 }} onChange={(e) => {
                    this.setState({
                        equipment_number: e
                    })
                }}>
                    <span className="blank-fillFormServer"><span className="must_Filin">* </span>设备编号</span>
                </InputItem>
                <WhiteSpace size="sm" />
                <WhiteSpace size="sm" />
                <Picker extra={<div className='picker_place'>请选择故障类型</div>} data={errorData} cols={1} value={errorData1} onChange={(val) => {
                    this.setState({
                        errorData1: val
                    })
                }} className="forss">
                    <List.Item arrow="horizontal"><span className="must_Filin">* </span>故障类型</List.Item>
                </Picker>
                <InputItem placeholder="请输入联系方式" value={user_phone} style={{ textAlign: 'right', paddingRight: 8 }} type="number" onChange={(e) => {
                    this.setState({
                        user_phone: e
                    })
                }}>
                    <span className="blank-fillFormServer"><span className="must_Filin">* </span>联系方式</span>
                </InputItem>
                <WhiteSpace size="sm" />
                <WhiteSpace size="sm" />
                <div className="textarea">
                    <div className="textarea_title">
                        <span className="must_Filin">* </span>问题描述
                    </div>
                    <div>
                        <TextareaItem
                            value={description}
                            autoHeight
                            placeholder="说点什么吧~"
                            labelNumber={5}
                            onChange={(e) => this.setState({
                                description: e
                            })}
                        />
                    </div>
                    <div className="textarea_title" style={{ padding: '16px 0 0' }}>
                        <span className="must_Filin">* </span>报修图片
                    </div>
                    <div>
                        <ImagePicker
                            className='repair_image'
                            title="图片上传"
                            multiple={false}
                            selectable={demoFiles.length < 4}
                            files={demoFiles}
                            onChange={(files, type, index) => {
                                this.upload(files, type, index)
                            }}
                        />
                    </div>
                </div>
                <div className='repair_sub'>
                    <Button type="default" onClick={() => {
                        this.submit()
                    }}>提交</Button>
                </div>
            </div>
        );
    }
}
export default createForm()(index);
