import React, { Component } from 'react'
import { TreeSelect, Select } from 'antd'
import { Picker, List, TextareaItem, Modal, Icon, Toast, InputItem, DatePicker } from 'antd-mobile';
import { req_findDepartmentInfo, findZxOrRoomByDwOrZx, req_findDepartmentInfoToTeacher } from '../../../../api/whp'
import { findWasteType, findBucketInfoByWasteId, findMasterProcess, findNextApprovalUser, addRegisterRecord } from '../../../../api/fqw'
import moment from 'moment';

class Add extends Component {
  state = {
    slideId: '',
    startX: '',
    depTreeData: [], // 学院部门树
    depData: [], // 学院部门
    centerData: [], // 中心
    roomData: [], // 实验室
    depUserData: [], // 部门下人员树
    depArr: [], // 部门下人员
    ssxy_id: undefined, // 所属学院id
    sszx_id: [], // 所属中心id
    room_id: [], // 所属实验室id
    principal_code: undefined, // 负责人
    predict_dispose_time: undefined, // 预处理时间
    specific_location: '', // 存放地点
    register_descr: '', // 备注
    visible: false, // 添加废弃物弹窗
    typeData: [], // 废弃物分类
    chemicalData: [], // 选择废弃物信息
    masterProcess: 0, // 是否多人同时审批
    approveVisible: false,
    spry: undefined,
  }

  componentDidMount = () => {
    document.title = '废弃物登记'
    window.scrollTo(0, 0)
    this.fetchDep()
    this.fetchDepUser()
    this.fetchType()
  }

  // 查询学院/部门
  fetchDep = async () => {
    const res = await req_findDepartmentInfo({ dwlx: 1 })
    if (res.code === 20000) {
      let menuList = res.data
      menuList = menuList.map(a => {
        return {
          title: a.dwmc,
          key: a.dwdm,
          parentId: a.sj_dm,
          value: a.dwdm,
          disabled: a.sj_dm === '0' ? true : false
        }
      })
      menuList = menuList.map(m => {
        m.children = menuList.filter(p => p.parentId === m.key)
        return m
      }).filter(x => !res.data.find(v => v.dwdm === x.parentId))
      this.setState({
        depTreeData: menuList,
        depData: res.data,
      })
    }
  }

  // （选择学院/部门）查询所属中心
  handleChangeDep = async e => {
    const res = await findZxOrRoomByDwOrZx({ flag: 1, param: e })
    if (res.code === 20000) {
      this.setState({
        centerData: res.data.map(item => {
          return {
            label: item.room_name,
            value: item.room_id,
          }
        })
      })
    }
  }

  // （选择中心）查询所属实验室
  handleChangeCenter = async e => {
    const res = await findZxOrRoomByDwOrZx({ flag: 2, param: e.join(',') })
    if (res.code === 20000) {
      this.setState({
        roomData: res.data.map(item => {
          return {
            label: item.room_name,
            value: item.room_id,
          }
        })
      })
    }
  }

  // 查询部门和人员
  fetchDepUser = async () => {
    const res = await req_findDepartmentInfoToTeacher()
    if (res.code === 20000) {
      let menuList = (res.data || []).map(item => {
        return {
          title: item.dwmc,
          value: item.dw_id,
          parentId: item.sj_dm,
          disabled: true,
          children: (item.teacherInfoEntities || []).map(val => {
            return {
              title: val.xm,
              value: val.gh,
              parentId: val.dwdm,
              disabled: false,
            }
          })
        }
      })
      menuList = menuList.map(item => {
        item.children = item.children.length ? item.children : menuList.filter(p => p.parentId === item.value)
        return item
      }).filter(p => p.parentId === '0')
      const a = []
      const c = a.concat(menuList)
      const depArr = this.deep(c)
      this.setState({
        depUserData: menuList,
        depArr
      })
    }
  }

  // 查询废弃物分类
  fetchType = async () => {
    const res = await findWasteType({ flag: 2 })
    if (res.code === 20000) {
      const arr = (res.data || []).reverse()
      arr.map(t => {
        findBucketInfoByWasteId({ wasteId: t.waste_id }).then(resu => {
          if (resu.code === 20000) {
            const obj = {
              ...t,
              barrelData: (resu.data || []).map(x =>{
                return {
                  label: x.bucket_name,
                  value: x.bucket_code
                }
              }),
              state: false,
            }
            this.setState({
              typeData: this.state.typeData.concat([obj])
            })
          }
        })
      })
    }
  }

  // 添加废弃物
  handleAddFqw = () => {
    const { typeData, chemicalData } = this.state
    typeData.forEach(item => {
      if (item.state && chemicalData.filter(x => x.waste_id === item.waste_id).length === 0) {
        item.total_weight = 1
        item.unit_price = Number(item.handling_expense) || 0
        item.pack_num = 1
        item.total_money = Number(item.handling_expense) || 0
        chemicalData.push(item)
        this.setState({
          chemicalData: chemicalData
        })
      }
    })
    this.setState({
      visible: false,
    })
  }

  // 判断审批是多人共同审批还是单人审批
  handleMasterProcess = async () => {
    const { chemicalData, ssxy_id, sszx_id, room_id, principal_code, predict_dispose_time, specific_location } = this.state
    if (ssxy_id === undefined || (!sszx_id.length) || (!room_id.length) || principal_code === undefined || predict_dispose_time === undefined || specific_location === '') {
      Toast.info('请补全上述内容！')
      return false
    }
    if (chemicalData.length === 0) {
      Toast.info('请添加废弃物！')
      return false
    }
    if (chemicalData.filter(x => x.total_weight).length !== chemicalData.length) {
      Toast.info('请填写废弃物总重量！')
      return false
    }
    if (chemicalData.filter(x => x.pack_num).length !== chemicalData.length) {
      Toast.info('请填写废弃物包装数！')
      return false
    }
    const res = await findMasterProcess({ flag: 3 })
    if (res.code === 20000) {
      this.setState({
        masterProcess: res.data
      }, () => {
        this.handleNextApprovalUser(room_id.join(','))
      })
    }
  }

  // 获取下一个审批人员信息
  handleNextApprovalUser = async roomId => {
    const { masterProcess } = this.state
    const res = await findNextApprovalUser({ flag: 3, roomId })
    if (res.code === 20000) {
      this.setState({
        approveUser: res.data,
        approveVisible: true,
      }, () => {
        if (masterProcess) {
          this.setState({
            spry: (res.data || []).map(x => x.gh)
          })
        }
      })
    }
  }

  // 提交
  submit = async () => {
    const { spry, ssxy_id, sszx_id, room_id, depData, centerData, roomData, masterProcess, approveUser, chemicalData, depArr, principal_code, predict_dispose_time, specific_location, register_descr } = this.state
    if (spry === undefined || spry === '') {
      Toast.info('请选择审批人员！')
      return false;
    }
    const values = {
      register_state: 1,
      ssxy_id,
      ssxy_mc: depData.find(x => x.dwdm === ssxy_id).dwmc,
      sszx_id: sszx_id.join(','),
      sszx_mc: centerData.find(x => x.value === sszx_id.join(',')).label,
      room_id: room_id.join(','),
      room_name: roomData.find(x => x.value === room_id.join(',')).label,
      principal_code,
      principal_name: depArr.find(x => x.value === principal_code).title,
      predict_dispose_time: moment(predict_dispose_time).format('YYYY-MM-DD'),
      specific_location,
      register_descr,
    }
    const params = {
      ...values,
      spuserVos: masterProcess ? approveUser : approveUser.filter(x => x.gh === spry),
      registerDetailRecordEntities: chemicalData.map(x => {
        x.unit_price = x.unit_price ? x.unit_price : 0
        return x
      }),
    }
    const res = await addRegisterRecord(params)
    if (res.code === 20000) {
      Toast.info("提交成功！")
      setTimeout(() => {
        this.props.history.goBack()
      }, 2000);
    }
  }









  // 数组转变
  deep = (node) => {
    let stack = node,
      data = []
    while (stack.length !== 0) {
      let pop = stack.pop()
      data.push({
        title: pop.title,
        parentId: pop.parentId,
        value: pop.value,
      })
      let children = pop.children
      if (children) {
        for (let i = children.length - 1; i >= 0; i--) {
          stack.push(children[i])
        }
      }
    }
    return data
  }

  render() {
    const { slideId, typeData, depTreeData, ssxy_id, centerData, sszx_id, roomData, room_id, depUserData, principal_code, predict_dispose_time, specific_location, register_descr, visible, chemicalData, approveVisible, masterProcess, approveUser, spry } = this.state

    return (
      <div className='subscription_add'>
        <div className='subscription_add_title'>
          基础信息
        </div>
        <div className='subscription_add_form'>
          <div className='subscription_add_tree'>
            <div><span style={{ color: 'red' }}>* </span>所属学院</div>
            <TreeSelect
              showSearch
              treeNodeFilterProp='title'
              placeholder="请选择"
              style={{ width: '100%' }}
              value={ssxy_id}
              treeData={depTreeData}
              onChange={(e) => {
                this.setState({
                  ssxy_id: e,
                  sszx_id: [],
                  room_id: [],
                })
                this.handleChangeDep(e)
              }}
            />
          </div>
          <div className='subscription_add_picker'>
            <Picker
              data={centerData}
              value={sszx_id}
              cols={1}
              onChange={(e) => {
                this.setState({
                  sszx_id: e,
                  room_id: []
                })
                this.handleChangeCenter(e)
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>所属中心</List.Item>
            </Picker>
          </div>
          <div className='subscription_add_picker'>
            <Picker
              data={roomData}
              value={room_id}
              cols={1}
              onChange={(e) => {
                this.setState({
                  room_id: e
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>所属实验室</List.Item>
            </Picker>
          </div>
          <div className='subscription_add_tree'>
            <div>
              <span style={{ color: 'red' }}>* </span>负责人
            </div>
            <TreeSelect
              placeholder="请选择负责人"
              showSearch
              treeData={depUserData}
              treeNodeFilterProp='title'
              style={{ width: '100%' }}
              value={principal_code}
              onChange={(e) => {
                this.setState({
                  principal_code: e,
                })
              }}
            />
          </div>
          <div className='subscription_add_picker'>
            <DatePicker
              mode="date"
              title="日期"
              extra="请选择时间"
              value={predict_dispose_time}
              onChange={date => {
                this.setState({ predict_dispose_time: date })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>预处理时间</List.Item>
            </DatePicker>
          </div>
          <div className='subscription_add_input'>
            <InputItem placeholder="请输入存放地点" value={specific_location} onChange={(e) => {
              this.setState({
                specific_location: e
              })
            }}><span style={{ color: 'red' }}>* </span>存放地点</InputItem>
          </div>
        </div>
        <div className='subscription_add_form'>
          <div className='subscription_add_text'>
            <div>备注</div>
            <TextareaItem
              placeholder="请输入备注"
              rows={5}
              value={register_descr}
              onChange={(e) => {
                this.setState({
                  register_descr: e
                })
              }}
            />
          </div>
        </div>
        <div className='subscription_add_increase'>
          <div onClick={() => {
            this.setState({
              visible: true
            })
          }}>
            <img src={require('./../../../../images/chemicals/02.png')} alt='' />
            <span>添加废弃物</span>
          </div>
        </div>
        <div className='subscription_add_increase_record'>
          <div className='subscription_list_card' style={{ margin: 0 }}>
            {(chemicalData || []).map((item, key) => {
              return (
                <div className='subscription_add_card_item' style={{ height: 296 }} onTouchStart={(e) => {
                  this.setState({
                    startX: e.touches[0].clientX
                  })
                }} onTouchEnd={(e) => {
                  const endX = e.changedTouches[0].clientX
                  if (this.state.startX === endX) {
                    return
                  }

                  if (endX - this.state.startX > 100) { // 阈值可以根据需要调整
                    this.setState({
                      slideId: ''
                    })
                    return false;
                    // 左滑后的处理逻辑
                  }
                  if (endX + this.state.startX > 100) { // 阈值可以根据需要调整
                    this.setState({
                      slideId: item.waste_id
                    })
                    // 右滑后的处理逻辑
                    return false
                  }
                }} key={item.waste_id}>
                  <div className='subscription_add_card_cont  subscription_add_cont_float' style={{ margin: slideId === item.waste_id ? '0px' : '0px 12px' }}>
                    <div className='subscription_list_card_item' style={{ width: slideId === item.waste_id ? window.screen.width : window.screen.width - 24 }}>
                      <div className='subscription_list_card_head'>
                        <div className='subscription_list_card_head_left'>
                          <div>{key + 1}</div>
                          <div>{item.waste_name}</div>
                        </div>
                        <div className='subscription_list_card_class'>
                          {item.waste_type_name}
                        </div>
                      </div>
                      <div className='subscription_list_card_company'>
                        <div>{item.waste_code}</div>
                      </div>
                      <div className='subscription_list_card_unit'>
                        <div>{item.waste_form_name}</div>
                        <div>{item.waste_hazard_name}</div>
                        <div>{item.waste_pack_name}</div>
                      </div>
                      <div className='subscription_list_card_input'>
                        <div className='subscription_add_input'>
                          <InputItem type='digit' placeholder="请输入" value={item.total_weight} onChange={(e) => {
                            this.setState({
                              chemicalData: chemicalData.map(x => {
                                if (x.waste_id === item.waste_id) {
                                  x.total_weight = e
                                  x.total_money = (e || 0) * (x.unit_price || 0)
                                }
                                return x
                              })
                            })
                          }}>总重量(kg)</InputItem>
                        </div>
                        <div className='subscription_add_input'>
                          <InputItem type='digit' placeholder="请输入" value={item.unit_price} onChange={(e) => {
                            this.setState({
                              chemicalData: chemicalData.map(x => {
                                if (x.waste_id === item.waste_id) {
                                  x.unit_price = e
                                  x.total_money = (e || 0) * (x.total_weight || 0)
                                }
                                return x
                              })
                            })
                          }}>处理单价</InputItem>
                        </div>
                        <div className='subscription_add_input registration_picker'>
                          <Picker
                            data={item.barrelData || []}
                            value={[item.bucket_code]}
                            cols={1}
                            onChange={(e) => {
                              this.setState({
                                chemicalData: chemicalData.map(x => {
                                  if (x.waste_id === item.waste_id) {
                                    x.bucket_code = e[0]
                                    x.bucket_name = (item.barrelData || []).find(x => x.value === e[0]).label
                                  }
                                  return x
                                })
                              })
                            }}
                          >
                            <List.Item arrow="horizontal">存放回收桶</List.Item>
                          </Picker>
                        </div>
                        <div className='subscription_add_input'>
                          <InputItem type='number' placeholder="请输入" value={item.pack_num} onChange={(e) => {
                            this.setState({
                              chemicalData: chemicalData.map(x => {
                                if (x.waste_id === item.waste_id) {
                                  x.pack_num = e
                                }
                                return x
                              })
                            })
                          }}>包装数量</InputItem>
                        </div>
                      </div>
                    </div>
                    <div className='subscription_add_card_del' style={{ display: slideId === item.waste_id ? 'block' : 'none' }} onClick={() => {
                      this.setState({
                        chemicalData: chemicalData.filter(x => x.waste_id !== item.waste_id)
                      })
                    }}>
                      删除
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className='subscription_list_but'>
          <div onClick={() => {
            this.handleMasterProcess()
          }}>提交审批</div>
        </div>
        {/* 添加废弃物弹窗 */}
        <Modal
          popup
          visible={visible}
          className='subscription_modal'
          onClose={() => {
            this.setState({
              visible: false
            })
          }}
          animationType="slide-up"
        >
          <div className='subscription_add_modal'>
            <div className='subscription_add_modal_locate'>
              <div className='subscription_add_modal_head'>
                <div>添加废弃物</div>
                <div>
                  <Icon type='cross' onClick={() => {
                    this.setState({
                      visible: false
                    })
                  }} />
                </div>
              </div>
            </div>
            <div className='subscription_add_modal_card' style={{ paddingTop: 32 }}>
              {(typeData || []).map((item, key) => {
                return (<div className={item.state ? 'subscription_list_card_item warehousing_modal_item_click' : 'subscription_list_card_item subscription_list_card_border'} key={item.waste_id} onClick={() => {
                  const data = typeData.map(x => {
                    if (item.waste_id === x.waste_id) {
                      x.state = item.state ? false : true
                    }
                    return x
                  })
                  this.setState({
                    typeData: data
                  })
                }}>
                  <div className='subscription_list_card_head'>
                    <div className='subscription_list_card_head_left'>
                      <div>{key + 1}</div>
                      <div>{item.waste_name}</div>
                    </div>
                    <div className='subscription_list_card_class'>
                      {item.waste_type_name}
                    </div>
                  </div>
                  <div className='subscription_list_card_company'>
                    <div>{item.waste_code}</div>
                  </div>
                  <div className='subscription_list_card_foot'>
                    <div className='subscription_list_card_unit'>
                      <div>{item.waste_form_name}</div>
                      <div>{item.waste_hazard_name}</div>
                      <div>{item.waste_pack_name}</div>
                    </div>
                  </div>
                </div>)
              })}
            </div>
            <div className='subscription_list_but'>
              <div onClick={() => {
                this.handleAddFqw()
              }}>添加</div>
            </div>
          </div>
        </Modal>
        {/* 选择审批人 */}
        <Modal
          popup
          visible={approveVisible}
          className='subscription_modal'
          onClose={() => {
            this.setState({
              approveVisible: false,
              spry: undefined
            })
          }}
          animationType="slide-up"
        >
          <div className='subscription_add_modal subscription_add_modal_approve'>
            <div className='subscription_add_modal_head'>
              <div>选择审批人员</div>
              <div>
                <Icon type='cross' onClick={() => {
                  this.setState({
                    approveVisible: false
                  })
                }} />
              </div>
            </div>
            <div className='subscription_add_select'>
              <div>
                <span style={{ color: 'red' }}>* </span>审批人员
              </div>
              <Select mode={masterProcess ? 'multiple' : null} disabled={masterProcess ? true : false} allowClear showSearch optionFilterProp="children" value={spry} placeholder='请选择' onChange={(e) => {
                this.setState({
                  spry: e
                })
              }}>
                {
                  (approveUser || []).map(item => {
                    return <Select.Option key={item.gh} value={item.gh}>{item.xm}</Select.Option>
                  })
                }
              </Select>
            </div>

            <div className='subscription_list_but'>
              <div onClick={() => this.submit()}>提交审批</div>
            </div>
          </div>
        </Modal>
      </div >
    )
  }
}

export default Add
