import React, { Component } from 'react'
import { Upload } from 'antd'
import { List, Picker, DatePicker, Toast } from 'antd-mobile'
import { findStudentCourseInfo, saveStudentReord, updateStudentReord, findTemplateId, findStudentReordId, findUserCourseNumber, findRecordId } from '../../../api/api'
import moment from 'moment'
import IP from '../../../config/config'
import cookie from 'react-cookies'

class TestBg extends Component {

  state = {
    courseData: [],
    courseId: [],
    xn: '',
    xqData: [
      { value: '1', label: '秋季学期' },
      { value: '2', label: '春季学期' },
      { value: '3', label: '夏季学期' },
    ],
    xqId: [],
    classData: [],
    kcxhId: [],
    fileAddr: '',
    templateId: '', // 模板id
    recordId: '', // 记录id
    disabled: false
  }

  componentDidMount = () => {
    const templateId = this.props.match.params.id
    if (templateId !== '0') {
      this.fetchTemplate(templateId)
      this.fetchCourseNumber(templateId)
      this.setState({
        templateId
      })
    }
  }

  // 查询课程序号信息
  fetchCourseNumber = async templateId => {
    const res = await findUserCourseNumber({ templateId })
    if (res.code === 20000) {
      if (res.data.length === 0) {
        Toast.info('报告信息不全，请勿提交!')
      }
      this.setState({
        classData: (res.data || []).map(x => {
          return {
            label: x,
            value: x
          }
        })
      })
    }
  }

  // 查询模板详情
  fetchTemplate = async templateId => {
    const res = await findTemplateId({ templateId })
    if (res.code === 20000) {
      if (res.data) {
        if (new Date(moment(res.data.end_time).format('YYYY/MM/DD') + ' 23:59:59') > new Date()) {
          this.fetchOrRecord(res.data)
        } else {
          Toast.info('该模板已过截止日期！')
          setTimeout(() => {
            this.props.history.push('/module/testReport')
          }, 2000)
        }
      } else {
        this.setState({
          templateId: ''
        })
      }
    }
  }

  // 查询上传实验报告详情信息
  fetchRecord = async recordId => {
    const res = await findRecordId({ recordId })
    if (res.code === 20000) {
      this.setState({
        kcxhId: [res.data.xs_bjdm]
      })
    }
  }

  // 判断是否提交过实验报告
  fetchOrRecord = async item => {
    const res = await findStudentReordId({ templateId: item.template_id })
    if (res.code === 20000) {
      if (res.data) {
        this.fetchRecord(res.data)
        this.fetchCourse(item.template_xn, item.template_xq, item.template_id)
        this.setState({
          recordId: res.data
        })
      } else {
        this.fetchCourse(item.template_xn, item.template_xq)
      }
      this.setState({
        xn: item.template_xn,
        xqId: [item.template_xq],
        courseId: [item.template_id],
      })
    }
  }

  // 查询学生未上报实验记录的课程
  fetchCourse = async (xn, xq, templateId) => {
    const res = await findStudentCourseInfo({ xn, xq, templateId })
    if (res.code === 20000) {
      this.setState({
        courseData: (res.data || []).map(x => {
          return {
            value: x.template_id,
            label: `${x.template_kcmc}(${x.jzg_xm})`,
          }
        })
      })
    }
  }

  handleSubmit = async () => {
    const { courseId, xn, xqId, fileAddr, recordId, kcxhId } = this.state
    this.setState({
      disabled: true
    })
    if (!xn) return Toast.info('请选择学年！')
    if (!xqId.length) return Toast.info('请选择学期！')
    if (!courseId.length) return Toast.info('请选择课程！')
    if (!recordId) {
      if (!kcxhId.length) return Toast.info('请选择教学班代码！')
    }
    if (!fileAddr) return Toast.info('请上传实验报告文件！')
    const req = recordId ? {
      recordId, fileUrl: fileAddr
    } : {
      templateId: courseId[0], fileUrl: fileAddr, kcbh: kcxhId[0]
    }
    const method = recordId ? updateStudentReord : saveStudentReord
    const res = await method(req)
    if (res.code === 20000) {
      Toast.info(res.message)
      setTimeout(() => {
        this.props.history.push('/module/testReport')
      }, 1500)
    }
  }

  render() {
    const that = this
    const { courseData, courseId, xn, xqData, xqId, templateId, fileAddr, kcxhId, classData, recordId } = this.state
    const props = {
      name: 'file',
      action: IP.host + '/report/templateinfo/uploadStudentTemplateFile',
      data: { templateId: courseId.length && courseId.join(',') },
      headers: { 'Authorization': 'sastc ' + cookie.load('token') },
      showUploadList: false,
      onChange(info) {
        if (info.file.status !== 'uploading') {
          Toast.info('上传中', 0)
        }
        if (info.file.type.includes('pdf')) {

        } else {
          Toast.info("请上传pdf的文件！")
          return;
        }
        const uname = info.file.name.split('.')[0]
        const newData = uname.split('+').filter(x => x.length < 11)
        if (newData.length || uname.split('+').length > 2) {
          Toast.info("上传失败，报告文件名格式错误(学号姓名/学号姓名+学号姓名)！")
          return;
        }

        if (info.file.status === 'done') {
          Toast.info(info.file.response.message)
          that.setState({
            fileAddr: info.file.response.data
          })
        } else if (info.file.status === 'error') {
          Toast.fail(`${info.file.name} 文件上传失败.`);
        }
      }
    };
    return (
      <div className='testMb'>
        <div className='testMb_list'>
          <DatePicker
            mode="year"
            disabled={templateId ? true : false}
            extra={xn ? xn + '年' : '学年'}
            onChange={date => {
              xqId.length && this.fetchCourse(moment(date).format('YYYY'), xqId[0])
              this.setState({ xn: moment(date).format('YYYY'), courseId: [], kcxhId: [] })
            }}
          >
            <List.Item arrow="horizontal">学年</List.Item>
          </DatePicker>
        </div>
        <div className='testMb_list'>
          <Picker
            extra="学期"
            disabled={templateId ? true : false}
            data={xqData}
            cols={1}
            value={xqId}
            onChange={e => {
              xn && this.fetchCourse(xn, e[0])
              this.setState({ xqId: e, courseId: [], kcxhId: [] })
            }}
          >
            <List.Item arrow="horizontal">学期</List.Item>
          </Picker>
        </div>
        <div className='testMb_list'>
          <Picker
            extra="课程"
            data={courseData}
            disabled={templateId ? true : false}
            cols={1}
            value={courseId}
            onChange={e => {
              this.fetchCourseNumber(e[0])
              this.setState({ courseId: e, kcxhId: [] })
            }}
          >
            <List.Item arrow="horizontal">课程</List.Item>
          </Picker>
        </div>
        <div className='testMb_list'>
          <Picker
            extra="教学班代码"
            data={classData}
            disabled={recordId ? true : false}
            cols={1}
            value={kcxhId}
            onChange={e => this.setState({ kcxhId: e })}
          >
            <List.Item arrow="horizontal">教学班代码</List.Item>
          </Picker>
        </div>
        <div className='testMb_upload'>
          <div className='testMb_upload_title'>上传报告</div>
          {courseId.length ? (<Upload {...props}>
            <div className='testMb_upload_btn'>
              上传
            </div>
          </Upload>) : (<div className='testMb_upload_btn' onClick={() => {
            Toast.info("请先选择课程再上传文件！")
          }}>
            上传
          </div>)}

        </div>
        {
          fileAddr ? <div className='testMb_file'>
            {fileAddr.split('/')[fileAddr.split('/').length - 1]}
          </div> : ''
        }
        <div className='report_tips'>
          <div></div>
          <div>
            <span>小提示：</span>上传文件名为：学号姓名+学号姓名.pdf
          </div>
        </div>
        <div className='testMb_btn'>
          <div className='testMb_btn_list' onClick={() => {
            if (this.state.disabled) {
              return false;
            }
            this.handleSubmit()
          }}>保存</div>
        </div>
      </div>
    )
  }
}

export default TestBg
