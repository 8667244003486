import React, { Component } from 'react'
import { SearchBar, Pagination, Icon } from 'antd-mobile'
import { gasTypeAll } from './../../../../api/gas'
import moment from 'moment'
import './list.less'

class List extends Component {
  state = {
    paramKey: '',
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    total: 0,
    data: [],
    sourceData: [
      {
        label: '校级',
        value: 1,
      },
      {
        label: '院级',
        value: 2,
      },
      {
        label: '赠送',
        value: 3,
      },
      {
        label: '其他',
        value: 4,
      },
    ]
  }

  componentDidMount = () => {
    document.title = '气体分类列表'
    window.scrollTo(0, 0)
    this.fetch()
  }

  fetch = async () => {
    const { pageSize, pageNum, paramKey } = this.state
    const res = await gasTypeAll({ size: pageSize, page: pageNum, name: paramKey })
    if (res.code === 20000) {
      this.setState({
        data: res.data.list,
        pages: res.data ? (res.data.pages ? res.data.pages : 1) : 1,
        total: res.data.total,
      })
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className='gas_manage_list'>
        <div className='gas_manage_list_head_bg'>
          <div className='gas_manage_list_head'>
            <SearchBar placeholder="请输入分类名称" value={this.state.paramKey} onCancel={(e) => {
              this.setState({
                paramKey: ''
              }, () => {
                this.fetch()
              })
            }} onChange={e => {
              this.setState({
                paramKey: e
              }, () => {
                this.fetch()
              })
            }} />
          </div>
        </div>
        <div className='gas_manage_list_record'>
          {data.map((item, key) => {
            return (
              <div className='gas_type_list_item' key={key}>
                <div className='gas_type_title'>
                  {key % 2 === 0 ? <img src={require('./../../../../images/gas/03.png')} alt='' /> : <img src={require('./../../../../images/gas/04.png')} alt='' />}

                  {item.type_name}
                </div>
                <div className='gas_type_desc'>
                  {item.type_desc}
                </div>
                <div className='gas_manage_list_item_line'></div>
                <div className='gas_type_param'>
                  创&nbsp;&nbsp;建&nbsp;&nbsp;人<span>{item.create_user_name}</span>
                </div>
                <div className='gas_type_param'>
                  创建时间<span>{moment(item.create_time).format('YYYY-MM-DD HH:mm')}</span>
                </div>



              </div>
            )
          })}

          {this.state.data.length === 0 ? <div className='not-yet'>--暂无数据--</div> : ''}

          {
            this.state.total > 20 ? (<div className='gas_manage_manager_page'>
              <Pagination total={this.state.pages}
                current={this.state.pageNum}
                onChange={e => {
                  this.setState({
                    pageNum: e
                  }, () => {
                    window.scrollTo(0, 0)
                    this.fetch()
                  })
                }}
                locale={{
                  prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                  nextText: (<span className="arrow-align"><Icon type="right" /></span>),
                }}
              />
            </div>) : ''
          }

        </div>



        <div className='gas_manage_list_but'>
          <div onClick={() => {
            this.props.history.push('/module/gasCylinder/gasTypeAdd')
          }}>新增气体分类</div>
        </div>
      </div>
    )
  }
}

export default List
