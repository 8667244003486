import React, { Component } from 'react'
import { SearchBar, Pagination, Icon } from 'antd-mobile'
import { gasInfoAll } from './../../../../api/gas'
import './list.less'

class List extends Component {
  state = {
    paramKey: '',
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    total: 0,
    data: [],
    sourceData: [
      {
        label: '校级',
        value: 1,
      },
      {
        label: '院级',
        value: 2,
      },
      {
        label: '赠送',
        value: 3,
      },
      {
        label: '其他',
        value: 4,
      },
    ]
  }

  componentDidMount = () => {
    document.title = '气体分类列表'
    window.scrollTo(0, 0)
    this.fetch()
  }

  fetch = async () => {
    const { pageSize, pageNum, paramKey } = this.state
    const res = await gasInfoAll({ size: pageSize, page: pageNum, name: paramKey })
    if (res.code === 20000) {
      this.setState({
        data: res.data.list,
        pages: res.data ? (res.data.pages ? res.data.pages : 1) : 1,
        total: res.data.total,
      })
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className='gas_manage_list'>
        <div className='gas_manage_list_head_top' style={{ marginTop: 0 }}>
          <SearchBar placeholder="请输入气体名称" value={this.state.paramKey} onCancel={(e) => {
            this.setState({
              paramKey: ''
            }, () => {
              this.fetch()
            })
          }} onChange={e => {
            this.setState({
              paramKey: e
            }, () => {
              this.fetch()
            })
          }} />
        </div>
        <div className='gas_list_record'>
          {data.map((item, key) => {
            return (
              <div className='gas_list_item' key={key}>
                <div className='gas_list_item_title'>
                  {item.gas_name}（{item.gas_formula}）
                </div>
                <div className='gas_list_item_tag'>
                  {item.gas_type_name}
                </div>
                <div className='gas_manage_list_item_line'></div>
                <div className='gas_list_item_param'>
                  <div>外观</div>
                  <div>{item.gas_appearance}</div>
                </div>
                <div className='gas_list_item_param'>
                  <div>熔点</div>
                  <div>{item.gas_melt}</div>
                </div>
                <div className='gas_list_item_param'>
                  <div>沸点</div>
                  <div>{item.gas_boiling}</div>
                </div>


              </div>
            )
          })}

          {this.state.data.length === 0 ? <div className='not-yet'>--暂无数据--</div> : ''}

          {
            this.state.total > 20 ? (<div className='gas_manage_manager_page'>
              <Pagination total={this.state.pages}
                current={this.state.pageNum}
                onChange={e => {
                  this.setState({
                    pageNum: e
                  }, () => {
                    window.scrollTo(0, 0)
                    this.fetch()
                  })
                }}
                locale={{
                  prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                  nextText: (<span className="arrow-align"><Icon type="right" /></span>),
                }}
              />
            </div>) : ''
          }

        </div>



        <div className='gas_manage_list_but'>
          <div onClick={() => {
            this.props.history.push('/module/gasCylinder/gasAdd')
          }}>新增气体</div>
        </div>
      </div>
    )
  }
}

export default List
