
import React, { Component } from 'react';
import { Icon } from 'antd-mobile';
import { findAppointOrder } from '../../../../api/repairApi'
import { createForm } from 'rc-form';
import moment from 'moment'
import './index.less'

class DistributeWorkOrders extends Component {
    state = {
        visible: false,
        dataList: [],
        state: 0
    }

    componentDidMount() {
        window.document.title = '派发工单'
        this.findAppointOrder()
    }

    //查询所有任务
    findAppointOrder = () => {
        // 保养状态:0待保养 1 保养中 2 已完成
        findAppointOrder().then(res => {
            if (res.code === 20000) {
                this.setState({
                    dataList: res.data || []
                })
            }
        })
    }
    onSelect = async (otp) => {
        await this.findAppDistributeWorkOrders(otp.props.value)
        await this.setState({
            visible: false
        })
    }
    render() {
        const { dataList } = this.state
        const style = (state) => {
            if (state === 0) {//0待保养 1 保养中 2 已完成
                return 'content_title_botton_dai'
            } else if (state === 1) {
                return 'content_title_botton_yi'
            } else if (state === 2) {
                return 'content_title_botton_over'
            } else {
                return 'content_title_botton_all'
            }
        }
        return (
            <div className="DistributeWorkOrders">
                <div className="DistributeWorkOrders_box">
                    <div className="DistributeWorkOrders_content">
                        {
                            (dataList || []).map((item, index) => {
                                return <div key={index} onClick={() => { this.props.history.push(`/module/smartRepair/WorkOrdersDetail/${item.order_id}`) }} className="content">
                                    <div className="content_title">
                                        <div className="content_title_code">{item.order_id}</div>
                                        <div className={style(2)}>
                                            <span className="span_02">已审批</span>
                                            <span className="span_03"><Icon type='right' /></span>
                                        </div>
                                    </div>
                                    <div className='maintenanceTask_cont'>
                                        <div className='maintenanceTask_cont_item'>
                                            <div>类型</div>
                                            <div>{item.fault_type_name}</div>
                                        </div>
                                        <div className='maintenanceTask_cont_item'>
                                            <div>问题描述</div>
                                            <div>{item.description}</div>
                                        </div>
                                        <div className='maintenanceTask_cont_item'>
                                            <div>位置</div>
                                            <div>{(item.building_name ? item.building_name : '') + ' ' + (item.room_name ? item.room_name : '')}</div>
                                        </div>
                                        <div className='maintenanceTask_cont_item'>
                                            <div>报修人</div>
                                            <div>{item.user_name}</div>
                                        </div>

                                    </div>
                                    <div className="maintenanceTask_time">
                                        {moment(item.create_time).format('YYYY-MM-DD ')}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
                {dataList.length === 0 ? (<div className="title-bottom">--暂无工单--</div>) : ''}

            </div>
        );
    }
}

export default createForm()(DistributeWorkOrders);