import React, { Component } from 'react';
import { InputItem, List, DatePicker, Toast, Checkbox } from 'antd-mobile'
import { getDeviceLockById, req_offonlinePwd } from '../../../api/api'
import moment from 'moment'
import './door_lock.less'

const CheckboxItem = Checkbox.CheckboxItem;

class LinePassword extends Component {

  state = {
    showPassword: '待生成', // 离线密码
    roomData: {}, // 房间信息
    username: '', // 使用人名称
    items: [
      { value: 'xs', name: '限时密码' },
      { value: 'dc', name: '单次密码' },
    ],
    judgeSelType: 'xs',
    dateTime: null, // 失效时间
    deviceId: '', // 锁序列号
  }

  componentDidMount = () => {
    this.findDetails()
  }

  findDetails = async () => {
    const res = await getDeviceLockById({ deviceId: this.props.match.params.id })
    if (res.code === 20000) {
      this.setState({
        dateTime: new Date(moment().subtract(30, "minute")),
        roomData: res.data,
        deviceId: res.data.device_id
      })
    }
  }

  handleSubmit = async () => {
    Toast.loading("生成离线密码中...", 0)
    const { judgeSelType, username, deviceId, dateTime } = this.state
    if (!username) return Toast.info('请输入使用人名称！')
    var typeNum = 0;
    if (judgeSelType === 'xs') {
      typeNum = 0;
    } else if (judgeSelType === 'dc') {
      typeNum = 1;
    }
    const res = await req_offonlinePwd({
      deviceId: deviceId,
      endTime: moment(dateTime).format('YYYY-MM-DD HH:mm:ss'),
      flag: typeNum,
      userName: username
    })
    if (res.code === 20000) {
      if (typeNum === 0) {
        Toast.success(`生成离线密码${res.data}，有效期至${moment(dateTime).format('YYYY-MM-DD HH:mm:ss')}`, 5)
      } else {
        Toast.success(`生成离线密码${res.data}，为单次密码，使用一次后失效`, 5)
      }
      this.setState({
        username: '',
        showPassword: res.data
      })
    }
  }

  render() {
    const { showPassword, roomData, username, items, judgeSelType, dateTime } = this.state
    return (
      <div className="linePassword">
        <div className="linePassword-sj">
          <div className="linePassword-sjbor">
            <div className="linePassword-sjbor-text">{showPassword}</div>
          </div>
          <div className="linePassword-change">
            房间：{roomData.device_building_name}{roomData.device_floor_name}{roomData.device_room_name}-{roomData.device_door_name}
          </div>
        </div>
        <div className="linePassword-gn">
          <div className="linePassword-gn-item">
            <InputItem className="linePassword-pwd-input" placeholder='请输入使用人名称' value={username} onChange={e => this.setState({ username: e })}>使用人名称</InputItem>
          </div>
          <div className="linePassword-gn-item">
            <InputItem className="linePassword-pwd-input" disabled value="以申请时间为准">生效时间</InputItem>
          </div>
          <div className="linePassword-gn-item linePassword-gn-items">
            <div className="linePassword-gn-item-title">密码类型</div>
            <div className="linePassword-gn-item-timegroup">
              {
                items.map((val, key) => {
                  return (
                    <CheckboxItem className="linePassword-check linePassword-check1" checked={judgeSelType === val.value} key={key} onChange={() => {
                      this.setState({
                        judgeSelType: val.value
                      })
                    }}>
                      {val.name}
                    </CheckboxItem>
                  )
                })
              }
            </div>
          </div>
          {
            judgeSelType === 'xs' ? (<div className="linePassword-gn-item">
              <DatePicker
                mode="datetime"
                value={dateTime}
                onChange={date => this.setState({ dateTime: date })}
              >
                <List.Item arrow="horizontal" className="linePassword-pwd-date">失效时间</List.Item>
              </DatePicker>

            </div>) : ('')
          }
          <div className="linePassword-gn-toast">点击申请离线限时密码, 生效时间以申请时间为准，失效时间默认为半小时后</div>
          <div className="linePassword-btn">
            <div className="linePassword-btn-content" onClick={() => this.handleSubmit()}>申 请</div>
          </div>
        </div>
      </div>
    );
  }
}

export default LinePassword;