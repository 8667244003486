import React, { Component } from 'react'
import { req_getDetailsById } from '../../../api/repairApi'
import './create_patrol.less'
import IP from '../../../config/config'
import moment from 'moment'

class PatrolDetail extends Component {
  state = {
    detailInfo: {},
    visible: false,
    fileUrl: ''
  }

  componentDidMount = () => {
    document.title = '巡检详情'
    this.fetchPatrolDetail()
  }

  //进入页面获取该房间的巡检配置情况
  fetchPatrolDetail = async () => {
    const detailsId = this.props.match.params.id
    const res = await req_getDetailsById({ id: detailsId })
    if (res.code === 20000) {
      this.setState({
        detailInfo: res.data,
      })
    }
  }

  render() {
    const { detailInfo } = this.state
    return (
      <div className='createPatrol patrolDetail'>
        <img src={require('../../../images/patrol/4.png')} width='100%' alt="" />

        <div className="listWrap mt-12">
          <div className='listStyl'>
            <div className="left">巡检点名称</div>
            <div className="right">{detailInfo.building_name + detailInfo.floor_name + detailInfo.room_name}</div>
          </div>
          {detailInfo.class_name ? (<div className='listStyl'>
            <div className="left">安全级别</div>
            <div className="right">{detailInfo.class_name}</div>
          </div>
          ) : ''}
        </div>

        <div className="listWrap mt-12">
          {detailInfo.task_title ? (<div className='listStyl'>
            <div className="left">巡检标题</div>
            <div className="right">{detailInfo.task_title}</div>
          </div>) : ''}

          {detailInfo.task_name ? (<div className='listStyl'>
            <div className="left">巡检批次</div>
            <div className="right">{detailInfo.task_name}</div>
          </div>) : ''}

          {detailInfo.dept_name ? (<div className='listStyl'>
            <div className="left">所属院系</div>
            <div className="right">{detailInfo.dept_name}</div>
          </div>) : ''}
        </div>

        <div className="listWrap mt-12">
          {detailInfo.fzr_name ? (<div className='listStyl'>
            <div className="left">巡检总负责人</div>
            <div className="right">{detailInfo.fzr_name}</div>
          </div>) : ''}
          <div className='listStyl'>
            <div className="left">巡检人</div>
            <div className="right">{detailInfo.user_name}</div>
          </div>
          <div className='listStyl'>
            <div className="left">巡检人签字</div>
            <div className="right">
              {detailInfo.details_qz && detailInfo.details_qz.split('/').length > 1 ? (<img src={IP.fileHost + detailInfo.details_qz} alt='' style={{ height: '40px' }} onClick={() => {
                this.setState({
                  visible: true,
                  fileUrl: detailInfo.details_qz,
                })
              }} />) : detailInfo.details_qz}
            </div>
          </div>
        </div>

        <div className="listWrap mt-12">
          <div className='listStyl'>
            <div className="left">隐患类型</div>
            <div className="right">
              {detailInfo.details_state === 1 ? '无隐患' : ''}
              {detailInfo.details_state === 2 ? '一般隐患' : ''}
              {detailInfo.details_state === 3 ? '较大隐患' : ''}
              {detailInfo.details_state === 4 ? '严重隐患' : ''}
            </div>
          </div>
          <div className='listStyl'>
            <div className="left">整改状态</div>
            <div className="right">
              {detailInfo.hidden_result === -2 ? '不属实隐患' : ''}
              {detailInfo.hidden_result === -1 ? '待核实隐患' : ''}
              {detailInfo.hidden_result === 0 ? '待整改' : ''}
              {detailInfo.hidden_result === 1 ? '已整改待确认' : ''}
              {detailInfo.hidden_result === 2 ? '整改完成' : ''}
              {detailInfo.hidden_result === 3 ? '整改不合格' : ''}
              {detailInfo.hidden_result === 4 ? '暂无法整改' : ''}
              {detailInfo.hidden_result === 5 ? '隐患撤回' : ''}
            </div>
          </div>
          {detailInfo.hidden_result > 0 ? (<div className='listStyl'>
            <div className="left">隐患是否属实</div>
            <div className="right">
              属实
            </div>
          </div>) : ''}
        </div>

        <div style={{ marginTop: '12px' }}>
          <div className='patrol_danger'>
            <div className='patrol_danger_head'>
              <div>
                <img src={require('./../../../images/patrol/6.png')} alt='' />
                整改前
              </div>
              <div>
                {moment(detailInfo.complete_time).format('YYYY-MM-DD HH:mm')} 提交
              </div>
            </div>
            <div className='patrol_danger_main'>
              {detailInfo && detailInfo.dangerEntityList && detailInfo.dangerEntityList.map((item, key) => {
                return <div className='patrol_danger_item' key={key}>
                  <div className='patrol_danger_title'>
                    <span>隐患{key + 1}：</span>{item.danger_title}
                  </div>
                  <div className='patrol_danger_desc'>
                    {item.danger_content}
                  </div>
                  <div className='patrol_danger_pic'>
                    <img src={IP.fileHost + item.danger_file} alt='' onClick={() => {
                      this.setState({
                        visible: true,
                        fileUrl: item.danger_file,
                        type: 1,
                      })
                    }} />
                  </div>
                </div>
              })}
            </div>
          </div>

        </div>

        {detailInfo.hidden_result > 0 && detailInfo.hidden_result !== 4 ? (<div className='patrol_change'>
          <div className='patrol_danger_head'>
            <div>
              <img src={require('./../../../images/patrol/7.png')} alt='' />
              整改后
            </div>
            <div>
              <span>{detailInfo.dispose_user_name}</span>
              {moment(detailInfo.dispose_time).format('YYYY-MM-DD HH:mm')}整改
            </div>
          </div>
          <div className='patrol_danger_main'>
            <div className='patrol_danger_item'>
              <div className='patrol_danger_desc'>
                {detailInfo.dispose_content}
              </div>
              <div className='patrol_danger_pic'>
                {detailInfo && detailInfo.disposeFileList && detailInfo.disposeFileList.map(item => {
                  return (
                    <img src={IP.fileHost + item.file_url} alt='' key={item.file_id} onClick={() => {
                      this.setState({
                        visible: true,
                        type: 1,
                        fileUrl: item.file_url
                      })
                    }} />
                  )
                })}
              </div>
            </div>
          </div>
        </div>) : ''}
        {
          detailInfo.hidden_result === 2 || detailInfo.hidden_result === 3 ? (<div className="listWrap mt-12">
            <div className='listStyl'>
              <div className="left">验收人</div>
              <div className="right">{detailInfo.check_user_name}</div>
            </div>
            <div className='listStyl'>
              <div className="left">验收结果</div>
              <div className="right">{detailInfo.hidden_result === 2 ? '整改合格' : '整改不合格'}</div>
            </div>
            <div className='listStyl'>
              <div className="left">验收描述</div>
              <div className="right">
                {detailInfo.check_content ? detailInfo.check_content : '无'}
              </div>
            </div>
            <div className='listStyl'>
              <div className="left">验收时间</div>
              <div className="right">{moment(detailInfo.check_time).format('YYYY-MM-DD HH:mm:ss')}</div>
            </div>


          </div>) : ''
        }


        {
          this.state.visible ? <div className='detailImg' onClick={() => this.setState({ visible: false })}>
            <img alt='' src={IP.fileHost + this.state.fileUrl} style={{ width: '80%' }} />
          </div> : ''
        }

      </div>
    )
  }
}
export default PatrolDetail