import React, { Component } from 'react'
import { DatePicker, List, Toast } from 'antd-mobile'
import { listCmdRecordCurtain } from '../../../api/api'
import moment from 'moment'

class CurtainOperation extends Component {

  state = {
    start_date: '',
    end_date: '',
    deviceImei: '',
    data: [],
  }

  componentDidMount = () => {
    document.title = "智慧窗帘-操作记录"
    this.setState({
      start_date: new Date(this.fun_date(-2)),
      end_date: new Date(),
      deviceImei: this.props.match.params.id,
    }, () => {
      this.fetch()
    })
  }

  fetch = async () => {
    Toast.loading('', 0)
    const { deviceImei, start_date, end_date } = this.state
    const res = await listCmdRecordCurtain({ deviceImei, startTime: start_date ? moment(start_date).format('YYYY-MM-DD') : '', endTime: end_date ? moment(end_date).format('YYYY-MM-DD') : '' })
    if (res.code === 20000) {
      Toast.hide()
      this.setState({
        data: res.data
      })
    }
  }

  // 获取几天前的日期
  fun_date = (num) => {
    var date1 = new Date()
    var date2 = new Date(date1)
    date2.setDate(date1.getDate() + num)
    //num是正数表示之后的时间，num负数表示之前的时间，0表示今天
    var time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate()
    return time2
  }

  render () {
    const { start_date, end_date, data } = this.state
    return (
      <div className='operation_records'>
        <div className='operation_records_head'>
          <div className='operation_records_head_date'>
            <DatePicker
              mode="date"
              title="开始时间"
              extra="开始时间"
              value={start_date}
              maxDate={end_date ? end_date : null}
              onChange={date => {
                this.setState({
                  start_date: date
                }, () => {
                  this.fetch()
                })
              }}
            >
              <List.Item arrow="horizontal"></List.Item>
            </DatePicker>
            <div style={{ background: '#f4f6f8' }}>至</div>
            <DatePicker
              mode="date"
              title="结束时间"
              extra="结束时间"
              value={end_date}
              minDate={start_date ? start_date : null}
              onChange={date => {
                this.setState({
                  end_date: date
                }, () => {
                  this.fetch()
                })
              }}
            >
              <List.Item arrow="horizontal"></List.Item>
            </DatePicker>
          </div>

        </div>
        <div className='operation_records_body ac'>

          {
            (data || []).length ? data.map(item => {
              return <div className='operation_records_item' key={item.record_id}>
                <div class="top">
                  <div>{item.cmd_operate_time ? moment(item.cmd_operate_time).format('YYYY-MM-DD HH:mm:ss') : ''}</div>
                  <div>{item.cmd_operate_user}</div>
                </div>
                <div className='content'>
                  <div className='cmd_data_status'>
                    <div>
                      {item.cmd_data === "read" ? "读取" : ""}
                      {item.cmd_data === "stop" ? "停止" : ""}
                      {item.cmd_data === "open" ? "开" : ""}
                      {item.cmd_data === "close" ? "关" : ""}
                      {item.cmd_data === "open%" ? "窗帘位置百分比" : ""}
                      {item.cmd_data === "scxc" ? "电机校准" : ""}
                      {item.cmd_data === "djfx" ? "电机方向" : ""}
                      {item.cmd_data === "djsd" ? "手拉使能" : ""}
                    </div>
                  </div>
                  <div className='other_status'>
                    <div className=''>
                      {item.cmd_param ? item.cmd_param : '--'}
                    </div>

                  </div>
                </div>
              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
      </div>
    )
  }
}

export default CurtainOperation