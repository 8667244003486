import React, { Component } from 'react'
import { TreeSelect, Radio } from 'antd'
import { Picker, List, InputItem, TextareaItem, Toast, DatePicker } from 'antd-mobile';
import { findZxOrRoomByDwOrZx } from './../../../api/whp'
import { findSpaceByLab } from './../../../api/api'
import { req_listAllDepartmentInfo, req_findCourseRecord, req_listEquipment, req_insert } from './../../../api/device'
import './index.less'
import moment from 'moment';

const { TreeNode } = TreeSelect;

class Add extends Component {
  state = {
    slideId: '',
    startX: '',
    visible: false,
    tab_id: '',
    sqfxData: [],//方向
    xydm: undefined,//所属学院
    zxid: undefined,//所属中心
    jsid: undefined,//所属实验室
    jfly: undefined,//经费来源
    jfh: '',//经费号
    sgfxdm: '',//申请方向
    sgrdh: '',
    sgyt: '',
    feedback: '',
    typeData: [],//类别
    whpFlbh: '',//化学品类别
    chemicalData: [],//化学品
    depTreeData: [],
    depData: [],
    centerData: [],//中心
    roomData: [],//房间
    masterProcess: 0, // 是否多人同时审批
    approveVisible: false,
    spry: undefined,
    jflyData: [],
    fileInfo: [],
    deviceData: [],
    equipment_code: '',
    courseData: '',
    course_code: '',
    use_date: new Date(),
    run_state: 1
  }

  componentDidMount = () => {
    document.title = '仪器使用'
    window.scrollTo(0, 0)
    this.fetchDep()
  }

  fetchSpace = async () => {
    const res = await findSpaceByLab()
    if (res.code === 20000) {
      let menuList = (res.data || []).map(item => {
        return {
          key: item.dwdm,
          title: item.dwmc,
          parentId: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
          disabled: item.bs === 3 ? false : true
        }
      })
      menuList = menuList.map(item => {
        item.children = menuList.filter(p => p.parentId === item.key)
        return item
      }).filter(p => p.parentId === '0')
      this.setState({
        buildData: menuList,
        spaceData: res.data
      })
    }
  }

  // 查询学院/部门
  fetchDep = async () => {
    const res = await req_listAllDepartmentInfo({ dwlx: 1 })
    if (res.code === 20000) {
      let menuList = res.data
      menuList = menuList.map(a => {
        return {
          title: a.dwmc,
          key: a.dwdm,
          parentId: a.sj_dm,
          value: a.dwdm,
          disabled: a.sj_dm === '0' ? true : false
        }
      })
      menuList = menuList.map(m => {
        m.children = menuList.filter(p => p.parentId === m.key)
        return m
      }).filter(x => !res.data.find(v => v.dwdm === x.parentId))
      this.setState({
        depTreeData: menuList,
        depData: res.data,
      })
    }
  }

  // 选择部门/学院
  handleChangeDep = async e => {
    const res = await findZxOrRoomByDwOrZx({ flag: 1, param: e })
    if (res.code === 20000) {
      this.setState({
        centerData: res.data.map(item => {
          return {
            label: item.room_name,
            value: item.room_id,
          }
        })
      })
    }
  }

  // 选择中心
  handleChangeCenter = async e => {
    const res = await findZxOrRoomByDwOrZx({ flag: 2, param: e.join(',') })
    if (res.code === 20000) {
      this.setState({
        roomData: res.data.map(item => {
          return {
            label: item.room_name,
            value: item.room_id,
          }
        })
      })
    }
  }

  // 提交
  submit = async () => {
    const { equipment_code, courseData, jsid, course_code, use_date, run_state, feedback } = this.state
    if (jsid === undefined) {
      Toast.info('请选择实验室！')
      return false;
    }
    if (equipment_code === '') {
      Toast.info('请选择使用仪器！')
      return false;
    }

    if (course_code === '') {
      Toast.info('请选择课程名称！')
      return false;
    }

    const newData = courseData.find(x => x.value === course_code.join(','))
    const params = {
      equipment_code: equipment_code.join(','),
      course_code: course_code.join(','),
      course_name: courseData.find(x => x.value === course_code.join(',')).label,
      teacher_code: newData.gh,
      teacher_name: newData.xm,
      use_section: newData.skjc,
      use_time: newData.times,
      use_date: moment(use_date).format('YYYY-MM-DD'),
      run_state,
      feedback,
    }
    const res = await req_insert(params)
    if (res.code === 20000) {
      Toast.info("提交成功！")
      setTimeout(() => {
        this.props.history.goBack()
      }, 2000);
    }
  }


  // 查询该实验室本学期所有课程
  _findCourseRecord = async (roomId) => {
    const res = await req_findCourseRecord({ roomId })
    if (res.code === 20000) {
      const data = res.data.map(item => {
        return {
          ...item,
          label: item.courseName,
          value: item.courseCode,
        }
      })
      this.setState({
        courseData: data,
      })
    }
  }

  // 查询已认领设备
  _listEquipment = async (roomId) => {
    const res = await req_listEquipment({ roomId })
    if (res.code === 20000) {
      const data = res.data.map(item => {
        return {
          label: item.yqmc,
          value: item.yqbh,
        }
      })
      this.setState({
        deviceData: data
      })
    }
  }

  render() {
    const { feedback, depTreeData, xydm, centerData, zxid, roomData, jsid, deviceData, equipment_code, courseData, course_code, use_date, run_state } = this.state
    const loop = data =>
      data.map(item => {
        if (item.children && item.children.length) {
          return (
            <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled} />;
      });
    return (
      <div className='subscription_add'>
        <div className='subscription_add_title'>

        </div>
        <div className='subscription_add_form'>
          <div className='subscription_add_tree'>
            <div>
              <span style={{ color: 'red' }}>* </span>申请所属学院
            </div>
            <TreeSelect
              showSearch
              treeNodeFilterProp='title'
              placeholder="请选择"
              style={{ width: '100%' }}
              value={xydm}
              onChange={(e) => {
                this.setState({
                  xydm: e,
                  zxid: undefined,
                  jsid: undefined,
                })
                this.handleChangeDep(e)
              }}
            >
              {loop(depTreeData)}
            </TreeSelect>
          </div>
          <div className='subscription_add_picker'>
            <Picker
              data={centerData}
              value={zxid}
              cols={1}
              onChange={(e) => {
                this.setState({
                  zxid: e,
                  jsid: undefined
                })
                this.handleChangeCenter(e)
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>所属中心</List.Item>
            </Picker>
          </div>
          <div className='subscription_add_picker'>
            <Picker
              data={roomData}
              value={jsid}
              cols={1}
              onChange={(e) => {
                this.setState({
                  jsid: e
                })
                this._findCourseRecord(e.join(','))
                this._listEquipment(e.join(','))
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>所属实验室</List.Item>
            </Picker>
          </div>
        </div>

        <div className='subscription_add_form'>
          <div className='subscription_add_picker'>
            <Picker
              data={deviceData}
              value={equipment_code}
              cols={1}
              onChange={(e) => {
                this.setState({
                  equipment_code: e
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>使用仪器</List.Item>
            </Picker>
          </div>
          <div className='subscription_add_picker'>
            <Picker
              data={courseData}
              value={course_code}
              cols={1}
              onChange={(e) => {
                this.setState({
                  course_code: e
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>课程名称</List.Item>
            </Picker>
          </div>

          <div className='subscription_add_date'>
            <DatePicker
              mode="date"
              extra={'请选择'}
              value={use_date}
              onChange={date => {
                this.setState({
                  use_date: date
                })
              }}
            >
              <List.Item arrow="horizontal">
                <span style={{ color: 'red' }}>* </span>使用日期
              </List.Item>
            </DatePicker>
          </div>
          <div className='subscription_add_radio'>
            <div>
              <span style={{ color: 'red' }}>* </span>设备运行状态
            </div>
            <Radio.Group value={run_state} onChange={(e) => {
              this.setState({
                run_state: e.target.value
              })
            }}>
              <Radio value={1}>正常</Radio>
              <Radio value={2}>异常</Radio>
            </Radio.Group>
          </div>

        </div>



        <div className='subscription_add_form'>
          <div className='subscription_add_input'>
            <InputItem placeholder="自动获取" value={course_code} disabled><span style={{ color: '#fff' }}>* </span>教师名称</InputItem>
          </div>
          <div className='subscription_add_input'>
            <InputItem placeholder="自动获取" value={course_code} disabled><span style={{ color: '#fff' }}>* </span>使用节次</InputItem>
          </div>
          <div className='subscription_add_input'>
            <InputItem placeholder="自动获取" value={course_code} disabled><span style={{ color: '#fff' }}>* </span>使用时间段</InputItem>
          </div>
        </div>



        <div className='subscription_add_form'>
          <div className='subscription_add_text'>
            <div><span style={{ color: 'white' }}>* </span>建议反馈</div>
            <TextareaItem
              placeholder="请输入"
              rows={5}
              value={feedback}
              onChange={(e) => {
                this.setState({
                  feedback: e
                })
              }}
            />
          </div>
        </div>

        <div className='subscription_list_but'>
          <div onClick={() => {
            this.submit()
          }}>提交</div>
        </div>


      </div >
    )
  }
}

export default Add
