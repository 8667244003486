import React, { Component } from 'react'
import { SearchBar, Pagination, Icon, } from 'antd-mobile'
import { listWaitCk, findCkjl } from './../../../../api/gas'
import moment from 'moment'

class List extends Component {
  state = {
    paramKey: '',
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    total: 0,
    data: [],
    tabs_id: 1,
  }

  componentDidMount = () => {
    document.title = '出库管理'
    window.scrollTo(0, 0)
    this.fetch()
  }

  fetch = async () => {
    const { pageSize, pageNum, paramKey, tabs_id } = this.state
    const method = tabs_id === 1 ? listWaitCk : findCkjl
    const res = await method({ size: pageSize, page: pageNum, paramKey })
    if (res.code === 20000) {
      this.setState({
        data: res.data.info.list,
        pages: res.data.info ? (res.data.info.pages ? res.data.info.pages : 1) : 1,
        total: res.data.info.total,
      })
    }
  }

  render() {
    const { data, tabs_id } = this.state
    return (
      <div className='gas_manage_list'>
        <div className='gas_manage_list_head_top gas_manage_list_head_manage'>
          <SearchBar placeholder="请输入出库单号" value={this.state.paramKey} onCancel={(e) => {
            this.setState({
              paramKey: ''
            }, () => {
              this.fetch()
            })
          }} onChange={e => {
            this.setState({
              paramKey: e
            }, () => {
              this.fetch()
            })
          }} />
          <div className='gas_manage_list_head_tabs'>
            <div className={tabs_id === 1 ? 'gas_manage_list_head_tabs_selected' : 'gas_manage_list_head_tabs_item'} onClick={() => {
              this.setState({
                tabs_id: 1
              }, () => {
                this.fetch()
              })
            }}>
              <div>出库登记</div>
              <div></div>
            </div>
            <div className={tabs_id === 2 ? 'gas_manage_list_head_tabs_selected' : 'gas_manage_list_head_tabs_item'} onClick={() => {
              this.setState({
                tabs_id: 2
              }, () => {
                this.fetch()
              })

            }}>
              <div>出库记录</div>
              <div></div>
            </div>
          </div>
        </div>

        <div className='gas_warehousing_list_record'>
          {data.map((item, key) => {
            return (
              <div className='gas_warehousing_list_item' key={key} onClick={() => {
                this.props.history.push(`/module/gasCylinder/outboundRecord/${item.lybh}`)
              }}>
                <div className='gas_warehousing_head'>
                  <div>
                    出库单号 {item.lybh}
                  </div>
                  <div className='gas_warehousing_dep'>
                    {item.xymc}
                  </div>

                </div>
                <div className='gas_warehousing_address'>
                  {item.xymc}-{item.zxmc}-{item.jsmc}
                </div>
                <div className='gas_warehousing_tag'>
                  <div>{item.lyrmc1}、{item.lyrmc2}</div>
                  <div>气体种类{item.whpzlsl}种</div>
                </div>
                <div className='gas_warehousing_date'>
                  {item.lysqsj ? moment(item.lysqsj).format('YYYY-MM-DD HH:mm:ss') : ''}
                </div>
              </div>
            )
          })}

          {this.state.data.length === 0 ? <div className='not-yet'>--暂无数据--</div> : ''}

          {
            this.state.total > 20 ? (<div className='subscription_manager_page'>
              <Pagination total={this.state.pages}
                current={this.state.pageNum}
                onChange={e => {
                  this.setState({
                    pageNum: e
                  }, () => {
                    window.scrollTo(0, 0)
                    this.fetch()
                  })
                }}
                locale={{
                  prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                  nextText: (<span className="arrow-align"><Icon type="right" /></span>),
                }}
              />
            </div>) : ''
          }

        </div>
      </div>
    )
  }
}

export default List
