import React, { Component } from 'react'
import { Toast, Flex, Modal, InputItem, Icon } from 'antd-mobile'
import { findSaftyType } from '../../../api/safty'
import { savePractiveRecord } from '../../../api/api'

export default class componentName extends Component {
  state = {
    typeData: [],
    categoryIds: [0],
    flag: 1,    //  1不限2限制
    num: 40,
    radioNum: 0,
    multiNum: 0,
    estimateNum: 0,
    visible: false,
    currentTiXing: 0, //1单选题2多选题3判断题
    currentTiNum: 0,
    numData: [10, 20, 30, 40]
  }
  componentDidMount () {
    this.fetchType()
  }
  fetchType = async () => {
    const res = await findSaftyType()
    if (res.code === 20000) {
      this.setState({
        typeData: res.data
      })
    }
  }
  setcategoryIds = (id) => {
    let { categoryIds } = this.state

    //限制类型题,已经选中过要删除这项
    const delId = (idx) => {
      categoryIds.splice(idx, 1)
      if (categoryIds.length === 0) {
        categoryIds = [0]
      }
    }
    // 选中的为不为0,之前有没有选中
    if (id === 0) {
      categoryIds = [0]
    } else {
      let idx = categoryIds.indexOf(id)
      let pos = categoryIds.indexOf(0)
      pos > -1 ? categoryIds = [id] : idx > -1 ? delId(idx) : categoryIds.push(id)
    }
    this.setState({
      categoryIds
    })
  }
  getChoosedState = (id) => {
    const { categoryIds } = this.state
    return categoryIds.indexOf(id) > -1 ? 'choosed' : ''
  }
  showModal = (e, currentTiXing) => {
    e.preventDefault() // 修复 Android 上点击穿透
    const setTiNum_0 = () => {
      this.setState({
        currentTiNum: 0
      })
    }
    const { num, radioNum, multiNum, estimateNum } = this.state
    if (currentTiXing === 0) {
      if (num > 0) {
        this.setState({
          currentTiNum: num
        })
      } else {
        setTiNum_0()
      }
    }
    if (currentTiXing === 1) {
      if (radioNum > 0) {
        this.setState({
          currentTiNum: radioNum
        })
      } else {
        setTiNum_0()
      }
    }
    if (currentTiXing === 2) {
      if (multiNum > 0) {
        this.setState({
          currentTiNum: multiNum
        })
      } else {
        setTiNum_0()
      }
    }
    if (currentTiXing === 3) {
      if (estimateNum > 0) {
        this.setState({
          currentTiNum: estimateNum
        })
      } else {
        setTiNum_0()
      }
    }
    this.setState({
      visible: true,
      currentTiXing,
    })
  }
  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  setNum = (value) => {
    this.setState({
      currentTiNum: value
    })
  }
  setTiXingNumObj = () => {
    const { currentTiXing, currentTiNum } = this.state
    if (!(currentTiNum - 0)) {
      Toast.info('数量必须大于0 !', 2)
      return
    }
    if (currentTiNum) {
      switch (currentTiXing) {
        case 0:
          this.setState({
            flag: 1,
            num: currentTiNum,
            radioNum: 0,
            multiNum: 0,
            estimateNum: 0
          })
          break
        case 1:
          this.setState({
            flag: 2,
            radioNum: currentTiNum,
            num: 0,
            flag: 2
          })
          break
        case 2:
          this.setState({
            flag: 2,
            multiNum: currentTiNum,
            num: 0,
            flag: 2
          })
          break
        case 3:
          this.setState({
            flag: 2,
            estimateNum: currentTiNum,
            num: 0,
            flag: 2
          })
          break
        default:
          break
      }
    }

    this.setState({
      visible: false
    })
  }
  goPractice = async () => {
    const { categoryIds, flag, num, radioNum, multiNum, estimateNum } = this.state
    console.log({ categoryIds, flag, num, radioNum, multiNum, estimateNum })
    // if (categoryIds.join(',')) {
    //   await savePractiveRecord({
    //     categoryIds: categoryIds.join(','),
    //     answer_number: flag === 1 ? num : ((radioNum || 0) + (multiNum || 0) + (estimateNum || 0))
    //   })
    // }
    this.props.history.push(`/module/safty/practice/online/${categoryIds.join(',')}/${flag}/${num}/${radioNum}/${multiNum}/${estimateNum}`)
  }


  render () {
    const { typeData, num, radioNum, multiNum, estimateNum, flag, visible, numData, currentTiXing, currentTiNum } = this.state
    return (
      <div className='saftyModule examPractice'>
        <div className='content_wrap'>
          <div className="floor floor_1">
            <p>安全类别</p>
            <Flex wrap="wrap">
              <div className='hor_half'>
                <div className={`flex_b hor_set_img_text ${this.getChoosedState(0)}`} onClick={() => this.setcategoryIds(0)}>
                  <div>
                    <span className='main_text'>不限类别</span>
                  </div>
                </div>
              </div>
              {(typeData || []).map((item, k) => {
                return <div key={item.category_id} className='hor_half'>
                  <div className={`flex_b hor_set_img_text ${this.getChoosedState(item.category_id)}`} onClick={() => this.setcategoryIds(item.category_id)}>
                    <div className="">
                      <span className='main_text'>{item.category_name}</span>
                    </div>
                  </div>
                </div>
              })}
            </Flex>
          </div>
          <div className="floor floor_2">
            <p>
              习题设置
              (共{flag === 1 ? <span>{num}</span> : <span>{(radioNum - 0) + (multiNum - 0) + (estimateNum - 0)}</span>}题)
            </p>
            <div className={`hor_set_img_text ${currentTiXing === 0 && 'choosed'}`} onClick={(e) => this.showModal(e, 0)}>
              <div className="">
                <img src={require('../../../img/safty/bxtx_img.png')} alt="" />
                <span className='main_text'>不限题型</span>
              </div>
              <div className="sub_text"> 默认随机<span className='num'>{num}道</span></div>
            </div>
            <Flex>
              <Flex.Item>
                <div className={`flex_v ver_set_img_text ${(radioNum > 0) && 'choosed'}`} onClick={(e) => this.showModal(e, 1)}>
                  <img src={require('../../../img/safty/radio_img.png')} alt="" />
                  <span className='main_text'>单选题</span>
                  {radioNum > 0 ? <div className="sub_text">已选择<span className='num'>{radioNum}道</span></div> : '设置数量'}
                </div>
              </Flex.Item>
              <Flex.Item>
                <div className={`flex_v ver_set_img_text ${(multiNum > 0) && 'choosed'}`} onClick={(e) => this.showModal(e, 2)}>
                  <img src={require('../../../img/safty/checkbox_img.png')} alt="" />
                  <span className='main_text'>多选题</span>
                  {multiNum > 0 ? <div className="sub_text">已选择<span className='num'>{multiNum}道</span></div> : '设置数量'}
                </div>
              </Flex.Item>
              <Flex.Item>
                <div className={`flex_v ver_set_img_text ${(estimateNum > 0) && 'choosed'}`} onClick={(e) => this.showModal(e, 3)}>
                  <img src={require('../../../img/safty/panduan_img.png')} alt="" />
                  <span className='main_text'>判断题</span>
                  {estimateNum > 0 ? <div className="sub_text">已选择<span className='num'>{estimateNum}道</span></div> : '设置数量'}
                </div>
              </Flex.Item>
            </Flex>
          </div>
        </div>
        <div className="bottom_btn fixed_bottom_btn">
          <div className='bottom_btn_list' onClick={this.goPractice}>
            开始练习
          </div>
        </div>
        <Modal
          className='numModal'
          popup
          visible={visible}
          onClose={this.onClose}
          animationType="slide-up"
        >
          <div className="flex_b">
            {currentTiXing === 0 ? <span>数量设置</span> : ''}
            {currentTiXing === 1 ? <span>单选题数量</span> : ''}
            {currentTiXing === 2 ? <span>多选题数量</span> : ''}
            {currentTiXing === 3 ? <span>判断题数量</span> : ''}

            <Icon type='cross' onClick={this.onClose} />
          </div>
          <Flex wrap="wrap">
            {(numData || []).map((item, k) => {
              return <div key={item} className='hor_half'>
                <div className={`hor_set_img_text ${currentTiNum === item && 'choosed'}`} onClick={() => this.setNum(item)}>
                  <div className="">
                    <span className='main_text'>{item}道</span>
                  </div>
                </div>
              </div>
            })}
          </Flex>
          <InputItem
            clear
            type="number"
            placeholder="输入自定义数量"
            value={numData.findIndex(x => x === currentTiNum) === -1 ? currentTiNum : ''}
            onChange={value => this.setNum(value)}
          />
          <div className="bottom_btn">
            <div className='bottom_btn_list' onClick={this.setTiXingNumObj}>
              确定数量
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
