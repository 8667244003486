import React, { Component } from 'react'
import { TreeSelect, Select } from 'antd'
import { Picker, List, TextareaItem, Modal, Icon, Toast, InputItem } from 'antd-mobile'
import { listDict, req_findDepartmentInfo, findZxOrRoomByDwOrZx, req_findDepartmentInfoToTeacher, findMasterProcess, findNextApprovalUser, findRepository, addLysqInfo, findPageKcInfoByCk } from './../../../../api/yhp'

const { TreeNode } = TreeSelect

class Add extends Component {
  state = {
    data: [
      {
        label: '2013年',
        value: '2013',
      },
      {
        label: '2014年',
        value: '2014',
      },
    ],
    slideId: '',
    startX: '',
    visible: false,
    tab_id: '',
    sqfxData: [],//方向
    xydm: undefined,//所属学院
    zxid: undefined,//所属中心
    jsid: undefined,//所属实验室
    lyckbh: undefined,
    syfxbh: undefined,
    lyrgh1: undefined,
    lyrgh2: undefined,
    lyyt: '',
    typeData: [],//类别
    whpFlbh: '',//化学品类别
    chemicalData: [],//化学品
    depTreeData: [],
    depData: [],
    centerData: [],//中心
    roomData: [],//房间
    masterProcess: 0, // 是否多人同时审批
    approveVisible: false,
    spry: undefined,
    warehouseData: [],//仓库
    userData: [],//领用人
    whpInfoEntityList: [],//领用物品
    depUserData: [],
    depArr: [],
    flbh: ''
  }

  componentDidMount = () => {
    document.title = '领用申请'
    window.scrollTo(0, 0)
    this.fetchDepUser()
    this.fetchSqfx()
    this.fetchDep()
    this.fetchType()
    this.fetchWarehouse()
  }

  // 查询申请方向
  fetchSqfx = async () => {
    const res = await listDict({ ZDLX: 'sqfx' })
    if (res.code === 20000) {
      const data = res.data.map(item => {
        return {
          label: item.zdmc,
          value: item.zdbh,
        }
      })
      this.setState({
        sqfxData: data
      })
    }
  }

  // 数组转变
  deep = (node) => {
    let stack = node,
      data = []
    while (stack.length !== 0) {
      let pop = stack.pop()
      data.push({
        title: pop.title,
        parentId: pop.parentId,
        value: pop.value,
      })
      let children = pop.children
      if (children) {
        for (let i = children.length - 1; i >= 0; i--) {
          stack.push(children[i])
        }
      }
    }
    return data
  }

  // 查询部门和人员
  fetchDepUser = async () => {
    const res = await req_findDepartmentInfoToTeacher()
    if (res.code === 20000) {
      let menuList = (res.data || []).map(item => {
        return {
          title: item.dwmc,
          value: item.dw_id,
          parentId: item.sj_dm,
          disabled: true,
          children: (item.teacherInfoEntities || []).map(val => {
            return {
              title: val.xm,
              value: val.gh,
              parentId: val.dwdm,
              disabled: false,
            }
          })
        }
      })
      menuList = menuList.map(item => {
        item.children = item.children.length ? item.children : menuList.filter(p => p.parentId === item.value)
        return item
      }).filter(p => p.parentId === '0')
      const a = []
      const c = a.concat(menuList)
      const depArr = this.deep(c)
      this.setState({
        depUserData: menuList,
        depArr
      })
    }
  }

  // 查询学院/部门
  fetchDep = async () => {
    const res = await req_findDepartmentInfo({ dwlx: 1 })
    if (res.code === 20000) {
      let menuList = res.data
      menuList = menuList.map(a => {
        return {
          title: a.dwmc,
          key: a.dwdm,
          parentId: a.sj_dm,
          value: a.dwdm,
          disabled: a.sj_dm === '0' ? true : false
        }
      })
      menuList = menuList.map(m => {
        m.children = menuList.filter(p => p.parentId === m.key)
        return m
      }).filter(x => !res.data.find(v => v.dwdm === x.parentId))
      this.setState({
        depTreeData: menuList,
        depData: res.data,
      })
    }
  }

  // 选择部门/学院
  handleChangeDep = async e => {
    const res = await findZxOrRoomByDwOrZx({ flag: 1, param: e })
    if (res.code === 20000) {
      this.setState({
        centerData: res.data.map(item => {
          return {
            label: item.room_name,
            value: item.room_id,
          }
        })
      })
    }
  }

  // 选择中心
  handleChangeCenter = async e => {
    const res = await findZxOrRoomByDwOrZx({ flag: 2, param: e.join(',') })
    if (res.code === 20000) {
      this.setState({
        roomData: res.data.map(item => {
          return {
            label: item.room_name,
            value: item.room_id,
          }
        })
      })
    }
  }

  // 查询类别
  fetchType = async () => {
    const res = await listDict({ ZDLX: 'lb' })
    if (res.code === 20000) {
      const data = res.data.map(item => {
        return {
          label: item.zdmc,
          value: item.zdbh,
        }
      })
      this.setState({
        typeData: data
      })
    }
  }

  // 查询仓库
  fetchWarehouse = async () => {
    const res = await findRepository()
    if (res.code === 20000) {
      const data = res.data.map(item => {
        return {
          label: item.repository_name,
          value: item.repository_id,
        }
      })
      this.setState({
        warehouseData: data
      })
    }
  }

  // 查询库存
  fetchkc = async () => {
    const { lyckbh } = this.state
    const res = await findPageKcInfoByCk({ size: 99999, page: 1, ckid: lyckbh.join(',') })
    if (res.code === 20000) {
      this.setState({
        chemicalData: res.data.list.map(item => {
          return {
            ...item,
            state: false
          }
        }),
      })
    }
  }

  // 求和
  sum (arr) {
    var sum = 0
    for (var i = 0; i < arr.length; i++) {
      sum += arr[i]
    }
    return sum
  }

  // 易耗品总数
  whpNum = () => {
    const { chemicalData } = this.state
    return this.sum(chemicalData.map(x => x.sgwhpsl))
  }

  // 添加易耗品
  handleAddWhp = () => {
    const { chemicalData, whpInfoEntityList } = this.state

    chemicalData.map(item => {
      if (item.state && whpInfoEntityList.filter(x => x.kc_id === item.kc_id).length === 0) {
        item.lysl = 1
        whpInfoEntityList.push(item)
        this.setState({
          whpInfoEntityList: whpInfoEntityList
        })
      }
    })

    this.setState({
      visible: false,
    })
  }


  // 判断审批是多人共同审批还是单人审批
  handleMasterProcess = async () => {
    const { whpInfoEntityList, xydm, zxid, jsid, lyckbh, lyyt, lyrgh1, lyrgh2, syfxbh } = this.state
    if (xydm === undefined || zxid === undefined || jsid === undefined || lyckbh === undefined || lyyt === '' || lyrgh1 === undefined || lyrgh2 === undefined || syfxbh === undefined) {
      Toast.info('请补全上述内容！')
      return false
    }
    if (whpInfoEntityList.length === 0) {
      Toast.info('请添加易耗品！')
      return false
    }
    if (whpInfoEntityList.filter(x => x.lysl === '' || x.lysl === null).length > 0) {
      Toast.info('请填写易耗品领用量！')
      return false
    }
    const res = await findMasterProcess({ flag: 3 })
    if (res.code === 20000) {
      this.setState({
        masterProcess: res.data
      }, () => {
        this.handleNextApprovalUser(jsid.join(','))
      })
    }
  }

  // 获取下一个审批人员信息
  handleNextApprovalUser = async roomId => {
    const { masterProcess } = this.state
    const res = await findNextApprovalUser({ flag: 3, roomId })
    if (res.code === 20000) {
      this.setState({
        approveUser: res.data,
        approveVisible: true,
      }, () => {
        if (masterProcess) {
          this.setState({
            spry: (res.data || []).map(x => x.gh)
          })
        }
      })
    }
  }

  // 提交
  submit = async () => {
    const { spry, lyyt, xydm, zxid, jsid, depData, centerData, roomData, masterProcess, approveUser, warehouseData, lyckbh, whpInfoEntityList, depArr, lyrgh1, lyrgh2, syfxbh, sqfxData } = this.state
    if (spry === undefined || spry === '') {
      Toast.info('请选择审批人员！')
      return false
    }
    if (xydm === undefined || zxid === undefined || jsid === undefined || lyckbh === undefined || lyyt === '' || lyrgh1 === undefined || lyrgh2 === undefined || syfxbh === undefined) {
      Toast.info('请补全上述内容！')
      return false
    }
    if (whpInfoEntityList.length === 0) {
      Toast.info('请添加易耗品！')
      return false
    }
    if (whpInfoEntityList.filter(x => x.lysl === '' || x.lysl === null).length > 0) {
      Toast.info('请填写易耗品领用量！')
      return false
    }
    const values = {
      xydm: xydm,
      zxid: zxid.join(','),
      jsid: jsid.join(','),
      lyckbh: lyckbh.join(','),
      xymc: depData.find(x => x.dwdm === xydm).dwmc,
      zxmc: centerData.find(x => x.value === zxid.join(',')).label,
      jsmc: roomData.find(x => x.value === jsid.join(',')).label,
      lyckmc: warehouseData.find(x => String(x.value) === lyckbh.join(',')).label,
      lyzt: 1,
      lyyt,
      lyrgh1,
      lyrgh2,
      lyrmc1: depArr.find(x => x.value === lyrgh1).title,
      lyrmc2: depArr.find(x => x.value === lyrgh2).title,
      syfxbh: syfxbh.join(','),
      syfxmc: sqfxData.find(x => x.value === syfxbh.join(',')).label,
    }
    const params = {
      ...values,
      spuserVos: masterProcess ? approveUser : approveUser.filter(x => x.gh === spry),
      whpInfoEntityList: whpInfoEntityList,
    }
    const res = await addLysqInfo(params)
    if (res.code === 20000) {
      Toast.info("提交成功！")
      setTimeout(() => {
        this.props.history.goBack()
      }, 2000)
    }
  }

  render () {
    const { slideId, visible, tab_id, lyyt, typeData, chemicalData, depTreeData, xydm, centerData, zxid, roomData, jsid, approveVisible, masterProcess, approveUser, spry, warehouseData, lyckbh, userData, whpInfoEntityList, sqfxData, syfxbh, depUserData, lyrgh1,
      lyrgh2, } = this.state
    const loop = data =>
      data.map(item => {
        if (item.children && item.children.length) {
          return (
            <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled}>
              {loop(item.children)}
            </TreeNode>
          )
        }
        return <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled} />
      })
    return (
      <div className='subscription_add'>
        <div className='subscription_add_title'>
          基础信息
        </div>
        <div className='subscription_add_form'>

          <div className='subscription_add_picker'>
            <Picker
              data={warehouseData}
              value={lyckbh}
              cols={1}
              onChange={(e) => {
                this.setState({
                  lyckbh: e,
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>领用仓库</List.Item>
            </Picker>
          </div>

          <div className='subscription_add_tree'>
            <div>
              <span style={{ color: 'red' }}>* </span>申请所属学院
            </div>
            <TreeSelect
              showSearch
              treeNodeFilterProp='title'
              placeholder="请选择"
              style={{ width: '100%' }}
              value={xydm}
              onChange={(e) => {
                this.setState({
                  xydm: e,
                  zxid: undefined,
                  jsid: undefined
                })
                this.handleChangeDep(e)
              }}
            >
              {loop(depTreeData)}
            </TreeSelect>
          </div>
          <div className='subscription_add_picker'>
            <Picker
              data={centerData}
              value={zxid}
              cols={1}
              onChange={(e) => {
                this.setState({
                  zxid: e,
                  jsid: undefined
                })
                this.handleChangeCenter(e)
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>所属中心</List.Item>
            </Picker>
          </div>
          <div className='subscription_add_picker'>
            <Picker
              data={roomData}
              value={jsid}
              cols={1}
              onChange={(e) => {
                this.setState({
                  jsid: e
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>所属实验室</List.Item>
            </Picker>
          </div>
          <div className='subscription_add_picker'>
            <Picker
              data={sqfxData}
              value={syfxbh}
              cols={1}
              onChange={(e) => {
                this.setState({
                  syfxbh: e
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>使用方向</List.Item>
            </Picker>
          </div>

        </div>

        <div className='subscription_add_form'>
          <div className='subscription_add_tree'>
            <div>
              <span style={{ color: 'red' }}>* </span>第一领用人
            </div>
            <TreeSelect
              placeholder="请选择"
              showSearch
              treeNodeFilterProp='title'
              style={{ width: '100%' }}
              value={lyrgh1}
              onChange={(e) => {
                this.setState({
                  lyrgh1: e,
                })
              }}
            >
              {loop(depUserData)}
            </TreeSelect>
          </div>
          <div className='subscription_add_tree'>
            <div>
              <span style={{ color: 'red' }}>* </span>第二领用人
            </div>
            <TreeSelect
              placeholder="请选择"
              showSearch
              treeNodeFilterProp='title'
              style={{ width: '100%' }}
              value={lyrgh2}
              onChange={(e) => {
                this.setState({
                  lyrgh2: e,
                })
              }}
            >
              {loop(depUserData)}
            </TreeSelect>
          </div>

        </div>


        <div className='subscription_add_form'>
          <div className='subscription_add_text'>
            <div><span style={{ color: 'red' }}>* </span>领用用途</div>
            <TextareaItem
              placeholder="请输入"
              rows={5}
              value={lyyt}
              onChange={(e) => {
                this.setState({
                  lyyt: e
                })
              }}
            />
          </div>
        </div>

        <div className='subscription_add_increase'>
          <div onClick={() => {
            if (this.state.lyckbh === undefined) {
              Toast.info('请选择领用仓库！')
              return false
            }
            this.fetchkc()
            this.setState({
              tab_id: '',
              visible: true
            })
          }}>
            <img src={require('./../../../../images/chemicals/02.png')} alt='' />
            <span>选择库存</span>
          </div>
        </div>

        <div className='subscription_add_increase_record'>
          <div className='subscription_list_card' style={{ margin: '0' }}>
            {whpInfoEntityList.map((item, key) => {
              return (
                <div className='subscription_add_card_item' style={{ height: '260px' }} onTouchStart={(e) => {
                  this.setState({
                    startX: e.touches[0].clientX
                  })
                }} onTouchEnd={(e) => {
                  const endX = e.changedTouches[0].clientX
                  if (this.state.startX === endX) {
                    return
                  }

                  if (endX - this.state.startX > 100) { // 阈值可以根据需要调整
                    this.setState({
                      slideId: ''
                    })
                    return false
                    // 左滑后的处理逻辑
                  }
                  if (endX + this.state.startX > 100) { // 阈值可以根据需要调整
                    this.setState({
                      slideId: item.kc_id
                    })
                    // 右滑后的处理逻辑
                    return false
                  }
                }}>
                  <div className='subscription_add_card_cont  subscription_add_cont_float' style={{ margin: slideId === item.kc_id ? '0px' : '0px 12px' }}>
                    <div className='subscription_list_card_item' style={{ width: slideId === item.kc_id ? window.screen.width : window.screen.width - 24 }}>
                      <div className='subscription_list_card_head'>
                        <div className='subscription_list_card_head_left'>
                          <div>{key + 1}</div>
                          <div>{item.whp_mc}</div>
                        </div>
                        <div className='subscription_list_card_class'>
                          {item.whp_fl}
                        </div>
                      </div>
                      <div className='subscription_list_card_company'>
                        <div>{item.whp_cas}</div>
                        <div>{item.whp_gys}</div>
                      </div>
                      <div className='subscription_list_card_unit'>
                        <div>{item.whp_ggxh}</div>
                        <div>{item.whp_bzsl}{item.whp_bzdw}</div>
                        <div>{item.whp_jldw}</div>
                      </div>
                      <div className='subscription_list_card_input'>
                        {/* <div className='subscription_list_card_num_item'>
                          <div>{item.whp_dj}</div>
                          <div>单价</div>
                        </div> */}
                        <div className='subscription_add_input'>
                          <InputItem type='digit' placeholder="请输入" value={item.lysl} onChange={(e) => {
                            this.setState({
                              whpInfoEntityList: whpInfoEntityList.map(x => {
                                if (x.kc_id === item.kc_id) {
                                  if (e > 0) {
                                    x.lysl = e > x.whp_xcsl ? x.whp_xcsl : e
                                  } else {
                                    x.lysl = 1
                                  }
                                }
                                return x
                              })
                            })
                          }}>领用量({item.whp_bzdw})</InputItem>
                        </div>
                        <div className='subscription_add_text'>
                          <div>备注</div>
                          <TextareaItem
                            placeholder="请输入"
                            rows={3}
                            value={item.lyms}
                            onChange={(e) => {
                              this.setState({
                                whpInfoEntityList: whpInfoEntityList.map(x => {
                                  if (x.kc_id === item.kc_id) {
                                    x.lyms = e
                                  }
                                  return x
                                })
                              })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='subscription_add_card_del' style={{ display: slideId === item.kc_id ? 'block' : 'none' }} onClick={() => {
                      this.setState({
                        whpInfoEntityList: whpInfoEntityList.filter(x => x.kc_id !== item.kc_id)
                      })
                    }}>
                      删除
                    </div>
                  </div>
                </div>
              )
            })}

          </div>
        </div>
        <div className='subscription_list_but'>
          <div onClick={() => {
            this.handleMasterProcess()
          }}>提交领用</div>
        </div>

        <Modal
          popup
          visible={visible}
          className='subscription_modal'
          onClose={() => {
            this.setState({
              visible: false
            })
          }}
          animationType="slide-up"
        >
          <div className='subscription_add_modal'>
            <div className='subscription_add_modal_locate'>
              <div className='subscription_add_modal_head'>
                <div>添加易耗品</div>
                <div>
                  <Icon type='cross' onClick={() => {
                    this.setState({
                      visible: false
                    })
                  }} />
                </div>
              </div>
              <div className='subscription_add_modal_tabs'>
                <div className='subscription_add_modal_tabs_bg'>
                  <div className='subscription_add_modal_tabs_width'>
                    <div className={tab_id === '' ? 'subscription_add_modal_tabs_click' : 'subscription_add_modal_tabs_item'} onClick={() => {
                      this.setState({
                        tab_id: '',
                      }, () => {
                        // this.fetchkc()
                      })
                    }}>
                      全部
                    </div>
                    {typeData.map(item => {
                      return (<div key={item.value} className={tab_id === item.value ? 'subscription_add_modal_tabs_click' : 'subscription_add_modal_tabs_item'} onClick={() => {
                        this.setState({
                          tab_id: item.value,
                        }, () => {
                          // this.fetchkc()
                        })
                      }}>
                        {item.label}
                      </div>)
                    })}

                  </div>
                </div>
              </div>
            </div>
            <div className='subscription_add_modal_card'>
              {(tab_id !== '' ? chemicalData.filter(x => x.whp_flbh === tab_id) : chemicalData).map((item, key) => {
                return (<div className={item.state ? 'subscription_list_card_item warehousing_modal_item_click' : 'subscription_list_card_item subscription_list_card_border'} key={item.kc_id} onClick={() => {
                  const data = chemicalData.map(x => {
                    if (item.kc_id === x.kc_id) {
                      x.state = item.state ? false : true
                    }
                    return x
                  })
                  this.setState({
                    chemicalData: data
                  })

                }}>
                  <div className='subscription_list_card_head'>
                    <div className='subscription_list_card_head_left'>
                      <div>{key + 1}</div>
                      <div>{item.whp_mc}</div>
                    </div>
                    <div className='subscription_list_card_class'>
                      {item.whp_fl}
                    </div>
                  </div>
                  <div className='subscription_list_card_company'>
                    <div>{item.whp_cas}</div>
                    <div>{item.whp_gys}</div>
                  </div>
                  <div className='subscription_list_card_foot'>
                    <div className='subscription_list_card_unit'>
                      <div>{item.whp_ggxh}</div>
                      <div>{item.whp_bzsl}{item.whp_bzdw}</div>
                      <div>{item.whp_jldw}</div>
                    </div>
                    <div className='subscription_list_card_count'>
                      {/* {item.state ? (<img src={require('./../../../../images/chemicals/03.png')} alt='' onClick={() => {
                        const data = chemicalData.map(x => {
                          if (item.kc_id === x.kc_id) {
                            x.state = false
                          }
                          return x
                        })
                        this.setState({
                          chemicalData: data
                        })
                      }} />) : (<img src={require('./../../../../images/chemicals/04.png')} alt='' onClick={() => {
                        const data = chemicalData.map(x => {
                          if (item.kc_id === x.kc_id) {
                            x.state = true
                          }
                          return x
                        })
                        this.setState({
                          chemicalData: data
                        })
                      }} />)} */}

                    </div>
                  </div>
                </div>)
              })}

            </div>
            <div className='subscription_list_but'>
              <div onClick={() => {
                this.handleAddWhp()
              }}>添加</div>
            </div>
          </div>
        </Modal>
        {/* 选择审批人 */}
        <Modal
          popup
          visible={approveVisible}
          className='subscription_modal'
          onClose={() => {
            this.setState({
              approveVisible: false,
              spry: undefined
            })
          }}
          animationType="slide-up"
        >
          <div className='subscription_add_modal subscription_add_modal_approve'>
            <div className='subscription_add_modal_head'>
              <div>选择审批人员</div>
              <div>
                <Icon type='cross' onClick={() => {
                  this.setState({
                    approveVisible: false
                  })
                }} />
              </div>
            </div>
            <div className='subscription_add_select'>
              <div>
                <span style={{ color: 'red' }}>* </span>审批人员
              </div>
              <Select mode={masterProcess ? 'multiple' : null} disabled={masterProcess ? true : false} allowClear showSearch optionFilterProp="children" value={spry} placeholder='请选择' onChange={(e) => {
                this.setState({
                  spry: e
                })
              }}>
                {
                  (approveUser || []).map(item => {
                    return <Select.Option key={item.gh} value={item.gh}>{item.xm}</Select.Option>
                  })
                }
              </Select>
            </div>

            <div className='subscription_list_but'>
              <div onClick={() => {
                this.submit()
              }}>提交审批</div>
            </div>
          </div>
        </Modal>
      </div >
    )
  }
}

export default Add
