import React, { Component } from 'react';
import { findAppTask, startChecking } from '../../../api/api'
import './index.less'
import { Toast } from 'antd-mobile';
import moment from 'moment';

class Keep extends Component {

  state = {
    active: '',
    data: [],
  }

  componentDidMount = () => {
    document.title = "日常巡检"
    window.addEventListener('focusin', this.setScrollTop)
    window.addEventListener('focusout', this.setScrollTop)
    this.fetchKeep(1)
  }

  setScrollTop = () => {
    let scrollTop = document.body.scrollTop + document.documentElement.scrollTop
    window.scrollTo(0, scrollTop)
  }

  // 查询巡检信息
  fetchKeep = async flag => {
    Toast.loading('加载中...', 0)
    const res = await findAppTask({ flag, task_level: 3 })
    if (res.code === 20000) {
      Toast.hide()
      this.setState({
        data: (res.data || []).map(x => {
          x.patrol_point = x.room_name ? x.room_name.split(',') : []
          return x
        })
      })
    }
  }

  handleChangeActive = active => {
    this.setState({
      active,
    })
    this.fetchKeep(active)
  }

  // 跳转巡检任务详情
  handleSkipInfo = async (e, item) => {
    e.stopPropagation();
    // 阻止与原生事件的冒泡
    e.nativeEvent.stopImmediatePropagation();
    // 判断巡检是否有效
    // 未开始
    if (moment() < moment(item.polling_start_time + '00:00:00')) {
      Toast.info('该巡检未开始！')
      return
    }
    if (moment() > moment(item.polling_time + '23:59:59')) {
      Toast.info('该巡检已结束！')
      return
    }
    if (item.status === 1) {
      const res = await startChecking({ taskId: item.task_id })
      if (res.code === 20000) {
        Toast.info('开始巡检')
      }
    }
    this.props.history.push({ pathname: `/module/patrol/periodical_task/${item.task_id}` })
  }

  render() {
    const { active, data } = this.state
    return (
      <div className='keep'>
        <div className='keep-header'>
          <div className='keep-header-content'>
            <div className={active === '' ? 'keep-header-active' : ''} onClick={() => this.handleChangeActive('')}>
              <div>全部</div>
              <div></div>
            </div>
            <div className={active === 1 ? 'keep-header-active' : ''} onClick={() => this.handleChangeActive(1)}>
              <div>待巡检</div>
              <div></div>
            </div>
            <div className={active === 2 ? 'keep-header-active' : ''} onClick={() => this.handleChangeActive(2)}>
              <div>已巡检</div>
              <div></div>
            </div>
          </div>
        </div>
        <div className='keep-content'>
          {
            (data || []).length ? data.map(item => {
              return <div className='keep-content-list' key={item.task_id} onClick={e => this.handleSkipInfo(e, item)}>
                <div className='keep_cont_head'>
                  <div>{item.task_name}</div>
                  <div className='keep_cont_state'>
                    {item.status === 1 && <div className='not-started'>待巡检</div>}
                    {item.status === 2 && <div className='not-started'>巡检中</div>}
                    {item.status === 3 && <div className='complete'>已巡检</div>}
                  </div>
                </div>
                <div className='keep_cont_main'>
                  <div>
                    {item.polling_num}个巡检点
                  </div>
                </div>
                <div className='keep_cont_date'>
                  起：{item.polling_start_time}
                  <span style={{ marginLeft: 12 }}>止：{item.polling_time}</span>
                </div>
              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
      </div>
    );
  }
}

export default Keep;