import React, { Component } from 'react'
import { findEnvironmentInfo } from '../../../api/api'
import './room.less'

class RoomEnvironment extends Component {

  state = {
    roomInfo: {},
    data: [],
  }

  componentDidMount = () => {
    document.title = "环境监测"
    this.fetch(this.props.match.params.id)
    this.setState({
      roomInfo: this.props.location.state.roomInfo
    })
  }

  fetch = async spaceId => {
    const res = await findEnvironmentInfo({ spaceId })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  // 显示文字
  handleWord = val => {
    // 设备状态：1 在线 2 离线
    if (val.device_state === 1) {
      return val.parameter_value ? val.parameter_value : '--'
    } else {
      return '离线'
    }
  }

  render() {
    const { roomInfo, data } = this.state
    return (
      <div className='roomEnvironment'>
        <div className='shortTerm_name'>{roomInfo.wzName}</div>
        <div className='shortTerm_addr'>{roomInfo.buildingName ? roomInfo.buildingName.split('/')[0] : ''}</div>
        <div className='roomEnvironment_con'>
          {(data || []).length ? data.map((item, key) => {
            return <div className='room_inner_device' key={item.room_id}>
              <div className='room_name'><span></span>{item.roomName}({item.room_number})</div>
              <span className='sub_title'>在线设备<strong>{item.zxNum}</strong>/{item.totalNum}</span>
              {(item.deviceParameterInfoEntities || []).length ? item.deviceParameterInfoEntities.map((i, k) => {
                return <div className={`device_list ${i.device_state === 1 ? 'on' : 'off'}`} key={k} onClick={() => {
                  const k = { ...i }
                  k.roomName = item.roomName
                  k.room_number = item.room_number
                  this.props.history.push({ pathname: '/module/environment/detail', state: { item: k } })
                }}>
                  <div className="left">
                    <div className="device_name">{i.deviceName}</div>
                    <div className='device_status'>
                      <span className='dot'></span>
                      <span className=''>{i.device_state === 1 ? '设备在线' : '设备离线'}</span>
                    </div>
                  </div>
                  <div className="right">
                    {
                      i.device_state === 1 ? <div>
                        <span className='device_value'>{i.parameter_value}</span>
                        <span className='device_unit'>{i.parameter_unit}</span>
                      </div> : <div className='off_text'>离线</div>
                    }
                    <div className='device_type'>{i.parameter_name}</div>
                  </div>
                </div>
              }) : <div className='not-yet'>--暂无设备--</div>}
            </div>
          }) : <div className='not-yet'>--暂无数据--</div>}
        </div>
      </div>
    )
  }
}

export default RoomEnvironment
