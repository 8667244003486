import React, { Component } from 'react'
import { Select } from 'antd'
import { Toast, Modal, Icon, TextareaItem } from 'antd-mobile'
import { submitResultDevice } from '../../../../api/api'
import { listApprovalRecordById, findMasterProcess, findNextApprovalUser, submitApprovalResult, estimateCountersign } from './../../../../api/gas'
import './record.less'

class deviceDetail extends Component {

  state = {
    data: {},
    visible: false,
    approveVisible: false,
    opinion: '',
    masterProcess: 0,
    spry: undefined,
    approveUser: [],
    remark: '',
    result: 2,
    countersign: 0
  }

  componentDidMount = () => {
    document.title = '审批详情'
    window.scrollTo(0, 0)
    this.fetch(this.props.match.params.id)
  }

  fetch = async id => {
    const res = await listApprovalRecordById({ flag: 2, id: id })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }

  // 判断审批是多人共同审批还是单人审批
  handleMasterProcess = async (jsid) => {
    const { data } = this.state
    const res = await findMasterProcess({ flag: 2, dh: data.sgdh })
    if (res.code === 20000) {
      this.setState({
        masterProcess: res.data
      }, () => {
        if (data.process_state) {
          this.fetchEstimateCountersign()
        } else {
          this.handleNextApprovalUser(jsid)
        }
      })
    }
  }

  // 会签审批判断是否是最后一个人审批（共同审批）
  fetchEstimateCountersign = async record => {
    const { data } = this.state
    const res = await estimateCountersign({ flag: 2, prId: data.pr_id })
    if (res.code === 20000) {
      this.setState({
        countersign: res.data,
      }, () => {
        this.handleNextApprovalUser(data.jsid)
      })
    }
  }

  // 获取下一个审批人员信息
  handleNextApprovalUser = async roomId => {
    const { masterProcess } = this.state
    const res = await findNextApprovalUser({ flag: 2, roomId })
    if (res.code === 20000) {
      this.setState({
        approveUser: res.data,
        approveVisible: true,
      }, () => {
        if (masterProcess) {
          this.setState({
            spry: (res.data || []).map(x => x.gh)
          })
        }
      })
    }
  }

  handleApproveOk = async () => {
    const { result, remark, data, countersign, spry, masterProcess, approveUser } = this.state
    if (result === 3) {
      if (remark === '') return Toast.info('请输入驳回原因！')
    } else {
      if (data.pr_status !== 999999) {
        if (data.process_state) {
          if (countersign) {
            if (spry) {
              if (spry === undefined) return Toast.info('请选择审批人！')
            } else {
              return Toast.info('请选择审批人！')
            }
          }
        } else {
          if (spry) {
            if (spry === undefined) return Toast.info('请选择审批人！')
          } else {
            return Toast.info('请选择审批人！')
          }
        }
      }
    }

    Toast.loading('提交中...', 0)
    const res = await submitApprovalResult({
      flag: 2,
      prIds: data.pr_id,
      remark: remark,
      result,
      spUserInfo: data.pr_status !== 999999 ? (
        masterProcess ? (
          data.process_state ? (
            countersign ? (JSON.stringify(approveUser)) : null
          ) : (JSON.stringify(approveUser))
        ) : (
          data.process_state ? (
            countersign ? (JSON.stringify(approveUser.filter(x => x.gh === spry))) : null
          ) : (JSON.stringify(approveUser.filter(x => x.gh === spry)))
        )
      ) : null
    })
    if (res.code === 20000) {
      this.setState({
        approveVisible: false,
      })
      this.fetch(this.props.match.params.id)
      Toast.info("提交成功！")
      setTimeout(() => {
        this.props.history.goBack()
      }, 2000);
    }
  }



  // 提交审批驳回
  handleSubmit = async () => {
    const { data, opinion } = this.state
    if (!opinion) return Toast.info('请输入驳回意见！')
    const res = await submitResultDevice({
      pr_id: data.pr_id,
      pr_approval_result: 3,
      pr_approval_opinion: opinion
    })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.props.history.go(-1)
    }
  }

  render() {
    const { data, visible, approveVisible, masterProcess, spry, approveUser, remark, result, countersign } = this.state
    return (
      <div className='approveDetail' style={{ padding: '0px 0px 76px' }}>
        <div className={data.pr_approval_result === 2 ? 'gas_warehousing_record_head' : 'gas_warehousing_record_head gas_warehousing_record_head_no'}>
          <div className='gas_warehousing_record_left'>
            <div className='gas_warehousing_record_left_title'>
              {data.xymc}-{data.zxmc}-{data.jsmc}
            </div>
            <div className='gas_warehousing_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='gas_warehousing_list_head_date'>
                {data.xymc}
              </div>
              <div className='gas_warehousing_list_head_seat'>气体种类{data.rkzls}种</div>
            </div>
          </div>
          <div className='gas_warehousing_record_right'>
            {data.pr_approval_result === 1 ? (<img src={require('./../../../../images/chemicals/06.png')} style={{ width: '90px' }} alt='' />) : ('')}
            {data.pr_approval_result === 0 ? (<img src={require('./../../../../images/chemicals/07.png')} style={{ width: '90px' }} alt='' />) : ('')}
            {data.pr_approval_result === 2 ? (<img src={require('./../../../../images/chemicals/08.png')} style={{ width: '90px' }} alt='' />) : ('')}
          </div>
        </div>
        <div className='gas_warehousing_record_cont'>
          <div className='gas_warehousing_record_bar'>
            <div className='gas_warehousing_record_item'>
              <div>入库单号</div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {data.sgdh}
                {/* <img src={require('./../../../../images/chemicals/05.png')} alt='' style={{ width: '16px', marginLeft: '4px' }} onClick={() => {
                  this.setState({
                    visible: true
                  })
                }} /> */}
              </div>
            </div>
            <div className='gas_warehousing_record_item'>
              <div>申请所属学院</div>
              <div>{data.xymc}</div>
            </div>
            <div className='gas_warehousing_record_item'>
              <div>所属中心</div>
              <div>{data.zxmc}</div>
            </div>
            <div className='gas_warehousing_record_item'>
              <div>所属实验室</div>
              <div>{data.jsmc}</div>
            </div>
            <div className='gas_warehousing_record_item'>
              <div>入库人</div>
              <div>{data.rksqrxm}</div>
            </div>
          </div>


          <div className='gas_warehousing_record_bar'>
            <div className='gas_warehousing_record_item'>
              <div>申请人</div>
              <div>
                {data.rksqrxm}
              </div>
            </div>
            <div className='gas_warehousing_record_item'>
              <div>备注</div>
              <div>
                {data.rkms}
              </div>
            </div>
          </div>

          <div className='gas_warehousing_card'>
            {data.rkWhpInfoEntities && data.rkWhpInfoEntities.map((item, key) => {
              return (
                <div className='gas_warehousing_card_item' style={{ background: '#fff' }} key={key}>
                  <div className='gas_warehousing_card_head'>
                    <div className='gas_warehousing_card_head_left'>
                      <div>{key + 1}</div>
                      <div>{item.gas_name}</div>
                    </div>
                    <div className='gas_warehousing_card_class'>
                    </div>
                  </div>
                  <div className='gas_warehousing_card_unit'>
                    <div>{item.bottle_number}</div>
                    <div>共 {item.bottle_capacity}L</div>
                    <div>当前量 {item.gas_number}L</div>
                  </div>
                  <div className='gas_manage_list_item_tag'>
                    {item.gas_type_name}
                  </div>


                </div>
              )
            })}


          </div>

        </div>






        {
          data.pr_approval_result === 1 ? <div className='gas_manage_list_but gas_manage_list_but_two'>
            <div onClick={() => {
              this.setState({
                approveVisible: true,
                result: 3
              }, () => {
                this.handleMasterProcess(data.jsid)
              })
            }}>审批驳回</div>
            <div onClick={() => {
              this.setState({
                approveVisible: true,
                result: 2
              }, () => {
                this.handleMasterProcess(data.jsid)
              })
            }}>审批通过</div>
          </div> : ''
        }

        {/* 选择审批人 */}
        <Modal
          popup
          visible={approveVisible}
          className='subscription_modal'
          onClose={() => {
            this.setState({
              approveVisible: false
            })
          }}
          animationType="slide-up"
        >
          <div className='approve_record_modal approve_record_modal_approve'>
            <div className='approve_record_modal_head'>
              <div>审批{result === 2 ? '通过' : '驳回'}</div>
              <div>
                <Icon type='cross' onClick={() => {
                  this.setState({
                    approveVisible: false
                  })
                }} />
              </div>
            </div>

            {
              data.pr_status !== 999999 && result === 2 ? (
                // 下一步多人审批
                masterProcess ? (
                  // 当前是不是共同审批
                  data.process_state ? (
                    // 是不是最后一个人
                    countersign ? <div className='gas_approve_record_select'>
                      <div>
                        {result === 2 ? <span style={{ color: 'red' }}>* </span> : <span style={{ color: 'white' }}>* </span>}审批人员
                      </div>
                      <Select mode='multiple' disabled allowClear showSearch optionFilterProp="children" value={spry} placeholder='请选择' onChange={(e) => {
                        this.setState({
                          spry: e
                        })
                      }}>
                        {
                          (approveUser || []).map(item => {
                            return <Select.Option key={item.gh} value={item.gh}>{item.xm}</Select.Option>
                          })
                        }
                      </Select>
                    </div> : ''
                  ) : (
                    <div className='gas_approve_record_select'>
                      <div>
                        {result === 2 ? <span style={{ color: 'red' }}>* </span> : <span style={{ color: 'white' }}>* </span>}审批人员
                      </div>
                      <Select mode='multiple' disabled allowClear showSearch optionFilterProp="children" value={spry} placeholder='请选择' onChange={(e) => {
                        this.setState({
                          spry: e
                        })
                      }}>
                        {
                          (approveUser || []).map(item => {
                            return <Select.Option key={item.gh} value={item.gh}>{item.xm}</Select.Option>
                          })
                        }
                      </Select>
                    </div>
                  )
                ) : (
                  // 下一步是单人审批
                  data.process_state ? (
                    countersign ? <div className='gas_approve_record_select'>
                      <div>
                        {result === 2 ? <span style={{ color: 'red' }}>* </span> : <span style={{ color: 'white' }}>* </span>}审批人员
                      </div>
                      <Select allowClear showSearch optionFilterProp="children" value={spry} placeholder='请选择' onChange={(e) => {
                        this.setState({
                          spry: e
                        })
                      }}>
                        {
                          (approveUser || []).map(item => {
                            return <Select.Option key={item.gh} value={item.gh}>{item.xm}</Select.Option>
                          })
                        }
                      </Select>
                    </div> : ''
                  ) : (
                    <div className='gas_approve_record_select'>
                      <div>
                        {result === 2 ? <span style={{ color: 'red' }}>* </span> : <span style={{ color: 'white' }}>* </span>}审批人员
                      </div>
                      <Select allowClear showSearch optionFilterProp="children" value={spry} placeholder='请选择' onChange={(e) => {
                        this.setState({
                          spry: e
                        })
                      }}>
                        {
                          (approveUser || []).map(item => {
                            return <Select.Option key={item.gh} value={item.gh}>{item.xm}</Select.Option>
                          })
                        }
                      </Select>
                    </div>
                  )
                )
              ) : ''
            }


            <div className='gas_approve_text'>
              <div>  {result === 3 ? <span style={{ color: 'red' }}>* </span> : <span style={{ color: 'white' }}>* </span>}审批意见</div>
              <TextareaItem
                placeholder="请输入"
                rows={5}
                value={remark}
                onChange={(e) => {
                  this.setState({
                    remark: e
                  })
                }}
              />
            </div>

            <div className='gas_approve_record_but'>
              <div onClick={() => {
                this.handleApproveOk()
              }}>提交审批</div>
            </div>
          </div>
        </Modal>


      </div>
    )
  }
}

export default deviceDetail