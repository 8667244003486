import React, { Component } from 'react'
import { Picker, List, TextareaItem, Toast } from 'antd-mobile';
import { saveBfWhpInfo, findRepository, findKcInfoByCk, findKcmxInfo } from './../../../../api/whp'

class Add extends Component {
  state = {
    ckid: undefined,
    whp_bh: undefined,
    whp_xh: undefined,
    bf_ms: '',
    warehouseData: [],//仓库
    whpData: [],
    whpInfo: [],
  }

  componentDidMount = () => {
    document.title = '危化品报废'
    window.scrollTo(0, 0)
    this.fetchWarehouse()
  }

  // 查询仓库
  fetchWarehouse = async () => {
    const res = await findRepository()
    if (res.code === 20000) {
      const data = res.data.map(item => {
        return {
          label: item.repository_name,
          value: item.repository_id,
        }
      })
      this.setState({
        warehouseData: data
      })
    }
  }

  // 查询仓库下可报废的危化品
  handleChangeCk = async e => {
    const res = await findKcInfoByCk({ ckid: e.join(',') })
    if (res.code === 20000) {
      const data = res.data.map(item => {
        return {
          label: item.whp_mc,
          value: item.whp_bh,
        }
      })
      this.setState({
        whpData: data,
      })
    }
  }

  // 查询仓库下可报废的危化品的详情
  handleChangeWhp = async e => {
    const { ckid } = this.state
    const res = await findKcmxInfo({ flag: 2, whpBh: e.join(','), ckid: ckid.join(',') })
    if (res.code === 20000) {
      const data = res.data.map(item => {
        return {
          label: item.whp_xh,
          value: item.whp_xh,
        }
      })
      this.setState({
        whpInfo: data
      })
    }
  }

  // 提交
  submit = async () => {
    const { ckid, whp_bh, whp_xh, bf_ms, warehouseData } = this.state
    if (ckid === undefined || whp_bh === undefined || whp_xh === undefined || bf_ms === '') {
      Toast.info('请补全上述内容！')
      return false
    }

    const params = {
      ckid: ckid.join(','),
      whp_bh: whp_bh.join(','),
      whp_xh: whp_xh.join(','),
      bf_ms: bf_ms,
      ckmc: warehouseData.find(x => String(x.value) === ckid.join(','),).label
    }
    const res = await saveBfWhpInfo(params)
    if (res.code === 20000) {
      Toast.info("提交成功！")
      setTimeout(() => {
        this.props.history.goBack()
      }, 2000);
    }
  }

  render() {
    const { warehouseData, bf_ms, whp_bh, ckid, whpInfo, whpData, whp_xh } = this.state
    return (
      <div className='subscription_add'>
        <div className='subscription_add_title'>
          
        </div>
        <div className='subscription_add_form'>
          <div className='subscription_add_picker'>
            <Picker
              data={warehouseData}
              value={ckid}
              cols={1}
              onChange={(e) => {
                this.setState({
                  ckid: e,
                  whp_bh: undefined,
                  whp_xh: undefined,
                }, () => {
                  this.handleChangeCk(e)
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>所属仓库</List.Item>
            </Picker>
          </div>


          <div className='subscription_add_picker'>
            <Picker
              data={whpData}
              value={whp_bh}
              cols={1}
              onChange={(e) => {
                this.setState({
                  whp_bh: e,
                  whp_xh: undefined,
                })
                this.handleChangeWhp(e)
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>危化品</List.Item>
            </Picker>
          </div>
          <div className='subscription_add_picker'>
            <Picker
              data={whpInfo}
              value={whp_xh}
              cols={1}
              onChange={(e) => {
                this.setState({
                  whp_xh: e
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>报废危化品</List.Item>
            </Picker>
          </div>
        </div>


        <div className='subscription_add_form'>
          <div className='subscription_add_text'>
            <div><span style={{ color: 'red' }}>* </span>描述</div>
            <TextareaItem
              placeholder="请输入"
              rows={5}
              value={bf_ms}
              onChange={(e) => {
                this.setState({
                  bf_ms: e
                })
              }}
            />
          </div>
        </div>



        <div className='subscription_list_but'>
          <div onClick={() => {
            this.submit()
          }}>提交报废</div>
        </div>

      </div >
    )
  }
}

export default Add
