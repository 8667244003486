import React, { Component } from 'react'
import { Grid } from 'antd-mobile'
import { findModuleInfo } from './../../../api/api'
import { tjMobile, countRkNum } from './../../../api/whp'
import IP from './../../../config/config'
import './index.less'

class Chemicals extends Component {

  state = {
    moduleData: [],
    tjData: '',
    countNum: 0
  }

  componentDidMount = () => {
    document.title = '危化品管理'
    window.scrollTo(0, 0)
    this.tjMobile()
    this._countRkNum()
    this.fetchModule()
  }

  _countRkNum = async () => {
    const res = await countRkNum()
    if (res.code === 20000) {
      this.setState({
        countNum: res.data
      })
    }
  }

  tjMobile = async () => {
    const res = await tjMobile()
    if (res.code === 20000) {
      this.setState({
        tjData: res.data
      })
    }
  }

  // 查询模块
  fetchModule = async () => {
    const res = await findModuleInfo()
    if (res.code === 20000) {
      const data = (res.data || []).filter(x => x.module_type === '6')
      this.setState({
        moduleData: data
      })
    }
  }

  handleSkipModule = item => {
    this.props.history.push(item.module_url)
  }

  render() {
    const { moduleData, tjData } = this.state
    return (
      <div className='chemicals'>
        <div className='chemicals_title'>危化品管理</div>
        <div className='chemicals_desc'>科学实验 安全为先</div>
        <div className='chemicals_con'>
          <div className='chemicals_con_intro'>
            <div className='chemicals_con_intro_header'>
              <div className='chemicals_intro_header_list'>
                <div className='chemicals_intro_header_list_num'>{tjData.whpzl}</div>
                <div className='chemicals_intro_header_list_title'>危化品种类</div>
              </div>
              <div className='chemicals_intro_header_list'>
                <div className='chemicals_intro_header_list_num'>{tjData.totalNum}</div>
                <div className='chemicals_intro_header_list_title'>危化品数量</div>
              </div>
              <div className='chemicals_intro_header_list'>
                <div className='chemicals_intro_header_list_num'>{tjData.cksl}</div>
                <div className='chemicals_intro_header_list_title'>仓库数量</div>
              </div>
            </div>
            <div className='chemicals_con_intro_bottom'>
              <div className='chemicals_con_intro_bottom_list'>
                <div className='chemicals_con_intro_bottom_list_title'>非管制类</div>
                <div className='chemicals_con_intro_bottom_list_num' style={{ color: '#00b4c4' }}>{tjData.fgzl}</div>
              </div>
              <div className='chemicals_con_intro_bottom_line'></div>
              <div className='chemicals_con_intro_bottom_list'>
                <div className='chemicals_con_intro_bottom_list_title'>管制类</div>
                <div className='chemicals_con_intro_bottom_list_num' style={{ color: '#f05248' }}>{tjData.gzl}</div>
              </div>
            </div>
          </div>
          <div className='chemicals_con_status'>
            <div className='status_left'>
              <img alt='' src={require('../../../images/whp/whp_status.png')} />
              <div className='chemicals_con_status_title'>
                <span>{this.state.countNum}个申购已通过</span>&nbsp;&nbsp;待入库
              </div>
            </div>
            <div className='status_right' onClick={() => {
              this.props.history.push('/module/warehousingManager/list')
            }}>
              <span>立即处理</span>
              <img alt='' src={require('../../../images/xq.png')} />
            </div>
          </div>
        </div>
        <div className='chemicals_grid'>
          <Grid
            columnNum={3}
            data={moduleData}
            hasLine={false}
            renderItem={item => (
              <div className='chemicals_con_grid_list' onClick={() => this.handleSkipModule(item)}>
                <img src={IP.fileHost + item.module_imge} alt="" style={{ width: '44px' }} />
                <span>{item.module_name}</span>
              </div>
            )}
          />
        </div>
      </div>
    )
  }
}

export default Chemicals
