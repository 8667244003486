import React, { Component } from 'react'
import { req_selectOne, req_findRecord } from '../../../api/cgyy'
import { Toast, Checkbox } from 'antd-mobile'
import cookie from 'react-cookies'
import moment from 'moment'
import './index.css'
const CheckboxItem = Checkbox.CheckboxItem


export default class componentName extends Component {
  state = {
    ksrq: '',//开始时间
    jsrq: '',//结束时间
    kfzc: [],//开放周次
    zdyysj: '',//最大预约天数
    timeDate: [],//存储的时间段
    yyrq: '',//日期
    yysj: '',//时间
    getweekData: [],
    nextWeekData: [],
    yygz: '',
    visible: false,
    yysjid: ''
  }

  componentDidMount = () => {
    this.getDate()
    document.title = "参观预约"
    this.fetchConfig()
  }

  findRecord = async (dateTime) => {
    const res = await req_findRecord({ dateTime })
    if (res.code === 20000) {
      console.log(res.data)
      this.setState({
        timeDate: res.data
      })
    }
  }

  // 日期组合
  getDate = () => {
    const getWeek = this.getWeekDate()
    const nextWeek = this.nextWeekDate()
    this.setState({
      getweekData: getWeek,
      nextWeekData: nextWeek
    })
  }

  // 当星期日期
  getWeekDate () {
    var new_Date = new Date() //获取本周一周日期
    var timesStamp = new_Date.getTime()
    var currenDay = new_Date.getDay()
    var dates = []
    for (var i = 0; i < 7; i++) {
      var das = new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - (currenDay + 6) % 7)).toLocaleDateString()
      dates.push(das)
    }
    return dates
  }

  //下星期日期
  nextWeekDate () {
    var new_Date = new Date(moment().add('7', 'day')) //获取本周一周日期
    var timesStamp = new_Date.getTime()
    var currenDay = new_Date.getDay()
    var dates = []
    for (var i = 0; i < 7; i++) {
      var das = new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - (currenDay + 6) % 7)).toLocaleDateString()
      dates.push(das)
    }
    return dates
  }

  fetchConfig = async () => {
    const res = await req_selectOne()
    if (res.code === 20000) {
      this.setState({
        ksrq: res.data.ksrq,
        jsrq: res.data.jsrq,
        kfzc: res.data.kfzc.split(','),
        zdyysj: res.data.zdyysj,
        // timeDate: res.data.reservationConfigTimeEntities,
        yygz: res.data.yygz
      })
    }
  }

  // 自定义渲染日期单元格，返回内容覆盖单元格		
  dateFullCellRender = (value, key) => {
    const { ksrq, jsrq, kfzc, zdyysj, yyrq } = this.state
    // 判断，可预约的时候
    const week = moment(value).day()
    if (moment(ksrq) <= moment() && moment() <= moment(jsrq) && moment(value) > moment()) {
      if (kfzc.filter(item => {
        if (week === 0) {
          return item === '7'
        }
        return item === String(week)
      }).length) {
        // 在当前天里往后退zdyysj天
        if (moment(value) < moment().add(zdyysj, 'day')) {
          return <div key={key} className={yyrq == moment(value).format('YYYY-MM-DD') ? 'calendar_item calendar_item_selected' : 'calendar_item'} onClick={() => {
            this.findRecord(moment(value).format('YYYY-MM-DD'))
            this.setState({
              yyrq: moment(value).format('YYYY-MM-DD'),
              yysj: '',
            })
          }}>
            <div className='calendar_month'>{moment(value).format('MM')}月</div>
            <div className='calendar_day'>{moment().format('YYYY-MM-DD') == moment(value).format('YYYY-MM-DD') ? '今天' : moment(value).format('DD') + '日'}</div>
            <div className='calendar_state'>
              {/* 可预约 */}
            </div>
          </div>
        }
      }

    }
    if (moment().month() === moment(value).month()) {
      // 判断，不可预约但显示描边
      return <div key={key} className='calendar_item calendar_item_none' >
        <div className='calendar_month'>{moment(value).format('MM')}月</div>
        <div className='calendar_day'>{moment().format('YYYY-MM-DD') == moment(value).format('YYYY-MM-DD') ? '今天' : moment(value).format('DD') + '日'}</div>
        <div className='calendar_state'>
          &nbsp;
        </div>
      </div>
    } else {
      // 判断，不可预约
      return <div key={key} className='calendar_item calendar_item_no'>
        <div className='calendar_month'>{moment(value).format('MM')}月</div>
        <div className='calendar_day'>{moment().format('YYYY-MM-DD') == moment(value).format('YYYY-MM-DD') ? '今天' : moment(value).format('DD') + '日'}</div>
        <div className='calendar_state'>
          &nbsp;
        </div>
      </div>
    }

  }

  // 下一步
  submit = () => {
    const { yyrq, yysj, visible, yysjid } = this.state
    if (yyrq === '') {
      Toast.info("请选择日期!")
      return
    }
    if (yysj === '') {
      Toast.info("请选择时间段!")
      return
    }

    if (visible === false) {
      Toast.info("请先阅读访客须知并勾选最下方选择框!")
      return
    }
    this.props.history.push(`/module/cgyy/submit/${yyrq}/${yysj}/${yysjid}`)
  }

  render () {
    const { timeDate, yysj, getweekData, nextWeekData, yygz } = this.state
    return (
      <div className="cgyy_home">
        <div className='home_slide'>
          <img src={require('../../../images/cgyy/01.png')} alt='' />
          <div className='home_slide_my' onClick={() => {
            this.props.history.push('/module/cgyy/my')
          }}>
            我的预约
          </div>
          <div className='home_slide_approval' onClick={() => {
            this.props.history.push('/module/cgyy/approval')
          }}>
            我的审批
          </div>

        </div>

        <div className='home_calendar'>
          <div className='home_calendar_head'>
            选择参观日期
          </div>
          <div className='home_calendar_main'>
            <div className='home_calendar_table'>
              <div className='home_calendar_table_head'>
                <div>一</div>
                <div>二</div>
                <div>三</div>
                <div>四</div>
                <div>五</div>
                <div>六</div>
                <div>日</div>
              </div>
              {/* main */}
              <div className='home_calendar_table_cont'>
                {getweekData.map((item, key) => {
                  return this.dateFullCellRender(item, key)
                })}
              </div>
              <div className='home_calendar_table_cont'>
                {nextWeekData.map((item, key) => {
                  return this.dateFullCellRender(item, key)
                })}
              </div>
            </div>
          </div>
          <div className='home_time_title'>
            选择参观时段
          </div>
          <div className='home_time_cont'>
            {
              timeDate.map(item => {
                console.log(item.yycs, item.num)
                return (
                  <div className={yysj === (item.kssj + '-' + item.jssj) ? 'home_time_item home_time_item_selected' : 'home_time_item'} key={item.id} onClick={() => {
                    if (item.yycs - item.num === 0) {
                      return
                    }
                    this.setState({
                      yysj: item.kssj + '-' + item.jssj,
                      yysjid: item.id
                    })
                  }}>
                    <div>{item.kssj}-{item.jssj}</div>
                    <div style={{ color: item.yycs - item.num === 0 ? 'red' : '#00b4c4' }}>{item.yycs - item.num === 0 ? '已约满' : '可预约'}</div>
                  </div>
                )
              })
            }

          </div>

        </div>
        <div className='home_tips' dangerouslySetInnerHTML={{ __html: yygz }}>
          {/* <p>访客须知:<br />
            &emsp;&emsp;欢迎您来到华北水利水电大学水文化馆参观，为保障您有一个安全、舒适的参观环境，请遵守以下规定。
            <br />1、本馆采取网络实名制预约免费参现，观众可提前在"水润中国"微信公众号进行预约，凭预约二维码、有效证件刷卡或登记进入，预约成功后，确实无法前往，请及时取消预约。2次预约成功但未入馆参观人员，15天内将无法再次预约。<br />2、本馆开放时间为:每周一至周五9:00-17:00(16:30停止入馆)(法定节假日除外)。<br />3、酗酒者、衣冠不整者以及无行为能力或限制行为能力者、无监护人陪伴的，谢绝入馆。身高为1.2米以下儿童须有家长陪同，行动不便的老年人和残障人员建议有亲朋好友陪同参观。<br />4、管制械具、打火机等易燃易爆品、饮料、食品及宠物等禁止带入场馆。<br />5、参观前请将随身包惠寄存，贵重物品自行保管。<br />6、参观时请自觉遵守参观秩序，不得大声喧哗，以免影响他人。馆内禁止吸烟。请勿高声谈笑、追逐嬉戏；请勿随地吐痰、抛弃纸屑杂物；请勿在休息座椅上躺卧；请将手机设置为静音。<br />7、为保护展厅展品安全，展厅内禁止使用闪光灯、支架类摄影器材拍照、摄像；请勿使用自拍杆拍摄。<br />8、请勿触摸文物及展品。<br />9、馆内文物展品、展览设施及提供的其它公共服务设施，如遇人为损坏须照价赔偿并承担相应法律责任。<br />10、请勿进未开放空间，以免发生事故。<br />11、如遇各类突发事件，请服从工作人员指挥，有序前往安全场所。</p> */}
        </div>
        <div className='index_checkbox'>
          <CheckboxItem onChange={(e) => {
            this.setState({
              visible: e.target.checked
            })
          }}>
            我已知悉<span>《访客须知》</span>
          </CheckboxItem>
        </div>

        <div className='home_logo'>
          {/* <img src={require('../../../images/cgyy/03.png')} alt='' /> */}
        </div>


        <div className='home_but'>
          <div onClick={() => {
            this.submit()
          }}>
            下一步
          </div>
        </div>
      </div>
    )
  }
}
