import React, { Component } from 'react'
import { Progress } from 'antd'
import { selectOneZnkt, sendCmdZnkt } from '../../../api/api'
import './aircondition_detail.less'

class AirconditionDetail extends Component {

  state = {
    data: {},
  }

  componentDidMount = () => {
    this.fetch(this.props.match.params.id)
  }

  fetch = async id => {
    const res = await selectOneZnkt({ id })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }
  // 修改开关
  handleChangeSwitch = item => {
    if (item.device_run_state === 0) {
      this.handleSendCmd(item.device_imei, 'on')
    } else {
      this.handleSendCmd(item.device_imei, 'off')
    }
  }

  // 空调操作
  handleSendCmd = async (devcieImei, cmdType, cmdParam) => {
    const res = await sendCmdZnkt({ devcieImei, cmdType, cmdParam })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }
  // 刷新状态
  handleSx = () => {
    this.fetch(this.props.match.params.id)
  }
  //设备定时
  handleTimingConfig = data => this.props.history.push(`/module/aircondition/timingConfiguration/${data.device_id}/${0}`)
  // 操作记录
  handleOperate = (e, data) => {
    this.props.history.push(`/module/aircondition/operationRecords/${data.device_imei}`)
  }
  // 定时列表
  handleTimingList = (e, data) => {
    this.props.history.push(`/module/aircondition/timingList/${data.device_id}`)
  }
  render () {
    const { data, wt } = this.state
    return (
      <div className='acDetail'>
        <div className={`acDetail_img_bg ${data.device_state === 1 ? 'acDetail_online' : 'acDetail_offline'}`}>
          <div className='acDetail_img'>
            <img alt='' src={require(`../../../img/controll/${data.device_state === 1 ? 'airCondition-top' : 'airCondition-top-2'}.png`)} />
          </div>
        </div>
        <div className='acDetail_main'>
          <div className='acDetail_con'>
            <div className='acDetail_con_left' style={{ width: `${wt}px` }}>
              <div className='acDetail_con_left_name'>{data.device_name}</div>
              <div className='acDetail_con_left_addr'>
                {data.device_building_name}/{data.device_floor_name}/{data.device_room_name}
              </div>
              <div className='acDetail_con_left_desc'>
                <div className={`acDetail_con_left_status ${data.device_state === 1 ? 'acDetail_status_online' : 'acDetail_status_offline'}`}>
                  <span></span>
                  <span>{data.device_state === 1 ? '设备在线' : '设备离线'}</span>
                </div>
                {
                  data.device_run_state !== 0 ? <div className='acDetail_con_left_desc_con'>已开机</div> : ''
                }
              </div>
            </div>
            {
              data.device_state === 1 ? <div className='acDetail_con_img' onClick={() => this.handleChangeSwitch(data)}>
                {
                  data.device_run_state !== 0 ? <img alt='' src={require('../../../images/kai.png')} /> : <img alt='' src={require('../../../images/guan.png')} />
                }
              </div> : <div></div>
            }
            {
              data.device_state === 0 ? <div className='acDetail_con_sx' onClick={() => this.handleSx()}>
                <img alt='' src={require('../../../images/sx.png')} />
                <span>刷新状态</span>
              </div> : ''
            }
          </div>
          <div className='acDetail_temp'>
            <div className='acDetail_temp_title'>温度调节</div>
            <div className='acDetail_temp_con'>
              {
                data.device_run_state === 0 ? <img alt='' src={require('../../../images/closeReduce.png')} /> : <img alt='' src={require('../../../images/reduce.png')} onClick={() => this.handleSendCmd(data.device_imei, 'reduce')} />
              }
              <div className='acDetail_temp_con_progress'>
                {
                  data.device_run_state === 0 ?
                    <Progress strokeColor={'#d3dbe3'} strokeWidth={44} percent={data.device_setup_temp !== -1 ? (data.device_setup_temp / 30) * 100 : 0} showInfo={false} /> :
                    <Progress strokeColor={'linear-gradient(180deg, #00B4C4 0%, rgba(0,180,196,0.6) 100%)'} strokeWidth={44} percent={(data.device_setup_temp / 30) * 100} showInfo={false} />
                }
                <span>{data.device_setup_temp}℃</span>
              </div>
              {
                data.device_run_state === 0 ?
                  <img alt='' src={require('../../../images/closeAdd.png')} /> :
                  <img alt='' src={require('../../../images/add.png')} onClick={() => this.handleSendCmd(data.device_imei, 'plus')} />
              }
            </div>
          </div>
          <div className='acDetail_model_wind'>
            <div className='acDetail_wind'>
              <div className='acDetail_wind_title'>风速</div>
              <div className='acDetail_wind_con'>
                <div className='acDetail_wind_con_cut'>
                  <div className={`acDetail_wind_con_list ${data.device_run_state === 0 ? 'wind_shutdown' : data.device_setup_wind === 0 ? 'wind_selected' : 'wind_unchecked'}`} onClick={() => data.device_run_state === 0 ? {} : this.handleSendCmd(data.device_imei, 'wind', 0)}>
                    <span>自动</span>
                  </div>
                  <div className={`acDetail_wind_con_list ${data.device_run_state === 0 ? 'wind_shutdown' : data.device_setup_wind === 1 ? 'wind_selected' : 'wind_unchecked'}`} onClick={() => data.device_run_state === 0 ? {} : this.handleSendCmd(data.device_imei, 'wind', 1)}>
                    <span>低速</span>
                  </div>
                </div>
                <div className='acDetail_wind_con_cut'>
                  <div className={`acDetail_wind_con_list ${data.device_run_state === 0 ? 'wind_shutdown' : data.device_setup_wind === 2 ? 'wind_selected' : 'wind_unchecked'}`} onClick={() => data.device_run_state === 0 ? {} : this.handleSendCmd(data.device_imei, 'wind', 2)}>
                    <span>中速</span>
                  </div>
                  <div className={`acDetail_wind_con_list ${data.device_run_state === 0 ? 'wind_shutdown' : data.device_setup_wind === 3 ? 'wind_selected' : 'wind_unchecked'}`} onClick={() => data.device_run_state === 0 ? {} : this.handleSendCmd(data.device_imei, 'wind', 3)}>
                    <span>高速</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='acDetail_model'>
              <div className='acDetail_model_title'>空调模式</div>
              <div className='acDetail_model_con'>
                {
                  data.device_run_state === 0 ? <div className='acDetail_model_con_list temp_shutdown'>
                    <span>制热</span>
                  </div> : (
                    data.device_setup_state === 2 ? <div className='acDetail_model_con_list temp_selected'>
                      <span>制热</span>
                    </div> : <div className='acDetail_model_con_list temp_unchecked' onClick={() => this.handleSendCmd(data.device_imei, 'hea')}>
                      <span>制热</span>
                    </div>
                  )
                }
                {
                  data.device_run_state === 0 ? <div className='acDetail_model_con_list temp_shutdown'>
                    <span>制冷</span>
                  </div> : (
                    data.device_setup_state === 1 ? <div className='acDetail_model_con_list temp_selected'>
                      <span>制冷</span>
                    </div> : <div className='acDetail_model_con_list temp_unchecked' onClick={() => this.handleSendCmd(data.device_imei, 'col')}>
                      <span>制冷</span>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          {/* <div className='acDetail_operation' onClick={(data) => this.handleTimingConfig(data)}>
            <div className='acDetail_operation_left'>
              <img alt='' src={require('../../../img/sbds.png')} />
              <span>设备定时</span>
            </div>
            <img alt='' src={require('../../../images/more2.png')} />
          </div> */}
          <div className='acDetail_operation' onClick={(e) => this.handleTimingList(e, data)}>
            <div className='acDetail_operation_left'>
              <img alt='' src={require('../../../img/sbds.png')} />
              <span>定时列表</span>
            </div>
            <img alt='' src={require('../../../images/more2.png')} />
          </div>
          <div className='acDetail_operation' onClick={(e) => this.handleOperate(e, data)}>
            <div className='acDetail_operation_left'>
              <img alt='' src={require('../../../img/czjl.png')} />
              <span>操作记录</span>
            </div>
            <img alt='' src={require('../../../images/more2.png')} />
          </div>
        </div>

      </div>
      // <div className='airconditionDetail'>
      //   <div className='airconditionDetail_air'>
      //     {
      //       data.device_run_state === 0 ? <img alt='' src={require('../../../images/airClose.png')} /> : <img alt='' src={require('../../../images/airOpen.png')} />
      //     }
      //   </div>
      //   <div className='airconditionDetail_con'>
      //     <div className='airconditionDetail_open'>
      //       <div className='airconditionDetail_open_open'>
      //         {
      //           data.device_run_state === 0 ? <img alt='' src={require('../../../images/openBtn.png')} onClick={() => this.handleSendCmd(data.device_imei, 'on')} /> : <img alt='' src={require('../../../images/close.png')} onClick={() => this.handleSendCmd(data.device_imei, 'off')} />
      //         }
      //         <span>{data.device_run_state === 0 ? '开机' : '关机'}</span>
      //       </div>
      //       {
      //         data.device_run_state === 0 ? <div className='airconditionDetail_open_time close_time'>定时</div> : <div className='airconditionDetail_open_time open_time' onClick={() => this.props.history.push(`/module/aircondition/timingConfiguration/${data.device_id}/${0}`)}>定时</div>
      //       }
      //     </div>
      //     <div className='airconditionDetail_temp'>
      //       <div className='airconditionDetail_temp_title'>温度调节</div>
      //       <div className='airconditionDetail_temp_con'>
      //         {
      //           data.device_run_state === 0 ? <img alt='' src={require('../../../images/closeReduce.png')} /> : <img alt='' src={require('../../../images/reduce.png')} onClick={() => this.handleSendCmd(data.device_imei, 'reduce')} />
      //         }
      //         <div className='airconditionDetail_temp_con_progress'>
      //           {
      //             data.device_run_state === 0 ? <Progress strokeColor={'#d3dbe3'} strokeWidth={36} percent={data.device_setup_temp !== -1 ? (data.device_setup_temp / 30) * 100 : 0} showInfo={false} /> : <Progress strokeColor={'#00b4c4'} strokeWidth={36} percent={(data.device_setup_temp / 30) * 100} showInfo={false} />
      //           }
      //           <span>{data.device_setup_temp}℃</span>
      //         </div>
      //         {
      //           data.device_run_state === 0 ? <img alt='' src={require('../../../images/closeAdd.png')} /> : <img alt='' src={require('../../../images/add.png')} onClick={() => this.handleSendCmd(data.device_imei, 'plus')} />
      //         }
      //       </div>
      //     </div>
      //     <div className='airconditionDetail_model_wind'>
      //       <div className='airconditionDetail_model'>
      //         <div className='airconditionDetail_model_title'>空调模式</div>
      //         <div className='airconditionDetail_model_con'>
      //           {
      //             data.device_run_state === 0 ? <div className='airconditionDetail_model_con_list temp_shutdown'>
      //               <img alt='' src={require('../../../images/cold3.png')} />
      //               <span>制冷</span>
      //             </div> : (
      //               data.device_setup_state === 1 ? <div className='airconditionDetail_model_con_list temp_selected'>
      //                 <img alt='' src={require('../../../images/cold2.png')} />
      //                 <span>制冷</span>
      //               </div> : <div className='airconditionDetail_model_con_list temp_unchecked' onClick={() => this.handleSendCmd(data.device_imei, 'col')}>
      //                 <img alt='' src={require('../../../images/cold1.png')} />
      //                 <span>制冷</span>
      //               </div>
      //             )
      //           }
      //           {
      //             data.device_run_state === 0 ? <div className='airconditionDetail_model_con_list temp_shutdown'>
      //               <img alt='' src={require('../../../images/hot3.png')} />
      //               <span>制热</span>
      //             </div> : (
      //               data.device_setup_state === 2 ? <div className='airconditionDetail_model_con_list temp_selected'>
      //                 <img alt='' src={require('../../../images/hot2.png')} />
      //                 <span>制热</span>
      //               </div> : <div className='airconditionDetail_model_con_list temp_unchecked' onClick={() => this.handleSendCmd(data.device_imei, 'hea')}>
      //                 <img alt='' src={require('../../../images/hot1.png')} />
      //                 <span>制热</span>
      //               </div>
      //             )
      //           }
      //         </div>
      //       </div>
      //       <div className='airconditionDetail_wind'>
      //         <div className='airconditionDetail_wind_title'>风速</div>
      //         <div className='airconditionDetail_wind_con'>
      //           <div className='airconditionDetail_wind_con_cut'>
      //             <div className={`airconditionDetail_wind_con_list ${data.device_run_state === 0 ? 'wind_shutdown' : data.device_setup_wind === 0 ? 'wind_selected' : 'wind_unchecked'}`} onClick={() => data.device_run_state === 0 ? {} : this.handleSendCmd(data.device_imei, 'wind', 0)}>
      //               <span>自动</span>
      //             </div>
      //             <div className={`airconditionDetail_wind_con_list ${data.device_run_state === 0 ? 'wind_shutdown' : data.device_setup_wind === 1 ? 'wind_selected' : 'wind_unchecked'}`} onClick={() => data.device_run_state === 0 ? {} : this.handleSendCmd(data.device_imei, 'wind', 1)}>
      //               <span>低速</span>
      //             </div>
      //           </div>
      //           <div className='airconditionDetail_wind_con_cut'>
      //             <div className={`airconditionDetail_wind_con_list ${data.device_run_state === 0 ? 'wind_shutdown' : data.device_setup_wind === 2 ? 'wind_selected' : 'wind_unchecked'}`} onClick={() => data.device_run_state === 0 ? {} : this.handleSendCmd(data.device_imei, 'wind', 2)}>
      //               <span>中速</span>
      //             </div>
      //             <div className={`airconditionDetail_wind_con_list ${data.device_run_state === 0 ? 'wind_shutdown' : data.device_setup_wind === 3 ? 'wind_selected' : 'wind_unchecked'}`} onClick={() => data.device_run_state === 0 ? {} : this.handleSendCmd(data.device_imei, 'wind', 3)}>
      //               <span>高速</span>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    )
  }
}

export default AirconditionDetail
