import React, { Component } from 'react'
import moment from 'moment'

export default class CountDownSelf extends Component {
  state = {
    daysList: ['-', '-'],
    hoursList: ['-', '-'],
    minutesList: ['-', '-'],
    secondsList: ['-', '-'],
    timer: null,
  }
  componentDidMount() {
    this.showDayPanel()
  }

  componentWillUnmount() {
    clearInterval(this.state.timer)
  }

  showDayPanel = () => {
    const end = moment(this.props.deadline)
    let days = 0
    let hours = 0
    let minutes = 0
    let seconds = 0
    this.setState({
      timer: setInterval(() => {
        const start = moment(Date.now())
        const date3 = end.diff(start, 'seconds')
        if (date3 >= 0) {
          //计算出天数
          days = date3 > 0 ? parseInt(date3 / 86400) : 0
          //计算出小时数
          hours = parseInt(date3 / 3600) - days * 24    //总小时数-换算成天的小时数=剩余小时数
          //计算分钟数
          minutes = parseInt(date3 % 3600 / 60)
          //计算秒数
          seconds = parseInt(date3 % 60)
        } else {
          clearInterval(this.state.timer)
        }
        date3 <= 0 ? this.props.changeParentState(true) : this.props.changeParentState(false)
        this.setState({
          daysList: (days < 10 ? `0${days}` : String(days)).split(''),
          hoursList: (hours < 10 ? `0${hours}` : String(hours)).split(''),
          minutesList: (minutes < 10 ? `0${minutes}` : String(minutes)).split(''),
          secondsList: (seconds < 10 ? `0${seconds}` : String(seconds)).split('')
        }, () => {
          console.log(this.state.daysList, this.state.hoursList, this.state.minutesList)
        })
      }, 1000)
    })
  }
  clearTimer = () => {
    clearInterval(this.state.timer)
  }

  render() {
    const { daysList, hoursList, minutesList, secondsList } = this.state
    return (
      <span>
        {daysList.filter(x => x === '0').length === 2 && hoursList.filter(x => x === '0').length === 2 && minutesList.filter(x => x === '0').length === 2 ? ''
          :
          <span>
            {(daysList || []).map((day, k) => <span key={k}>{day}</span>)}天
            {(hoursList || []).map((hours, k) => <span key={k}>{hours}</span>)}时
            {(minutesList || []).map((min, k) => <span key={k}>{min}</span>)}分后&nbsp;
          </span>}
      </span>
    )
  }
}