import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import RouteMap from './route/index'
import 'antd/dist/antd.css' // or 'antd/dist/antd.less'
import Utils from './utils/utils'
import IP from './config/config'
import { req_wxLogin } from './api/api'
import cookie from 'react-cookies'
import { createBrowserHistory } from 'history'
const history = createBrowserHistory()

export default class App extends Component {
  state = {
    isShow: false
  }

  componentDidMount = () => {
    // 加判断，如果是访问的网页扫码登录直接进
    const url = Utils.GetQueryString('state');
    const code = Utils.GetQueryString('code')
    if (url) {
      const arr = url.split('~')
      if (arr[0] === 'web') {
        history.push({ pathname: `/web/${arr[1]}`, state: { code } })
        this.setState({
          isShow: true
        })
        return
      }
    }


    this.handleLogin()
    // 触屏滑动
    var div1 = document.querySelector('#btn')
    var oL
    var oT
    //限制最大宽高，不让滑块出去
    var maxW = document.body.clientWidth - div1.offsetWidth
    var maxH = document.body.clientHeight - div1.offsetHeight - 50
    //手指触摸开始，记录div的初始位置
    div1.addEventListener('touchstart', function (e) {
      var ev = e || window.event
      var touch = ev.targetTouches[0]
      oL = touch.clientX - div1.offsetLeft
      oT = touch.clientY - div1.offsetTop
      document.addEventListener("touchmove", defaultEvent, false)
    })
    //触摸中的，位置记录
    div1.addEventListener('touchmove', function (e) {
      var ev = e || window.event
      var touch = ev.targetTouches[0]
      var oLeft = touch.clientX - oL
      var oTop = touch.clientY - oT
      if (oLeft < 0) {
        oLeft = 0
      } else if (oLeft >= maxW) {
        oLeft = maxW
      }
      if (oTop < 0) {
        oTop = 0
      } else if (oTop >= maxH) {
        oTop = maxH
      }
      div1.style.left = oLeft + 'px'
      div1.style.top = oTop + 'px'
    })
    //触摸结束时的处理
    div1.addEventListener('touchend', function () {
      document.removeEventListener("touchmove", defaultEvent)
    })
    //阻止默认事件
    function defaultEvent(e) {
      e.preventDefault()
    }
  }

  handleLogin = () => {
    const code = Utils.GetQueryString('code')
    const path = Utils.GetQueryString('path')
    if (code) {
      // 重定向之后
      this.getUserInfoByCode(code)
      return
    }
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${IP.appId}&redirect_uri=${IP.wxredirect}&response_type=code&scope=snsapi_userinfo&state=${path}&connect_redirect=1#wechat_redirect`
    return
  }

  getUserInfoByCode = async (code) => {
    const result = await req_wxLogin({ code })
    if (result.code === 20000) {
      cookie.save('token', result.data.token)
      cookie.save('openId', result.data.openId)
      cookie.save('status', result.data.state)
      cookie.save('userData', result.data)
      cookie.save('sf', result.data.sf)
      const url = Utils.GetQueryString('state')
      if (url && url !== 'null') {
        // 1656229073664532480
        // IP + ?path=bind~imei // 班牌绑定
        // IP + ?path=lab~roomId // 实验室信息
        // IP + ?path=qrLab~roomId // 实验室信息 - 隐患上报 - 自查
        // IP + ?path=luSign~paperId // 试卷绑定承诺书
        const urlArray = url.split('~')
        const str = urlArray[0]
        if (str === 'bind') {
          history.push(`/binding/${urlArray[1]}`)
        }
        if (str === 'lab') {
          history.push(`/lab/${urlArray[1]}`)
        }

        if (str === 'qrLab') {
          history.push(`/module/patrol/roomReport/${urlArray[1]}`)
        }
        if (str === 'luSign') {
          history.push(`/module/safty/luSign/${urlArray[1]}`)
        }
        if (str === 'qrWhp') {
          history.push(`/module/chemicals/qrcodeSign/${urlArray[1]}`)
        }
        if (str === 'qrOutWhp') {
          history.push(`/module/chemicals/qrcodeSign/${urlArray[1]}`)
        }
        if (str === 'qrGas') {
          history.push(`/module/gasCylinder/manageRecord/${urlArray[1]}`)
        }
      }
      this.setState({ isShow: true })
    } else if (result.code === 20010) {
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${IP.appId}&redirect_uri=${IP.wxredirect}&response_type=code&scope=snsapi_userinfo&state=&connect_redirect=1#wechat_redirect`
    }
  }

  handleClickHome = () => {
    const url = window.location.pathname
    if (url !== '/' && url !== '/home' && url !== '/attestation' && (url.indexOf('/web/') != -1 ? false : true)) {
      this.setState({
        isShow: false,
      }, () => {
        history.push('/home')
        this.setState({ isShow: true })
      })
    }
  }

  render() {
    const { isShow } = this.state
    return (
      <div className='lab-app'>
        <div className='lab-home' id='btn' onClick={() => this.handleClickHome()}>
          <img alt='' src={require('./images/quit.png')} />
        </div>
        {isShow && <BrowserRouter>
          <RouteMap />
        </BrowserRouter>}
      </div>
    )
  }
}