import React, { Component } from 'react'
import { Modal, Toast, Button } from 'antd-mobile'
import { findLyInfoDetailsById, cancelLysq } from '../../../../api/gas'
import IP from './../../../../config/config'
import moment from 'moment'

class deviceDetail extends Component {

  state = {
    data: {},
    visible: false,
    opinion: '',
    signVisible: false,
    base64: '',
    signAddvisible: false,
    width: window.innerWidth,
    height: window.innerHeight,
    fileUrl: '',
    imgVisible: false,
  }

  componentDidMount = () => {
    document.title = '出库详情'
    window.scrollTo(0, 0)
    this.fetch(this.props.match.params.id)
  }

  fetch = async lydh => {
    const res = await findLyInfoDetailsById({ lydh })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }

  // 取消领用
  handleCancel = async () => {
    const res = await cancelLysq({ lybh: this.props.match.params.id })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.fetch()
      setTimeout(() => {
        this.props.history.go(-2)
      }, 2000);
    }
  }

  // 点击签字
  goSign = () => {
    this.setState({
      signAddvisible: true
    })
    setTimeout(() => {
      eval("PageReady()")
    }, 500)
  }
  // 清除签字
  handleClearBtnClick = () => {
    eval('clearCanvas()')
  }

  // 签字确认
  handleOk = () => {
    const data = eval('saveSignature()')
    this.setState({
      signAddvisible: false
    })
    this.rotateBase64Img(data, 270)
  }

  //将canvas图片转为base64图片
  rotateBase64Img = (src, edg, callback) => {
    const This = this
    var canvas = document.createElement("canvas")
    var ctx = canvas.getContext("2d")
    var imgW//图片宽度
    var imgH//图片高度
    var size//canvas初始大小
    if (edg % 90 != 0) {
      throw '旋转角度必须是90的倍数!'
    }
    (edg < 0) && (edg = (edg % 360) + 360)
    const quadrant = (edg / 90) % 4 //旋转象限
    const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 } //裁剪坐标
    var image = new Image()
    image.crossOrigin = "anonymous"
    image.src = src
    image.onload = function () {
      imgW = image.width
      imgH = image.height
      size = imgW > imgH ? imgW : imgH
      canvas.width = size * 2
      canvas.height = size * 2
      switch (quadrant) {
        case 0:
          cutCoor.sx = size
          cutCoor.sy = size
          cutCoor.ex = size + imgW
          cutCoor.ey = size + imgH
          break
        case 1:
          cutCoor.sx = size - imgH
          cutCoor.sy = size
          cutCoor.ex = size
          cutCoor.ey = size + imgW
          break
        case 2:
          cutCoor.sx = size - imgW
          cutCoor.sy = size - imgH
          cutCoor.ex = size
          cutCoor.ey = size
          break
        case 3:
          cutCoor.sx = size
          cutCoor.sy = size - imgW
          cutCoor.ex = size + imgH
          cutCoor.ey = size + imgW
          break
      }
      ctx.translate(size, size)
      ctx.rotate(edg * Math.PI / 180)
      ctx.drawImage(image, 0, 0)
      var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey)
      if (quadrant % 2 == 0) {
        canvas.width = imgW
        canvas.height = imgH
      } else {
        canvas.width = imgH
        canvas.height = imgW
      }
      ctx.putImageData(imgData, 0, 0)

      //获取旋转后的base64图片
      This.setState({
        base64: canvas.toDataURL(),
      })
    }
  }
  //重签
  reSign = () => {
    this.setState({
      base64: '',
      signAddvisible: true,
    })
    setTimeout(() => {
      eval("PageReady()")
    }, 500)
  }

  // // 签字提交
  // subSign = async () => {
  //   const { base64 } = this.state
  //   if (base64 === '') {
  //     return Toast.info('请签字!')
  //   }
  //   const res = await saveQzInfo({
  //     lydh: this.props.match.params.id,
  //     qzurl: base64,
  //   })
  //   if (res.code === 20000) {
  //     Toast.info(res.message)
  //     this.setState({
  //       signVisible: false
  //     })
  //     this.fetch(this.props.match.params.id)
  //   }
  // }

  render() {
    const { data, visible, signVisible, base64, signAddvisible, width, height } = this.state
    return (
      <div className='approveDetail' style={{ padding: '0px 0px 76px' }}>
        <div className={data.lyzt > 2 && data.lyzt !== 8 ? 'gas_warehousing_record_head' : 'gas_warehousing_record_head gas_warehousing_record_head_no'}>
          <div className='gas_warehousing_record_left'>
            <div className='gas_warehousing_record_left_title'>
              {data.xymc}-{data.zxmc}-{data.jsmc}
            </div>
            <div className='approve_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='gas_warehousing_list_head_date'>
                {data.xymc}
              </div>
              <div className='gas_warehousing_list_head_seat'>气体种类{data.rkzls}种</div>
            </div>
          </div>
          <div className='gas_warehousing_record_right'>
          </div>
        </div>
        <div className='gas_warehousing_record_cont'>
          <div className='gas_warehousing_record_bar'>
            <div className='gas_warehousing_record_item'>
              <div>领用单号</div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {data.lybh}
                {/* <img src={require('./../../../../images/chemicals/05.png')} alt='' style={{ width: '16px', marginLeft: '4px' }} onClick={() => {
                  this.setState({
                    visible: true
                  })
                }} /> */}
              </div>
            </div>
            <div className='gas_warehousing_record_item'>
              <div>申请所属学院</div>
              <div>{data.xymc}</div>
            </div>
            <div className='gas_warehousing_record_item'>
              <div>所属中心</div>
              <div>{data.zxmc}</div>
            </div>
            <div className='gas_warehousing_record_item'>
              <div>所属实验室</div>
              <div>{data.jsmc}</div>
            </div>
          </div>


          <div className='gas_warehousing_record_bar'>
            <div className='gas_warehousing_record_item'>
              <div>第一领用人</div>
              <div>
                {data.lyrmc1}
              </div>
            </div>
            <div className='gas_warehousing_record_item'>
              <div>第二领用人</div>
              <div>
                {data.lyrmc2}
              </div>
            </div>
            <div className='gas_warehousing_record_item'>
              <div>领用用途</div>
              <div>
                {data.lyyt}
              </div>
            </div>
          </div>

          <div className='gas_warehousing_card'>
            {data.whpInfoEntityList && data.whpInfoEntityList.map((item, key) => {
              return (
                <div className='gas_warehousing_card_item' style={{ background: '#fff' }} key={key}>
                  <div className='gas_warehousing_card_head'>
                    <div className='gas_warehousing_card_head_left'>
                      <div>{key + 1}</div>
                      <div>{item.gas_name}</div>
                    </div>
                    <div className='gas_warehousing_card_class'>
                    </div>
                  </div>
                  <div className='gas_warehousing_card_unit'>
                    <div>{item.bottle_number}</div>
                    <div>共 {item.bottle_capacity}L</div>
                    <div>当前量 {item.gas_number}L</div>
                    <div>领用量 {item.lysl}L</div>
                  </div>
                  <div className='gas_manage_list_item_tag'>
                    {item.gas_type_name}
                  </div>


                </div>
              )
            })}


          </div>

          {/* <div className='approve_manager_order'>
            <div className='approve_manager_order_title'>
              签字记录
            </div>
            <div className='approve_manager_order_cont' style={{ padding: '1px 12px 16px 7px' }}>
              {
                data && data.lysqQzInfoEntities && data.lysqQzInfoEntities.map((item, key) => {
                  return (
                    <div className='approve_manager_order_item' key={key}>
                      <div className='approve_manager_order_item_left'>
                        <div className='approve_manager_order_item_icon'>
                          <img src={require('./../../../../images/chemicals/10.png')} alt='' />
                        </div>
                        <div className='approve_manager_order_item_line'>

                        </div>
                      </div>
                      <div className='approve_manager_order_item_right'>
                        <div className='approve_manager_order_item_name'>
                          <div>
                            {item.qzrxm}
                          </div>
                          <div>{item.qzsj ? moment(item.qzsj).format('YYYY-MM-DD HH:mm') : ''}</div>
                        </div>
                        <div style={{ marginTop: '12px' }}>
                          <img src={IP.fileHost + item.qzurl} alt='' style={{ height: '100px', border: '1px solid #d3d3d3' }} onClick={() => {
                            this.setState({
                              fileUrl: item.qzurl,
                              imgVisible: true,
                            })
                          }} />
                        </div>
                      </div>
                    </div>
                  )
                })
              }

            </div>
          </div> */}

        </div>
        {data.lyzt !== 6 ? <div className='approveDetail_btn'>
          {data.lyzt === 1 || data.lyzt === 2 || data.lyzt === 3 ? (<div className='approveDetail_btn_cancel' onClick={() => {
            this.handleCancel()
          }}>取消领用</div>) : ''}

          <div className='approveDetail_btn_list_ok' onClick={() => {
            this.props.history.push(`/module/gasCylinder/outboundAdd/${data.lybh}`)
          }}>领用出库</div>
          {/* {data.qzstatus >= 2 ? <div className='approveDetail_btn_list_ok' onClick={() => {
            this.props.history.push(`/module/outboundManager/add/${data.lybh}`)
          }}>领用出库</div> : ''}
          {data.qzstatus >= 2 ? <div className='approveDetail_btn_list_ok' onClick={() => {
            this.props.history.push(`/module/outboundManager/qrAdd/${data.lybh}`)
          }}>扫码出库</div> : ''}



          {data.qzstatus < 2 && data.qzstate === null ? <div className='approveDetail_btn_list' onClick={() => {
            this.setState({
              signVisible: true
            })
          }}>出库签字</div> : ''} */}
        </div> : ''}





        <Modal
          popup
          visible={signVisible}
          onClose={() => this.setState({ signVisible: false })}
          animationType="slide-up"
        >
          <div style={{ marginTop: '12px' }}>出库签字</div>

          <div className='my_modal_height'>
            {
              base64 ?
                <div>
                  <img src={base64} alt="" width={`92%`} />
                </div>
                : ''
            }

            <div className='my_modal_height_operate'>
              {base64 ? (<Button inline size="small" className="grey-text-button" onClick={this.reSign}>重签</Button>) : (<Button inline size="small" className='grey-text-button' onClick={this.goSign}>点击签字</Button>)}
            </div>


          </div>
          <Button className='my_modal_height_sub' type="primary" onClick={() => {
            this.subSign()
          }}>签字提交 </Button>
        </Modal>



        <Modal
          visible={signAddvisible}
          transparent
          onClose={() => this.setState({ signAddvisible: false })}
          style={{ width: '100%', height: '100%', background: '#fff' }}
        >
          <div>
            <section className="signature-component" >
              <div id='signature-pad' className="js-signature" data-width={width * 0.7} data-height={height * 0.8} data-border="1px solid black" data-line-color="#000" data-auto-fit="true"></div>
            </section>
            <div className='sign_btn' style={{ height: `${height * 0.2 - 60}px` }}>
              <div className='approveDetail_btn_list' style={{ width: `${height * 0.2 - 60}px` }} onClick={() => this.handleClearBtnClick()}>清除</div>
              <div className='approveDetail_btn_list' style={{ width: `${height * 0.2 - 60}px` }} onClick={() => this.handleOk()}>确认</div>
            </div>
          </div>
        </Modal>

        {
          this.state.imgVisible ? <div className='detailImg' onClick={() => this.setState({ imgVisible: false })}>
            <img alt='' src={IP.fileHost + this.state.fileUrl} style={{ width: '80%' }} />
          </div> : ''
        }
      </div>
    )
  }
}

export default deviceDetail