import React, { Component } from 'react'
import { Grid } from 'antd-mobile'

class UseDevice extends Component {

  state = {
    moduleData: [
      {
        module_id: 1,
        module_name: '仪器使用',
        module_image: require('../../../images/use_device/02.png'),
        module_url: '/module/useAdd'
      },
      {
        module_id: 2,
        module_name: '使用记录',
        module_image: require('../../../images/use_device/03.png'),
        module_url: '/module/useList'
      },
      // {
      //   module_id: 3,
      //   module_name: '建议反馈',
      //   module_image: require('../../../images/use_device/04.png'),
      //   module_url: '/module/useFeedback'
      // },
    ], // 模块
    role: 0
  }

  componentDidMount = () => {
    document.title = "仪器设备使用"
  }

  // 跳转模块
  handleSkipModule = item => {
    this.props.history.push(item.module_url)
  }

  render() {
    const { moduleData } = this.state
    return (
      <div className='patrol'>
        <div className='patrol_img'>
          <img alt='' src={require('../../../images/use_device/01.png')} />
        </div>
        <div className='patrol_con'>
          <Grid
            columnNum={3}
            data={moduleData}
            hasLine={false}
            renderItem={item => (
              <div className='patrol_con_list' onClick={() => this.handleSkipModule(item)}>
                <img src={item.module_image} alt="" style={{ width: '44px' }} />
                <span>{item.module_name}</span>
              </div>
            )}
          />
        </div>
      </div>
    )
  }
}

export default UseDevice
