import React, { Component } from 'react'
import { Statistic } from 'antd'
import { Toast, Modal } from 'antd-mobile'
import { startExam, saveAnswerRecord, findPaperConfigId } from '../../../api/api'
import './on_line.less'

const { Countdown } = Statistic;

const flagMap = {
  0: '单选题',
  1: '多选题',
  2: '判断题'
}

const questionNumber = [
  {id: 0, title: 'A'},
  {id: 1, title: 'B'},
  {id: 2, title: 'C'},
  {id: 3, title: 'D'},
  {id: 4, title: 'E'},
  {id: 5, title: 'F'},
  {id: 6, title: 'G'},
  {id: 7, title: 'H'},
  {id: 8, title: 'I'},
]

// 0：没有答题；1：已答
const isErrMap = {
  0: '',
  1: 'doneAnswer',
}

class OnLineSingle extends Component {
  state = {
    examData: {}, // 试卷信息
    recordId: '',
    allData: [],            //所有题
    choosedIndex: 1,        //选中的第几道题
    visible: false,     // 考试时间结束
    nowDate: Date.now(), // 当前时间
  }
  componentDidMount = () => {
    this.fetch()
    this.fetchExam(this.props.match.params.id)
  }
  fetch = async () => {
    Toast.info('加载中', 0)
    const paperId = this.props.match.params.id
    const res = await startExam({ paperId })
    if (res.code === 20000 && res.data) {
      Toast.hide()
      const arr = (res.data.danxuanze || []).concat(res.data.panduan || []).concat(res.data.duoxuanze || [])
      this.setState({
        allData: arr.map(x => {
          return ({
            ...x,
            "isAnswer": 0,
            "answer": [],
          })
        }),
        recordId: res.data.recordId,
      })
    }
  }
  // 查询考卷信息
  fetchExam = async paperId => {
    const res = await findPaperConfigId({ paperId })
    if (res.code === 20000) {
      this.setState({
        examData: res.data
      })
    }
  }

  //单选和判断题点击答题
  submitDanxuanPanduan = (data, evP) => {
    this.setState({
      allData: this.state.allData.map(x => {
        if (x.problem_id === data.problem_id) {
          x.isAnswer = 1
          x.answer = [evP]
          x.resultConfigEntities = (x.resultConfigEntities || []).map(t => {
            if (t.result_id === evP.result_id) {
              t.answer_title = 1
            } else {
              t.answer_title = null
            }
            return t
          })
        }
        return x
      })
    })
  }
  //多选点击答题
  submitDuoChoose = (data, p) => {
    const { allData } = this.state
    const newAllData = allData.map(x => {
      if (x.problem_id === data.problem_id) {
        if (x.answer.find(t => t.result_id === p.result_id)) {
          x.answer = x.answer.filter(y => y.result_id !== p.result_id)
        } else {
          x.answer = x.answer.concat([p])
        }
      }
      return x
    })
    this.setState({
      allData: newAllData.map(x => {
        if (x.problem_id === data.problem_id) {
          x.isAnswer = x.answer.length ? 1 : 0
          x.resultConfigEntities = (x.resultConfigEntities || []).map(t => {
            if (x.answer.find(y => y.result_id === t.result_id)) {
              t.answer_title = 1
            } else {
              t.answer_title = null
            }
            return t
          })
        }
        return x
      })
    })
  }
  //切换下一题
  nextData = () => {
    this.handleChooseData(this.state.choosedIndex)
  }
  //切换题
  handleChooseData = index => {
    if (index === this.state.allData.length) {
      Toast.info('已经是最后一题了')
      return false
    }
    this.setState({
      choosedIndex: index + 1,
    })
  }
  // 交卷
  handleSubmit = async () => {
    const { allData, recordId, visible } = this.state
    const newArr = allData.map(x => {
      return {
        problem_id: x.problem_id,
        result_ids: x.answer.map(t => t.result_id).sort(function (a, b) {
          return a - b;
        }).join(',')
      }
    })
    const res = await saveAnswerRecord({
      recordId,
      content: JSON.stringify(newArr)
    })
    if (res.code === 20000) {
      (!visible) && this.props.history.push(`/module/safty/exam/report/${this.props.match.params.id}/${recordId}`)
    }
  }
  // 考试时间结束自动交卷
  handleFinish = () => {
    console.log('考试时间结束自动交卷')
    this.setState({
      visible: true,
    }, () => {
      this.handleSubmit()
    })
  }

  // 关闭弹窗
  handleClose = () => {
    const { recordId } = this.state
    this.setState({
      visible: false
    })
    this.props.history.push(`/module/safty/exam/report/${this.props.match.params.id}/${recordId}`)
  }

  render() {
    const { examData, allData, choosedIndex, visible, nowDate } = this.state
    const chooseData = allData[choosedIndex - 1]

    return (
      <div className="on_line">
        <div className='on_line_header'>
          <div className='on_line_title'>
            <div className='on_line_title_bt'>{examData.paper_name}</div>
            <div className='on_line_title_djs'>
              <img alt='' src={require('../../../images/djs.png')} />
              {
                examData.paper_duration && (!visible) ? <Countdown value={nowDate + examData.paper_duration * 60 * 1000} onFinish={() => this.handleFinish()} /> : <span className='on_line_title_djs_time'>00:00:00</span>
              }
            </div>
          </div>
          <div className='on_line_card'>
            <div className='on_line_card_title'>
              <div className='card_title'>答题卡</div>
              <div className='card_num'>
                <span>{(allData || []).filter(x => x.isAnswer).length}</span><span>/{(allData || []).length}</span>
              </div>
            </div>
            <div className='on_line_card_con'>
              {
                (allData || []).map((item, index) => {
                  return <a className={`${item.problem_id === chooseData.problem_id ? 'checked' : ''} ${isErrMap[item.isAnswer]}`} key={item.problem_id} onClick={() => this.handleChooseData(index)}>{index + 1}</a>
                })
              }
            </div>
          </div>
        </div>
        {
          chooseData && JSON.stringify(chooseData) !== '{}' ? <div className="on_line_question">
            <div className='quest_title'>
              <span className='quest_title_lx'>{flagMap[chooseData.problem_flag]}</span>
              <span className='quest_title_name'>
                {choosedIndex}、{chooseData.problem_name}
              </span>
            </div>
            {(chooseData.problem_flag === 0 || chooseData.problem_flag === 2) && <div>
              <div className='all_answer'>
                {chooseData.resultConfigEntities && chooseData.resultConfigEntities.map((ev, i) =>
                  <div key={ev.result_id} className={`all_answer_list ${ev.answer_title === 1 ? 'answer_success' : ''}`} onClick={() => this.submitDanxuanPanduan(chooseData, ev)}>
                    {/* {ev.result_sort}.{ev.result_name} */}
                    {questionNumber[i].title}.{ev.result_name}
                  </div>
                )}
              </div>
            </div>
            }
            {(chooseData.problem_flag === 1) && <div className='all_answer'>
              {chooseData.resultConfigEntities && chooseData.resultConfigEntities.map((p, i) =>
                <div key={p.result_id} className={`all_answer_list ${p.answer_title === 1 ? 'answer_success' : ''}`} onClick={() => this.submitDuoChoose(chooseData, p)}>
                  {/* {p.result_sort}.{p.result_name} */}
                  {questionNumber[i].title}.{p.result_name}
                </div>
              )}
            </div>
            }
          </div> : ''
        }
        <div className='on_line_btn'>
          {
            choosedIndex < allData.length ? <div className='on_line_btn_next' onClick={() => this.nextData()}>下一题</div> : <div className='on_line_btn_practice' onClick={() => this.handleSubmit()}>交卷</div>
          }
        </div>
        <Modal
          className='modal_exam'
          maskClosable={false}
          transparent
          title={
            <div className='modal_title'>
              <img alt='' src={require('../../../images/warn.png')} />
              <span>提示</span>
            </div>
          }
          visible={visible}
        >
          <div className='modal_con'>
            <div className='modal_con_con'>考试时间结束，已为您自动交卷！</div>
            <div className='modal_con_btn'>
              <div className='modal_con_btn_list' onClick={() => this.handleClose()}>确定</div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default OnLineSingle