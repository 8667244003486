import React, { Component } from 'react'
import { SearchBar } from 'antd-mobile'
import { Cascader, Field } from 'react-vant'
import { findSpaceInfo, listAppSpace, findAllCurtain, sendCmdCurtain } from '../../../api/api'

class Curtain extends Component {

  state = {
    searchContent: '', // 搜索值
    buildId: undefined, // 楼宇id
    floorId: undefined, // 楼层id
    roomId: undefined, // 房间id
    curtainData: [], // 窗帘设备
    roomData: [], // 楼层下的房间
    wt: 265,
  }

  componentDidMount = () => {
    document.title = "智慧窗帘"
    this.fetch()
    this.fetchRoom()
    this.setState({
      wt: document.body.clientWidth - 110
    })
  }

  fetch = async () => {
    const { searchContent, buildId, floorId, roomId } = this.state
    const res = await findAllCurtain({ spaceId: roomId ? roomId : floorId ? floorId : buildId ? buildId : '', roomName: searchContent })
    if (res.code === 20000) {
      this.setState({
        curtainData: res.data
      })
    }
  }

  // 查询位置
  fetchRoom = async () => {
    const res = await findSpaceInfo()
    if (res.code === 20000) {
      let menuList = res.data.map(item => {
        return {
          text: item.dwmc,
          parentId: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
        }
      })
      menuList = menuList.map(item => {
        if (menuList.filter(p => p.parentId === item.value).length) {
          item.children = menuList.filter(p => p.parentId === item.value)
        }
        return item
      }).filter(p => p.parentId === '0')
      this.setState({
        roomData: menuList
      })
    }
  }

  // 搜索房间
  handleChange = e => {
    this.setState({
      searchContent: e
    }, () => {
      this.fetch()
    })
  }

  handleCancel = () => {
    this.setState({
      searchContent: ''
    }, () => {
      this.fetch()
    })
  }
  // 全部选项选择完成
  handleCascaderFinish = (val, selectedRows, actions) => {
    this.setState({
      roomId: val[val.length - 1],
    }, () => {
      this.fetch()
    })
  }

  // 窗帘操作
  handleSendCmd = async (devcieImei, cmdType) => {
    const res = await sendCmdCurtain({ devcieImei, cmdType })
    if (res.code === 20000) {
      this.fetch()
    }
  }



  render () {
    const { roomData, curtainData, wt, buildId, floorId, roomId } = this.state
    return (
      <div className='banpai'>
        <div className='banpai_img'>
          <img alt='' src={require('../../../img/controll/curtain-1.png')} />
          <div className='banpai_search'>
            <SearchBar placeholder="请输入房间信息" onCancel={() => this.handleCancel()} onChange={e => this.handleChange(e)} />
          </div>
        </div>
        <div className='banpai_title'>
          <span>设备列表</span>
          <div className='banpai_cascader'>
            <Cascader
              popup={{ round: true }}
              title='选择实验室'
              activeColor="#00B4C4"
              options={roomData}
              onFinish={this.handleCascaderFinish}
            >
              {(_, selectedRows, actions) => (
                <Field
                  isLink
                  value={selectedRows.length > 0 ? selectedRows[selectedRows.length - 1].text : ''}
                  readOnly
                  className='door_lock_cascader'
                  placeholder='全部房间'
                  onClick={() => actions.open()}
                />
              )}
            </Cascader>
          </div>
        </div>
        <div className='banpai_con'>
          {
            (curtainData || []).length ? curtainData.map(item => {
              return <div className='banpai_con_list' key={item.device_id} onClick={() => this.props.history.push(`/module/curtain/detail/${item.device_id}`)}>
                <div className='banpai_con_list_left' style={{ width: `${wt}px` }}>
                  <div className='banpai_con_list_left_name'>{item.device_name}</div>
                  <div className='banpai_con_list_left_addr'>
                    {item.device_building_name}/{item.device_floor_name}/{item.device_room_name}
                  </div>
                  <div className='banpai_con_list_left_desc'>
                    <div className={`banpai_con_list_left_desc_status ${item.device_state === 1 ? 'banpai_status_online' : 'banpai_status_offline'}`}>
                      <span></span>
                      <span>{item.device_state === 1 ? '设备在线' : '设备离线'}</span>
                    </div>
                  </div>
                </div>
              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
      </div>
    )
  }
}

export default Curtain