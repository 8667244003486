import React, { Component } from 'react'
import { findMobileSafetyInfo } from '../../api/api'
import moment from 'moment'

class RiskReport extends Component {

  state = {
    statusData: [
      { id: 1, name: '待审核', checked: true },
      { id: 2, name: '审核成功', checked: false },
      { id: 3, name: '审核失败', checked: false },
    ],
    type: 1, // 审批状态
    data: [],
  }

  componentDidMount = () => {
    this.sbFetch()
  }

  sbFetch = async () => {
    const { type } = this.state
    const res = await findMobileSafetyInfo({ state: type })
    if (res.code === 20000) {
      this.setState({
        data: res.data || []
      })
    }
  }

  // 切换状态
  handleStatus = item => {
    const { statusData } = this.state
    this.setState({
      statusData: statusData.map(x => {
        x.checked = x.id === item.id ? true : false
        return x
      })
    })
    this.fetch(item.id)
  }

  render() {
    const { statusData, data, type } = this.state
    return (
      <div className='approve'>
        <div className='approve_header riskReport'>
          <div className='approve_header_config'>
            <div className='approve_header_con'>
              {
                statusData.map(item => {
                  return <div className={`approve_header_con_list ${type === item.id ? 'approve_header_con_list_active' : ''}`} key={item.id} onClick={() => {
                    this.setState({
                      type: item.id
                    }, () => {
                      this.sbFetch()
                    })
                  }}>{item.name}</div>
                })
              }

            </div>
          </div>
        </div>
        <div className='approve_con riskReport_con'>
          {
            (data || []).length ? data.map((item, key) => {
              return <div className='approve_risk_list' key={key} onClick={() => this.props.history.push(`/my/riskReport/detail/${item.safety_id}`)}>
                <div className='approve_risk'>
                  <div className='approve_risk_head'>
                    <div>{item.create_time ? moment(item.create_time).format('YYYY-MM-DD HH:mm') : moment(new Date()).format('YYYY-MM-DD HH:mm')}</div>
                    <div>{item.fzr}</div>
                  </div>
                  <div className='approve_risk_room'>
                    {item.room_name}
                  </div>
                  <div className='approve_risk_card'>
                    <div>{item.grade_name}</div>
                    <div>{item.type_name}</div>
                  </div>
                  <div className='approve_risk_desc'>
                    <div className='approve_risk_desc_item'>
                      <div>防护品</div>
                      <div>{item.productsName}</div>
                    </div>
                    <div className='approve_risk_desc_item'>
                      <div>危险源</div>
                      <div>{item.hazardName}</div>
                    </div>
                  </div>

                </div>

              </div>
            }) : ('')
          }
          {/* {
            data.map((item, key) => {
              return <div className='approve_con_list' key={key} onClick={() => this.props.history.push(`/my/riskReport/detail/${item.safety_id}`)}>
                <div className='approve_con_list_title'>
                  <div className='approve_con_list_title_name'>{item.mc}</div>
                </div>
                <div className='approve_con_list_desc'>
                  <div className='approve_con_list_desc_list'>
                    责任人：{item.fzr}
                  </div>
                  <div className='approve_con_list_desc_list' style={{ color: item.grade_colour, border: '1px solid' + item.grade_colour, opacity: '0.6' }}>
                    {item.grade_name}
                  </div>
                </div>
                <div className='approve_con_list_con' style={{ display: 'block' }}>
                  <div className='approve_con_list_con_item' style={{ width: 'auto', alignItems: 'unset' }}>
                    <span>危险分类</span>
                    <span style={{ flex: '1 1' }}>{item.typeName}</span>
                  </div>
                  <div className='approve_con_list_con_item' style={{ width: 'auto', alignItems: 'unset' }}>
                    <span>危&nbsp;&nbsp;险&nbsp;&nbsp;源</span>
                    <span style={{ flex: '1 1' }}>{item.hazardName}</span>
                  </div>
                  <div className='approve_con_list_con_item' style={{ width: 'auto', alignItems: 'unset' }}>
                    <span>防&nbsp;&nbsp;护&nbsp;&nbsp;品</span>
                    <span style={{ flex: '1 1' }}>{item.productsName}</span>
                  </div>
                </div>
                <div className='approve_con_list_time'>
                  提交时间：{item.create_time ? moment(item.create_time).format('YYYY/MM/DD HH:mm') : moment(new Date()).format('YYYY/MM/DD HH:mm')}
                </div>
              </div>
            })
          } */}
          {
            data.length === 0 ? (<div className='not-yet'>--暂无数据--</div>) : ('')
          }
        </div>
      </div>
    )
  }
}

export default RiskReport