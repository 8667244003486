import React, { Component } from 'react'
import { Toast, Modal, List, Picker } from 'antd-mobile'
import { req_findUserFunction, req_appFindOrderByOrderStatus, req_cancelOrder, req_refuseOrder, req_acceptOrder, req_findRepairUser, req_sendOrder, req_transferOrder, req_endOrder, req_finishOrder } from '../../../api/api'
import './index.less'
import cookie from 'react-cookies'
import moment from 'moment'
const alert = Modal.alert;
const prompt = Modal.prompt;

class Home extends Component {
  state = {
    tabs_id: null,
    // 0待接单 1已接单,维修中 2已完工,待评价 3已评价 4已拒单 5 已终止 6 已取消 7 转派 8 派单
    tabsData: [
      {
        label: '全部',
        value: null,
      },
      {
        label: '待接单',
        value: 0,
      },
      {
        label: '已接单',
        value: 1,
      },
      {
        label: '已完工',
        value: 2,
      },
      {
        label: '已评价',
        value: 3,
      },
      {
        label: '已拒单',
        value: 4,
      },
      {
        label: '已终止',
        value: 5,
      },
      {
        label: '已取消',
        value: 6,
      },
      {
        label: '转派',
        value: 7,
      },
      {
        label: '派单',
        value: 8,
      }
    ],
    funcData: [], // 
    tabs_type_id: 0,
    tabsTypeData: [],
    orderData: [], // 工单
    sendVisible: false, //派单弹窗状态
    transferVisible: false, //转派弹窗状态
    orderId: '', // 工单ID
    repairUserData: [], //维修工Data
    sendId: [],  //被派单人ID
    transferId: [], //被转单人ID
    repairVisible: false,
  }

  componentDidMount = async () => {
    await this.findUserFunction()
    await this.findOrderList(null)
  }


  // 获取用户按钮权限
  findUserFunction = async () => {
    const { tabsData } = this.state
    const res = await req_findUserFunction()
    if (res.code === 20000) {
      // 判断转派功能
      let data = tabsData;
      if (res.data.filter(x => x === 6).length === 0) {
        data = data.filter(x => x.value !== 7)
      }
      if (res.data.filter(x => x === 7).length === 0) {
        data = data.filter(x => x.value !== 8)
      }
      this.setState({
        tabsData: data,
        funcData: res.data,
        repairVisible: res.data.find(x => x === 1) ? true : false
      })
    }
  }

  // 获取工单数据
  findOrderList = async (orderStatus) => {
    const res = await req_appFindOrderByOrderStatus({ orderStatus: orderStatus !== 'null' ? orderStatus : '' })
    if (res.code === 20000) {
      const data = [
        res.data && res.data.baoxiu ? res.data.baoxiu : [],
        res.data && res.data.weixiu ? res.data.weixiu : [],
        res.data && res.data.xiangguan ? res.data.xiangguan : []
      ]
      let tabsTypeData = []

      if (orderStatus === 'null' || Number(orderStatus) === 0) {
        tabsTypeData = [
          {
            label: '我报修的',
            value: 0
          },
          {
            label: '我维修的',
            value: 1
          },
          {
            label: '与我相关',
            value: 2
          }
        ]
      } else if (Number(orderStatus) === 6 || Number(orderStatus) === 7 || Number(orderStatus) === 8) {
        tabsTypeData = []
        if (Number(orderStatus) === 6) {
          this.setState({ tabs_type_id: 0 })
        } else {
          this.setState({ tabs_type_id: 1 })
        }
      } else {
        tabsTypeData = [
          {
            label: '我报修的',
            value: 0
          },
          {
            label: '我维修的',
            value: 1
          }
        ]
      }
      this.setState({
        orderData: data,
        tabsTypeData
      })
    }
  }

  // 取消工单
  orderCancel = async (orderId) => {
    const { tabs_id } = this.state
    alert('提示', '您确定该取消工单吗?', [
      {
        text: '取消', onPress: () => {
        }
      },
      {
        text: '确定', onPress: async () => {
          const res = await req_cancelOrder({ orderId })
          if (res.code === 20000) {
            Toast.info('取消工单成功')
            this.findOrderList(tabs_id)
          }
        }
      },
    ])
  }


  // 拒单
  orderRefuse = async (orderId) => {
    const { tabs_id } = this.state
    prompt('提示', '您确定拒绝该工单吗？',
      [
        {
          text: '取消',
          onPress: () => new Promise((resolve) => {
            resolve();
          }),
        },
        {
          text: '确定',
          onPress: value => new Promise(async (resolve, reject) => {
            if (!value) {
              Toast.info("请输入拒单原因")
              return;
            }
            const res = await req_refuseOrder({ orderId, reason: value })
            if (res.code === 20000) {
              Toast.hide('取消工单成功')
              this.findOrderList(tabs_id)
              resolve();
            }
          }),
        },
      ], 'default', null, ['请输入拒单原因'])
  }

  // 接单
  acceptOrder = async (orderId) => {
    const { tabs_id } = this.state
    alert('提示', '您确定接取该工单吗?', [
      {
        text: '取消', onPress: () => {
        }
      },
      {
        text: '确定', onPress: async () => {
          const res = await req_acceptOrder({ orderId })
          if (res.code === 20000) {
            Toast.info('接单成功')
            this.findOrderList(tabs_id)
          }
        }
      },
    ])
  }

  // 根据工单id查询维修人员信息
  findRepairUser = async (orderId) => {
    const res = await req_findRepairUser({ orderId })
    if (res.code === 20000) {
      if (res.data) {
        const data = res.data.map(val => {
          return {
            label: val.xm,
            value: val.zh
          }
        })
        this.setState({
          repairUserData: data
        })
      }

    }
  }


  // 派单
  orderSend = async () => {
    const { tabs_id, orderId, sendId, repairUserData } = this.state
    if (!sendId) {
      Toast.info("请选择维修工姓名")
      return
    }
    const params = {
      orderId,
      sendId: sendId.join(','),
      sendName: repairUserData.find(x => x.value === String(sendId.join(','))).label
    }
    const res = await req_sendOrder(params)
    if (res.code === 20000) {
      Toast.info('派单成功')
      this.findOrderList(tabs_id)
      this.setState({
        sendVisible: false,
        sendId: []
      })
    }
  }

  //转派
  transferSend = async () => {
    const { tabs_id, orderId, transferId, repairUserData } = this.state
    if (!transferId) {
      Toast.info("请选择维修工姓名")
      return
    }
    const params = {
      orderId,
      transferId: transferId.join(','),
      transferName: repairUserData.find(x => x.value === String(transferId.join(','))).label
    }
    const res = await req_transferOrder(params)
    if (res.code === 20000) {
      Toast.info('转派成功')
      this.findOrderList(tabs_id)
      this.setState({
        transferVisible: false,
        transferId: []
      })
    }
  }

  // 终止
  endOrder = async (orderId) => {
    const { tabs_id } = this.state
    prompt('提示', '您确定终止该工单吗？',
      [
        {
          text: '取消',
          onPress: () => new Promise((resolve) => {
            resolve();
          }),
        },
        {
          text: '确定',
          onPress: value => new Promise(async (resolve, reject) => {
            if (!value) {
              Toast.info("请输入终止原因")
              return;
            }
            const res = await req_endOrder({ orderId, reason: value })
            if (res.code === 20000) {
              Toast.hide('终止工单成功')
              this.findOrderList(tabs_id)
              resolve();
            }
          }),
        },
      ], 'default', null, ['请输入终止原因'])
  }

  // 完工
  finishOrder = async (orderId) => {
    const { tabs_id } = this.state
    alert('提示', '您确定完工该工单吗?', [
      {
        text: '取消', onPress: () => {
        }
      },
      {
        text: '确定',
        onPress: value => new Promise(async (resolve, reject) => {
          const res = await req_finishOrder({ orderId })
          if (res.code === 20000) {
            Toast.info('完工成功')
            this.findOrderList(tabs_id)
            resolve();
          }
        })
      },
    ])
  }

  render() {
    const { tabs_id, tabsData, tabs_type_id, tabsTypeData, orderData, funcData, sendVisible,
      transferVisible, repairUserData, sendId, transferId, repairVisible } = this.state
    return (
      <div className='order'>
        <div className='order-position'>
          <div className='order-tabs'>
            <div className='order-tabs-width'>
              {
                tabsData.map(val => {
                  return (
                    <div className='order-tabs-item' key={val.value} onClick={() => {
                      this.findOrderList(val.value)
                      this.setState({
                        tabs_id: val.value,
                        tabs_type_id: 0,
                      })
                    }}>
                      <div />
                      <div className={`order-tabs-name ${String(val.value) === String(tabs_id) ? 'order-tabs-name-active' : ''}`}>{val.label}</div>
                      <div className={`order-tabs-line ${String(val.value) === String(tabs_id) ? 'order-tabs-line-active' : ''}`}></div>
                    </div>
                  )
                })
              }
            </div>
          </div>

          {
            (tabsTypeData || []).length ? <div className='order-tabs-class'>
              {
                tabsTypeData.map(val => {
                  return <div className={val.value === tabs_type_id ? 'order-class-click' : ''} key={val.value} onClick={() => {
                    this.setState({
                      tabs_type_id: val.value
                    })
                  }}>{val.label}</div>
                })
              }
            </div> : ''
          }
          <div className='order-total'>
            共计{orderData[tabs_type_id] ? orderData[tabs_type_id].length : 0}条工单
          </div>
        </div>
        <div className='order-list' style={{ paddingTop: `${(tabsTypeData || []).length ? 148 : 100}px`, paddingBottom: `${repairVisible ? 83 : 12}px` }}>
          {
            orderData[tabs_type_id] && orderData[tabs_type_id].map(val => {
              return (<div className='order-list-item' key={val.order_id} onClick={() => {
                this.props.history.push(`/module/order/details/${val.order_id}/${tabs_id}/${tabs_type_id}`)
              }}>
                <div className='order-list-header'>
                  <div className='order-list-header-time'>{val.create_time ? moment(val.create_time).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm')}</div>
                  <div className='order-list-header-name'>{val.bx_name}</div>
                </div>
                <div className='order-list-addr'>{val.area_name}-{val.room}</div>
                <div className='order-list-type'>{val.fix_type_name}</div>
                <div className='order-list-d'>
                  <div className='order-list-d-item'>
                    <div className='order-list-d-item-title'>预约时间</div>
                    <div className='order-list-d-item-con'>
                    {val.advance_start_time ? moment(val.advance_start_time).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm')}至{val.advance_finish_time ? moment(val.advance_finish_time).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm')}
                    </div>
                  </div>
                  <div className='order-list-d-item'>
                    <div className='order-list-d-item-title'>问题描述</div>
                    <div className='order-list-d-item-con'>{val.order_desc}</div>
                  </div>
                </div>
                <div className='order-list-but-width'>
                  {/* 
                  * 待接单页面：取消，接单，拒接，派单（自己报修的不能自己拒接，也不能自己接）
                  * 已接单页面：完工，转派，终止（只有接单人拥有相应权限才可以进行这些操作）
                  * 待评价页面：评价 
                */}
                  {/* 判断 */}
                  {
                    val.order_status === 0 ? <div className='order-list-but'>
                      {
                        cookie.load('userData').id === val.bx_id && tabs_type_id === 0 ? (<div className='order-reject' onClick={(e) => {
                          e.stopPropagation();
                          this.orderCancel(val.order_id)
                        }}>取消</div>) : ''
                      }

                      {
                        funcData.find(x => x === 4) ? (<div className='order-reject' onClick={(e) => {
                          e.stopPropagation();
                          this.orderRefuse(val.order_id)
                        }}>拒单</div>) : ''
                      }

                      {
                        funcData.find(x => x === 7) && val.send_status === 0 ? (<div className='order-send' onClick={(e) => {
                          e.stopPropagation();

                          this.findRepairUser(val.order_id)
                          this.setState({
                            sendVisible: true,
                            orderId: val.order_id
                          })
                        }}>派单</div>) : ''
                      }

                      {
                        funcData.find(x => x === 2) && tabs_type_id === 1 ? (<div className='order-take' onClick={(e) => {
                          e.stopPropagation();
                          this.acceptOrder(val.order_id)
                        }}>接单</div>) : ''
                      }
                    </div> : ''
                  }

                  {
                    val.order_status === 1 && tabs_type_id === 1 ? <div className='order-list-but'>
                      {
                        funcData.find(x => x === 5) ? (<div className='order-reject' onClick={(e) => {
                          e.stopPropagation();
                          this.endOrder(val.order_id)
                        }}>终止</div>) : ''
                      }

                      {
                        funcData.find(x => x === 6) && val.transfer_status === 0 && tabs_type_id === 1 ? (<div className='order-send' onClick={(e) => {
                          e.stopPropagation();
                          this.findRepairUser(val.order_id)
                          this.setState({
                            transferVisible: true,
                            orderId: val.order_id
                          })
                        }}>转派</div>) : ''
                      }

                      {
                        cookie.load('userData').id === val.worker_id && tabs_type_id === 1 ? (<div className='order-take' onClick={(e) => {
                          e.stopPropagation();
                          this.finishOrder(val.order_id)
                        }}>完工</div>) : ''
                      }
                    </div> : ''
                  }

                  {
                    val.order_status === 2 && tabs_type_id === 0 ? <div className='order-list-but'>
                      {
                        funcData.find(x => x === 3) ? (<div className='order-take' onClick={(e) => {
                          e.stopPropagation();
                          this.props.history.push(`/module/order/evaluate/${val.order_id}/${tabs_id}/${tabs_type_id}`)
                        }}>评价</div>) : ''
                      }
                    </div> : ''
                  }
                </div>
              </div>)
            })
          }
        </div>
        {
          repairVisible ? <div className='repair-btn' onClick={() => this.props.history.push('/module/repair')}>
            <div className='repair-btn-list'>我要报修</div>
          </div> : ''
        }
        {/* 派单 */}
        <Modal
          visible={sendVisible}
          transparent
          className='order-modal'
          maskClosable={false}
          onClose={() => {
            this.setState({
              sendVisible: false
            })
          }}
        >
          <div className='order-modal-title'>请选择派单的维修工</div>
          <div className='order-modal-picker'>
            <Picker
              data={repairUserData}
              cols={1}
              value={sendId}
              onChange={(val) => {
                this.setState({
                  sendId: val,
                })
              }}
            >
              <List.Item arrow="horizontal">维修工姓名</List.Item>
            </Picker>
          </div>
          <div className='order-modal-foot'>
            <div onClick={() => {
              this.setState({
                sendVisible: false
              }, () => {
                this.setState({
                  sendId: [],
                })
              })
            }}>取消</div>
            <div onClick={() => {
              this.orderSend()
            }}>确定</div>
          </div>
        </Modal>

        {/* 转派 */}
        <Modal
          visible={transferVisible}
          transparent
          className='order-modal'
          maskClosable={false}
          onClose={() => {
            this.setState({
              transferVisible: false
            })
          }}
        >
          <div className='order-modal-title'>请选择转派的维修工</div>
          <div className='order-modal-picker'>
            <Picker
              data={repairUserData}
              cols={1}
              value={transferId}
              onChange={(val) => {
                this.setState({
                  transferId: val,
                })
              }}
            >
              <List.Item arrow="horizontal">维修工姓名</List.Item>
            </Picker>
          </div>
          <div className='order-modal-foot'>
            <div onClick={() => {
              this.setState({
                transferVisible: false
              }, () => {
                this.setState({
                  transferId: [],
                })
              })
            }}>取消</div>
            <div onClick={() => {
              this.transferSend()
            }}>确定</div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default Home
