import React, { Component } from 'react'
import { TreeSelect, Select } from 'antd'
import { Picker, List, InputItem, TextareaItem, Modal, Icon, Toast } from 'antd-mobile';
import { listDict, req_findDepartmentInfo, findZxOrRoomByDwOrZx, findPageWhpInfo, saveSgInfo, findMasterProcess, findNextApprovalUser, uploadSgFile } from './../../../api/whp'
import IP from './../../../config/config'

const { TreeNode } = TreeSelect;

class Add extends Component {
  state = {
    data: [
      {
        label: '2013年',
        value: '2013',
      },
      {
        label: '2014年',
        value: '2014',
      },
    ],
    slideId: '',
    startX: '',
    visible: false,
    tab_id: '',
    sqfxData: [],//方向
    xydm: undefined,//所属学院
    zxid: undefined,//所属中心
    jsid: undefined,//所属实验室
    jfly: undefined,//经费来源
    jfh: '',//经费号
    sgfxdm: '',//申请方向
    sgrdh: '',
    sgyt: '',
    sgbz: '',
    typeData: [],//类别
    whpFlbh: '',//化学品类别
    chemicalData: [],//化学品
    depTreeData: [],
    depData: [],
    centerData: [],//中心
    roomData: [],//房间
    masterProcess: 0, // 是否多人同时审批
    approveVisible: false,
    spry: undefined,
    jflyData: [],
    fileInfo: []
  }

  componentDidMount = () => {
    document.title = '建议反馈'
    window.scrollTo(0, 0)
    this.fetchSqfx()
    this.fetchJfly()
    this.fetchDep()
    this.fetchType()
    this.findPageWhpInfo()
  }


  // 经费来源
  fetchJfly = async () => {
    const res = await listDict({ ZDLX: 'jfly' })
    if (res.code === 20000) {
      const data = res.data.map(item => {
        return {
          label: item.zdmc,
          value: item.zdbh,
        }
      })
      this.setState({
        jflyData: data
      })
    }
  }


  // 查询申请方向
  fetchSqfx = async () => {
    const res = await listDict({ ZDLX: 'sqfx' })
    if (res.code === 20000) {
      const data = res.data.map(item => {
        return {
          label: item.zdmc,
          value: item.zdbh,
        }
      })
      this.setState({
        sqfxData: data
      })
    }
  }

  // 查询学院/部门
  fetchDep = async () => {
    const res = await req_findDepartmentInfo({ dwlx: 1 })
    if (res.code === 20000) {
      let menuList = res.data
      menuList = menuList.map(a => {
        return {
          title: a.dwmc,
          key: a.dwdm,
          parentId: a.sj_dm,
          value: a.dwdm,
          disabled: a.sj_dm === '0' ? true : false
        }
      })
      menuList = menuList.map(m => {
        m.children = menuList.filter(p => p.parentId === m.key)
        return m
      }).filter(x => !res.data.find(v => v.dwdm === x.parentId))
      this.setState({
        depTreeData: menuList,
        depData: res.data,
      })
    }
  }

  // 选择部门/学院
  handleChangeDep = async e => {
    const res = await findZxOrRoomByDwOrZx({ flag: 1, param: e })
    if (res.code === 20000) {
      this.setState({
        centerData: res.data.map(item => {
          return {
            label: item.room_name,
            value: item.room_id,
          }
        })
      })
    }
  }

  // 选择中心
  handleChangeCenter = async e => {
    const res = await findZxOrRoomByDwOrZx({ flag: 2, param: e.join(',') })
    if (res.code === 20000) {
      this.setState({
        roomData: res.data.map(item => {
          return {
            label: item.room_name,
            value: item.room_id,
          }
        })
      })
    }
  }

  // 查询类别
  fetchType = async () => {
    const res = await listDict({ ZDLX: 'lb' })
    if (res.code === 20000) {
      const data = res.data.map(item => {
        return {
          label: item.zdmc,
          value: item.zdbh,
        }
      })
      this.setState({
        typeData: data
      })
    }
  }

  // 化学品
  findPageWhpInfo = async () => {
    const res = await findPageWhpInfo({ page: 1, size: 999999, whpZt: 1 })
    if (res.code === 20000) {
      this.setState({
        chemicalData: res.data.info.list.map(item => {
          item.sgwhpsl = 0
          return item
        })
      })
    }
  }

  // 求和
  sum(arr) {
    var sum = 0;
    for (var i = 0; i < arr.length; i++) {
      sum += arr[i];
    }
    return sum;
  }

  // 危化品总数
  whpNum = () => {
    const { chemicalData } = this.state
    return this.sum(chemicalData.map(x => x.sgwhpsl))
  }

  // 判断审批是多人共同审批还是单人审批
  handleMasterProcess = async () => {
    const { sgfxdm, sgrdh, sgyt, chemicalData, xydm, zxid, jsid, jfly, jfh } = this.state
    if (sgfxdm.length === 0 || sgrdh === '' || sgyt === '' || xydm === undefined || zxid === undefined || jsid === undefined || jfly === undefined || jfh === '') {
      Toast.info('请补全上述内容！')
      return false
    }
    if (chemicalData.filter(x => x.sgwhpsl !== 0).length === 0) {
      Toast.info('请添加危化品！')
      return false
    }
    const res = await findMasterProcess({ flag: 1 })
    if (res.code === 20000) {
      this.setState({
        masterProcess: res.data
      }, () => {
        this.handleNextApprovalUser(jsid.join(','))
      })
    }
  }

  // 获取下一个审批人员信息
  handleNextApprovalUser = async roomId => {
    const { masterProcess } = this.state
    const res = await findNextApprovalUser({ flag: 1, roomId })
    if (res.code === 20000) {
      this.setState({
        approveUser: res.data,
        approveVisible: true,
      }, () => {
        if (masterProcess) {
          this.setState({
            spry: (res.data || []).map(x => x.gh)
          })
        }
      })
    }
  }

  // 提交
  submit = async () => {
    const { spry, sgfxdm, sqfxData, sgrdh, sgyt, sgbz, chemicalData, xydm, zxid, jsid, depData, centerData, roomData, masterProcess, approveUser, jfly, jfh, jflyData, fileInfo } = this.state
    if (spry === undefined || spry === '') {
      Toast.info('请选择审批人员！')
      return false;
    }
    if (sgfxdm.length === 0 || sgrdh === '' || sgyt === '' || xydm === undefined || zxid === undefined || jsid === undefined || jfly === undefined || jfh === '') {
      Toast.info('请补全上述内容！')
      return false
    }
    if (chemicalData.filter(x => x.sgwhpsl !== 0).length === 0) {
      Toast.info('请添加危化品！')
      return false
    }

    const sgFileEntities = fileInfo.map(item => {
      return {
        file_name: item.file_name,
        file_url: item.file_url.url,
      }
    })

    const params = {
      xydm: xydm,
      zxid: zxid.join(','),
      jsid: jsid.join(','),
      xymc: depData.find(x => x.dwdm === xydm).dwmc,
      zxmc: centerData.find(x => x.value === zxid.join(',')).label,
      jsmc: roomData.find(x => x.value === jsid.join(',')).label,

      sgfxdm: sgfxdm.join(','),
      sgfx: sqfxData.find(x => x.value === sgfxdm.join(',')).label,

      jfly: jfly.join(','),
      jflydm: jflyData.find(x => x.value === jfly.join(',')).label,
      jfh,
      sgzt: 1,
      sgrdh,
      sgyt,
      sgbz,
      sgWhpInfoEntities: chemicalData.filter(x => x.sgwhpsl !== 0),
      spuserVos: masterProcess ? approveUser : approveUser.filter(x => x.gh === spry),
      sgFileEntities
    }
    const res = await saveSgInfo(params)
    if (res.code === 20000) {
      Toast.info("提交成功！")
      setTimeout(() => {
        this.props.history.goBack()
      }, 2000);
    }
  }

  // 上传文件
  onChangeFile = async (e) => {
    const { fileInfo } = this.state
    const { files } = e.target
    Toast.loading("附件上传中,请稍等", 0)
    let formData = new FormData()
    formData.append("file", files[files.length - 1])
    const res = await uploadSgFile(formData)
    if (res.code === 20000) {
      Toast.hide()
      Toast.info("上传成功", 1)
      fileInfo.push({ file_name: files[files.length - 1].name, file_url: res.data })
      this.setState({ fileInfo })
    } else {
      Toast.hide()
      Toast.fail(res.message)
    }
  }

  render() {
    const { slideId, visible, tab_id, sqfxData, sgfxdm, sgrdh, sgyt, sgbz, typeData, chemicalData, depTreeData, xydm, centerData, zxid, roomData, jsid, approveVisible, masterProcess, approveUser, spry, fileInfo } = this.state
    const loop = data =>
      data.map(item => {
        if (item.children && item.children.length) {
          return (
            <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled} />;
      });
    return (
      <div className='subscription_add'>
        <div className='subscription_add_title'>
        </div>


        <div className='subscription_add_form'>
          <div className='subscription_add_input'>
            <InputItem placeholder="请输入" value={sgrdh} onChange={(e) => {
              this.setState({
                sgrdh: e
              })
            }}><span style={{ color: 'red' }}>* </span>建议标题</InputItem>
          </div>
        </div>
        <div className='subscription_add_form'>
          <div className='subscription_add_text'>
            <div><span style={{ color: 'white' }}>* </span>建议描述</div>
            <TextareaItem
              placeholder="请输入"
              rows={5}
              value={sgbz}
              onChange={(e) => {
                this.setState({
                  sgbz: e
                })
              }}
            />
          </div>
        </div>

        <div className='subscription_list_but'>
          <div onClick={() => {
            this.handleMasterProcess()
          }}>提交</div>
        </div>

      </div>
    )
  }
}

export default Add
