import React, { Component } from 'react'
import { TextareaItem } from 'antd-mobile'
import { findSafetyInfoByID } from '../../api/api'
import moment from 'moment'

class RiskReportDetail extends Component {

  state = {
    data: {},
  }

  componentDidMount = () => {
    this.sbfetch(this.props.match.params.id)
  }

  sbfetch = async safetyId => {
    const res = await findSafetyInfoByID({ safetyId })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className='approveDetail' style={{ padding: '0px' }}>
        <div className='approve_detail_head'>
          <div className='approve_detail_left'>
            <div className='approve_detail_left_title'>
              {data.room_name}
            </div>
            <div className='approve_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='approve_risk_card' style={{ marginLeft: '0px' }}>
                <div>{data.grade_name}</div>
                <div>{data.type_name}</div>
              </div>
            </div>
          </div>
          <div className='approve_detail_right'>
          </div>
        </div>
        <div className='approve_detail_cont'>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>房间号</div>
              <div>{data.room_number}</div>
            </div>
            <div className='approve_detail_item'>
              <div>实验室名称</div>
              <div>{data.room_name}</div>
            </div>
            <div className='approve_detail_item'>
              <div>危险等级</div>
              <div>{data.grade_name}</div>
            </div>
            <div className='approve_detail_item'>
              <div>危险分类</div>
              <div>{data.type_name}</div>
            </div>
            <div className='approve_detail_item_more'>
              <div>防护品</div>
              <div>{data.productsName}</div>
            </div>
            <div className='approve_detail_item_more'>
              <div>危险源</div>
              <div>{data.hazardName}</div>
            </div>
          </div>

          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>责任人</div>
              <div>{data.fzr}</div>
            </div>
            <div className='approve_detail_item'>
              <div>提交时间</div>
              <div>{
                data.create_time ? moment(data.create_time).format('YYYY/MM/DD HH:mm') : moment(new Date()).format('YYYY/MM/DD HH:mm')
              }</div>
            </div>
            {data.pr_approval_time ? (
              <div className='approve_detail_item'>
                <div>审批时间</div>
                <div>{moment(data.pr_approval_time).format('YYYY/MM/DD HH:mm')}</div>
              </div>
            ) : ('')}
            {
              data.pr_approval_result === 2 ? (<div className='approve_detail_item'>
                <div>审批操作</div>
                <div>
                  审核成功
                </div>
              </div>) : ('')
            }
            {
              data.pr_approval_result === 3 ? (<div className='approve_detail_item'>
                <div>审批操作</div>
                <div>
                  审核驳回
                </div>
              </div>) : ('')
            }
            {
              data.pr_approval_result === 3 ? (<div className='approve_detail_item'>
                <div>驳回原因</div>
                <div>
                  <TextareaItem
                    rows={5}
                    placeholder=""
                    value={data.pr_approval_opinion}
                  />
                </div>
              </div>) : ('')
            }
          </div>
        </div>

        {/* <div className='approveDetail_con'>
          <div className='approveDetail_detail'>
            <div className='approveDetail_detail_list'>
              <div className='approveDetail_detail_list_name'>编号</div>
              <div className='approveDetail_detail_list_con'>{data.room_bh}</div>
            </div>
            <div className='approveDetail_detail_list'>
              <div className='approveDetail_detail_list_name'>实验室名称</div>
              <div className='approveDetail_detail_list_con'>{data.room_name}</div>
            </div>
            <div className='approveDetail_detail_list'>
              <div className='approveDetail_detail_list_name'>危险等级</div>
              <div className='approveDetail_detail_list_con'>{data.grade_name}</div>
            </div>
            <div className='approveDetail_detail_list'>
              <div className='approveDetail_detail_list_name'>危险源</div>
              <div className='approveDetail_detail_list_con'>{data.hazardName}</div>
            </div>
            <div className='approveDetail_detail_list'>
              <div className='approveDetail_detail_list_name'>危险分类</div>
              <div className='approveDetail_detail_list_con'>{data.typeName}</div>
            </div>
            <div className='approveDetail_detail_list'>
              <div className='approveDetail_detail_list_name'>防护品</div>
              <div className='approveDetail_detail_list_con'>{data.productsName}</div>
            </div>
            <div className='approveDetail_detail_list'>
              <div className='approveDetail_detail_list_name'>责任人</div>
              <div className='approveDetail_detail_list_con'>{data.fzr}</div>
            </div>

            <div className='approveDetail_detail_list'>
              <div className='approveDetail_detail_list_name'>提交时间</div>
              <div className='approveDetail_detail_list_con'>{
                data.create_time ? moment(data.create_time).format('YYYY/MM/DD HH:mm') : moment(new Date()).format('YYYY/MM/DD HH:mm')
              }</div>
            </div>
            {
              data.pr_approval_time ? <div className='approveDetail_detail_list'>
                <div className='approveDetail_detail_list_name'>审批时间</div>
                <div className='approveDetail_detail_list_con'>
                  {moment(data.pr_approval_time).format('YYYY/MM/DD HH:mm')}</div>
              </div> : ''
            }
            {
              data.pr_approval_result === 2 && <div className='approveDetail_detail_list'>
                <div className='approveDetail_detail_list_name'>审批操作</div>
                <div className='approveDetail_detail_list_con'>审核成功</div>
              </div>
            }
            {
              data.pr_approval_result === 3 && <div className='approveDetail_detail_list'>
                <div className='approveDetail_detail_list_name'>审批操作</div>
                <div className='approveDetail_detail_list_con'>审核驳回</div>
              </div>
            }
            {
              data.pr_approval_result === 3 && <div className='approveDetail_detail_list_textarea'>
                <div className='approveDetail_detail_list_textarea_title'>驳回原因</div>
                <TextareaItem
                  rows={5}
                  placeholder="说点什么吧~"
                  value={data.pr_approval_opinion}
                />
              </div>
            }
          </div>
        </div> */}
      </div>
    )
  }
}

export default RiskReportDetail