import React, { Component } from 'react'
import { InputItem, DatePicker, List, Toast } from 'antd-mobile'
import { Radio, Checkbox, Switch } from 'antd'
import { findScheduledById, addScheduled, updateScheduled } from '../../../api/api'
import moment from 'moment'
import './timing_configuration.less'

class TimingConfiguration extends Component {

  state = {
    configData: {
      deviceIds: '', // 空调设备deviceId
      task_id: '', // 配置id
      task_name: '', // 配置名称
      task_action: '', // 执行动作
      taskCondition: undefined, // 执行时间
      taskCycle: [], // 执行周期
      start_date: undefined, // 周期执行开始日期
      end_date: undefined, // 周期执行结束日期
      task_status: true, // 是否启用
    }, // 配置信息
  }

  componentDidMount = () => {
    const { configData } = this.state
    document.title = "空调-定时配置"
    if (this.props.match.params.teskId !== '0') {
      this.fetch(this.props.match.params.teskId)
    } else {
      configData.deviceIds = this.props.match.params.deviceId
      this.setState({
        configData
      })
    }
  }

  fetch = async taskId => {
    const res = await findScheduledById({ taskId })
    if (res.code === 20000) {
      const configData = res.data || {}
      configData.deviceIds = this.props.match.params.deviceId
      configData.taskCondition = new Date(moment(configData.task_condition, 'HH:mm'))
      configData.taskCycle = configData.task_cycle.length ? configData.task_cycle.split(',') : []
      configData.start_date = new Date(moment(configData.task_start_date))
      configData.end_date = new Date(moment(configData.task_end_date))
      configData.task_status = configData.task_state ? true : false
      this.setState({
        configData
      })
    }
  }

  // 保存
  handleSubmit = async () => {
    const { configData } = this.state
    if (!configData.task_name) return Toast.info('请输入定时配置名称')
    if (!configData.task_action) return Toast.info('请选择执行动作')
    if (!configData.taskCondition) return Toast.info('请选择每日执行时间')
    if (!configData.taskCycle.length) return Toast.info('请选择执行周期')
    if (!configData.start_date) return Toast.info('请选择周期执行开始时间')
    if (!configData.end_date) return Toast.info('请选择周期执行结束时间')
    const req = {
      deviceIds: configData.deviceIds,
      task_id: configData.task_id,
      task_name: configData.task_name,
      task_action: configData.task_action,
      task_condition: moment(configData.taskCondition).format('HH:mm'),
      task_cycle: configData.taskCycle.join(','),
      task_start_date: moment(configData.start_date).format('YYYY-MM-DD'),
      task_end_date: moment(configData.end_date).format('YYYY-MM-DD'),
      task_state: configData.task_status ? 1 : 0
    }
    const method = req.task_id ? updateScheduled : addScheduled
    const res = await method(req)
    if (res.code === 20000) {
      Toast.success(res.message)
      setTimeout(() => {
        this.props.history.go(-1)
      }, 2000)
    }
  }

  changeTaskAction = (str) => {
    const { configData } = this.state
    configData.task_action = str
    this.setState({
      configData
    })
  }
  changeTaskCycle = (val) => {
    const { configData } = this.state
    const index = configData.taskCycle.indexOf(val)
    index >= 0 ? configData.taskCycle.splice(index, 1) : configData.taskCycle.push(val)
    this.setState({
      configData
    })
    console.log(this.state.configData.taskCycle, 'ppppppppppp')

  }
  render () {
    const { configData } = this.state
    const options = [
      { label: '周一', value: '1' },
      { label: '周二', value: '2' },
      { label: '周三', value: '3' },
      { label: '周四', value: '4' },
      { label: '周五', value: '5' },
      { label: '周六', value: '6' },
      { label: '周日', value: '7' }
    ]
    return (
      <div className='TimingConf'>
        <div className='TimingConf_main'>
          <div className='TimingConf_item_input'>
            <InputItem value={configData.task_name} placeholder="请输入定时配置名称" onChange={e => {
              configData.task_name = e
              this.setState({ configData })
            }}>配置名称</InputItem>
          </div>
          <div className='TimingConf_item_span'>
            <div className='TimingConf_item_span_name'>
              执行动作
            </div>
            <div className='TimingConf_item_span_value'>
              <span className={configData.task_action === 'off' ? "checked" : ''} onClick={() => this.changeTaskAction('off')}>关机</span>
              <span className={configData.task_action === 'hea' ? "checked" : ''} onClick={() => this.changeTaskAction('hea')}>开机制热</span>
              <span className={configData.task_action === 'col' ? "checked" : ''} onClick={() => this.changeTaskAction('col')}>开机制冷</span>
              {/* <Radio.Group onChange={e => {
                configData.task_action = e.target.value
                this.setState({ configData })
              }} value={configData.task_action}>
                <Radio value="off">关机</Radio>
                <Radio value="hea">开机制热</Radio>
                <Radio value="col">开机制冷</Radio>
              </Radio.Group> */}
            </div>
          </div>
          <div className='TimingConf_item_time'>
            <DatePicker
              mode="time"
              value={configData.taskCondition}
              onChange={time => {
                configData.taskCondition = time
                this.setState({ configData })
              }}
            >
              <List.Item arrow="horizontal">每日执行时间</List.Item>
            </DatePicker>
          </div>
          <div className='TimingConf_item_checkbox'>
            <div className='TimingConf_item_checkbox_name'>
              执行周期
            </div>
            <div className='TimingConf_item_checkbox_value'>
              {
                options.map(item => {
                  return <span className={configData.taskCycle.indexOf(item.value) >= 0 ? 'checked' : ''}
                    key={item.value}
                    onClick={() => this.changeTaskCycle(item.value)}
                  >{item.label}</span>
                })
              }
              {/* <Checkbox.Group value={configData.taskCycle} options={options} onChange={e => {
                configData.taskCycle = e
                this.setState({
                  configData
                })
              }} /> */}
            </div>
          </div>
          <div className='TimingConf_item_date'>
            <DatePicker
              mode="date"
              value={configData.start_date}
              maxDate={configData.end_date ? configData.end_date : null}
              onChange={time => {
                configData.start_date = time
                this.setState({ configData })
              }}
            >
              <List.Item arrow="horizontal">周期执行开始日期</List.Item>
            </DatePicker>
          </div>
          <div className='TimingConf_item_date'>
            <DatePicker
              mode="date"
              value={configData.end_date}
              minDate={configData.start_date ? configData.start_date : null}
              onChange={time => {
                configData.end_date = time
                this.setState({ configData })
              }}
            >
              <List.Item arrow="horizontal">周期执行结束日期</List.Item>
            </DatePicker>
          </div>
          <div className='TimingConf_item_switch '>
            <div className='TimingConf_item_switch_name'>是否启用</div>
            <div className='TimingConf_item_switch_label'>
              <Switch checked={configData.task_status} checkedChildren="启" unCheckedChildren="禁" onChange={e => {
                configData.task_status = e
                this.setState({
                  configData
                })
              }} />
            </div>
          </div>
        </div>
        <div className='TimingConf_submit' onClick={() => this.handleSubmit()}>
          <div>保存配置</div>
        </div>
      </div>
      // <div className='TimingConfiguration'>
      //   <div className='TimingConfiguration_main'>
      //     <div className='TimingConfiguration_item_input'>
      //       <InputItem value={configData.task_name} placeholder="请输入定时配置名称" onChange={e => {
      //         configData.task_name = e
      //         this.setState({ configData })
      //       }}>配置名称</InputItem>
      //     </div>
      //     <div className='TimingConfiguration_item_radio'>
      //       <div className='TimingConfiguration_item_radio_name'>
      //         执行动作
      //       </div>
      //       <div className='TimingConfiguration_item_radio_value'>
      //         <Radio.Group onChange={e => {
      //           configData.task_action = e.target.value
      //           this.setState({ configData })
      //         }} value={configData.task_action}>
      //           <Radio value="off">关机</Radio>
      //           <Radio value="hea">开机制热</Radio>
      //           <Radio value="col">开机制冷</Radio>
      //         </Radio.Group>
      //       </div>
      //     </div>
      //     <div className='TimingConfiguration_item_time'>
      //       <DatePicker
      //         mode="time"
      //         value={configData.taskCondition}
      //         onChange={time => {
      //           configData.taskCondition = time
      //           this.setState({ configData })
      //         }}
      //       >
      //         <List.Item arrow="horizontal">每日执行时间</List.Item>
      //       </DatePicker>
      //     </div>
      //     <div className='TimingConfiguration_item_checkbox'>
      //       <div className='TimingConfiguration_item_checkbox_name'>
      //         执行周期
      //       </div>
      //       <div className='TimingConfiguration_item_checkbox_value'>
      //         <Checkbox.Group value={configData.taskCycle} options={options} onChange={e => {
      //           configData.taskCycle = e
      //           this.setState({
      //             configData
      //           })
      //         }} />
      //       </div>
      //     </div>
      //     <div className='TimingConfiguration_item_date'>
      //       <DatePicker
      //         mode="date"
      //         value={configData.start_date}
      //         maxDate={configData.end_date ? configData.end_date : null}
      //         onChange={time => {
      //           configData.start_date = time
      //           this.setState({ configData })
      //         }}
      //       >
      //         <List.Item arrow="horizontal">周期执行开始日期</List.Item>
      //       </DatePicker>
      //     </div>
      //     <div className='TimingConfiguration_item_date'>
      //       <DatePicker
      //         mode="date"
      //         value={configData.end_date}
      //         minDate={configData.start_date ? configData.start_date : null}
      //         onChange={time => {
      //           configData.end_date = time
      //           this.setState({ configData })
      //         }}
      //       >
      //         <List.Item arrow="horizontal">周期执行结束日期</List.Item>
      //       </DatePicker>
      //     </div>
      //     <div className='TimingConfiguration_item_switch '>
      //       <div className='TimingConfiguration_item_switch_name'>是否启用</div>
      //       <div className='TimingConfiguration_item_switch_label'>
      //         <Switch checked={configData.task_status} checkedChildren="启" unCheckedChildren="禁" onChange={e => {
      //           configData.task_status = e
      //           this.setState({
      //             configData
      //           })
      //         }} />
      //       </div>
      //     </div>
      //   </div>
      //   <div className='TimingConfiguration_submit' onClick={() => this.handleSubmit()}>
      //     保存
      //   </div>
      // </div>
    )
  }
}

export default TimingConfiguration
