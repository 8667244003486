import React, { Component } from 'react'
import { Toast } from 'antd-mobile'
import { findMyAnswerRecord } from '../../../api/api'
import './on_line.less'

const flagMap = {
  0: '单选题',
  1: '多选题',
  2: '判断题'
}

const questionNumber = [
  {id: 0, title: 'A'},
  {id: 1, title: 'B'},
  {id: 2, title: 'C'},
  {id: 3, title: 'D'},
  {id: 4, title: 'E'},
  {id: 5, title: 'F'},
  {id: 6, title: 'G'},
  {id: 7, title: 'H'},
  {id: 8, title: 'I'},
]

// 0：没有答题；1：答对了；2：答错了
const isErrMap = {
  3: '',
  1: 'doneSucc',
  2: 'doneErr'
}

class ExamAnalysis extends Component {

  state = {
    allData: [],            //所有题
    choosedIndex: 1,        //选中的第几道题
  }

  componentDidMount = () => {
    this.fetch()
  }

  fetch = async () => {
    Toast.loading('加载中', 0)
    const param = this.props.match.params
    const res = await findMyAnswerRecord({ recordId: param.recordId, flag: param.flag === '1' ? 1 : null })
    if (res.code === 20000) {
      Toast.hide()
      const arr = res.data.map(x => {
        x.answer = x.resultIds ? x.resultIds.split(',').map(t => Number(t)) : []
        return x
      })
      const newArr = arr.map(x => {
        x.resultConfigEntities = x.resultConfigEntities.map(t => {
          if (x.problem_flag === 1) {
            if (x.answer.find(y => y === t.result_id)) {
              if (t.result_flag === 1) {
                t.answer_title = 1 // 做正确
              } else {
                t.answer_title = 2 // 做错
              }
            } else {
              if (t.result_flag === 1) {
                t.answer_title = 1 // 漏掉的正确答案
              }
            }
          } else {
            if (x.answer.find(y => y === t.result_id)) {
              if (t.result_flag === 1) {
                t.answer_title = 1 // 做正确
              } else {
                t.answer_title = 2 // 做错
              }
            } else {
              if (t.result_flag === 1) {
                t.answer_title = 1 // 正确答案
              }
            }
          }
          return t
        })
        return x
      })
      this.setState({
        allData: newArr
      })
    }
  }

  //切换下一题
  nextData = () => {
    this.handleChooseData(this.state.choosedIndex)
  }

  //切换题
  handleChooseData = index => {
    if (index === this.state.allData.length) {
      Toast.info('已经是最后一题了')
      return false
    }
    this.setState({
      choosedIndex: index + 1,
    })
  }

  render() {
    const { allData, choosedIndex } = this.state
    const chooseData = allData[choosedIndex - 1]
    return (
      <div className='on_line'>
        <div className='on_line_card'>
          <div className='on_line_card_title'>
            <div className='card_title'>答题卡</div>
            <div className='card_num'>
              <span>{(allData || []).length}</span><span>/{(allData || []).length}</span>
            </div>
          </div>
          <div className='on_line_card_con'>
            {
              (allData || []).map((item, index) => {
                return <a className={`${isErrMap[item.state]}`} key={item.problem_id} onClick={() => this.handleChooseData(index)}>{index + 1}</a>
              })
            }
          </div>
        </div>
        {
          chooseData && JSON.stringify(chooseData) !== '{}' ? <div className="on_line_question">
            <div className='quest_title'>
              <span className='quest_title_lx'>{flagMap[chooseData.problem_flag]}</span>
              <span className='quest_title_name'>
                {choosedIndex}、{chooseData.problem_name}
              </span>
            </div>
            {(chooseData.problem_flag === 0 || chooseData.problem_flag === 2) && <div>
              <div className='all_answer'>
                {chooseData.resultConfigEntities && chooseData.resultConfigEntities.map((ev, i) =>
                  <div key={ev.result_id} className={`all_answer_list ${ev.answer_title === 1 ? 'answer_success' : ev.answer_title === 2 ? 'answer_error' : ''}`}>
                    {/* {ev.result_sort}.{ev.result_name} */}
                    {questionNumber[i].title}.{ev.result_name}
                  </div>
                )}
              </div>
            </div>
            }
            {(chooseData.problem_flag === 1) && <div className='all_answer'>
              {chooseData.resultConfigEntities && chooseData.resultConfigEntities.map((p, i) =>
                <div key={p.result_id} className={`all_answer_list ${p.answer_title === 1 ? 'answer_success' : p.answer_title === 2 ? 'answer_error' : ''}`}>
                  {/* {p.result_sort}.{p.result_name} */}
                  {questionNumber[i].title}.{p.result_name}
                </div>
              )}
            </div>
            }
          </div> : ''
        }
        {
          chooseData && JSON.stringify(chooseData) !== '{}' ? <div className="quest_foot">
            <div className='quest_foot_title'>答案解析</div>
            <div className='quest_foot_desc'>{chooseData.answer_desc}</div>
          </div> : ''
        }
        <div className='on_line_btn'>
          {
            choosedIndex < allData.length ? <div className='on_line_btn_next' onClick={() => this.nextData()}>下一题</div> : ''
          }
        </div>
      </div>
    )
  }
}

export default ExamAnalysis