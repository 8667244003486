import React, { Component } from 'react'
import { TreeSelect } from 'antd'
import { TextareaItem, Toast, InputItem } from 'antd-mobile';
import { req_findDepartmentInfo, req_findDepartmentInfoToTeacher } from '../../../../api/whp'
import { disposeRegisterRecord, findDisposeRecordByCode } from '../../../../api/fqw'

class Disposal extends Component {
  state = {
    startX: '',
    depTreeData: [], // 学院部门树
    depData: [], // 学院部门
    depUserData: [], // 部门下人员树
    depArr: [], // 部门下人员
    dispose_dwdm: undefined, // 处理单位id
    dispose_dwdh: undefined, // 单位电话
    principal_code: undefined, // 负责人
    principal_phone: undefined, // 负责人电话
    dispose_cost: undefined, // 处理费用
    dispose_descr: '', // 备注
    type: '1',
  }

  componentDidMount = () => {
    document.title = '废弃物处理'
    window.scrollTo(0, 0)
    this.fetchDep()
    this.fetchDepUser()
    this.props.match.params.type === '2' && this.fetchDetail(this.props.match.params.id)
    this.setState({
      type: this.props.match.params.type
    })
  }

  // 查询处理详情
  fetchDetail = async registerCode => {
    const res = await findDisposeRecordByCode({ registerCode })
    if (res.code === 20000) {
      this.setState({
        dispose_dwdm: res.data.dispose_dwdm,
        dispose_dwdh: res.data.dispose_dwdh,
        principal_code: res.data.principal_code,
        principal_phone: res.data.principal_phone,
        dispose_cost: res.data.dispose_cost,
        dispose_descr: res.data.dispose_descr,
      })
    }
  }

  // 查询学院/部门
  fetchDep = async () => {
    const res = await req_findDepartmentInfo({ dwlx: 1 })
    if (res.code === 20000) {
      let menuList = res.data
      menuList = menuList.map(a => {
        return {
          title: a.dwmc,
          key: a.dwdm,
          parentId: a.sj_dm,
          value: a.dwdm,
          disabled: a.sj_dm === '0' ? true : false
        }
      })
      menuList = menuList.map(m => {
        m.children = menuList.filter(p => p.parentId === m.key)
        return m
      }).filter(x => !res.data.find(v => v.dwdm === x.parentId))
      this.setState({
        depTreeData: menuList,
        depData: res.data,
      })
    }
  }

  // 查询部门和人员
  fetchDepUser = async () => {
    const res = await req_findDepartmentInfoToTeacher()
    if (res.code === 20000) {
      let menuList = (res.data || []).map(item => {
        return {
          title: item.dwmc,
          value: item.dw_id,
          parentId: item.sj_dm,
          disabled: true,
          children: (item.teacherInfoEntities || []).map(val => {
            return {
              title: val.xm,
              value: val.gh,
              parentId: val.dwdm,
              disabled: false,
            }
          })
        }
      })
      menuList = menuList.map(item => {
        item.children = item.children.length ? item.children : menuList.filter(p => p.parentId === item.value)
        return item
      }).filter(p => p.parentId === '0')
      const a = []
      const c = a.concat(menuList)
      const depArr = this.deep(c)
      this.setState({
        depUserData: menuList,
        depArr
      })
    }
  }

  // 提交处理
  handleSubmit = async () => {
    const { dispose_dwdm, dispose_dwdh, principal_code, principal_phone, dispose_cost, dispose_descr } = this.state
    if (dispose_dwdm === undefined || dispose_dwdh === undefined || principal_code === undefined || principal_phone === undefined || dispose_cost === undefined) {
      Toast.info('请补全上述内容！')
      return false
    }
    const res = await disposeRegisterRecord({
      register_code: this.props.match.params.id,
      dispose_dwdm,
      dispose_dwdh,
      principal_code,
      principal_phone,
      dispose_cost,
      dispose_descr
    })
    if (res.code === 20000) {
      Toast.info("提交成功！")
      setTimeout(() => {
        this.props.history.goBack()
      }, 2000);
    }
  }

  // 数组转变
  deep = (node) => {
    let stack = node,
      data = []
    while (stack.length !== 0) {
      let pop = stack.pop()
      data.push({
        title: pop.title,
        parentId: pop.parentId,
        value: pop.value,
      })
      let children = pop.children
      if (children) {
        for (let i = children.length - 1; i >= 0; i--) {
          stack.push(children[i])
        }
      }
    }
    return data
  }

  render() {
    const { depTreeData, dispose_dwdm, dispose_dwdh, depUserData, principal_code, principal_phone, dispose_cost, dispose_descr, type } = this.state

    return (
      <div className='subscription_add disposal_detail'>
        <div className='subscription_add_title'>
          基础信息
        </div>
        <div className='subscription_add_form'>
          <div className='subscription_add_tree'>
            <div><span style={{ color: 'red' }}>* </span>处理单位</div>
            <TreeSelect
              showSearch
              treeNodeFilterProp='title'
              placeholder="请选择"
              disabled={type === '2' ? true : false}
              style={{ width: '100%' }}
              value={dispose_dwdm}
              treeData={depTreeData}
              onChange={(e) => {
                this.setState({
                  dispose_dwdm: e ? e : undefined,
                })
              }}
            />
          </div>
          <div className='subscription_add_input'>
            <InputItem placeholder="请输入单位电话" disabled={type === '2' ? true : false} value={dispose_dwdh} onChange={(e) => {
              this.setState({
                dispose_dwdh: e ? e : undefined
              })
            }}><span style={{ color: 'red' }}>* </span>单位电话</InputItem>
          </div>
          <div className='subscription_add_tree'>
            <div>
              <span style={{ color: 'red' }}>* </span>负责人
            </div>
            <TreeSelect
              placeholder="请选择负责人"
              showSearch
              disabled={type === '2' ? true : false}
              treeData={depUserData}
              treeNodeFilterProp='title'
              style={{ width: '100%' }}
              value={principal_code}
              onChange={(e) => {
                this.setState({
                  principal_code: e ? e : undefined,
                })
              }}
            />
          </div>
          <div className='subscription_add_input'>
            <InputItem placeholder="请输入负责人电话" disabled={type === '2' ? true : false} value={principal_phone} onChange={(e) => {
              this.setState({
                principal_phone: e ? e : undefined
              })
            }}><span style={{ color: 'red' }}>* </span>负责人电话</InputItem>
          </div>
          <div className='subscription_add_input'>
            <InputItem type='digit' placeholder="请输入处理费用"disabled={type === '2' ? true : false} value={dispose_cost} onChange={(e) => {
              this.setState({
                dispose_cost: e ? e : undefined
              })
            }}><span style={{ color: 'red' }}>* </span>处理费用</InputItem>
          </div>
        </div>
        <div className='subscription_add_form'>
          <div className='subscription_add_text'>
            <div>备注</div>
            <TextareaItem
              placeholder="请输入备注"
              rows={5}
              disabled={type === '2' ? true : false}
              value={dispose_descr}
              onChange={(e) => {
                this.setState({
                  dispose_descr: e
                })
              }}
            />
          </div>
        </div>
        {
          type === '1' ? <div className='subscription_list_but'>
            <div onClick={() => this.handleSubmit()}>提交处理</div>
          </div> : ''
        }
      </div >
    )
  }
}

export default Disposal
