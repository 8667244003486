import React, { Component } from 'react'
import { Icon, Checkbox } from 'antd'
import { Modal, Toast } from 'antd-mobile'
import cookie from 'react-cookies'
import './index.less'

class QrImage extends Component {

  state = {
    visible: false,
    checked: false,
  }

  componentDidMount = () => {
    this.setState({
      checked: cookie.load('photo') == 1 ? false : true
    })
  }

  handleModal = () => {
    const { checked } = this.state
    if (checked) {
      document.getElementById('upload').click()
    } else {
      this.setState({
        visible: true
      })
    }
  }

  handleChangePhoto = e => {
    if (e.target.checked) {
      this.setState({
        checked: true
      })
      cookie.save('photo', 2)
    } else {
      this.setState({
        checked: false
      })
      cookie.save('photo', 1)
    }
  }

  // 下一步
  handleStep = () => {
    this.setState({
      visible: false
    })
    document.getElementById('upload').click()
  }

  // 上传附件
  onChangeFile = (e) => {
    const { files } = e.target
    let that = this
    Toast.loading('图片上传中', 0)
    if (files && files.length) {
      let formData = new FormData()
      formData.append("multipartfile", files[0])
      const imgSize = files[0].size
      let imageDataReader = new FileReader()
      imageDataReader.readAsDataURL(files[0])
      imageDataReader.onload = function () {
        Toast.hide()
        let imgValue = this.result
        let base64 = ''
        if (imgSize > 1024 * 1024 * 2) {
          var img = new Image()
          img.src = imgValue
          img.onload = function () {
            var these = this
            // 默认按比例压缩
            var w = these.width * 0.6,
              h = these.height * 0.6
            //生成canvas
            var canvas = document.createElement('canvas')
            var ctx = canvas.getContext('2d')
            // 创建属性节点
            var anw = document.createAttribute("width")
            anw.nodeValue = w
            var anh = document.createAttribute("height")
            anh.nodeValue = h
            canvas.setAttributeNode(anw)
            canvas.setAttributeNode(anh)
            // 压缩图片
            ctx.drawImage(these, 0, 0, w, h)
            // quality 默认图片质量为0.7, quality值越小，所绘制出的图像越模糊
            // toDataURL将画布转化为base64格式信息图像
            base64 = canvas.toDataURL('image/jpeg', 0.8)
            that.props.history.push({ pathname: '/module/gather/cropping', state: { imgUrl: base64 } })
          }
        } else {
          that.props.history.push({ pathname: '/module/gather/cropping', state: { imgUrl: imgValue } })
        }
      }
    }
  };

  render () {
    const { visible, checked } = this.state
    return (
      <div className='qrImage'>
        <div className='qrImage_title'>请采集照片</div>
        <div className='qrImage_con'>
          <div className='qrImage_con_photo' onClick={() => this.handleModal()}>
            <Icon type="plus" />
            <div className='qrImage_con_photo_title'>采集照片</div>
          </div>
        </div>
        <div className='qrImage_info'>查看 <a onClick={() => this.setState({ visible: true })}>照片采集示例</a></div>
        <Modal
          popup
          visible={visible}
          title="照片采集示例"
          onClose={() => this.setState({ visible: false })}
          animationType="slide-up"
          className='gather_modal sl_modal'
        >
          <div className='gather_modal_img'>
            <img alt='' src={require('../../../images/photo.png')} />
          </div>
          {/* <div className='gather_modal_checkbox'>
            <Checkbox checked={checked} onChange={e => this.handleChangePhoto(e)}>我知道了，以后不在提醒</Checkbox>
          </div> */}
          <div className='gather_modal_step' onClick={() => this.handleStep()}>下一步</div>
        </Modal>
        <input type='file' id='upload' accept="image/*" onChange={e => this.onChangeFile(e)} hidden />
      </div>
    )
  }
}

export default QrImage
