import React, { Component } from 'react'
import { findQrCodeInfoBySafety } from '../../api/api'

class Lab extends Component {

  state = {
    data: {},
  }

  componentDidMount = async () => {
    const res = await findQrCodeInfoBySafety({ roomId: this.props.match.params.id })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className='lab'>
        <div className='lab_title'>实验室信息</div>
        <div className='lab_con'>
          <div className='lab_con_detail'>
            <div className='lab_con_detail_list'>
              <div className='lab_con_detail_list_title'>实验室编号</div>
              <div className='lab_con_detail_list_con'>{ data.room_bh }</div>
            </div>
            <div className='lab_con_detail_list'>
              <div className='lab_con_detail_list_title'>实验室名称</div>
              <div className='lab_con_detail_list_con'>{ data.room_name }</div>
            </div>
            <div className='lab_con_detail_list'>
              <div className='lab_con_detail_list_title'>房间号</div>
              <div className='lab_con_detail_list_con'>{ data.room_number }</div>
            </div>
            <div className='lab_con_detail_list'>
              <div className='lab_con_detail_list_title'>安全级别</div>
              <div className='lab_con_detail_list_con'>{ data.grade_name }</div>
            </div>
            <div className='lab_con_detail_list'>
              <div className='lab_con_detail_list_title'>实验室性质</div>
              <div className='lab_con_detail_list_con'>{ data.sysxzmc }</div>
            </div>
            <div className='lab_con_detail_list'>
              <div className='lab_con_detail_list_title'>面积</div>
              <div className='lab_con_detail_list_con'>{ data.mj }m²</div>
            </div>
            <div className='lab_con_detail_list'>
              <div className='lab_con_detail_list_title'>容纳人数</div>
              <div className='lab_con_detail_list_con'>{ data.rnrs }</div>
            </div>
            <div className='lab_con_detail_list'>
              <div className='lab_con_detail_list_title'>级别</div>
              <div className='lab_con_detail_list_con'>{ data.jbmc }</div>
            </div>
            <div className='lab_con_detail_list'>
              <div className='lab_con_detail_list_title'>所属学院</div>
              <div className='lab_con_detail_list_con'>{ data.bmmc }</div>
            </div>
            <div className='lab_con_detail_list'>
              <div className='lab_con_detail_list_title'>所属中心</div>
              <div className='lab_con_detail_list_con'>{ data.sszxmc }</div>
            </div>
            <div className='lab_con_detail_list'>
              <div className='lab_con_detail_list_title'>实验室负责人</div>
              <div className='lab_con_detail_list_con'>{ data.fzr_name }</div>
            </div>
            <div className='lab_con_detail_list'>
              <div className='lab_con_detail_list_title'>安全负责人</div>
              <div className='lab_con_detail_list_con'>{ data.aqfzr }</div>
            </div>
            <div className='lab_con_detail_list'>
              <div className='lab_con_detail_list_title'>安全负责人电话</div>
              <div className='lab_con_detail_list_con'>{ data.aqfzrdh }</div>
            </div>
            <div className='lab_con_detail_list'>
              <div className='lab_con_detail_list_title'>承担学生上课人数</div>
              <div className='lab_con_detail_list_con'>{ data.stuNum }</div>
            </div>
            <div className='lab_con_detail_list'>
              <div className='lab_con_detail_list_title'>承担专业人数</div>
              <div className='lab_con_detail_list_con'>{ data.jobNum }</div>
            </div>
            <div className='lab_con_detail_list'>
              <div className='lab_con_detail_list_title'>承担课程数</div>
              <div className='lab_con_detail_list_con'>{ data.courseNum }</div>
            </div>
            <div className='lab_con_detail_list'>
              <div className='lab_con_detail_list_title'>检查隐患数</div>
              <div className='lab_con_detail_list_con'>{ data.hidden_num }</div>
            </div>
            <div className='lab_con_detail_list'>
              <div className='lab_con_detail_list_title'>待整改隐患数</div>
              <div className='lab_con_detail_list_con'>{ data.nothidden_num }</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Lab
