import React, { Component } from 'react'
import { findSaftyType, findJFTJ } from '../../../api/safty'
import IP from '../../../config/config'
export default class componentName extends Component {
  state = {
    TJ: {},
    typeData: []
  }
  componentDidMount () {
    this.fetchTJ()
    this.fetchType()
  }
  fetchTJ = async () => {
    const res = await findJFTJ({ jflx: 1 })
    if (res.code === 20000) {
      this.setState({
        TJ: res.data || {}
      })
    }
  }
  fetchType = async () => {
    const res = await findSaftyType()
    if (res.code === 20000) {
      this.setState({
        typeData: res.data
      })
    }
  }
  goStudy = (id) => {
    this.props.history.push(`/module/safty/study/online/${id}`)
  }
  goPaihangbang = () => {
    this.props.history.push(`/module/safty/study/paihangbang`)
  }
  render () {
    const { typeData, TJ } = this.state
    return (
      <div className='saftyModule examStudy'>
        <div className='content_wrap'>
          <div className="floor">
            <div className="title">
              <p>学习积分</p>
              <div className='paihangbang' onClick={this.goPaihangbang}>
                <img src={require("../../../images/safety_exam/paihangbang.png")} alt="" width={16} />
                <span>排行榜</span>
                <img src={require("../../../images/more2.png")} alt="" width={16} />
              </div>
            </div>
            <div className='gaikuang'>
              <div>
                <p>{TJ.totalIntegrate ? Math.round(TJ.totalIntegrate) : 0}</p>
                <span>累计积分</span>
              </div>
              <div>
                <p>{TJ.dayIntegrate ? Math.round(TJ.dayIntegrate) : 0}</p>
                <span>今日积分</span>
              </div>
              <div>
                <p>{TJ.dayNum || 0}</p>
                <span>学习天数</span>
              </div>
            </div>
          </div>
          <div className="floor">
            <p>课程分类</p>
            <div className="category_wrap">
              {(typeData || []).map(x => {
                return <div className='ev_category' key={x.category_id} onClick={() => this.goStudy(x.category_id)}>
                  <div>
                    {x.category_url ? <img src={IP.fileHost + x.category_url} alt="" width={32} height={32} /> : <img src={require('../../../img/safty/type_img.png')} alt="" width={32} height={32} />}

                    <div>
                      <div className='category_name'>{x.category_name}</div>
                      <span className='category_zhangjie_num'>{x.problem_num || 0} 门课程</span>
                    </div>
                  </div>
                </div>
              })}
              {typeData.length === 0 && <div className='not-yet'>--暂无数据--</div>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
