import React, { Component } from 'react'
import { Carousel, Grid } from 'antd-mobile';
import { findBxModuleInfo } from '../../../../api/repairApi'
import IP from '../../../../config/config'
import './index.less'
import img1 from '../../../../images/smartRepair/1694512439299.png'
import img2 from '../../../../images/smartRepair/1694512450517.png'
import img3 from '../../../../images/smartRepair/1694512504404.png'
import img4 from '../../../../images/smartRepair/1694512472090.png'
import img5 from '../../../../images/smartRepair/1700185379720.png'

class Home extends Component {
  state = {
    data: [],
    module: [],
    // module: [
    //   {
    //     icon: img1,
    //     url: '/module/smartRepair/fillFormServer',
    //     text: '用户报修',
    //   },
    //   {
    //     icon: img2,
    //     url: '/module/smartRepair/maintenanceTask',
    //     text: '维修工单',
    //   },
    //   {
    //     icon: img3,
    //     url: '/module/smartRepair/WorkOrdersList',
    //     text: '派发工单',
    //   },
    //   {
    //     icon: img4,
    //     url: '/module/smartRepair/workTracking',
    //     text: '工单跟踪',
    //   },
    //   {
    //     icon: img5,
    //     url: '/module/smartRepair/statistics',
    //     text: '数据统计',
    //   }
    // ],
    countUser: {},
    isOpen: false,
    moduleData: []
  }

  componentDidMount = () => {
    window.document.title = '智慧报修'
    this.findBxModuleInfo();
  }

  // 首页mode
  findBxModuleInfo = async () => {
    const res = await findBxModuleInfo()
    if (res.code === 20000) {
      this.setState({
        module: res.data.map(item => {
          return {
            icon: item.module_imge,
            url: item.module_url,
            text: item.module_name,
          }
        }),
      })
    }
  }

  // 模块跳转
  handleSkip = e => {
    if (e) {
      this.props.history.push({ pathname: e.url })
    }
  }

  render() {
    const { module } = this.state;
    return (
      <div className="repair_home">
        <Carousel className="space-carousel"
          autoplay
          infinite
        >
          <span style={{ display: 'block', position: 'relative', top: 0 }}>
            <img src={require('../../../../images/smartRepair/banner.jpg')} alt="" style={{ width: '100%', verticalAlign: 'top' }} onLoad={() => {
              window.dispatchEvent(new Event('resize'));
            }}
            />
          </span>
        </Carousel>
        <div className='repair_home-shortcut'>
          <Grid data={module} hasLine={false} columnNum={3}
            renderItem={dataItem => (
              <div style={{ padding: '12.5px' }}>
                {/* <img src={dataItem.icon} style={{ width: '60%', height: '60%' }} alt="" /> */}
                <img src={IP.fileHost + dataItem.icon} style={{ width: '44px', height: '44px' }} alt="" />
                <div style={{ color: '#888', fontSize: '14px', marginTop: '10%' }}>
                  <span>{dataItem.text}</span>
                </div>
              </div>
            )}
            onClick={e => this.handleSkip(e)} />
        </div>
      </div>
    );
  }
}

export default Home