import React, { Component } from 'react'
import { Cascader } from 'react-vant'
import { findAppSpaceDevice, findAppParamInfo, findAppDeviceEnvironmental2, findAppDeviceZnyd2, findAppDeviceZnkt2, findAppDeviceRelay2, findAppDeviceZncl2, findAppDeviceZnzd2, findModuleInfo, findSpaceInfo, findAppDeviceZhbp2, findAppDeviceZhms2, findAppDeviceJk2 } from '../../../api/api'
import cookie from 'react-cookies'
import './room.less'

class RoomManager extends Component {

  state = {
    buildRoom: [], // 楼宇房间
    roomData: [], // 所有房间
    roomInfo: {},
    moduleData: [], // 模块信息
    totalData: {}, // 温湿度、空气、设备总数
    allRoom: [], // 所有房间
    sensorData: {}, // 智能传感器
    znydData: {}, // 智能空开(智慧用电)
    znktData: {}, // 智能空调
    relayData: {}, // 智慧照明
    znclData: {}, // 智能窗帘
    znzdData: {}, // 智慧终端
    zhbpData: {}, // 智慧班牌
    zhmsData: {}, // 智慧门锁
    monitorData: {} //监控
  }

  componentDidMount = () => {
    document.title = "房间内设备"
    this.fetchModule()
    this.fetchBuild()
  }

  // 查询位置
  fetchBuild = async () => {
    const res = await findSpaceInfo()
    if (res.code === 20000) {
      let menuList = res.data.map(item => {
        return {
          text: item.dwmc,
          parentId: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
        }
      })
      menuList = menuList.map(item => {
        if (menuList.filter(p => p.parentId === item.value).length) {
          item.children = menuList.filter(p => p.parentId === item.value)
        }
        return item
      }).filter(p => p.parentId === '0')
      this.setState({
        buildRoom: menuList,
      })
    }
  }

  // 查询模块
  fetchModule = async () => {
    const res = await findModuleInfo()
    if (res.code === 20000) {
      this.fetchRoom(res.data)
      this.setState({
        moduleData: res.data
      })
    }
  }

  // 查询房间信息
  fetchRoom = async (arr) => {
    const res = await findAppSpaceDevice()
    if (res.code === 20000) {
      if (res.data.length) {
        const obj = cookie.load('roomInfo') ? cookie.load('roomInfo') : res.data[0]
        arr.find(x => x.module_url === '/module/environment') && this.fetchTotal(obj.wzId)
        arr.find(x => x.module_url === '/module/environment') && this.fetchSensor(obj.wzId)  // 环境监测
        arr.find(x => x.module_url === '/module/electricity') && this.fetchAirSwitch(obj.wzId) // 智慧用电
        arr.find(x => x.module_url === '/module/aircondition') && this.fetchZnkt(obj.wzId) // 智慧空调
        arr.find(x => x.module_url === '/module/lighting') && this.fetchRelay(obj.wzId) // 智慧照明
        arr.find(x => x.module_url === '/module/curtain') && this.fetchZncl(obj.wzId) // 智慧窗帘
        arr.find(x => x.module_url === '/module/controller') && this.fetchZnzd(obj.wzId) // 智慧终端
        arr.find(x => x.module_url === '/module/banpai') && this.fetchZhbp(obj.wzId) // 智慧班牌
        arr.find(x => x.module_url === '/module/doorLock') && this.fetchZhms(obj.wzId) // 智慧门锁
        this.fetchSPJK(obj.wzId)
        this.setState({
          roomInfo: obj,
          roomData: res.data
        })
      }
    }
  }

  // 切换房间
  handleCascaderFinish = (val, selectedRows, actions) => {
    const { moduleData, roomData } = this.state
    const obj = roomData.find(x => x.wzId === val[val.length - 1])
    moduleData.find(x => x.module_url === '/module/environment') && this.fetchTotal(val[val.length - 1])
    moduleData.find(x => x.module_url === '/module/environment') && this.fetchSensor(val[val.length - 1])
    moduleData.find(x => x.module_url === '/module/electricity') && this.fetchAirSwitch(val[val.length - 1])
    moduleData.find(x => x.module_url === '/module/aircondition') && this.fetchZnkt(val[val.length - 1])
    moduleData.find(x => x.module_url === '/module/lighting') && this.fetchRelay(val[val.length - 1])
    moduleData.find(x => x.module_url === '/module/curtain') && this.fetchZncl(val[val.length - 1])
    moduleData.find(x => x.module_url === '/module/controller') && this.fetchZnzd(val[val.length - 1])
    moduleData.find(x => x.module_url === '/module/banpai') && this.fetchZhbp(val[val.length - 1])
    moduleData.find(x => x.module_url === '/module/doorLock') && this.fetchZhms(val[val.length - 1])
    this.fetchSPJK(val[val.length - 1])
    const newObj = {
      wzId: obj ? obj.wzId : '',
      wzName: obj ? obj.wzName : '',
      buildingName: obj ? obj.buildingName : '',
      totalNum: obj ? obj.totalNum : 0,
    }
    this.setState({
      roomInfo: newObj
    })
    cookie.save('roomInfo', newObj)
  }

  // 查询温湿度、空气、设备总数
  fetchTotal = async roomId => {
    const res = await findAppParamInfo({ roomId })
    if (res.code === 20000) {
      this.setState({
        totalData: res.data || {}
      })
    }
  }

  // 查询智能传感器
  fetchSensor = async roomId => {
    const res = await findAppDeviceEnvironmental2({ roomId })
    if (res.code === 20000) {
      this.setState({
        sensorData: res.data
      })
    }
  }

  // 查询智能空开（智慧用电）
  fetchAirSwitch = async roomId => {
    const res = await findAppDeviceZnyd2({ roomId })
    if (res.code === 20000) {
      this.setState({
        znydData: res.data
      })
    }
  }

  // 查询智能空调
  fetchZnkt = async roomId => {
    const res = await findAppDeviceZnkt2({ roomId })
    if (res.code === 20000) {
      this.setState({
        znktData: res.data
      })
    }
  }

  // 查询智慧照明
  fetchRelay = async roomId => {
    const res = await findAppDeviceRelay2({ roomId })
    if (res.code === 20000) {
      this.setState({
        relayData: res.data
      })
    }
  }

  // 查询智能窗帘
  fetchZncl = async roomId => {
    const res = await findAppDeviceZncl2({ roomId })
    if (res.code === 20000) {
      this.setState({
        znclData: res.data
      })
    }
  }

  // 查询智慧终端
  fetchZnzd = async roomId => {
    const res = await findAppDeviceZnzd2({ roomId })
    if (res.code === 20000) {
      this.setState({
        znzdData: res.data
      })
    }
  }

  // 查询智慧班牌
  fetchZhbp = async roomId => {
    const res = await findAppDeviceZhbp2({ roomId })
    if (res.code === 20000) {
      this.setState({
        zhbpData: res.data
      })
    }
  }

  // 查询智慧门锁
  fetchZhms = async roomId => {
    const res = await findAppDeviceZhms2({ roomId })
    if (res.code === 20000) {
      this.setState({
        zhmsData: res.data
      })
    }
  }

  // 查询视频监控
  fetchSPJK = async roomId => {
    const res = await findAppDeviceJk2({ roomId })
    if (res.code === 20000) {
      this.setState({
        monitorData: res.data ? res.data[0] : {}
      })
    }
  }

  render () {
    const { buildRoom, roomInfo, totalData, sensorData, znydData, znktData, relayData, znclData, znzdData, zhbpData, zhmsData, monitorData } = this.state
    return (
      <div className='roomDevice'>
        <div className='shortTerm_img'>
          <Cascader
            popup={{ round: true }}
            title='选择实验室'
            activeColor="#00B4C4"
            options={buildRoom}
            onFinish={this.handleCascaderFinish}
          >
            {(_, selectedRows, actions) => (
              <div onClick={() => actions.open()}>切换房间</div>
            )}
          </Cascader>
        </div>
        <div className='shortTerm_name'>{roomInfo.wzName}</div>
        <div className='shortTerm_addr'>{roomInfo.buildingName ? roomInfo.buildingName.split('/')[0] : ''}</div>
        <div className='roomDevice_main'>
          <div className='roomDevice_environment'>
            <div className='roomDevice_environment_list'>
              <div className='roomDevice_environment_list_con'>
                {totalData.temperature ? `${totalData.temperature}℃` : '--'}
              </div>
              <div className='roomDevice_environment_list_desc'>温度</div>
            </div>
            <div className='roomDevice_environment_list'>
              <div className='roomDevice_environment_list_con'>
                {totalData.temperature ? '' : '--'}
                {totalData.temperature && totalData.temperature < 18 ? '偏冷' : ''}
                {totalData.temperature && totalData.temperature > 18 && totalData.temperature < 25 ? '舒适' : ''}
                {totalData.temperature && totalData.temperature > 25 ? '偏热' : ''}
              </div>
              <div className='roomDevice_environment_list_desc'>空气</div>
            </div>
            <div className='roomDevice_environment_list'>
              <div className='roomDevice_environment_list_con'>
                {totalData.humidity ? `${totalData.humidity}%` : '--'}
              </div>
              <div className='roomDevice_environment_list_desc'>湿度</div>
            </div>
            <div className='roomDevice_environment_list'>
              <div className='roomDevice_environment_list_con'>
                {roomInfo.totalNum || 0}
              </div>
              <div className='roomDevice_environment_list_desc'>设备总数</div>
            </div>
          </div>
          <div className='roomDevice_con'>
            <div className='roomDevice_con_con'>
              {/* 智能传感器 */}
              {
                sensorData.deviceNum ? <div className='roomDevice_con_item' onClick={() => this.props.history.push({ pathname: `/module/roomEnvironment/${roomInfo.wzId}`, state: { roomInfo } })}>
                  <div className='roomDevice_con_list'>
                    <div className='roomDevice_con_list_arrow'>
                      <img alt='' src={require('../../../images/more3.png')} />
                    </div>
                    <img className='roomDevice_con_list_img' alt='' src={require('../../../images/sensor.png')} />
                    <div className='roomDevice_con_list_top'>
                      <div className='roomDevice_con_list_name'>智能传感器</div>
                      <div className='roomDevice_con_list_on'>在线：{sensorData.onDevNum}</div>
                    </div>
                    <div className='roomDevice_con_list_desc'>
                      <div className='roomDevice_con_list_desc_num'>{sensorData.deviceNum}个设备</div>
                      <div className='roomDevice_con_list_desc_off'>离线：{sensorData.offDevNum}</div>
                    </div>
                  </div>
                </div> : ''
              }
              {/* 智能空开(智慧用电) */}
              {
                znydData.deviceNum ? <div className='roomDevice_con_item' onClick={() => this.props.history.push({ pathname: `/module/electricity/airSwitch/${roomInfo.wzId}`, state: { roomInfo } })}>
                  <div className='roomDevice_con_list'>
                    <div className='roomDevice_con_list_arrow'>
                      <img alt='' src={require('../../../images/more3.png')} />
                    </div>
                    <img className='roomDevice_con_list_img' alt='' src={require('../../../images/airSwitch.png')} />
                    <div className='roomDevice_con_list_top'>
                      <div className='roomDevice_con_list_name'>智能空开</div>
                      <div className='roomDevice_con_list_on'>在线：{znydData.onDevNum}</div>
                    </div>
                    <div className='roomDevice_con_list_desc'>
                      <div className='roomDevice_con_list_desc_num'>{znydData.deviceNum}个设备</div>
                      <div className='roomDevice_con_list_desc_off'>离线：{znydData.offDevNum}</div>
                    </div>
                  </div>
                </div> : ''
              }
              {/* 智能空调 */}
              {
                znktData.deviceNum ? <div className='roomDevice_con_item' onClick={() => this.props.history.push({ pathname: `/module/roomAir/${roomInfo.wzId}`, state: { roomInfo } })}>
                  <div className='roomDevice_con_list'>
                    <div className='roomDevice_con_list_arrow'>
                      <img alt='' src={require('../../../images/more3.png')} />
                    </div>
                    <img className='roomDevice_con_list_img' alt='' src={require('../../../images/airClose.png')} />
                    <div className='roomDevice_con_list_top'>
                      <div className='roomDevice_con_list_name'>智能空调</div>
                      <div className='roomDevice_con_list_on'>在线：{znktData.onDevNum}</div>
                    </div>
                    <div className='roomDevice_con_list_desc'>
                      <div className='roomDevice_con_list_desc_num'>{znktData.deviceNum}个设备</div>
                      <div className='roomDevice_con_list_desc_off'>离线：{znktData.offDevNum}</div>
                    </div>
                  </div>
                </div> : ''
              }
              {/* 智慧照明 */}
              {
                relayData.deviceNum ? <div className='roomDevice_con_item' onClick={() => this.props.history.push({ pathname: `/module/roomLighting/${roomInfo.wzId}`, state: { roomInfo } })}>
                  <div className='roomDevice_con_list'>
                    <div className='roomDevice_con_list_arrow'>
                      <img alt='' src={require('../../../images/more3.png')} />
                    </div>
                    <img className='roomDevice_con_list_img' alt='' src={require('../../../images/lampOpen.png')} />
                    <div className='roomDevice_con_list_top'>
                      <div className='roomDevice_con_list_name'>照明设备</div>
                      <div className='roomDevice_con_list_on'>在线：{relayData.onDevNum}</div>
                    </div>
                    <div className='roomDevice_con_list_desc'>
                      <div className='roomDevice_con_list_desc_num'>{relayData.deviceNum}个设备</div>
                      <div className='roomDevice_con_list_desc_off'>离线：{relayData.offDevNum}</div>
                    </div>
                  </div>
                </div> : ''
              }
              {/* 智能窗帘 */}
              {
                znclData.deviceNum ? <div className='roomDevice_con_item' onClick={() => this.props.history.push({ pathname: `/module/roomCurtain/${roomInfo.wzId}`, state: { roomInfo } })
                }>
                  <div className='roomDevice_con_list'>
                    <div className='roomDevice_con_list_arrow'>
                      <img alt='' src={require('../../../images/more3.png')} />
                    </div>
                    <img className='roomDevice_con_list_img' alt='' src={require('../../../images/curtain.png')} />
                    <div className='roomDevice_con_list_top'>
                      <div className='roomDevice_con_list_name'>智能窗帘</div>
                      <div className='roomDevice_con_list_on'>在线：{znclData.onDevNum}</div>
                    </div>
                    <div className='roomDevice_con_list_desc'>
                      <div className='roomDevice_con_list_desc_num'>{znclData.deviceNum}个设备</div>
                      <div className='roomDevice_con_list_desc_off'>离线：{znclData.offDevNum}</div>
                    </div>
                  </div>
                </div> : ''
              }
              {/* 智慧终端 */}
              {
                znzdData.deviceNum ? <div className='roomDevice_con_item' onClick={() => this.props.history.push({ pathname: `/module/controllerSwitch/${roomInfo.wzId}`, state: { roomInfo } })}>
                  <div className='roomDevice_con_list'>
                    <div className='roomDevice_con_list_arrow'>
                      <img alt='' src={require('../../../images/more3.png')} />
                    </div>
                    <img className='roomDevice_con_list_img' alt='' src={require('../../../images/kzq.png')} />
                    <div className='roomDevice_con_list_top'>
                      <div className='roomDevice_con_list_name'>终端设备</div>
                      <div className='roomDevice_con_list_on'>在线：{znzdData.onDevNum}</div>
                    </div>
                    <div className='roomDevice_con_list_desc'>
                      <div className='roomDevice_con_list_desc_num'>{znzdData.deviceNum}个设备</div>
                      <div className='roomDevice_con_list_desc_off'>离线：{znzdData.offDevNum}</div>
                    </div>
                  </div>
                </div> : ''
              }
              {/* 智慧班牌 */}
              {
                zhbpData.deviceNum ? <div className='roomDevice_con_item' onClick={() => this.props.history.push({ pathname: `/module/roomBanpai/${roomInfo.wzId}`, state: { roomInfo } })}>
                  <div className='roomDevice_con_list'>
                    <div className='roomDevice_con_list_arrow'>
                      <img alt='' src={require('../../../images/more3.png')} />
                    </div>
                    <img className='roomDevice_con_list_img' alt='' src={require('../../../images/zhgkbp.jpg')} />
                    <div className='roomDevice_con_list_top'>
                      <div className='roomDevice_con_list_name'>智慧班牌</div>
                      <div className='roomDevice_con_list_on'>在线：{zhbpData.onDevNum}</div>
                    </div>
                    <div className='roomDevice_con_list_desc'>
                      <div className='roomDevice_con_list_desc_num'>{zhbpData.deviceNum}个设备</div>
                      <div className='roomDevice_con_list_desc_off'>离线：{zhbpData.offDevNum}</div>
                    </div>
                  </div>
                </div> : ''
              }
              {/* 智慧门锁 */}
              {
                zhmsData.deviceNum ? <div className='roomDevice_con_item' onClick={() => this.props.history.push({ pathname: `/module/roomLock/${roomInfo.wzId}`, state: { roomInfo } })}>
                  <div className='roomDevice_con_list'>
                    <div className='roomDevice_con_list_arrow'>
                      <img alt='' src={require('../../../images/more3.png')} />
                    </div>
                    <img className='roomDevice_con_list_img' alt='' src={require('../../../images/suo.png')} />
                    <div className='roomDevice_con_list_top'>
                      <div className='roomDevice_con_list_name'>智慧门锁</div>
                      <div className='roomDevice_con_list_on'>在线：{zhmsData.onDevNum}</div>
                    </div>
                    <div className='roomDevice_con_list_desc'>
                      <div className='roomDevice_con_list_desc_num'>{zhmsData.deviceNum}个设备</div>
                      <div className='roomDevice_con_list_desc_off'>离线：{zhmsData.offDevNum}</div>
                    </div>
                  </div>
                </div> : ''
              }
              {/* 视频监控 */}
              {
                monitorData.deviceNum ? <div className='roomDevice_con_item' onClick={() => this.props.history.push({ pathname: `/module/roomMonitor/${roomInfo.wzId}`, state: { roomInfo } })}>
                  <div className='roomDevice_con_list'>
                    <div className='roomDevice_con_list_arrow'>
                      <img alt='' src={require('../../../images/more3.png')} />
                    </div>
                    <img className='roomDevice_con_list_img' alt='' src={require('../../../images/monitor2.png')} />
                    <div className='roomDevice_con_list_top'>
                      <div className='roomDevice_con_list_name'>视频监控</div>
                      {/* <div className='roomDevice_con_list_on'>在线：{monitorData.onDevNum}</div> */}
                    </div>
                    <div className='roomDevice_con_list_desc'>
                      <div className='roomDevice_con_list_desc_num'>{monitorData.deviceNum}个设备</div>
                      {/* <div className='roomDevice_con_list_desc_off'>离线：{monitorData.offDevNum}</div> */}
                    </div>
                  </div>
                </div> : ''
              }

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RoomManager
