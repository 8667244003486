
import React, { Component } from 'react';
import { Tabs, Badge, Icon } from 'antd-mobile';
import { createForm } from 'rc-form';
import moment from 'moment'
import cookie from 'react-cookies'
import './index.less'
import { findOrderByUserId, findAppAllOrder } from '../../../../api/repairApi'

class MaintenanceTask extends Component {
  state = {
    visible: false,
    dataList: [],
    state: 0,
    state2: 1,
    dataList2: [],
    classType: 0,
    orderState: 0,
    workState: 1
  }
  componentDidMount() {
    window.document.title = '维修工单'
    if (cookie.load('userData').dwdm == '999') {
      this.findAppAllOrder(1)
    } else {
      this.findOrderByUserId(0)
    }
  }

  findAppAllOrder2 = () => {
    // flag ，orderState ，workState
    const { classType, orderState, workState } = this.state;
    let parmas = {}
    if (classType === 0) {
      parmas.flag = 2
      parmas.workState = workState
    } else {
      parmas.flag = 1
      parmas.orderState = orderState
    }
    findAppAllOrder(parmas).then(res => {
      if (res.code === 20000) {
        if (classType == 0) {
          this.setState({
            dataList2: res.data || []
          })
        } else {
          this.setState({
            dataList: res.data || []
          })
        }
      }
    })
  }
  findAppAllOrder = (index) => {
    const { } = this.state
    var fasme = {}

    fasme.flag = 2
    fasme.workState = index
    this.setState({
      state2: index
    })


    if (cookie.load('userData').dwdm == '999') {
      fasme.flag = 2
      fasme.workState = index
      this.setState({
        state2: index
      })
    } else {
      fasme.flag = 1
      fasme.orderState = index
      this.setState({
        state: index
      })
    }
    findAppAllOrder(fasme).then(res => {
      if (res.code === 20000) {
        if (cookie.load('userData').dwdm == '999') {
          this.setState({
            dataList2: res.data || []
          })
        } else {
          this.setState({
            dataList: res.data || []
          })
        }
      }
    })
  }
  //查询所有任务
  findOrderByUserId = (state) => {
    this.setState({
      state
    })
    // 保养状态:0待保养 1 保养中 2 已完成
    findOrderByUserId({ orderState: state, userId: cookie.load('userData').user_id }).then(res => {
      if (res.code === 20000) {
        this.setState({
          dataList: res.data || []
        })
      }
    })
  }
  onSelect = async (otp) => {
    await this.findAppMaintenancetask(otp.props.value)
    await this.setState({
      visible: false
    })
  }
  render() {
    const { dataList, state, dataList2, state2, classType, orderState, workState } = this.state
    const tabs = [
      { title: <Badge>审批中<span>{state === 0 ? `(${dataList.length})` : ''}</span></Badge> },
      { title: <Badge>审批完成<span>{state === 1 ? `(${dataList.length})` : ''}</span></Badge> },
      { title: <Badge>审批拒绝<span>{state === 2 ? `(${dataList.length})` : ''}</span></Badge> },
      { title: <Badge>维修完成<span>{state === 3 ? `(${dataList.length})` : ''}</span></Badge> },
      { title: <Badge>工单完成<span>{state === 4 ? `(${dataList.length})` : ''}</span></Badge> },
    ]
    const tabs2 = [
      { title: <Badge>待维修<span>{state2 === 1 ? `(${dataList2.length})` : ''}</span></Badge> },
      { title: <Badge>维修完成<span>{state2 === 3 ? `(${dataList2.length})` : ''}</span></Badge> },
    ]
    const style = (state) => {
      if (state === 0) {//0待保养 1 保养中 2 已完成
        return 'content_title_botton_dai'
      } else if (state === 1) {
        return 'content_title_botton_yi'
      } else if (state === 2) {
        return 'content_title_botton_over'
      } else {
        return 'content_title_botton_all'
      }
    }
    const orderStateTitle = () => {
      const { orderState } = this.state
      if (orderState == 0) {
        return "待审批";
      } else if (orderState == 1) {
        return "审批完成"
      } else if (orderState == 2) {
        return "审批拒绝"
      } else if (orderState == 3) {
        return "维修完成"
      } else {
        return "工单完成"
      }
    }
    return (
      <div className="maintenanceTask">
        {cookie.load('userData').dwdm == '999' ? <div className="maintenanceTask_box maintenanceTask_box2">
          {classType == 0 ? <Tabs tabs={tabs2}
            initialPage={0}
            className="tabNoPading"
            onChange={(tab, index) => {
              this.setState({
                workState: index === 0 ? 1 : 3,
                state2: index === 0 ? 1 : 3,
              }, () => {
                this.findAppAllOrder2()
              })

            }}
          >
            <div className="maintenanceTask_content">
              {
                (dataList2 || []).map((item, index) => {
                  return <div key={index} onClick={() => {
                    if (state2 == 1) {
                      this.props.history.push(`/module/smartRepair/theDetails3/${item.order_id}`)
                    } else {
                      this.props.history.push(`/module/smartRepair/theDetails/${item.order_id}`)
                    }
                  }} className="content">
                    <div className="content_title">
                      <div className="content_title_code">{item.order_id}</div>
                      <div className={style(state2 == 1 ? 0 : 2)}>
                        {/* <span className="span_01">中</span> */}
                        <span className="span_02">{workState == 1 ? '待维修' : '维修完成'}</span>
                        <span className="span_03"><Icon type='right' /></span>
                      </div>
                    </div>

                    <div className='maintenanceTask_cont'>
                      <div className='maintenanceTask_cont_item'>
                        <div>类型</div>
                        <div>{item.fault_type_name}</div>
                      </div>
                      <div className='maintenanceTask_cont_item'>
                        <div>问题描述</div>
                        <div>{item.description}</div>
                      </div>
                      <div className='maintenanceTask_cont_item'>
                        <div>位置</div>
                        <div>{(item.building_name ? item.building_name : '') + ' ' + (item.room_name ? item.room_name : '')}</div>
                      </div>
                      <div className='maintenanceTask_cont_item'>
                        <div>报修人</div>
                        <div>{item.user_name}</div>
                      </div>

                    </div>
                    <div className="maintenanceTask_time">
                      {moment(item.create_time).format('YYYY-MM-DD ')}
                    </div>
                  </div>
                })
              }
            </div>
          </Tabs> : ''}
          {classType == 1 ? <Tabs tabs={tabs}
            initialPage={0}
            // tabBarActiveTextColor='red'
            // renderTabBar={this.renderTabBar}
            onChange={(tab, index) => {
              this.setState({
                orderState: index,
                state: index
              }, () => {
                this.findAppAllOrder2()
              })

            }}
          >
            <div className="maintenanceTask_content">
              {
                (dataList || []).map((item, index) => {
                  return <div key={index} onClick={() => {
                    this.props.history.push(`/module/smartRepair/theDetails/${item.order_id}`)
                  }} className="content">
                    <div className="content_title">
                      <div className="content_title_code">{item.order_id}</div>
                      <div className={style(orderState == 0 ? 0 : orderState == 2 ? 1 : 2)}>
                        {/* <span className="span_01">中</span> */}
                        <span className="span_02">{orderStateTitle()}</span>
                        <span className="span_03"><Icon type='right' /></span>
                      </div>
                    </div>

                    <div className='content_c'>
                      <div className="content_c_01"><span>类型: </span><span>{item.fault_type_name}</span></div>
                      <div className="content_c_02"><span>问题描述: </span><span>{item.description}</span></div>
                      <div className="content_c_03"><span><img width="18px" src={require('../../../../images/smartRepair/2.png')} alt="" /> </span><span>{(item.building_name ? item.building_name : '') + ' ' + (item.room_name ? item.room_name : '')}</span></div>
                      <div className="content_c_04">
                        <span><img width="18px" src={require('../../../../images/smartRepair/1.png')} alt="" /> <span>{item.user_name}</span> </span>
                        <span>报修时间: <span>{moment(item.create_time).format('YYYY-MM-DD ')}</span></span>
                      </div>
                    </div>
                  </div>
                })
              }
            </div>
          </Tabs> : ''}
        </div> : cookie.load('userData').dwdm == '999' ? <div className="maintenanceTask_box">
          {/* 维修工展示 */}
          <Tabs tabs={tabs2}
            initialPage={0}
            onChange={(tab, index) => {
              this.findAppAllOrder(index === 0 ? 1 : 3)
            }}
          >
            <div className="maintenanceTask_content">
              {
                (dataList2 || []).map((item, index) => {
                  return <div key={index} onClick={() => { this.props.history.push(`/module/smartRepair/theDetails3/${item.order_id}`) }} className="content">
                    <div className="content_title">
                      <div className="content_title_code">{item.order_id}</div>
                      <div className={style(0)}>
                        <span className="span_02">待维修</span>
                        <span className="span_03"><Icon type='right' /></span>
                      </div>
                    </div>

                    <div className='content_c'>
                      <div className="content_c_01"><span>类型: </span><span>{item.fault_type_name}</span></div>
                      <div className="content_c_02"><span>问题描述: </span><span>{item.description}</span></div>
                      <div className="content_c_03"><span><img width="18px" src={require('../../../../images/smartRepair/2.png')} alt="" /> </span><span>{(item.building_name ? item.building_name : '') + ' ' + (item.room_name ? item.room_name : '')}</span></div>
                      <div className="content_c_04">
                        <span><img width="18px" src={require('../../../../images/smartRepair/1.png')} alt="" /> <span>{item.user_name}</span> </span>
                        <span>报修时间: <span>{moment(item.create_time).format('YYYY-MM-DD ')}</span></span>
                      </div>
                    </div>
                  </div>
                })
              }
            </div>
            {/* 已审批 */}
            <div className="maintenanceTask_content">
              {
                (dataList2 || []).map((item, index) => {
                  return <div key={index} onClick={() => { this.props.history.push(`/module/smartRepair/theDetails/${item.order_id}`) }} className="content">
                    <div className="content_title">
                      <div className="content_title_code">{item.order_id}</div>
                      <div className={style(2)}>
                        <span className="span_02">维修完成</span>
                        <span className="span_03"><Icon type='right' /></span>
                      </div>
                    </div>

                    <div className='content_c'>
                      <div className="content_c_01"><span>类型: </span><span>{item.fault_type_name}</span></div>
                      <div className="content_c_02"><span>问题描述: </span><span>{item.description}</span></div>
                      <div className="content_c_03"><span><img width="18px" src={require('../../../../images/smartRepair/2.png')} alt="" /> </span><span>{(item.building_name ? item.building_name : '') + ' ' + (item.room_name ? item.room_name : '')}</span></div>
                      <div className="content_c_04">
                        <span><img width="18px" src={require('../../../../images/smartRepair/1.png')} alt="" /> <span>{item.user_name}</span> </span>
                        <span>报修时间: <span>{moment(item.create_time).format('YYYY-MM-DD ')}</span></span>
                      </div>
                    </div>
                  </div>
                })
              }
            </div>
          </Tabs>
          {/* *************************************** */}
        </div> : <div className="maintenanceTask_box">
          {/* 管理员角色及无角色 */}
          <Tabs tabs={tabs}
            initialPage={0}
            onChange={(tab, index) => {
              if (cookie.load('userData').dwdm == '999') {
                this.findAppAllOrder(index)
              } else {
                this.findOrderByUserId(index)
              }

            }}
          >

            <div className="maintenanceTask_content">
              {
                (dataList || []).map((item, index) => {
                  return <div key={index} onClick={() => { this.props.history.push(`/module/smartRepair/theDetails/${item.order_id}`) }} className="content">
                    <div className="content_title">
                      <div className="content_title_code">{item.order_id}</div>
                      <div className={style(0)}>
                        <span className="span_02">审批中</span>
                        <span className="span_03"><Icon type='right' /></span>
                      </div>
                    </div>

                    <div className='maintenanceTask_cont'>
                      <div className='maintenanceTask_cont_item'>
                        <div>类型</div>
                        <div>{item.fault_type_name}</div>
                      </div>
                      <div className='maintenanceTask_cont_item'>
                        <div>问题描述</div>
                        <div>{item.description}</div>
                      </div>
                      <div className='maintenanceTask_cont_item'>
                        <div>位置</div>
                        <div>{(item.building_name ? item.building_name : '') + ' ' + (item.room_name ? item.room_name : '')}</div>
                      </div>
                      <div className='maintenanceTask_cont_item'>
                        <div>报修人</div>
                        <div>{item.user_name}</div>
                      </div>

                    </div>
                    <div className="maintenanceTask_time">
                      {moment(item.create_time).format('YYYY-MM-DD ')}
                    </div>
                  </div>
                })
              }
            </div>
            {/* 已审批 */}
            <div className="maintenanceTask_content">
              {
                (dataList || []).map((item, index) => {
                  return <div key={index} onClick={() => { this.props.history.push(`/module/smartRepair/theDetails/${item.order_id}`) }} className="content">
                    <div className="content_title">
                      <div className="content_title_code">{item.order_id}</div>
                      <div className={style(2)}>
                        <span className="span_02">审批完成</span>
                        <span className="span_03"><Icon type='right' /></span>
                      </div>
                    </div>

                    <div className='maintenanceTask_cont'>
                      <div className='maintenanceTask_cont_item'>
                        <div>类型</div>
                        <div>{item.fault_type_name}</div>
                      </div>
                      <div className='maintenanceTask_cont_item'>
                        <div>问题描述</div>
                        <div>{item.description}</div>
                      </div>
                      <div className='maintenanceTask_cont_item'>
                        <div>位置</div>
                        <div>{(item.building_name ? item.building_name : '') + ' ' + (item.room_name ? item.room_name : '')}</div>
                      </div>
                      <div className='maintenanceTask_cont_item'>
                        <div>报修人</div>
                        <div>{item.user_name}</div>
                      </div>

                    </div>
                    <div className="maintenanceTask_time">
                      {moment(item.create_time).format('YYYY-MM-DD ')}
                    </div>
                  </div>
                })
              }
            </div>
            {/* 已完成 */}
            <div className="maintenanceTask_content">
              {
                (dataList || []).map((item, index) => {
                  return <div key={index} onClick={() => { this.props.history.push(`/module/smartRepair/theDetails/${item.order_id}`) }} className="content">
                    <div className="content_title">
                      <div className="content_title_code">{item.order_id}</div>
                      <div className={style(3)}>
                        <span className="span_02">审批拒绝</span>
                        <span className="span_03"><Icon type='right' /></span>
                      </div>
                    </div>

                    <div className='maintenanceTask_cont'>
                      <div className='maintenanceTask_cont_item'>
                        <div>类型</div>
                        <div>{item.fault_type_name}</div>
                      </div>
                      <div className='maintenanceTask_cont_item'>
                        <div>问题描述</div>
                        <div>{item.description}</div>
                      </div>
                      <div className='maintenanceTask_cont_item'>
                        <div>位置</div>
                        <div>{(item.building_name ? item.building_name : '') + ' ' + (item.room_name ? item.room_name : '')}</div>
                      </div>
                      <div className='maintenanceTask_cont_item'>
                        <div>报修人</div>
                        <div>{item.user_name}</div>
                      </div>

                    </div>
                    <div className="maintenanceTask_time">
                      {moment(item.create_time).format('YYYY-MM-DD ')}
                    </div>
                  </div>
                })
              }

            </div>
            <div className="maintenanceTask_content">
              {
                (dataList || []).map((item, index) => {
                  return <div key={index} onClick={() => { this.props.history.push(`/module/smartRepair/theDetails/${item.order_id}`) }} className="content">
                    <div className="content_title">
                      <div className="content_title_code">{item.order_id}</div>
                      <div className={style(2)}>
                        <span className="span_02">维修完成</span>
                        <span className="span_03"><Icon type='right' /></span>
                      </div>
                    </div>

                    <div className='maintenanceTask_cont'>
                      <div className='maintenanceTask_cont_item'>
                        <div>类型</div>
                        <div>{item.fault_type_name}</div>
                      </div>
                      <div className='maintenanceTask_cont_item'>
                        <div>问题描述</div>
                        <div>{item.description}</div>
                      </div>
                      <div className='maintenanceTask_cont_item'>
                        <div>位置</div>
                        <div>{(item.building_name ? item.building_name : '') + ' ' + (item.room_name ? item.room_name : '')}</div>
                      </div>
                      <div className='maintenanceTask_cont_item'>
                        <div>报修人</div>
                        <div>{item.user_name}</div>
                      </div>

                    </div>
                    <div className="maintenanceTask_time">
                      {moment(item.create_time).format('YYYY-MM-DD ')}
                    </div>
                  </div>
                })
              }
            </div>
            <div className="maintenanceTask_content">
              {
                (dataList || []).map((item, index) => {
                  return <div key={index} onClick={() => { this.props.history.push(`/module/smartRepair/theDetails/${item.order_id}`) }} className="content">
                    <div className="content_title">
                      <div className="content_title_code">{item.order_id}</div>
                      <div className={style(2)}>
                        <span className="span_02">工单完成</span>
                        {
                          item.order_jianyi ? <img className='span_img' alt='' src={require('../../../../images/smartRepair/star.png')} /> : ''
                        }
                        <span className="span_03"><Icon type='right' /></span>
                      </div>
                    </div>

                    <div className='maintenanceTask_cont'>
                      <div className='maintenanceTask_cont_item'>
                        <div>类型</div>
                        <div>{item.fault_type_name}</div>
                      </div>
                      <div className='maintenanceTask_cont_item'>
                        <div>问题描述</div>
                        <div>{item.description}</div>
                      </div>
                      <div className='maintenanceTask_cont_item'>
                        <div>位置</div>
                        <div>{(item.building_name ? item.building_name : '') + ' ' + (item.room_name ? item.room_name : '')}</div>
                      </div>
                      <div className='maintenanceTask_cont_item'>
                        <div>报修人</div>
                        <div>{item.user_name}</div>
                      </div>

                    </div>
                    <div className="maintenanceTask_time">
                      {moment(item.create_time).format('YYYY-MM-DD ')}
                    </div>
                  </div>
                })
              }
            </div>
          </Tabs>
        </div>
        }

        {(cookie.load('userData').dwdm == '999' ? dataList2 : dataList).length === 0 ? (<div className="title-bottom">--暂无工单--</div>) : ('')}
      </div>
    );
  }
}

export default createForm()(MaintenanceTask);