import moment from 'moment'
import React, { Component } from 'react'
import { findNewestMessage, findNewestMessageDevice, findSbNewestMessage, findBgNewestMessage, findSecondModuleInfo, findModuleInfo } from '../../api/api'
import Tabbar from '../../components/tabbar'

class Notify extends Component {

  state = {
    wt: 225,
    riskReport: {}, // 风险上报
    riskChange: {}, // 风险变更
    roomOrder: {}, // 实验室预约
    deviceOrder: {}, // 设备预约
    moduleData: [],
    sfjsyy: true, // 是否有实验室预约
  }

  componentDidMount = () => {
    window.document.title = '消息'
    this.setState({
      wt: document.body.clientWidth - 170
    })
    this.fetchModule()
    this.fetchModuleNum()
  }

  // 查询模块
  fetchModule = async () => {
    const res = await findModuleInfo()
    if (res.code === 20000) {
      const pd = res.data.find(x => x.module_url === '/module/appointment') ? true : false
      if (pd) {
        this.fetchRoomOrder()
      }
      this.setState({
        sfjsyy: pd
      })
    }
  }

  // 查询mobule_url数字模块
  fetchModuleNum = async () => {
    const res = await findSecondModuleInfo()
    if (res.code === 20000) {
      const arr = res.data
      if (arr.find(x => x.module_url === '1')) {
        this.fetchRiskReport()
      }
      if (arr.find(x => x.module_url === '2')) {
        this.fetchRiskChange()
      }
      // if (arr.find(x => x.module_url === '3')) {
      //   this.fetchRoomOrder()
      // }
      if (arr.find(x => x.module_url === '4')) {
        this.fetchDeviceOrder()
      }
      this.setState({
        moduleData: res.data
      })
    }
  }

  // 实验室预约消息通知
  fetchRoomOrder = async () => {
    const res = await findNewestMessage({ flag: 1 })
    if (res.code === 20000) {
      this.setState({
        roomOrder: res.data ? res.data : {}
      })
    }
  }

  // 设备预约消息通知
  fetchDeviceOrder = async () => {
    const res = await findNewestMessageDevice({ flag: 1 })
    if (res.code === 20000) {
      this.setState({
        deviceOrder: res.data ? res.data : {}
      })
    }
  }

  // 风险上报消息通知
  fetchRiskReport = async () => {
    const res = await findSbNewestMessage({ flag: 1 })
    if (res.code === 20000) {
      this.setState({
        riskReport: res.data ? res.data : {}
      })
    }
  }

  // 查询风险变更消息通知
  fetchRiskChange = async () => {
    const res = await findBgNewestMessage({ flag: 1 })
    if (res.code === 20000) {
      this.setState({
        riskChange: res.data ? res.data : {}
      })
    }
  }

  render() {
    const { wt, roomOrder, deviceOrder, riskReport, riskChange, moduleData, sfjsyy } = this.state
    return (
      <div>
        <div className='notify'>
          {!moduleData.filter(x => x.module_url !== '10' && x.module_url !== '11').length && (!sfjsyy) && <div className='not-yet'>--暂无数据--</div>}
          {
            moduleData.find(x => x.module_url === '1' || x.module_url === '2') ? <div className='notify_list'>
              <div className='notify_list_title'>风险管控</div>
              <div className='notify_list_con'>
                {
                  moduleData.find(x => x.module_url === '1') ? <div className='notify_list_con_list' onClick={() => this.props.history.push('/notify/riskReport')}>
                    <div className='notify_list_con_list_left'>
                      <img alt='' src={require('../../images/new/01.png')} />
                      <div className='notify_list_con_list_left_con'>
                        <div className='notify_list_con_list_name'>风险上报</div>
                        <div className='notify_list_con_list_desc' style={{ width: `${wt}px` }}>{riskReport.message_content ? riskReport.message_content : '暂无风险上报'}</div>
                      </div>
                    </div>
                    <div className='notify_list_con_list_right'>
                      <div className='notify_list_con_list_time'>{riskReport.create_time ? moment(riskReport.create_time).format('MM月DD日') : moment(new Date()).format('MM月DD日')}</div>
                      {
                        riskReport.totalNum ? <div className='notify_list_con_list_num'>{riskReport.totalNum}</div> : <div style={{ height: 15 }} />
                      }
                    </div>
                  </div> : ''
                }
                {
                  moduleData.find(x => x.module_url === '2') ? <div className='notify_list_con_list' onClick={() => this.props.history.push('/notify/riskChange')}>
                    <div className='notify_list_con_list_left'>
                      <img alt='' src={require('../../images/new/02.png')} />
                      <div className='notify_list_con_list_left_con'>
                        <div className='notify_list_con_list_name'>风险变更</div>
                        <div className='notify_list_con_list_desc' style={{ width: `${wt}px` }}>{riskChange.message_content ? riskChange.message_content : '暂无风险变更'}</div>
                      </div>
                    </div>
                    <div className='notify_list_con_list_right'>
                      <div className='notify_list_con_list_time'>{riskChange.create_time ? moment(riskChange.create_time).format('MM月DD日') : moment(new Date()).format('MM月DD日')}</div>
                      <div style={{ height: 15 }} />
                      {
                        riskChange.totalNum ? <div className='notify_list_con_list_num'>{riskChange.totalNum}</div> : <div style={{ height: 15 }} />
                      }
                    </div>
                  </div> : ''
                }
              </div>
            </div> : ''
          }

          {
            moduleData.find(x => x.module_url === '3' || x.module_url === '4') || sfjsyy ? <div className='notify_list'>
              <div className='notify_list_title'>预约管理</div>
              <div className='notify_list_con'>
                {
                  sfjsyy ? <div className='notify_list_con_list' onClick={() => this.props.history.push('/notify/roomOrder')}>
                    <div className='notify_list_con_list_left'>
                      <img alt='' src={require('../../images/new/03.png')} />
                      <div className='notify_list_con_list_left_con'>
                        <div className='notify_list_con_list_name'>实验室预约</div>
                        <div className='notify_list_con_list_desc' style={{ width: `${wt}px` }}>{roomOrder.message_content ? roomOrder.message_content : '暂无预约信息'}</div>
                      </div>
                    </div>
                    <div className='notify_list_con_list_right'>
                      <div className='notify_list_con_list_time'>{roomOrder.create_time ? moment(roomOrder.create_time).format('MM月DD日') : moment(new Date()).format('MM月DD日')}</div>
                      {
                        roomOrder.totalNum ? <div className='notify_list_con_list_num'>{roomOrder.totalNum}</div> : <div style={{ height: 15 }} />
                      }
                    </div>
                  </div> : ''
                }
                {
                  moduleData.find(x => x.module_url === '4') ? <div className='notify_list_con_list' onClick={() => this.props.history.push('/notify/deviceOrder')}>
                    <div className='notify_list_con_list_left'>
                      <img alt='' src={require('../../images/new/04.png')} />
                      <div className='notify_list_con_list_left_con'>
                        <div className='notify_list_con_list_name'>设备预约</div>
                        <div className='notify_list_con_list_desc' style={{ width: `${wt}px` }}>{deviceOrder.message_content ? deviceOrder.message_content : '暂无预约信息'}</div>
                      </div>
                    </div>
                    <div className='notify_list_con_list_right'>
                      <div className='notify_list_con_list_time'>{deviceOrder.create_time ? moment(deviceOrder.create_time).format('MM月DD日') : moment(new Date()).format('MM月DD日')}</div>
                      {
                        deviceOrder.totalNum ? <div className='notify_list_con_list_num'>{deviceOrder.totalNum}</div> : <div style={{ height: 15 }} />
                      }
                    </div>
                  </div> : ''
                }
              </div>
            </div> : ''
          }
        </div>
        { /* 标签栏 */}
        <Tabbar {...this.props} />
      </div>
    )
  }
}

export default Notify