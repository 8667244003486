import React from 'react'
import { Route } from 'react-router-dom'
import cookie from 'react-cookies'
// 班牌绑定房间
import Binding from '../page/binding/index'
// 房间信息
import Lab from '../page/binding/lab'
// 网页扫码登录
import Webs from '../page/webs/index'
// 首页
import Home from '../page/home/index'
import Notice from '../page/home/notice'
import NoticeDtail from '../page/home/notice_detail'
import Safety from '../page/home/safety'
import Notify from '../page/home/notify'
import RiskReport from '../page/home/risk_report'
import RiskChange from '../page/home/risk_change'
import RoomOrder from '../page/home/room_order'
import DeviceOrder from '../page/home/device_order'
// 实名认证
import Attestation from '../page/attestation/index'
// 工作台
import Workbench from '../page/module/workbench/index'
// 智能门锁
import DoorLock from '../page/module/door_lock/door_lock'
import Authorization from '../page/module/door_lock/temporary_authorization'
import LinePassword from '../page/module/door_lock/line_password'
import OpenLock from '../page/module/door_lock/open_lock'
import Batch from '../page/module/door_lock/batch'
import BatchRecord from '../page/module/door_lock/batch_record'
import LockRecord from '../page/module/door_lock/lock_record'
import LockDetails from '../page/module/door_lock/details'
// 预约实验室
import Appointment from '../page/module/appointment/appointment'
import AppointmentRecord from '../page/module/appointment/appointment_record'
import AppointmentDetail from '../page/module/appointment/appointment_detail'
// 审批中心
import Approve from '../page/module/approve/index'
import ApproveList from '../page/module/approve/approve'
import ApproveDetail from '../page/module/approve/approve_detail'
import ApproveOrderDetail from '../page/module/approve/order_detail'
import DeviceApprove from '../page/module/approve/device_detail'
import ApprovePatrolDetail from '../page/module/approve/patrol_detail'
// 智慧空调
import Aircondition from '../page/module/aircondition/aircondition'
import AirconditionDetail from '../page/module/aircondition/aircondition_detail'
import TimingList from '../page/module/aircondition/timing_list' //定时列表
import OperationRecords from '../page/module/aircondition/operation_records' //操作记录
import TimingConfiguration from '../page/module/aircondition/timing_configuration' //定时配置
// 智慧照明
import Lighting from '../page/module/lighting/lighting'
import LightSwitch from '../page/module/lighting/light_switch'
import LightOperation from '../page/module/lighting/light_operation'
// 环境监测
import Environment from '../page/module/environment/environment'
import EnvironmentAlarm from '../page/module/environment/environment_alarm'
import EnvironmentDetail from '../page/module/environment/environment_detail'
// 智慧用电
import Electricity from '../page/module/electricity/electricity'
import AirSwitch from '../page/module/electricity/air_switch'
import TimingConfig from '../page/module/electricity/timing_config'
import OperationLog from '../page/module/electricity/operation_log'
import AlarmData from '../page/module/electricity/alarm_data'
// 智能终端
import Controller from '../page/module/controller/controller'
import ControllerSwitch from '../page/module/controller/controller_switch'
import ControllerOperation from '../page/module/controller/controller_operation'
// 智慧窗帘
import Curtain from '../page/module/curtain/curtain'
import CurtainOperation from '../page/module/curtain/curtain_operation'
import CurtainDetail from '../page/module/curtain/curtain_detail'

// 快速报修
import Repair from '../page/module/repair/index'
import Order from '../page/module/order/index'
import OrderDetails from '../page/module/order/details'
import OrderEvaluate from '../page/module/order/evaluate'

// 巡检
import Patrol from '../page/module/patrol/index'
import Periodical from '../page/module/patrol/periodical'
import PeriodicalSchool from '../page/module/patrol/school'
import PeriodicalCourtyard from '../page/module/patrol/courtyard'
import Task from '../page/module/patrol/task'
import CourtyardTask from '../page/module/patrol/courtyard_task'
import PeriodicalTask from '../page/module/patrol/periodical_task'
import CreatePatrol from '../page/module/patrol/create_patrol'
import CreateCourtyard from '../page/module/patrol/create_courtyard'
import CreatePeriodical from '../page/module/patrol/create_periodical'
import PatrolDetail from '../page/module/patrol/patrol_detail'
import HiddenDanger from '../page/module/patrol/hidden_danger'
import SchoolDanger from '../page/module/patrol/school_danger'
import RandomDanger from '../page/module/patrol/random_danger'
import CourtyardDanger from '../page/module/patrol/courtyard_danger'
import PatrolStatistics from '../page/module/patrol/statistics'
import HiddenHandle from '../page/module/patrol/hidden_handle'
import HiddenDetail from '../page/module/patrol/hidden_detail'
import RandomDangerDetail from '../page/module/patrol/random_danger_detail'
import RandomDangerHandle from '../page/module/patrol/random_danger_handle'
import PatrolReport from '../page/module/patrol/report'
import CreateReport from '../page/module/patrol/create_report'
import ReportDetail from '../page/module/patrol/report_detail'
import SpecialList from '../page/module/patrol/special_list'
import SpecialPatrol from '../page/module/patrol/special_patrol'
import SpecialState from '../page/module/patrol/special_state'
import ReportState from '../page/module/patrol/report_state'
import RoomReport from '../page/module/patrol/room_report'
import DailyDanger from '../page/module/patrol/daily_danger'
import CheckAccept from '../page/module/patrol/check_accept'
import PatrolStat from '../page/module/patrol/stat'
import ConfirmDangers from '../page/module/patrol/confirm_dangers'
import MyCheck from '../page/module/patrol/my_check'
import MyCheckDanger from '../page/module/patrol/my_check_danger'
import CreateMyCheck from '../page/module/patrol/create_my_check'
import MyCheckDetail from '../page/module/patrol/my_check_detail'
import CheckDangerDetail from '../page/module/patrol/check_danger_detail'
import MyCheckAccept from '../page/module/patrol/my_check_accept'
import AcceptList from '../page/module/patrol/accept_list'
import AcceptDetail from '../page/module/patrol/accept_detail'
import Briefing from '../page/module/patrol/briefing'
import CreateBriefing from '../page/module/patrol/create_briefing'
import BriefingDetail from '../page/module/patrol/briefing_detail'
import BriefingDanger from '../page/module/patrol/briefing_danger'
import BriefingDangerDetail from '../page/module/patrol/briefing_danger_detail'
import BriefingHiddenDetail from '../page/module/patrol/briefing_hidden_detail'
import BriefingCheckAccept from '../page/module/patrol/briefing_check_accept'
import BriefingAcceptDetail from '../page/module/patrol/briefing_accept_detail'

// 房间管理
import RoomManager from '../page/module/room_manager/index'
import Room from '../page/module/room_manager/room_manager'
import RoomLighting from '../page/module/room_manager/room_lighting'
import RoomAir from '../page/module/room_manager/room_air'
import RoomAirSwitch from '../page/module/room_manager/room_air_switch'
import RoomEnvironment from '../page/module/room_manager/room_environment'
import RoomCurtain from '../page/module/room_manager/room_curtain'
import RoomController from '../page/module/room_manager/room_controller'
import RoomBanpai from '../page/module/room_manager/room_banpai'
import RoomLock from '../page/module/room_manager/room_lock'
//测试视频页面
import VideoCom from '../page/module/room_manager/video'

import My from '../page/my/index'
import MyInfo from '../page/my/myInfo'
import MyRiskReport from '../page/my/risk_report'
import MyRiskReportDetail from '../page/my/risk_report_detail'

// 设备预约
import DeviceAppo from '../page/module/device_appointment/device_appo'
import AppoDetail from '../page/module/device_appointment/appo_detail'
import DeviceDetail from '../page/module/device_appointment/device_detail'
import DeviceRecord from '../page/module/device_appointment/device_record'
import DeviceRecordDetail from '../page/module/device_appointment/device_record_detail'

// 信息采集
import Gather from '../page/module/gather/index'
import QrImage from '../page/module/gather/qrImage'
import Cropping from '../page/module/gather/cropping'

// 班牌管理
import Banpai from '../page/module/banpai/index'
import BanpaiDetail from '../page/module/banpai/detail'
import OpenConfig from '../page/module/banpai/open_config'

// 智慧报修
import SmartRepair from '../page/module/smart_repair/home/index.jsx'
import FillFormServer from '../page/module/smart_repair/repairService/fillFormServer.js'
import MaintenanceTask from '../page/module/smart_repair/maintenanceTask/index.jsx'
import DistributeWorkOrders from '../page/module/smart_repair/DistributeWorkOrders/index'
import Details from '../page/module/smart_repair/maintenanceTask/theDetails.jsx'
import TheDetails3 from '../page/module/smart_repair/maintenanceTask/theDetails3'
import WorkOrders from '../page/module/smart_repair/DistributeWorkOrders/workOrders'
import WorkTracking from '../page/module/smart_repair/WorkTracking/index'
import WorkTrackingOrders from '../page/module/smart_repair/WorkTracking/workOrders'
import Statistics from '../page/module/smart_repair/statistics/index'
import Approval from '../page/module/smart_repair/approval/index.jsx'
import ApprovalDetail from '../page/module/smart_repair/approval/approvalDetail'
import ApprovalDetails from '../page/module/smart_repair/approval/approvalDetails.jsx'
import ApprovalDetails2 from '../page/module/smart_repair/approval/approvalDetails2.jsx'
// 使用管理
import UseRecord from '../page/module/useManager/use_record'
import UseStatistics from '../page/module/useManager/use_statistics'
import UseState from '../page/module/useManager/use_state'
//安全教育
import Safty from '../page/module/safty'
import SaftyStudy from '../page/module/safty/study'
import SaftyPractice from '../page/module/safty/practice'
import SaftyExam from '../page/module/safty/exam'
import ParcticeOnline from '../page/module/safty/practice_online'
import ExamReport from '../page/module/safty/exam_report'
import OnLineSingle from '../page/module/safty/on_line_single' // 考试
import SaftyStudyOnline from '../page/module/safty/study_online' // 学习
import SaftyStudyOnlineDetail from '../page/module/safty/study_online_detail'
import SaftyCertificate from '../page/module/safty/certificate'
import ExamAnalysis from '../page/module/safty/exam_analysis'
import Grades from '../page/module/safty/grades'
import GradesDetail from '../page/module/safty/grades_detail'
import SaftyState from '../page/module/safty/state'
import LuSign from '../page/module/safty/lu_sign'
import StudyPaihangbang from '../page/module/safty/study_jf_paihang'
import JiaoYuPaihangbang from '../page/module/safty/jiaoyu_jf_paihang'
import JFDetail from '../page/module/safty/jf_detail'
import JFRule from '../page/module/safty/jf_rule'



import CgyyHome from '../page/module/cgyy/index'
import CgyySubmit from '../page/module/cgyy/submit'
import CgyySuccess from '../page/module/cgyy/success'
import CgyyMy from '../page/module/cgyy/my'
import CgyyApproval from '../page/module/cgyy/approval'
import CgyyMyView from '../page/module/cgyy/my_view'
import CgyyApprovalView from '../page/module/cgyy/approval_view'




// 实验资源
import Resources from '../page/module/resources/index'
import ResourceWj from '../page/module/resources/resource_wj'
import ResourcesDetail from '../page/module/resources/resource_detail'
// 实验报告
import TestReport from '../page/module/test_report/index'
import TestMb from '../page/module/test_report/test_mb'
import TestBg from '../page/module/test_report/test_bg'
import TestBan from '../page/module/test_report/test_ban'
import TestTerDetails from '../page/module/test_report/test_ter_details'
import TestState from '../page/module/test_report/test_state'

// 危化品
// 申购
import Chemicals from '../page/module/chemicals/index'
import SubscriptionList from '../page/module/chemicals/subscription_manager/list'
import SubscriptionAdd from '../page/module/chemicals/subscription_manager/add'
import SubscriptionRecord from '../page/module/chemicals/subscription_manager/record'
// 审批
import ChemicalsApproveList from '../page/module/chemicals/approve_manager/list'
import ChemicalsApproveSubRecord from '../page/module/chemicals/approve_manager/subRecord'
import ChemicalsApproveWarRecord from '../page/module/chemicals/approve_manager/warRecord'
import ChemicalsApproveUseRecord from '../page/module/chemicals/approve_manager/useRecord'
import ChemicalsApproveRepayRecord from '../page/module/chemicals/approve_manager/repayRecord'
//入库
import WarehousingList from '../page/module/chemicals/warehousing_manager/list'
import WarehousingAdd from '../page/module/chemicals/warehousing_manager/add'
import WarehousingOrderAdd from '../page/module/chemicals/warehousing_manager/order_add'
import WarehousingRecord from '../page/module/chemicals/warehousing_manager/record'
// 领用
import UseList from '../page/module/chemicals/use_manager/list'
import UseManagerAdd from '../page/module/chemicals/use_manager/add'
import UseManagerRecord from '../page/module/chemicals/use_manager/record'
// 出库
import OutboundList from '../page/module/chemicals/outbound_manager/list'
import OutboundAdd from '../page/module/chemicals/outbound_manager/add'
import OutboundRecord from '../page/module/chemicals/outbound_manager/record'
import OutboundQradd from '../page/module/chemicals/outbound_manager/qr_add'
// 归还
import RepayList from '../page/module/chemicals/repay_manager/list'
import RepayAdd from '../page/module/chemicals/repay_manager/add'
import RepayRecord from '../page/module/chemicals/repay_manager/record'
// 库存
import InventoryList from '../page/module/chemicals/inventory_manager/list'
import InventoryRecord from '../page/module/chemicals/inventory_manager/record'
// 查询统计
import QueryStatList from '../page/module/chemicals/query_stat/list'
// 使用
import ApplyList from '../page/module/chemicals/apply_manager/list'
import ApplyAdd from '../page/module/chemicals/apply_manager/add'
import ApplyRecord from '../page/module/chemicals/apply_manager/record'
// 报废
import ScrapList from '../page/module/chemicals/scrap_manager/list'
import ScrapAdd from '../page/module/chemicals/scrap_manager/add'
import ScrapRecord from '../page/module/chemicals/scrap_manager/record'
import QrcodeSign from '../page/module/chemicals/qrcode_sign/index'
// 盘点
import StocktakingAdd from '../page/module/chemicals/stocktaking_manager/add'
import StocktakingList from '../page/module/chemicals/stocktaking_manager/list'
import StocktakingRecord from '../page/module/chemicals/stocktaking_manager/record'
// 轨迹
import TrackList from '../page/module/chemicals/track_manager/list'
import TrackRecord from '../page/module/chemicals/track_manager/record'

// 易耗品
// 申购
import SoftsChemicals from '../page/module/consumable/index'
import SoftsSubscriptionList from '../page/module/consumable/subscription_manager/list'
import SoftsSubscriptionAdd from '../page/module/consumable/subscription_manager/add'
import SoftsSubscriptionRecord from '../page/module/consumable/subscription_manager/record'
// 审批
import SoftsChemicalsApproveList from '../page/module/consumable/approve_manager/list'
import SoftsChemicalsApproveSubRecord from '../page/module/consumable/approve_manager/subRecord'
import SoftsChemicalsApproveWarRecord from '../page/module/consumable/approve_manager/warRecord'
import SoftsChemicalsApproveUseRecord from '../page/module/consumable/approve_manager/useRecord'
import SoftsChemicalsApproveRepayRecord from '../page/module/consumable/approve_manager/repayRecord'
//入库
import SoftsWarehousingList from '../page/module/consumable/warehousing_manager/list'
import SoftsWarehousingAdd from '../page/module/consumable/warehousing_manager/add'
import SoftsWarehousingOrderAdd from '../page/module/consumable/warehousing_manager/order_add'
import SoftsWarehousingRecord from '../page/module/consumable/warehousing_manager/record'
// 领用
import SoftsUseList from '../page/module/consumable/use_manager/list'
import SoftsUseManagerAdd from '../page/module/consumable/use_manager/add'
import SoftsUseManagerRecord from '../page/module/consumable/use_manager/record'
// 出库
import SoftsOutboundList from '../page/module/consumable/outbound_manager/list'
import SoftsOutboundAdd from '../page/module/consumable/outbound_manager/add'
import SoftsOutboundRecord from '../page/module/consumable/outbound_manager/record'
// 归还
import SoftsRepayList from '../page/module/consumable/repay_manager/list'
import SoftsRepayAdd from '../page/module/consumable/repay_manager/add'
import SoftsRepayRecord from '../page/module/consumable/repay_manager/record'
// 库存
import SoftsInventoryList from '../page/module/consumable/inventory_manager/list'
import SoftsInventoryRecord from '../page/module/consumable/inventory_manager/record'
// 查询统计
import SoftsQueryStatList from '../page/module/consumable/query_stat/list'
// 使用
import SoftsApplyList from '../page/module/consumable/apply_manager/list'
import SoftsApplyAdd from '../page/module/consumable/apply_manager/add'
import SoftsApplyRecord from '../page/module/consumable/apply_manager/record'
// 报废
import SoftsScrapList from '../page/module/consumable/scrap_manager/list'
import SoftsScrapAdd from '../page/module/consumable/scrap_manager/add'
import SoftsScrapRecord from '../page/module/consumable/scrap_manager/record'



// 仪器使用
import UseDevice from '../page/module/use_device/index'
import UseDeviceAdd from '../page/module/use_device/useAdd'
import UseDeviceList from '../page/module/use_device/useList'
import UseDeviceFeedback from '../page/module/use_device/useFeedback'
import UseDeviceRecord from '../page/module/use_device/useRecord'

// 废弃物管理
import WasteManger from '../page/module/waste/index'
// 废弃物登记
import registrationList from '../page/module/waste/registration/list'
import registrationAdd from '../page/module/waste/registration/add'
import registrationRecord from '../page/module/waste/registration/record'
// 废弃物处理
import DisposalList from '../page/module/waste/disposal/list'
import DisPosalRecord from '../page/module/waste/disposal/record'
import DisPosalDetail from '../page/module/waste/disposal/detail'
// 废弃物审批
import WasteApprove from '../page/module/waste/approve/list'
import WasteApproveDetail from '../page/module/waste/approve/detail'


// 气体钢瓶
import GasCylinder from '../page/module/gas_cylinder/index'
// 钢瓶管理
import ManageList from '../page/module/gas_cylinder/manage/list'
import ManageAdd from '../page/module/gas_cylinder/manage/add'
import Managerecord from '../page/module/gas_cylinder/manage/record'
// 气体分类
import GasTypeList from '../page/module/gas_cylinder/gasType/list'
import GasTypeAdd from '../page/module/gas_cylinder/gasType/add'
// 气体信息
import GasList from '../page/module/gas_cylinder/gas/list'
import GasAdd from '../page/module/gas_cylinder/gas/add'
// 入库
import GasWarehousingList from '../page/module/gas_cylinder/warehousing/list'
import GasWarehousingAdd from '../page/module/gas_cylinder/warehousing/add'
import GasWarehousingRecord from '../page/module/gas_cylinder/warehousing/record'
// 领用
import GasUseList from '../page/module/gas_cylinder/use/list'
import GasUseAdd from '../page/module/gas_cylinder/use/add'
import GasUseRecord from '../page/module/gas_cylinder/use/record'
// 出库
import GasOutboundList from '../page/module/gas_cylinder/outbound/list'
import GasOutboundAdd from '../page/module/gas_cylinder/outbound/add'
import GasOutboundRecord from '../page/module/gas_cylinder/outbound/record'
// 归还
import GasReturnList from '../page/module/gas_cylinder/return/list'
import GasReturnAdd from '../page/module/gas_cylinder/return/add'
import GasReturnRecord from '../page/module/gas_cylinder/return/record'
// 使用
import GasUsageList from '../page/module/gas_cylinder/usage/list'
import GasUsageAdd from '../page/module/gas_cylinder/usage/add'
import GasUsageRecord from '../page/module/gas_cylinder/usage/record'
// 转移
import GasShaList from '../page/module/gas_cylinder/sharedTransfer/list'
import GasShaAdd from '../page/module/gas_cylinder/sharedTransfer/add'
import GasShaRecord from '../page/module/gas_cylinder/sharedTransfer/record'
// 共享
import GasAnalysisList from '../page/module/gas_cylinder/analysis/list'
import GasAnalysisAdd from '../page/module/gas_cylinder/analysis/add'
import GasAnalysisRecord from '../page/module/gas_cylinder/analysis/record'
// 审批
import GasApproveList from '../page/module/gas_cylinder/approve/list'
import GasApproveWarRecord from '../page/module/gas_cylinder/approve/warRecord'
import GasApproveUseRecord from '../page/module/gas_cylinder/approve/useRecord'
import GasApproveRepayRecord from '../page/module/gas_cylinder/approve/repayRecord'
//钢瓶统计
import GasStat from '../page/module/gas_cylinder/tj'





function RouteMap () {
  return (
    <div>
      {/* <Route exact path="/" component={Lab} /> */}
      <Route exact path="/" component={cookie.load('status') == 1 ? Home : Attestation} />
      {/* 班牌绑定房间 */}
      <Route exact path="/binding/:id" component={Binding} />
      {/* 房间信息 */}
      <Route exact path="/lab/:id" component={Lab} />
      {/* 网页扫码登录 */}
      <Route exact path="/web/:id" component={Webs} />
      {/* 首页 */}
      <Route exact path="/home" component={Home} />
      <Route exact path="/notice" component={Notice} />
      <Route exact path="/notice/detail/:id" component={NoticeDtail} />
      <Route exact path="/safety" component={Safety} />
      <Route exact path="/notify" component={Notify} />
      <Route exact path="/notify/riskReport" component={RiskReport} />
      <Route exact path="/notify/riskChange" component={RiskChange} />
      <Route exact path="/notify/roomOrder" component={RoomOrder} />
      <Route exact path="/notify/deviceOrder" component={DeviceOrder} />
      {/* 实名认证 */}
      <Route exact path="/attestation" component={Attestation} />
      {/* 工作台 */}
      <Route exact path="/module/workbench" component={Workbench} />
      {/* 智能门锁 */}
      <Route exact path="/module/doorLock" component={DoorLock} />
      <Route exact path="/module/temporaryAuthorization/:id" component={Authorization} />
      <Route exact path="/module/linePassword/:id" component={LinePassword} />
      <Route exact path="/module/openLock/:id" component={OpenLock} />
      <Route exact path="/module/batch" component={Batch} />
      <Route exact path="/module/batchRecord" component={BatchRecord} />
      <Route exact path="/module/lockRecord/:id" component={LockRecord} />
      <Route exact path="/module/lockDetails/:id" component={LockDetails} />
      {/* 预约实验室 */}
      <Route exact path="/module/appointment" component={Appointment} />
      <Route exact path="/module/appointmentRecord" component={AppointmentRecord} />
      <Route exact path="/module/appointmentRecord/detail/:id" component={AppointmentDetail} />
      {/* 审批中心 */}
      <Route exact path="/module/approveHome" component={Approve} />
      <Route exact path="/module/approve" component={ApproveList} />
      <Route exact path="/module/approve/detail/:id/:typeId" component={ApproveDetail} />
      <Route exact path="/module/approve/orderDetail/:id/" component={ApproveOrderDetail} />
      <Route exact path="/module/approve/deviceApprove/:id/" component={DeviceApprove} />
      <Route exact path="/module/approve/patrolDetail/:id/" component={ApprovePatrolDetail} />
      {/* 智慧空调 */}
      <Route exact path="/module/aircondition" component={Aircondition} />
      <Route exact path="/module/aircondition/detail/:id" component={AirconditionDetail} />
      <Route exact path="/module/aircondition/timingList/:id" component={TimingList} />
      <Route exact path="/module/aircondition/operationRecords/:id" component={OperationRecords} />
      <Route exact path="/module/aircondition/timingConfiguration/:deviceId/:teskId" component={TimingConfiguration} />
      {/* 智慧照明 */}
      <Route exact path="/module/lighting" component={Lighting} />
      <Route exact path="/module/airSwitch/:id" component={LightSwitch} />
      <Route exact path="/module/airSwitch/lightOperation/:mac/:number" component={LightOperation} />
      {/* 环境检测 */}
      <Route exact path="/module/environment" component={Environment} />
      <Route exact path="/module/environment/environmentAlarm/:id" component={EnvironmentAlarm} />
      <Route exact path="/module/environment/detail" component={EnvironmentDetail} />
      {/* 智慧用电 */}
      <Route exact path="/module/electricity" component={Electricity} />
      <Route exact path="/module/electricity/airSwitch/:id" component={AirSwitch} />
      <Route exact path="/module/electricity/timingConfig/:id" component={TimingConfig} />
      <Route exact path="/module/electricity/operationLog/:id" component={OperationLog} />
      <Route exact path="/module/electricity/alarmData/:id" component={AlarmData} />
      {/* 智能终端 */}
      <Route exact path="/module/controller" component={Controller} />
      <Route exact path="/module/controllerSwitch/:id" component={ControllerSwitch} />
      <Route exact path="/module/controllerSwitch/controllerOperation/:mac/:number" component={ControllerOperation} />
      {/* 智慧窗帘 */}
      <Route exact path="/module/curtain" component={Curtain} />
      <Route exact path="/module/curtain/curtainOperation/:id" component={CurtainOperation} />
      <Route exact path="/module/curtain/detail/:id" component={CurtainDetail} />
      {/* 快速报修 */}
      <Route exact path="/module/repair" component={Repair} />
      {/* 工单池 */}
      <Route exact path="/module/order/:tabs_id/:tabs_type_id" component={Order} />
      <Route exact path="/module/order/details/:id/:tabs_id/:tabs_type_id" component={OrderDetails} />
      <Route exact path="/module/order/evaluate/:id/:tabs_id/:tabs_type_id" component={OrderEvaluate} />
      {/* 巡检 */}
      <Route exact path="/module/patrol" component={Patrol} />
      <Route exact path="/module/patrol/periodical" component={Periodical} />
      <Route exact path="/module/patrol/school" component={PeriodicalSchool} />
      <Route exact path="/module/patrol/courtyard" component={PeriodicalCourtyard} />
      <Route exact path="/module/patrol/task/:id" component={Task} />
      <Route exact path="/module/patrol/courtyard_task/:id" component={CourtyardTask} />
      <Route exact path="/module/patrol/periodical_task/:id" component={PeriodicalTask} />
      <Route exact path="/module/patrol/create_patrol/:id" component={CreatePatrol} />
      <Route exact path="/module/patrol/create_courtyard/:id" component={CreateCourtyard} />
      <Route exact path="/module/patrol/create_periodical/:id" component={CreatePeriodical} />
      <Route exact path="/module/patrol/patrol_detail/:id" component={PatrolDetail} />
      <Route exact path="/module/patrol/hidden_danger" component={HiddenDanger} />
      <Route exact path="/module/patrol/school_danger" component={SchoolDanger} />
      <Route exact path="/module/patrol/random_danger" component={RandomDanger} />
      <Route exact path="/module/patrol/courtyard_danger" component={CourtyardDanger} />
      <Route exact path="/module/patrol/statistics/:id" component={PatrolStatistics} />
      <Route exact path="/module/patrol/hidden_handle/:id" component={HiddenHandle} />
      <Route exact path="/module/patrol/hidden_detail/:id" component={HiddenDetail} />
      <Route exact path="/module/patrol/random_danger_detail/:id" component={RandomDangerDetail} />
      <Route exact path="/module/patrol/random_danger_handle/:id" component={RandomDangerHandle} />
      <Route exact path="/module/patrol/report" component={PatrolReport} />
      <Route exact path="/module/patrol/create_report/:id" component={CreateReport} />
      <Route exact path="/module/patrol/report_detail/:id" component={ReportDetail} />
      <Route exact path="/module/patrol/accept_list" component={AcceptList} />

      <Route exact path="/module/patrol/special" component={SpecialList} />
      <Route exact path="/module/patrol/specialPatrol" component={SpecialPatrol} />
      <Route exact path="/module/patrol/specialState" component={SpecialState} />
      <Route exact path="/module/patrol/reportState" component={ReportState} />
      <Route exact path="/module/patrol/roomReport/:id" component={RoomReport} />
      <Route exact path="/module/patrol/daily_danger" component={DailyDanger} />
      <Route exact path="/module/patrol/check_accept/:id" component={CheckAccept} />
      <Route exact path="/module/patrol/stat" component={PatrolStat} />
      <Route exact path="/module/patrol/confirm_dangers/:id" component={ConfirmDangers} />
      <Route exact path="/module/patrol/accept_detail/:id" component={AcceptDetail} />
      <Route exact path="/module/patrol/briefing" component={Briefing} />
      <Route exact path="/module/patrol/createBriefing/:id" component={CreateBriefing} />
      <Route exact path="/module/patrol/briefingDetail/:id" component={BriefingDetail} />
      <Route exact path="/module/patrol/briefing_danger" component={BriefingDanger} />
      <Route exact path="/module/patrol/briefing_danger_detail/:id" component={BriefingDangerDetail} />
      <Route exact path="/module/patrol/briefing_hidden_detail/:id" component={BriefingHiddenDetail} />
      <Route exact path="/module/patrol/briefing_check_accept/:id" component={BriefingCheckAccept} />
      <Route exact path="/module/patrol/briefing_accept_detail/:id" component={BriefingAcceptDetail} />

      {/* 自查 */}
      <Route exact path="/module/patrol/my_check" component={MyCheck} />
      <Route exact path="/module/patrol/my_check_danger" component={MyCheckDanger} />
      <Route exact path="/module/patrol/create_my_check/:id" component={CreateMyCheck} />
      <Route exact path="/module/patrol/my_check_detail/:id" component={MyCheckDetail} />
      <Route exact path="/module/patrol/check_danger_detail/:id" component={CheckDangerDetail} />
      <Route exact path="/module/patrol/my_check_accept/:id" component={MyCheckAccept} />

      {/* 房间管理 */}
      <Route exact path="/module/roomManager" component={RoomManager} />
      <Route exact path="/module/room" component={Room} />
      <Route exact path="/module/roomLighting/:id" component={RoomLighting} />
      <Route exact path="/module/roomAir/:id" component={RoomAir} />
      <Route exact path="/module/roomAirSwitch/:id" component={RoomAirSwitch} />
      <Route exact path="/module/roomEnvironment/:id" component={RoomEnvironment} />
      <Route exact path="/module/roomCurtain/:id" component={RoomCurtain} />
      <Route exact path="/module/roomController/:id" component={RoomController} />
      <Route exact path="/module/roomBanpai/:id" component={RoomBanpai} />
      <Route exact path="/module/roomLock/:id" component={RoomLock} />
      <Route exact path="/module/roomMonitor/:id" component={VideoCom} />

      <Route exact path="/my" component={My} />
      <Route exact path="/myInfo" component={MyInfo} />
      <Route exact path="/my/riskReport" component={MyRiskReport} />
      <Route exact path="/my/riskReport/detail/:id" component={MyRiskReportDetail} />

      {/* 设备预约 */}
      <Route exact path="/module/deviceAppointment" component={DeviceAppo} />
      <Route exact path="/module/appoDetail/:id" component={AppoDetail} />
      <Route exact path="/module/deviceDetail/:id" component={DeviceDetail} />
      <Route exact path="/module/deviceRecord" component={DeviceRecord} />
      <Route exact path="/module/deviceRecord/detail/:id" component={DeviceRecordDetail} />

      {/* 信息采集 */}
      <Route exact path="/module/gather" component={Gather} />
      <Route exact path="/module/gather/qrImage" component={QrImage} />
      <Route exact path="/module/gather/cropping" component={Cropping} />

      {/* 班牌管理  */}
      <Route exact path="/module/banpai" component={Banpai} />
      <Route exact path="/module/banpai/detail/:id" component={BanpaiDetail} />
      <Route exact path="/module/banpai/openConfig/:id" component={OpenConfig} />

      {/* 智慧报修 */}
      <Route exact path="/module/smartRepair" component={SmartRepair} />
      <Route exact path="/module/smartRepair/fillFormServer" component={FillFormServer} />
      <Route exact path="/module/smartRepair/maintenanceTask" component={MaintenanceTask} />
      <Route exact path="/module/smartRepair/WorkOrdersList" component={DistributeWorkOrders} />
      <Route exact path="/module/smartRepair/theDetails/:id" component={Details} />
      <Route exact path="/module/smartRepair/theDetails3/:id" component={TheDetails3} />
      <Route exact path="/module/smartRepair/WorkOrdersDetail/:id" component={WorkOrders} />
      <Route exact path="/module/smartRepair/workTracking" component={WorkTracking} />
      <Route exact path="/module/smartRepair/workTrackingOrders/:id" component={WorkTrackingOrders} />
      <Route exact path="/module/smartRepair/statistics" component={Statistics} />
      <Route exact path="/module/smartRepair/approval" component={Approval} />
      <Route exact path="/module/smartRepair/approvalDetail/:id" component={ApprovalDetail} />
      <Route exact path="/module/smartRepair/approvalDetails/:id" component={ApprovalDetails} />
      <Route exact path="/module/smartRepair/approvalDetails2/:id" component={ApprovalDetails2} />

      {/* 使用管理 */}
      <Route exact path="/module/useRecord" component={UseRecord} />
      <Route exact path="/module/useStatistics" component={UseStatistics} />
      <Route exact path="/module/useState" component={UseState} />

      {/* 安全教育 */}
      <Route exact path="/module/safty" component={Safty} />
      <Route exact path="/module/safty/study" component={SaftyStudy} />
      <Route exact path="/module/safty/practice" component={SaftyPractice} />
      <Route exact path="/module/safty/exam" component={SaftyExam} />
      <Route exact path="/module/safty/practice/online/:categoryIds/:flag/:num/:radioNum/:multiNum/:estimateNum" component={ParcticeOnline} />
      <Route exact path="/module/safty/exam/report/:paperId/:recordId" component={ExamReport} />
      <Route exact path="/module/safty/single/:id" component={OnLineSingle} />
      <Route exact path="/module/safty/study/online/:typeId" component={SaftyStudyOnline} />
      <Route exact path="/module/safty/study/online/:typeId/:zjId" component={SaftyStudyOnlineDetail} />
      <Route exact path="/module/safty/certificate" component={SaftyCertificate} />
      <Route exact path="/module/safty/exam/analysis/:recordId/:flag/:name" component={ExamAnalysis} />
      <Route exact path="/module/safty/grades" component={Grades} />
      <Route exact path="/module/safty/grades/detail/:id" component={GradesDetail} />
      <Route exact path="/module/safty/state" component={SaftyState} />
      <Route exact path="/module/safty/luSign/:id" component={LuSign} />
      <Route exact path="/module/safty/study/paihangbang" component={StudyPaihangbang} />
      <Route exact path="/module/safty/jiaoyupaihangbang" component={JiaoYuPaihangbang} />
      <Route exact path="/module/safty/jfdetail" component={JFDetail} />
      <Route exact path="/module/safty/jfrule" component={JFRule} />


      {/* 场馆预约 */}
      <Route exact path="/module/cgyy" component={CgyyHome} />
      <Route exact path="/module/cgyy/submit/:date/:time/:yysjid" component={CgyySubmit} />
      <Route exact path="/module/cgyy/success/:date/:time" component={CgyySuccess} />
      <Route exact path="/module/cgyy/my" component={CgyyMy} />
      <Route exact path="/module/cgyy/my/view/:id" component={CgyyMyView} />
      <Route exact path="/module/cgyy/approval" component={CgyyApproval} />
      <Route exact path="/module/cgyy/approval/view/:id" component={CgyyApprovalView} />



      {/* 实验资源 */}
      <Route exact path="/module/resources" component={Resources} />
      <Route exact path="/module/resourceWj" component={ResourceWj} />
      <Route exact path="/module/resources/detail/:id" component={ResourcesDetail} />
      {/* 实验报告 */}
      <Route exact path="/module/testReport" component={TestReport} />
      <Route exact path="/module/testMb/:id" component={TestMb} />
      <Route exact path="/module/testBg/:id" component={TestBg} />
      <Route exact path="/module/testBan/:templateId/:sta_id" component={TestBan} />
      <Route exact path="/module/testTerDetails/:id" component={TestTerDetails} />
      <Route exact path="/module/testState/:id" component={TestState} />


      {/* 危化品 */}
      {/* 申购 */}
      <Route exact path="/module/chemicals" component={Chemicals} />
      <Route exact path="/module/subscriptionManager/list" component={SubscriptionList} />
      <Route exact path="/module/subscriptionManager/add" component={SubscriptionAdd} />
      <Route exact path="/module/subscriptionManager/record/:id" component={SubscriptionRecord} />
      {/* 审批 */}
      <Route exact path="/module/approveManager/list" component={ChemicalsApproveList} />
      <Route exact path="/module/approveManager/subRecord/:id" component={ChemicalsApproveSubRecord} />
      <Route exact path="/module/approveManager/warRecord/:id" component={ChemicalsApproveWarRecord} />
      <Route exact path="/module/approveManager/useRecord/:id" component={ChemicalsApproveUseRecord} />
      <Route exact path="/module/approveManager/repayRecord/:id" component={ChemicalsApproveRepayRecord} />
      {/* 入库 */}
      <Route exact path="/module/warehousingManager/list" component={WarehousingList} />
      <Route exact path="/module/warehousingManager/add" component={WarehousingAdd} />
      <Route exact path="/module/warehousingManager/orderAdd" component={WarehousingOrderAdd} />
      <Route exact path="/module/warehousingManager/record/:id" component={WarehousingRecord} />
      {/* 领用 */}
      <Route exact path="/module/useManager/list" component={UseList} />
      <Route exact path="/module/useManager/add" component={UseManagerAdd} />
      <Route exact path="/module/useManager/record/:id" component={UseManagerRecord} />
      {/* 出库 */}
      <Route exact path="/module/outboundManager/list" component={OutboundList} />
      <Route exact path="/module/outboundManager/add/:id" component={OutboundAdd} />
      <Route exact path="/module/outboundManager/record/:id" component={OutboundRecord} />
      <Route exact path="/module/outboundManager/qrAdd/:id" component={OutboundQradd} />
      {/* 归还 */}
      <Route exact path="/module/repayManager/list" component={RepayList} />
      <Route exact path="/module/repayManager/add" component={RepayAdd} />
      <Route exact path="/module/repayManager/record/:id" component={RepayRecord} />
      {/* 库存 */}
      <Route exact path="/module/inventoryManager/list" component={InventoryList} />
      <Route exact path="/module/inventoryManager/record/:id" component={InventoryRecord} />
      {/* 查询统计 */}
      <Route exact path="/module/queryStat" component={QueryStatList} />
      {/* 使用 */}
      <Route exact path="/module/applyManager/list" component={ApplyList} />
      <Route exact path="/module/applyManager/add" component={ApplyAdd} />
      <Route exact path="/module/applyManager/record/:id" component={ApplyRecord} />
      {/* 报废 */}
      <Route exact path="/module/scrapManager/list" component={ScrapList} />
      <Route exact path="/module/scrapManager/add" component={ScrapAdd} />
      <Route exact path="/module/scrapManager/record/:id" component={ScrapRecord} />
      <Route exact path="/module/chemicals/qrcodeSign/:id" component={QrcodeSign} />
      {/* 盘点 */}
      <Route exact path="/module/stocktakingManager/add" component={StocktakingAdd} />
      <Route exact path="/module/stocktakingManager/list" component={StocktakingList} />
      <Route exact path="/module/stocktakingManager/record/:id" component={StocktakingRecord} />
      {/* 轨迹 */}
      <Route exact path="/module/trackManager/list" component={TrackList} />
      <Route exact path="/module/trackManager/record/:id" component={TrackRecord} />

      {/* 易耗品 */}
      {/* 申购 */}
      <Route exact path="/module/consumable" component={SoftsChemicals} />
      <Route exact path="/module/consumable/subscriptionManager/list" component={SoftsSubscriptionList} />
      <Route exact path="/module/consumable/subscriptionManager/add" component={SoftsSubscriptionAdd} />
      <Route exact path="/module/consumable/subscriptionManager/record/:id" component={SoftsSubscriptionRecord} />
      {/* 审批 */}
      <Route exact path="/module/consumable/approveManager/list" component={SoftsChemicalsApproveList} />
      <Route exact path="/module/consumable/approveManager/subRecord/:id" component={SoftsChemicalsApproveSubRecord} />
      <Route exact path="/module/consumable/approveManager/warRecord/:id" component={SoftsChemicalsApproveWarRecord} />
      <Route exact path="/module/consumable/approveManager/useRecord/:id" component={SoftsChemicalsApproveUseRecord} />
      <Route exact path="/module/consumable/approveManager/repayRecord/:id" component={SoftsChemicalsApproveRepayRecord} />
      {/* 入库 */}
      <Route exact path="/module/consumable/warehousingManager/list" component={SoftsWarehousingList} />
      <Route exact path="/module/consumable/warehousingManager/add" component={SoftsWarehousingAdd} />
      <Route exact path="/module/consumable/warehousingManager/orderAdd" component={SoftsWarehousingOrderAdd} />
      <Route exact path="/module/consumable/warehousingManager/record/:id" component={SoftsWarehousingRecord} />
      {/* 领用 */}
      <Route exact path="/module/consumable/useManager/list" component={SoftsUseList} />
      <Route exact path="/module/consumable/useManager/add" component={SoftsUseManagerAdd} />
      <Route exact path="/module/consumable/useManager/record/:id" component={SoftsUseManagerRecord} />
      {/* 出库 */}
      <Route exact path="/module/consumable/outboundManager/list" component={SoftsOutboundList} />
      <Route exact path="/module/consumable/outboundManager/add/:id" component={SoftsOutboundAdd} />
      <Route exact path="/module/consumable/outboundManager/record/:id" component={SoftsOutboundRecord} />
      {/* 归还 */}
      <Route exact path="/module/consumable/repayManager/list" component={SoftsRepayList} />
      <Route exact path="/module/consumable/repayManager/add" component={SoftsRepayAdd} />
      <Route exact path="/module/consumable/repayManager/record/:id" component={SoftsRepayRecord} />
      {/* 库存 */}
      <Route exact path="/module/consumable/inventoryManager/list" component={SoftsInventoryList} />
      <Route exact path="/module/consumable/inventoryManager/record/:id" component={SoftsInventoryRecord} />
      {/* 查询统计 */}
      <Route exact path="/module/consumable/queryStat" component={SoftsQueryStatList} />
      {/* 使用 */}
      <Route exact path="/module/consumable/applyManager/list" component={SoftsApplyList} />
      <Route exact path="/module/consumable/applyManager/add" component={SoftsApplyAdd} />
      <Route exact path="/module/consumable/applyManager/record/:id" component={SoftsApplyRecord} />
      {/* 报废 */}
      <Route exact path="/module/consumable/scrapManager/list" component={SoftsScrapList} />
      <Route exact path="/module/consumable/scrapManager/add" component={SoftsScrapAdd} />
      <Route exact path="/module/consumable/scrapManager/record/:id" component={SoftsScrapRecord} />



      {/* 仪器使用 */}
      <Route exact path="/module/useDevice" component={UseDevice} />
      <Route exact path="/module/useAdd" component={UseDeviceAdd} />
      <Route exact path="/module/useList" component={UseDeviceList} />
      <Route exact path="/module/useFeedback" component={UseDeviceFeedback} />
      <Route exact path="/module/useDevice/record/:id" component={UseDeviceRecord} />

      {/* 废弃物管理 */}
      <Route exact path="/module/waste" component={WasteManger} />
      {/* 废弃物登记 */}
      <Route exact path="/module/registration/list" component={registrationList} />
      <Route exact path="/module/registration/add" component={registrationAdd} />
      <Route exact path="/module/registration/record/:id" component={registrationRecord} />
      {/* 废弃物处理 */}
      <Route exact path="/module/disposal/list" component={DisposalList} />
      <Route exact path="/module/disposal/record/:id" component={DisPosalRecord} />
      <Route exact path="/module/disposal/detail/:id/:type" component={DisPosalDetail} />
      {/* 废弃物审批 */}
      <Route exact path="/module/waste/approve" component={WasteApprove} />
      <Route exact path="/module/waste/approveDetail/:id" component={WasteApproveDetail} />


      {/* 气体钢瓶 */}
      <Route exact path="/module/gasCylinder" component={GasCylinder} />
      {/*  钢瓶管理 */}
      <Route exact path="/module/gasCylinder/manage" component={ManageList} />
      <Route exact path="/module/gasCylinder/manageAdd" component={ManageAdd} />
      <Route exact path="/module/gasCylinder/manageRecord/:id" component={Managerecord} />
      {/* 气体分类 */}
      <Route exact path="/module/gasCylinder/gasType" component={GasTypeList} />
      <Route exact path="/module/gasCylinder/gasTypeAdd" component={GasTypeAdd} />
      {/* 气体信息 */}
      <Route exact path="/module/gasCylinder/gas" component={GasList} />
      <Route exact path="/module/gasCylinder/gasAdd" component={GasAdd} />
      {/* 入库 */}
      <Route exact path="/module/gasCylinder/warehousing" component={GasWarehousingList} />
      <Route exact path="/module/gasCylinder/warehousingAdd" component={GasWarehousingAdd} />
      <Route exact path="/module/gasCylinder/warehousingRecord/:id" component={GasWarehousingRecord} />
      {/* 领用 */}
      <Route exact path="/module/gasCylinder/use" component={GasUseList} />
      <Route exact path="/module/gasCylinder/useAdd" component={GasUseAdd} />
      <Route exact path="/module/gasCylinder/useRecord/:id" component={GasUseRecord} />
      {/* 出库 */}
      <Route exact path="/module/gasCylinder/outbound" component={GasOutboundList} />
      <Route exact path="/module/gasCylinder/outboundAdd/:id" component={GasOutboundAdd} />
      <Route exact path="/module/gasCylinder/outboundRecord/:id" component={GasOutboundRecord} />
      {/* 归还 */}
      <Route exact path="/module/gasCylinder/return" component={GasReturnList} />
      <Route exact path="/module/gasCylinder/returnAdd" component={GasReturnAdd} />
      <Route exact path="/module/gasCylinder/returnRecord/:id" component={GasReturnRecord} />
      {/* 使用 */}
      <Route exact path="/module/gasCylinder/usage" component={GasUsageList} />
      <Route exact path="/module/gasCylinder/usageAdd" component={GasUsageAdd} />
      <Route exact path="/module/gasCylinder/usageRecord/:id" component={GasUsageRecord} />
      {/* 转移 */}
      <Route exact path="/module/gasCylinder/sharedTransfer" component={GasShaList} />
      <Route exact path="/module/gasCylinder/shaAdd" component={GasShaAdd} />
      <Route exact path="/module/gasCylinder/shaRecord/:id" component={GasShaRecord} />
      {/* 共享 */}
      <Route exact path="/module/gasCylinder/analysis" component={GasAnalysisList} />
      <Route exact path="/module/gasCylinder/analysisAdd" component={GasAnalysisAdd} />
      <Route exact path="/module/gasCylinder/analysisRecord/:id" component={GasAnalysisRecord} />
      {/* 审批 */}
      <Route exact path="/module/gasCylinder/approve" component={GasApproveList} />
      <Route exact path="/module/gasCylinder/approveWarRecord/:id" component={GasApproveWarRecord} />
      <Route exact path="/module/gasCylinder/approveUseRecord/:id" component={GasApproveUseRecord} />
      <Route exact path="/module/gasCylinder/approveRepayRecord/:id" component={GasApproveRepayRecord} />
      {/* 钢瓶统计 */}
      <Route exact path="/module/gasCylinder/stat" component={GasStat} />


    </div >
  )
}
export default RouteMap