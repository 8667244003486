import React, { Component } from 'react'
import { Steps } from 'antd-mobile'
import { findKcbdGjDetailsInfo } from '../../../../api/whp'
import moment from 'moment'
import './record.less'

const Step = Steps.Step;

class deviceDetail extends Component {

  state = {
    data: {},
    visible: false,
    opinion: '',
  }

  componentDidMount = () => {
    document.title = '入库详情'
    window.scrollTo(0, 0)
    this.fetch(this.props.match.params.id)
  }

  fetch = async whpBh => {
    const res = await findKcbdGjDetailsInfo({ whpBh })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }


  render() {
    const { data } = this.state
    return (
      <div className='approveDetail' style={{ padding: '0px 0px 76px' }}>
        <div className='approve_detail_head'>
          <div className='approve_detail_left'>
            <div className='approve_detail_left_title'>
              危化品{data.whp_mc}({data.whp_bh})时间线
            </div>
          </div>
        </div>
        <div className='approve_detail_cont' style={{ marginTop: '-120px' }}>
          <div className='approve_detail_bar'>
            <Steps className='track_steps' current={data.kcbdInfoEntities ? data.kcbdInfoEntities.length : 0}>
              {data.kcbdInfoEntities && data.kcbdInfoEntities.map((item, key) => {
                return <Step icon={() => {

                }} status="process" title={<div style={{ fontSize: '16px' }}>
                  {moment(item.bdsj).format('YYYY-MM-DD HH:mm:ss')}
                  <span className='track_steps_color'>
                    {item.kcbd_type === 2 ? '入库' : ''}
                    {item.kcbd_type === 3 ? '出库' : ''}
                    {item.kcbd_type === 4 ? '归还' : ''}
                    {item.kcbd_type === 5 ? '报废' : ''}
                    {item.kcbd_type === 6 ? '调拨' : ''}
                  </span>
                </div>} description={
                  <div className='track_steps_cont'>
                    <div><div></div>对应单号：{item.gldh}</div>
                    <div><div></div>入库仓库：{item.kcck_mc}</div>
                    <div><div></div>数量：{item.whp_zjsz}</div>
                    <div><div></div>库存量：{item.whp_zxcs}</div>
                    <div><div></div>操作人：{item.czr_xm}</div>
                  </div>
                } />
              })}
            </Steps>
          </div>

        </div>

      </div>
    )
  }
}

export default deviceDetail