import React, { Component } from 'react'
import { SearchBar, List, Picker } from 'antd-mobile'
import { Cascader, Field } from 'react-vant'
import { req_findByTaskId, finPollingTaskById, finAppPollingTaskDetails, listRoomByTaskId, req_findAllyuanxi } from '../../../api/api'
import './index.less'

class Task extends Component {

  state = {
    data: {},
    timeData: [], // 巡检日期集合
    frequencyData: [], // 巡检次数集合
    time: '', // 巡检日期 周和月
    frequency: 0, // 巡检次数
    roomData: [], // 巡检房间
    patrolType: 1, // 巡检类型  1天巡检 2周巡检 3月巡检 4临时巡更
    isPatrol: false, // 是否可巡检
    roomName: '', // 房间名称
    TaskTypeId: [1], // 巡检类型
    roomId: '', // 房间id
    TaskType: [
      { value: '', label: '全部' },
      { value: 1, label: '待巡检' },
      { value: 2, label: '已巡检' },
    ], // 巡检类型
    glRoomData: [], // 关联的所有房间树
    roomValue: [],
    recordId: '',
    depData: [],
    dept: ''
  }

  componentDidMount = () => {
    this.fetch(this.props.match.params.id)
    this._findAllyuanxi()
  }

  _findAllyuanxi = async () => {
    const res = await req_findAllyuanxi({ task_level: 1, task_id: this.props.match.params.id })
    if (res.code === 20000) {
      console.log(res.data)
      this.setState({
        depData: res.data.map(item => {
          return {
            label: item.dwmc,
            value: item.dwdm,
          }
        })
      })
    }
  }

  // 查询巡检次数
  findByTaskId = async (taskId) => {
    const res = await req_findByTaskId({ taskId })
    if (res.code === 20000) {
      this.setState({
        frequencyData: res.data,
        recordId: res.data.length > 0 ? res.data[0].record_id : ''
      }, () => {
        this.fetchRoom(this.props.match.params.id)
        this.fetchGlRoom(this.props.match.params.id)
      })
    }
  }

  // 查询巡检信息
  fetch = async taskId => {
    const res = await finPollingTaskById({ taskId })
    if (res.code === 20000 && res.data) {
      this.setState({
        data: res.data,
        TaskTypeId: res.data.status === 3 ? [''] : [1]
      }, () => {
        this.findByTaskId(this.props.match.params.id)
      })
    }
  }

  // 查询巡检房间
  fetchRoom = async (taskId) => {
    const { roomName, TaskTypeId, roomId, recordId, dept } = this.state
    const res = await finAppPollingTaskDetails({
      taskId, roomName, xgState: TaskTypeId[0], roomId: roomId !== '-1' ? roomId : '', recordId,
      dept: dept ? dept.join(',') : ''
    })
    if (res.code === 20000) {
      this.setState({
        roomData: res.data
      })
    }
  }

  // 查询关联的房间
  fetchGlRoom = async taskId => {
    const res = await listRoomByTaskId({ taskId })
    if (res.code === 20000) {
      let menuList = res.data.map(item => {
        return {
          text: item.dwmc,
          parentId: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
        }
      })
      menuList = menuList.map(item => {
        if (menuList.filter(p => p.parentId === item.value).length) {
          item.children = menuList.filter(p => p.parentId === item.value)
        }
        return item
      }).filter(p => p.parentId === '0')
      this.setState({
        glRoomData: [{
          children: menuList,
          parentId: "-1",
          text: "全部校区",
          value: "-1"
        }]
      })
    }
  }

  // 搜索房间 取消
  handleCancel = () => {
    this.setState({
      roomName: ''
    }, () => {
      this.fetchRoom(this.props.match.params.id)
    })
  }
  // 搜索房间
  handleChange = e => {
    this.setState({
      roomName: e
    }, () => {
      this.fetchRoom(this.props.match.params.id)
    })
  }

  // 选择房间
  handleCascaderFinish = (val) => {
    this.setState({
      roomId: val[val.length - 1],
      roomValue: val,
    }, () => {
      this.fetchRoom(this.props.match.params.id)
    })
  }

  // 选择巡检类型
  changePicker = val => {
    this.setState({
      TaskTypeId: val,
    }, () => {
      this.fetchRoom(this.props.match.params.id)
    })
  }

  // 选择巡检次数
  handleFrequency = item => {
    // 当前时间是否可巡检
    this.setState({
      recordId: item.record_id,
    }, () => {
      this.fetchRoom(this.props.match.params.id)
    })
  }

  // 开始巡检
  handlePatrol = item => {
    this.props.history.push({ pathname: `/module/patrol/create_patrol/${item.details_id}` })
  }

  // 查看巡检详情
  handlePatrolInfo = item => {
    this.props.history.push({ pathname: `/module/patrol/patrol_detail/${item.details_id}`, state: 1 })
  }

  render() {
    const { data, frequencyData, roomData, TaskType, TaskTypeId, glRoomData, recordId, depData, dept } = this.state
    return (
      <div className='task_body'>
        <div className='task_head'>
          <div className='task_head_left'>
            <div className='task_head_title'>
              {data.task_name}
            </div>
            <div className='task_head_card'>
              <div>
                巡检{data.polling_count}次
              </div>
              <div>
                {data.polling_num}个巡检点
              </div>
            </div>
            <div className='task_head_date'>
              巡检时间 {data.polling_start_time + '~' + data.polling_time}
            </div>
            <div className='task_head_search'>
              <SearchBar placeholder="请输入实验室信息" onCancel={() => this.handleCancel()} onChange={e => this.handleChange(e)} />
            </div>
            <div className='task_head_info'>
              <div className='task_head_info_title'>巡检列表</div>
              <div className='task_head_info_select'>
                <div className='task_head_info_select_picker task_picker_width'>
                  <Picker
                    data={depData}
                    cols={1}
                    extra="请选择院系"
                    value={dept}
                    onChange={val => {
                      this.setState({
                        dept: val,
                      }, () => {
                        this.fetchRoom(this.props.match.params.id)
                      })
                    }}
                  >
                    <List.Item arrow="horizontal"></List.Item>
                  </Picker>
                </div>
                {/* <div className='banpai_cascader'>
                  <Cascader
                    popup={{ round: true }}
                    title='选择实验室'
                    activeColor="#00B4C4"
                    options={glRoomData}
                    onChange={this.handleCascaderFinish}
                    value={this.state.roomValue}
                  >
                    {(_, selectedRows, actions) => (
                      <Field
                        isLink
                        value={selectedRows.length > 0 ? selectedRows[selectedRows.length - 1].text : ''}
                        readOnly
                        className='door_lock_cascader'
                        placeholder='全部房间'
                        onClick={() => actions.open()}
                      />
                    )}
                  </Cascader>
                </div> */}
                <div className='task_head_info_select_picker'>
                  <Picker
                    data={TaskType}
                    cols={1}
                    value={TaskTypeId}
                    onChange={val => this.changePicker(val)}
                  >
                    <List.Item arrow="horizontal"></List.Item>
                  </Picker>
                </div>
              </div>
            </div>
          </div>
          <div className='task_head_rigth'>
            {
              data.status === 2 ? (<img src={require('./../../../images/new/23.png')} alt='' />) : ('')
            }
          </div>
        </div>

        <div className='task_cont'>
          <div style={{ paddingTop: '12px' }}></div>
          <div className='task_tabs'>
            <div className='task_tabs_height'>
              <div className='task_tabs_width'>
                {
                  (frequencyData || []).map(item => {
                    return <div className={`task_tabs_item ${recordId === item.record_id ? 'task_tabs_item_active' : ''}`} key={item.record_id} onClick={() => this.handleFrequency(item)}>
                      <div>{item.task_name}</div>
                      <div></div>
                    </div>
                  })
                }
              </div>
            </div>
          </div>

          {/* 巡检 */}
          <div className='task_list'>
            {
              (roomData || []).map(item => {
                return (<div className='task_list_item' key={item.details_id} onClick={() => (item.state !== 1) ? this.handlePatrolInfo(item) : {}}>
                  <div className='task_list_left'>
                    <div className='task_list_item_icon'>
                      <div>
                        {
                          item.state === 1 ? (<img src={require('./../../../images/new/25.png')} />) : (<img src={require('./../../../images/new/24.png')} />)
                        }

                      </div>
                      <div>
                      </div>
                    </div>
                    <div className='task_list_item_title'>
                      <div>{item.room_name}</div>
                      <div><div>{item.dept_name}</div>{item.xq_name}/{item.building_name}/{item.floor_name}</div>
                    </div>
                  </div>
                  {item.state === 1 ? (<div className='task_list_item_sub' onClick={() => this.handlePatrol(item)}>
                    开始巡检
                  </div>) : ('')}

                  {item.state === 2 || item.state === 3 ? <div className='task_list_item_date'>
                    <div>{item.complete_time}</div>
                    <div className={item.details_state === 1 ? 'task_list_item_success' : ''}>{item.details_state === 1 ? '无隐患' : '有隐患'}</div>
                  </div> : ('')}
                </div>)
              })
            }
            {roomData.length === 0 ? <div className='not-yet'>--暂无数据--</div> : ''}
          </div>
        </div>
      </div>
    )
  }
}

export default Task