import React, { Component } from 'react'
import { findSecondModuleInfo, countMySbApproval, countMyBgApproval, countMyApproval, countMyApprovalDevice, countAppApprovalOrderRecord } from '../../../api/api'
import IP from '../../../config/config'

class Approve extends Component {

  state = {
    wt: 285,
    moduleData: [],
    sbNum: 0,
    bgNum: 0,
    sysNum: 0,
    deviceNum: 0,
    repairNum: 0,
  }

  componentDidMount = () => {
    this.fetchModuleNum()
    this.setState({
      wt: document.body.clientWidth - 100
    })
  }

  // 查询mobule_url数字模块
  fetchModuleNum = async () => {
    const res = await findSecondModuleInfo()
    if (res.code === 20000) {
      const arr = res.data.filter(x => x.module_url !== '10' && x.module_url !== '11')
      const newArr = arr.map(x => {
        x.name = x.module_url != 5 && x.module_url != 6 ? x.module_name.split('审批')[0] : x.module_name
        return x
      })
      this.setState({
        moduleData: newArr,
      })
      if (newArr.find(x => x.module_url === '1')) {
        this.fetchSbNum()
      }
      if (newArr.find(x => x.module_url === '2')) {
        this.fetchBgNum()
      }
      if (newArr.find(x => x.module_url === '3')) {
        this.fetchSysNum()
      }
      if (newArr.find(x => x.module_url === '4')) {
        this.fetchDeviceNum()
      }
      if (newArr.find(x => x.module_url === '5')) {
        this.fetchRepairNum()
      }
    }
  }

  // 查询上报未审批数量
  fetchSbNum = async () => {
    const res = await countMySbApproval()
    if (res.code === 20000) {
      this.setState({
        sbNum: res.data || 0
      })
    }
  }

  // 查询变更未审批数量
  fetchBgNum = async () => {
    const res = await countMyBgApproval()
    if (res.code === 20000) {
      this.setState({
        bgNum: res.data || 0
      })
    }
  }

  // 查询实验室预约未审批数量
  fetchSysNum = async () => {
    const res = await countMyApproval()
    if (res.code === 20000) {
      this.setState({
        sysNum: res.data || 0
      })
    }
  }

  // 查询设备预约未审批数量
  fetchDeviceNum = async () => {
    const res = await countMyApprovalDevice()
    if (res.code === 20000) {
      this.setState({
        deviceNum: res.data || 0
      })
    }
  }

  // 查询报修未审批数量
  fetchRepairNum = async () => {
    const res = await countAppApprovalOrderRecord()
    if (res.code === 20000) {
      this.setState({
        repairNum: res.data || 0
      })
    }
  }

  // 跳转
  handleSkip = item => {
    this.props.history.push({ pathname: '/module/approve', state: { status: Number(item.module_url) } })
  }

  render() {
    const { wt, moduleData, sbNum, bgNum, sysNum, deviceNum, repairNum } = this.state
    return (
      <div>
        <div className='notify'>
          {
            moduleData.find(x => x.module_url === '1' || x.module_url === '2') ? <div className='notify_list'>
              <div className='notify_list_title'>安全管控</div>
              <div className='notify_list_con'>
                {
                  moduleData.find(x => x.module_url === '1') ? <div className='notify_list_con_list' onClick={() => this.handleSkip(moduleData.find(x => x.module_url === '1'))}>
                    <div className='notify_list_con_list_left'>
                      <img alt='' src={IP.fileHost + moduleData.find(x => x.module_url === '1').module_imge} />
                      <div className='notify_list_con_list_left_con approve_sy_title'>
                        <div className='notify_list_con_list_name'>{moduleData.find(x => x.module_url === '1').name}</div>
                        <div className='notify_list_con_list_desc' style={{ width: `${wt}px` }}>{moduleData.find(x => x.module_url === '1').module_desc}</div>
                        { sbNum ? <div className='approve_sy_title_num'>{ sbNum }条</div> : '' }
                      </div>
                    </div>
                  </div> : ''
                }
                {
                  moduleData.find(x => x.module_url === '2') ? <div className='notify_list_con_list' onClick={() => this.handleSkip(moduleData.find(x => x.module_url === '2'))}>
                    <div className='notify_list_con_list_left'>
                      <img alt='' src={IP.fileHost + moduleData.find(x => x.module_url === '2').module_imge} />
                      <div className='notify_list_con_list_left_con approve_sy_title'>
                        <div className='notify_list_con_list_name'>{moduleData.find(x => x.module_url === '2').name}</div>
                        <div className='notify_list_con_list_desc' style={{ width: `${wt}px` }}>{moduleData.find(x => x.module_url === '2').module_desc}</div>
                        { bgNum ? <div className='approve_sy_title_num'>{ bgNum }条</div> : '' }
                      </div>
                    </div>
                  </div> : ''
                }
              </div>
            </div> : ''
          }

          {
            moduleData.find(x => x.module_url === '3' || x.module_url === '4') ? <div className='notify_list'>
              <div className='notify_list_title'>预约管理</div>
              <div className='notify_list_con'>
                {
                  moduleData.find(x => x.module_url === '3') ? <div className='notify_list_con_list' onClick={() => this.handleSkip(moduleData.find(x => x.module_url === '3'))}>
                    <div className='notify_list_con_list_left'>
                      <img alt='' src={IP.fileHost + moduleData.find(x => x.module_url === '3').module_imge} />
                      <div className='notify_list_con_list_left_con approve_sy_title'>
                        <div className='notify_list_con_list_name'>{moduleData.find(x => x.module_url === '3').name}</div>
                        <div className='notify_list_con_list_desc' style={{ width: `${wt}px` }}>{moduleData.find(x => x.module_url === '3').module_desc}</div>
                        { sysNum ? <div className='approve_sy_title_num'>{ sysNum }条</div> : '' }
                      </div>
                    </div>
                  </div> : ''
                }
                {
                  moduleData.find(x => x.module_url === '4') ? <div className='notify_list_con_list' onClick={() => this.handleSkip(moduleData.find(x => x.module_url === '4'))}>
                    <div className='notify_list_con_list_left'>
                      <img alt='' src={IP.fileHost + moduleData.find(x => x.module_url === '4').module_imge} />
                      <div className='notify_list_con_list_left_con approve_sy_title'>
                        <div className='notify_list_con_list_name'>{moduleData.find(x => x.module_url === '4').name}</div>
                        <div className='notify_list_con_list_desc' style={{ width: `${wt}px` }}>{moduleData.find(x => x.module_url === '4').module_desc}</div>
                        { deviceNum ? <div className='approve_sy_title_num'>{ deviceNum }条</div> : '' }
                      </div>
                    </div>
                  </div> : ''
                }
              </div>
            </div> : ''
          }

          {
            moduleData.find(x => x.module_url === '5') ? <div className='notify_list'>
              <div className='notify_list_title'>报修管理</div>
              <div className='notify_list_con'>
                <div className='notify_list_con_list' onClick={() => this.handleSkip(moduleData.find(x => x.module_url === '5'))}>
                  <div className='notify_list_con_list_left'>
                    <img alt='' src={IP.fileHost + moduleData.find(x => x.module_url === '5').module_imge} />
                    <div className='notify_list_con_list_left_con approve_sy_title'>
                      <div className='notify_list_con_list_name'>{moduleData.find(x => x.module_url === '5').name}</div>
                      <div className='notify_list_con_list_desc' style={{ width: `${wt}px` }}>{moduleData.find(x => x.module_url === '5').module_desc}</div>
                      { repairNum ? <div className='approve_sy_title_num'>{ repairNum }条</div> : '' }
                    </div>
                  </div>
                </div>
              </div>
            </div> : ''
          }
        </div>
      </div>
    )
  }
}

export default Approve