import React, { Component } from 'react'
import { Toast, InputItem, TextareaItem } from 'antd-mobile';
import { gasTypeInsert } from './../../../../api/gas'


class Add extends Component {
  state = {
    type_code: '',
    type_name: '',
    type_desc: '',
  }

  componentDidMount = () => {
    document.title = '新增气体分类'
    window.scrollTo(0, 0)
  }

  // 提交
  submit = async () => {
    let { type_code,
      type_name,
      type_desc } = this.state
    if (type_code === '' || type_name === '') {
      Toast.info('请补全上述内容！')
      return false
    }
    const params = {
      type_code,
      type_name,
      type_desc
    }
    const res = await gasTypeInsert(params)
    if (res.code === 20000) {
      Toast.info("提交成功！")
      setTimeout(() => {
        this.props.history.goBack()
      }, 2000);
    }
  }

  render() {
    const { type_code, type_name, type_desc } = this.state

    return (
      <div className='gas_manage_add'>
        <div className='gas_manage_add_title'>
        </div>
        <div className='gas_manage_add_form'>
          <div className='gas_manage_add_input'>
            <InputItem placeholder="请输入" value={type_code} onChange={(e) => {
              this.setState({
                type_code: e
              })
            }}><span style={{ color: 'red' }}>* </span>分类编号</InputItem>
          </div>
          <div className='gas_manage_add_input'>
            <InputItem placeholder="请输入" value={type_name} onChange={(e) => {
              this.setState({
                type_name: e
              })
            }}><span style={{ color: 'red' }}>* </span>分类名称</InputItem>
          </div>
        </div>

        <div className='subscription_add_form'>
          <div className='subscription_add_text'>
            <div><span style={{ color: 'white' }}>* </span>分类描述</div>
            <TextareaItem
              placeholder="请输入"
              rows={5}
              value={type_desc}
              onChange={(e) => {
                this.setState({
                  type_desc: e
                })
              }}
            />
          </div>
        </div>

        <div className='gas_manage_list_but gas_manage_list_but_two'>
          <div onClick={() => {
            window.history.go(-1);
          }}>取消</div>
          <div onClick={() => {
            this.submit()
          }}>确定</div>
        </div>

      </div >
    )
  }
}

export default Add
