import React, { Component } from 'react';
import { List, ImagePicker, WhiteSpace, TextareaItem, Button, Steps, Toast } from 'antd-mobile';
import { createForm } from 'rc-form';
import moment from 'moment'
import cookie from 'react-cookies'
import './../commen.less'
import { saveApprovalRecord, findOrderByOrderId } from '../../../../api/repairApi'
import IP from '../../../../config/config'
const Item = List.Item;
const Step = Steps.Step;

class Details extends Component {
  state = {
    data: {},
    reason: ''
  }
  componentDidMount() {
    window.document.title = '工单审批'
    this.setState({
      id: this.props.match.params.id
    })
    findOrderByOrderId({ orderId: this.props.match.params.id }).then(res => {
      if (res.code === 20000) {
        this.setState({
          data: res.data ? res.data : {}
        })
      }
    })
  }
  saveApprovalRecord = (index) => {
    const { id, reason, data } = this.state
    var data1 = {
      order_id: id,
      approval_state: index,
      approval_number: cookie.load('xinbanduomeitiData').user_id,
      approval_id: cookie.load('xinbanduomeitiData').user_id,
      approval_name: cookie.load('xinbanduomeitiData').user_name,
      order_user_id: data.user_id ? data.user_id : '',
      order_department_id: data.department_id ? data.department_id : '',
      department_id: cookie.load('xinbanduomeitiData').department_id,
      department_name: cookie.load('xinbanduomeitiData').department_name,
      config_id: data.approval_config_id != null ? data.approval_config_id : '',
    }
    if (index === 0) {
      data1.refuse_reason = reason
    }
    saveApprovalRecord(data1).then(res => {
      if (res.code === 20000) {
        Toast.success(res.message, 2)
        this.props.history.goBack()
      }
    })
  }
  render() {
    const { data } = this.state
    return (
      <div className="details">
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.user_name}>用户姓名</Item>
        </List>
        <WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.user_phone_number}>联系方式</Item>
        </List>
        <WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={(data.repairOrderEntity && data.repairOrderEntity.building_name ? data.repairOrderEntity.building_name : '') + ' ' + (data.repairOrderEntity && data.repairOrderEntity.room_name ? data.repairOrderEntity.room_name : '')}>维修地点</Item>
        </List>
        <WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.fault_type_name}>故障类型</Item>
        </List>
        <WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.equipment_name ? data.repairOrderEntity.equipment_name : ''}>设备名称</Item>
        </List>
        <WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.equipment_number ? data.repairOrderEntity.equipment_number : ''}>设备编号</Item>
        </List>
        <WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.money !== 0 ? data.repairOrderEntity.money : ''}>维修费用</Item>
        </List>
        <WhiteSpace size="sm" />
        <div className="textarea">
          <div className="textarea_title">
            问题描述
          </div>
          <div>
            <TextareaItem
              value={data.repairOrderEntity && data.repairOrderEntity.description ? data.repairOrderEntity.description : ''}
              autoHeight
              labelNumber={5}
              disabled
            />
          </div>
          <div>
            <ImagePicker
              className="details-upload"
              disableDelete={true}
              selectable={false}
              files={data.repairOrderEntity && data.repairOrderEntity.pic_url ? (data.repairOrderEntity && data.repairOrderEntity.pic_url.split(',').map((item, index) => { return { url: IP.fileHost + item, id: index } })) : []}
            />
          </div>
        </div>
        <WhiteSpace size="sm" />
        <div className="stepApproval">
          <div className="stepTitle">工单流程</div>
          {data.approvalRecordEntities && data.approvalRecordEntities.length ? <Steps size="small" current={data.approvalRecordEntities.length}>
            {
              data.approvalRecordEntities.map((item, index) => <Step key={index} status={item.state === 0 ? 'error' : item.state === 2 ? 'wait' : 'process'} title={<div>{`审批人 : ${item.approval_name}`} <span style={{ paddingLeft: '3px' }}>{item.approval_state == 1 ? '  通过' : item.approval_state == 0 ? "  不通过" : ''}</span></div>} description={<div>
                <div>{item.approval_time ? (`审批时间 : ${moment(item.approval_time).format('YYYY-MM-DD HH:mm:ss')}`) : ''}</div>
                {
                  item.refuse_reason != null ? <div>{`驳回原因 : ${item.refuse_reason}`}</div> : ''
                }
                {item.sign_url ? <div className="signPic"><span>审批签名 : </span><img src={IP.fileHost + item.sign_url} ></img> </div> : ''}

              </div>} />)
            }
          </Steps> : <div className='appreverNo'>暂无审批</div>}
          <Steps size="small" current={3}>
            {
              data.repairOrderEntity && data.repairOrderEntity.order_state !== 2 && data.repairOrderEntity.order_state >= 1 ? <Step status={data.repairOrderEntity && data.repairOrderEntity.work_name !== '' && data.repairOrderEntity.work_name !== null ? 'process' : 'wait'} title={<div>{`维修人 : ${data.repairOrderEntity && data.repairOrderEntity.work_name !== '' && data.repairOrderEntity.work_name !== null ? data.repairOrderEntity.work_name : '待指派'}`} </div>} description={<div>{data.repairOrderEntity && data.repairOrderEntity.work_name !== '' && data.repairOrderEntity.work_name !== null ? `指派时间 : ${moment(data.repairOrderEntity.appoint_time).format('YYYY-MM-DD HH:mm:ss')}` : ''}</div>} /> : ''
            }
            {
              data.repairOrderEntity && data.repairOrderEntity.order_state >= 1 && data.repairOrderEntity.order_state !== 2 && data.repairOrderEntity.work_name !== '' && data.repairOrderEntity.work_name !== null ? <Step status={data.repairOrderEntity.order_state >= 3 ? 'process' : 'wait'} title={<div>{`维修状态 : ${data.repairOrderEntity.order_state >= 3 ? '已维修' : '待维修'}`} </div>} description={<div>{data.repairOrderEntity && data.repairOrderEntity.order_state >= 3 ? `维修时间 : ${moment(data.repairOrderEntity.end_time).format('YYYY-MM-DD HH:mm:ss')}` : ''}</div>} /> : ''
            }
            {
              data.repairOrderEntity && data.repairOrderEntity.order_state >= 3 ? <Step status={data.repairOrderEntity.order_state === 4 ? 'process' : 'wait'} title={<div>{`工单状态 : ${data.repairOrderEntity.order_state === 4 ? '已完成' : '待确认'}`} </div>} description={<div></div>} /> : ''
            }
          </Steps>
        </div>
        <WhiteSpace size="lg" />
        <Button type="primary" onClick={() => this.props.history.goBack()}>返回</Button>
        <WhiteSpace size="lg" />
      </div>
    );
  }
}

export default createForm()(Details);