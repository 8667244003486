import React, { Component } from 'react'
import { SearchBar, Pagination, Icon } from 'antd-mobile'
import { listDict, findPageKcInfo } from './../../../../api/whp'
import './list.less'

class List extends Component {
  state = {
    paramKey: '',
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    total: 0,
    data: [],
    flbh: '',
    typeData: []
  }
  componentDidMount = () => {
    document.title = '库存'
    window.scrollTo(0, 0)
    this.fetchType()
    this.fetch()
  }

  // 查询类别
  fetchType = async () => {
    const res = await listDict({ ZDLX: 'lb' })
    if (res.code === 20000) {
      const data = res.data.map(item => {
        return {
          label: item.zdmc,
          value: item.zdbh,
        }
      })
      this.setState({
        typeData: data
      })
    }
  }

  fetch = async () => {
    const { pageSize, pageNum, paramKey, flbh } = this.state
    const res = await findPageKcInfo({ size: pageSize, page: pageNum, paramKey, flbh })
    if (res.code === 20000) {
      this.setState({
        data: res.data.list,
        pages: res.data ? (res.data.pages ? res.data.pages : 1) : 1,
        total: res.data.total,
      })
    }
  }



  render() {
    const { typeData, flbh, data, paramKey } = this.state
    return (
      <div className='subscription_list'>
        <div className='subscription_list_head inventory_manager_head'>
          <SearchBar placeholder="请输入危化品名称" value={paramKey} onCancel={(e) => {
            this.setState({
              paramKey: ''
            }, () => {
              this.fetch()
            })
          }} onChange={e => {
            this.setState({
              paramKey: e
            }, () => {
              this.fetch()
            })
          }} />
          <div className='inventory_manager_record_tabs'>
            <div className='inventory_manager_record_tabs_cont'>
              <div className='inventory_manager_record_tabs_width'>
                <div className={flbh === '' ? 'inventory_manager_record_tabs_item inventory_manager_record_tabs_item_active' : 'inventory_manager_record_tabs_item'} onClick={() => {
                  this.setState({
                    flbh: '',
                  }, () => {
                    this.fetch()
                  })
                }}>
                  <div>全部</div>
                  <div></div>
                </div>
                {typeData.map(item => {
                  return (<div key={item.value} className={flbh === item.value ? 'inventory_manager_record_tabs_item inventory_manager_record_tabs_item_active' : 'inventory_manager_record_tabs_item'} onClick={() => {
                    this.setState({
                      flbh: item.value,
                    }, () => {
                      this.fetch()
                    })
                  }}>
                    <div>{item.label}</div>
                    <div></div>
                  </div>)
                })}
              </div>
            </div>
          </div>
        </div>
        <div className='inventory_manager_record'>
          {
            data.map((item, key) => {
              return <div className='inventory_manager_item' key={key} onClick={() => {
                this.props.history.push(`/module/inventoryManager/record/${item.kc_id}`)
              }}>
                <div className='subscription_list_card_head'>
                  <div className='subscription_list_card_head_left'>
                    <div>{key + 1}</div>
                    <div>{item.whp_mc}</div>
                  </div>
                  <div className='subscription_list_card_class'>
                    {item.whp_fl}
                  </div>
                </div>
                <div className='subscription_list_card_company'>
                  <div>{item.whp_cas}</div>
                  <div>{item.kcck_mc}</div>
                </div>
                <div className='subscription_list_card_unit'>
                  <div>{item.whp_ggxh}</div>
                  <div>{item.whp_bzsl}{item.whp_bzdw}</div>
                  <div>{item.whp_jldw}</div>
                </div>
              </div>
            })
          }

          {this.state.data.length === 0 ? <div className='not-yet'>--暂无数据--</div> : ''}

          {
            this.state.total > 20 ? (<div className='subscription_manager_page'>
              <Pagination total={this.state.pages}
                current={this.state.pageNum}
                onChange={e => {
                  this.setState({
                    pageNum: e
                  }, () => {
                    window.scrollTo(0, 0)
                    this.fetch()
                  })
                }}
                locale={{
                  prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                  nextText: (<span className="arrow-align"><Icon type="right" /></span>),
                }}
              />
            </div>) : ''
          }
        </div>
      </div>
    )
  }
}

export default List
