import React, { Component } from 'react'
import { Grid, Icon } from 'antd-mobile'
import { findModuleInfo } from '../../../api/api'
import { findMyTotalIntegrate } from '../../../api/safty'
import IP from '../../../config/config'
import './index.less'

export default class componentName extends Component {
  state = {
    moduleData: [],
    rateNum: 0,
  }
  componentDidMount = () => {
    document.title = "安全教育"
    this.fetchModule()
    this._findMyTotalIntegrate()
  }
  // 查询模块
  fetchModule = async () => {
    const res = await findModuleInfo()
    if (res.code === 20000) {
      this.setState({
        moduleData: (res.data || []).filter(x => x.module_type === '5')
      })
    }
  }

  _findMyTotalIntegrate = async () => {
    const res = await findMyTotalIntegrate()
    if (res.code === 20000) {
      this.setState({
        rateNum: res.data
      })
    }
  }
  render () {
    const { moduleData } = this.state
    return (
      <div className='patrol'>
        <div className='patrol_img'>
          <img alt='' src={require('../../../img/safty/index_top.png')} />
        </div>
        <div className='patrol_con patrol_jf_dashboard'>
          <div className="jf_dashboard_content">
            <div className="jf_dashboard_content_floor1">
              <div>
                <div>安全积分</div>
                <b>{this.state.rateNum ? Math.round(this.state.rateNum) : 0}</b>
              </div>
              <div className='guize_link' onClick={() => { this.props.history.push('/module/safty/jfrule') }}>
                积分规则
                <Icon type='right' />
              </div>
            </div>
            <div className="jf_dashboard_content_floor2">
              <div className='flex_s' onClick={() => { this.props.history.push('/module/safty/jfdetail') }}>
                <img src={require('../../../images/safety_exam/jfmx_icon.png')} alt="" />
                积分明细
              </div>
              <div className='flex_s' onClick={() => { this.props.history.push('/module/safty/jiaoyupaihangbang') }}>
                <img src={require('../../../images/safety_exam/jf_icon.png')} alt="" />
                积分排行
              </div>
            </div>
          </div>
          <Grid
            columnNum={3}
            data={moduleData}
            hasLine={false}
            renderItem={item => (
              <div className='patrol_con_list' onClick={() => this.props.history.push(item.module_url)}>
                <img src={IP.fileHost + item.module_imge} alt="" />
                <span>{item.module_name}</span>
              </div>
            )}
          />
        </div>
      </div>
    )
  }
}
