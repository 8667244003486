import React, { Component } from 'react'
import { Toast } from 'antd-mobile'
import { Progress } from 'antd'
import { findExamReport } from '../../../api/safty'
import './exam.less'
import moment from 'moment'

export default class ExamReport extends Component {
  state = {
    dataInfo: {},// 基本信息
    dataCard: [],// 答题卡信息
    dataGrade: {}, // 做题得分信息
    paperId: this.props.match.params.paperId
  }
  componentDidMount () {
    document.title = "考试报告"
    this.fetchExamReport()

  }
  fetchExamReport = async () => {
    const { paperId, recordId } = this.props.match.params
    console.log(this)
    const res = await findExamReport({ paperId, recordId })
    if (res.code === 20000) {
      this.setState({
        dataInfo: res.data.answerReportVo,
        dataCard: res.data.answerReportVo.answerDetailsEntityList || [],
        dataGrade: res.data.answerRecordCoutVo,
      })
    }
  }
  // 解析
  handleAnalysis = flag => {
    const { dataInfo } = this.state
    if (flag === 1 && dataInfo.error_number === 0) return Toast.info('没有错题！！')
    this.props.history.push(`/module/safty/exam/analysis/${dataInfo.record_id}/${flag}/${dataInfo.paper_name}`)
  }
  nextExam = () => {
    const { dataGrade } = this.state
    dataGrade.restrict_num - dataGrade.ykNum > 0 && this.props.history.push(`/module/safty/single/${this.state.paperId}`)
  }
  render () {
    const { dataInfo, dataCard, dataGrade } = this.state
    return (
      <div className='saftyExamReport'>
        <div className='floor floor_1'>
          <div className="progress_wrap">
            <Progress type="dashboard" strokeColor={dataInfo.exam_result === 1 ? { '0%': '#00B4C4', '100%': 'rgba(0, 180, 196, 0.60)' } : { '0%': '#FFB07B', '100%': '#FA914B' }} gapDegree={150} strokeLinecap="round" showInfo={false} width={234} strokeWidth={8} percent={((dataInfo.record_score) / (dataInfo.total_score)) * 100} />
            <div className="content">
              {
                dataInfo.exam_result === 1 ?
                  <span className='standard yes'>及格</span> : <span className='standard no'>不及格</span>
              }
              <div className={`fs ${dataInfo.exam_result === 1 ? 'green_text' : 'orange_text'}`}>{dataInfo.record_score || 0}</div>
              <div className=''>得分</div>
            </div>
          </div>
          <div className='flex_b'>
            <div className='flex_v'>
              <span className=''>共计</span>
              <span className='blue_text'>{dataCard.length}题</span>
            </div>
            <div className='flex_v'>
              <span className=''>答对</span>
              <span className='green_text'>{dataInfo.success_number || 0}题</span>
            </div>
            <div className='flex_v'>
              <span className=''>答错</span>
              <span className='orange_text'>{dataInfo.error_number || 0}题</span>
            </div>
            <div className='flex_v'>
              <span className=''>未答</span>
              <span className='grey_text'>{dataInfo.no_answer_number || 0}题</span>
            </div>
          </div>
        </div>
        <div className="floor floor_2">
          <p>答题卡</p>
          <div className='ti_card'>
            {dataCard.map((x, k) => {
              return <a key={x.problem_id} className={x.state === '1' ? 'right' : x.state === '2' ? 'error' : ''}>{k + 1}</a>
            })}
          </div>
          <div className="flex_b">
            <div className='grey_text'>试卷名称</div>
            <div>{dataInfo.paper_name}</div>
          </div>
          <div className="flex_b">
            <div className='grey_text'>交卷时间</div>
            <div>
              {
                dataInfo.record_end_time ? moment(dataInfo.record_end_time).format('YYYY.MM.DD HH:mm') : ''
              }
            </div>
          </div>
          <div className="bottom_btn">
            <div className="bottom_btn_list no_solid_bg" onClick={() => this.handleAnalysis(0)}>全部解析</div>
            <div className="bottom_btn_list" onClick={() => this.handleAnalysis(1)}>错题解析</div>
          </div>
        </div>
        <div className="flex_b bottomInfo">
          <div className="flex_v">
            <span className='grey_text'>最高得分</span>
            {
              dataGrade.maxResult === 1 ?
                <div className='flex_b'>
                  <span className='num green_text'>{dataGrade.maxScore}</span>
                  <span className='standard yes'>及格</span>
                </div> :
                <div className='flex_b'>
                  <span className='num orange_text'>{dataGrade.maxScore}</span>
                  <span className='standard no'>不及格</span>
                </div>
            }
          </div>
          <div className={`flex_v ${dataGrade.restrict_num - dataGrade.ykNum > 0 ? 'haveChoose' : 'noChoose'}`}
            onClick={() => this.nextExam()}>
            <span>再次考试</span>
            <span>剩余考试次数: {dataGrade.restrict_num - dataGrade.ykNum}次</span>
          </div>
        </div>

      </div>
    )
  }
}
