import React, { Component } from 'react'
import { Progress } from 'antd'
import { statisticsExam, statisticsPractiveRecord, statisticsLearnRecord } from '../../../api/api'
import ReactEcharts from 'echarts-for-react';

const color = ['#546FC6', '#91CD75', '#FAC958', '#EF6667', '#73BFDF', '#3BA272', '#08CEDF', '#FFC34E', '#F76964', '#00C2FF', '#FFDB68', '#7B83EA']

class SaftyState extends Component {

  state = {
    examData: {},
    practiceData: [],
    practiceNum: 0,
    studyData: [],
  }

  componentDidMount = () => {
    this.fetchExam()
    this.fetchPractice()
    this.fetchStudy()

  }

  // 查询考试信息
  fetchExam = async () => {
    const res = await statisticsExam()
    if (res.code === 20000) {
      this.setState({
        examData: res.data || {}
      })
    }
  }

  // 查询练习信息
  fetchPractice = async (colors) => {
    const res = await statisticsPractiveRecord()
    if (res.code === 20000) {
      const data = (res.data || []).map((val, index) => {
        return {
          name: val.category_name,
          value: val.num || 0,
        }
      })
      this.setState({
        practiceData: data,
        practiceNum: this.sum(data.map(x => x.value))
      })
    }
  }

  // 查询学习信息
  fetchStudy = async () => {
    const res = await statisticsLearnRecord()
    if (res.code === 20000) {
      const sums = this.sum((res.data || []).map(x => x.num))
      const data = (res.data || []).map(x => {
        x.percent = sums ? (x.num / sums) * 100 : 0
        return x
      })
      this.setState({
        studyData: data
      })
    }
  }

  sum(arr) {
    var sum = 0;
    for (var i = 0; i < arr.length; i++) {
      sum += arr[i];
    }
    return sum;
  }

  render() {
    const { examData, practiceData, practiceNum, studyData } = this.state
    const option = {
      title: {
        text: '参与安全练习',    // 主标题
        top: '46%',
        left: '49%',
        textStyle: {
          textAlign: 'center',
          color: '#919499',
          fontSize: 12,
          fontWeight: 400,
        },
        itemGap: 8,   // 主副标题间隔
        subtext: `${practiceNum}人`,   // 副标题
        subtextStyle: {
          textAlign: 'center',
          color: '#00b4c4',
          fontSize: 20,

        },
        textAlign: 'center',
      },
      color: color,
      series: [{
        name: '参与安全练习人数',
        type: 'pie',
        radius: ['40%', '60%'],
        center: ["50%", "55%"],
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        hoverAnimation: false, // 取消放大
        labelLine: {
          length: 10
        },
        width: '100%',
        label: {
          alignTo: 'edge',
          margin: 0,
          edgeDistance: 0,
          // 饼图图形上的文本标签
          formatter: '{name|{b}}\n {num|{c}}',
          rich: {
            name: {
              fontSize: 12,
              color: '#919499',
              padding: [6, 0],
            },
            num: {
              fontSize: 12,
              fontWeight: 'bold',
              color: '#292c33',
            },
          }
        },
        data: practiceData
      }]
    }
    return (
      <div className='safty_state'>
        <div className='safty_state_con'>
          <div className='state_con_title'>
            <div>安全学习</div>
            <div>各类别人数</div>
          </div>
          <div className='state_con_echarts'>
            {
              (studyData || []).map((item, key) => {
                return <div className='state_con_progress' key={key + 1}>
                  <div className='state_con_progress_title'>
                    <div className='state_progress_title_name'>{item.category_name}</div>
                    <div className='state_progress_title_num'>{item.num}人</div>
                  </div>
                  <Progress percent={item.percent} strokeColor={{ '0%': '#00B4C4', '100%': 'rgba(0,180,196,0.6)' }} showInfo={false} />
                </div>
              })
            }
          </div>
        </div>
        <div className='safty_state_con'>
          <div className='state_con_title'>
            <div>安全练习</div>
            <div>各类别人数</div>
          </div>
          <div className='state_con_echarts'>
            <ReactEcharts option={option} style={{ height: '250px', width: '100%' }} />
          </div>
        </div>
        <div className='safty_state_con'>
          <div className='state_con_title'>
            <div>安全考试</div>
          </div>
          <div className='state_con_exam'>
            <div className='state_con_exam_list'>
              <div className='state_exam_num'>{examData.totalNum || 0}</div>
              <div className='state_exam_title'>考试学生人数</div>
            </div>
            <div className='state_con_exam_line'></div>
            <div className='state_con_exam_list'>
              <div className='state_exam_num'>{(Number(examData.avgScore) || 0).toFixed(1)}</div>
              <div className='state_exam_title'>平均成绩</div>
            </div>
            <div className='state_con_exam_line'></div>
            <div className='state_con_exam_list'>
              <div className='state_exam_num'>{(examData.passRate || 0) * 100}%</div>
              <div className='state_exam_title'>平均合格率</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SaftyState
