import React, { Component } from 'react'
import { TreeSelect, Select } from 'antd'
import { Picker, List, TextareaItem, Modal, Icon, Toast, InputItem } from 'antd-mobile';
import { req_findDepartmentInfo, findZxOrRoomByDwOrZx, req_findDepartmentInfoToTeacher, findMasterProcess, findNextApprovalUser, addLysqInfo, listKcByPage } from './../../../../api/gas'

const { TreeNode } = TreeSelect;

class Add extends Component {
  state = {
    data: [
      {
        label: '2013年',
        value: '2013',
      },
      {
        label: '2014年',
        value: '2014',
      },
    ],
    slideId: '',
    startX: '',
    visible: false,
    tab_id: '',
    sqfxData: [],//方向
    xydm: undefined,//所属学院
    zxid: undefined,//所属中心
    jsid: undefined,//所属实验室
    lyckbh: undefined,
    syfxbh: undefined,
    lyrgh1: undefined,
    lyrgh2: undefined,
    lyyt: '',
    typeData: [],//类别
    whpFlbh: '',//化学品类别
    chemicalData: [],//化学品
    depTreeData: [],
    depData: [],
    centerData: [],//中心
    roomData: [],//房间
    masterProcess: 0, // 是否多人同时审批
    approveVisible: false,
    spry: undefined,
    warehouseData: [],//仓库
    userData: [],//领用人
    whpInfoEntityList: [],//领用物品
    depUserData: [],
    depArr: [],
    flbh: ''
  }

  componentDidMount = () => {
    document.title = '领用申请'
    window.scrollTo(0, 0)
    this.fetchDepUser()
    this.fetchDep()
  }

  // 数组转变
  deep = (node) => {
    let stack = node,
      data = []
    while (stack.length !== 0) {
      let pop = stack.pop()
      data.push({
        title: pop.title,
        parentId: pop.parentId,
        value: pop.value,
      })
      let children = pop.children
      if (children) {
        for (let i = children.length - 1; i >= 0; i--) {
          stack.push(children[i])
        }
      }
    }
    return data
  }

  // 查询部门和人员
  fetchDepUser = async () => {
    const res = await req_findDepartmentInfoToTeacher()
    if (res.code === 20000) {
      let menuList = (res.data || []).map(item => {
        return {
          title: item.dwmc,
          value: item.dw_id,
          parentId: item.sj_dm,
          disabled: true,
          children: (item.teacherInfoEntities || []).map(val => {
            return {
              title: val.xm,
              value: val.gh,
              parentId: val.dwdm,
              disabled: false,
            }
          })
        }
      })
      menuList = menuList.map(item => {
        item.children = item.children.length ? item.children : menuList.filter(p => p.parentId === item.value)
        return item
      }).filter(p => p.parentId === '0')
      const a = []
      const c = a.concat(menuList)
      const depArr = this.deep(c)
      this.setState({
        depUserData: menuList,
        depArr
      })
    }
  }

  // 查询学院/部门
  fetchDep = async () => {
    const res = await req_findDepartmentInfo({ dwlx: 1 })
    if (res.code === 20000) {
      let menuList = res.data
      menuList = menuList.map(a => {
        return {
          title: a.dwmc,
          key: a.dwdm,
          parentId: a.sj_dm,
          value: a.dwdm,
          disabled: a.sj_dm === '0' ? true : false
        }
      })
      menuList = menuList.map(m => {
        m.children = menuList.filter(p => p.parentId === m.key)
        return m
      }).filter(x => !res.data.find(v => v.dwdm === x.parentId))
      this.setState({
        depTreeData: menuList,
        depData: res.data,
      })
    }
  }

  // 选择部门/学院
  handleChangeDep = async e => {
    const res = await findZxOrRoomByDwOrZx({ flag: 1, param: e })
    if (res.code === 20000) {
      this.setState({
        centerData: res.data.map(item => {
          return {
            label: item.room_name,
            value: item.room_id,
          }
        })
      })
    }
  }

  // 选择中心
  handleChangeCenter = async e => {
    const res = await findZxOrRoomByDwOrZx({ flag: 2, param: e.join(',') })
    if (res.code === 20000) {
      this.setState({
        roomData: res.data.map(item => {
          return {
            label: item.room_name,
            value: item.room_id,
          }
        })
      })
    }
  }

  // 查询库存
  fetchkc = async () => {
    const { lyckbh, jsid } = this.state
    const res = await listKcByPage({ size: 9999999, page: 1, roomId: jsid[0] })
    if (res.code === 20000) {
      this.setState({
        chemicalData: res.data.list.map(item => {
          return {
            ...item,
            select_state: false,
            state: false
          }
        }),
      })
    }
  }

  // 求和
  sum(arr) {
    var sum = 0;
    for (var i = 0; i < arr.length; i++) {
      sum += arr[i];
    }
    return sum;
  }

  // 钢瓶气体总数
  whpNum = () => {
    const { chemicalData } = this.state
    return this.sum(chemicalData.map(x => x.sgwhpsl))
  }

  // 添加钢瓶气体
  handleAddWhp = () => {
    const { chemicalData, whpInfoEntityList } = this.state
    const newData = chemicalData.filter(x => x.select_state)
    newData.map(item => {
      if (whpInfoEntityList.filter(x => x.bottle_number === item.bottle_number).length === 0) {
        whpInfoEntityList.push(item)
      }
      this.setState({
        whpInfoEntityList: whpInfoEntityList
      })
    })
    this.setState({
      visible: false,
    })
  }


  // 判断审批是多人共同审批还是单人审批
  handleMasterProcess = async () => {
    const { whpInfoEntityList, xydm, zxid, jsid, lyyt, lyrgh1, lyrgh2 } = this.state
    if (xydm === undefined || zxid === undefined || jsid === undefined || lyyt === '' || lyrgh1 === undefined || lyrgh2 === undefined) {
      Toast.info('请补全上述内容！')
      return false
    }
    if (whpInfoEntityList.length === 0) {
      Toast.info('请添加钢瓶气体！')
      return false
    }
    if (whpInfoEntityList.filter(x => x.lysl === '' || x.lysl === null).length > 0) {
      Toast.info('请填写钢瓶气体领用量！')
      return false
    }
    if (whpInfoEntityList.filter(x => x.lysl > 0).length !== whpInfoEntityList.length) {
      Toast.info('领用量必须大于0！')
      return
    }
    const res = await findMasterProcess({ flag: 3 })
    if (res.code === 20000) {
      this.setState({
        masterProcess: res.data
      }, () => {
        this.handleNextApprovalUser(jsid.join(','))
      })
    }
  }

  // 获取下一个审批人员信息
  handleNextApprovalUser = async roomId => {
    const { masterProcess } = this.state
    const res = await findNextApprovalUser({ flag: 3, roomId })
    if (res.code === 20000) {
      this.setState({
        approveUser: res.data,
        approveVisible: true,
      }, () => {
        if (masterProcess) {
          this.setState({
            spry: (res.data || []).map(x => x.gh)
          })
        }
      })
    }
  }

  // 提交
  submit = async () => {
    const { spry, lyyt, xydm, zxid, jsid, depData, centerData, roomData, masterProcess, approveUser, whpInfoEntityList, depArr, lyrgh1, lyrgh2, } = this.state
    if (spry === undefined || spry === '') {
      Toast.info('请选择审批人员！')
      return false;
    }
    if (xydm === undefined || zxid === undefined || jsid === undefined || lyyt === '' || lyrgh1 === undefined || lyrgh2 === undefined) {
      Toast.info('请补全上述内容！')
      return false
    }
    if (whpInfoEntityList.length === 0) {
      Toast.info('请添加钢瓶气体！')
      return false
    }
    if (whpInfoEntityList.filter(x => x.lysl === '' || x.lysl === null).length > 0) {
      Toast.info('请填写钢瓶气体领用量！')
      return false
    }
    if (whpInfoEntityList.filter(x => x.lysl > 0).length !== whpInfoEntityList.length) {
      Toast.info('领用量必须大于0！')
      return
    }
    const values = {
      xydm: xydm,
      zxid: zxid.join(','),
      jsid: jsid.join(','),
      xymc: depData.find(x => x.dwdm === xydm).dwmc,
      zxmc: centerData.find(x => x.value === zxid.join(',')).label,
      jsmc: roomData.find(x => x.value === jsid.join(',')).label,
      lyzt: 1,
      lyyt,
      lyrgh1,
      lyrgh2,
      lyrmc1: depArr.find(x => x.value === lyrgh1).title,
      lyrmc2: depArr.find(x => x.value === lyrgh2).title,
    }
    const params = {
      ...values,
      spuserVos: masterProcess ? approveUser : approveUser.filter(x => x.gh === spry),
      whpInfoEntityList: whpInfoEntityList,
    }
    const res = await addLysqInfo(params)
    if (res.code === 20000) {
      Toast.info("提交成功！")
      setTimeout(() => {
        this.props.history.goBack()
      }, 2000);
    }
  }

  render() {
    const { slideId, visible, tab_id, lyyt, typeData, chemicalData, depTreeData, xydm, centerData, zxid, roomData, jsid, approveVisible, masterProcess, approveUser, spry, whpInfoEntityList, depUserData, lyrgh1,
      lyrgh2, } = this.state
    const loop = data =>
      data.map(item => {
        if (item.children && item.children.length) {
          return (
            <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled} />;
      });
    return (
      <div className='gas_manage_add'>
        <div className='gas_manage_add_title'>

        </div>
        <div className='gas_manage_add_form'>


          <div className='gas_manage_add_tree'>
            <div>
              <span style={{ color: 'red' }}>* </span>申请所属学院
            </div>
            <TreeSelect
              showSearch
              treeNodeFilterProp='title'
              placeholder="请选择"
              style={{ width: '100%' }}
              value={xydm}
              onChange={(e) => {
                this.setState({
                  xydm: e,
                  zxid: undefined,
                  jsid: undefined
                })
                this.handleChangeDep(e)
              }}
            >
              {loop(depTreeData)}
            </TreeSelect>
          </div>
          <div className='gas_manage_add_picker'>
            <Picker
              data={centerData}
              value={zxid}
              cols={1}
              onChange={(e) => {
                this.setState({
                  zxid: e,
                  jsid: undefined
                })
                this.handleChangeCenter(e)
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>所属中心</List.Item>
            </Picker>
          </div>
          <div className='gas_manage_add_picker'>
            <Picker
              data={roomData}
              value={jsid}
              cols={1}
              onChange={(e) => {
                this.setState({
                  jsid: e
                })
              }}
            >
              <List.Item arrow="horizontal"><span style={{ color: 'red' }}>* </span>所属实验室</List.Item>
            </Picker>
          </div>


        </div>

        <div className='gas_manage_add_form'>
          <div className='gas_manage_add_tree'>
            <div>
              <span style={{ color: 'red' }}>* </span>第一领用人
            </div>
            <TreeSelect
              placeholder="请选择"
              showSearch
              treeNodeFilterProp='title'
              style={{ width: '100%' }}
              value={lyrgh1}
              onChange={(e) => {
                this.setState({
                  lyrgh1: e,
                })
              }}
            >
              {loop(depUserData)}
            </TreeSelect>
          </div>
          <div className='gas_manage_add_tree'>
            <div>
              <span style={{ color: 'red' }}>* </span>第二领用人
            </div>
            <TreeSelect
              placeholder="请选择"
              showSearch
              treeNodeFilterProp='title'
              style={{ width: '100%' }}
              value={lyrgh2}
              onChange={(e) => {
                this.setState({
                  lyrgh2: e,
                })
              }}
            >
              {loop(depUserData)}
            </TreeSelect>
          </div>

        </div>


        <div className='gas_manage_add_form'>
          <div className='gas_manage_add_text'>
            <div><span style={{ color: 'red' }}>* </span>领用用途</div>
            <TextareaItem
              placeholder="请输入"
              rows={5}
              value={lyyt}
              onChange={(e) => {
                this.setState({
                  lyyt: e
                })
              }}
            />
          </div>
        </div>

        <div className='gas_manage_add_increase'>
          <div onClick={() => {
            if (!jsid) {
              Toast.info('请先选择实验室！')
              return
            }
            this.fetchkc()
            this.setState({
              tab_id: '',
              visible: true
            })
          }}>
            <img src={require('./../../../../images/gas/06.png')} alt='' />
            <span>选择库存</span>
          </div>
        </div>

        <div className='gas_manage_add_increase_record'>
          <div className='subscription_list_card' style={{ margin: '0' }}>
            {whpInfoEntityList.map((item, key) => {
              return (
                <div className='gas_manage_add_card_item' style={{ height: '170px' }} onTouchStart={(e) => {
                  this.setState({
                    startX: e.touches[0].clientX
                  })
                }} onTouchEnd={(e) => {
                  const endX = e.changedTouches[0].clientX
                  if (this.state.startX === endX) {
                    return
                  }

                  if (endX - this.state.startX > 100) { // 阈值可以根据需要调整
                    this.setState({
                      slideId: ''
                    })
                    return false;
                    // 左滑后的处理逻辑
                  }
                  if (endX + this.state.startX > 100) { // 阈值可以根据需要调整
                    this.setState({
                      slideId: item.bottle_number
                    })
                    // 右滑后的处理逻辑
                    return false
                  }
                }}>
                  <div className='gas_manage_add_card_cont  gas_manage_add_cont_float' style={{ margin: slideId === item.bottle_number ? '0px' : '0px 12px' }}>
                    <div className='subscription_list_card_item' style={{ width: slideId === item.bottle_number ? window.screen.width : window.screen.width - 24 }}>
                      <div className='subscription_list_card_head'>
                        <div className='subscription_list_card_head_left'>
                          <div>{key + 1}</div>
                          <div>{item.gas_name}</div>
                        </div>
                        <div className='gas_manage_list_item_tag'>
                          {item.gas_type_name}
                        </div>
                      </div>
                      <div className='subscription_list_card_unit' style={{ marginTop: '12px' }}>
                        <div>{item.bottle_number}</div>
                        <div>{item.bottle_type}</div>
                      </div>
                      <div className='flex_c'>
                        <div className='flex_c_item'>
                          <div>{item.bottle_specs}</div>
                          <div>钢瓶规格</div>
                        </div>
                        <div className='flex_c_item'>
                          <div>{item.bottle_capacity}L</div>
                          <div>容量</div>
                        </div>
                        <div className='flex_c_item'>
                          <div>{item.gas_number}L</div>
                          <div>当前气量</div>
                        </div>
                      </div>
                      <div className='subscription_list_card_input'>
                        <div className='gas_manage_add_input'>
                          <InputItem type='digit' placeholder="请输入" value={item.lysl} onChange={(e) => {
                            this.setState({
                              whpInfoEntityList: whpInfoEntityList.map(x => {
                                if (x.bottle_number === item.bottle_number) {
                                  if (e > 0) {
                                    x.lysl = e > x.gas_number ? x.gas_number : e
                                  } else {
                                    x.lysl = Number(e)
                                  }
                                }
                                return x
                              })
                            })
                          }}>领用量(L)</InputItem>
                        </div>
                      </div>
                    </div>
                    <div className='gas_manage_add_card_del' style={{ display: slideId === item.bottle_number ? 'block' : 'none' }} onClick={() => {
                      this.setState({
                        whpInfoEntityList: whpInfoEntityList.filter(x => x.bottle_number !== item.bottle_number)
                      })
                    }}>
                      删除
                    </div>
                  </div>
                </div>
              )
            })}

          </div>
        </div>
        <div className='gas_manage_list_but gas_manage_list_but_two'>
          <div onClick={() => {
            window.history.go(-1);
          }}>取消</div>
          <div onClick={() => {
            this.handleMasterProcess()
          }}>确定</div>
        </div>

        <Modal
          popup
          visible={visible}
          className='subscription_modal'
          onClose={() => {
            this.setState({
              visible: false
            })
          }}
          animationType="slide-up"
        >
          <div className='gas_manage_add_modal'>
            <div className='gas_manage_add_modal_locate'>
              <div className='gas_manage_add_modal_head'>
                <div>添加钢瓶气体</div>
                <div>
                  <Icon type='cross' onClick={() => {
                    this.setState({
                      visible: false
                    })
                  }} />
                </div>
              </div>
            </div>
            <div className='intoHouse_card'>
              {chemicalData.map((item, key) => {
                return (<div className={item.select_state ? 'intoHouse_item selected' : 'intoHouse_item'} key={item.bottle_number} onClick={() => {
                  const data = chemicalData.map(x => {
                    if (item.bottle_number === x.bottle_number) {
                      x.select_state = item.select_state ? false : true
                    }
                    return x
                  })
                  this.setState({
                    chemicalData: data
                  })

                }}>
                  <div className='flex_b'>
                    <div className='left'>
                      <span className='ping_sort'>{key + 1}</span>
                      <span className='ping_bh'>{item.gas_name}</span>
                    </div>
                    <div className='right'>
                      {item.gas_type_name}
                    </div>
                  </div>
                  <div className='flex_b bottom'>
                    <div className=''>
                      <span className='gas_name'>{item.bottle_number}</span>
                      <span className='ping_specs'>{item.bottle_type}</span>
                    </div>
                  </div>
                  <div className='flex_c'>
                    <div className='flex_c_item'>
                      <div>{item.bottle_specs}</div>
                      <div>钢瓶规格</div>
                    </div>
                    <div className='flex_c_item'>
                      <div>{item.bottle_capacity}L</div>
                      <div>容量</div>
                    </div>
                    <div className='flex_c_item'>
                      <div>{item.gas_number}L</div>
                      <div>当前气量</div>
                    </div>
                  </div>
                </div>)
              })}

            </div>
            <div className='gas_manage_list_but'>
              <div onClick={() => {
                this.handleAddWhp()
              }}>添加</div>
            </div>
          </div>
        </Modal>
        {/* 选择审批人 */}
        <Modal
          popup
          visible={approveVisible}
          className='subscription_modal'
          onClose={() => {
            this.setState({
              approveVisible: false,
              spry: undefined
            })
          }}
          animationType="slide-up"
        >
          <div className='gas_manage_add_modal gas_manage_add_modal_approve'>
            <div className='gas_manage_add_modal_head'>
              <div>选择审批人员</div>
              <div>
                <Icon type='cross' onClick={() => {
                  this.setState({
                    approveVisible: false
                  })
                }} />
              </div>
            </div>
            <div className='gas_manage_add_select'>
              <div>
                <span style={{ color: 'red' }}>* </span>审批人员
              </div>
              <Select mode={masterProcess ? 'multiple' : null} disabled={masterProcess ? true : false} allowClear showSearch optionFilterProp="children" value={spry} placeholder='请选择' onChange={(e) => {
                this.setState({
                  spry: e
                })
              }}>
                {
                  (approveUser || []).map(item => {
                    return <Select.Option key={item.gh} value={item.gh}>{item.xm}</Select.Option>
                  })
                }
              </Select>
            </div>

            <div className='gas_approve_record_but'>
              <div onClick={() => {
                this.submit()
              }}>提交审批</div>
            </div>
          </div>
        </Modal>
      </div >
    )
  }
}

export default Add
