import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { getJFRule } from '../../../api/safty'

export default class componentName extends Component {
  state = {
    content: ''
  }
  componentDidMount = () => {
    this.fetch()
  }
  fetch = async () => {
    const res = await getJFRule()
    if (res.code === 20000) {
      this.setState({
        content: res.data ? res.data.integrate_notice : ''
      })
    }
  }
  render () {
    const { content } = this.state
    return (
      <div className='saftyModule jf_rule'>
        {
          content ? ReactHtmlParser(content) : <div className='not-yet'>--暂无内容--</div>
        }
      </div>
    )
  }
}
