import React, { Component } from 'react'
import { findTemplateId, req_findTemplateRecordId, req_findReportFileInfo, downloadFilesToZip } from '../../../api/api'
import IP from './../../../config/config'
import './index.less'

class ResourceDetail extends Component {

  state = {
    recordInfo: {},
    data: [],
    weekData: [
      {
        label: '周一',
        value: 1,
      },
      {
        label: '周二',
        value: 2,
      },
      {
        label: '周三',
        value: 3,
      },
      {
        label: '周四',
        value: 4,
      },
      {
        label: '周五',
        value: 5,
      },
      {
        label: '周六',
        value: 6,
      },
      {
        label: '周日',
        value: 7,
      },
    ],
  }

  componentDidMount = () => {
    this.findTemplateId(this.props.match.params.id)
    this.findTemplateRecordId(this.props.match.params.id)
  }

  // 根据模板id查看模板详情
  findTemplateId = async (templateId) => {
    const res = await findTemplateId({ templateId })
    if (res.code === 20000) {
      this.setState({
        recordInfo: res.data
      })
    }
  }

  // 根据模板id查看模板详情
  findTemplateRecordId = async (templateId) => {
    const { weekData } = this.state
    const res = await req_findTemplateRecordId({ templateId })
    if (res.code === 20000) {
      this.setState({
        data: res.data.map((item, key) => {
          item.key = key
          if (item.kc_week) {
            const week = weekData.find(x => x.value === item.kc_week).label
            item.week = week + ' ' + '第' + item.kc_ksjc + '-' + item.kc_jsjc + '节'
          }
          return item
        })
      })
    }
  }

  // 导出上传实验记录的学生信息
  handleExport = item => {
    window.location.href = `${IP.host}/report/templateinfo/excelExport?templateId=${item.template_id}&kcxh=${item.kc_xh}`
  }

  // 下载
  handleDownload = async (item) => {
    console.log(item)
    const res = await req_findReportFileInfo({
      templateId: item.template_id,
      kcxh: item.kc_xh
    })
    if (res.code === 20000) {
      const result = await downloadFilesToZip({
        zipName: res.data.zipName,
        filePaths: res.data.filePaths
      })
      if (result.code === 20000) {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
        console.log('是否是Android：', isAndroid); //true,false
        console.log('是否是iOS：', isIOS)
        if (isIOS) {
          window.location.href = IP.fileHost + result.data
        } else {
          window.location.href = `http://labmanage.sastc.cn/Zip.html?url=${IP.fileHost + result.data}`
        }
      }
    }
  }

  render() {
    const { recordInfo, data } = this.state
    return (
      <div className='resource_detail'>
        <div className='resource_detail_course'>{recordInfo.template_kcmc}</div>
        <div className='resource_detail_xnq'>{recordInfo.jzg_xm}&nbsp;&nbsp;&nbsp;&nbsp;{recordInfo.template_xn} {recordInfo.template_xq === '1' ? '秋季' : recordInfo.record_xq === '2' ? '春季' : '夏季'}学期</div>
        <div className='resource_detail_detail' style={{ paddingBottom: 60 }}>
          {
            (data || []).length ? <div className='resource_detail_con'>
              <div className='resource_detail_con_header'>
                <div className='resource_detail_con_header_title'>报告列表</div>
              </div>
              {
                data.map(item => {
                  return <div className='resource_detail_con_list' key={item.key} style={{ marginBottom: '16px', paddingBottom: '16px' }}>
                    <div className='resource_detail_con_list_left' style={{ alignItems: ' unset' }}>
                      <img style={{ width: '32px', height: '32px', marginTop: '4px' }} alt='' src={require('./../../../images/教学班.png')} />
                      <div className='resource_detail_con_list_left_con'>
                        <div className='resource_detail_con_list_left_con_name'>{item.kc_xh}</div>
                        <div className='resource_detail_con_list_left_con_desc'>{item.week ? item.week : '-'}&nbsp;&nbsp;|&nbsp;&nbsp;助教：{item.zj_xm ? item.zj_xm : '-'}</div>
                        <div className='sub_Num'>已提交{item.num}人</div>
                      </div>
                    </div>
                    <div className='ter_details_sub'>
                      <div className='resource_detail_con_list_xz' onClick={() => this.handleExport(item)}>
                        <img alt='' src={require('./../../../images/导出.png')} />
                      </div>
                      <div className='resource_detail_con_list_xz' onClick={() => this.handleDownload(item)}>
                        <img alt='' src={require('./../../../images/下载导出.png')} />
                      </div>
                    </div>
                  </div>
                })
              }
            </div> : <div className='not-yet'>--暂无报告记录--</div>
          }
        </div>
      </div>
    )
  }
}

export default ResourceDetail
