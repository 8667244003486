import React, { Component } from 'react'
import { Button } from 'antd'
import { List, Toast, InputItem, Picker } from 'antd-mobile'
import { getBanZj, saveBanZj, req_findJcInfo } from '../../../api/api'

class TestBan extends Component {

  state = {
    templateId: '',
    bjData: [
      {
        name: '',
        gh: undefined,
        xm: '',
        kc_week: [],
        kc_ksjc: [],
        kc_jsjc: [],
      }
    ],
    weekData: [
      {
        label: '周一',
        value: 1,
      },
      {
        label: '周二',
        value: 2,
      },
      {
        label: '周三',
        value: 3,
      },
      {
        label: '周四',
        value: 4,
      },
      {
        label: '周五',
        value: 5,
      },
      {
        label: '周六',
        value: 6,
      },
      {
        label: '周日',
        value: 7,
      },
    ],
    sectionData: []
  }

  componentDidMount = () => {
    this.findJcInfo()
    const id = this.props.match.params.templateId
    const sta_id = this.props.match.params.sta_id
    this.setState({
      templateId: id
    })
    if (sta_id !== '0') {
      this.fetchTemplate(id)
    }
  }

  // 查询上课节次
  findJcInfo = async () => {
    const res = await req_findJcInfo()
    if (res.code === 20000) {
      this.setState({
        sectionData: res.data.map(item => {
          return {
            label: '第' + item.jcmc + '节',
            value: item.jc,
          }
        })
      })
    }
  }

  // 查询模板详情
  fetchTemplate = async templateId => {
    const res = await getBanZj({ templateId })
    if (res.code === 20000) {
      const data = res.data.map((x, i) => {
        return {
          name: x.kc_xh,
          gh: x.zj_gh ? String(x.zj_gh) : undefined,
          xm: x.zj_xm,
          kc_week: [x.kc_week],
          kc_ksjc: [x.kc_ksjc],
          kc_jsjc: [x.kc_jsjc],
        }
      })
      this.setState({
        bjData: data
      })
    }
  }

  // 提交
  handleSubmit = async () => {
    const { templateId, bjData } = this.state
    if (bjData.filter(x => x.name).length !== bjData.length) return Toast.info('班级不能为空！')
    if (bjData.filter(x => x.kc_week).length !== bjData.length) return Toast.info('课程星期不能为空！')
    if (bjData.filter(x => x.kc_ksjc).length !== bjData.length) return Toast.info('开始节次不能为空！')
    if (bjData.filter(x => x.kc_jsjc).length !== bjData.length) return Toast.info('结束节次不能为空！')
    const obj = {}
    obj.templateId = templateId - 0
    obj.zjInfo = JSON.stringify(bjData.map(b => {
      b.kc_xh = b.name
      b.zj_gh = b.gh ? b.gh : ''
      b.zj_xm = b.xm
      b.kc_week = b.kc_week.join(',')
      b.kc_ksjc = b.kc_ksjc.join(',')
      b.kc_jsjc = b.kc_jsjc.join(',')
      return b
    }))
    const res = await saveBanZj(obj)
    if (res.code === 20000) {
      Toast.info(res.message)
      setTimeout(() => {
        this.props.history.go(-1)
      }, 1500)
    }
  }

  // 修改班级
  changeBj = (e, item, k) => {
    const { bjData } = this.state
    this.setState({
      bjData: bjData.map((x, i) => {
        if (i === k) {
          return {
            ...x,
            name: e
          }
        }
        return x
      })
    })
  }

  // 新增班级
  handleAddClass = () => {
    this.setState({
      bjData: [...this.state.bjData, {
        name: '',
        gh: undefined,
        xm: '',
        kc_week: [],
        kc_ksjc: [],
        kc_jsjc: [],
      }]
    })
  }

  // 删除班级
  handleRemove = (item, k) => {
    const { bjData } = this.state
    const arr = bjData.filter((x, i) => i !== k)
    this.setState({
      bjData: arr
    })
  }

  //改变可成星期
  changeWeek = (e, item, k) => {
    this.setState({
      bjData: this.state.bjData.map((b, i) => {
        if (i === k) {
          return {
            ...b,
            kc_week: e
          }
        }
        return b
      })
    })
  }

  //开始节次
  changeKs = (e, item, k) => {
    this.setState({
      bjData: this.state.bjData.map((b, i) => {
        if (i === k) {
          return {
            ...b,
            kc_ksjc: e
          }
        }
        return b
      })
    })
  }

  //结束节次
  changeJs = (e, item, k) => {
    this.setState({
      bjData: this.state.bjData.map((b, i) => {
        if (i === k) {
          return {
            ...b,
            kc_jsjc: e
          }
        }
        return b
      })
    })
  }

  render() {
    const { bjData, weekData, sectionData } = this.state
    return (
      <div className='testMb'>
        {
          (bjData || []).map((item, k) => {
            return <div className='BanZj' key={k}>
              <div className='testMb_title'>
                <div>教学班</div>
                <div>
                  {
                    (bjData || []).length > 1 ? <img src={require('./../../../images/text_mb_jian.png')} onClick={() => this.handleRemove(item, k)} alt='' /> : ''
                  }
                </div>
              </div>
              <div>
                <div className='testMb_list testMb_bj' >
                  <InputItem value={item.name} placeholder="请输入教学班" onChange={e => this.changeBj(e, item, k)}>
                    教学班
                  </InputItem>
                </div>
              </div>
              <div className='testMb_picker'>
                <Picker
                  extra={<div className='testMb_picker_tips'>课程星期</div>}
                  data={weekData}
                  cols={1}
                  value={item.kc_week}
                  onChange={e => {
                    this.changeWeek(e, item, k)
                  }}
                >
                  <List.Item arrow="horizontal">课程星期</List.Item>
                </Picker>
              </div>
              <div className='testMb_picker'>
                <Picker
                  extra={<div className='testMb_picker_tips'>开始节次</div>}
                  data={item.kc_jsjc.length ? sectionData.filter(x => x.value < Number(item.kc_jsjc.join(','))) : sectionData}
                  cols={1}
                  value={item.kc_ksjc}
                  onChange={e => {
                    this.changeKs(e, item, k)
                  }}
                >
                  <List.Item arrow="horizontal">开始节次</List.Item>
                </Picker>
              </div>

              <div className='testMb_picker'>
                <Picker
                  extra={<div className='testMb_picker_tips'>结束节次</div>}
                  data={item.kc_ksjc.length ? sectionData.filter(x => x.value > Number(item.kc_ksjc.join(','))) : sectionData}
                  cols={1}
                  value={item.kc_jsjc}
                  onChange={e => {
                    this.changeJs(e, item, k)
                  }}
                >
                  <List.Item arrow="horizontal">结束节次</List.Item>
                </Picker>
              </div>
            </div>
          })
        }
        <div className='testMb_add'>
          <Button className='testMb_add_btn' onClick={() => this.handleAddClass()}>新增教学班</Button>
        </div>
        <div className='testMb_btn'>
          <div className='testMb_btn_list' onClick={() => this.handleSubmit()}>保存</div>
        </div>
      </div>
    )
  }
}

export default TestBan
