import React, { Component } from 'react'
import { SearchBar } from 'antd-mobile'
import { Cascader, Field } from 'react-vant'
import { findSpaceInfo, findRelayInfoDevice, issueAllKgRelay } from '../../../api/api'
import '../controller/controller.less'

class Lighting extends Component {

  state = {
    searchContent: '', // 搜索值
    lightData: [], // 照明设备
    roomData: [], // 楼层下的房间
    buildId: undefined, // 楼宇id
    floorId: undefined, // 楼层id
    roomId: undefined, // 房间id
  }

  componentDidMount = () => {
    document.title = "智慧照明"
    this.fetch()
    this.fetchAllRoom()
  }

  fetch = async () => {
    const { searchContent, buildId, floorId, roomId } = this.state
    const res = await findRelayInfoDevice({ spaceId: roomId ? roomId : floorId ? floorId : buildId ? buildId : '', roomName: searchContent })
    if (res.code === 20000) {
      this.setState({
        lightData: res.data
      })
    }
  }
  //查询所有房间
  fetchAllRoom = async () => {
    const res = await findSpaceInfo()
    if (res.code === 20000) {
      let menuList = res.data.map(item => {
        return {
          text: item.dwmc,
          parentId: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
        }
      })
      menuList = menuList.map(item => {
        if (menuList.filter(p => p.parentId === item.value).length) {
          item.children = menuList.filter(p => p.parentId === item.value)
        }
        return item
      }).filter(p => p.parentId === '0')
      this.setState({
        roomData: menuList
      })
    }
  }

  // 搜索房间
  handleChange = e => {
    this.setState({
      searchContent: e
    }, () => {
      this.fetch()
    })
  }

  handleCancel = () => {
    this.setState({
      searchContent: ''
    }, () => {
      this.fetch()
    })
  }

  // 一键开关
  handleLightSwitch = async (e, item, relayState) => {
    e.stopPropagation()
    const res = await issueAllKgRelay({ relayId: item.relay_id, mac: item.relay_mac, relayAddress: item.relay_address, relayState })
    if (res.code === 20000) {
      this.fetch()
    }
  }
  // 选中项变化时触发
  handleCascaderChange = (val, selectedRows, actions) => {
    // console.log(val, selectedRows, actions, 'xxxxxx')
  }

  // 全部选项选择完成
  handleCascaderFinish = (val, selectedRows, actions) => {
    this.setState({
      roomId: val[val.length - 1],
    }, () => {
      this.fetch()
    })
  }
  render () {
    const { roomData, lightData, buildData, floorData, buildId, floorId, roomId } = this.state
    return (
      <div className='banpai'>
        <div className='banpai_img'>
          <img src={require('./../../../img/controll/light-1.png')} alt='' />
          <div className='banpai_search'>
            <SearchBar placeholder="搜索房间" onCancel={() => this.handleCancel()} onChange={e => this.handleChange(e)} />
          </div>
        </div>
        <div className="banpai_title">

          <div>设备列表</div>
          <div className='banpai_cascader'>
            <Cascader
              popup={{ round: true }}
              title='选择房间'
              activeColor="#00B4C4"
              options={roomData}
              onFinish={this.handleCascaderFinish}
            >
              {(_, selectedRows, actions) => (
                <Field
                  isLink
                  className='controller_cascader'
                  value={selectedRows.length > 0 ? selectedRows[selectedRows.length - 1].text : ''}
                  readOnly
                  placeholder='全部房间'
                  onClick={() => actions.open()}
                />
              )}
            </Cascader>
          </div>
        </div>
        <div className='banpai_con'>
          {
            (lightData || []).length ? lightData.map(item => {
              return <div className='controller_list_item' key={item.relay_id} onClick={() => this.props.history.push(`/module/airSwitch/${item.relay_id}`)}>
                <div className='controller_item_title'>
                  {item.relay_name}
                </div>
                <div className='controller_item_room'>
                  {item.building_name}-{item.floor_name}-{item.room_name}
                </div>
                <div className='controller_item_status'>
                  <div className={item.relay_state === 1 ? 'controller_item_status_onLine' : 'controller_item_status_offLine'}>
                    <div></div>
                    <div>{item.relay_state === 1 ? '设备在线' : '设备离线'}</div>
                  </div>
                  <div className='controller_item_line'></div>
                  <div className={'controller_item_status_use ' + (item.startNum ? 'hasOpen' : 'noOpen')}>
                    <span >{item.startNum ? "已开启" : "未开启"}</span>
                    <span>{item.startNum}/{item.totalNum}</span>
                  </div>
                </div>
                {
                  (item.deviceInfoEntities || []).length ?
                    <div className='controller_item_num'>
                      {
                        item.deviceInfoEntities.map(i => {
                          return <span className={i.device_state === 1 ? 'open' : i.device_state === 2 ? 'close' : ''} key={i.device_id}>{i.device_name}</span>
                        })
                      }
                    </div> : ''
                }

                <div className='controller_item_but'>
                  <div onClick={e => this.handleLightSwitch(e, item, 2)}>
                    <img alt='' src={require('../../../img/controll/OFF.png')} />
                    一键关闭
                  </div>
                  <div onClick={e => this.handleLightSwitch(e, item, 1)}>
                    <img alt='' src={require('../../../img/controll/ON.png')} />
                    一键开启
                  </div>

                </div>
              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
        {/* <div className='lighting_con'>
          {
            (lightData || []).length ? lightData.map(item => {
              return <div className='lighting_con_list' key={item.relay_id} onClick={() => this.props.history.push(`/module/airSwitch/${item.relay_id}`)}>
                <div className='lighting_con_list_header'>
                  <div className='lighting_con_list_left'>
                    <div className='lighting_con_list_left_top'>
                      <div className='lighting_con_list_left_name'>{item.relay_name}</div>
                      {
                        item.relay_state === 1 ? <div className='lighting_con_list_left_top_status status_online'>在线</div> : <div className='lighting_con_list_left_top_status status_offline'>离线</div>
                      }
                    </div>
                    <div className='lighting_con_list_left_address'>
                      {item.building_name}/{item.floor_name}/{item.room_name}
                    </div>
                  </div>
                  <div className='lighting_con_list_right'>
                    <div className='lighting_con_list_right_con'>
                      <span className='lighting_con_list_right_con_open'>{item.startNum}</span>
                      <span className='lighting_con_list_right_con_all'>/{item.totalNum}</span>
                    </div>
                    <div className='lighting_con_list_right_con_desc'>开启设备</div>
                  </div>
                </div>
                <div className='lighting_con_list_footer'>
                  <div className='lighting_con_list_footer_list' onClick={e => this.handleLightSwitch(e, item, 1)}>
                    <img alt='' src={require('../../../images/yjkj.png')} />
                    <span>一键开启</span>
                  </div>
                  <div className='lighting_con_list_footer_list' onClick={e => this.handleLightSwitch(e, item, 2)}>
                    <img alt='' src={require('../../../images/yjgj.png')} />
                    <span>一键关闭</span>
                  </div>
                </div>
              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div> */}
      </div>
    )
  }
}

export default Lighting
