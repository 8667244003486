import React, { Component } from 'react'
import { req_selectOne, } from './../../../api/device'
import moment from 'moment'

class deviceDetail extends Component {

  state = {
    data: {},
    visible: false,
    opinion: '',
  }

  componentDidMount = () => {
    document.title = '使用详情'
    window.scrollTo(0, 0)
    this.fetch(this.props.match.params.id)
  }

  fetch = async id => {
    const res = await req_selectOne({ id })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className='approveDetail' style={{ padding: '0px 0px 76px' }}>
        <div className='approve_detail_head'>
          <div className='approve_detail_left'>
            <div className='approve_detail_left_title'>
              {data.equipment_name}
            </div>
            <div className='approve_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='approve_list_head_date'>
                {moment(data.use_date).format('YYYY-MM-DD')}
              </div>
              <div className='approve_list_head_seat'>
                {data.use_time}
              </div>
              <div className='approve_list_head_seat'>
                第{data.use_section}节
              </div>
            </div>
          </div>
          <div className='approve_detail_right'>

          </div>
        </div>
        <div className='approve_detail_cont'>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>仪器编号</div>
              <div>
                {data.equipment_code}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>所属实验室</div>
              <div>{data.room_name}</div>
            </div>
            <div className='approve_detail_item'>
              <div>所属部门</div>
              <div>{data.dept_name}</div>
            </div>
          </div>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>课程名称</div>
              <div>{data.course_name}</div>
            </div>
            <div className='approve_detail_item'>
              <div>任课教师</div>
              <div>{data.teacher_name}</div>
            </div>

          </div>

        </div>

        <div className='approve_detail_bar'>
          <div className='approve_detail_item'>
            <div>建议反馈</div>
            <div>{data.feedback}</div>
          </div>
        </div>

      </div>
    )
  }
}

export default deviceDetail