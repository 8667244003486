
import React, { Component } from 'react';
import { Tabs, Badge, Icon } from 'antd-mobile';
import { findAppApprovalOrderRecord } from '../../../../api/repairApi'
import { createForm } from 'rc-form';
import moment from 'moment'
import cookie from 'react-cookies'
import './index.less'

class MaintenanceTask extends Component {
  state = {
    visible: false,
    dataList: [],
    state: 0
  }

  componentDidMount() {
    window.document.title = '维修工单'
    this.findAppApprovalOrderRecord(0)
  }

  //查询所有任务
  findAppApprovalOrderRecord = (state) => {
    this.setState({
      state
    })
    // 保养状态:0待保养 1 保养中 2 已完成
    findAppApprovalOrderRecord({ flag: state == 0 ? 1 : 2, userId: cookie.load('userData').id }).then(res => {
      if (res.code === 20000) {
        this.setState({
          dataList: res.data || []
        })
      }
    })
  }
  onSelect = async (otp) => {
    await this.findAppMaintenancetask(otp.props.value)
    await this.setState({
      visible: false
    })
  }
  render() {
    const { dataList, state } = this.state
    const tabs = [
      { title: <Badge>待审批<span>{state === 0 ? `(${dataList.length})` : ''}</span></Badge> },
      { title: <Badge>已审批<span>{state === 1 ? `(${dataList.length})` : ''}</span></Badge> },
    ]
    const style = (state) => {
      if (state === 0) {//0待保养 1 保养中 2 已完成
        return 'content_title_botton_dai'
      } else if (state === 1) {
        return 'content_title_botton_yi'
      } else if (state === 2) {
        return 'content_title_botton_over'
      } else {
        return 'content_title_botton_all'
      }
    }
    return (
      <div className="maintenanceTask">
        <div className="maintenanceTask_box">
          <Tabs tabs={tabs}
            initialPage={0}
            // tabBarActiveTextColor='red'
            // renderTabBar={this.renderTabBar}
            onChange={(tab, index) => {
              this.findAppApprovalOrderRecord(index)
            }}
          >
            {/* 待审批 */}
            <div className="maintenanceTask_content">
              {
                (dataList || []).map((item, index) => {
                  return <div key={index} onClick={() => { this.props.history.push(`/module/smartRepair/approvalDetails/${item.order_id}`) }} className="content">
                    <div className="content_title">
                      <div className="content_title_code">{item.order_id}</div>
                      <div className={style(0)}>
                        {/* <span className="span_01">待</span> */}
                        <span className="span_02">待审批</span>
                        <span className="span_03"><Icon type='right' /></span>
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className='content_c'>
                      <div className="content_c_01"><span>类型: </span><span>{item.fault_type_name}</span></div>
                      <div className="content_c_02"><span>问题描述: </span><span>{item.description}</span></div>
                      <div className="content_c_03"><span><img width="18px" src={require('../../../../images/smartRepair/2.png')} alt="" /> </span><span>{(item.building_name ? item.building_name : '') + ' ' + (item.room_name ? item.room_name : '')}</span></div>
                      <div className="content_c_04">
                        <span><img width="18px" src={require('../../../../images/smartRepair/1.png')} alt="" /> <span>{item.user_name}</span> </span>
                        <span>报修时间: <span>{moment(item.create_time).format('YYYY-MM-DD ')}</span></span>
                      </div>
                    </div>
                  </div>
                })
              }
            </div>
            {/* 已完成 */}
            <div className="maintenanceTask_content">
              {
                (dataList || []).map((item, index) => {
                  return <div key={index} onClick={() => { this.props.history.push(`/module/smartRepair/approvalDetails2/${item.order_id}`) }} className="content">
                    <div className="content_title">
                      <div className="content_title_code">{item.order_id}</div>
                      <div className={style(2)}>
                        <span className="span_02">已审批</span>
                        <span className="span_03"><Icon type='right' /></span>
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className='content_c'>
                      <div className="content_c_01"><span>类型: </span><span>{item.fault_type_name}</span></div>
                      <div className="content_c_02"><span>问题描述: </span><span>{item.description}</span></div>
                      <div className="content_c_03"><span><img width="18px" src={require('../../../../images/smartRepair/2.png')} alt="" /> </span><span>{(item.building_name ? item.building_name : '') + ' ' + (item.room_name ? item.room_name : '')}</span></div>
                      <div className="content_c_04">
                        <span><img width="18px" src={require('../../../../images/smartRepair/1.png')} alt="" /> <span>{item.user_name}</span> </span>
                        <span>报修时间: <span>{moment(item.create_time).format('YYYY-MM-DD ')}</span></span>
                      </div>
                    </div>
                  </div>
                })
              }

            </div>
          </Tabs>
        </div>
        <div className="title-bottom">没有更多工单</div>
      </div>
    );
  }
}

export default createForm()(MaintenanceTask);