import React, { Component } from 'react'
import { findRelayDevice, issueAllKgRelay } from '../../../api/api'
import './room_lighting.less'

class RoomLighting extends Component {

  state = {
    data: {},
    roomInfo: {}
  }

  componentDidMount = () => {
    document.title = "照明设备"
    this.fetch(this.props.match.params.id)
    this.setState({
      roomInfo: this.props.location.state.roomInfo,
    })
  }

  fetch = async roomId => {
    const res = await findRelayDevice({ roomId })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  // 一键开关
  handleLightSwitch = async (e, item, relayState) => {
    e.stopPropagation()
    const res = await issueAllKgRelay({ relayId: item.relay_id, mac: item.relay_mac, relayAddress: item.relay_address, relayState })
    if (res.code === 20000) {
      this.fetch(this.props.match.params.id)
    }
  }

  render() {
    const { data, roomInfo } = this.state
    return (
      <div className='roomLighting'>
        <div className='shortTerm_name'>{roomInfo.wzName}</div>
        <div className='shortTerm_addr'>{roomInfo.buildingName ? roomInfo.buildingName.split('/')[0] : ''}</div>
        <div className='roomLighting_con'>
          {
            (data || []).length ? data.map(item => {
              return <div className='controller_list_item' key={item.relay_id} onClick={() => this.props.history.push(`/module/airSwitch/${item.relay_id}`)}>
                <div className='controller_item_title'>
                  {item.relay_name}
                </div>
                <div className='controller_item_room'>
                  {item.building_name}-{item.floor_name}-{item.room_name}
                </div>
                <div className='controller_item_status'>
                  <div className={item.relay_state === 1 ? 'controller_item_status_onLine' : 'controller_item_status_offLine'}>
                    <div></div>
                    <div>{item.relay_state === 1 ? '设备在线' : '设备离线'}</div>
                  </div>
                  <div className='controller_item_line'></div>
                  <div className={'controller_item_status_use ' + (item.startNum ? 'hasOpen' : 'noOpen')}>
                    <span >{item.startNum ? "已开启" : "未开启"}</span>
                    <span>{item.startNum}/{item.totalNum}</span>
                  </div>
                </div>
                {
                  (item.deviceInfoEntities || []).length ?
                    <div className='controller_item_num'>
                      {
                        item.deviceInfoEntities.map(i => {
                          return <span className={i.device_state === 1 ? 'open' : i.device_state === 2 ? 'close' : ''} key={i.device_id}>{i.device_name}</span>
                        })
                      }
                    </div> : ''
                }

                <div className='controller_item_but'>
                  <div onClick={e => this.handleLightSwitch(e, item, 2)}>
                    <img alt='' src={require('../../../img/controll/OFF.png')} />
                    一键关闭
                  </div>
                  <div onClick={e => this.handleLightSwitch(e, item, 1)}>
                    <img alt='' src={require('../../../img/controll/ON.png')} />
                    一键开启
                  </div>

                </div>
              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
      </div>
    )
  }
}

export default RoomLighting
