import React, { Component } from 'react'
import { Toast, TextareaItem } from 'antd-mobile'
import { findSubByID, cancelSubByID } from '../../../api/api'
import moment from 'moment'
import IP from '../../../config/config'
import './appointment.less'

class AppointmentDetail extends Component {

  state = {
    data: {},
    approveData: [], // 审批流程
  }

  componentDidMount = () => {
    this.fetch(this.props.match.params.id)
  }

  fetch = async recordId => {
    const res = await findSubByID({ recordId })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {},
        approveData: res.data && res.data.approvalProcessRecordEntities ? res.data.approvalProcessRecordEntities : []
      })
    }
  }

  // 取消预约
  handleClose = async () => {
    const { data } = this.state
    const res = await cancelSubByID({ record_id: data.record_id })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.props.history.go(-1)
    }
  }

  getweekday = (date) => {
    var weekArray = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
    var week = weekArray[new Date(date).getDay()];
    return week;
  }

  render() {
    const { data, approveData } = this.state
    return (
      <div className='appointmentDetail' style={{ padding: '0px' }}>

        <div className='approve_detail_head'>
          <div className='approve_detail_left'>
            <div className='approve_detail_left_title'>
              {data.room}
            </div>
            <div className='approve_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='approve_list_head_date'>
                {data.date_time} {this.getweekday(data.date_time)} 第{data.nodeInfo}节
              </div>
              {data.seat_name && data.seat_number ? (<div className='approve_list_head_seat'>座位号：{data.seat_name}{data.seat_number}</div>) : ''}

            </div>
          </div>
          <div className='approve_detail_right'>
            {/* {data.pr_approval_result === 1 ? (<img src={require('./../../../images/new/21.png')} alt='' />) : ('')} */}
          </div>
        </div>
        <div className='approve_detail_cont'>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>实验室名称</div>
              <div>{data.room}</div>
            </div>
            <div className='approve_detail_item'>
              <div>预约时间</div>
              <div>
                <div>{data.date_time} 第{data.nodeInfo}节</div>
              </div>
            </div>
            {
              data.seat_name && data.seat_number ? (<div className='approve_detail_item'>
                <div>座位号</div>
                <div>{data.seat_name}{data.seat_number}</div>
              </div>) : ""
            }

          </div>

          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>实验用途</div>
              <div>{data.syyt_name}</div>
            </div>
            <div className='approve_detail_item'>
              <div>实验目的</div>
              <div>{data.symd}</div>
            </div>
            <div className='approve_detail_item'>
              <div>实验内容</div>
              <div>{data.synr}</div>
            </div>
            <div className='approve_detail_item'>
              <div>实验要求</div>
              <div>{data.syyq}</div>
            </div>
          </div>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>姓名</div>
              <div>{data.reservationist}</div>
            </div>
            <div className='approve_detail_item'>
              <div>性别</div>
              <div>{data.reservationist_sex ? '男' : '女'}</div>
            </div>
            <div className='approve_detail_item'>
              <div>学号</div>
              <div>{data.reservationist_number}</div>
            </div>
            {
              data.yxmc ? (<div className='approve_detail_item'>
                <div>院系</div>
                <div>{data.yxmc}</div>
              </div>) : (<div className='approve_detail_item'>
                <div>部门</div>
                <div>{data.dep_name}</div>
              </div>)
            }
            {
              data.zymc ? (<div className='approve_detail_item'>
                <div>专业</div>
                <div>{data.zymc}</div>
              </div>) : ('')
            }
            {
              data.bjmc ? (<div className='approve_detail_item'>
                <div>班级</div>
                <div>{data.bjmc}</div>
              </div>) : ('')
            }

          </div>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>提交时间</div>
              <div>
                {
                  data.submit_time ? moment(data.submit_time).format('YYYY/MM/DD HH:mm') : moment(new Date()).format('YYYY/MM/DD HH:mm')
                }
              </div>
            </div>
          </div>
        </div>

        {/* <div className='appointmentDetail_con'>
          <div className='appointmentDetail_detail'>
            <div className='appointmentDetail_detail_list'>
              <div className='appointmentDetail_detail_list_name'>实验室名称</div>
              <div className='appointmentDetail_detail_list_con'>{data.room}</div>
            </div>
            <div className='appointmentDetail_detail_list'>
              <div className='appointmentDetail_detail_list_name'>所属位置</div>
              <div className='appointmentDetail_detail_list_con'>{data.buildingName}</div>
            </div>
            <div className='appointmentDetail_detail_list'>
              <div className='appointmentDetail_detail_list_name'>预约时间</div>
              <div className='appointmentDetail_detail_list_con'>{data.date_time} 第{data.nodeInfo}节</div>
            </div>
            <div className='appointmentDetail_detail_list'>
              <div className='appointmentDetail_detail_list_name'>座位号</div>
              <div className='appointmentDetail_detail_list_con'>{data.seat_name}{data.seat_number}</div>
            </div>
            <div className='appointmentDetail_detail_list'>
              <div className='appointmentDetail_detail_list_name'>姓名</div>
              <div className='appointmentDetail_detail_list_con'>{data.reservationist}</div>
            </div>
            <div className='appointmentDetail_detail_list'>
              <div className='appointmentDetail_detail_list_name'>性别</div>
              <div className='appointmentDetail_detail_list_con'>{data.reservationist_sex ? '男' : '女'}</div>
            </div>
            <div className='appointmentDetail_detail_list'>
              <div className='appointmentDetail_detail_list_name'>学号</div>
              <div className='appointmentDetail_detail_list_con'>{data.reservationist_number}</div>
            </div>
            {
              data.yxmc ? <div className='appointmentDetail_detail_list'>
                <div className='appointmentDetail_detail_list_name'>院系</div>
                <div className='appointmentDetail_detail_list_con'>{data.yxmc}</div>
              </div> : <div className='appointmentDetail_detail_list'>
                <div className='appointmentDetail_detail_list_name'>部门</div>
                <div className='appointmentDetail_detail_list_con'>{data.dep_name}</div>
              </div>
            }
            {
              data.zymc ? <div className='appointmentDetail_detail_list'>
                <div className='appointmentDetail_detail_list_name'>专业</div>
                <div className='appointmentDetail_detail_list_con'>{data.zymc}</div>
              </div> : ''
            }
            {
              data.yxmc ? <div className='appointmentDetail_detail_list'>
                <div className='appointmentDetail_detail_list_name'>班级</div>
                <div className='appointmentDetail_detail_list_con'>{data.dep_name}</div>
              </div> : ''
            }
            <div className='appointmentDetail_detail_list'>
              <div className='appointmentDetail_detail_list_name'>实验用途</div>
              <div className='appointmentDetail_detail_list_con'>{data.syyt_name}</div>
            </div>
            <div className='appointmentDetail_detail_item'>
              <div className='appointmentDetail_detail_item_name'>实验目的</div>
              <TextareaItem
                disabled
                rows={3}
                value={data.symd}
              />
            </div>
            <div className='appointmentDetail_detail_item'>
              <div className='appointmentDetail_detail_item_name'>实验内容</div>
              <TextareaItem
                disabled
                rows={3}
                value={data.synr}
              />
            </div>
            <div className='appointmentDetail_detail_item'>
              <div className='appointmentDetail_detail_item_name'>实验要求</div>
              <TextareaItem
                disabled
                rows={3}
                value={data.syyq}
              />
            </div>
            <div className='appointmentDetail_detail_list'>
              <div className='appointmentDetail_detail_list_name'>提交时间</div>
              <div className='appointmentDetail_detail_list_con'>{
                data.submit_time ? moment(data.submit_time).format('YYYY/MM/DD HH:mm') : moment(new Date()).format('YYYY/MM/DD HH:mm')
              }</div>
            </div>
          </div>
        </div> */}
        {
          (approveData || []).length ? <div className='appointmentDetail_process'>
            <div className='appointmentDetail_process_detail'>
              <div className='appointmentDetail_process_title'>审批流程</div>
              <div className='appointmentDetail_process_con'>
                {
                  approveData.map((item, index) => {
                    return <div className="progess-bar" key={index + 1}>
                      <div className={`progress ${index ? ((item.pr_approval_result !== 1) && (item.pr_approval_result !== 0)) ? 'active' : 'negative' : ''}`}></div>
                      <div className="approval-detail">
                        <div className='approval-detail-people'>
                          {
                            item.pr_approval_user_img ? <img alt='' src={IP.fileHost + item.pr_approval_user_img} /> : <img alt='' src={require('../../../images/avatar.png')} />
                          }
                          {/* <span>{item.pr_auth_name}</span> */}
                          {
                            (item.pr_approval_result === 1 || item.pr_approval_result === 0) ? <span>{item.pr_auth_name}</span> : <span>{item.pr_auth_name}：{item.pr_approval_username}</span>
                          }
                        </div>
                        <div className='approval-detail-status'>
                          {
                            item.pr_approval_result === 2 ? <span className='approval-success'>审批成功</span> : item.pr_approval_result === 3 ? <span className='approval-reject'>审批驳回</span> : <span className='approval-wait'>待审批</span>
                          }
                          <span>{item.pr_approval_time}</span>
                        </div>
                        <div className='approval-detail-opinion'>{item.pr_approval_opinion}</div>
                      </div>
                      <div className={`ball ${(item.pr_approval_result === 1 || item.pr_approval_result === 0) ? 'negative' : 'active'}`}></div>
                    </div>
                  })
                }
              </div>
            </div>
          </div> : ''
        }
        {
          data.subscribe_status === 0 ? <div className='appointmentDetail_btn'>
            <div className='appointmentDetail_btn_list' onClick={() => this.handleClose()}>取消预约</div>
          </div> : ''
        }
      </div>
    )
  }
}

export default AppointmentDetail
