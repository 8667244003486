import React, { Component } from 'react'
import { SearchBar, Toast } from 'antd-mobile'
import { Cascader, Field } from 'react-vant'
import { findSpaceInfo, findEnvironmentInfo } from '../../../api/api'
import './environment.less'

class Environment extends Component {

  state = {
    searchContent: '', // 搜索值
    environmentData: [], // 环境监测设备
    visible: false,
    curveData: [], // 数据曲线
    roomData: [], // 位置数据
    buildId: undefined, // 楼宇id
    floorId: undefined, // 楼层id
    roomId: undefined, // 房间id
  }

  componentDidMount = () => {
    document.title = "环境监测"
    this.fetch()
    this.fetchBuild()
  }

  fetch = async () => {
    Toast.loading('', 0)
    const { searchContent, buildId, floorId, roomId } = this.state
    const res = await findEnvironmentInfo({ spaceId: roomId ? roomId : floorId ? floorId : buildId ? buildId : '', roomName: searchContent })
    if (res.code === 20000) {
      Toast.hide()
      this.setState({
        environmentData: res.data
      })
    }
  }

  // 查询位置
  fetchBuild = async () => {
    const res = await findSpaceInfo()
    if (res.code === 20000) {
      let menuList = res.data.map(item => {
        return {
          text: item.dwmc,
          parentId: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
        }
      })
      menuList = menuList.map(item => {
        if (menuList.filter(p => p.parentId === item.value).length) {
          item.children = menuList.filter(p => p.parentId === item.value)
        }
        return item
      }).filter(p => p.parentId === '0')
      this.setState({
        roomData: menuList
      })
    }
  }

  // 搜索房间
  handleChange = e => {
    this.setState({
      searchContent: e
    }, () => {
      this.fetch()
    })
  }

  handleCancel = () => {
    this.setState({
      searchContent: ''
    }, () => {
      this.fetch()
    })
  }

  // 全部选项选择完成
  handleCascaderFinish = (val, selectedRows, actions) => {
    this.setState({
      roomId: val[val.length - 1],
    }, () => {
      this.fetch()
    })
  }

  // 显示文字
  handleWord = val => {
    // 设备状态：1 在线 2 离线
    if (val.device_state === 1) {
      return val.parameter_value ? val.parameter_value : '--'
    } else {
      return '离线'
    }
  }

  render() {
    const { roomData, environmentData } = this.state
    return (
      <div className='environment' >
        <div className='banpai_img'>
          <img alt='' src={require('../../../images/hjjcsy.png')} />
          <div className='banpai_search'>
            <SearchBar placeholder="请输入房间信息" onCancel={() => this.handleCancel()} onChange={e => this.handleChange(e)} />
          </div>
        </div>
        <div className='banpai_title'>
          <span>设备列表</span>
          <div className='banpai_cascader'>
            <Cascader
              popup={{ round: true }}
              title='选择实验室'
              activeColor="#00B4C4"
              options={roomData}
              onFinish={this.handleCascaderFinish}
            >
              {(_, selectedRows, actions) => (
                <Field
                  isLink
                  // value={selectedRows.map(el => el.text).join(',')}
                  value={selectedRows.length > 0 ? selectedRows[selectedRows.length - 1].text : ''}
                  readOnly
                  className='door_lock_cascader'
                  placeholder='全部房间'
                  onClick={() => actions.open()}
                />
              )}
            </Cascader>
          </div>
        </div>
        <div className='roomEnvironment_con'>
          {/* {
            (environmentData || []).length ? environmentData.map(item => {
              return <div className='roomEnvironment_con_list' key={item.parameter_id} onClick={() => this.props.history.push({ pathname: '/module/environment/detail', state: { item } })}>
                <div className='roomEnvironment_con_list_top'>
                  <div className='roomEnvironment_con_list_l'>
                    <div className='roomEnvironment_con_list_l_name'>{item.device_name}</div>
                    <div className='roomEnvironment_con_list_l_addr'>{item.roomName}</div>
                  </div>
                  <div className='roomEnvironment_con_list_r'>
                    <div className='roomEnvironment_con_list_r_sj'>
                      {
                        this.handleWord(item) === '离线' ? <span style={{ color: '#969799' }}>离线</span> : this.handleWord(item)
                      }
                      {this.handleWord(item) === '离线' ? '' : item.parameter_unit}
                    </div>
                    <div className='roomEnvironment_con_list_r_type'>{item.parameter_name}</div>
                  </div>
                </div>
                {
                  item.device_state === 1 ? <div className='roomEnvironment_con_list_state roomEnvironment_status_online'>
                    <span></span>
                    <span>在线</span>
                  </div> : <div className='roomEnvironment_con_list_state roomEnvironment_status_offline'>
                    <span></span>
                    <span>离线</span>
                  </div>
                }
              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          } */}
          {(environmentData || []).length ? environmentData.map((item, key) => {
            return <div className='room_inner_device' key={item.room_id}>
              <div className='room_name'><span></span>{item.roomName}({item.room_number})</div>
              <span className='sub_title'>在线设备<strong>{item.zxNum}</strong>/{item.totalNum}</span>
              {(item.deviceParameterInfoEntities || []).length ? item.deviceParameterInfoEntities.map((i, k) => {
                return <div className={`device_list ${i.device_state === 1 ? 'on' : 'off'}`} key={k} onClick={() => {
                  const k = { ...i }
                  k.roomName = item.roomName
                  k.room_number = item.room_number
                  this.props.history.push({ pathname: '/module/environment/detail', state: { item: k } })
                }}>
                  <div className="left">
                    <div className="device_name">{i.deviceName}</div>
                    <div className='device_status'>
                      <span className='dot'></span>
                      <span className=''>{i.device_state === 1 ? '设备在线' : '设备离线'}</span>
                    </div>
                  </div>
                  <div className="right">
                    {
                      i.device_state === 1 ? <div>
                        <span className='device_value'>{i.parameter_value}</span>
                        <span className='device_unit'>{i.parameter_unit}</span>
                      </div> : <div className='off_text'>离线</div>
                    }
                    <div className='device_type'>{i.parameter_name}</div>
                  </div>
                </div>
              }) : <div className='not-yet'>--暂无设备--</div>}
            </div>
          }) : <div className='not-yet'>--暂无数据--</div>}
        </div>
      </div>
    )
  }
}

export default Environment