import React, { Component } from 'react'
import { DatePicker, List } from 'antd-mobile'
import {
  req_countTask, req_countHidden, req_countHiddenByDept, req_countHiddenByState, req_countDzgHidden, req_countHiddenRoom, req_countHiddenType, req_countVerify, req_countVerifyByDept
} from '../../../api/api'
import ReactEcharts from 'echarts-for-react'
import moment from 'moment'
import './index.less'

class useState extends Component {

  state = {
    year: moment().format('YYYY'),
    countTask: [],
    countHidden: [],
    countHiddenDep: [],
    HiddenState: [],
    countDzgHidden: [],
    countHiddenRoom: [],
    countHiddenType: [],
    countVerify: [],
    countVerifyDept: [],
  }
  componentDidMount = () => {
    this._countTask()
    this._countHidden()
    this._countHiddenByDept()
    this._countHiddenByState()
    this._countDzgHidden()
    this._countHiddenRoom()
    this._countHiddenType()
    this._countVerify()
    this._countVerifyByDept()
    this.setState({
      // date: new Date(this.fun_date(-6)),
      // endTime: new Date(),
    })
  }

  // 根据年度统计校级巡检、院级巡检、日常巡检各自的隐患总数
  _countTask = async () => {
    const { year } = this.state
    const res = await req_countTask({ year })
    if (res.code === 20000) {
      const data = res.data
      this.setState({
        countTask: [
          {
            name: '校级巡检',
            value: data.schoolNum,
          },
          {
            name: '院级巡检',
            value: data.deptNum,
          },
          {
            name: '日常巡检',
            value: data.dailyNum,
          },
        ]
      })
    }
  }

  // 根据年度统计校级巡检、院级巡检、日常巡检各自的隐患总数
  _countHidden = async () => {
    const { year } = this.state
    const res = await req_countHidden({ year })
    if (res.code === 20000) {
      const data = res.data
      this.setState({
        countHidden: [
          {
            name: '校级巡检',
            value: data.schoolNum,
          },
          {
            name: '院级巡检',
            value: data.deptNum,
          },
          {
            name: '日常巡检',
            value: data.dailyNum,
          },
        ]
      })
    }
  }

  // 根据年度统计各个院系的隐患总数
  _countHiddenByDept = async () => {
    const { year } = this.state
    const res = await req_countHiddenByDept({ year })
    if (res.code === 20000) {
      const data = res.data
      this.setState({
        countHiddenDep: data
      })
    }
  }

  // 根据年度统计全校隐患总数、已整改总数、待整改的总数的比较
  _countHiddenByState = async () => {
    const { year } = this.state
    const res = await req_countHiddenByState({ year })
    if (res.code === 20000) {
      const data = res.data
      this.setState({
        HiddenState: [
          {
            name: '已整改' + ' ' + data.yzgNum,
            value: data.yzgNum,
          },
          {
            name: '待整改' + ' ' + data.dzgNum,
            value: data.dzgNum,
          },
        ]
      })
    }
  }

  // 根据年度统计全校隐患总数、已整改总数、待整改的总数的比较
  _countDzgHidden = async () => {
    const { year } = this.state
    const res = await req_countDzgHidden({ year })
    if (res.code === 20000) {
      const data = res.data.map(item => {
        return {
          name: item.deptName,
          value: item.totalNum ? (item.dzgNum / item.totalNum * 100).toFixed(2) : 0
        }
      })
      this.setState({
        countDzgHidden: data
      })
    }
  }

  // 根据年度统计隐患实验室总数排行榜，前五位；
  _countHiddenRoom = async () => {
    const { year } = this.state
    const res = await req_countHiddenRoom({ year })
    if (res.code === 20000) {
      const data = res.data
      this.setState({
        countHiddenRoom: data
      })
    }
  }

  // 根据年度统计隐患实验室总数排行榜，前五位；
  _countHiddenType = async () => {
    const { year } = this.state
    const res = await req_countHiddenType({ year })
    if (res.code === 20000) {
      const data = res.data
      this.setState({
        countHiddenType: data
      })
    }
  }

  // 8.查询校级 院级 日常 巡检任务完成率
  _countVerify = async () => {
    const { year } = this.state
    const res = await req_countVerify({ year })
    if (res.code === 20000) {
      const data = res.data
      this.setState({
        countVerify: [
          {
            name: '待巡检' + ' ' + data.dxj,
            value: data.dxj,
          },
          {
            name: '已巡检' + ' ' + data.yxj,
            value: data.yxj,
          },
        ]
      })
    }
  }


  // 9.查询每个学院校级 院级 日常 巡检任务完成率及整改完成率
  _countVerifyByDept = async () => {
    const { year } = this.state
    const res = await req_countVerifyByDept({ year })
    if (res.code === 20000) {
      const data = res.data
      console.log(data)
      this.setState({
        countVerifyDept: [
          {
            name: '待巡检',
            value: data ? data.dxj : 0
          },
          {
            name: '已巡检',
            value: data ? data.yxj : 0
          },
          {
            name: '待整改',
            value: data ? data.dzg : 0
          },
          {
            name: '已整改',
            value: data ? data.yzg : 0
          },
        ]
      })
    }
  }


  render() {
    const { countTask, countHidden, countHiddenDep, HiddenState, countDzgHidden, countHiddenRoom, countHiddenType, year, countVerify, countVerifyDept } = this.state
    const option1 = {
      color: ['#5EE9D8', '#008FFF'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          itemStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        left: '3%',
        right: '3%',
        top: '8%',
        bottom: '8%',
        containLabel: true
      },
      legend: {
        data: countTask.map(x => x.name) || [],
        orient: 'horizontal',
        icon: 'circle',
        x: 'right',
        y: 'top',
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 16, // 设置间距
        textStyle: {  // 图例文字的样式
          color: '#666666',
          fontSize: 12
        },
      },
      xAxis: [
        {
          type: 'category',
          data: countTask.map(x => x.name) || [],
          axisLabel: {
            interval: 0, //使x轴文字显示全
            textStyle: {
              color: '#292C33', // y轴字体颜色
              fontSize: 12,
            }
          },
          axisTick: {
            show: false

          },
          axisPointer: {
            type: 'shadow'
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD',// X轴线的颜色
              width: 1 // x轴线的宽度
            }
          },
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '',
          axisLabel: {
            formatter: '{value}',
            textStyle: {  // 图例文字的样式
              color: '#919499',
              fontSize: 12
            },
          },
          splitLine: {    //网格线
            lineStyle: {
              type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
            },
            show: false //隐藏或显示
          },
          minInterval: 1, //最小刻度是1
          splitNumber: 5, //段数是5
          min: 0, //最小是0
          interval: Math.ceil(Math.max.apply(null, countTask.map(x => x.value)) / 5) || 1,
          max: Math.ceil(Math.max.apply(null, countTask.map(x => x.value)) / 5) * 5 || 5,
        },
      ],
      series: [
        {
          type: 'bar',
          barWidth: 20, // 设置柱子粗细
          itemStyle: {
            normal: {
              //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
              barBorderRadius: [2, 2, 0, 0],
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                formatter: '{c}',
                textStyle: { //数值样式
                  color: '#666666',
                  fontSize: 12
                }
              }
            }
          },
          data: countTask.map(x => x.value) || []
        }
      ]
    }

    const option2 = {
      color: ['#5EE9D8', '#008FFF'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          itemStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        left: '3%',
        right: '3%',
        top: '8%',
        bottom: '8%',
        containLabel: true
      },
      legend: {
        data: countHidden.map(x => x.name) || [],
        orient: 'horizontal',
        icon: 'circle',
        x: 'right',
        y: 'top',
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 16, // 设置间距
        textStyle: {  // 图例文字的样式
          color: '#666666',
          fontSize: 12
        },
      },
      xAxis: [
        {
          type: 'category',
          data: countHidden.map(x => x.name) || [],
          axisLabel: {
            interval: 0, //使x轴文字显示全
            textStyle: {
              color: '#292C33', // y轴字体颜色
              fontSize: 12,
            }
          },
          axisTick: {
            show: false

          },
          axisPointer: {
            type: 'shadow'
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD',// X轴线的颜色
              width: 1 // x轴线的宽度
            }
          },
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '',
          axisLabel: {
            formatter: '{value}',
            textStyle: {  // 图例文字的样式
              color: '#919499',
              fontSize: 12
            },
          },
          splitLine: {    //网格线
            lineStyle: {
              type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
            },
            show: false //隐藏或显示
          },
          minInterval: 1, //最小刻度是1
          splitNumber: 5, //段数是5
          min: 0, //最小是0
          interval: Math.ceil(Math.max.apply(null, countHidden.map(x => x.value)) / 5) || 1,
          max: Math.ceil(Math.max.apply(null, countHidden.map(x => x.value)) / 5) * 5 || 5,
        },
      ],
      series: [
        {
          type: 'bar',
          barWidth: 20, // 设置柱子粗细
          itemStyle: {
            normal: {
              //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
              barBorderRadius: [2, 2, 0, 0],
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                formatter: '{c}',
                textStyle: { //数值样式
                  color: '#666666',
                  fontSize: 12
                }
              }
            }
          },
          data: countHidden.map(x => x.value) || []
        }
      ]
    }

    const option3 = {
      color: ['#5EE9D8', '#008FFF'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          itemStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        left: '3%',
        right: '3%',
        top: '8%',
        bottom: '8%',
        containLabel: true
      },
      legend: {
        data: countHiddenDep.map(x => x.name) || [],
        orient: 'horizontal',
        icon: 'circle',
        x: 'right',
        y: 'top',
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 16, // 设置间距
        textStyle: {  // 图例文字的样式
          color: '#666666',
          fontSize: 12
        },
      },
      xAxis: [
        {
          type: 'category',
          data: countHiddenDep.map(x => x.name) || [],
          axisLabel: {
            interval: 0, //使x轴文字显示全
            textStyle: {
              color: '#292C33', // y轴字体颜色
              fontSize: 12,
            },
            // 使用 formatter 自定义标签显示
            formatter: function (value) {
              return value.length > 4 ? value.slice(0, 4) + '...' : value;
            }
          },
          axisTick: {
            show: false

          },
          axisPointer: {
            type: 'shadow'
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD',// X轴线的颜色
              width: 1 // x轴线的宽度
            }
          },
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '',
          axisLabel: {
            formatter: '{value}',
            textStyle: {  // 图例文字的样式
              color: '#919499',
              fontSize: 12
            },
          },
          splitLine: {    //网格线
            lineStyle: {
              type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
            },
            show: false //隐藏或显示
          },
          minInterval: 1, //最小刻度是1
          splitNumber: 5, //段数是5
          min: 0, //最小是0
          interval: Math.ceil(Math.max.apply(null, countHiddenDep.map(x => x.value)) / 5) || 1,
          max: Math.ceil(Math.max.apply(null, countHiddenDep.map(x => x.value)) / 5) * 5 || 5,
        },
      ],
      series: [
        {
          type: 'bar',
          barWidth: 20, // 设置柱子粗细
          itemStyle: {
            normal: {
              //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
              barBorderRadius: [2, 2, 0, 0],
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                formatter: '{c}',
                textStyle: { //数值样式
                  color: '#666666',
                  fontSize: 12
                }
              }
            }
          },
          data: countHiddenDep.map(x => x.count) || []
        }
      ]
    }

    const option4 = {
      tooltip: {
        trigger: 'item',
      },
      color: ['#33D0BD', '#FFC34E'],
      legend: {
        orient: 'horizontal',
        icon: 'circle',
        x: 'center',
        y: 'bottom',
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 16, // 设置间距
        textStyle: {  // 图例文字的样式
          color: '#666666',
          fontSize: 12
        },
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: '72%',
          center: ['50%', '46%'],
          silent: 'ture',//图形是否不响应和触发鼠标事件，默认为 false，即响应和触发鼠标事件。
          label: {
            formatter: '{name|{b}} \n{uname|占比} {scale|{d}%}',
            rich: {
              name: {
                fontSize: 12,
                color: '#666666',
                align: 'left'
              },
              uname: {
                fontSize: 12,
                color: '#666666',
                align: 'left'
              },
              num: {
                fontSize: 12,
                color: '#292C33',
                align: 'left'
              },
              scale: {
                fontSize: 12,
                color: '#292C33',
                align: 'left'
              }
            }
          },
          data: HiddenState,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    }

    const option5 = {
      color: ['#5EE9D8', '#008FFF'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          itemStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        left: '3%',
        right: '3%',
        top: '8%',
        bottom: '8%',
        containLabel: true
      },
      legend: {
        data: countDzgHidden.map(x => x.name) || [],
        orient: 'horizontal',
        icon: 'circle',
        x: 'right',
        y: 'top',
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 16, // 设置间距
        textStyle: {  // 图例文字的样式
          color: '#666666',
          fontSize: 12
        },
      },
      xAxis: [
        {
          type: 'category',
          data: countDzgHidden.map(x => x.name) || [],
          axisLabel: {
            interval: 0, //使x轴文字显示全
            textStyle: {
              color: '#292C33', // y轴字体颜色
              fontSize: 12,
            },
            // 使用 formatter 自定义标签显示
            formatter: function (value) {
              return value.length > 4 ? value.slice(0, 4) + '...' : value;
            }
          },
          axisTick: {
            show: false

          },
          axisPointer: {
            type: 'shadow'
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD',// X轴线的颜色
              width: 1 // x轴线的宽度
            }
          },
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '',
          axisLabel: {
            formatter: '{value}',
            textStyle: {  // 图例文字的样式
              color: '#919499',
              fontSize: 12
            },
          },
          splitLine: {    //网格线
            lineStyle: {
              type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
            },
            show: false //隐藏或显示
          },
          minInterval: 1, //最小刻度是1
          splitNumber: 5, //段数是5
          min: 0, //最小是0
          interval: Math.ceil(Math.max.apply(null, countDzgHidden.map(x => x.value)) / 5) || 1,
          max: Math.ceil(Math.max.apply(null, countDzgHidden.map(x => x.value)) / 5) * 5 || 5,
        },
      ],
      series: [
        {
          type: 'bar',
          barWidth: 20, // 设置柱子粗细
          itemStyle: {
            normal: {
              //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
              barBorderRadius: [2, 2, 0, 0],
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                formatter: '{c}',
                textStyle: { //数值样式
                  color: '#666666',
                  fontSize: 12
                }
              }
            }
          },
          data: countDzgHidden.map(x => x.value) || []
        }
      ]
    }

    const option6 = {
      color: ['#5EE9D8', '#008FFF'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          itemStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        left: '3%',
        right: '3%',
        top: '8%',
        bottom: '8%',
        containLabel: true
      },
      legend: {
        data: countHiddenRoom.map(x => x.name) || [],
        orient: 'horizontal',
        icon: 'circle',
        x: 'right',
        y: 'top',
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 16, // 设置间距
        textStyle: {  // 图例文字的样式
          color: '#666666',
          fontSize: 12
        },
      },
      xAxis: [
        {
          type: 'category',
          data: countHiddenRoom.map(x => x.name) || [],
          axisLabel: {
            interval: 0, //使x轴文字显示全
            textStyle: {
              color: '#292C33', // y轴字体颜色
              fontSize: 12,
            },
            // 使用 formatter 自定义标签显示
            formatter: function (value) {
              return value.length > 4 ? value.slice(0, 4) + '...' : value;
            }
          },
          axisTick: {
            show: false

          },
          axisPointer: {
            type: 'shadow'
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD',// X轴线的颜色
              width: 1 // x轴线的宽度
            }
          },
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '',
          axisLabel: {
            formatter: '{value}',
            textStyle: {  // 图例文字的样式
              color: '#919499',
              fontSize: 12
            },
          },
          splitLine: {    //网格线
            lineStyle: {
              type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
            },
            show: false //隐藏或显示
          },
          minInterval: 1, //最小刻度是1
          splitNumber: 5, //段数是5
          min: 0, //最小是0
          interval: Math.ceil(Math.max.apply(null, countHiddenRoom.map(x => x.count)) / 5) || 1,
          max: Math.ceil(Math.max.apply(null, countHiddenRoom.map(x => x.count)) / 5) * 5 || 5,
        },
      ],
      series: [
        {
          type: 'bar',
          barWidth: 20, // 设置柱子粗细
          itemStyle: {
            normal: {
              //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
              barBorderRadius: [2, 2, 0, 0],
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                formatter: '{c}',
                textStyle: { //数值样式
                  color: '#666666',
                  fontSize: 12
                }
              }
            }
          },
          data: countHiddenRoom.map(x => x.count) || []
        }
      ]
    }

    const option7 = {
      color: ['#5EE9D8', '#008FFF'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          itemStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        left: '3%',
        right: '3%',
        top: '8%',
        bottom: '8%',
        containLabel: true
      },
      legend: {
        data: countHiddenType.map(x => x.name) || [],
        orient: 'horizontal',
        icon: 'circle',
        x: 'right',
        y: 'top',
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 16, // 设置间距
        textStyle: {  // 图例文字的样式
          color: '#666666',
          fontSize: 12
        },
      },
      xAxis: [
        {
          type: 'category',
          data: countHiddenType.map(x => x.name) || [],
          axisLabel: {
            interval: 0, //使x轴文字显示全
            textStyle: {
              color: '#292C33', // y轴字体颜色
              fontSize: 12,
            }
          },
          axisTick: {
            show: false

          },
          axisPointer: {
            type: 'shadow'
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD',// X轴线的颜色
              width: 1 // x轴线的宽度
            }
          },
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '',
          axisLabel: {
            formatter: '{value}',
            textStyle: {  // 图例文字的样式
              color: '#919499',
              fontSize: 12
            },
          },
          splitLine: {    //网格线
            lineStyle: {
              type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
            },
            show: false //隐藏或显示
          },
          minInterval: 1, //最小刻度是1
          splitNumber: 5, //段数是5
          min: 0, //最小是0
          interval: Math.ceil(Math.max.apply(null, countHiddenType.map(x => x.count)) / 5) || 1,
          max: Math.ceil(Math.max.apply(null, countHiddenType.map(x => x.count)) / 5) * 5 || 5,
        },
      ],
      series: [
        {
          type: 'bar',
          barWidth: 20, // 设置柱子粗细
          itemStyle: {
            normal: {
              //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
              barBorderRadius: [2, 2, 0, 0],
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                formatter: '{c}',
                textStyle: { //数值样式
                  color: '#666666',
                  fontSize: 12
                }
              }
            }
          },
          data: countHiddenType.map(x => x.count) || []
        }
      ]
    }

    const option8 = {
      tooltip: {
        trigger: 'item',
      },
      color: ['#33D0BD', '#FFC34E'],
      legend: {
        orient: 'horizontal',
        icon: 'circle',
        x: 'center',
        y: 'bottom',
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 16, // 设置间距
        textStyle: {  // 图例文字的样式
          color: '#666666',
          fontSize: 12
        },
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: '72%',
          center: ['50%', '46%'],
          silent: 'ture',//图形是否不响应和触发鼠标事件，默认为 false，即响应和触发鼠标事件。
          label: {
            formatter: '{name|{b}} \n{uname|占比} {scale|{d}%}',
            rich: {
              name: {
                fontSize: 12,
                color: '#666666',
                align: 'left'
              },
              uname: {
                fontSize: 12,
                color: '#666666',
                align: 'left'
              },
              num: {
                fontSize: 12,
                color: '#292C33',
                align: 'left'
              },
              scale: {
                fontSize: 12,
                color: '#292C33',
                align: 'left'
              }
            }
          },
          data: countVerify,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    }

    const option9 = {
      color: ['#5EE9D8', '#008FFF'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          itemStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        left: '3%',
        right: '3%',
        top: '8%',
        bottom: '8%',
        containLabel: true
      },
      legend: {
        data: countVerifyDept.map(x => x.name) || [],
        orient: 'horizontal',
        icon: 'circle',
        x: 'right',
        y: 'top',
        itemWidth: 10,  // 设置宽度
        itemHeight: 10, // 设置高度
        itemGap: 16, // 设置间距
        textStyle: {  // 图例文字的样式
          color: '#666666',
          fontSize: 12
        },
      },
      xAxis: [
        {
          type: 'category',
          data: countVerifyDept.map(x => x.name) || [],
          axisLabel: {
            interval: 0, //使x轴文字显示全
            textStyle: {
              color: '#292C33', // y轴字体颜色
              fontSize: 12,
            }
          },
          axisTick: {
            show: false

          },
          axisPointer: {
            type: 'shadow'
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD',// X轴线的颜色
              width: 1 // x轴线的宽度
            }
          },
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '',
          axisLabel: {
            formatter: '{value}',
            textStyle: {  // 图例文字的样式
              color: '#919499',
              fontSize: 12
            },
          },
          splitLine: {    //网格线
            lineStyle: {
              type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
            },
            show: false //隐藏或显示
          },
          minInterval: 1, //最小刻度是1
          splitNumber: 5, //段数是5
          min: 0, //最小是0
          interval: Math.ceil(Math.max.apply(null, countVerifyDept.map(x => x.value)) / 5) || 1,
          max: Math.ceil(Math.max.apply(null, countVerifyDept.map(x => x.value)) / 5) * 5 || 5,
        },
      ],
      series: [
        {
          type: 'bar',
          barWidth: 20, // 设置柱子粗细
          itemStyle: {
            normal: {
              //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
              barBorderRadius: [2, 2, 0, 0],
              label: {
                show: true, //开启显示
                position: 'top', //在上方显示
                formatter: '{c}',
                textStyle: { //数值样式
                  color: '#666666',
                  fontSize: 12
                }
              }
            }
          },
          data: countVerifyDept.map(x => x.value) || []
        }
      ]
    }


    return (
      <div className='stat'>
        <div className='stat_card2'>
          <div className='stat_card2_head'>
            <div className='stat_card2_time patrol_stat'>
              <DatePicker
                mode="year"
                extra={year ? year : ''}
                onChange={date => {
                  this.setState({
                    year: moment(date).format('YYYY')
                  }, () => {
                    this._countTask()
                    this._countHidden()
                    this._countHiddenByDept()
                    this._countHiddenByState()
                    this._countDzgHidden()
                    this._countHiddenRoom()
                    this._countHiddenType()
                    this._countVerify()
                    this._countVerifyByDept()
                  })
                }}
              >
                <List.Item arrow="horizontal"></List.Item>
              </DatePicker>
            </div>
          </div>
        </div>
        <div className='stat_card'>
          <div className='stat_card_head'>
            <div className='stat_card_head_title'>
              <div></div>
              <div>各类巡检计划总数</div>
            </div>
          </div>
          <div className='stat_pie'>
            <ReactEcharts option={option1} style={{ height: '100%', width: '100%' }} />
          </div>
          <div className='stat_card_head'>
            <div className='stat_card_head_title'>
              <div></div>
              <div>各类巡检隐患总数</div>
            </div>
          </div>
          <div className='stat_pie'>
            <ReactEcharts option={option2} style={{ height: '100%', width: '100%' }} />
          </div>
          <div className='stat_card_head'>
            <div className='stat_card_head_title'>
              <div></div>
              <div>各个院系隐患总数</div>
            </div>
          </div>
          <div className='stat_pie'>
            <ReactEcharts option={option3} style={{ height: '100%', width: '100%' }} />
          </div>
          <div className='stat_card_head'>
            <div className='stat_card_head_title'>
              <div></div>
              <div>全校隐患整改状态数量比较</div>
            </div>
          </div>
          <div className='stat_pie'>
            <ReactEcharts option={option4} style={{ height: '100%', width: '100%' }} />
          </div>
          <div className='stat_card_head'>
            <div className='stat_card_head_title'>
              <div></div>
              <div>各个院系待整改占隐患总数的比例</div>
            </div>
          </div>
          <div className='stat_pie'>
            <ReactEcharts option={option5} style={{ height: '100%', width: '100%' }} />
          </div>
          <div className='stat_card_head'>
            <div className='stat_card_head_title'>
              <div></div>
              <div>隐患总数排行榜(前五位)</div>
            </div>
          </div>
          <div className='stat_pie'>
            <ReactEcharts option={option6} style={{ height: '100%', width: '100%' }} />
          </div>
          <div className='stat_card_head'>
            <div className='stat_card_head_title'>
              <div></div>
              <div>隐患总数检查项排行榜(前五位)</div>
            </div>
          </div>
          <div className='stat_pie'>
            <ReactEcharts option={option7} style={{ height: '100%', width: '100%' }} />
          </div>
          <div className='stat_card_head'>
            <div className='stat_card_head_title'>
              <div></div>
              <div>巡检完成率</div>
            </div>
          </div>
          <div className='stat_pie'>
            <ReactEcharts option={option8} style={{ height: '100%', width: '100%' }} />
          </div>
          <div className='stat_card_head'>
            <div className='stat_card_head_title'>
              <div></div>
              <div>巡检任务完成及整改率</div>
            </div>
          </div>
          <div className='stat_pie'>
            <ReactEcharts option={option9} style={{ height: '100%', width: '100%' }} />
          </div>



        </div>


      </div >)
  }
}
export default useState