import http from '../config/httpconfig'


/**
 * 查询预约配置 
 */
export const req_selectOne = params => {
  return http.get('/stadium/reservationConfig/selectOne', params)
}
/**
 * 手机端预约 
 */
export const req_insert = params => {
  return http.jsonPost('/stadium/reservationRecord/insert', params)
}

/**
 * 查询我自己的预约记录(分页) 
 */
export const req_listMyRecord = params => {
  return http.get('/stadium/reservationRecord/listMyRecord', params)
}

/**
 * 取消预约
 */
export const req_cancelSubByID = params => {
  return http.get('/stadium/reservationRecord/cancelSubByID', params)
}

/**
 * 查看预约详情
 */
export const req_findMyRecordById = params => {
  return http.get('/stadium/reservationRecord/findMyRecordById', params)
}

/**
 * 查看审批列表
 */
export const req_listMyApproval = params => {
  return http.get('/stadium/approval/listMyApproval', params)
}

/**
 * 查看审批详情
 */
export const req_findApprovalDetailsl = params => {
  return http.get('/stadium/approval/findApprovalDetails', params)
}

/**
 * 审批
 */
export const req_submitApprovalResult = params => {
  return http.jsonPost('/stadium/approval/submitApprovalResult', params)
}

/**
 * 审批
 */
export const req_findRecord = params => {
  return http.get('/stadium/reservationRecord/findRecord', params)
}