import http from '../config/httpconfig'

/**
 * 查询考试安全类别
 */
export const findSaftyType = params => {
  return http.get('/exam/categoryconfig/findCategoryByUserClient', params)
}

/**
 * 根据类别id查询类别详情
 */
export const findSaftyTypeDetail = params => {
  return http.get('/exam/categoryconfig/findCategoryById', params)
}


/**
 * 查询试卷报告
 */
export const findExamReport = params => {
  return http.get('/exam/examweb/generateExamReport', params)
}

/**
 * 根据类别查询课程信息
 */
export const findCourseInfo = params => {
  return http.get('/exam/courseinfo/findCourseByCategoryId', params)
}
/**
 * 根据课程查询章节信息
 */
export const findZJInfo = params => {
  return http.get('/exam/coursecatalogueinfo/findCourseCatalogueInfo', params)
}


/**
 * 查询课程章节目录
 */
export const findZJDetail = params => {
  return http.get('/exam/coursecatalogueinfo/findCatalogueInfoByUserClient', params)
}

/**
 * 保存学习记录
 */
export const saveStudyRecord = params => {
  return http.post('/exam/learningrecord/addLearningRecord', params)
}

/**
 * 查询我的证书
 */
export const findMyCert = params => {
  return http.get('/exam/answerrecord/findMyCert', params)
}


/**
 * 查询积分规则公告
 */
export const getJFRule = params => http.get('/exam/integratenoticeinfo/findNoticeInfo', params)

/**
 * 查询我的积分明细
 */
export const findMyIntegrateRecord = params => http.get('/exam/integraterecord/findMyIntegrateRecord', params)

/**
 * 查询我的积分
 */
export const findMyTotalIntegrate = params => http.get('/exam/integraterecord/findMyTotalIntegrate', params)

/**
 * 查询积分排行
 */
export const findJFPaiHang = params => http.get('/exam/integraterecord/findIntegrateRank', params)

/**
 * 查询积分统计
 */
export const findJFTJ = params => http.get('/exam/integraterecord/findXxIntegrateRank', params)
