import React, { Component } from 'react'
import { Toast } from 'antd-mobile'
import { Tabs, Form, InputNumber, Switch } from 'antd'
import { createForm } from 'rc-form';
import { req_getConfigBySwitchImei, updateDeviceAlarm, updateDeviceWarn } from '../../../api/api'
import './timing_config.less'

class TimingConfig extends Component {

  state = {
    activeKey: '1', // 参数配置里面的切换状态
    parameterConfig: {}, // 配置信息
  }

  componentDidMount = () => {
    document.title = "智慧用电-参数配置"
    this.fetch(this.props.match.params.id)
  }

  // 查询定时开关配置
  fetch = async switchId => {
    const { form } = this.props
    const res = await req_getConfigBySwitchImei({ id: switchId })
    if (res.code === 20000) {
      const data = res.data
      console.log(data, 'tttt')
      const obj = { ...data }
      obj.BJ_LD_switch = obj.bj_ld ? true : false  // 漏电报警
      obj.BJ_GW_switch = obj.bj_gw ? true : false  // 温度报警
      obj.BJ_GL_switch = obj.bj_gl ? true : false  // 过流报警
      obj.BJ_GG_switch = obj.bj_gg ? true : false  // 过功报警
      obj.BJ_GY_switch = obj.bj_gy ? true : false  // 过压报警
      obj.BJ_QY_switch = obj.bj_qy ? true : false  // 欠压报警
      obj.YJ_LD_switch = obj.yj_ld ? true : false  // 漏电预警
      obj.YJ_GW_switch = obj.yj_gw ? true : false  // 温度预警
      obj.YJ_GL_switch = obj.yj_gl ? true : false  // 过流预警
      obj.YJ_GG_switch = obj.yj_gg ? true : false  // 过功预警
      obj.YJ_GY_switch = obj.yj_gy ? true : false  // 过压预警
      obj.YJ_QY_switch = obj.yj_qy ? true : false  // 欠压预警
      this.setState({
        parameterConfig: obj
      }, () => {
        form.setFieldsValue(obj)
      })
    }
  }

  // 切换参数配置按钮
  handleActiveKey = activeKey => {
    const { parameterConfig } = this.state
    const { form } = this.props
    this.setState({
      activeKey
    }, () => {
      form.setFieldsValue(parameterConfig)
    })
  }

  // 参数配置（报警设置）
  handleOkSubmitBjConfig = () => {
    const { parameterConfig } = this.state
    const { form } = this.props
    form.validateFields(async (err, values) => {
      if (err) return
      values.bj_ld = values.BJ_LD_switch ? 1 : 0  // 漏电报警
      values.bj_gw = values.BJ_GW_switch ? 1 : 0  // 温度报警
      values.bj_gl = values.BJ_GL_switch ? 1 : 0  // 过流报警
      values.bj_gg = values.BJ_GG_switch ? 1 : 0  // 过功报警
      values.bj_gy = values.BJ_GY_switch ? 1 : 0  // 过压报警
      values.bj_qy = values.BJ_QY_switch ? 1 : 0  // 欠压报警
      values.air_switch_id = parameterConfig.air_switch_id
      values.air_switch_imei = parameterConfig.air_switch_imei
      values.dev_type = parameterConfig.dev_type
      values.device_token = parameterConfig.device_token
      const res = await updateDeviceAlarm(values)
      if (res.code === 20000) {
        Toast.success(res.message)
        setTimeout(() => {
          this.props.history.go(-1)
        }, 2000)
      }
    })
  }

  // 参数配置（预警设置）
  handleOkSubmitYjConfig = () => {
    const { parameterConfig } = this.state
    const { form } = this.props
    form.validateFields(async (err, values) => {
      if (err) return
      values.yj_ld = values.YJ_LD_switch ? 1 : 0  // 漏电报警
      values.yj_gw = values.YJ_GW_switch ? 1 : 0  // 温度报警
      values.yj_gl = values.YJ_GL_switch ? 1 : 0  // 过流报警
      values.yj_gg = values.YJ_GG_switch ? 1 : 0  // 过功报警
      values.yj_gy = values.YJ_GY_switch ? 1 : 0  // 过压报警
      values.yj_qy = values.YJ_QY_switch ? 1 : 0  // 欠压报警
      values.air_switch_id = parameterConfig.air_switch_id
      values.air_switch_imei = parameterConfig.air_switch_imei
      values.dev_type = parameterConfig.dev_type
      values.device_token = parameterConfig.device_token
      const res = await updateDeviceWarn(values)
      if (res.code === 20000) {
        Toast.success(res.message)
        setTimeout(() => {
          this.props.history.go(-1)
        }, 2000)
      }
    })
  }

  render() {
    const { form } = this.props
    const { activeKey } = this.state
    return (
      <div className='timingConfig'>
        <Tabs activeKey={activeKey} onChange={activeKey => this.handleActiveKey(activeKey)}>
          <Tabs.TabPane tab="报警设置" key="1">
            <div className='alarmData'>
              <div className='alarmData_title'>
                <div className='alarmData_title_kg'>使能开关</div>
                <div className='alarmData_title_lx'>类型</div>
                <div className='alarmData_title_qz'>阙值</div>
              </div>
              {
                activeKey === '1' ? <Form>
                  <div className='alarmData_list'>
                    <div className='alarmData_list_kg original_kg'>
                      <Form.Item>
                        {form.getFieldDecorator('BJ_LD_switch', {
                          valuePropName: 'checked',
                        })(<Switch checkedChildren="开" unCheckedChildren="关" />)}
                      </Form.Item>
                    </div>
                    <div className='alarmData_list_lx alarmData_list_disabled'>漏电报警</div>
                    <div className='alarmData_list_qz'>
                      <Form.Item>
                        {form.getFieldDecorator('bj_ld_yz', {
                          rules: [{ required: true, message: '请输入漏电报警值！' }]
                        })(<InputNumber formatter={value => `${value}mA`} parser={value => value.replace('mA', '')} disabled />)}
                      </Form.Item>
                    </div>
                  </div>
                  <div className='alarmData_list_qz_desc'>*漏电报警阈值是国家统一标准，不可修改。</div>
                  <div className='alarmData_list'>
                    <div className='alarmData_list_kg original_kg'>
                      <Form.Item>
                        {form.getFieldDecorator('BJ_GW_switch', {
                          valuePropName: 'checked',
                        })(<Switch checkedChildren="开" unCheckedChildren="关" />)}
                      </Form.Item>
                    </div>
                    <div className='alarmData_list_lx'>温度报警</div>
                    <div className='alarmData_list_qz'>
                      <Form.Item>
                        {form.getFieldDecorator('bj_gw_yz', {
                          rules: [{ required: true, message: '请输入温度报警值！' }]
                        })(<InputNumber formatter={value => `${value}℃`} parser={value => value.replace('℃', '')} disabled={form.getFieldValue('BJ_GW_switch') ? false : true} />)}
                      </Form.Item>
                    </div>
                  </div>
                  <div className='alarmData_list'>
                    <div className='alarmData_list_kg original_kg'>
                      <Form.Item>
                        {form.getFieldDecorator('BJ_GL_switch', {
                          valuePropName: 'checked',
                        })(<Switch checkedChildren="开" unCheckedChildren="关" />)}
                      </Form.Item>
                    </div>
                    <div className='alarmData_list_lx'>过流报警</div>
                    <div className='alarmData_list_qz'>
                      <Form.Item>
                        {form.getFieldDecorator('bj_gl_yz', {
                          rules: [{ required: true, message: '请输入过流报警值！' }]
                        })(<InputNumber formatter={value => `${value}A`} parser={value => value.replace('A', '')} disabled={form.getFieldValue('BJ_GL_switch') ? false : true} />)}
                      </Form.Item>
                    </div>
                  </div>
                  <div className='alarmData_list'>
                    <div className='alarmData_list_kg original_kg'>
                      <Form.Item>
                        {form.getFieldDecorator('BJ_GG_switch', {
                          valuePropName: 'checked',
                        })(<Switch checkedChildren="开" unCheckedChildren="关" />)}
                      </Form.Item>
                    </div>
                    <div className='alarmData_list_lx'>过功报警</div>
                    <div className='alarmData_list_qz'>
                      <Form.Item>
                        {form.getFieldDecorator('bj_gg_yz', {
                          rules: [{ required: true, message: '请输入过功报警值！' }]
                        })(<InputNumber formatter={value => `${value}W`} parser={value => value.replace('W', '')} disabled={form.getFieldValue('BJ_GG_switch') ? false : true} />)}
                      </Form.Item>
                    </div>
                  </div>
                  <div className='alarmData_list'>
                    <div className='alarmData_list_kg original_kg'>
                      <Form.Item>
                        {form.getFieldDecorator('BJ_GY_switch', {
                          valuePropName: 'checked',
                        })(<Switch checkedChildren="开" unCheckedChildren="关" />)}
                      </Form.Item>
                    </div>
                    <div className='alarmData_list_lx'>过压报警</div>
                    <div className='alarmData_list_qz'>
                      <Form.Item>
                        {form.getFieldDecorator('bj_gy_yz', {
                          rules: [{ required: true, message: '请输入过压报警值！' }]
                        })(<InputNumber formatter={value => `${value}V`} parser={value => value.replace('V', '')} disabled={form.getFieldValue('BJ_GY_switch') ? false : true} />)}
                      </Form.Item>
                    </div>
                  </div>
                  <div className='alarmData_list'>
                    <div className='alarmData_list_kg original_kg'>
                      <Form.Item>
                        {form.getFieldDecorator('BJ_QY_switch', {
                          valuePropName: 'checked',
                        })(<Switch checkedChildren="开" unCheckedChildren="关" />)}
                      </Form.Item>
                    </div>
                    <div className='alarmData_list_lx'>欠压报警</div>
                    <div className='alarmData_list_qz'>
                      <Form.Item>
                        {form.getFieldDecorator('bj_qy_yz', {
                          rules: [{ required: true, message: '请输入欠压报警值！' }]
                        })(<InputNumber formatter={value => `${value}V`} parser={value => value.replace('V', '')} disabled={form.getFieldValue('BJ_QY_switch') ? false : true} />)}
                      </Form.Item>
                    </div>
                  </div>
                </Form> : ''
              }
            </div>
            <div className='alarmBtn'>
              <div className='ant_btn' onClick={() => this.handleOkSubmitBjConfig()}>确 定</div>
            </div>
            <div className='alarmDesc'>
              <div className='alarmDesc_title'>说明：</div>
              <div>1、向服务器提交指令，并非立即生效，指令由服务器下发到设备，根据网络情况可能会有延迟现象。</div>
              <div>2、当使能开关关闭时，阈值不可修改。</div>
              <div>3、漏电报警阈值是国家统一标准，不可修改。</div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="预警设置" key="2">
            <div className='alarmData'>
              <div className='alarmData_title'>
                <div className='alarmData_title_kg'>使能开关</div>
                <div className='alarmData_title_lx'>类型</div>
                <div className='alarmData_title_qz'>阙值</div>
              </div>
              {
                activeKey === '2' ? <Form>
                  <div className='alarmData_list'>
                    <div className='alarmData_list_kg original_kg'>
                      <Form.Item>
                        {form.getFieldDecorator('YJ_LD_switch', {
                          valuePropName: 'checked',
                        })(<Switch checkedChildren="开" unCheckedChildren="关" />)}
                      </Form.Item>
                    </div>
                    <div className='alarmData_list_lx alarmData_list_disabled'>漏电预警</div>
                    <div className='alarmData_list_qz'>
                      <Form.Item>
                        {form.getFieldDecorator('yj_ld_yz', {
                          rules: [{ required: true, message: '请输入漏电预警值！' }]
                        })(<InputNumber formatter={value => `${value}mA`} parser={value => value.replace('mA', '')} disabled />)}
                      </Form.Item>
                    </div>
                  </div>
                  <div className='alarmData_list_qz_desc'>*漏电预警阈值是国家统一标准，不可修改。</div>
                  <div className='alarmData_list'>
                    <div className='alarmData_list_kg original_kg'>
                      <Form.Item>
                        {form.getFieldDecorator('YJ_GW_switch', {
                          valuePropName: 'checked',
                        })(<Switch checkedChildren="开" unCheckedChildren="关" />)}
                      </Form.Item>
                    </div>
                    <div className='alarmData_list_lx'>温度预警</div>
                    <div className='alarmData_list_qz'>
                      <Form.Item>
                        {form.getFieldDecorator('yj_gw_yz', {
                          rules: [{ required: true, message: '请输入温度预警值！' }]
                        })(<InputNumber formatter={value => `${value}℃`} parser={value => value.replace('℃', '')} disabled={form.getFieldValue('YJ_GW_switch') ? false : true} />)}
                      </Form.Item>
                    </div>
                  </div>
                  <div className='alarmData_list'>
                    <div className='alarmData_list_kg original_kg'>
                      <Form.Item>
                        {form.getFieldDecorator('YJ_GL_switch', {
                          valuePropName: 'checked',
                        })(<Switch checkedChildren="开" unCheckedChildren="关" />)}
                      </Form.Item>
                    </div>
                    <div className='alarmData_list_lx'>过流预警</div>
                    <div className='alarmData_list_qz'>
                      <Form.Item>
                        {form.getFieldDecorator('yj_gl_yz', {
                          rules: [{ required: true, message: '请输入过流预警值！' }]
                        })(<InputNumber formatter={value => `${value}A`} parser={value => value.replace('A', '')} disabled={form.getFieldValue('YJ_GL_switch') ? false : true} />)}
                      </Form.Item>
                    </div>
                  </div>
                  <div className='alarmData_list'>
                    <div className='alarmData_list_kg original_kg'>
                      <Form.Item>
                        {form.getFieldDecorator('YJ_GG_switch', {
                          valuePropName: 'checked',
                        })(<Switch checkedChildren="开" unCheckedChildren="关" />)}
                      </Form.Item>
                    </div>
                    <div className='alarmData_list_lx'>过功预警</div>
                    <div className='alarmData_list_qz'>
                      <Form.Item>
                        {form.getFieldDecorator('yj_gg_yz', {
                          rules: [{ required: true, message: '请输入过功预警值！' }]
                        })(<InputNumber formatter={value => `${value}W`} parser={value => value.replace('W', '')} disabled={form.getFieldValue('YJ_GG_switch') ? false : true} />)}
                      </Form.Item>
                    </div>
                  </div>
                  <div className='alarmData_list'>
                    <div className='alarmData_list_kg original_kg'>
                      <Form.Item>
                        {form.getFieldDecorator('YJ_GY_switch', {
                          valuePropName: 'checked',
                        })(<Switch checkedChildren="开" unCheckedChildren="关" />)}
                      </Form.Item>
                    </div>
                    <div className='alarmData_list_lx'>过压预警</div>
                    <div className='alarmData_list_qz'>
                      <Form.Item>
                        {form.getFieldDecorator('yj_gy_yz', {
                          rules: [{ required: true, message: '请输入过压预警值！' }]
                        })(<InputNumber formatter={value => `${value}V`} parser={value => value.replace('V', '')} disabled={form.getFieldValue('YJ_GY_switch') ? false : true} />)}
                      </Form.Item>
                    </div>
                  </div>
                  <div className='alarmData_list'>
                    <div className='alarmData_list_kg original_kg'>
                      <Form.Item>
                        {form.getFieldDecorator('YJ_QY_switch', {
                          valuePropName: 'checked',
                        })(<Switch checkedChildren="开" unCheckedChildren="关" />)}
                      </Form.Item>
                    </div>
                    <div className='alarmData_list_lx'>欠压预警</div>
                    <div className='alarmData_list_qz'>
                      <Form.Item>
                        {form.getFieldDecorator('yj_qy_yz', {
                          rules: [{ required: true, message: '请输入欠压预警值！' }]
                        })(<InputNumber formatter={value => `${value}V`} parser={value => value.replace('V', '')} disabled={form.getFieldValue('YJ_QY_switch') ? false : true} />)}
                      </Form.Item>
                    </div>
                  </div>
                </Form> : ''
              }
            </div>
            <div className='alarmBtn'>
              <div className='ant_btn' onClick={() => this.handleOkSubmitYjConfig()}>确 定</div>
            </div>
            <div className='alarmDesc'>
              <div className='alarmDesc_title'>说明：</div>
              <div>1、向服务器提交指令，并非立即生效，指令由服务器下发到设备，根据网络情况可能会有延迟现象。</div>
              <div>2、当使能开关关闭时，阈值不可修改。</div>
              <div>3、漏电预警阈值是国家统一标准，不可修改。</div>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    )
  }
}

export default createForm()(TimingConfig);
