import { getSign } from './../api/whp'
import IP from '../config/config'

const JSSDK = {
    InitWxConfig: async () => {
        const result = await getSign({ url: window.location.href.split('#')[0] });
        if (result.code === 20000) {
            await window.wx.config({
                beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: IP.appId, // 必填，企业微信的corpID
                timestamp: Number(result.data.timestamp), // 必填，生成签名的时间戳
                nonceStr: result.data.noncestr, // 必填，生成签名的随机串
                signature: result.data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
                // scanQRCode 扫码使用
                jsApiList: ['scanQRCode', 'startRecord', 'stopRecord', 'translateVoice', 'uploadVoice'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
            })
        }
    },
    handleConvertText: (localId, func) => {
        // 转换为文字
        window.wx.translateVoice({
            localId: localId, // 需要识别的音频的本地Id，由录音相关接口获得，音频时长不能超过60秒
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: function (res) {
                // alert(res.translateResult); // 语音识别的结果
                func(res.translateResult)
            }
        })
    },
    handleVoiceStart: () => {
        // 点击开始按钮的时候
        window.wx.startRecord();
    },
    handleVoiceEnd: () => new Promise(resolve => {
        // 点击结束按钮时候
        window.wx.stopRecord({
            success: (res) => {
                var localId = res.localId;
                // JSSDK.handleConvertText(localId, text => {
                //     resolve(text)
                // })
                JSSDK.handleVoiceUpload(localId, serverId => {
                    resolve(serverId)
                })
            }
        });
    }),
    handleVoiceUpload: (localId, func) => {
        // 获取音频的服务端id
        window.wx.uploadVoice({
            localId: localId, // 需要识别的音频的本地Id，由录音相关接口获得，音频时长不能超过60秒
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: function (res) {
                func(res.serverId)
            }
        })
    }
}

export default JSSDK;