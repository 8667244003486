import http from '../config/httpconfig'

/**
 * 查询home页面模块
 */
export const findBxModuleInfo = params => {
  return http.get('mobile/moduleinfo/findBxModuleInfo', params)
}

/**
 * 查询所有房间
 */
export const findRepairSpaceInfo = params => {
  return http.get('/system/departmentinfo/findAllDepTreeInfo', params)
}

export const req_findRepairArea = params => {
  return http.get('/equipmentrepair/repairarea/findRepairArea', params)
}

// 查询所有故障类型信息
export const findAllFaultType = params => {
  return http.get('/equipmentrepair/faulttype/findAllFaultType', params)
}

// 获取教室节点
export const saveRepairOrder = params => {
  return http.jsonPost('/equipmentrepair/repairorder/saveRepairOrder', params)
}

//   上传维修工单图片
export const uploadRepairOrderImgae = params => {
  return http.jsonPost('/equipmentrepair/repairorder/uploadRepairOrderImgae', params)
}

// 根据用户ID查询其报修的工单(主要是手机端使用)
export const findOrderByUserId = params => {
  return http.get('/equipmentrepair/repairorder/findOrderByUserId', params)
}

// 手机端查询所有工单
export const findAppAllOrder = params => {
  return http.get('/equipmentrepair/repairorder/findAppAllOrder', params)
}

// 手机端查询所有待指派工单
export const findAppointOrder = params => {
  return http.get('/equipmentrepair/repairorder/findAppointOrder', params)
}

// 保存一条审批记录
export const saveApprovalRecord = params => {
  return http.jsonPost('/equipmentrepair/approvalrecord/saveApprovalRecord', params)
}

//   repairorder/findOrderByOrderId
export const findOrderByOrderId = params => {
  return http.get('/equipmentrepair/repairorder/findOrderByOrderId', params)
}

// 报修用户提交工单所花费的金额(工单完工时使用)
export const updateOrderMoney = params => {
  return http.post('/equipmentrepair/repairorder/updateOrderMoney', params)
}

// 手机端报修人确认工单
export const completeOrder = params => {
  return http.post('/equipmentrepair/repairorder/completeOrder', params)
}
/**
 * 提交建议反馈
 */
export const orderJianyi = params => http.jsonPost('/equipmentrepair/repairorder/orderJianyi', params)

// 拒接工单
export const jujieOrder = params => {
  return http.post('/equipmentrepair/repairorder/jujieOrder', params)
}
// 查询维修人员信息
export const findWorkUser = params => {
  return http.get('/equipmentrepair/repairorder/findWorkUser', params)
}

// 手机端指派工单
export const appointOrder = params => {
  return http.post('/equipmentrepair/repairorder/appointOrder', params)
}

// 工单跟踪查询
export const findOrder = params => {
  return http.get('/equipmentrepair/repairorder/findOrder', params)
}

/**
 * 分部门统计工单数量
 */
export const statisticsOrderNumByDept = params => http.get('/equipmentrepair/statisticsApp/statisticsOrderNumByDept', params)

/**
 * 按类型查询工单比例
 */
export const statisticsOrderNumByType = params => http.get('/equipmentrepair/statisticsApp/statisticsOrderNumByType', params)

/**
 *分部门统计工单费用
 */
export const statisticsOrderMoneyByDept = params => http.get('/equipmentrepair/statisticsApp/statisticsOrderMoneyByDept', params)

// 查询手机端审批工单
export const findAppApprovalOrderRecord = params => {
  return http.get('/equipmentrepair/approvalrecord/findAppApprovalOrderRecord', params)
}

// 移动端查询我的隐患信息
export const req_findAppHidden = params => {
  return http.get('/polling/hidden/findAppHidden', params)
}

// 查询我的审批列表
export const req_listMyApproval = params => {
  return http.get('/polling/approval/listMyApproval', params)
}

// 根据ID查询巡更点详情（巡更房间完成完成后查询详情使用，带巡更异常照片）
export const req_getPollingDetailsById = params => {
  return http.get('/polling/apppollingtask/getPollingDetailsById', params)
}


// 处理一般隐患信息
export const req_disposalYbHidden = params => {
  return http.post('/polling/hidden/disposalYbHidden', params)
}

// 提交重大隐患处理信息
export const req_disposalZdHidden = params => {
  return http.post('/polling/hidden/disposalZdHidden', params)
}

// 处理随手报隐患信息
export const req_disposalSsbHidden = params => {
  return http.post('/polling/hidden/disposalSsbHidden', params)
}

// 上传隐患整改报告
export const uploadDisposeFile = params => {
  return http.jsonPost('/polling/apppollingtask/uploadDisposeFile', params)
}

// 提交审批结果
export const req_submitApprovalResult = params => {
  return http.post('/polling/approval/submitApprovalResult', params)
}

// 查询审批详情
export const req_processDetails = params => {
  return http.get('/polling/approval/processDetails', params)
}

// 完成重大隐患处理
export const req_completeZdHidden = params => {
  return http.post('/polling/hidden/disposalZdHidden', params)
}

// 查询我的专项/随手报巡检记录
export const req_findAppMyRecord = params => {
  return http.get('/polling/apppollingtask/findAppMyRecord', params)
}

/**
* 用户端查询试题(不分页)
*/
export const findAllPaper = params => http.get('/exam/examweb/findMyPaperInfo', params)
export const findAllPaper2 = params => http.get('/exam/examweb/findMyPaperInfo2', params)


// 根据ID查询巡更点详情（巡更房间完成完成后查询详情使用，带巡更异常照片）
export const req_getDetailsById = params => {
  return http.get('/polling/dlpollingtaskdetails/getDetailsById', params)
}

// 上传发票
export const req_updateOrderInvoice = params => {
  return http.get('/equipmentrepair/repairorder/updateOrderInvoice', params)
}