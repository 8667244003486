import React, { Component } from 'react'
import { Toast, TextareaItem } from 'antd-mobile'
import { Radio } from 'antd'
import { req_getDetailsById } from '../../../api/repairApi'
import { req_checkHiddenDangers } from '../../../api/api'
import IP from './../../../config/config'
import moment from 'moment'

class ApproveDetail extends Component {

  state = {
    data: {},
    visible: false,
    opinion: '',
    checkContent: '',//隐患描述
    fileUrl: '',
    type: 1,
    detailsState: '',
    state: ''
  }

  componentDidMount = () => {
    document.title = "整改验收"
    this.fetch(this.props.match.params.id)
  }

  fetch = async detailsId => {
    const res = await req_getDetailsById({ id: detailsId })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }
  // 验收
  handleSubmit = async () => {
    const { state, checkContent } = this.state
    if (state === '') {
      return Toast.info('请选择验收结果！')
    }
    if (state === 2) {
      if (checkContent === '') {
        return Toast.info('请输入验收描述！')
      }
    }

    const res = await req_checkHiddenDangers({
      state,
      ids: this.props.match.params.id,
      checkContent,
    })
    if (res.code === 20000) {
      Toast.info(res.message)
      setTimeout(() => {
        this.props.history.go(-1)
      }, 3000);
    }
  }

  // 放大图片或者视频
  handleImageClick = (fileUrl, type) => {
    this.setState({
      visible: true,
      fileUrl,
      type,
    })
  }

  render() {
    const { data } = this.state
    return (
      <div className='approveDetail hidden_handle' style={{ padding: '0px' }}>
        <div className='approve_detail_head'>
          <div className='approve_detail_left'>
            <div className='approve_detail_left_title'>
              {data.room_name} {data.class_name ? '（' + data.class_name + '）' : ''}
            </div>
            <div className='approve_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='approve_list_head_date'>
                {data.building_name}{data.floor_name}
              </div>
            </div>
          </div>
          <div className='approve_detail_right hidden_handle_type'>
            <img src={require('./../../../img/patrol/未提交.png')} alt='' />
          </div>
        </div>
        <div className='approve_detail_cont'>
          <div className='approve_detail_bar' style={{ padding: '0px' }}>
            <div className={data.task_level === 1 ? 'patrol_type_xiao' : data.task_level === 2 ? 'patrol_type_yuan' : ''} style={{ padding: '12px 16px 0px' }}>
              {data.task_title ? (<div className='approve_detail_item patrol_approve_detail_item'>
                <div>巡检标题</div>
                <div>{data.task_title}</div>
              </div>) : ''}

              {data.task_name ? (<div className='approve_detail_item patrol_approve_detail_item'>
                <div>巡检批次</div>
                <div>{data.task_name}</div>
              </div>) : ''}

              {data.dept_name ? (<div className='approve_detail_item patrol_approve_detail_item'>
                <div>所属院系</div>
                <div>{data.dept_name}</div>
              </div>) : ''}

              {data.fzr_name ? (<div className='approve_detail_item patrol_approve_detail_item'>
                <div>巡检总负责人</div>
                <div>{data.fzr_name}</div>
              </div>) : ''}

              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>巡检人</div>
                <div>{data.user_name}</div>
              </div>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>隐患类型</div>
                <div>
                  {data.details_state === 1 ? '正常' : ''}
                  {data.details_state === 2 ? '一般隐患' : ''}
                  {data.details_state === 3 ? '重大隐患' : ''}
                  {data.details_state === 4 ? '严重隐患' : ''}
                </div>
              </div>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>整改状态</div>
                <div>
                  {data.hidden_result === -2 ? '不属实隐患' : ''}
                  {data.hidden_result === -1 ? '待核实隐患' : ''}
                  {data.hidden_result === 0 ? '待整改' : ''}
                  {data.hidden_result === 1 ? '已整改待确认' : ''}
                  {data.hidden_result === 2 ? '整改完成' : ''}
                  {data.hidden_result === 3 ? '整改不合格' : ''}
                  {data.hidden_result === 4 ? '暂无法整改' : ''}
                  {data.hidden_result === 5 ? '隐患撤回' : ''}
                </div>
              </div>
              {data.task_level === 4 && data.hidden_result > -1 ? (<div className='approve_detail_item patrol_approve_detail_item'>
                <div>隐患是否属实</div>
                <div>
                  <div><span className='round-label green'>属实</span></div>
                </div>
              </div>) : ''}
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>巡检人签字</div>
                <div>
                  {data.details_qz && data.details_qz.split('/').length > 1 ? (<img src={IP.fileHost + data.details_qz} alt='' style={{ height: '40px' }} onClick={() => {
                    this.setState({
                      visible: true,
                      fileUrl: data.details_qz,
                    })
                  }} />) : data.details_qz}
                </div>
              </div>
            </div>
          </div>


          {/* 隐患 */}
          <div className='patrol_danger'>
            <div className='patrol_danger_head'>
              <div>
                <img src={require('./../../../images/patrol/6.png')} alt='' />
                整改前
              </div>
              <div>
                {moment(data.complete_time).format('YYYY-MM-DD HH:mm')} 提交
              </div>
            </div>
            <div className='patrol_danger_main'>
              {data && data.dangerEntityList && data.dangerEntityList.map((item, key) => {
                return <div className='patrol_danger_item' key={key}>
                  <div className='patrol_danger_title'>
                    <span>隐患{key + 1}：</span>{item.danger_title}
                  </div>
                  <div className='patrol_danger_desc'>
                    {item.danger_content}
                  </div>
                  <div className='patrol_danger_pic'>
                    <img src={IP.fileHost + item.danger_file} alt='' onClick={() => {
                      this.setState({
                        visible: true,
                        fileUrl: item.danger_file,
                        type: 1,
                      })
                    }} />
                  </div>
                </div>
              })}
            </div>
          </div>

          {/* 整改 */}
          <div className='patrol_change'>
            <div className='patrol_danger_head'>
              <div>
                <img src={require('./../../../images/patrol/7.png')} alt='' />
                整改后
              </div>
              <div>
                <span>{data.dispose_user_name}</span>
                {moment(data.dispose_time).format('YYYY-MM-DD HH:mm')}整改
              </div>
            </div>
            <div className='patrol_danger_main'>
              <div className='patrol_danger_item'>
                <div className='patrol_danger_desc'>
                  {data.dispose_content}
                </div>
                <div className='patrol_danger_pic'>
                  {data && data.disposeFileList && data.disposeFileList.map(item => {
                    return (
                      <img src={IP.fileHost + item.file_url} alt='' key={item.file_id} onClick={() => {
                        this.setState({
                          visible: true,
                          type: 1,
                          fileUrl: item.file_url
                        })
                      }} />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className='approve_detail_bar' style={{ padding: '12px 16px 12px' }}>
            <div className='approve_detail_radio'>
              <div><span style={{ color: 'red' }}>* </span>验收结果</div>
              <div>
                <Radio.Group onChange={(e) => {
                  this.setState({
                    state: e.target.value
                  })
                }}>
                  <Radio value={1}>整改合格</Radio>
                  <Radio value={2}>整改不合格</Radio>
                </Radio.Group>
              </div>
            </div>

            <div className='approve_detail_textarea'>
              <div>{this.state.state === 2 ? <span style={{ color: 'red' }}>* </span> : <span style={{ color: '#fff' }}>* </span>}验收描述</div>
              <div className='approve_detail_textareaItem'>
                <TextareaItem
                  rows={3}
                  placeholder="请输入验收描述"
                  onChange={e => this.setState({ checkContent: e })}
                />
              </div>
            </div>

          </div>
        </div>
        <div className='approveDetail_btn'>
          <div className='approveDetail_btn_list' onClick={() => this.handleSubmit()}>提交验收</div>
        </div>

        {
          this.state.visible ? <div className='detailImg' onClick={() => this.setState({ visible: false })}>
            {this.state.type === 1 ? (<img alt='' src={IP.fileHost + this.state.fileUrl} style={{ width: '80%' }} />) : (<video width="80%" src={IP.fileHost + this.state.fileUrl} poster={IP.fileHost + this.state.fileUrl} controls="controls" controlslist="nodownload noremoteplayback" disablePictureInPicture>
              your browser does not support the video tag
            </video>)}

          </div> : ''
        }


      </div >
    )
  }
}

export default ApproveDetail
