import React, { Component } from 'react'
import { findRegisterRecordByCode } from '../../../../api/fqw'
import moment from 'moment'

class deviceDetail extends Component {

  state = {
    data: {},
  }

  componentDidMount = () => {
    document.title = '废弃物登记详情'
    window.scrollTo(0, 0)
    this.fetch(this.props.match.params.id)
  }

  fetch = async registerCode => {
    const res = await findRegisterRecordByCode({ registerCode })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className='approveDetail' style={{ paddingBottom: 12 }}>
        <div className={data.register_state == 4 || data.register_state === 5 ? 'approve_detail_head' : 'approve_detail_head approve_detail_head_no'}>
          <div className='approve_detail_left'>
            <div className='approve_detail_left_title'>
              {data.ssxy_mc}-{data.sszx_mc}-{data.room_name}
            </div>
            <div className='approve_list_head_desc' style={{ paddingLeft: 0, marginTop: 8 }}>
              <div className='approve_list_head_date'>
                {data.principal_name}
              </div>
            </div>
          </div>
          <div className='approve_detail_right'>
            {data.register_state === 1 ? (<img src={require('./../../../../images/chemicals/06.png')} style={{ width: '90px' }} alt='' />) : ('')}
            {data.register_state === 2 ? (<img src={require('./../../../../images/chemicals/07.png')} style={{ width: '90px' }} alt='' />) : ('')}
            {(data.register_state == 4 || data.register_state === 5) ? (<img src={require('./../../../../images/chemicals/08.png')} style={{ width: '90px' }} alt='' />) : ('')}
          </div>
        </div>
        <div className='approve_detail_cont'>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>登记单号</div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {data.register_code}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>所属学院</div>
              <div>{data.ssxy_mc}</div>
            </div>
            <div className='approve_detail_item'>
              <div>所属中心</div>
              <div>{data.sszx_mc}</div>
            </div>
            <div className='approve_detail_item'>
              <div>所属实验室</div>
              <div>{data.room_name}</div>
            </div>
          </div>


          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>负责人</div>
              <div>
                {data.principal_name}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>登记人</div>
              <div>
                {data.dispose_name}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>登记时间</div>
              <div>
                {data.create_time ? moment(data.create_time).format('YYYY-MM-DD HH:mm') : ''}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>预处理时间</div>
              <div>
                {data.predict_dispose_time ? moment(data.predict_dispose_time).format('YYYY-MM-DD HH:mm') : ''}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>存放地点</div>
              <div>
                {data.specific_location}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>备注</div>
              <div>
                {data.register_descr}
              </div>
            </div>
          </div>
          <div className='subscription_list_card'>
            {data.registerDetailRecordEntities && data.registerDetailRecordEntities.map((item, key) => {
              return (
                <div className='subscription_list_card_item' style={{ background: '#fff' }} key={key}>
                  <div className='subscription_list_card_head'>
                    <div className='subscription_list_card_head_left'>
                      <div>{key + 1}</div>
                      <div>{item.waste_name}</div>
                    </div>
                    <div className='subscription_list_card_class'>
                      {item.bucket_name}
                    </div>
                  </div>
                  <div className='subscription_list_card_company'>
                    <div>{item.register_code}</div>
                  </div>
                  <div className='subscription_list_card_unit'>
                    <div>{item.waste_form_name}</div>
                    <div>{item.waste_hazard_name}</div>
                    <div>{item.waste_pack_name}</div>
                  </div>
                  <div className='subscription_list_card_num'>
                    <div className='subscription_list_card_num_item'>
                      <div>{item.total_weight}</div>
                      <div>总重量(kg)</div>
                    </div>
                    <div className='subscription_list_card_num_item'>
                      <div>{item.unit_price}</div>
                      <div>处理单价(/kg)</div>
                    </div>
                    <div className='subscription_list_card_num_item'>
                      <div>{item.pack_num}</div>
                      <div>包装数量</div>
                    </div>
                    <div className='subscription_list_card_num_item'>
                      <div>{item.total_money}</div>
                      <div>处理金额</div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>

          <div className='approve_manager_order'>
            <div className='approve_manager_order_title'>
              审批流程
            </div>
            <div className='approve_manager_order_cont'>
              {
                data && data.approvalProcessRecordEntityList && data.approvalProcessRecordEntityList.map((item, key) => {
                  return (
                    <div className='approve_manager_order_item' key={key}>
                      <div className='approve_manager_order_item_left'>
                        <div className='approve_manager_order_item_icon'>
                          {item.pr_approval_result === 0 ? (<img src={require('./../../../../images/chemicals/12.png')} alt='' />) : ''}
                          {item.pr_approval_result === 1 ? (<img src={require('./../../../../images/chemicals/11.png')} alt='' />) : ''}
                          {item.pr_approval_result === 2 ? (<img src={require('./../../../../images/chemicals/10.png')} alt='' />) : ''}

                        </div>
                        <div className='approve_manager_order_item_line'>
                        </div>
                      </div>
                      <div className='approve_manager_order_item_right'>
                        <div className='approve_manager_order_item_name'>
                          <div>
                            {item.pr_approval_username ? item.pr_approval_username + '(' + item.pr_auth_name + ')' : item.pr_auth_name}
                          </div>
                          <div>{item.pr_approval_result !== 0 ? moment(item.pr_submit_time).format('YYYY-MM-DD HH:mm') : ''}</div>
                        </div>
                        {item.pr_approval_result === 0 ? (<div className='approve_manager_status'>
                          未审批
                        </div>) : ''}
                        {item.pr_approval_result === 1 ? (<div className='approve_manager_status_wait'>
                          待审批
                        </div>) : ''}
                        {item.pr_approval_result === 2 ? (<div className='approve_manager_status_pass_through'>
                          已审批
                        </div>) : ''}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default deviceDetail