import React, { Component } from 'react'
import { Toast, Grid } from 'antd-mobile'
import { findModuleInfo } from '../../../api/api'
import { getSf } from '../../../api/patrol'
import IP from '../../../config/config'

class Patrol extends Component {

  state = {
    moduleData: [], // 模块
    role: 0
  }

  componentDidMount = () => {
    document.title = "安全巡检"
    this.fetchModule()
    this._getSf()
  }

  _getSf = async () => {
    const res = await getSf()
    if (res.code === 20000) {
      this.setState({
        role: res.data
      })
    }
  }

  // 查询模块
  fetchModule = async () => {
    const res = await findModuleInfo()
    if (res.code === 20000) {
      this.setState({
        moduleData: (res.data || []).filter(x => x.module_type === '4')
      })
    }
  }

  // 跳转模块
  handleSkipModule = item => {
    if (item.module_url.indexOf("module") !== -1) {
      if (item.module_url.indexOf('/module/patrol/statistics') > -1) {
        // this.props.history.push({ pathname: item.module_url, state: this.state.role })
        this.props.history.push(`${item.module_url}/${this.state.role ? this.state.role : 0}`)
      } else {
        this.props.history.push(item.module_url)
      }
    } else {
      Toast.info('模块开发中...')
    }
  }

  render () {
    const { moduleData } = this.state
    return (
      <div className='patrol'>
        <div className='patrol_img'>
          <img alt='' src={require('../../../images/patrol/banner.png')} />
        </div>
        <div className='patrol_con'>
          <Grid
            columnNum={3}
            data={moduleData}
            hasLine={false}
            renderItem={item => (
              <div className='patrol_con_list' onClick={() => this.handleSkipModule(item)}>
                <img src={IP.fileHost + item.module_imge} alt="" style={{ width: '44px' }} />
                <span>{item.module_name}</span>
              </div>
            )}
          />
        </div>
      </div>
    )
  }
}

export default Patrol
