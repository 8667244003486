import React, { Component } from 'react'
import { SearchBar, Pagination, Icon, } from 'antd-mobile'
import { bottlefoAll } from './../../../../api/gas'
import moment from 'moment'
import './list.less'

class List extends Component {
  state = {
    paramKey: '',
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    total: 0,
    data: [],
    sourceData: [
      {
        label: '校级',
        value: 1,
      },
      {
        label: '院级',
        value: 2,
      },
      {
        label: '赠送',
        value: 3,
      },
      {
        label: '其他',
        value: 4,
      },
    ]
  }

  componentDidMount = () => {
    document.title = '钢瓶列表'
    window.scrollTo(0, 0)
    this.fetch()
  }

  fetch = async () => {
    const { pageSize, pageNum, paramKey } = this.state
    const res = await bottlefoAll({ size: pageSize, page: pageNum, bottle_number: paramKey })
    if (res.code === 20000) {
      this.setState({
        data: res.data.list,
        pages: res.data ? (res.data.pages ? res.data.pages : 1) : 1,
        total: res.data.total,
      })
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className='gas_manage_list'>
        <div className='gas_manage_list_head_bg'>
          <div className='gas_manage_list_head'>
            <SearchBar placeholder="请输入钢瓶编号" value={this.state.paramKey} onCancel={(e) => {
              this.setState({
                paramKey: ''
              }, () => {
                this.fetch()
              })
            }} onChange={e => {
              this.setState({
                paramKey: e
              }, () => {
                this.fetch()
              })
            }} />
          </div>
        </div>
        <div className='gas_manage_list_record' style={{ padding: '86px 12px 12px' }}>
          {data.map((item, key) => {
            return (
              <div className='gas_manage_list_item' key={key}>
                <div className='gas_manage_list_item_title'>
                  {item.gas_name}（{item.state === 1 ? <span style={{ color: '#0078C7' }}>在库</span> : <span style={{ color: '#E77F00' }}>已出库</span>}）
                </div>
                <div className='gas_manage_list_item_tab'>
                  <div>编号 {item.bottle_number}</div>
                  <div>{item.bottle_specs}</div>
                  <div>{item.bottle_type}</div>
                </div>
                <div className='gas_manage_list_item_tag'>
                  {item.gas_type_name}
                </div>
                <div className='gas_manage_list_item_line'></div>
                <div className='gas_manage_list_item_params'>
                  <div>
                    颜&emsp;&emsp;色
                    <span>{item.bottle_color}</span>
                  </div>
                  <div>
                    有无气体
                    <span>{item.gas_number > 0 ? '有气体' : '空瓶'}</span>
                  </div>
                </div>
                <div className='gas_manage_list_item_params'>
                  <div>
                    气&emsp;&emsp;量
                    <span>{item.gas_number}</span>
                  </div>
                  <div>
                    钢瓶来源
                    <span>{this.state.sourceData.find(x => x.value === item.bottle_source).label}</span>
                  </div>
                </div>
                {/* <div className='gas_manage_list_item_param'>
                  <div>
                    充装日期
                    <span>{moment(item.bottle_aerate_time).format('YYYY-MM-DD')}</span>
                  </div>
                </div> */}
                <div className='gas_manage_list_item_param'>
                  <div>
                    位置信息
                    <span>{item.lymc + '/' + item.lcmc + '/' + item.room_name}</span>
                    <img src={require('./../../../../images/gas/02.png')} alt='' />
                  </div>
                </div>

              </div>
            )
          })}

          {this.state.data.length === 0 ? <div className='not-yet'>--暂无数据--</div> : ''}

          {
            this.state.total > 20 ? (<div className='gas_manage_manager_page'>
              <Pagination total={this.state.pages}
                current={this.state.pageNum}
                onChange={e => {
                  this.setState({
                    pageNum: e
                  }, () => {
                    window.scrollTo(0, 0)
                    this.fetch()
                  })
                }}
                locale={{
                  prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                  nextText: (<span className="arrow-align"><Icon type="right" /></span>),
                }}
              />
            </div>) : ''
          }

        </div>



        {/* <div className='gas_manage_list_but'>
          <div onClick={() => {
            this.props.history.push('/module/gasCylinder/manageAdd')
          }}>新增钢瓶</div>
        </div> */}
      </div>
    )
  }
}

export default List
