import React, { Component } from 'react'
import { InputItem, TextareaItem, Toast } from 'antd-mobile'
import { req_insert } from '../../../api/cgyy'
import moment from 'moment'
import './submit.css'

export default class componentName extends Component {
  state = {
    yyrq: this.props.match.params.date,
    yysj: this.props.match.params.time,
    yylx: 2,//状态
    yydw: '',//单位
    yymc: '',//姓名
    yysjh: '',//手机号
    yyscrs: '',//随行人数
    yybz: '',//备注
    xndjdw: '',//校内对接单位
    visible: false
  }

  componentDidMount = () => {
    document.title = "提交预约"
  }

  // 提交
  submit = async () => {
    const { yyrq, yysj, yylx, yydw, yymc, yysjh, yyscrs, yybz, xndjdw, visible } = this.state
    if (visible === true) {
      return
    }
    if (yydw === '') {
      Toast.info("请填写单位名称!")
      return
    }
    if (yymc === '') {
      Toast.info("请填写参观人姓名!")
      return
    }
    if (yysjh === '') {
      Toast.info("请填写参观人手机号!")
      return
    }
    if ((yysjh.replace(/\s/g, "")).length !== 11) {
      Toast.info("请填写11位手机号!")
      return
    }
    if (yyscrs === '') {
      Toast.info("请填写随行人数!")
      return
    }
    if (yylx === 2 && xndjdw === '') {
      Toast.info("请填写校内对接单位!")
      return
    }
    const params = {
      yyrq,
      yysj,
      yylx,
      yydw,
      yymc,
      yysjh: yysjh.replace(/\s/g, ""),
      yyscrs,
      yybz,
      yysjid: this.props.match.params.yysjid,
      xndjdw
    }
    this.setState({
      visible: true
    })
    Toast.loading('正在提交中...', 0)
    const res = await req_insert(JSON.stringify(params))
    if (res.code === 20000) {
      this.setState({
        visible: false
      })
      Toast.hide()
      this.props.history.push(`/module/cgyy/success/${yyrq}/${yysj}`)
    } else {
      this.setState({
        visible: false
      })
    }

  }

  render () {
    const { yyrq, yysj, yylx } = this.state
    return (
      <div className="submit">
        <div className='sub_form'>
          <div className='sub_form_item_date'>
            <div>参观时间</div>
            <div className='sub_form_item_date_value'>
              <div>{moment(yyrq).format('YYYY年MM月DD日')} {yysj}</div>
              {/* <div>
                                <img src={require('./../../images/04.svg')} alt='' />
                            </div> */}
            </div>
          </div>
          <div className='sub_form_raido_bg'>
            <div className='sub_form_raido'>
              <div className={yylx === 2 ? 'sub_form_raido_item sub_form_raido_item_selected' : 'sub_form_raido_item'} onClick={() => {
                this.setState({
                  yylx: 2
                })
              }}>
                校外团队
              </div>
              <div className={yylx === 1 ? 'sub_form_raido_item sub_form_raido_item_selected' : 'sub_form_raido_item'} onClick={() => {
                this.setState({
                  yylx: 1
                })
              }}>
                校内团队
              </div>

            </div>
          </div>



          <div className='sub_form_item'>
            <InputItem placeholder="请填写申请人姓名" onChange={(item) => {
              this.setState({
                yymc: item
              })
            }}
            ><span className='item_red'>*</span>申请人姓名</InputItem>

          </div>

          <div className='sub_form_item sub_form_item_merge'>
            <InputItem placeholder={yylx === 1 ? "请填写所在二级学院或部门" : '请填写所在单位'} onChange={(item) => {
              this.setState({
                yydw: item
              })
            }}
            ><span className='item_red'>*</span>申请人单位</InputItem>
          </div>
          <div className='sub_form_item sub_form_item_two'>
            <InputItem type='phone' placeholder="请填写联系电话" onChange={(item) => {
              this.setState({
                yysjh: item
              })
            }}
            ><span className='item_red'>*</span>联系电话</InputItem>
          </div>

          <div className='sub_form_item'>
            <InputItem type='number' placeholder="请填写随行人数" onChange={(item) => {
              this.setState({
                yyscrs: item
              })
            }}
            ><span className='item_red'>*</span>随行人数</InputItem>
          </div>
          {
            yylx === 2 ? (<div className='sub_form_item sub_form_item_two'>
              <InputItem placeholder="请填写校内对接单位" onChange={(item) => {
                this.setState({
                  xndjdw: item
                })
              }}
              ><span className='item_red'>*</span>校内对接单位</InputItem>
            </div>) : ''
          }



          <div className='sub_form_item_text'>
            <div>备注</div>
            <TextareaItem
              placeholder='如有特殊接待要求请注明'
              rows={4}
              onChange={(item) => {
                this.setState({
                  yybz: item
                })
              }}
            />
          </div>


        </div>


        <div className='home_but'>
          <div onClick={() => {
            this.submit()
          }}>
            提交预约
          </div>
        </div>
      </div>
    )
  }
}
