import http from '../config/httpconfig'

/**
 * 查询手机端统计
 */
export const tjAppIndex = params => http.get('/fqw/statistics/tjAppIndex', params)

/**
 * 查询废弃物登记信息
 */
export const findRegisterRecord = params => http.get('/fqw/registerrecord/findRegisterRecord', params)

/**
 * 查询废弃物分类信息
 */
export const findWasteType = params => http.get('/fqw/wastetype/findWasteType', params)

/**
 * 查询废弃物分类匹配的回收桶信息
 */
export const findBucketInfoByWasteId = params => http.get('/fqw/bucketinfo/findBucketInfoByWasteId', params)

/**
 * 获取审批是多人共同审批还是单人审批
 */
export const findMasterProcess = params => http.get('/fqw/approvalmaster/findMasterProcess', params)

/**
 * 获取下一个审批人员信息
 */
export const findNextApprovalUser = params => http.get('/fqw/approvalprocessrecord/findNextApprovalUser', params)

/**
 * 会签审批判断是否是最后一个人审批（共同审批）
 */
export const estimateCountersign = params => http.get('/fqw/approvalprocessrecord/estimateCountersign', params)

/**
 * 新增废弃物登记信息
 */
export const addRegisterRecord = params => http.jsonPost('/fqw/registerrecord/addRegisterRecord', params)

/**
 * 查询废弃物登记详细信息
 */
export const findRegisterRecordByCode = params => http.get('/fqw/registerrecord/findRegisterRecordByCode', params)

/**
 * 查询废弃物处理列表数据
 */
export const findDisposalRecord = params => http.get('/fqw/registerdisposerecord/findRegisterRecord', params)

/**
 * 取消处理废弃物
 */
export const cancelDispose = params => http.get('/fqw/registerdisposerecord/cancelDispose', params)

/**
 * 处理废弃物
 */
export const disposeRegisterRecord = params => http.jsonPost('/fqw/registerdisposerecord/disposeRegisterRecord', params)

/**
 * 查询处理废弃物详情
 */
export const findDisposeRecordByCode = params => http.get('/fqw/registerdisposerecord/findDisposeRecordByCode', params)

/**
 * 查询废弃物审批列表
 */
export const listMyApprovalRecord = params => http.get('/fqw/approvalprocessrecord/listMyApprovalRecord', params)

/**
 * 查询审批详情
 */
export const listApprovalRecordById = params => http.get('/fqw/approvalprocessrecord/listApprovalRecordById', params)

/**
 * 提交审批
 */
export const submitApprovalResult = params => http.post('/fqw/approvalprocessrecord/submitApprovalResult', params)