import React, { Component } from 'react'
import { Toast, TextareaItem, Picker, List } from 'antd-mobile'
import { req_getDetailsById, req_disposalYbHidden, req_disposalZdHidden, uploadDisposeFile, req_disposalSsbHidden } from '../../../api/repairApi'
import IP from './../../../config/config'
import moment from 'moment'

class ApproveDetail extends Component {

  state = {
    data: {},
    visible: false,
    opinion: '',
    disposalContent: '',//隐患描述
    fileInfo: [],//上传附件
    fileUrl: '',
    type: 1,
    typeData: [
      {
        label: '无隐患',
        value: 0
      },
      {
        label: '一般隐患',
        value: 1
      },
      {
        label: '重大隐患',
        value: 2
      },
    ],
    detailsState: ''
  }

  componentDidMount = () => {
    this.fetch(this.props.match.params.id)
  }

  fetch = async detailsId => {
    const res = await req_getDetailsById({ id: detailsId })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }
  // 提交审批驳回
  handleSubmit = async () => {
    const { data, disposalContent, fileInfo } = this.state
    let method = ''
    // 随手报
    if (data.details_type === 3) {
      if (data.details_state === '') {
        return Toast.info('请选择隐患类型！')
      }
    }
    method = req_disposalZdHidden
    if (disposalContent === '') {
      return Toast.info('请输入整改方案！')
    }
    if (fileInfo.length === 0) {
      return Toast.info('请上传附件！')
    }
    const res = await method({
      detailsState: data.details_state,
      detailsId: this.props.match.params.id,
      disposalContent,
      fileInfo: JSON.stringify(fileInfo)
    })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.props.history.go(-1)
    }
  }

  // 上传文件
  onChangeFile = async (e) => {
    const { fileInfo } = this.state
    const { files } = e.target
    Toast.loading("附件上传中,请稍等", 0)
    const imgSize = files[files.length - 1].size
    const type = files[files.length - 1].type
    if (type.split('/')[0] !== 'image') return Toast.info('请上传图片', 2)
    if (imgSize > 20 * 1024 * 1024) return Toast.info('请上传小于20M的附件', 2)
    let formData = new FormData()
    formData.append("multipartFile", files[files.length - 1])
    const res = await uploadDisposeFile(formData)
    if (res.code === 20000) {
      Toast.hide()
      Toast.info("上传成功", 1)
      fileInfo.push({ file_name: files[files.length - 1].name, file_url: res.data })
      this.setState({ fileInfo })
    } else {
      Toast.hide()
      Toast.fail(res.message)
    }
  }

  // 放大图片或者视频
  handleImageClick = (fileUrl, type) => {
    this.setState({
      visible: true,
      fileUrl,
      type,
    })
  }

  render() {
    const { data, disposalContent, fileInfo, typeData, detailsState } = this.state
    return (
      <div className='approveDetail hidden_handle' style={{ padding: '0px' }}>
        <div className='approve_detail_head hidden_handle_head'>
          <div className='approve_detail_left'>
            <div className='approve_detail_left_title'>
              {data.room_name}（{data.class_name}）
            </div>
            <div className='approve_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='approve_list_head_date'>
                {data.building_name}{data.floor_name}
              </div>
            </div>
          </div>
          <div className='approve_detail_right hidden_handle_type'>
            <img src={require('./../../../img/patrol/未提交.png')} alt='' />
          </div>
        </div>
        <div className='approve_detail_cont'>
          <div className='approve_detail_bar' style={{ padding: '0px' }}>
            <div className={data.task_level === 1 ? 'patrol_type_xiao' : data.task_level === 2 ? 'patrol_type_yuan' : ''} style={{ padding: '12px 16px 0px' }}>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>巡检标题</div>
                <div>{data.task_title}</div>
              </div>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>巡检批次</div>
                <div>{data.task_name}</div>
              </div>

              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>巡检总负责人</div>
                <div>{data.fzr_name}</div>
              </div>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>巡检人</div>
                <div>{data.user_name}</div>
              </div>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>提交时间</div>
                <div>
                  <div>{moment(data.complete_time).format('YYYY-MM-DD HH:mm')}</div>
                </div>
              </div>
              
            </div>
          </div>


          <div>
            {data && data.dangerEntityList && data.dangerEntityList.map((item, key) => {
              return <div className='approve_detail_danger' key={key}>
                <div className='approve_detail_danger_item'>
                  <div>隐患标题</div>
                  <div>{item.danger_title}</div>
                </div>
                <div className='approve_detail_danger_item'>
                  <div>隐患描述</div>
                  <div>{item.danger_content}</div>
                </div>
                <div className='approve_detail_danger_item'>
                  <div>图片预览</div>
                  <div>
                    <img src={IP.fileHost + item.danger_file} alt='' style={{ height: '40px' }} onClick={() => {
                      this.setState({
                        visible: true,
                        fileUrl: item.danger_file,
                        type: 1,
                      })
                    }} />
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
        <div className='approveDetail_btn'>
          <div className='approveDetail_btn'>
            <div className='approveDetail_btn_list' onClick={() => this.handleFailModal()}>隐患误报</div>
            <div className='approveDetail_btn_list' onClick={() => this.handleSuccess()}>隐患属实</div>
          </div>
        </div>

        <input type='file' id='handle_file' accept="image/*" onChange={e => this.onChangeFile(e)} hidden onClick={(event) => { event.target.value = null }} />

        {
          this.state.visible ? <div className='detailImg' onClick={() => this.setState({ visible: false })}>
            {this.state.type === 1 ? (<img alt='' src={IP.fileHost + this.state.fileUrl} style={{ width: '80%' }} />) : (<video width="80%" src={IP.fileHost + this.state.fileUrl} poster={IP.fileHost + this.state.fileUrl} controls="controls" controlslist="nodownload noremoteplayback" disablePictureInPicture>
              your browser does not support the video tag
            </video>)}

          </div> : ''
        }


      </div >
    )
  }
}

export default ApproveDetail
