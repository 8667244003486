import React, { Component } from 'react'
import { findMyGradeInfo } from '../../../api/api'
import './grades.less'
import moment from 'moment'

class GradesDetail extends Component {
  state = {
    examData: []
  }

  componentDidMount = () => {
    this.fetch(this.props.match.params.id)
  }

  fetch = async paperId => {
    const res = await findMyGradeInfo({ paperId })
    if (res.code === 20000) {
      this.setState({
        examData: res.data
      })
    }
  }

  render() {
    const { examData } = this.state

    return (
      <div className="on_line grades_detail">
        <div className='on_line_header'>
          <div className='on_line_title'>
            <div className='on_line_title_bt'>{examData.length && examData[0].paper_name}</div>
          </div>
          {
            examData.map(item => {
              return <div className='on_line_card' key={item.record_id}>
                <div className='grades_detail_con'>
                  {
                    item.exam_result === 1 ? <div className='grades_detail_status succ_status'>合格</div> : <div className='grades_detail_status err_status'>不合格</div>
                  }
                  <div className={`grades_detail_num ${item.exam_result === 1 ? 'succ_num' : 'err_num'}`}>{item.record_score || 0}</div>
                  <div className='grades_detail_title'>得分</div>
                </div>
                <div className='grades_detail_info'>
                  <div className='grades_detail_info_list'>
                    <div className='grades_list_title'>考生姓名</div>
                    <div className='grades_list_con'>{item.record_user_name}</div>
                  </div>
                  <div className='grades_detail_info_list'>
                    <div className='grades_list_title'>学号/工号</div>
                    <div className='grades_list_con'>{item.record_user_number}</div>
                  </div>
                  <div className='grades_detail_info_list'>
                    <div className='grades_list_title'>考试日期</div>
                    <div className='grades_list_con'>
                      {
                        item.record_start_time ? moment(item.record_start_time).format('YYYY年MM月DD日 HH:mm') : ''
                      }
                    </div>
                  </div>
                  {/* <div className='grades_detail_info_list'>
                    <div className='grades_list_title'>考试次数</div>
                    <div className='grades_list_con'>{examData.num}次</div>
                  </div> */}
                </div>
              </div>
            })
          }
        </div>
      </div>
    )
  }
}

export default GradesDetail