import React, { Component } from 'react'
import { SearchBar, Pagination, Icon, } from 'antd-mobile'
import { findPageBfWhpInfo } from './../../../../api/whp'
import moment from 'moment'

class List extends Component {
  state = {
    paramKey: '',
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    total: 0,
    data: [],
  }

  componentDidMount = () => {
    document.title = '报废列表'
    window.scrollTo(0, 0)
    this.fetch()
  }

  fetch = async () => {
    const { pageSize, pageNum, paramKey } = this.state
    const res = await findPageBfWhpInfo({ size: pageSize, page: pageNum, paramKey })
    if (res.code === 20000) {
      this.setState({
        data: res.data.list,
        pages: res.data ? (res.data.pages ? res.data.pages : 1) : 1,
        total: res.data.total,
      })
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className='subscription_list'>
        <div className='subscription_list_head'>
          <SearchBar placeholder="请输入危化品编号" value={this.state.paramKey} onCancel={(e) => {
            this.setState({
              paramKey: ''
            }, () => {
              this.fetch()
            })
          }} onChange={e => {
            this.setState({
              paramKey: e
            }, () => {
              this.fetch()
            })
          }} />
        </div>
        <div className='subscription_list_record'>
          {data.map((item, key) => {
            return (
              <div className='subscription_list_item' key={key} onClick={() => {
                this.props.history.push(`/module/scrapManager/record/${item.bfdh}`)
              }}>
                <div className='subscription_list_item_head'>
                  <div>危化品编号：{item.whp_bh}</div>
                  <div>{item.bfrxm}</div>
                </div>
                <div className='subscription_list_title'>
                  {item.whp_fl}-{item.whp_mc}
                </div>
                <div className='subscription_list_tag'>
                  <div>{item.whp_ggxh}</div>
                  <div>{item.whp_bzsl}{item.whp_bzdw}</div>
                  <div>{item.whp_jldw}</div>
                </div>
                <div className='subscription_list_foot'>
                  <div>
                    {item.cjsj ? moment(item.cjsj).format('YYYY-MM-DD HH:mm:ss') : ''}
                  </div>
                  <div>单价{item.whp_dj}元</div>
                </div>

              </div>
            )
          })}

          {this.state.data.length === 0 ? <div className='not-yet'>--暂无数据--</div> : ''}

          {
            this.state.total > 20 ? (<div className='subscription_manager_page'>
              <Pagination total={this.state.pages}
                current={this.state.pageNum}
                onChange={e => {
                  this.setState({
                    pageNum: e
                  }, () => {
                    window.scrollTo(0, 0)
                    this.fetch()
                  })
                }}
                locale={{
                  prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                  nextText: (<span className="arrow-align"><Icon type="right" /></span>),
                }}
              />
            </div>) : ''
          }

        </div>



        <div className='subscription_list_but'>
          <div onClick={() => {
            this.props.history.push('/module/scrapManager/add')
          }}>危化品报废</div>
        </div>
      </div>
    )
  }
}

export default List
