import http from '../config/httpconfig'
/**
 * 巡检 根据任务id，查巡房间详情，然后提交巡检
 */
export const findRoomPatrolDetail = params => {
  return http.get('/polling/apppollingtask/getPollingDetails', params)
}

//   上传巡检隐患图片
export const uploadPatrolImages = params => {
  return http.jsonPost('/polling/apppollingtask/uploadPollingImgae', params)
}

/**
 * 巡检  根据ID查询巡更点详情(巡更房间完成完成后查询详情使用，带巡更异常照片)
 */
export const getPollingDetailsById = params => {
  return http.get('/polling/apppollingtask/getPollingDetailsById', params)
}


/**
 * 巡检  移动端统计  获取身份验证
 */
export const getSf = params => {
  return http.get('/polling/statistics/getSf', params)
}
/**
 * 巡检  移动端统计  获取校区信息
 */
export const getXqinfo = params => {
  return http.get('/polling/statistics/getXqinfo', params)
}
/**
 * 巡检  移动端统计  按校区/楼宇统计弱电间分布情况
 */
export const statisticsFbqk = params => {
  return http.get('/polling/statistics/statisticsFbqk', params)
}
/**
 * 巡检  移动端统计  按时间统计巡检情况
 */
export const statisticsXjqkByTime = params => {
  return http.get('/polling/statistics/statisticsXjqkByTime', params)
}
/**
 * 巡检  移动端统计  按校区统计巡检情况
 */
export const statisticsXjqkByXq = params => {
  return http.get('/polling/statistics/statisticsXjqkByXq', params)
}
/**
 * 巡检  移动端统计  按时间统计隐患房间
 */
export const statisticsYhfjByTime = params => {
  return http.get('/polling/statistics/statisticsYhfjByTime', params)
}
/**
 * 巡检  移动端统计  按校区统计隐患房间
 */
export const statisticsYhfjByXq = params => {
  return http.get('/polling/statistics/statisticsYhfjByXq', params)
}
/**
 * 巡检  移动端统计  按时间统计隐患类型
 */
export const statisticsYhlxByTime = params => {
  return http.get('/polling/statistics/statisticsYhlxByTime', params)
}
/**
 * 巡检  移动端统计  按校区统计隐患房间
 */
export const statisticsYhlxByXq = params => {
  return http.get('/polling/statistics/statisticsYhlxByXq', params)
}

/**
 * 按校区/楼宇 统计报修分布情况
 */
export const statisticsSsbFbqk = params => http.get('/polling/statistics/statisticsSsbFbqk', params)

