import React, { Component } from 'react'
import { findBfWhpInfoDetails } from '../../../../api/yhp'
import moment from 'moment'

class deviceDetail extends Component {

  state = {
    data: {},
    visible: false,
    opinion: '',
  }

  componentDidMount = () => {
    document.title = '报废详情'
    window.scrollTo(0, 0)
    this.fetch(this.props.match.params.id)
  }

  fetch = async bfdh => {
    const res = await findBfWhpInfoDetails({ bfdh })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }


  render () {
    const { data } = this.state
    return (
      <div className='approveDetail' style={{ padding: '0px 0px 76px' }}>
        <div className='approve_detail_head'>
          <div className='approve_detail_left'>
            <div className='approve_detail_left_title'>
              {data.whp_mc}
            </div>
            <div className='approve_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='approve_list_head_seat'>{data.whp_ggxh}</div>
              <div className='approve_list_head_seat'>{data.whp_bzsl}{data.whp_bzdw}</div>
              <div className='approve_list_head_seat'>{data.whp_jldw}</div>
            </div>
          </div>
          <div className='approve_detail_right'>
          </div>
        </div>
        <div className='approve_detail_cont'>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>报废单号</div>
              <div>
                {data.bfdh}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>易耗品编号</div>
              <div>
                {data.whp_bh}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>标签编码</div>
              <div>
                {data.whp_xh}
              </div>
            </div>
          </div>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>编号</div>
              <div>
                {data.whp_cas}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>规格型号</div>
              <div>
                {data.whp_ggxh}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>包装</div>
              <div>
                {data.whp_bzsl}{data.whp_bzdw}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>计量单位</div>
              <div>
                {data.whp_jldw}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>单价(元)</div>
              <div>
                {data.whp_dj}
              </div>
            </div>
          </div>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>物品分类</div>
              <div>
                {data.whp_fl}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>受管制类别</div>
              <div>
                {data.whp_sgzlb}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>供货商</div>
              <div>
                {data.whp_gys}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>报废时间</div>
              <div>
                {moment(data.cjsj).format('YYYY-MM-DD HH:mm:ss')}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>报废人</div>
              <div>
                {data.bfrxm}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>报废原因</div>
              <div>
                {data.bf_ms}
              </div>
            </div>
          </div>


        </div>
      </div>
    )
  }
}

export default deviceDetail