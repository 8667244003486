import React, { Component } from 'react'
import { Toast, Modal, TextareaItem } from 'antd-mobile'
import { req_getDetailsById, req_completeZdHidden } from '../../../api/repairApi'
import IP from './../../../config/config'
import moment from 'moment'

class ApproveDetail extends Component {

  state = {
    data: {},
    visible: false,
    opinion: '',
    fileUrl: '',
    type: 1,
  }

  componentDidMount = () => {
    document.title = "隐患详情"
    this.fetch(this.props.match.params.id)
  }

  fetch = async detailsId => {
    const res = await req_getDetailsById({ id: detailsId })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }

  // 审核成功
  handleSuccess = async (result) => {
    const { data } = this.state
    const res = await req_completeZdHidden({ detailsId: data.details_id, result })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.props.history.go(-1)
    }
  }

  // 放大图片或者视频
  handleImageClick = (fileUrl, type) => {
    this.setState({
      visible: true,
      fileUrl,
      type,
    })
  }

  render() {
    const { data } = this.state
    return (
      <div className='approveDetail' style={{ padding: '0px' }}>
        <div className='approve_detail_head'>
          <div className='approve_detail_left'>
            <div className='approve_detail_left_title'>
              {data.room_name} {data.class_name ? '（' + data.class_name + '）' : ''}
            </div>
            <div className='approve_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='approve_list_head_date'>
                {data.building_name}{data.floor_name}
              </div>
            </div>
          </div>
          <div className='approve_detail_right hidden_handle_type'>
            {data.hidden_result === 1 ? (<img src={require('./../../../img/patrol/待处理.png')} alt='' />) : ('')}
            {data.hidden_result === 2 ? (<img src={require('./../../../img/patrol/已处理.png')} alt='' />) : ('')}
            {data.hidden_result === 4 ? (<img src={require('./../../../img/patrol/未处理.png')} alt='' />) : ('')}
          </div>
        </div>
        <div className='approve_detail_cont'>
          <div className='approve_detail_bar' style={{ padding: '0px' }}>
            <div className={data.task_level === 1 ? 'patrol_type_xiao' : data.task_level === 2 ? 'patrol_type_yuan' : ''} style={{ padding: '12px 16px 0px' }}>
              {data.dept_name ? (<div className='approve_detail_item patrol_approve_detail_item'>
                <div>所属院系</div>
                <div>{data.dept_name}</div>
              </div>) : ''}

              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>隐患发现人</div>
                <div>{data.user_name}</div>
              </div>
            </div>

          </div>


          <div className='approve_detail_bar' style={{ padding: '0px' }}>
            <div className={data.task_level === 1 ? 'patrol_type_xiao' : data.task_level === 2 ? 'patrol_type_yuan' : ''} style={{ padding: '12px 16px 0px' }}>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>整改状态</div>
                <div>
                  {data.hidden_result === -2 ? '不属实隐患' : ''}
                  {data.hidden_result === -1 ? '待核实隐患' : ''}
                  {data.hidden_result === 0 ? '待整改' : ''}
                  {data.hidden_result === 1 ? '已整改待确认' : ''}
                  {data.hidden_result === 2 ? '整改完成' : ''}
                  {data.hidden_result === 3 ? '整改不合格' : ''}
                  {data.hidden_result === 4 ? '暂无法整改' : ''}
                  {data.hidden_result === 5 ? '隐患撤回' : ''}
                </div>
              </div>
              {data.hidden_result > -1 ? (<div className='approve_detail_item patrol_approve_detail_item'>
                <div>隐患是否属实</div>
                <div>
                  <div><span className='round-label green'>属实</span></div>
                </div>
              </div>) : ''}


            </div>

          </div>

          <div className='patrol_danger' style={{ marginTop: '12px' }}>
            <div className='patrol_danger_head'>
              <div>
                <img src={require('./../../../images/patrol/6.png')} alt='' />
                整改前
              </div>
              <div>
                {moment(data.complete_time).format('YYYY-MM-DD HH:mm')} 提交
              </div>
            </div>
            <div className='patrol_danger_main'>
              <div className='patrol_danger_item'>
                <div className='patrol_danger_desc'>
                  {data.details_content}
                </div>
                <div className='patrol_danger_pic'>
                  {data && data.hiddenFileList && data.hiddenFileList.map(item => {
                    return item.file_type === 1 ? (<img src={IP.fileHost + item.file_url} alt='' style={{ height: '72px', marginRight: '12px', marginBottom: '12px' }} onClick={() => {
                      this.handleImageClick(item.file_url, 1)
                    }} key={item.file_id} />) : (<video height="72px" style={{ marginRight: '12px', marginBottom: '12px' }} src={IP.fileHost + item.file_url} poster={IP.fileHost + item.file_url} controls="controls" controlslist="nodownload noremoteplayback" disablePictureInPicture key={item.file_id}>
                      your browser does not support the video tag
                    </video>)
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className='patrol_change'>
            <div className='patrol_danger_head'>
              <div>
                <img src={require('./../../../images/patrol/7.png')} alt='' />
                整改后
              </div>
              <div>
                <span>{data.dispose_user_name}</span>
                {moment(data.dispose_time).format('YYYY-MM-DD HH:mm')}整改
              </div>
            </div>
            <div className='patrol_danger_main'>
              <div className='patrol_danger_item'>
                <div className='patrol_danger_desc'>
                  {data.dispose_content}
                </div>
                <div className='patrol_danger_pic'>
                  {data && data.disposeFileList && data.disposeFileList.map(item => {
                    return (
                      <img src={IP.fileHost + item.file_url} alt='' key={item.file_id} onClick={() => {
                        this.setState({
                          visible: true,
                          type: 1,
                          fileUrl: item.file_url
                        })
                      }} />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>

          {
            data.hidden_result === 2 || data.hidden_result === 3 ? (<div className='approve_detail_bar' style={{ paddingBottom: '40px' }}>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>验收人</div>
                <div>{data.check_user_name}</div>
              </div>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>验收结果</div>
                <div>{data.hidden_result === 2 ? '整改合格' : '整改不合格'}</div>
              </div>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>验收描述</div>
                <div>{data.check_content}</div>
              </div>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>验收时间</div>
                <div>{moment(data.check_time).format('YYYY-MM-DD HH:mm:ss')}</div>
              </div>
            </div>) : ''
          }


        </div>


        {
          this.state.visible ? <div className='detailImg' onClick={() => this.setState({ visible: false })}>
            {this.state.type === 1 ? (<img alt='' src={IP.fileHost + this.state.fileUrl} style={{ width: '80%' }} />) : (<video width="80%" src={IP.fileHost + this.state.fileUrl} poster={IP.fileHost + this.state.fileUrl} controls="controls" controlslist="nodownload noremoteplayback" disablePictureInPicture>
              your browser does not support the video tag
            </video>)}

          </div> : ''
        }
      </div>
    )
  }
}

export default ApproveDetail
