import React, { Component } from 'react'
import { SearchBar } from 'antd-mobile'
import { findNewestMessageDevice, updateMessageStateDevice } from '../../api/api'
import moment from 'moment'

class RoomOrder extends Component {

  state = {
    searchContent: '',
    data: []
  }

  componentDidMount = () => {
    this.fetch()
  }

  fetch = async () => {
    const { searchContent } = this.state
    const res = await findNewestMessageDevice({ flag: 2, title: searchContent })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  handleCancel = () => {
    this.setState({
      searchContent: ''
    }, () => {
      this.fetch()
    })
  }

  // 搜索房间
  handleChange = e => {
    this.setState({
      searchContent: e
    }, () => {
      this.fetch()
    })
  }

  // 修改实验室预约为已读
  fetchRead = async messageId => {
    await updateMessageStateDevice({ messageId })
  }

  // 跳转
  handleSkip = item => {
    if (item.message_flag === 1) {
      this.props.history.push({ pathname: '/module/approve', state: { status: 4 } })
    }
    if (item.message_flag === 2) {
      this.fetchRead(item.message_id)
      this.props.history.push('/module/deviceRecord')
    } 
  }

  render() {
    const { data } = this.state
    return (
      <div className='risk'>
        <div className='risk_search'>
          <SearchBar placeholder="请输入搜索消息信息" onCancel={() => this.handleCancel()} onChange={e => this.handleChange(e)} />
        </div>
        <div className='risk_con'>
          {
            (data || []).length ? data.map(item => {
              return <div className='risk_con_list' key={item.message_id}>
                <div className='risk_con_list_con'>
                  <div className='risk_con_list_name'>{item.message_title}</div>
                  <div className='risk_con_list_desc'>{item.message_content}</div>
                  <div className='risk_con_list_bottom'>
                    <span onClick={() => this.handleSkip(item)}>立即跳转</span>
                  </div>
                </div>
                <div className='risk_con_list_time'>{item.create_time ? moment(item.create_time).format('YYYY-MM-DD HH:mm:ss') : moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}</div>
              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
      </div>
    )
  }
}

export default RoomOrder