import React, { Component } from 'react'
import { Icon, Toast } from 'antd-mobile'
import { findSaftyTypeDetail, findCourseInfo, findZJInfo } from '../../../api/safty'

export default class StudyOnline extends Component {
  state = {
    typeId: this.props.match.params.typeId,
    typeData: {},
    courseName: '',
    courseList: [],
    zjList: JSON.parse(sessionStorage.getItem('zjList')) || [],
    courseId: Number(sessionStorage.getItem('courseId')) || '',
  }
  componentDidMount() {
    this.fetchTypeDetail()
    this.fetCourseInfo()
  }
  fetchTypeDetail = async () => {
    const { typeId } = this.state
    const res = await findSaftyTypeDetail({ categoryId: typeId })
    if (res.code === 20000) {
      this.setState({
        typeData: res.data
      })
    }
  }
  fetCourseInfo = async () => {
    const { typeId } = this.state
    const res = await findCourseInfo({ categoryId: typeId })
    if (res.code === 20000) {
      this.setState({
        courseList: res.data
      })
    }
  }

  formatToTree = (ary, pid) => {
    return ary
      .filter((item) =>
        // 如果没有父id（第一次递归的时候）将所有父级查询出来
        // 这里认为 item.parentId === 1 就是最顶层 需要根据业务调整
        pid === undefined ? item.parent_id === 1 : item.parent_id === pid
      )
      .map((item) => {
        // 通过父节点ID查询所有子节点
        item.children = this.formatToTree(ary, item.catalogue_id)
        return item
      })
  }

  getZJ = async (courseId) => {
    const { courseId: stateCourseId } = this.state
    if (courseId === stateCourseId) return
    Toast.loading('加载中...', 0)
    const res = await findZJInfo({ courseId })
    if (res.code === 20000) {
      Toast.hide()
      this.setState({
        zjList: this.formatToTree(res.data, 0),
        courseId
      }, () => {
        sessionStorage.setItem("zjList", JSON.stringify(this.state.zjList))
        sessionStorage.setItem("courseId", this.state.courseId)
      })
    }
  }
  viewZJDetail = async (zjId) => {
    const { typeId } = this.state
    this.props.history.push(`/module/safty/study/online/${typeId}/${zjId}`)
  }

  render() {
    const { typeData, courseId, courseList, zjList } = this.state
    const handleLoop = data => {
      return data.map(item => {
        if (item.children && item.children.length) {
          return <div className='content' key={item.catalogue_id} >
            <div className="title" onClick={() => this.viewZJDetail(item.catalogue_id)}>
              {/* <Icon type='up' className='iconSty' /> */}
              {item.catalogue_name}
            </div>
            {handleLoop(item.children)}
          </div>
        }
        return <div className="content" key={item.catalogue_id} >
          <div className="title" onClick={() => this.viewZJDetail(item.catalogue_id)}>
            {/* <div className='kx_circle'></div> */}
            {item.catalogue_name}
          </div>
        </div>
      })
    }
    return (
      <div className="on_line onLineStudy">
        <div className='on_line_header'>
          <div className='on_line_title'>
            <div className='on_line_title_bt'>{typeData.category_name}</div>
          </div>
        </div>
        {
          courseList.length ?
            (courseList || []).map(item => {
              return (
                <div className='content' key={item.course_id}>
                  <div className="title" onClick={() => this.getZJ(item.course_id)}>
                    {
                      courseId === item.course_id ? <Icon type='up' className='iconSty' style={{ backgroundColor: '#00B4C4' }} /> : <Icon type='down' className='iconSty' style={{ backgroundColor: '#D1D2D4' }} />
                    }
                    {/* <Icon type='up' className='iconSty' style={{ backgroundColor: courseId === item.course_id ? '#00B4C4' : '#D1D2D4' }} /> */}
                    {item.course_name}{item.require_learned !== 0 ? '【必修】' : '【非必修】'}
                  </div>
                  {
                    (courseId === item.course_id) && handleLoop(zjList)
                  }
                </div>
              )
            }) : <div style={{ marginTop: '100px', textAlign: 'center' }}>--暂无课程--</div>
        }


      </div>
    )
  }
}
