import React, { Component } from 'react'
import { req_findAirSwitchDevice, req_yongdianRoomDeviceGroupList, req_yongdianRoomKKList, req_sendCmd, req_fetchRoomInfo, req_findDtuDevice } from '../../../api/api'
import './air_switch.less'
import { Toast, Modal, Icon } from 'antd-mobile'
import moment from 'moment'
import cookie from 'react-cookies'
import IP from '../../../config/config'

class AirSwitch extends Component {

  state = {
    room: {},
    deviceGroupList: [],
    groupId: null,
    data: [],
    electricData: {},
    isActive: false,
    isAllChoose: false,
    fetchSuccNum: 0,
    choosedAirSwitchIdList: [],
    choosedAirSwitchItemList: [],
    wholeNum: 0,
    otherNum: 0,
    modal: false,
    cmdTypeTitle: ''
  }

  componentDidMount = async () => {
    document.title = "智慧用电-空开"

    let item = {}
    if (!this.props.location.state.item) {
      const room_id = this.props.match.params.id
      const room_info = await req_fetchRoomInfo({ id: room_id })
      item.room_id = room_info.data.room_id
      item.room_number = room_info.data.room_number
      item.room_name = room_info.data.room_name
      item.building_name = room_info.data.lymc
      item.floor_name = room_info.data.lcmc
    } else {
      item = this.props.location.state.item
    }

    await this.getYongDianRoomDeviceGroupList(item)
    await this.fetch(item, this.state.groupId)
    const { deviceGroupList, data, wholeNum } = this.state
    //将全部设备和其他分组添加到分组数据中，如果后端没有返回分组数据，则没有其他这个分组
    const wholeObj = { group_id: null, group_name: '全部设备', switch_num: wholeNum }
    let otherNum = 0

    if ((Array.isArray(data) && data.length) && (Array.isArray(deviceGroupList) && deviceGroupList.length)) {
      const sum = deviceGroupList.reduce((prev, cur) => prev + cur.switch_num, 0)
      otherNum = wholeNum - sum
    }
    let newDeviceGroupList = [wholeObj, ...deviceGroupList]
    if (otherNum) {
      const otherObj = { group_id: 0, group_name: '其它', switch_num: otherNum }
      newDeviceGroupList = [...newDeviceGroupList, otherObj]
    }

    this.setState({
      room: item,
      otherNum,
      deviceGroupList: newDeviceGroupList
    })


  }
  //获取设备分组有哪些
  getYongDianRoomDeviceGroupList = async (item) => {
    const res = await req_yongdianRoomDeviceGroupList({ roomId: item.room_id })
    if (res.code === 20000) {
      this.setState({
        deviceGroupList: res.data
      })
    }

  }
  //获取当前分组下的空开数据
  fetch = async (item, groupId) => {
    Toast.loading('加载中...', 10)
    const res = await req_yongdianRoomKKList({ roomId: item.room_id, groupId })
    if (res.code === 20000) {
      this.setState({
        data: res.data.deviceList,
        electricData: res.data.electric
      })
      if (groupId === null) {
        this.setState({
          wholeNum: res.data.deviceList.length
        })
      }
      Toast.hide()
    }
  }

  // 开关选项按钮
  handleChangeSwitch = item => {
    if (item.air_state === 2) {
      Toast.info('设备不在线，无法操作！')
      return false
    }
    if (item.air_kg_state) {
      this.handleSendCmd(item.air_switch_id, 'CMD_FZ')
    } else {
      this.handleSendCmd(item.air_switch_id, 'CMD_HZ')
    }
  }

  // 空开操作
  handleSendCmd = async (switchId, cmdType) => {
    Toast.loading('命令执行中...')
    const { data } = this.state
    const res = await req_sendCmd({ switchId, cmdType })
    if (res.code === 20000) {
      const arr = data.map(x => {
        if (x.air_switch_id === switchId) {
          x.air_kg_state = cmdType === 'CMD_FZ' ? 0 : 1
        }
        return x
      })
      this.setState({
        data: arr
      })
      Toast.success(res.message)
    } else {
      Toast.fail('执行失败！')
    }
  }
  // 空开自检
  handleSelfCheck = async (item, cmdType) => {
    if (item.air_state === 2) {
      Toast.info('设备不在线，无法自检！')
      return false
    }
    Toast.loading('命令执行中...')
    const res = await req_sendCmd({ switchId: item.air_switch_id, cmdType })
    if (res.code === 20000) {
      Toast.success(res.message)
    } else {
      Toast.fail('执行失败！')
    }
  }
  changeActive = () => {
    this.setState({
      isActive: true
    })
  }
  //改变所有的选中状态
  changechooseStatus = () => {
    const length1 = Array.isArray(this.state.data) && this.state.data.filter(d => d.air_state === 1).length
    const length2 = this.state.choosedAirSwitchIdList.length
    if (length2 < length1) {
      //选中的长度小于在线数据列表的长度，则变为全选
      const arr = []
      this.state.data.filter(d => d.air_state === 1).forEach(d => {
        //设备在线才能选中
        arr.push(d.air_switch_id)
      })
      this.setState({
        choosedAirSwitchIdList: arr
      })
    } else {
      //选中的长度等于在线数据列表的长度，则变为取消全选
      this.setState({
        choosedAirSwitchIdList: []
      })
    }
  }
  //改变每一个的选中状态
  changeItemChooseStatus = item => {
    if (item.air_state === 2) {
      Toast.info('设备不在线，无法操作！')
      return false
    }
    const pos = this.state.choosedAirSwitchIdList.indexOf(item.air_switch_id)
    const copyList = this.state.choosedAirSwitchIdList.slice()
    if (pos === -1) {
      copyList.push(item.air_switch_id)
    } else {
      copyList.splice(pos, 1)
    }
    this.setState({
      choosedAirSwitchIdList: copyList
    }, () => {
      console.log(this.state.choosedAirSwitchIdList, 'XXXXXXXXXXXXXXXXXXXXXXXXXX')
    })
  }
  //取消按钮
  handleCancel = () => {
    this.setState({
      isActive: false,
      choosedAirSwitchIdList: [],
      choosedAirSwitchItemList: []
    })
  }
  //切换分组选项
  handleGroup = (groupId) => {
    this.setState({
      groupId
    })
    this.handleCancel()
    this.fetch(this.state.room, groupId)
  }

  //一键开启/关闭/自检
  yiJianOperate = (e, key, type) => {
    e.preventDefault() // 修复 Android 上点击穿透
    const { choosedAirSwitchIdList, choosedAirSwitchItemList, data } = this.state
    if (choosedAirSwitchIdList.length === 0) {
      Toast.info('没有选中的设备！')
      return false
    }
    const list = JSON.parse(JSON.stringify(data)).filter(d => choosedAirSwitchIdList.indexOf(d.air_switch_id) !== -1)
    this.setState({
      choosedAirSwitchItemList: list
    })
    const _this = this
    list.forEach((c, index) => {
      ((c, index) => {
        setTimeout(async () => {
          const res = await req_sendCmd({ switchId: c.air_switch_id, cmdType: type })
          switch (type) {
            case 'CMD_FZ':
              if (res.code === 20000) {
                c.status = 1
              } else if (res.code === 20001) {
                c.status = -1
              } else { }
              c.air_kg_state = 0
              break
            case 'CMD_HZ':
              if (res.code === 20000) {
                c.status = 1
              } else if (res.code === 20001) {
                c.status = -1
              } else { }
              c.air_kg_state = 1
              break
            case 'CMD_LDZJ':
              if (res.code === 20000) {
                c.status = 1
              } else if (res.code === 20001) {
                c.status = -1
              } else { }
              break
            default:
              break
          }
          c.message = res.message
          _this.setState((state, props) => {
            return {
              fetchSuccNum: state.fetchSuccNum + 1,
              choosedAirSwitchItemList: list,
            }
          })
        }, 1500 + index * 1500)
      })(c, index)
    })

    switch (type) {
      case 'CMD_FZ':
        this.setState({
          cmdTypeTitle: '关闭'
        })
        break
      case 'CMD_HZ':
        this.setState({
          cmdTypeTitle: '开启'
        })
        break
      case 'CMD_LDZJ':
        this.setState({
          cmdTypeTitle: '自检'
        })
        break
      default:
        break
    }
    this.setState({
      [key]: true,
    })

  }
  showModal = (e, key) => {
    e.preventDefault() // 修复 Android 上点击穿透
    this.setState({
      [key]: true,
    })
  }
  onClose = key => {
    if (this.state.fetchSuccNum !== this.state.choosedAirSwitchItemList.length) {
      Toast.info('命令正在执行中！')
      return false
    }
    this.setState({
      [key]: false,
    })
  }
  //modal框关闭时将数据清空
  handleAfterClose = () => {
    this.setState({
      fetchSuccNum: 0,
      choosedAirSwitchItemList: []
    })
    this.fetch(this.state.room, this.state.groupId)
  }
  //转换单选框的样式
  changeRadioStyle = (item) => {
    if (item.air_state === 2) {
      return 'notCanChoose'
    }
    if (this.state.choosedAirSwitchIdList.indexOf(item.air_switch_id) > -1) {
      return 'is_check'
    } else {
      return 'no_check'
    }
  }

  render() {
    const { data, electricData, room, deviceGroupList, fetchSuccNum, choosedAirSwitchIdList, choosedAirSwitchItemList, otherNumm, groupId, isActive, isAllChoose, cmdTypeTitle } = this.state
    return (

      <div className='KK'>
        <div className='KK_top'>
          <div className='KK_top_floor1'>
            <div className='pos'>{room.building_name}/{room.floor_name}/{room.room_number ? `${room.room_number} ${room.room_name}` : room.room_name} </div>
            <div className='YDTongJi'>
              <div>
                <p>{electricData.total}</p>
                <span>总电量（kw.h）</span>
              </div>
              <div>
                <p>{electricData.day}</p>
                <span>今日电量（kw.h）</span>
              </div>
              <div>
                <p>{electricData.month}</p>
                <span>本月电量（kw.h）</span>

              </div>
            </div>
          </div>
          <div className="scroll_floor KK_top_floor2">
            <div className='wrap'>
              {(deviceGroupList || []).map((d, k) => {
                return <div key={k} className={groupId === d.group_id ? 'ev-div selected' : 'ev-div'} onClick={() => this.handleGroup(d.group_id)}>
                  {d.group_name}<span>({d.switch_num})</span>
                  <div className='bottom-block'></div>
                </div>
              })}
            </div>
          </div>
        </div>
        <div className={isActive ? 'KK_content active' : 'KK_content'}>
          {
            Array.isArray(data) ?
              data.length ?
                data.map(item => {
                  return (
                    <div className='KK_content_item' key={item.air_switch_id}>
                      <div className='check' onClick={() => this.changeItemChooseStatus(item)}>
                        <img src={require(`../../../images/${this.changeRadioStyle(item)}.png`)} alt="" />
                      </div>
                      <div className='item_desc'>
                        <div>
                          <div className="KK_content_item_floor1">
                            <div className='floor1_left'>
                              {/* air_state  1:在线  2:离线 */}
                              <img src={require(`../../../images/KK_icon_${item.air_state === 1 ? 'ON' : 'OFF'}.png`)} alt="" />
                              <div>
                                <p>{item.air_switch_name}
                                  {/* {item.air_switch_imei} */}
                                </p>
                                {item.data_upload_time ? <span>{moment(item.data_upload_time).format('YYYY-MM-DD HH:mm:ss')} 记录</span> : <span>&nbsp;</span>}
                              </div>
                            </div>
                            {/* air_kg_state  1:合闸，开着  0:分闸，关着 */}
                            <img className='floor1_right'
                              src={require(`../../../images/${item.air_kg_state === 1 ? 'kai' : 'guan'}.png`)}
                              alt=""
                              onClick={() => this.handleChangeSwitch(item)} />
                          </div>
                          <div className='roomAirSwitch_con_d_list_main KK_content_item_floor2'>
                            <div className={'airSwitch_con_list_main_item'}>
                              <div>{item.air_h_volt ? item.air_h_volt : 0}</div>
                              <div>电压有效值V</div>
                            </div>
                            <div className={'airSwitch_con_list_main_item'}>
                              <div>{item.air_h_curr ? item.air_h_curr : 0}</div>
                              <div>电流有效值A</div>
                            </div>
                            <div className={'airSwitch_con_list_main_item'}>
                              <div>{item.air_freq ? item.air_freq : 0}</div>
                              <div>频率Hz</div>
                            </div>
                            <div className={'airSwitch_con_list_main_item'}>
                              <div>{item.air_temp ? item.air_temp : 0}</div>
                              <div>设备温度℃</div>
                            </div>
                            <div className={'airSwitch_con_list_main_item'}>
                              <div>{item.air_h_actpow ? item.air_h_actpow : 0}</div>
                              <div>有功功率kW</div>
                            </div>
                            <div className={'airSwitch_con_list_main_item'}>
                              <div>{item.air_h_pow ? item.air_h_pow : 0}</div>
                              <div>功率因数</div>
                            </div>
                            <div className={'airSwitch_con_list_main_item'}>
                              <div>{item.air_h_energy ? item.air_h_energy : 0}</div>
                              <div>有功电能kWh</div>
                            </div>
                            <div className={'airSwitch_con_list_main_item'}>
                              <div>{item.air_leak_curr ? item.air_leak_curr : 0}</div>
                              <div>漏电mA</div>
                            </div>
                          </div>
                          <div className="scroll_floor KK_content_item_floor3">
                            <div className="wrap">
                              <div className='ev-div' onClick={() => this.props.history.push(`/module/electricity/timingConfig/${item.air_switch_id}`)}>
                                <i className="oper_icon setTime"></i>
                                参数配置
                              </div>
                              <div className='ev-div' onClick={() => this.props.history.push(`/module/electricity/alarmData/${item.air_switch_imei}`)}>
                                <i className="oper_icon BaoJing"></i>
                                告警数据
                              </div>
                              <div className='ev-div' onClick={() => this.props.history.push(`/module/electricity/operationLog/${item.air_switch_imei}`)}>
                                <i className="oper_icon CaoZuo"></i>
                                操作记录
                              </div>
                              <div className='ev-div' onClick={() => this.handleSelfCheck(item, 'CMD_LDZJ')}>
                                <i className="oper_icon LJZhiJian"></i>
                                立即自检
                              </div>
                            </div>
                          </div>
                          {item.air_state === 1 ?
                            <div className='KK_content_item_floor4 ON'>
                              在线
                            </div> :
                            <div className='KK_content_item_floor4 OFF'>
                              离线
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  )
                }) :
                <div style={{ marginTop: '30px', textAlign: 'center' }}>暂无数据</div>
              : <div style={{ marginTop: '30px', textAlign: 'center' }}>返回数据错误</div>
          }

        </div>
        <div className="KK_bottom">
          {isActive ?
            <div className='KK_bottom_choose'>
              <div onClick={this.changechooseStatus}>
                <i className={`${choosedAirSwitchIdList.length > 0 && (choosedAirSwitchIdList.length === data.filter(d => d.air_state === 1).length) ? 'choose all_choose' : 'choose no_all_choose'}`}></i>
                全选
              </div>
              <div className='cancel' onClick={this.handleCancel}>取消</div>
            </div>
            :
            <div className='KK_bottom_choose' onClick={this.changeActive}>
              <i className='choose no_active'></i>
              全选</div>
          }

          <div className='operate-btn'>
            <div className='btn-ljzj' onClick={e => this.yiJianOperate(e, 'modal', 'CMD_LDZJ')}>
              <img src={require('../../../images/LJZhiJian_icon.png')} alt="" />
              <span>一键自检</span>
            </div>
            <div className='btn-yjgb' onClick={e => this.yiJianOperate(e, 'modal', 'CMD_FZ')}>
              <img src={require('../../../img/controll/OFF.png')} alt="" />
              <span>一键关闭</span>
            </div>
            <div className='btn-yjkq' onClick={e => this.yiJianOperate(e, 'modal', 'CMD_HZ')}>
              <img src={require('../../../img/controll/ON.png')} alt="" />
              <span>一键开启</span>
            </div>
          </div>
        </div>
        <Modal
          className='modal_cont_wrap'
          closable
          visible={this.state.modal}
          transparent
          maskClosable={false}
          onClose={() => this.onClose('modal')}
          title={`一键${cmdTypeTitle}`}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
          afterClose={this.handleAfterClose}
        >
          <div style={{ maxHeight: 310, overflow: 'auto', marginRight: '-10px' }}>
            {fetchSuccNum === choosedAirSwitchItemList.length ? <p className='totalStatus'>命令执行完毕！</p> : <p className='totalStatus'>命令正在执行中...</p>}
            <div style={{ marginRight: '15px' }}>
              {choosedAirSwitchItemList.length ?
                choosedAirSwitchItemList.map((item, k) => {
                  return <div className='modal_cont_wrap_item' key={item.air_switch_id}>
                    <div className='sortNum'>{k + 1}</div>
                    <div className='sortDesc'>
                      <p>{item.air_switch_name} {item.air_switch_imei}</p>
                      <div><span className='process' style={{ width: `${item.status ? 100 : 60}%` }}></span></div>
                      <span>{item.message || '命令执行中...'}</span>
                    </div>
                    {item.status === 1 ? <img className='sortStatus' src={require('../../../images/open_success_icon.png')} alt="" /> : <div className='sortStatus'></div>}
                  </div>
                }) : <Icon type='loading' size='lg' />}
            </div>
          </div>
        </Modal>
      </div >
    )
  }
}

export default AirSwitch
