import React, { Component } from 'react';
import { InputItem, List, DatePicker, Toast, Modal } from 'antd-mobile'
import { req_batchAuthRoom, req_listUserAuthMmRoom, req_listUserBuildingInfo } from '../../../api/api'
import copy from 'copy-to-clipboard'
import moment from 'moment'
import './door_lock.less'

class Batch extends Component {

  state = {
    password: '',
    usedName: '',
    startTime: '',
    endTime: '',
    authRoomIds: [],
    pwdLength: 6, // 密码个数
    visible: false, // modal状态
    modalType: 0, // 楼宇类别状态
    buildingId: '', // 楼宇上选中的id
    floorId: '', // 楼层上选中的id
    buildingName: '', // 楼宇上选中的name
    floorIdName: '', // 楼层上选中的name
    infoData: [], // 类别
    roomData: [], // 房间
  }

  componentDidMount = () => {
    window.addEventListener('focusin', this.setScrollTop)
    window.addEventListener('focusout', this.setScrollTop)
    this.setState({
      password: this.mathRand(),
      startTime: new Date(),
      endTime: new Date(new Date().getTime() + 1800000),
    })
  }

  setScrollTop = () => {
    let scrollTop = document.body.scrollTop + document.documentElement.scrollTop
    window.scrollTo(0, scrollTop)
  }

  // 输入密码
  handleChangePwd = e => {
    const { pwdLength } = this.state
    let value = e
    if (e.length > pwdLength) {
      value = value.slice(0, pwdLength)
    }
    this.setState({
      password: value
    })
  }
  // 获取使用人姓名
  handleChangeName = e => {
    this.setState({
      usedName: e
    })
  }

  // 随机生成六位数字
  handleMathRand = () => {
    this.setState({
      password: this.mathRand()
    })
  }

  // 随机生成六位数字
  mathRand = () => {
    const { pwdLength } = this.state
    var num = "";
    for (var i = 0; i < pwdLength; i++) {
      num += Math.floor(Math.random() * 10);
    }
    return num
  }

  // 复制分享
  handleCopy = () => {
    const { password } = this.state
    copy(password);
    Toast.success('复制成功');
  }

  // 打开授权房间modal
  handleRoom = () => {
    this.findListUserRoom()
    this.setState({
      visible: true,
    })
  }

  // 查询类别
  findListBuildingInfo = async () => {
    this.setState({
      infoData: []
    })
    const { buildingId, modalType } = this.state
    const res = await req_listUserBuildingInfo({ buildingId: modalType === 2 ? buildingId : '', type: modalType })
    if (res.code === 20000) {
      const obj = {
        id: '',
        title: '全部'
      }
      res.data.unshift(obj)
      this.setState({
        infoData: res.data
      })
    }
  }

  // 查询房间
  findListUserRoom = async () => {
    const { buildingId, floorId } = this.state
    const res = await req_listUserAuthMmRoom({ buildingId: buildingId, floorId: floorId })
    if (res.code === 20000) {
      this.setState({
        modalType: 0,
        roomData: res.data
      })
    }
  }

  // 点击房间
  handleChangeRoom = (val) => {
    const { authRoomIds } = this.state
    let data = authRoomIds
    // 判断是否已点击过
    if (authRoomIds.filter(x => x.device_id === val.device_id).length) {
      data = authRoomIds.filter(x => x.device_id !== val.device_id)
    } else {
      data.push(val)
    }
    this.setState({
      authRoomIds: data
    })
  }
  // 保存
  handleSubmit = async () => {
    const { password, usedName, startTime, endTime, authRoomIds } = this.state;
    if (password === '') {
      Toast.offline("请输入密码！")
      return false;
    }
    if (usedName === '') {
      Toast.offline("请输入使用人姓名！")
      return false;
    }
    if (authRoomIds.length === 0) {
      Toast.offline("请选择最少一个授权房间！")
      return false;
    }
    const params = {
      password,
      usedName,
      startTime: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
      endTime: moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
      authDeviceIds: authRoomIds.map(val => val.device_id).join(',')
    }
    const res = await req_batchAuthRoom(params)
    if (res.code === 20000) {
      Toast.success(res.message)
      setTimeout(() => {
        this.props.history.go(-1)
      }, 2000);
    }
  }

  render() {
    const { password, usedName, startTime, endTime, visible, modalType, buildingId, floorId, roomData, infoData, authRoomIds, buildingName, floorIdName } = this.state
    return (
      <div className='batch_index'>
        <div className='temporary-content'>
          <div className='temporary-content-detail'>
            <InputItem type='number' value={password} onChange={e => this.handleChangePwd(e)} />
            <div onClick={() => this.handleMathRand()}>随机生成</div>
          </div>
        </div>
        <div className='temporary-copy' onClick={() => this.handleCopy()}>复制分享</div>
        <div className='temporary-time'>
          <List>
            <InputItem placeholder="请输入使用人姓名" value={usedName} onChange={e => this.handleChangeName(e)}>使用人姓名</InputItem>
            <DatePicker
              mode="datetime"
              minDate={new Date()}
              value={startTime}
              onChange={date => this.setState({ startTime: date })}
            >
              <List.Item arrow="horizontal">生效时间</List.Item>
            </DatePicker>
            <DatePicker
              mode="datetime"
              minDate={new Date()}
              value={endTime}
              onChange={date => this.setState({ endTime: date })}
            >
              <List.Item arrow="horizontal">失效时间</List.Item>
            </DatePicker>
          </List>
        </div>
        <div className='batch'>
          <div className='batch_title'>授权房间</div>
          <div className='batch_main'>
            {
              authRoomIds.map(val => {
                return (<div key={val.device_id}>{val.device_room_name}-{val.device_door_name}</div>)
              })
            }
          </div>
          <div className='batch_but'>
            <div onClick={() => this.handleRoom()}>移除</div>
            <div onClick={() => this.handleRoom()}>添加</div>
          </div>
        </div>
        <div className='temporary-btn'>
          <div className='temporary-btn-content' onClick={() => this.handleSubmit()}>保 存</div>
        </div>
        <Modal
          visible={visible}
          maskClosable={false}
        >
          <div style={{ width: '100%', height: '90vh' }}>
            <div className='batch_room_head'>
              <div className='batch_room_head_cont'>
                <div className='batch_room_head_width'>
                  <div className='batch_room_head_item' onClick={() => {
                    this.setState({
                      modalType: modalType !== 1 ? 1 : 0,
                    }, () => {
                      this.findListBuildingInfo()
                    })
                  }}>
                    <div>
                      {buildingName ? buildingName : '全部楼宇'}
                    </div>
                    <div>
                      {modalType === 1 ? (<img alt='' src={require('../../../images/上.png')} />) : (<img alt='' src={require('../../../images/下.png')} />)}
                    </div>
                  </div>
                  <div className='batch_room_head_item' onClick={() => {
                    this.setState({
                      modalType: modalType !== 2 ? 2 : 0,
                    }, () => {
                      this.findListBuildingInfo()
                    })
                  }}>
                    <div>
                      {floorIdName ? floorIdName : '全部楼层'}
                    </div>
                    <div>
                      {modalType === 2 ? (<img alt='' src={require('../../../images/上.png')} />) : (<img alt='' src={require('../../../images/下.png')} />)}
                    </div>
                  </div>
                </div>
                {
                  modalType === 1 || modalType === 2 ? (<div className='batch_room_position' onClick={() => {
                    this.setState({
                      modalType: 0
                    })
                  }}>
                    <div className='batch_room_position_width'>
                      {
                        infoData.map(val => {
                          return (<div className={(modalType === 1 && val.id === buildingId) || (modalType === 2 && val.id === floorId) ? 'batch_room_position_click' : ''} key={val.id} onClick={(e) => {
                            e.stopPropagation()
                            if (modalType === 1) {
                              this.setState({
                                buildingId: val.id,
                                floorId: '',
                                buildingName: val.title,
                                floorIdName: ''
                              }, () => {
                                this.findListUserRoom()
                              })
                            } else {
                              this.setState({
                                floorId: val.id,
                                floorIdName: val.title
                              }, () => {
                                this.findListUserRoom()
                              })
                            }

                          }}>{val.title}</div>)
                        })
                      }
                    </div>
                  </div>) : ('')
                }
              </div>
            </div>
            <div className='batch_room_main'>
              {
                roomData.map(val => {
                  return (<div className={authRoomIds.filter(v => v.device_id === val.device_id).length ? 'batch_room_main_item batch_room_click' : 'batch_room_main_item'} key={val.device_id} onClick={() => {
                    this.handleChangeRoom(val)
                  }}>
                    <div>{val.device_room_name}-{val.device_door_name}</div>
                    <div>{val.device_building_name}/{val.device_floor_name}</div>
                  </div>)
                })
              }
            </div>
            <div className='temporary-btn batch_room_but'>
              <div className='temporary-btn-content' onClick={() => {
                this.setState({
                  visible: false
                })
              }}>授权房间
                <span style={{ fontSize: '12px' }}>（已选中{authRoomIds.length}个房间）</span>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Batch;