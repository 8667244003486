import React, { Component } from 'react'
import { Grid } from 'antd-mobile'
import { findModuleInfo } from './../../../api/api'
import { tjAppIndex } from './../../../api/fqw'
import IP from './../../../config/config'
import './index.less'

class Chemicals extends Component {

  state = {
    moduleData: [],
    tjData1: {},
    tjData2: [],
  }

  componentDidMount = () => {
    document.title = '废弃物管理'
    this.fetchModule()
    this.fetchTj()
  }

  // 查询模块
  fetchModule = async () => {
    const res = await findModuleInfo()
    if (res.code === 20000) {
      const data = (res.data || []).filter(x => x.module_type === '10')
      this.setState({
        moduleData: data
      })
    }
  }

  // 查询统计
  fetchTj = async () => {
    const res = await tjAppIndex()
    if (res.code === 20000) {
      this.setState({
        tjData1: res.data.tj,
        tjData2: res.data.lb,
      })
    }
  }

  handleSkipModule = item => {
    this.props.history.push(item.module_url)
  }

  render() {
    const { moduleData, tjData1, tjData2 } = this.state
    return (
      <div className='chemicals waste'>
        <div className='chemicals_title'>废弃物管理</div>
        <div className='chemicals_desc'>助力生态文明建设</div>
        <div className='chemicals_con'>
          <div className='chemicals_con_intro'>
            <div className='chemicals_con_intro_header'>
              <div className='chemicals_intro_header_list'>
                <div className='chemicals_intro_header_list_num'>{tjData1.typeNum || 0}</div>
                <div className='chemicals_intro_header_list_title'>废弃物类别</div>
              </div>
              <div className='chemicals_intro_header_list'>
                <div className='chemicals_intro_header_list_num'>{tjData1.packNum || 0}</div>
                <div className='chemicals_intro_header_list_title'>处理废弃物数量</div>
              </div>
              <div className='chemicals_intro_header_list'>
                <div className='chemicals_intro_header_list_num'>{tjData1.weightNum || 0}kg</div>
                <div className='chemicals_intro_header_list_title'>废弃物总重量</div>
              </div>
            </div>
            <div className='chemicals_con_intro_bottom'>
              {
                (tjData2 || []).map((item, index) => {
                  return <div className='chemicals_con_intro_bottom_list waste_index_list' key={index}>
                    <div className='chemicals_con_intro_bottom_list_title'>{item.zdmc}</div>
                    <div className='chemicals_con_intro_bottom_list_num'>{item.lbsl}</div>
                  </div>
                })
              }
            </div>
          </div>
        </div>
        <div className='chemicals_grid'>
          <Grid
            columnNum={3}
            data={moduleData}
            hasLine={false}
            renderItem={item => (
              <div className='chemicals_con_grid_list' onClick={() => this.handleSkipModule(item)}>
                <img src={IP.fileHost + item.module_imge} alt="" style={{ width: '44px' }} />
                <span>{item.module_name}</span>
              </div>
            )}
          />
        </div>
      </div>
    )
  }
}

export default Chemicals