import React, { Component } from 'react'
import { findAllZnkt, sendCmdZnkt } from '../../../api/api'
import './room_air.less'

class RoomAir extends Component {

  state = {
    data: [],
    roomInfo: {}
  }

  componentDidMount = () => {
    document.title = "空调"
    this.fetch(this.props.match.params.id)
    this.setState({
      roomInfo: this.props.location.state.roomInfo
    })
  }

  fetch = async spaceId => {
    const res = await findAllZnkt({ spaceId })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  // 修改开关
  handleChangeSwitch = item => {
    if (item.device_run_state === 0) {
      this.handleSendCmd(item.device_imei, 'on')
    } else {
      this.handleSendCmd(item.device_imei, 'off')
    }
  }

  handleSendCmd = async (devcieImei, cmdType) => {
    const { data } = this.state
    const res = await sendCmdZnkt({ devcieImei, cmdType })
    if (res.code === 20000) {
      const obj = res.data
      const arr = data.map(x => {
        if (x.device_imei === devcieImei) {
          x.device_run_state = obj.device_run_state
        }
        return x
      })
      this.setState({
        data: arr
      })
    }
  }

  render () {
    const { data, roomInfo } = this.state
    return (
      <div className='roomAir'>
        <div className='shortTerm_name'>{roomInfo.wzName}</div>
        <div className='shortTerm_addr'>{roomInfo.buildingName ? roomInfo.buildingName.split('/')[0] : ''}</div>
        <div className='roomEnvironment_con'>
          {
            (data || []).length ? data.map(item => {
              return (
                <div className='banpai_con_list' key={item.device_id} >
                  <div className='banpai_con_list_left' onClick={() => this.props.history.push(`/module/aircondition/detail/${item.device_id}`)}>
                    <div className='banpai_con_list_left_name'>{item.device_name}</div>
                    <div className='banpai_con_list_left_addr'>
                      {item.device_building_name}/{item.device_floor_name}/{item.device_room_name}
                    </div>
                    <div className='banpai_con_list_left_desc'>
                      <div className={`banpai_con_list_left_desc_status ${item.device_state === 1 ? 'banpai_status_online' : 'banpai_status_offline'}`}>
                        <span></span>
                        <span>{item.device_state === 1 ? '设备在线' : '设备离线'}</span>
                      </div>
                      {
                        item.device_run_state !== 0 ? <div className='banpai_con_list_left_desc_con'>已开启</div> : ''
                      }
                    </div>
                  </div>
                  <div className='banpai_con_img' onClick={() => this.handleChangeSwitch(item)}>
                    {
                      item.device_run_state !== 0 ? <img alt='' src={require('../../../images/kai.png')} /> : <img alt='' src={require('../../../images/guan.png')} />
                    }
                  </div>
                </div>
              )
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
      </div>
    )
  }
}

export default RoomAir
