import React, { Component } from 'react'
import { Slider } from 'antd'
import { selectOneCurtain, sendCmdCurtain } from '../../../api/api'
import "./curtain_detail.less"

class CurtainDetail extends Component {

  state = {
    data: {},
  }

  componentDidMount = () => {
    this.fetch(this.props.match.params.id)
  }

  fetch = async id => {
    const res = await selectOneCurtain({ id })
    if (res.code === 20000) {
      this.setState({
        data: res.data,
      })

    }
  }

  // 执行操作
  handleSendCmd = async (devcieImei, cmdType, cmdParam) => {
    const res = await sendCmdCurtain({ devcieImei, cmdType, cmdParam })
    if (res.code === 20000) {
      this.fetch(this.props.match.params.id)
    }
  }
  // 操作记录
  handleOperate = (e, data) => {
    this.props.history.push(`/module/curtain/curtainOperation/${data.device_imei}`)
  }
  // 刷新状态
  handleSx = () => {
    this.fetch(this.props.match.params.id)
  }
  render () {
    const { data, wt } = this.state
    return (
      <div className='acDetail'>
        <div className={`acDetail_img_bg ${data.device_state === 1 ? 'acDetail_online' : 'acDetail_offline'}`}>
          <div className="acDetail_img">
            <img alt='' src={require('../../../img/controll/curtain-top.png')} />
          </div>
        </div>
        <div className='acDetail_main'>
          <div className='acDetail_con'>
            <div className='acDetail_con_left' style={{ width: `${wt}px` }}>
              <div className='acDetail_con_left_name'>{data.device_name}</div>
              <div className='acDetail_con_left_addr'>
                {data.device_building_name}/{data.device_floor_name}/{data.device_room_name}
              </div>
              <div className='acDetail_con_left_desc'>
                <div className={`acDetail_con_left_status ${data.device_state === 1 ? 'acDetail_status_online' : 'acDetail_status_offline'}`}>
                  <span></span>
                  <span>{data.device_state === 1 ? '设备在线' : '设备离线'}</span>
                </div>
              </div>
            </div>
            {
              data.device_state === 1 ? <div className='acDetail_con_sx' onClick={() => this.handleSendCmd(data.device_imei, 'scxc')}>
                <img alt='' src={require('../../../img/jiaozhun.png')} />
                <span>电机校准</span>
              </div> : <div className='acDetail_con_sx' onClick={() => this.handleSx()}>
                <img alt='' src={require('../../../images/sx.png')} />
                <span>刷新状态</span>
              </div>
            }

          </div>
          <div className='curtainDetail_ck'>
            <div className="curtainDetail_title_flex">
              <div className='curtainDetail_ck_title'>窗帘操控</div>
              <div className="curtainDetail_ck_pos">窗帘位置&nbsp;{data.device_dj_wz === 255 ? 0 : data.device_dj_wz}%</div>
            </div>
            <div className='curtainDetail_ck_slide'>
              <Slider disabled={data.device_dj_wz === 255 ? true : false} value={data.device_dj_wz === 255 ? 0 : data.device_dj_wz} onChange={e => {
                data.device_dj_wz = e
                this.setState({ data })
              }} onAfterChange={e => this.handleSendCmd(data.device_imei, 'open%', e)} />
            </div>
            <div className='curtainDetail_ck_con'>
              <div className='curtainDetail_ck_con_list' onClick={() => this.handleSendCmd(data.device_imei, 'close')}>
                <img alt='' src={require('../../../img/close_curtain.png')} />
                <span>关闭</span>
              </div>
              <div className='curtainDetail_ck_con_list' onClick={() => this.handleSendCmd(data.device_imei, 'stop')}>
                <img alt='' src={require('../../../img/pause.png')} />
                <span>暂停</span>
              </div>
              <div className='curtainDetail_ck_con_list' onClick={() => this.handleSendCmd(data.device_imei, 'open')}>
                <img alt='' src={require('../../../img/open_curtain.png')} />
                <span>开启</span>
              </div>
            </div>
          </div>
          <div className='acDetail_model_wind'>
            <div className='acDetail_wind'>
              <div className='acDetail_model_title'>电机方向</div>
              <div className='acDetail_model_con'>
                <div className={`acDetail_model_con_list ${data.device_dj_fx === 0 ? 'wind_selected' : 'wind_unchecked'}`} onClick={() => data.device_dj_fx === 0 ? {} : this.handleSendCmd(data.device_imei, 'djfx', 0)}>
                  <span>默认</span>
                </div>
                <div className={`acDetail_model_con_list ${data.device_dj_fx === 1 ? 'wind_selected' : 'wind_unchecked'}`} onClick={() => data.device_dj_fx === 1 ? {} : this.handleSendCmd(data.device_imei, 'djfx', 1)}>
                  <span>反转</span>
                </div>
              </div>
            </div>
            <div className='acDetail_model'>
              <div className='acDetail_model_title'>手拉使能</div>
              <div className='acDetail_model_con'>
                <div className={`acDetail_model_con_list ${data.device_dj_sd === 0 ? 'wind_selected' : 'wind_unchecked'}`} onClick={() => data.device_dj_sd === 0 ? {} : this.handleSendCmd(data.device_imei, 'djsd', 0)}>
                  <span>开启</span>
                </div>
                <div className={`acDetail_model_con_list ${data.device_dj_sd === 1 ? 'wind_selected' : 'wind_unchecked'}`} onClick={() => data.device_dj_sd === 1 ? {} : this.handleSendCmd(data.device_imei, 'djsd', 1)}>
                  <span>关闭</span>
                </div>
              </div>
            </div>
          </div>
          <div className='acDetail_operation' onClick={(e) => this.handleOperate(e, data)}>
            <div className='acDetail_operation_left'>
              <img alt='' src={require('../../../img/czjl.png')} />
              <span>操作记录</span>
            </div>
            <img alt='' src={require('../../../images/more2.png')} />
          </div>
        </div>

      </div>
    )
  }
}

export default CurtainDetail
