import React, { Component } from 'react'
import { DatePicker, List, Picker } from 'antd-mobile'
import { findReportSemesterTime, findResourcesCourseInfo, findStudentResourcesInfo } from '../../../api/api'
import moment from 'moment'
import './index.less'

class ResourceStu extends Component {

  state = {
    courseData: [], // 课程
    courseId: [], // 选中的课程
    xqData: [
      { value: '1', label: '秋季学期' },
      { value: '2', label: '春季学期' },
      { value: '3', label: '夏季学期' },
    ], // 学期
    xqId: [], // 选中的学期
    xn: '', // 选中的学年
    data: [],
    wt: 250,
  }

  componentDidMount = () => {
    this.fetchXnXq()
    this.setState({
      wt: document.body.clientWidth - 125
    })
  }

  // 查询最近的学年学期
  fetchXnXq = async () => {
    const res = await findReportSemesterTime()
    if (res.code === 20000) {
      this.fetchCourse(res.data.xn, res.data.xq)
      this.fetchResource(res.data.xn, res.data.xq)
      this.setState({
        xqId: [res.data.xq],
        xn: res.data.xn
      })
    }
  }

  // 查询课程
  fetchCourse = async (xn, xq) => {
    const res = await findResourcesCourseInfo({ xn, xq })
    if (res.code === 20000) {
      this.setState({
        courseData: this.deduplication(res.data || []).map(x => {
          return {
            value: x.record_kcmc,
            label: x.record_kcmc,
          }
        })
      })
    }
  }

  // 查询实验资源
  fetchResource = async (xn, xq) => {
    const { courseId } = this.state
    const res = await findStudentResourcesInfo({ flag: 2, xn, xq, kcdm: courseId[0] })
    if (res.code === 20000) {
      this.setState({
        data: res.data,
      })
    }
  }

  // 数组去重
  deduplication(arr) {
    let list = [];
    return arr.filter((item) => !list.includes(item.record_kcmc) && list.push(item.record_kcmc))
  }

  render() {
    const { courseData, courseId, xqData, xqId, xn, data, wt } = this.state
    return (
      <div className='resource'>
        <div className='resource_header'>
          <div className='resource_header_title'>实验室资源管理</div>
          <div className='resource_desc'>学生可在此下载实验室资源</div>
        </div>
        <div className='testReport_tj_cx'>
          <div className='testReport_search'>
            <div className='testReport_search_list'>
              <DatePicker
                mode="year"
                extra={xn ? xn + '年' : '学年'}
                onChange={date => {
                  this.fetchCourse(moment(date).format('YYYY'), xqId[0])
                  this.fetchResource(moment(date).format('YYYY'), xqId[0])
                  this.setState({ xn: moment(date).format('YYYY') })
                }}
              >
                <List.Item arrow="horizontal"></List.Item>
              </DatePicker>
            </div>
            <div className='testReport_search_list'>
              <Picker
                extra="学期"
                data={xqData}
                cols={1}
                value={xqId}
                onChange={e => {
                  this.fetchCourse(xn, e[0])
                  this.fetchResource(xn, e[0])
                  this.setState({ xqId: e })
                }}
              >
                <List.Item arrow="horizontal"></List.Item>
              </Picker>
            </div>
            <div className='testReport_search_list'>
              <Picker
                extra="课程"
                data={courseData}
                cols={1}
                value={courseId}
                onChange={e => {
                  this.setState({ courseId: e }, () => {
                    this.fetchResource(xn, xqId[0])
                  })
                }}
              >
                <List.Item arrow="horizontal"></List.Item>
              </Picker>
            </div>
          </div>
        </div>
        <div className='resource_con'>
          {
            (data || []).length ? data.map((item, index) => {
              return <div className='resource_con_list' key={index + 1}>
                <div className='resource_con_list_xq' onClick={() => this.props.this.props.history.push(`/module/resources/detail/${item.record_id}`)}>
                  <img alt='' src={require('../../../images/xq.png')} />
                </div>
                <div className='resource_con_list_header'>
                  <img alt='' src={require('../../../images/zybg.png')} />
                  <div className='resource_con_list_header_con' style={{ width: `${wt}px` }}>
                    <div className='resource_con_list_header_con_course'>{item.record_kcmc}</div>
                    <div className='resource_con_list_header_con_xnq'>{item.record_xn} {item.record_xq === '1' ? '秋季' : item.record_xq === '2' ? '春季' : '夏季'}学期</div>
                  </div>
                </div>
                <div className='testReport_con_list_con'>
                  <div className='testReport_con_list_con_list'>
                    <div className='testReport_con_list_con_list_title'>任课老师</div>
                    <div className='testReport_con_list_con_list_detail'>{item.jzg_xm}</div>
                  </div>
                  <div className='testReport_con_list_con_list'>
                    <div className='testReport_con_list_con_list_title'>工号</div>
                    <div className='testReport_con_list_con_list_detail'>{item.jzg_gh}</div>
                  </div>
                  <div className='testReport_con_list_con_list'>
                    <div className='testReport_con_list_con_list_title'>提交时间</div>
                    <div className='testReport_con_list_con_list_detail'>{item.create_time ? moment(item.create_time).format('YYYY-MM-DD') : '--'}</div>
                  </div>
                </div>
                <div className='testReport_con_list_footer'>
                  <div className='testReport_con_list_footer_btn testReport_btn_down' onClick={() => this.props.this.props.history.push(`/module/resources/detail/${item.record_id}`)}>下载</div>
                </div>
              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
      </div>
    )
  }
}

export default ResourceStu