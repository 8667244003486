import React, { Component } from 'react'
import { SearchBar } from 'antd-mobile'
import { Cascader, Field } from 'react-vant'
import { findSpaceInfo, req_listRelayRoom, issueAllKgZnkzq } from '../../../api/api'
import './controller.less'

class Controller extends Component {

  state = {
    searchContent: '', // 搜索值
    controllerData: [], // 终端设备
    roomData: [], // 楼层下的房间
    buildId: undefined, // 楼宇id
    floorId: undefined, // 楼层id
    roomId: undefined, // 房间id
  }

  componentDidMount = () => {
    document.title = "智能终端"
    this.fetch()
    this.fetchAllRoom()
  }

  fetch = async () => {
    const { searchContent, buildId, floorId, roomId } = this.state
    const res = await req_listRelayRoom({ spaceId: roomId ? roomId : floorId ? floorId : buildId ? buildId : '', roomName: searchContent })
    if (res.code === 20000) {
      this.setState({
        controllerData: res.data
      })
    }
  }
  //查询所有房间
  fetchAllRoom = async () => {
    const res = await findSpaceInfo()
    if (res.code === 20000) {
      let menuList = res.data.map(item => {
        return {
          text: item.dwmc,
          parentId: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
        }
      })
      menuList = menuList.map(item => {
        if (menuList.filter(p => p.parentId === item.value).length) {
          item.children = menuList.filter(p => p.parentId === item.value)
        }
        return item
      }).filter(p => p.parentId === '0')
      this.setState({
        roomData: menuList
      })
    }
  }

  // 搜索房间
  handleChange = e => {
    this.setState({
      searchContent: e
    }, () => {
      this.fetch()
    })
  }

  handleCancel = () => {
    this.setState({
      searchContent: ''
    }, () => {
      this.fetch()
    })
  }

  // 一键开关
  handleControllerSwitch = async (e, item, relayState) => {
    e.stopPropagation()
    const res = await issueAllKgZnkzq({ relayId: item.relay_id, mac: item.relay_mac, relayAddress: item.relay_address, relayState })
    if (res.code === 20000) {
      this.fetch()
    }
  }
  // 选中项变化时触发
  handleCascaderChange = (val, selectedRows, actions) => {
    // console.log(val, selectedRows, actions, 'xxxxxx')
  }

  // 全部选项选择完成
  handleCascaderFinish = (val, selectedRows, actions) => {
    this.setState({
      roomId: val[val.length - 1],
    }, () => {
      this.fetch()
    })
  }
  render () {
    const { roomData, controllerData, buildData, floorData, buildId, floorId, roomId } = this.state
    return (
      <div className='banpai'>
        <div className='banpai_img'>
          <img src={require('./../../../img/controll/1.2.png')} alt='' />
          <div className='banpai_search'>
            <SearchBar placeholder="搜索房间" onCancel={() => this.handleCancel()} onChange={e => this.handleChange(e)} />
          </div>
        </div>
        <div className="banpai_title">
          <div>房间列表</div>
          <div className='banpai_cascader'>
            <Cascader
              popup={{ round: true }}
              title='选择房间'
              activeColor="#00B4C4"
              options={roomData}
              onFinish={this.handleCascaderFinish}
            >
              {(_, selectedRows, actions) => (
                <Field
                  isLink
                  className='controller_cascader'
                  value={selectedRows.length > 0 ? selectedRows[selectedRows.length - 1].text : ''}
                  readOnly
                  placeholder='全部房间'
                  onClick={() => actions.open()}
                />
              )}
            </Cascader>
          </div>
        </div>
        <div className='banpai_con'>
          {
            (controllerData || []).length ? controllerData.map(item => {
              return <div className='electricity_con_list' key={item.room_id} onClick={() => {
                this.props.history.push(`/module/controllerSwitch/${item.room_id}`)
              }}>
                <div className='electricity_con_list_left'>
                  <img src={require('../../../images/room_icon.png')} alt="" />
                  <div>
                    <span className='electricity_con_list_left_roomName'>{item.room_name}</span>
                    <span className='electricity_con_list_left_pos'>
                      {item.building_name}/{item.floor_name}{item.room_number ? '/' + item.room_number : ''}
                    </span>
                  </div>
                </div>
                <div className='electricity_con_list_right'>
                  已开启
                  <span className='electricity_con_list_right_online'>{item.devNum1}</span>
                  /
                  <span>{item.devNum2}</span>
                </div>
              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
      </div>
    )
  }
}

export default Controller
