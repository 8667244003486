import React, { Component } from 'react'
import { Pagination, Icon, } from 'antd-mobile'
import { findSecondModuleInfo } from '../../../../api/api'
import { listMyApprovalRecord } from '../../../../api/whp'
import moment from 'moment'
import './list.less'

class Approve extends Component {

  state = {
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    total: 0,
    configData: [],
    statusData: [
      { id: 1, name: '待审批', checked: true },
      { id: 2, name: '已审批', checked: false },
    ],
    status: 20, //审批类型 20开始
    type: 1, // 审批状态 （1待审批  2已审核）
    data: [],
  }

  componentDidMount = () => {
    document.title = '审批'
    window.scrollTo(0, 0)
    this.fetchModuleNum()
  }

  // 查询mobule_url数字模块
  fetchModuleNum = async () => {
    const res = await findSecondModuleInfo()
    if (res.code === 20000) {
      const arr = res.data.filter(x => x.module_type === '7')
      const newArr = arr.map((x, i) => {
        return {
          id: Number(x.module_url),
          name: x.module_name,
          checked: i ? false : true
        }
      })
      this.setState({
        configData: newArr,
        status: newArr[0].id,
      }, () => {
        if (this.props.location.state) {
          const record = this.props.location.state
          if (record.status === 20) {
            this.subscriptionFetch()
            this.setState({
              status: 20
            })
          } else if (record.status === 21) {
            this.warehousingFetch()
            this.setState({
              status: 21
            })
          } else if (record.status === 22) {
            this.useFetch()
            this.setState({
              status: 22
            })
          } else if (record.status === 23) {
            this.repayFetch()
            this.setState({
              status: 23
            })
          }
        } else {
          if (newArr[0].id === 20) {
            this.subscriptionFetch()
          } else if (newArr[0].id === 21) {
            this.warehousingFetch()
          } else if (newArr[0].id === 22) {
            this.useFetch()
          } else if (newArr[0].id === 23) {
            this.repayFetch()
          }
        }
      })
    }
  }

  // 申购审批
  subscriptionFetch = async () => {
    const { pageSize, pageNum, type } = this.state
    const res = await listMyApprovalRecord({ size: pageSize, page: pageNum, result: type, flag: 1 })
    if (res.code === 20000) {
      this.setState({
        data: res.data.list || [],
        pages: res.data ? (res.data.pages ? res.data.pages : 1) : 1,
        total: res.data.total,
      })
    }
  }

  // 入库审批
  warehousingFetch = async () => {
    const { pageSize, pageNum, type } = this.state
    const res = await listMyApprovalRecord({ size: pageSize, page: pageNum, result: type, flag: 2 })
    if (res.code === 20000) {
      this.setState({
        data: res.data.list || [],
        pages: res.data ? (res.data.pages ? res.data.pages : 1) : 1,
        total: res.data.total,
      })
    }
  }

  // 领用审批
  useFetch = async () => {
    const { pageSize, pageNum, type } = this.state
    const res = await listMyApprovalRecord({ size: pageSize, page: pageNum, result: type, flag: 3 })
    if (res.code === 20000) {
      console.log(res.data.list)
      this.setState({
        data: res.data.list || [],
        pages: res.data ? (res.data.pages ? res.data.pages : 1) : 1,
        total: res.data.total,
      })
    }
  }

  // 归还审批
  repayFetch = async () => {
    const { pageSize, pageNum, type } = this.state
    const res = await listMyApprovalRecord({ size: pageSize, page: pageNum, result: type, flag: 4 })
    if (res.code === 20000) {
      console.log(res.data.list)
      this.setState({
        data: res.data.list || [],
        pages: res.data ? (res.data.pages ? res.data.pages : 1) : 1,
        total: res.data.total,
      })
    }
  }


  render() {
    const { statusData, data, configData, status, type } = this.state
    return (
      <div className='approve'>
        <div className='approve_header'>
          <div className='approve-select' style={{ height: '32px' }}>
            <div className='approve-select_width' style={{ height: '40px' }}>
              <div className='approve-select-con' style={{ width: 'max-content' }}>
                {
                  configData.map(item => {
                    return <div className={`approve-select-con-list ${status === item.id ? 'approve-select-con-list-active' : ''}`} key={item.id} onClick={() => {
                      this.setState({
                        status: item.id,
                        type: 1,
                      }, () => {
                        if (item.id === 20) {
                          this.subscriptionFetch()
                        } else if (item.id === 21) {
                          this.warehousingFetch()
                        } else if (item.id === 22) {
                          this.useFetch()
                        } else if (item.id === 23) {
                          this.repayFetch()
                        }
                      })
                    }}>
                      <span className='approve-select-con-list-name'>{item.name}</span>
                      {
                        status === item.id ? <span className='approve-select-con-list-line-active'></span> : ''
                      }
                    </div>
                  })
                }
              </div>
            </div>
          </div>

          {/* 待审核，审核成功，审核失败 */}
          <div className='approve_header_config approve_manager_tabs'>
            <div className='approve_header_con'>
              {
                statusData.map(item => {
                  return <div className={`approve_header_con_list ${type === item.id ? 'approve_header_con_list_active' : ''}`} key={item.id} onClick={() => {
                    this.setState({
                      type: item.id
                    }, () => {
                      if (status === 20) {
                        this.subscriptionFetch()
                      } else if (status === 21) {
                        this.warehousingFetch()
                      } else if (status === 22) {
                        this.useFetch()
                      } else if (status === 23) {
                        this.repayFetch()
                      }
                    })
                  }}>{item.name}</div>
                })
              }

            </div>
          </div>

        </div>

        <div className='approve_con'>

          {/* 申购审批 */}
          {status === 20 ? (<div className='approve_manager_top'>

            {data.map((item, key) => {
              return (
                <div className='subscription_list_item' key={key} onClick={() => {
                  this.props.history.push(`/module/approveManager/subRecord/${item.pr_id}`)
                }}>
                  <div className='subscription_list_item_head'>
                    <div>申购单号：{item.sgdh}</div>
                    {item.sgzt === 0 ? (<div className='wait'>草稿</div>) : ''}
                    {item.sgzt === 1 ? (<div className='wait'>待审批</div>) : ''}
                    {item.sgzt === 2 ? (<div className='wait'>待审批</div>) : ''}
                    {item.sgzt === 3 ? (<div className='pass_through'>待确认</div>) : ''}
                    {item.sgzt === 4 ? (<div className='pass_through'>待收货</div>) : ''}
                    {item.sgzt === 5 ? (<div className='pass_through'>待入库</div>) : ''}
                    {item.sgzt === 6 ? (<div className='pass_through'>入库中</div>) : ''}
                    {item.sgzt === 7 ? (<div className='pass_through'>已入库</div>) : ''}
                    {item.sgzt === 8 ? (<div className='reject'>审批驳回</div>) : ''}
                  </div>
                  <div className='subscription_list_title'>
                    {item.xymc}-{item.zxmc}-{item.jsmc}
                  </div>
                  <div className='subscription_list_tag'>
                    <div>{item.sgrxm}</div>
                    <div>{item.sgfx}</div>
                    <div>危化品种类：{item.sgzls}</div>
                    <div>总金额：{item.sgje}</div>
                  </div>
                  <div style={{ padding: '8px 12px 14px' }}>
                    {item.sgsj ? moment(item.sgsj).format('YYYY-MM-DD HH:mm:ss') : ''}
                  </div>
                </div>
              )
            })}

            {
              this.state.total > 20 ? (<div className='subscription_manager_page'>
                <Pagination total={this.state.pages}
                  current={this.state.pageNum}
                  onChange={e => {
                    this.setState({
                      pageNum: e
                    }, () => {
                      window.scrollTo(0, 0)
                      this.subscriptionFetch()
                    })
                  }}
                  locale={{
                    prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                    nextText: (<span className="arrow-align"><Icon type="right" /></span>),
                  }}
                />
              </div>) : ''
            }




          </div>) : ''}


          {/* 入库审批 */}
          {status === 21 ? (<div className='approve_manager_top'>

            {data.map((item, key) => {
              return (
                <div className='subscription_list_item' key={key} onClick={() => {
                  this.props.history.push(`/module/approveManager/warRecord/${item.pr_id}`)
                }}>
                  <div className='subscription_list_item_head'>
                    <div>入库单号：{item.rkdh}</div>
                    {item.sgzt === 0 ? (<div className='wait'>草稿</div>) : ''}
                    {item.sgzt === 1 ? (<div className='wait'>待审批</div>) : ''}
                    {item.sgzt === 2 ? (<div className='wait'>待审批</div>) : ''}
                    {item.sgzt === 3 ? (<div className='pass_through'>待确认</div>) : ''}
                    {item.sgzt === 4 ? (<div className='pass_through'>待收货</div>) : ''}
                    {item.sgzt === 5 ? (<div className='pass_through'>待入库</div>) : ''}
                    {item.sgzt === 6 ? (<div className='pass_through'>入库中</div>) : ''}
                    {item.sgzt === 7 ? (<div className='pass_through'>已入库</div>) : ''}
                    {item.sgzt === 8 ? (<div className='reject'>审批驳回</div>) : ''}
                  </div>
                  <div className='subscription_list_title'>
                    {item.xymc}-{item.zxmc}-{item.jsmc}
                  </div>
                  <div className='subscription_list_tag'>
                    <div>{item.rkrxm}</div>
                    <div>{item.ckmc}</div>
                    <div>危化品{item.rkzls}种</div>
                  </div>
                  <div className='subscription_list_foot'>
                    <div>
                      {item.rksj ? moment(item.rksj).format('YYYY-MM-DD HH:mm:ss') : ''}
                    </div>
                    <div>总金额{item.rkje}</div>
                  </div>
                </div>
              )
            })}

            {
              this.state.total > 20 ? (<div className='subscription_manager_page'>
                <Pagination total={this.state.pages}
                  current={this.state.pageNum}
                  onChange={e => {
                    this.setState({
                      pageNum: e
                    }, () => {
                      window.scrollTo(0, 0)
                      this.warehousingFetch()
                    })
                  }}
                  locale={{
                    prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                    nextText: (<span className="arrow-align"><Icon type="right" /></span>),
                  }}
                />
              </div>) : ''
            }
          </div>) : ''}

          {/* 领用审批 */}
          {status === 22 ? (<div className='approve_manager_top'>

            {data.map((item, key) => {
              return (
                <div className='subscription_list_item' key={key} onClick={() => {
                  this.props.history.push(`/module/approveManager/useRecord/${item.pr_id}`)
                }}>
                  <div className='subscription_list_item_head'>
                    <div>领用单号：{item.lybh}</div>
                    {item.sgzt === 0 ? (<div className='wait'>草稿</div>) : ''}
                    {item.sgzt === 1 ? (<div className='wait'>待审批</div>) : ''}
                    {item.sgzt === 2 ? (<div className='wait'>待审批</div>) : ''}
                    {item.sgzt === 3 ? (<div className='pass_through'>待确认</div>) : ''}
                    {item.sgzt === 4 ? (<div className='pass_through'>待收货</div>) : ''}
                    {item.sgzt === 5 ? (<div className='pass_through'>待入库</div>) : ''}
                    {item.sgzt === 6 ? (<div className='pass_through'>入库中</div>) : ''}
                    {item.sgzt === 7 ? (<div className='pass_through'>已入库</div>) : ''}
                    {item.sgzt === 8 ? (<div className='reject'>审批驳回</div>) : ''}
                  </div>
                  <div className='subscription_list_title'>
                    {item.lyrmc1}、{item.lyrmc2} - {item.lyckmc}
                  </div>
                  <div className='subscription_list_tag'>
                    <div>危化品{item.whpzlsl}种</div>
                    {/* <div>领用{ }个</div>
                  <div className='subscription_list_tag_wait'>待使用{ }个</div> */}
                  </div>
                  <div className='subscription_list_foot'>
                    <div>
                      {item.lysqsj ? moment(item.lysqsj).format('YYYY-MM-DD HH:mm:ss') : ''}
                    </div>
                    <div></div>
                  </div>
                </div>
              )
            })}

            {
              this.state.total > 20 ? (<div className='subscription_manager_page'>
                <Pagination total={this.state.pages}
                  current={this.state.pageNum}
                  onChange={e => {
                    this.setState({
                      pageNum: e
                    }, () => {
                      window.scrollTo(0, 0)
                      this.useFetch()
                    })
                  }}
                  locale={{
                    prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                    nextText: (<span className="arrow-align"><Icon type="right" /></span>),
                  }}
                />
              </div>) : ''
            }




          </div>) : ''}

          {/* 归还审批 */}
          {status === 23 ? (<div className='approve_manager_top'>

            {data.map((item, key) => {
              return (
                <div className='subscription_list_item' key={key} onClick={() => {
                  this.props.history.push(`/module/approveManager/repayRecord/${item.pr_id}`)
                }}>
                  <div className='subscription_list_item_head'>
                    <div>归还单号：{item.ghbh}</div>
                    {item.ghzt === 0 ? (<div className='wait'>草稿</div>) : ''}
                    {item.ghzt === 1 ? (<div className='wait'>待审批</div>) : ''}
                    {item.ghzt === 2 ? (<div className='wait'>审批中</div>) : ''}
                    {item.ghzt === 3 ? (<div className='pass_through'>审批通过</div>) : ''}
                    {item.ghzt === 4 ? (<div className='reject'>审批未通过</div>) : ''}
                    {item.ghzt === 5 ? (<div className='reject'>审批驳回</div>) : ''}
                  </div>
                  <div className='subscription_list_title'>
                    {item.xymc}-{item.zxmc}-{item.jsmc}
                  </div>
                  <div className='subscription_list_tag'>
                    <div>{item.sqrmc}</div>
                    <div>危化品{item.whpzlsl}种</div>
                  </div>
                  <div className='subscription_list_foot'>
                    <div>
                      {item.sqsj ? moment(item.sqsj).format('YYYY-MM-DD HH:mm:ss') : ''}
                    </div>
                    <div></div>
                  </div>
                </div>
              )
            })}

            {
              this.state.total > 20 ? (<div className='subscription_manager_page'>
                <Pagination total={this.state.pages}
                  current={this.state.pageNum}
                  onChange={e => {
                    this.setState({
                      pageNum: e
                    }, () => {
                      window.scrollTo(0, 0)
                      this.repayFetch()
                    })
                  }}
                  locale={{
                    prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                    nextText: (<span className="arrow-align"><Icon type="right" /></span>),
                  }}
                />
              </div>) : ''
            }




          </div>) : ''}




          {/* 暂无数据 */}
          {data.length === 0 ? (<div className='not-yet'>--暂无数据--</div>) : ('')}
        </div>
      </div>
    )
  }
}

export default Approve