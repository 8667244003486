import React, { Component } from "react";
import {
  List,
  ImagePicker,
  WhiteSpace,
  TextareaItem,
  Button,
  Steps,
  Toast,
  Modal,
  InputItem,
} from "antd-mobile";
import { createForm } from "rc-form";
import moment from "moment";
import cookie from "react-cookies";
import "./../commen.less";
import "./the.less";
import {
  saveApprovalRecord,
  findOrderByOrderId,
  updateOrderMoney,
  jujieOrder,
  uploadRepairOrderImgae
} from "../../../../api/repairApi";
import IP from "../../../../config/config";
const Item = List.Item;
const Step = Steps.Step;

class Details extends Component {
  state = {
    data: {},
    price: "",
    id: "",
    description: "",
    money: "",
    visable2: false,
    visible: false,
    imgUrl: '',
    repairInfo: {}, // 报修详情
    processInfo: [], // 工单流程
    demoFiles: [],
    urls: []
  };

  componentDidMount() {
    window.document.title = "维修工单";
    this.setState({
      id: this.props.match.params.id,
    });
    findOrderByOrderId({ orderId: this.props.match.params.id }).then((res) => {
      if (res.code === 20000) {
        this.setState({
          data: res.data ? res.data : {},
          repairInfo: res.data ? (res.data.repairOrderEntity || {}) : {},
          processInfo: res.data ? (res.data.approvalRecordEntities || []) : [],
        });
      }
    });
  }
  // 打开拒接框
  subRefuseOrderModal = (index) => {
    this.setState({
      visable2: true,
    });
  };


  // 图片上传
  upload = (files, type, index) => {
    const { urls, demoFiles } = this.state
    if (type === "add") {
      Toast.loading("图片上传中,请稍等", 0)
      const imgSize = files[files.length - 1].file.size;
      if (imgSize > 10 * 1024 * 1024) return Toast.info('请上传小于10M的图片', 2);
      let formData = new FormData();
      formData.append("multipartFile", files[files.length - 1].file);
      formData.append("flag", 2);
      uploadRepairOrderImgae(formData).then(res => {
        this.setState({
          demoFiles: files,
        });
        if (res.code === 20000) {
          Toast.hide()
          Toast.info("上传成功", 1)
          this.setState({
            urls: urls.concat(res.data)
          })
        } else {
          Toast.hide()
          Toast.fail(res.message)
        }
      })
    }
    if (type === "remove") {
      this.setState({
        urls: urls.filter((x, indexs) => indexs !== index),
        demoFiles: demoFiles.filter((x, indexs) => indexs !== index),
      })
    }
  }


  // 提交
  handleSubmit = () => {
    const { description, money, urls } = this.state;
    if (this.state.money != "") {
      if (isNaN(this.state.money)) {
        Toast.fail("输入费用格式错误,重新输入!", 2);
        return;
      }
      if (!description) {
        Toast.fail("请填写维修内容!", 2);
        return;
      }
      if (urls.length === 0) {
        Toast.fail("请上传图片!", 2);
        return;
      }
      Toast.loading("上传中！", 0);
      updateOrderMoney({
        orderId: this.state.id,
        money: money,
        workDesc: description,
        repair_pic_url: urls.join(','),
      }).then((res) => {
        if (res.code === 20000) {
          Toast.hide();
          Toast.success(res.message, "2");
          this.props.history.goBack();
        } else {
          Toast.fail(res.message, "2");
        }
      });
    } else {
      Toast.offline("请输入您的维修费用！！！");
    }
  };

  saveApprovalRecord = (index) => {
    const { id, reason, data } = this.state;
    var data1 = {
      order_id: id,
      approval_state: index,
      approval_number: cookie.load("xinbanduomeitiData").user_id,
      approval_id: cookie.load("xinbanduomeitiData").user_id,
      approval_name: cookie.load("xinbanduomeitiData").user_name,
      order_user_id: data.user_id ? data.user_id : "",
      order_department_id: data.department_id ? data.department_id : "",
    };
    if (index === 0) {
      data1.refuse_reason = reason;
    }
    saveApprovalRecord(data1).then((res) => {
      if (res.code === 20000) {
        Toast.success(res.message, 2);
        this.props.history.goBack();
      }
    });
  };
  //拒接工单
  jujieOrder = async () => {
    const { id } = this.state;
    const res = await jujieOrder({ orderId: id });
    if (res.code === 20000) {
      if (res.flag === true) {
        Toast.success(res.message, "2");
      } else {
        Toast.fail(res.message, "2");
      }

      this.props.history.goBack();
    }
  };

  handleImageClick = (index, files) => {
    this.setState({
      visible: true,
      imgUrl: files.find(x => x.id === index).url
    })
  }
  render() {
    const { data, visable2, visible, imgUrl, repairInfo, processInfo, demoFiles } = this.state;
    return (
      <div className="details details3">
        <List className="my-list">
          <Item
            extra={data.repairOrderEntity && data.repairOrderEntity.user_name}
          >
            用户姓名
          </Item>
        </List>
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <List className="my-list">
          <Item
            extra={
              data.repairOrderEntity && data.repairOrderEntity.user_phone_number
            }
          >
            联系方式
          </Item>
        </List>
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <List className="my-list">
          <Item
            extra={
              (data.repairOrderEntity && data.repairOrderEntity.building_name
                ? data.repairOrderEntity.building_name
                : "") +
              " " +
              (data.repairOrderEntity && data.repairOrderEntity.room_name
                ? data.repairOrderEntity.room_name
                : "")
            }
          >
            维修地点
          </Item>
        </List>
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <List className="my-list">
          <Item
            extra={
              data.repairOrderEntity && data.repairOrderEntity.fault_type_name
            }
          >
            故障类型
          </Item>
        </List>
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <List className="my-list">
          <Item
            extra={
              data.repairOrderEntity && data.repairOrderEntity.equipment_name
                ? data.repairOrderEntity.equipment_name
                : ""
            }
          >
            设备名称
          </Item>
        </List>
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <List className="my-list">
          <Item
            extra={
              data.repairOrderEntity && data.repairOrderEntity.equipment_number
                ? data.repairOrderEntity.equipment_number
                : ""
            }
          >
            设备编号
          </Item>
        </List>
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <List className="my-list">
          <Item
            extra={
              data.repairOrderEntity && data.repairOrderEntity.money !== 0
                ? data.repairOrderEntity.money
                : ""
            }
          >
            维修费用
          </Item>
        </List>
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <List className="my-list">
          <Item extra={data.repairOrderEntity && data.repairOrderEntity.work_desc !== null ? data.repairOrderEntity.work_desc : ''}>维修内容</Item>
        </List>
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <div className="textarea">
          <div className="textarea_title">问题描述</div>
          <div>
            <TextareaItem
              value={
                data.repairOrderEntity && data.repairOrderEntity.description
                  ? data.repairOrderEntity.description
                  : ""
              }
              autoHeight
              labelNumber={5}
              disabled
            />
          </div>
          <div>
            <ImagePicker
              className="details-upload"
              disableDelete={true}
              selectable={false}
              onImageClick={this.handleImageClick}
              files={
                data.repairOrderEntity && data.repairOrderEntity.pic_url
                  ? data.repairOrderEntity &&
                  data.repairOrderEntity.pic_url
                    .split(",")
                    .map((item, index) => {
                      return { url: IP.fileHost + item, id: index };
                    })
                  : []
              }
            />
          </div>
        </div>
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <div className="textarea">
          <div className="textarea_title">
            维修后图片
          </div>
          <ImagePicker
            className="details-upload"
            disableDelete={true}
            selectable={false}
            onImageClick={this.handleImageClick}
            files={data.repairOrderEntity && data.repairOrderEntity.repair_pic_url ? (data.repairOrderEntity && data.repairOrderEntity.repair_pic_url.split(',').map((item, index) => { return { url: IP.fileHost + item, id: index } })) : []}
          />
        </div>
        <WhiteSpace size="sm" /><WhiteSpace size="sm" />
        <div className='repair_approval_detail_desc'>
          <div className='repair_approval_detail_desc_title'>工单流程</div>
          <div className='repair_approval_process'>
            {
              (processInfo || []).length ? <Steps size="small" current={processInfo.length}>
                {
                  processInfo.map((item, index) => <Step
                    key={index}
                    icon={
                      item.state === 2 ? <img alt='' src={require('../../../../images/new/25.png')} /> : <img alt='' src={require('../../../../images/new/24.png')} />
                    }
                    title={
                      <div className='step_title'>
                        <span>审批人：{item.approval_name}</span>
                        {
                          item.approval_state === 1 ? <span className='step_title_tg'>通过</span> : (
                            item.approval_state === 0 ? <span className='step_title_jj'>拒绝</span> : ''
                          )
                        }
                      </div>
                    }
                    description={
                      <div className='step_desc'>
                        <div className='step_desc_time'>
                          {
                            item.approval_time ? (`审批时间：${moment(item.approval_time).format('YYYY-MM-DD HH:mm:ss')}`) : ''
                          }
                        </div>
                        {/* {
                          item.refuse_reason ? <div className='step_desc_bh'>{`审批意见：${item.refuse_reason}`}</div> : ''
                        } */}
                        {
                          item.sign_url ? <div className='step_desc_qm'>
                            <div>审批签名：</div>
                            <img alt='' src={IP.fileHost + item.sign_url} />
                          </div> : ''
                        }
                      </div>
                    } />)
                }
              </Steps> : <div style={{ textAlign: 'center' }}>--暂无审批--</div>
            }
            <Steps size="small" current={3}>
              {
                repairInfo.order_state >= 1 && repairInfo.order_state !== 2 ? <Step
                  icon={
                    repairInfo.work_name ? <img alt='' src={require('../../../../images/new/24.png')} /> : <img alt='' src={require('../../../../images/new/25.png')} />
                  }
                  title={
                    <div className='step_title'>
                      <span>维修人：{repairInfo.work_name ? repairInfo.work_name : '待指派'}</span>
                    </div>
                  }
                  description={
                    <div className='step_desc'>
                      <div className='step_desc_time'>
                        {
                          repairInfo.work_name && repairInfo.appoint_time ? (`指派时间：${moment(repairInfo.appoint_time).format('YYYY-MM-DD HH:mm:ss')}`) : ''
                        }
                      </div>
                    </div>
                  }
                /> : ''
              }
              {
                repairInfo.order_state >= 1 && repairInfo.order_state !== 2 && repairInfo.work_name ? <Step
                  icon={
                    repairInfo.order_state >= 3 ? <img alt='' src={require('../../../../images/new/24.png')} /> : <img alt='' src={require('../../../../images/new/25.png')} />
                  }
                  title={
                    <div className='step_title'>
                      <span>维修状态：{repairInfo.order_state >= 3 ? '已维修' : '待维修'}</span>
                    </div>
                  }
                  description={
                    <div className='step_desc'>
                      <div className='step_desc_time'>
                        {
                          repairInfo.order_state >= 3 && repairInfo.end_time ? (`维修时间：${moment(repairInfo.end_time).format('YYYY-MM-DD HH:mm:ss')}`) : ''
                        }
                      </div>
                    </div>
                  }
                /> : ''
              }
              {
                repairInfo.order_state >= 3 ? <Step
                  icon={
                    repairInfo.order_state === 4 ? <img alt='' src={require('../../../../images/new/24.png')} /> : <img alt='' src={require('../../../../images/new/25.png')} />
                  }
                  title={
                    <div className='step_title'>
                      <span>工单状态：{repairInfo.order_state === 4 ? '已完成' : '待确认'}</span>
                    </div>
                  }
                  description={<div></div>}
                /> : ''
              }
            </Steps>
          </div>
        </div>
        <WhiteSpace size="lg" />
        <div className="details_sub">
          {data.repairOrderEntity && data.repairOrderEntity.order_state === 1 ? (
            <Button type="primary" onClick={() => this.subRefuseOrderModal()}>
              填写维修费用
            </Button>
          ) : (
            <Button type="primary" onClick={() => this.props.history.goBack()}>
              返回
            </Button>
          )}
          <WhiteSpace size="lg" />
          {/* 拒接工单 */}
          {data.repairOrderEntity && data.repairOrderEntity.order_state === 1 ? (
            <div className="jujie" onClick={this.jujieOrder}>
              拒接
            </div>
          ) : (
            ""
          )}
        </div>
        <WhiteSpace size="lg" />
        <Modal
          visible={visable2}
          popup
          animationType="slide-up"
          onClose={() =>
            this.setState({ visable2: false, money: "", description: "" })
          }
          title={<div style={{ textAlign: "center", paddingTop: '12px' }}>填写维修信息</div>}
        >
          <div className="inputModal theDetails3_modal">
            <WhiteSpace size="sm" /><WhiteSpace size="sm" />
            <InputItem
              className="inputmoney"
              placeholder="请输入维修金额"
              style={{ paddingLeft: "0", textAlign: "right", paddingRight: 8 }}
              type="number"
              onChange={(e) => {
                this.setState({
                  money: e,
                });
              }}
            >
              <span style={{ display: "inline-block", width: "105px" }}>
                <span className="must_Filin">* </span>维修金额(元)
              </span>
            </InputItem>
            <div className="textarea">
              <div
                style={{
                  textAlign: "left",
                  paddingLeft: "15px",
                  fontSize: "17px",
                  color: "#000000",
                }}
              >
                <span className="must_Filin">* </span>维修内容
              </div>
              <div style={{ paddingRight: "14px" }}>
                <TextareaItem
                  autoHeight
                  placeholder="请输入维修内容"
                  labelNumber={5}
                  style={{
                    width: "100%",
                    border: "1px solid #dcd6d6",
                    borderRadius: "5px",
                    minHeight: "130px",
                    padding: "3px",
                  }}
                  onChange={(e) =>
                    this.setState({
                      description: e,
                    })
                  }
                />
              </div>
            </div>
            <div className="theDetails3_upload">
              <div>
                <span className="must_Filin">* </span>维修后图片
              </div>
              <ImagePicker
                className='repair_image'
                title="图片上传"
                multiple={false}
                selectable={demoFiles.length < 8}
                files={demoFiles}
                onChange={(files, type, index) => {
                  this.upload(files, type, index)
                }}
              />
            </div>
            <div
              className="theDetails_sub"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "30px",
                padding: "0 14px 20px",
              }}
            >
              <Button
                type="primary"
                onClick={() => this.handleSubmit()}
                style={{
                  width: "100%",
                  height: "40px",
                  lineHeight: "40px",
                  padding: "0 10px",
                  fontSize: "14px",
                }}
              >
                提交
              </Button>
            </div>
          </div>
        </Modal>
        {
          visible ? <div className='detailImg' onClick={() => this.setState({ visible: false })}>
            <img alt='' src={imgUrl} />
          </div> : ''
        }
      </div>
    );
  }
}

export default createForm()(Details);
