import React, { Component } from 'react'
import { Flex, ImagePicker, Modal, Toast, TextareaItem, Button, Icon } from 'antd-mobile'
// import { createForm } from 'rc-form'
import { Radio, Select, Input } from 'antd'
import { req_submitCheckResult, req_listPollingType } from '../../../api/api'
import { findRoomPatrolDetail, uploadPatrolImages } from '../../../api/patrol'
import { uploadDisposeFile } from '../../../api/repairApi'
import cookie from 'react-cookies'
import moment from 'moment'
import IP from './../../../config/config'
import './create_patrol.less'
const { Option } = Select;

let key = 0
class CreatePatrol extends Component {
  state = {
    project: [],
    patrolType: 0,
    // checkDesc: '',
    patrolDesc: '',
    width: window.innerWidth,
    height: window.innerHeight,
    visible: false,
    base64: '',
    detailInfo: {},
    urls: [],
    patrolImgs: [],
    patrolVideo: [],
    addVisible: false,
    dangerType: '',
    dangerEntityList: [],// 隐患信息
    pollingKey: '',//默认隐患点
    pollingTypeList: [],//巡更点信息
    parentData1: [],
    parentData2: [],
    parentData3: [],
    parentData4: [],
  }
  componentDidMount = () => {
    this.fetchTaskDetail()
  }

  //进入页面获取该房间的巡检配置情况
  fetchTaskDetail = async () => {
    const detailsId = this.props.match.params.id
    const res = await findRoomPatrolDetail({ detailsId })
    if (res.code === 20000) {
      this.setState({
        detailInfo: res.data,
        project: res.data && (res.data.typeEntityList || [])
      }, () => {
        this.listPollingType(0, 1)
      })
    }
  }

  // 查询巡更项
  listPollingType = async (parentId, key) => {
    const { detailInfo } = this.state
    const res = await req_listPollingType({
      configId: detailInfo.config_id,
      roomId: detailInfo.room_id,
      parentId: parentId
    })
    if (res.code === 20000) {
      const data = res.data
      if (key === 1) {
        this.setState({
          parentData1: data
        })
      }
      if (key === 2) {
        this.setState({
          parentData2: data
        })
      }
      if (key === 3) {
        this.setState({
          parentData3: data
        })
      }
      if (key === 4) {
        this.setState({
          parentData4: data
        })
      }

    }
  }

  //巡检后，输入内容，提交到隐患库
  taskSubmit = async () => {
    const detailsId = this.props.match.params.id
    const { base64, dangerEntityList, dangerType, pollingTypeList } = this.state
    if (dangerType === '') {
      Toast.info("请选择隐患类型", 1)
      return false
    }
    if (dangerType > 1 && dangerEntityList.length === 0) {
      Toast.info("请添加隐患点", 1)
      return false
    }
    if (!base64) {
      Toast.info("请签字确认", 1)
      return false
    }

    const values = {
      detailsId,
      sign: base64,
      dangerType,
      dangerEntityList: dangerEntityList.map(item => {
        item.danger_file = item.danger_file[0].file_url
        return item
      }),
      pollingTypeList
    }
    const res = await req_submitCheckResult(values)
    if (res.code === 20000) {
      Toast.info("提交成功", 1)
      this.props.history.goBack()
    }
  }
  //巡检项目选择是否有隐患
  onChangeProject = (e, k) => {
    const { project } = this.state
    this.setState({
      project: project.map((p, i) => {
        if (k === i) {
          p.type_content = e.target.value
        }
        return p
      })
    })
  }
  //隐患类型
  onChangeType = e => {
    this.setState({
      patrolType: e.target.value
    })
  }

  // 图片上传
  upload = (files, type, index) => {
    const { urls, patrolImgs, dangerEntityList, pollingKey } = this.state
    if (type === "add") {
      Toast.loading("图片上传中,请稍等", 0)
      const imgSize = files[files.length - 1].file.size
      if (imgSize > 10 * 1024 * 1024) return Toast.info('请上传小于10M的图片', 2)
      let formData = new FormData()
      formData.append("multipartFile", files[files.length - 1].file)
      uploadPatrolImages(formData).then(res => {
        this.setState({
          patrolImgs: files,
        })
        if (res.code === 20000) {
          Toast.hide()
          Toast.info("上传成功", 1)
          this.setState({
            dangerEntityList: dangerEntityList.map(item => {
              if (item.key === pollingKey) {
                item.danger_file = [
                  {
                    id: 1,
                    url: IP.fileHost + res.data,
                    file_url: res.data
                  }
                ]
              }
              return item
            })
          })
        } else {
          Toast.hide()
          Toast.fail(res.message)
        }
      })
    }
    if (type === "remove") {
      this.setState({
        dangerEntityList: dangerEntityList.map(item => {
          if (item.key === pollingKey) {
            item.danger_file = []
          }
          return item
        })
      })
    }
  }
  // 点击签字
  goSign = () => {
    this.setState({
      visible: true
    })
    setTimeout(() => {
      eval("PageReady()")
    }, 500)
  }
  // 清除签字
  handleClearBtnClick = () => {
    eval('clearCanvas()')
  }

  // 签字确认
  handleOk = () => {
    const data = eval('saveSignature()')
    this.setState({
      visible: false
    })
    this.rotateBase64Img(data, 270)
  }

  //将canvas图片转为base64图片
  rotateBase64Img = (src, edg, callback) => {
    const This = this
    var canvas = document.createElement("canvas")
    var ctx = canvas.getContext("2d")
    var imgW//图片宽度
    var imgH//图片高度
    var size//canvas初始大小
    if (edg % 90 != 0) {
      throw '旋转角度必须是90的倍数!'
    }
    (edg < 0) && (edg = (edg % 360) + 360)
    const quadrant = (edg / 90) % 4 //旋转象限
    const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 } //裁剪坐标
    var image = new Image()
    image.crossOrigin = "anonymous"
    image.src = src
    image.onload = function () {
      imgW = image.width
      imgH = image.height
      size = imgW > imgH ? imgW : imgH
      canvas.width = size * 2
      canvas.height = size * 2
      switch (quadrant) {
        case 0:
          cutCoor.sx = size
          cutCoor.sy = size
          cutCoor.ex = size + imgW
          cutCoor.ey = size + imgH
          break
        case 1:
          cutCoor.sx = size - imgH
          cutCoor.sy = size
          cutCoor.ex = size
          cutCoor.ey = size + imgW
          break
        case 2:
          cutCoor.sx = size - imgW
          cutCoor.sy = size - imgH
          cutCoor.ex = size
          cutCoor.ey = size
          break
        case 3:
          cutCoor.sx = size
          cutCoor.sy = size - imgW
          cutCoor.ex = size + imgH
          cutCoor.ey = size + imgW
          break
      }
      ctx.translate(size, size)
      ctx.rotate(edg * Math.PI / 180)
      ctx.drawImage(image, 0, 0)
      var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey)
      if (quadrant % 2 == 0) {
        canvas.width = imgW
        canvas.height = imgH
      } else {
        canvas.width = imgH
        canvas.height = imgW
      }
      ctx.putImageData(imgData, 0, 0)

      //获取旋转后的base64图片
      This.setState({
        base64: canvas.toDataURL(),
      })
    }
  }
  //重签
  reSign = () => {
    this.setState({
      base64: '',
      visible: true,
    })
    setTimeout(() => {
      eval("PageReady()")
    }, 500)
  }
  // 上传视频文件
  onChangeFile = async (e) => {
    const { urls, patrolVideo } = this.state
    const { files } = e.target
    Toast.loading("视频上传中,请稍等", 0)
    const imgSize = files[files.length - 1].size
    if (imgSize > 20 * 1024 * 1024) return Toast.info('请上传小于20M的附件', 2)
    let formData = new FormData()
    formData.append("multipartFile", files[files.length - 1])
    const res = await uploadDisposeFile(formData)
    if (res.code === 20000) {
      Toast.hide()
      Toast.info("上传成功", 1)
      urls.push({ file_name: files[files.length - 1].name, file_url: res.data })
      patrolVideo.push({ file_name: files[files.length - 1].name, file_url: res.data })
      this.setState({ urls, patrolVideo })
    } else {
      Toast.hide()
      Toast.fail(res.message)
    }
  }
  // 删除视频
  handleRemoveVideo = item => {
    const { urls, patrolVideo } = this.state
    this.setState({
      urls: urls.filter(x => x.file_url !== item.file_url),
      patrolVideo: patrolVideo.filter(x => x.file_url !== item.file_url),
    })
  }
  render() {
    const { dangerType, addVisible, width, height, visible, base64, detailInfo, patrolImgs, dangerEntityList, pollingKey, parentData1, parentData2, parentData3, parentData4, type_id, pollingTypeList } = this.state

    return (
      <div className='createPatrol'>
        <img src={require('../../../images/patrol/3.png')} width='100%' alt="" />
        <div className="listWrap mt-12">
          <div className='listStyl'>
            <div className="left"><span style={{ color: 'red' }}>* </span>巡检点名称</div>
            <div className="right">{detailInfo.building_name + detailInfo.floor_name + detailInfo.room_name}</div>
          </div>
          <div className='listStyl'>
            <div className="left"><span style={{ color: 'red' }}>* </span>巡检人</div>
            <div className="right">{cookie.load('userData').name}</div>
          </div>
          <div className='listStyl'>
            <div className="left"><span style={{ color: 'red' }}>* </span>所属院系</div>
            <div className="right">{detailInfo.dept_name}</div>
          </div>
        </div>
        <div className="listWrap mt-12">

          <div className='listStyl'>
            <div className="left"><span style={{ color: 'red' }}>* </span>隐患类型</div>
            <div className="right patrol_select" style={{ color: '#00b4c4' }}>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="请选择隐患类型"
                optionFilterProp="children"
                onChange={(item) => {
                  this.setState({
                    dangerType: item
                  })
                }}
              >
                <Option value={1}>无隐患</Option>
                <Option value={2}>一般隐患</Option>
                <Option value={3}>较大隐患</Option>
                <Option value={4}>严重隐患</Option>
              </Select>
            </div>
          </div>
          {dangerType && dangerType !== 1 ? (<div className='listStyl'>
            <div className="left"><span style={{ color: 'red' }}>* </span>隐患点</div>
            <div className="right" style={{ color: '#00b4c4' }}>
              <div className='listStyl_video_list' onClick={() => {
                if (this.state.addVisible === false) {
                  key++
                  dangerEntityList.push({
                    key,
                    danger_title: '',
                    danger_content: '',
                    danger_file: [],
                    type_id: '',
                  })
                  pollingTypeList.push(
                    {
                      key,
                      type_id: undefined,
                      type_name: '',
                      type_sort: '',
                      typeId1: undefined,
                      typeId2: undefined,
                      typeId3: undefined,
                    }
                  )

                  this.setState({
                    pollingKey: key,
                    dangerEntityList,
                    pollingTypeList,
                  }, () => {
                    this.setState({
                      addVisible: true,
                    })
                  })
                }

              }}>点击添加</div>
            </div>
          </div>) : ''}

          {pollingTypeList.map(item => {
            return (
              <div className='listStyl' key={item.key}>
                <div className="left listStyl_item_title">{item.type_sort + item.type_name}</div>
                <div className='listStyl_video_list_del' onClick={() => {
                  this.setState({
                    dangerEntityList: dangerEntityList.filter(x => x.key !== item.key),
                    pollingTypeList: pollingTypeList.filter(x => x.key !== item.key)
                  })
                }}>删除</div>
              </div>
            )
          })}
        </div>
        <div className="listWrap mt-12">
          <div className='listStyl'>
            <div className="left"><span style={{ color: 'red' }}>* </span>巡检人签字</div>
          </div>
          <div className='pd-16'>
            {
              base64 ?
                <div>
                  <Flex>
                    <Flex.Item ><img src={base64} alt="" width={`100%`} /></Flex.Item>
                    <Flex.Item style={{ textAlign: 'center' }}><Button inline size="small" className="light-green-bg-button" onClick={this.reSign}>重签</Button></Flex.Item>
                  </Flex>
                </div>
                :
                <Button className='grey-text-button' onClick={this.goSign}>点击签字</Button>
            }
          </div>
          <div className='listStyl'>
            <div className="left">检查时间</div>
            <div className="right">{moment(new Date()).format('YYYY-MM-DD HH:mm')}</div>
          </div>
        </div>

        <Flex className='btn-group mt-12'>
          <Flex.Item onClick={this.taskSubmit}><Button className='green-bg-button'>提交</Button></Flex.Item>
        </Flex>


        <Modal
          visible={visible}
          transparent
          onClose={() => this.setState({ visible: false })}
          style={{ width: '100%', height: '100%', background: '#fff' }}
        >
          <div>
            <section className="signature-component" >
              <div id='signature-pad' className="js-signature" data-width={width * 0.7} data-height={height * 0.8} data-border="1px solid black" data-line-color="#000" data-auto-fit="true"></div>
            </section>
            <div className='sign_btn' style={{ height: `${height * 0.2 - 60}px` }}>
              <div className='approveDetail_btn_list' style={{ width: `${height * 0.2 - 60}px` }} onClick={() => this.handleClearBtnClick()}>清除</div>
              <div className='approveDetail_btn_list' style={{ width: `${height * 0.2 - 60}px` }} onClick={() => this.handleOk()}>确认</div>
            </div>
          </div>
        </Modal>
        <input id='handle_video' type="file" accept="video/*" capture="camcorder" hidden onChange={e => this.onChangeFile(e)} onClick={(event) => { event.target.value = null }} />


        {addVisible ? (<Modal
          visible={addVisible}
          transparent
          className='patrol_modal'
          style={{ width: '100%', height: '100%', background: '#fff' }}
        >
          <div>
            <div className='patrol_modal_title'>
              <div></div>
              <div>添加隐患点</div>
              <div>
                <Icon type='cross' onClick={() => {
                  this.setState({
                    addVisible: false,
                    dangerEntityList: dangerEntityList.filter(x => x.key !== pollingKey),
                    pollingTypeList: pollingTypeList.filter(x => x.key !== pollingKey)
                  })
                }} />
              </div>
            </div>

            <div className='patrol_modal_item'>
              <div>
                <span style={{ color: 'red' }}>* </span>
                检查项目
              </div>
              <div className='patrol_modal_select_two'>
                <Select
                  showSearch
                  placeholder="请选择"
                  optionFilterProp="children"
                  value={pollingTypeList.find(x => x.key === pollingKey).typeId1}
                  onChange={(e) => {
                    this.listPollingType(e, 2)
                    this.setState({
                      dangerEntityList: dangerEntityList.map(item => {
                        if (item.key === pollingKey) {
                          item.danger_title = ''
                          item.type_id = undefined
                        }
                        return item
                      }),
                      pollingTypeList: pollingTypeList.map(item => {
                        if (item.key === pollingKey) {
                          item.typeId1 = e
                          item.typeId2 = undefined
                          item.typeId3 = undefined
                          item.type_id = undefined
                        }
                        return item
                      })
                    })
                  }}
                >
                  {parentData1.map(item => {
                    return (
                      <Option value={item.type_id} key={item.type_id}>{item.type_name}</Option>
                    )
                  })}

                </Select>
                <Select
                  showSearch
                  placeholder="请选择"
                  optionFilterProp="children"
                  value={pollingTypeList.find(x => x.key === pollingKey).typeId2}
                  onChange={(e) => {
                    this.listPollingType(e, 3)
                    this.setState({
                      dangerEntityList: dangerEntityList.map(item => {
                        if (item.key === pollingKey) {
                          item.danger_title = ''
                          item.type_id = undefined
                        }
                        return item
                      }),
                      pollingTypeList: pollingTypeList.map(item => {
                        if (item.key === pollingKey) {
                          item.typeId2 = e
                          item.typeId3 = undefined
                          item.type_id = undefined
                        }
                        return item
                      })
                    })
                  }}
                >
                  {parentData2.map(item => {
                    return (
                      <Option value={item.type_id} key={item.type_id}>{item.type_sort}、{item.type_name}</Option>
                    )
                  })}
                </Select>

              </div>
            </div>
            <div className='patrol_modal_item'>
              <div>
                <span style={{ color: 'red' }}>* </span>
                二级项目
              </div>
              <div className='patrol_modal_select'>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="请选择"
                  optionFilterProp="children"
                  value={pollingTypeList.find(x => x.key === pollingKey).typeId3}
                  onChange={(e) => {
                    this.listPollingType(e, 4)
                    this.setState({
                      dangerEntityList: dangerEntityList.map(item => {
                        if (item.key === pollingKey) {
                          item.danger_title = ''
                          item.type_id = undefined
                        }
                        return item
                      }),
                      pollingTypeList: pollingTypeList.map(item => {
                        if (item.key === pollingKey) {
                          item.typeId3 = e
                          item.type_id = undefined
                        }
                        return item
                      })
                    })
                  }}
                >
                  {parentData3.map(item => {
                    return (
                      <Option value={item.type_id} key={item.type_id}>{item.type_sort}、{item.type_name}</Option>
                    )
                  })}
                </Select>
              </div>
            </div>
            <div className='patrol_modal_item'>
              <div>
                <span style={{ color: 'red' }}>* </span>
                检查要点
              </div>
              <div className='patrol_modal_select'>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="请选择"
                  optionFilterProp="children"
                  value={pollingTypeList.find(x => x.key === pollingKey).type_id}
                  onChange={(e) => {
                    this.setState({
                      dangerEntityList: dangerEntityList.map(item => {
                        if (item.key === pollingKey) {
                          item.danger_title = parentData4.find(x => x.type_id === e).type_sort
                          item.type_id = e
                        }
                        return item
                      }),
                      pollingTypeList: pollingTypeList.map(item => {
                        if (item.key === pollingKey) {
                          item.type_id = e
                          item.type_name = parentData4.find(x => x.type_id === e).type_name
                          item.type_sort = parentData4.find(x => x.type_id === e).type_sort
                          item.type_id = e
                        }
                        return item
                      })
                    })
                  }}
                >
                  {parentData4.map(item => {
                    return (
                      <Option value={item.type_id} key={item.type_id}>{item.type_sort}、{item.type_name}</Option>
                    )
                  })}
                </Select>
              </div>
            </div>
            {parentData4 && dangerEntityList.find(x => x.key === pollingKey) && dangerEntityList.find(x => x.key === pollingKey).type_id && parentData4.find(x => x.type_id === dangerEntityList.find(x => x.key === pollingKey).type_id) ? (<div className='patrol_modal_content'>
              {parentData4.find(x => x.type_id === dangerEntityList.find(x => x.key === pollingKey).type_id).type_content}
            </div>) : ''}

            <div className='patrol_modal_item'>
              <div>
                <span style={{ color: 'red' }}>* </span>
                隐患标题
              </div>
              <div className='patrol_modal_select'>
                <Input placeholder="请输入" value={dangerEntityList.find(x => x.key === pollingKey).danger_title} onChange={(e) => {
                  this.setState({
                    dangerEntityList: dangerEntityList.map(item => {
                      if (item.key === pollingKey) {
                        item.danger_title = e.target.value
                      }
                      return item
                    })
                  })
                }} />
              </div>
            </div>
            <div className='patrol_modal_item' style={{ alignItems: 'baseline' }}>
              <div>
                <span style={{ color: 'red' }}>* </span>
                隐患描述
              </div>
              <div className='patrol_modal_text'>
                <TextareaItem
                  rows={5}
                  placeholder="请输入隐患描述"
                  value={dangerEntityList.find(x => x.key === pollingKey).danger_content} onChange={(e) => {
                    this.setState({
                      dangerEntityList: dangerEntityList.map(item => {
                        if (item.key === pollingKey) {
                          item.danger_content = e
                        }
                        return item
                      })
                    })
                  }}
                />
              </div>
            </div>
            <div className='patrol_modal_item_upload'>
              <div>
                <span style={{ color: 'red' }}>* </span>
                上传图片
              </div>
              <ImagePicker
                className='potral_upload'
                length="4"
                files={dangerEntityList.find(x => x.key === pollingKey).danger_file}
                selectable={dangerEntityList.find(x => x.key === pollingKey).danger_file.length < 1}
                onChange={(files, type, index) => {
                  this.upload(files, type, index)
                }}
              />
            </div>
            <div className='potral_but' style={{ padding: '0px 24px 24px' }}>
              <Button className='green-bg-button' onClick={() => {
                const newData = dangerEntityList.find(x => x.key === pollingKey)
                const polData = pollingTypeList.find(x => x.key === pollingKey)
                if (newData.danger_title === '' || newData.danger_content === '' || newData.danger_file.length === 0 || polData.type_id === '') {
                  Toast.info('请先填写完整表单！')
                  return
                }
                this.setState({
                  addVisible: false,
                  parentData2: [],
                  parentData3: [],
                  parentData4: [],
                })
              }}>保存</Button>
            </div>

          </div>
        </Modal>) : ''}

      </div>
    )
  }
}
export default CreatePatrol