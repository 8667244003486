import React, { Component } from 'react'
import { Toast } from 'antd-mobile'
import ReactHtmlParser from 'react-html-parser'
import { findZJDetail, findSaftyTypeDetail, saveStudyRecord } from '../../../api/safty'
import Utils from '../../../utils/utils'
export default class StudyOnlineDetail extends Component {
  state = {
    typeId: this.props.match.params.typeId - 0,
    zjId: this.props.match.params.zjId - 0,
    typeData: {},
    data: {},
    flattenZjList: [],
    time: 0
  }
  componentDidMount () {
    this.fetch()
    this.setState({
      flattenZjList: Utils.flattenTree(JSON.parse(sessionStorage.getItem('zjList')))
    })
  }
  componentWillUnmount () {
    this.clear()
  }
  start = () => {
    this.timer = setInterval(() => {
      this.setState({ time: this.state.time + 1 })
    }, 1000)
  }
  clear = () => {
    const { zjId, time } = this.state
    saveStudyRecord({ categoryId: zjId, timeSecond: time }).then(res => {
      // message.info('学习时长已提交')
      console.log(res, 'res')
    }, err => {
      Toast.fail('学习时长提交失败')
      console.log(err, 'err')
    }).finally(v => {
      clearInterval(this.timer)
      this.setState({
        time: 0
      })
    })
  }
  fetch = () => {
    this.viewZJDetail()
    this.fetchTypeDetail()
    this.start()
  }
  fetchTypeDetail = async () => {
    const { typeId } = this.state
    const res = await findSaftyTypeDetail({ categoryId: typeId })
    if (res.code === 20000) {
      this.setState({
        typeData: res.data
      })
    }
  }
  viewZJDetail = async () => {
    const { zjId } = this.state
    const res = await findZJDetail({ courseId: zjId })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }
  //上一节 下一节
  nextOrPrev = (flag) => {
    this.clear()
    const { typeId, zjId, flattenZjList } = this.state
    let curr_index = flattenZjList.findIndex(x => x.catalogue_id - 0 === zjId)
    let willSelectedIdx = null
    if (flag === 'next') {
      willSelectedIdx = curr_index + 1
      if (willSelectedIdx === flattenZjList.length) {
        Toast.info('当前课程已经最后一节了！')
        return
      }
    }
    if (flag === 'prev') {
      willSelectedIdx = curr_index - 1
      if (curr_index === 0) {
        Toast.info('当前课程已经已经第一节了！')
        return
      }
    }
    this.setState({
      zjId: flattenZjList[willSelectedIdx].catalogue_id
    }, () => {
      this.props.history.replace(`/module/safty/study/online/${typeId}/${flattenZjList[willSelectedIdx].catalogue_id}`)
      this.fetch()
    })
  }
  render () {
    const { data, typeData, time } = this.state
    return (
      <div className="on_line onLineStudyDetail">
        <div className='on_line_header'>
          <div className='on_line_title'>
            <div className='on_line_title_bt'>{typeData.category_name}
              <span className='time'>学习时长：{Utils.secondsToHMS(time)}</span></div>
          </div>
        </div>
        <div className="content">
          <p className="zjTitle">
            {data.catalogue_name}

          </p>
          {data.catalogue_content ? ReactHtmlParser(data.catalogue_content) : <div style={{ marginTop: '100px', textAlign: 'center' }}>--暂无内容--</div>}
        </div>
        <div className="bottom_btn fixed_bottom_btn">
          <div className="bottom_btn_list no_solid_bg" onClick={() => this.nextOrPrev('next')}>
            下一节
          </div>
        </div>
      </div>
    )
  }
}
