import React, { Component } from 'react'
// import "../../../assets/adapter.min"
// import "../../../assets/webrtcstreamer"
import { findAppDeviceJk3 } from '../../../api/api'
import './video.less'

const screen = window.screen.width

export default class Video extends Component {
  state = {
    webRtcServer: null,
    w: screen * 0.9,
    tabs: [],
    curTab: 0,
    curRtsp: ''
  }
  async componentDidMount () {
    document.title = "监控"
    const res = await findAppDeviceJk3({ roomId: this.props.match.params.id })
    if (res.code === 20000) {
      let serverUrl = 'http://192.168.10.100:8000'
      this.setState({
        tabs: res.data,
        webRtcServer: new window.WebRtcStreamer(this.video, serverUrl),
        curRtsp: res.data[0].rtp_url.trim()
      }, () => {
        console.log(this.state.curRtsp, '=====================')
        this.connect()
      })
    }
  }
  connect = () => {
    // let options2 = "rtptransport=tcp&timeout=60"
    // this.state.webRtcServer.connect(this.state.curRtsp, this.state.curRtsp, options2, null)

    this.state.webRtcServer.connect(this.state.curRtsp)
  }
  disconnectServer = () => {
    this.state.webRtcServer.disconnect()
  }
  destroyServerObj = () => {
    this.setState({
      webRtcServer: null
    })
  }
  componentWillUnmount () {
    this.disconnectServer()
    this.destroyServerObj()
  }
  handleChange = (tab, index) => {
    this.setState({
      curTab: index,
      curRtsp: tab.rtp_url.trim()
    }, () => {
      this.connect()
    })
  }

  render () {
    const { w, tabs, curTab } = this.state
    return (
      <div className='video_monitor'>
        <div className="overflow">
          <div className='tabs_self'>
            {tabs.map((tab, index) => {
              return <div className={curTab === index ? 'select' : ''} key={tab.monitoring_id} onClick={() => this.handleChange(tab, index)}>{tab.monitoring_name}</div>
            })}
          </div>
        </div>
        <video ref={ref => this.video = ref} width={w} playsInline controls>您的浏览器不支持播放该视频！</video>
      </div>
    )
  }
}
