import React, { Component } from 'react'
import { TreeSelect, Select } from 'antd'
import { TextareaItem, Modal, Icon, Toast, InputItem } from 'antd-mobile';
import { findLyInfoDetailsById, lysqWhpCkInfoLyck, findKcmxInfo, findKcmxDetails } from './../../../../api/whp'
import './qr_add.less'
import wx from './../../../../utils/weixin'
const { TreeNode } = TreeSelect;

class Add extends Component {
  state = {
    data: [
      {
        label: '2013年',
        value: '2013',
      },
      {
        label: '2014年',
        value: '2014',
      },
    ],
    slideId: '',
    startX: '',
    visible: false,
    tab_id: '',
    sqfxData: [],//方向
    xydm: undefined,//所属学院
    zxid: undefined,//所属中心
    jsid: undefined,//所属实验室
    lyckbh: undefined,
    syfxbh: undefined,
    lyrgh1: undefined,
    lyrgh2: undefined,
    lyyt: '',
    typeData: [],//类别
    whpFlbh: '',//化学品类别
    chemicalData: [],//化学品
    depTreeData: [],
    depData: [],
    centerData: [],//中心
    roomData: [],//房间
    masterProcess: 0, // 是否多人同时审批
    approveVisible: false,
    spry: undefined,
    warehouseData: [],//仓库
    userData: [],//领用人
    whpInfoEntityList: [],//领用物品
    depUserData: [],
    depArr: [],
    flbh: '',
    record: {},
    whpInfo: {},
    whpxh: '',
    selectedRowKeys: [],
  }

  componentDidMount = async () => {
    document.title = '扫码出库'
    window.scrollTo(0, 0)
    this._findLyInfoDetailsById(this.props.match.params.id)
    await wx.InitWxConfig()
  }

  // 详情
  _findLyInfoDetailsById = async (lydh) => {
    const res = await findLyInfoDetailsById({ lydh })
    if (res.code === 20000) {
      const list = res.data.whpInfoEntityList.map(item => {
        item.whpCkInfoVoList = []
        item.whp_cksl = 0
        return item
      })
      console.log(list)
      this.setState({
        record: res.data,
        whpInfoEntityList: list
      })
    }
  }

  // 查询可选择出库的危化品
  fetchWhp = async (whpBh, ckid) => {
    const res = await findKcmxInfo({ size: 999999, page: 1, whpBh, ckid })
    if (res.code === 20000) {
      this.setState({
        chemicalData: res.data.list.map(item => {
          item.state = false
          return item
        }),
      })
    }
  }

  // 求和
  sum(arr) {
    var sum = 0;
    for (var i = 0; i < arr.length; i++) {
      sum += arr[i];
    }
    return sum;
  }

  // 添加危化品
  handleAddWhp = () => {
    const { chemicalData, whpInfoEntityList, whpInfo } = this.state
    const data = chemicalData.filter(item => item.state)
    if (data.length === 0) {
      return Toast.info('请添加危化品！')
    }
    this.setState({
      visible: false,
      whpInfoEntityList: whpInfoEntityList.map(x => {
        if (x.whp_bh === whpInfo.whp_bh) {
          x.whpCkInfoVoList = data
          x.whp_cksl = this.sum(data.map(x => x.whp_xcs))
        }
        return x
      })
    })
  }


  // 提交
  submit = async () => {
    const { record, whpInfoEntityList } = this.state
    const arr = whpInfoEntityList.filter(x => x.whp_cksl)
    if (!arr.length) return Toast.info('请先选择出库的危化品！')
    const params = {
      lybh: record.lybh,
      whpList: whpInfoEntityList,
    }
    const res = await lysqWhpCkInfoLyck(params)
    if (res.code === 20000) {
      Toast.info("提交成功！")
      setTimeout(() => {
        this.props.history.go(-2)
      }, 2000);
    }
  }

  // 扫描二维码
  handleIdentify = async () => {
    const { selectedRowKeys, whpInfoEntityList, whpxh } = this.state
    const res = await findKcmxDetails({ whpxh })
    if (res.code === 20000) {
      const whpInfo = res.data
      if (!whpInfo) {
        return Toast.info("查询为空！")
      }
      if (whpInfoEntityList.filter(x => x.whp_bh === whpInfo.whp_bh).length === 0) {
        this.setState({
          whpxh: '',
        })
        return Toast.info("此危化品不在出库范围内！")
      }
      if (whpInfoEntityList.find(x => x.whp_bh === whpInfo.whp_bh).dlysl === 0) {
        this.setState({
          whpxh: '',
        })
        return Toast.info("此危化品领用量已足够！")
      }
      if (selectedRowKeys.filter(x => x.whp_xh === whpInfo.whp_xh).length) {
        this.setState({
          whpxh: '',
        })
        return Toast.info("该危化品已扫描！")
      }
      // 查询当前类
      const sum = this.sum(whpInfoEntityList.find(x => x.whp_bh === whpInfo.whp_bh).whpCkInfoVoList.map(x => x.whp_xcs))
      if (sum >= whpInfoEntityList.find(x => x.whp_bh === whpInfo.whp_bh).dlysl) {
        this.setState({
          whpxh: '',
        })
        return Toast.info("出库数量过多！")
      }

      selectedRowKeys.push(whpInfo)

      this.setState({
        whpxh: '',
        selectedRowKeys: selectedRowKeys,
        whpInfoEntityList: whpInfoEntityList.map(x => {
          if (x.whp_bh === whpInfo.whp_bh) {
            x.whpCkInfoVoList.push(whpInfo)
            x.whp_cksl = this.sum(selectedRowKeys.filter(x => x.whp_bh === whpInfo.whp_bh).map(x => x.whp_xcs))
          }
          return x
        }),

      }, () => {
        Toast.info("扫描成功，已添加！")
      })
    }
  }

  render() {
    const { slideId, visible, chemicalData, whpInfoEntityList, record } = this.state
    const loop = data =>
      data.map(item => {
        if (item.children && item.children.length) {
          return (
            <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode title={item.title} key={item.value} value={item.value} disabled={item.disabled} />;
      });
    return (
      <div className='subscription_add'>
        <div className='subscription_add_title'>
          基础信息
        </div>
        <div className='subscription_add_form'>
          <div className='subscription_add_input'>
            <InputItem placeholder="" value={record.lybh} disabled ><span style={{ color: '#fff' }}>* </span>领用单号</InputItem>
          </div>
          <div className='subscription_add_input'>
            <InputItem placeholder="" value={record.lyckmc} disabled ><span style={{ color: '#fff' }}>* </span>领用仓库</InputItem>
          </div>
          <div className='subscription_add_input'>
            <InputItem placeholder="" value={record.xymc} disabled ><span style={{ color: '#fff' }}>* </span>申请所属学院</InputItem>
          </div>
          <div className='subscription_add_input'>
            <InputItem placeholder="" value={record.zxmc} disabled ><span style={{ color: '#fff' }}>* </span>所属中心</InputItem>
          </div>
          <div className='subscription_add_input'>
            <InputItem placeholder="" value={record.jsmc} disabled ><span style={{ color: '#fff' }}>* </span>所属实验室</InputItem>
          </div>
          <div className='subscription_add_input'>
            <InputItem placeholder="" value={record.syfxmc} disabled ><span style={{ color: '#fff' }}>* </span>使用方向</InputItem>
          </div>
        </div>

        <div className='subscription_add_form'>
          <div className='subscription_add_input'>
            <InputItem placeholder="" value={record.lyrmc1} disabled ><span style={{ color: '#fff' }}>* </span>第一领用人</InputItem>
          </div>
          <div className='subscription_add_input'>
            <InputItem placeholder="" value={record.lyrmc2} disabled ><span style={{ color: '#fff' }}>* </span>第二领用人</InputItem>
          </div>

        </div>


        <div className='subscription_add_form'>
          <div className='subscription_add_text'>
            <div><span style={{ color: '#fff' }}>* </span>领用用途</div>
            <TextareaItem
              placeholder="请输入"
              rows={5}
              disabled
              value={record.lyyt}
            />
          </div>
        </div>

        <div className='subscription_add_form'>
          <div className='subscription_add_input' style={{ display: 'flex', background: '#fff', alignItems: 'center' }}>
            <InputItem placeholder="" disabled className='outbound_qr_add_input'><span style={{ color: '#fff' }}>* </span>扫码出库</InputItem>

            <img src={require('./../../../../images/chemicals/13.png')} style={{ width: 16, height: 16, marginRight: 16 }} alt='' onClick={async () => {
              // Toast.loading("正在打开扫一扫！", 0)
              const that = this
              // that.setState({
              //   whpxh: 'FG000009-000009'
              // }, () => {
              //   that.handleIdentify()
              // })
              // return

              window.wx.ready(() => {
                window.wx.scanQRCode({
                  needResult: 1,
                  scanType: ['qrCode'], // 可以传入的值有 barcode、‌qrCode，‌如果传入的值是 barcode 则只识别一维码，‌如果是qrCode则只识别二维码，‌如果都不传入则优先识别二维码
                  success: function (res) {
                    that.setState({
                      whpxh: res.resultStr
                    }, () => {
                      that.handleIdentify()
                    })
                    // Toast.hide()
                  },
                  error: function (res) {
                    console.log(res)
                  }
                });
              })

            }} />

          </div>

        </div>


        <div className='subscription_add_increase_record'>
          <div className='subscription_list_card' style={{ margin: '0' }}>
            {whpInfoEntityList.map((item, key) => {
              return (
                <div className='subscription_add_card_item' style={{ height: 'auto' }} key={key}>
                  <div className='subscription_add_card_cont  subscription_add_cont_float' style={{ margin: '0px 12px', position: 'unset' }}>
                    <div className='subscription_list_card_item' style={{ width: slideId === item.whp_xh ? window.screen.width : window.screen.width - 24 }}>
                      <div className='subscription_list_card_head'>
                        <div className='subscription_list_card_head_left'>
                          <div>{key + 1}</div>
                          <div>{item.whp_mc}</div>
                        </div>
                        {item.dlysl > 0 ? (<div className='subscription_list_card_class outbound_but' onClick={() => {
                          this.setState({
                            visible: true,
                            whpInfo: item,
                            chemicalData: whpInfoEntityList.find(x => x.whp_bh === item.whp_bh).whpCkInfoVoList
                          })
                        }}>
                          出库详情
                        </div>) : ''}

                      </div>
                      <div className='subscription_list_card_company'>
                        <div>{item.whp_fl} {item.whp_cas}</div>
                        <div>{item.whp_gys}</div>
                      </div>
                      <div className='subscription_list_card_unit'>
                        <div>{item.whp_ggxh}</div>
                        <div>{item.whp_bzsl}{item.whp_bzdw}</div>
                        <div>{item.whp_jldw}</div>
                      </div>
                      <div className='subscription_list_card_num'>
                        <div className='subscription_list_card_num_item'>
                          <div>{item.lysl}</div>
                          <div>领用量</div>
                        </div>
                        <div className='subscription_list_card_num_item'>
                          <div>{item.dlysl}</div>
                          <div>待领用量</div>
                        </div>
                        <div className='subscription_list_card_num_item'>
                          <div>{item.whp_cksl}</div>
                          <div>出库量</div>
                        </div>
                      </div>
                      {item.lyms ? (<div style={{ marginTop: '4px' }}>
                        备注：{item.lyms}
                      </div>) : ''}

                    </div>
                  </div>
                </div>
              )
            })}

          </div>
        </div>
        <div className='subscription_list_but'>
          <div onClick={() => {
            this.submit()
          }}>提交领用</div>
        </div>

        <Modal
          popup
          visible={visible}
          className='subscription_modal'
          onClose={() => {
            this.setState({
              visible: false
            })
          }}
          animationType="slide-up"
        >
          <div className='subscription_add_modal'>
            <div className='subscription_add_modal_locate'>
              <div className='subscription_add_modal_head'>
                <div>危化品信息</div>
                <div>
                  <Icon type='cross' onClick={() => {
                    this.setState({
                      visible: false
                    })
                  }} />
                </div>
              </div>
            </div>
            <div className='subscription_add_modal_card' style={{ padding: '30px 0px 76px' }}>
              {chemicalData.map((item, key) => {
                return (<div className={item.state ? 'subscription_list_card_item warehousing_modal_item_click' : 'subscription_list_card_item subscription_list_card_border'} key={item.whp_xh}>
                  <div className='subscription_list_card_head'>
                    <div className='subscription_list_card_head_left'>
                      <div>{key + 1}</div>
                      <div>{item.whp_mc}</div>
                    </div>
                    <div className='subscription_list_card_class'>
                      {item.whp_fl}
                    </div>
                  </div>
                  <div className='subscription_list_card_company'>
                    <div>{item.whp_cas}</div>
                    <div>{item.whp_gys}</div>
                  </div>
                  <div className='subscription_list_card_foot'>
                    <div className='subscription_list_card_unit'>
                      <div>{item.whp_ggxh}</div>
                      <div>{item.whp_xcs}{item.whp_bzdw}</div>
                      <div>{item.whp_jldw}</div>
                    </div>
                    <div className='subscription_list_card_count'>


                    </div>
                  </div>
                </div>)
              })}

            </div>
            <div className='subscription_list_but'>
              <div onClick={() => {
                this.setState({
                  visible: false
                })
              }}>关闭</div>
            </div>
          </div>
        </Modal>

      </div >
    )
  }
}

export default Add
