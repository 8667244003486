import React, { Component } from 'react'
import { Pagination, Icon } from 'antd-mobile'
import { findPdInfoDetails, submitSgqr } from '../../../../api/whp'
import './record.less'

class deviceDetail extends Component {

  state = {
    data: [],
    visible: false,
    opinion: '',
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    total: 0,
  }

  componentDidMount = () => {
    document.title = '盘点报告'
    window.scrollTo(0, 0)
    this.fetch()
  }

  fetch = async () => {
    const { pageSize, pageNum } = this.state
    const res = await findPdInfoDetails({ size: pageSize, page: pageNum, pdid: this.props.match.params.id })
    if (res.code === 20000) {
      this.setState({
        data: res.data.list,
        pages: res.data ? (res.data.pages ? res.data.pages : 1) : 1,
        total: res.data.total,
      })
    }
  }

  render() {
    const { data } = this.state
    return (
      <div className='approveDetail' style={{ padding: '1px 0px 76px' }}>
        <div className='approve_detail_cont' style={{ marginTop: '0px' }}>

          <div className='subscription_list_card'>
            {data && data.map((item, key) => {
              return (
                <div className='subscription_list_card_item' style={{ background: '#fff' }} key={key}>
                  <div className='subscription_list_card_head'>
                    <div className='subscription_list_card_head_left'>
                      <div>{key + 1}</div>
                      <div>{item.whp_mc}</div>
                    </div>
                    <div className='subscription_list_card_class'>
                      {item.whp_fl}
                    </div>
                  </div>
                  <div className='subscription_list_card_company'>
                    <div>{item.kcck_mc}</div>
                    <div>{item.whp_cas}&emsp;{item.whp_gys}</div>
                  </div>
                  <div className='subscription_list_card_unit'>
                    <div>{item.whp_ggxh}</div>
                    <div>{item.whp_bzsl}{item.whp_bzdw}</div>
                    <div>{item.whp_jldw}</div>
                  </div>
                  <div className='subscription_list_card_num'>
                    <div className='subscription_list_card_num_item'>
                      <div>{item.whp_dj}</div>
                      <div>单价</div>
                    </div>
                    <div className='subscription_list_card_num_item'>
                      <div>{item.whp_kcs}{item.whp_jldw}</div>
                      <div>库存量</div>
                    </div>
                    <div className='subscription_list_card_num_item'>
                      <div>{item.whp_xcsl}{item.whp_bzdw}</div>
                      <div>现存量</div>
                    </div>
                  </div>
                </div>
              )
            })}


          </div>


        </div>

        {this.state.data.length === 0 ? <div className='not-yet'>--暂无数据--</div> : ''}

        {
          this.state.total > 20 ? (<div className='subscription_manager_page'>
            <Pagination total={this.state.pages}
              current={this.state.pageNum}
              onChange={e => {
                this.setState({
                  pageNum: e
                }, () => {
                  window.scrollTo(0, 0)
                  this.fetch()
                })
              }}
              locale={{
                prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                nextText: (<span className="arrow-align"><Icon type="right" /></span>),
              }}
            />
          </div>) : ''
        }

      </div>
    )
  }
}

export default deviceDetail