import React, { Component } from 'react'
import { Toast, TextareaItem } from 'antd-mobile'
import { Radio } from 'antd'
import { req_getDetailsById } from '../../../api/repairApi'
import { req_checkHiddenDangers } from '../../../api/api'
import IP from './../../../config/config'
import moment from 'moment'

class ApproveDetail extends Component {

  state = {
    data: {},
    visible: false,
    opinion: '',
    checkContent: '',//隐患描述
    fileUrl: '',
    type: 1,
    detailsState: '',
    state: ''
  }

  componentDidMount = () => {
    document.title = "整改验收"
    this.fetch(this.props.match.params.id)
  }

  fetch = async detailsId => {
    const res = await req_getDetailsById({ id: detailsId })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }
  // 验收
  handleSubmit = async () => {
    const { data, state, checkContent } = this.state
    if (data.state === '') {
      return Toast.info('请选择验收结果！')
    }
    if (state === 2) {
      if (checkContent === '') {
        return Toast.info('请输入验收描述！')
      }
    }

    const res = await req_checkHiddenDangers({
      state,
      ids: this.props.match.params.id,
      checkContent,
    })
    if (res.code === 20000) {
      Toast.info(res.message)
      setTimeout(() => {
        this.props.history.go(-1)
      }, 3000);
    }
  }

  // 放大图片或者视频
  handleImageClick = (fileUrl, type) => {
    this.setState({
      visible: true,
      fileUrl,
      type,
    })
  }

  render() {
    const { data } = this.state
    return (
      <div className='approveDetail hidden_handle' style={{ padding: '0px' }}>
        <div className='approve_detail_head hidden_handle_head'>
          <div className='approve_detail_left'>
            <div className='approve_detail_left_title'>
              {data.room_name}（{data.class_name}）
            </div>
            <div className='approve_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='approve_list_head_date'>
                {data.building_name}{data.floor_name}
              </div>
            </div>
          </div>
          <div className='approve_detail_right hidden_handle_type'>
            <img src={require('./../../../img/patrol/未提交.png')} alt='' />
          </div>
        </div>
        <div className='approve_detail_cont'>
          <div className='approve_detail_bar' style={{ padding: '0px' }}>
            <div className={data.task_level === 1 ? 'patrol_type_xiao' : data.task_level === 2 ? 'patrol_type_yuan' : ''} style={{ padding: '12px 16px 0px' }}>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>巡检标题</div>
                <div>{data.task_title}</div>
              </div>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>巡检批次</div>
                <div>{data.task_name}</div>
              </div>

              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>巡检总负责人</div>
                <div>{data.fzr_name}</div>
              </div>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>巡检人</div>
                <div>{data.user_name}</div>
              </div>
              <div className='approve_detail_item patrol_approve_detail_item'>
                <div>提交时间</div>
                <div>
                  <div>{moment(data.complete_time).format('YYYY-MM-DD HH:mm')}</div>
                </div>
              </div>
              
            </div>
          </div>


          <div>
            {data && data.dangerEntityList && data.dangerEntityList.map((item, key) => {
              return <div className='approve_detail_danger' key={key}>
                <div className='approve_detail_danger_item'>
                  <div>隐患标题</div>
                  <div>{item.danger_title}</div>
                </div>
                <div className='approve_detail_danger_item'>
                  <div>隐患描述</div>
                  <div>{item.danger_content}</div>
                </div>
                <div className='approve_detail_danger_item'>
                  <div>图片预览</div>
                  <div>
                    <img src={IP.fileHost + item.danger_file} alt='' style={{ height: '40px' }} onClick={() => {
                      this.setState({
                        visible: true,
                        fileUrl: item.danger_file,
                        type: 1,
                      })
                    }} />
                  </div>
                </div>
              </div>
            })}
          </div>

          <div className='approve_detail_bar' style={{ padding: '12px 16px 12px' }}>
            <div className='approve_detail_radio'>
              <div>验收结果</div>
              <div>
                <Radio.Group onChange={(e) => {
                  this.setState({
                    state: e.target.value
                  })
                }}>
                  <Radio value={1}>整改合格</Radio>
                  <Radio value={2}>整改不合格</Radio>
                </Radio.Group>
              </div>
            </div>

            <div className='approve_detail_textarea'>
              <div>验收描述</div>
              <div className='approve_detail_textareaItem'>
                <TextareaItem
                  rows={3}
                  placeholder="请输入验收描述"
                  onChange={e => this.setState({ checkContent: e })}
                />
              </div>
            </div>

          </div>
        </div>
        <div className='approveDetail_btn'>
          <div className='approveDetail_btn_list' onClick={() => this.handleSubmit()}>提交验收</div>
        </div>

        {
          this.state.visible ? <div className='detailImg' onClick={() => this.setState({ visible: false })}>
            {this.state.type === 1 ? (<img alt='' src={IP.fileHost + this.state.fileUrl} style={{ width: '80%' }} />) : (<video width="80%" src={IP.fileHost + this.state.fileUrl} poster={IP.fileHost + this.state.fileUrl} controls="controls" controlslist="nodownload noremoteplayback" disablePictureInPicture>
              your browser does not support the video tag
            </video>)}

          </div> : ''
        }


      </div >
    )
  }
}

export default ApproveDetail
