import React, { Component } from 'react'
import { Flex, ImagePicker, Modal, Toast, TextareaItem, Button, Picker, List } from 'antd-mobile'
import { Radio } from 'antd'
import { findAllMobileType, findAllSpaceInfo, submitZxxj } from '../../../api/api'
import { uploadPatrolImages } from '../../../api/patrol'
import { uploadDisposeFile } from '../../../api/repairApi'
import cookie from 'react-cookies'
import moment from 'moment'
import './create_patrol.less'
const RadioGroup = Radio.Group


class SpecialPatrol extends Component {
  state = {
    project: [], // 选择的巡检项
    patrolType: 0,
    position: [], // 位置
    positionId: [], // 位置id
    roomId: '', // 需传值的位置id
    inspection: [], // 巡检项选择使用
    inspectionData: [], // 巡检项
    inspectionId: [], // 选中的巡检项id
    patrolDesc: '', // 隐患描述
    width: window.innerWidth,
    height: window.innerHeight,
    visible: false,
    base64: '',
    urls: [],
    patrolImgs: [],
    patrolVideo: [],
  }
  componentDidMount = () => {
    document.title = '开始巡检'
    this.fetchTaskDetail()
    this.fetchAllType()
  }
  //进入页面获取该房间的巡检配置情况
  fetchTaskDetail = async () => {
    const res = await findAllSpaceInfo()
    if (res.code === 20000) {
      const data = (res.data || []).map(item => {
        return {
          children: [],
          bs: item.bs,
          label: item.dwmc,
          parentId: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
        }
      })
      const newData = data.map(item => {
        item.children = data.filter(x => x.parentId === item.value)
        return item
      }).filter(x => x.bs === 1)
      this.setState({
        position: newData,
      })
    }
  }
  // 查询巡检项
  fetchAllType = async () => {
    const res = await findAllMobileType()
    if (res.code === 20000) {
      this.setState({
        inspection: (res.data || []).map(x => {
          return {
            label: x.type_name,
            value: x.type_id,
          }
        }),
        inspectionData: (res.data || []).map(x => {
          x.type_content = 0
          return x
        })
      })
    }
  }
  //巡检后，输入内容，提交到隐患库
  taskSubmit = async () => {
    const { roomId, inspectionId, patrolType, patrolDesc, project, urls, base64 } = this.state
    if (!roomId) return Toast.info("请选择巡检点", 1)
    if (!inspectionId.length) return Toast.info("请选择巡检项", 1)
    //根据检查项目是否有隐患，来判断是否传隐患的内容
    const hasPatrol = project.findIndex(p => p.type_content) > -1
    if (hasPatrol) {
      if (!patrolType) {
        Toast.info("请选择隐患类型", 1)
        return false
      }
      if (!patrolDesc) {
        Toast.info("请输入隐患描述", 1)
        return false
      }
    }
    if (!base64) {
      Toast.info("请签字确认", 1)
      return false
    }
    let params = {
      roomId,
      xjx: JSON.stringify(project.map(p => {
        p.type_content = p.type_content || 0
        return p
      })),
      yhlx: hasPatrol ? patrolType : 0,
      xjfj: JSON.stringify(hasPatrol ? urls : []),
      xjqk: hasPatrol ? patrolDesc : '',
      qz: base64
    }
    const res = await submitZxxj(params)
    if (res.code === 20000) {
      Toast.info("提交成功", 1)
      this.props.history.goBack()
    }
  }
  // 选择巡检项
  handleChangeInspection = val => {
    const { inspectionData } = this.state
    this.setState({
      inspectionId: val,
      project: inspectionData.filter(x => x.type_id === val[0])
    })
  }
  //巡检项目选择是否有隐患
  onChangeProject = (e, k) => {
    const { project } = this.state
    this.setState({
      project: project.map((p, i) => {
        if (k === i) {
          p.type_content = e.target.value
        }
        return p
      })
    })
  }
  //隐患类型
  onChangeType = e => {
    this.setState({
      patrolType: e.target.value
    })
  }
  // 图片上传
  upload = (files, type, index) => {
    const { urls, patrolImgs } = this.state
    if (type === "add") {
      Toast.loading("图片上传中,请稍等", 0)
      const imgSize = files[files.length - 1].file.size
      if (imgSize > 10 * 1024 * 1024) return Toast.info('请上传小于10M的图片', 2)
      let formData = new FormData()
      formData.append("multipartFile", files[files.length - 1].file)
      uploadPatrolImages(formData).then(res => {
        this.setState({
          patrolImgs: files,
        })
        if (res.code === 20000) {
          Toast.hide()
          Toast.info("上传成功", 1)
          urls.push({ file_name: files[files.length - 1].file.name, file_url: res.data })
          this.setState({ urls })
        } else {
          Toast.hide()
          Toast.fail(res.message)
        }
      })
    }
    if (type === "remove") {
      this.setState({
        urls: urls.filter((x, indexs) => indexs !== index),
        patrolImgs: patrolImgs.filter((x, indexs) => indexs !== index),
      })
    }
  }
  // 点击签字
  goSign = () => {
    this.setState({
      visible: true
    })
    setTimeout(() => {
      eval("PageReady()")
    }, 500)
  }
  // 清除签字
  handleClearBtnClick = () => {
    eval('clearCanvas()')
  }

  // 签字确认
  handleOk = () => {
    const data = eval('saveSignature()')
    this.setState({
      visible: false
    })
    this.rotateBase64Img(data, 270)
  }
  //将canvas图片转为base64图片
  rotateBase64Img = (src, edg, callback) => {
    const This = this
    var canvas = document.createElement("canvas")
    var ctx = canvas.getContext("2d")
    var imgW//图片宽度
    var imgH//图片高度
    var size//canvas初始大小
    if (edg % 90 != 0) {
      console.error("旋转角度必须是90的倍数!")
      throw '旋转角度必须是90的倍数!'
    }
    (edg < 0) && (edg = (edg % 360) + 360)
    const quadrant = (edg / 90) % 4 //旋转象限
    const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 } //裁剪坐标
    var image = new Image()
    image.crossOrigin = "anonymous"
    image.src = src
    image.onload = function () {
      imgW = image.width
      imgH = image.height
      size = imgW > imgH ? imgW : imgH
      canvas.width = size * 2
      canvas.height = size * 2
      switch (quadrant) {
        case 0:
          cutCoor.sx = size
          cutCoor.sy = size
          cutCoor.ex = size + imgW
          cutCoor.ey = size + imgH
          break
        case 1:
          cutCoor.sx = size - imgH
          cutCoor.sy = size
          cutCoor.ex = size
          cutCoor.ey = size + imgW
          break
        case 2:
          cutCoor.sx = size - imgW
          cutCoor.sy = size - imgH
          cutCoor.ex = size
          cutCoor.ey = size
          break
        case 3:
          cutCoor.sx = size
          cutCoor.sy = size - imgW
          cutCoor.ex = size + imgH
          cutCoor.ey = size + imgW
          break
      }
      ctx.translate(size, size)
      ctx.rotate(edg * Math.PI / 180)
      ctx.drawImage(image, 0, 0)
      var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey)
      if (quadrant % 2 == 0) {
        canvas.width = imgW
        canvas.height = imgH
      } else {
        canvas.width = imgH
        canvas.height = imgW
      }
      ctx.putImageData(imgData, 0, 0)

      //获取旋转后的base64图片
      This.setState({
        base64: canvas.toDataURL(),
      })
    }
  }
  //重签
  reSign = () => {
    this.setState({
      base64: '',
      visible: true,
    })
    setTimeout(() => {
      eval("PageReady()")
    }, 500)
  }
  // 上传文件
  onChangeFile = async (e) => {
    const { urls, patrolVideo } = this.state
    const { files } = e.target
    Toast.loading("视频上传中,请稍等", 0)
    const imgSize = files[files.length - 1].size
    if (imgSize > 20 * 1024 * 1024) return Toast.info('请上传小于20M的附件', 2)
    let formData = new FormData()
    formData.append("multipartFile", files[files.length - 1])
    const res = await uploadDisposeFile(formData)
    if (res.code === 20000) {
      Toast.hide()
      Toast.info("上传成功", 1)
      urls.push({ file_name: files[files.length - 1].name, file_url: res.data })
      patrolVideo.push({ file_name: files[files.length - 1].name, file_url: res.data })
      this.setState({ urls, patrolVideo })
    } else {
      Toast.hide()
      Toast.fail(res.message)
    }
  }
  // 删除视频
  handleRemoveVideo = item => {
    const { urls, patrolVideo } = this.state
    console.log(urls, patrolVideo, item)
    this.setState({
      urls: urls.filter(x => x.file_url !== item.file_url),
      patrolVideo: patrolVideo.filter(x => x.file_url !== item.file_url),
    })
  }
  render() {
    const { position, positionId, inspection, inspectionId, project, patrolType, width, height, visible, base64, detailInfo, patrolImgs, patrolVideo } = this.state

    return (
      <div className='createPatrol'>
        <img src={require('../../../img/patrol_special.png')} width='100%' alt="" />
        <div className="listWrap mt-12">
          <div className='listStylP'>
            <Picker
              extra='请选择巡检点'
              data={position}
              value={positionId}
              cols={3}
              onChange={(val) => {
                this.setState({
                  positionId: val,
                  roomId: val[2]
                })
              }}
            >
              <List.Item arrow="horizontal">巡检点名称</List.Item>
            </Picker>
          </div>
          <div className='listStylP'>
            <Picker
              extra='请选择巡检项'
              data={inspection}
              value={inspectionId}
              cols={1}
              onChange={val => this.handleChangeInspection(val)}
            >
              <List.Item arrow="horizontal">巡检项</List.Item>
            </Picker>
          </div>
          <div className='listStyl'>
            <div className="left">巡检人</div>
            <div className="right">{cookie.load('userData').name}</div>
          </div>
        </div>
        <div className="listWrap mt-12">
          {
            (project || []).map((p, k) => (
              <div className='listStyl' key={k}>
                <div className="left">{p.type_name}</div>
                <div className="right">
                  <RadioGroup onChange={(e) => this.onChangeProject(e, k)} value={p.type_content - 0}>
                    <Radio value={0}>正常</Radio>
                    <Radio value={1}>有隐患</Radio>
                  </RadioGroup>
                </div>
              </div>
            ))
          }
        </div>
        {
          project.findIndex(p => p.type_content) > -1 ?
            <div className="listWrap pb-16 mt-12">
              <div className='listStyl'>
                <div className="left">隐患类型</div>
                <div className="right">
                  <RadioGroup onChange={this.onChangeType} value={patrolType}>
                    <Radio value={1}>一般</Radio>
                    <Radio value={2}>严重</Radio>
                  </RadioGroup>
                </div>
              </div>
              <div className='listStyl'>
                <div className="left">上传图片/视频</div>
              </div>
              <div className='pd-16'>请拍摄设备的检查图片或视频，至少一张（若图片或视频模糊或在对应点视为作废）</div>
              <ImagePicker
                className='potral-upload'
                length="3"
                files={patrolImgs}
                selectable={patrolImgs.length < 3}
                onChange={(files, type, index) => {
                  this.upload(files, type, index)
                }}
              />
              <div className='listStyl_video'>
                <div className='listStyl_video_list' onClick={() => {
                  document.getElementById('handle_video').click()
                }}>上传视频</div>
              </div>
              <div className='potral_video'>
                {
                  (patrolVideo || []).map(item => {
                    return <div className='potral_video_list' key={item.file_url}>
                      <div className='potral_video_list_name'>{item.file_name}</div>
                      <img alt='' src={require('../../../images/del.png')} onClick={() => this.handleRemoveVideo(item)} />
                    </div>
                  })
                }
              </div>
              <div className='listStyl'>
                <div className="left">隐患描述</div>
              </div>
              <TextareaItem
                rows={5}
                placeholder="请输入隐患描述"
                onChange={e => this.setState({ patrolDesc: e })}
              />
            </div> : null
        }
        <div className="listWrap mt-12">
          <div className='listStyl'>
            <div className="left">检查人员签字确认</div>
          </div>
          <div className='pd-16'>
            {
              base64 ?
                <div>
                  <Flex>
                    <Flex.Item ><img src={base64} alt="" width={`100%`} /></Flex.Item>
                    <Flex.Item style={{ textAlign: 'center' }}><Button inline size="small" className="light-green-bg-button" onClick={this.reSign}>重签</Button></Flex.Item>
                  </Flex>
                </div>
                :
                <Button className='grey-text-button' onClick={this.goSign}>点击签字</Button>
            }
          </div>
          <div className='listStyl'>
            <div className="left">检查时间</div>
            <div className="right">{moment(new Date()).format('YYYY-MM-DD HH:mm')}</div>
          </div>
        </div>

        <Flex className='btn-group mt-12'>
          <Flex.Item onClick={this.taskSubmit}><Button className='green-bg-button'>提交</Button></Flex.Item>
        </Flex>


        <Modal
          visible={visible}
          transparent
          onClose={() => this.setState({ visible: false })}
          style={{ width: '100%', height: '100%', background: '#fff' }}
        >
          <div>
            <section className="signature-component" >
              <div id='signature-pad' className="js-signature" data-width={width * 0.7} data-height={height * 0.8} data-border="1px solid black" data-line-color="#000" data-auto-fit="true"></div>
            </section>
            <div className='sign_btn' style={{ height: `${height * 0.2 - 60}px` }}>
              <div className='approveDetail_btn_list' style={{ width: `${height * 0.2 - 60}px` }} onClick={() => this.handleClearBtnClick()}>清除</div>
              <div className='approveDetail_btn_list' style={{ width: `${height * 0.2 - 60}px` }} onClick={() => this.handleOk()}>确认</div>
            </div>
          </div>
        </Modal>
        <input id='handle_video' type="file" accept="video/*" capture="camcorder" hidden onChange={e => this.onChangeFile(e)} onClick={(event) => { event.target.value = null }} />
      </div>
    )
  }
}
export default SpecialPatrol