import React, { Component } from 'react'
import { Modal, Toast, Flex, Button } from 'antd-mobile'
import { findAuthQz, saveQzInfo } from '../../../../api/whp'
import moment from 'moment'

class deviceDetail extends Component {

  state = {
    data: {},
    visible: false,
    opinion: '',
    signVisible: true,
    base64: '',
    signAddvisible: false,
    width: window.innerWidth,
    height: window.innerHeight,
  }

  componentDidMount = () => {
    document.title = '出库签字'
    this._findAuthQz()
  }

  _findAuthQz = async () => {
    const res = await findAuthQz({ lydh: this.props.match.params.id })
    if (res.code === 20005) {
      this.setState({
        visible: true
      })
    }
  }

  // 点击签字
  goSign = () => {
    this.setState({
      signAddvisible: true
    })
    setTimeout(() => {
      eval("PageReady()")
    }, 500)
  }
  // 清除签字
  handleClearBtnClick = () => {
    eval('clearCanvas()')
  }

  // 签字确认
  handleOk = () => {
    const data = eval('saveSignature()')
    this.setState({
      signAddvisible: false
    })
    this.rotateBase64Img(data, 270)
  }

  //将canvas图片转为base64图片
  rotateBase64Img = (src, edg, callback) => {
    const This = this
    var canvas = document.createElement("canvas")
    var ctx = canvas.getContext("2d")
    var imgW//图片宽度
    var imgH//图片高度
    var size//canvas初始大小
    if (edg % 90 != 0) {
      throw '旋转角度必须是90的倍数!'
    }
    (edg < 0) && (edg = (edg % 360) + 360)
    const quadrant = (edg / 90) % 4 //旋转象限
    const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 } //裁剪坐标
    var image = new Image()
    image.crossOrigin = "anonymous"
    image.src = src
    image.onload = function () {
      imgW = image.width
      imgH = image.height
      size = imgW > imgH ? imgW : imgH
      canvas.width = size * 2
      canvas.height = size * 2
      switch (quadrant) {
        case 0:
          cutCoor.sx = size
          cutCoor.sy = size
          cutCoor.ex = size + imgW
          cutCoor.ey = size + imgH
          break
        case 1:
          cutCoor.sx = size - imgH
          cutCoor.sy = size
          cutCoor.ex = size
          cutCoor.ey = size + imgW
          break
        case 2:
          cutCoor.sx = size - imgW
          cutCoor.sy = size - imgH
          cutCoor.ex = size
          cutCoor.ey = size
          break
        case 3:
          cutCoor.sx = size
          cutCoor.sy = size - imgW
          cutCoor.ex = size + imgH
          cutCoor.ey = size + imgW
          break
      }
      ctx.translate(size, size)
      ctx.rotate(edg * Math.PI / 180)
      ctx.drawImage(image, 0, 0)
      var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey)
      if (quadrant % 2 == 0) {
        canvas.width = imgW
        canvas.height = imgH
      } else {
        canvas.width = imgH
        canvas.height = imgW
      }
      ctx.putImageData(imgData, 0, 0)

      //获取旋转后的base64图片
      This.setState({
        base64: canvas.toDataURL(),
      })
    }
  }
  //重签
  reSign = () => {
    this.setState({
      base64: '',
      signAddvisible: true,
    })
    setTimeout(() => {
      eval("PageReady()")
    }, 500)
  }

  // 签字提交
  subSign = async () => {
    const { base64 } = this.state
    if (base64 === '') {
      return Toast.info('请签字!')
    }
    const res = await saveQzInfo({
      lydh: this.props.match.params.id,
      qzurl: base64,
    })
    if (res.code === 20000) {
      this.setState({
        visible: true
      })
      Toast.info(res.message)
    }
  }

  render() {
    const { data, base64, signAddvisible, width, height } = this.state
    return (
      <div className='approveDetail' style={{ padding: '1px 0px 76px' }}>
        <div className='my_modal_height'>
          {
            base64 ?
              <div>
                <img src={base64} alt="" width={`92%`} />
              </div>
              : ''
          }

          <div className='my_modal_height_operate' style={{ display: 'flex', justifyContent: 'center' }}>
            {base64 ? (<Button inline size="small" className="grey-text-button" onClick={this.reSign}>重签</Button>) : (<Button inline size="small" className='grey-text-button' onClick={this.goSign}>点击签字</Button>)}
          </div>
        </div>

        <div className='approveDetail_btn'>
          <div className='approveDetail_btn_list' onClick={() => {
            if (this.state.visible) {
              Toast.info("请勿重复签字！")
              return false
            }
            this.subSign()
          }}>签字提交</div>
        </div>



        <Modal
          visible={signAddvisible}
          transparent
          onClose={() => this.setState({ signAddvisible: false })}
          style={{ width: '100%', height: '100%', background: '#fff' }}
        >
          <div>
            <section className="signature-component" >
              <div id='signature-pad' className="js-signature" data-width={width * 0.7} data-height={height * 0.8} data-border="1px solid black" data-line-color="#000" data-auto-fit="true"></div>
            </section>
            <div className='sign_btn' style={{ height: `${height * 0.2 - 60}px` }}>
              <div className='approveDetail_btn_list' style={{ width: `${height * 0.2 - 60}px` }} onClick={() => this.handleClearBtnClick()}>清除</div>
              <div className='approveDetail_btn_list' style={{ width: `${height * 0.2 - 60}px` }} onClick={() => this.handleOk()}>确认</div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default deviceDetail