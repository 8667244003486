import React, { Component } from 'react'
import { Select } from 'antd'
import { Toast, Modal, Icon, TextareaItem } from 'antd-mobile'
import { listApprovalRecordById, findMasterProcess, findNextApprovalUser, estimateCountersign, submitApprovalResult } from '../../../../api/fqw'
import IP from './../../../../config/config'
import moment from 'moment'

class deviceDetail extends Component {

  state = {
    data: {},
    approveVisible: false,
    opinion: '',
    masterProcess: 0,
    spry: undefined,
    approveUser: [],
    remark: '',
    result: 2,
    countersign: 0
  }

  componentDidMount = () => {
    document.title = '审批详情'
    window.scrollTo(0, 0)
    this.fetch(this.props.match.params.id)
  }

  fetch = async id => {
    const res = await listApprovalRecordById({ id })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }

  // 判断审批是多人共同审批还是单人审批
  handleMasterProcess = async () => {
    const { data } = this.state
    const res = await findMasterProcess({ dh: data.register_code })
    if (res.code === 20000) {
      this.setState({
        masterProcess: res.data
      }, () => {
        if (data.process_state) {
          this.fetchEstimateCountersign()
        } else {
          this.handleNextApprovalUser(data.register_code, data.room_id)
        }
      })
    }
  }

  // 会签审批判断是否是最后一个人审批（共同审批）
  fetchEstimateCountersign = async () => {
    const { data } = this.state
    const res = await estimateCountersign({ prId: data.pr_id })
    if (res.code === 20000) {
      this.setState({
        countersign: res.data,
      }, () => {
        this.handleNextApprovalUser(data.register_code, data.room_id)
      })
    }
  }

  // 获取下一个审批人员信息
  handleNextApprovalUser = async (recordid, roomId) => {
    const { masterProcess } = this.state
    const res = await findNextApprovalUser({ recordid, roomId })
    if (res.code === 20000) {
      this.setState({
        approveUser: res.data,
        approveVisible: true,
      }, () => {
        if (masterProcess) {
          this.setState({
            spry: (res.data || []).map(x => x.gh)
          })
        }
      })
    }
  }

  handleApproveOk = async () => {
    const { result, remark, data, countersign, spry, masterProcess, approveUser } = this.state
    if (result === 3) {
      if (remark === '') return Toast.info('请输入驳回原因！')
    } else {
      if (data.pr_status !== 999999) {
        if (data.process_state) {
          if (countersign) {
            if (spry) {
              if (spry === undefined) return Toast.info('请选择审批人！')
            } else {
              return Toast.info('请选择审批人！')
            }
          }
        } else {
          if (spry) {
            if (spry === undefined) return Toast.info('请选择审批人！')
          } else {
            return Toast.info('请选择审批人！')
          }
        }
      }
    }
    Toast.loading('提交中...', 0)
    const res = await submitApprovalResult({
      flag: 1, prIds: data.pr_id, remark: remark, result,
      spUserInfo: data.pr_status !== 999999 ? (
        masterProcess ? (
          data.process_state ? (
            countersign ? (JSON.stringify(approveUser)) : null
          ) : (JSON.stringify(approveUser))
        ) : (
          data.process_state ? (
            countersign ? (JSON.stringify(approveUser.filter(x => x.gh === spry))) : null
          ) : (JSON.stringify(approveUser.filter(x => x.gh === spry)))
        )
      ) : null
    })
    if (res.code === 20000) {
      this.setState({
        approveVisible: false,
      })
      this.fetch(this.props.match.params.id)
      Toast.info("提交成功！")
      setTimeout(() => {
        this.props.history.goBack()
      }, 2000);
    }
  }

  render() {
    const { data, approveVisible, masterProcess, spry, approveUser, remark, result, countersign } = this.state
    return (
      <div className='approveDetail' style={{ padding: '0px 0px 76px' }}>
        <div className={data.pr_approval_result === 2 ? 'approve_detail_head' : 'approve_detail_head approve_detail_head_no'}>
          <div className='approve_detail_left'>
            <div className='approve_detail_left_title'>
              {data.ssxy_mc}-{data.sszx_mc}-{data.room_name}
            </div>
            <div className='approve_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='approve_list_head_date'>
                {data.principal_name}
              </div>
            </div>
          </div>
          <div className='approve_detail_right'>
            {data.pr_approval_result === 1 ? (<img src={require('./../../../../images/chemicals/06.png')} style={{ width: '90px' }} alt='' />) : ('')}
            {data.pr_approval_result === 0 ? (<img src={require('./../../../../images/chemicals/07.png')} style={{ width: '90px' }} alt='' />) : ('')}
            {data.pr_approval_result === 2 ? (<img src={require('./../../../../images/chemicals/08.png')} style={{ width: '90px' }} alt='' />) : ('')}
          </div>
        </div>
        <div className='approve_detail_cont'>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>登记单号</div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {data.register_code}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>所属学院</div>
              <div>{data.ssxy_mc}</div>
            </div>
            <div className='approve_detail_item'>
              <div>所属中心</div>
              <div>{data.sszx_mc}</div>
            </div>
            <div className='approve_detail_item'>
              <div>所属实验室</div>
              <div>{data.room_name}</div>
            </div>
          </div>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>负责人</div>
              <div>
                {data.principal_name}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>登记人</div>
              <div>
                {data.dispose_name}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>登记时间</div>
              <div>
                {data.create_time ? moment(data.create_time).format('YYYY-MM-DD HH:mm') : ''}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>预处理时间</div>
              <div>
                {data.predict_dispose_time ? moment(data.predict_dispose_time).format('YYYY-MM-DD HH:mm') : ''}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>存放地点</div>
              <div>
                {data.specific_location}
              </div>
            </div>
            <div className='approve_detail_item'>
              <div>备注</div>
              <div>
                {data.register_descr}
              </div>
            </div>
          </div>
          <div className='subscription_list_card'>
            {data.registerDetailRecordEntities && data.registerDetailRecordEntities.map((item, key) => {
              return (
                <div className='subscription_list_card_item' style={{ background: '#fff' }} key={key}>
                  <div className='subscription_list_card_head'>
                    <div className='subscription_list_card_head_left'>
                      <div>{key + 1}</div>
                      <div>{item.waste_name}</div>
                    </div>
                    <div className='subscription_list_card_class'>
                      {item.bucket_name}
                    </div>
                  </div>
                  <div className='subscription_list_card_company'>
                    <div>{item.register_code}</div>
                  </div>
                  <div className='subscription_list_card_unit'>
                    <div>{item.waste_form_name}</div>
                    <div>{item.waste_hazard_name}</div>
                    <div>{item.waste_pack_name}</div>
                  </div>
                  <div className='subscription_list_card_num'>
                    <div className='subscription_list_card_num_item'>
                      <div>{item.total_weight}</div>
                      <div>总重量(kg)</div>
                    </div>
                    <div className='subscription_list_card_num_item'>
                      <div>{item.unit_price}</div>
                      <div>处理单价(/kg)</div>
                    </div>
                    <div className='subscription_list_card_num_item'>
                      <div>{item.pack_num}</div>
                      <div>包装数量</div>
                    </div>
                    <div className='subscription_list_card_num_item'>
                      <div>{item.total_money}</div>
                      <div>处理金额</div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        {
          data.pr_approval_result === 1 ? <div className='approveDetail_btn'>
            <div className='approveDetail_btn_list' onClick={() => {
              this.setState({
                approveVisible: true,
                result: 3
              }, () => {
                this.handleMasterProcess()
              })
            }}>审批驳回</div>
            <div className='approveDetail_btn_list' onClick={() => {
              this.setState({
                approveVisible: true,
                result: 2
              }, () => {
                this.handleMasterProcess()
              })
            }}>审批通过</div>
          </div> : ''
        }
        {/* 选择审批人 */}
        <Modal
          popup
          visible={approveVisible}
          className='subscription_modal'
          onClose={() => {
            this.setState({
              approveVisible: false
            })
          }}
          animationType="slide-up"
        >
          <div className='subscription_add_modal subscription_add_modal_approve'>
            <div className='subscription_add_modal_head'>
              <div>审批{result === 2 ? '通过' : '驳回'}</div>
              <div>
                <Icon type='cross' onClick={() => {
                  this.setState({
                    approveVisible: false
                  })
                }} />
              </div>
            </div>
            {
              data.pr_status !== 999999 && result === 2 ? (
                // 下一步多人审批
                masterProcess ? (
                  // 当前是不是共同审批
                  data.process_state ? (
                    // 是不是最后一个人
                    countersign ? <div className='subscription_add_select'>
                      <div>
                        {result === 2 ? <span style={{ color: 'red' }}>* </span> : <span style={{ color: 'white' }}>* </span>}审批人员
                      </div>
                      <Select mode='multiple' disabled allowClear showSearch optionFilterProp="children" value={spry} placeholder='请选择' onChange={(e) => {
                        this.setState({
                          spry: e
                        })
                      }}>
                        {
                          (approveUser || []).map(item => {
                            return <Select.Option key={item.gh} value={item.gh}>{item.xm}</Select.Option>
                          })
                        }
                      </Select>
                    </div> : ''
                  ) : (
                    <div className='subscription_add_select'>
                      <div>
                        {result === 2 ? <span style={{ color: 'red' }}>* </span> : <span style={{ color: 'white' }}>* </span>}审批人员
                      </div>
                      <Select mode='multiple' disabled allowClear showSearch optionFilterProp="children" value={spry} placeholder='请选择' onChange={(e) => {
                        this.setState({
                          spry: e
                        })
                      }}>
                        {
                          (approveUser || []).map(item => {
                            return <Select.Option key={item.gh} value={item.gh}>{item.xm}</Select.Option>
                          })
                        }
                      </Select>
                    </div>
                  )
                ) : (
                  // 下一步是单人审批
                  data.process_state ? (
                    countersign ? <div className='subscription_add_select'>
                      <div>
                        {result === 2 ? <span style={{ color: 'red' }}>* </span> : <span style={{ color: 'white' }}>* </span>}审批人员
                      </div>
                      <Select allowClear showSearch optionFilterProp="children" value={spry} placeholder='请选择' onChange={(e) => {
                        this.setState({
                          spry: e
                        })
                      }}>
                        {
                          (approveUser || []).map(item => {
                            return <Select.Option key={item.gh} value={item.gh}>{item.xm}</Select.Option>
                          })
                        }
                      </Select>
                    </div> : ''
                  ) : (
                    <div className='subscription_add_select'>
                      <div>
                        {result === 2 ? <span style={{ color: 'red' }}>* </span> : <span style={{ color: 'white' }}>* </span>}审批人员
                      </div>
                      <Select allowClear showSearch optionFilterProp="children" value={spry} placeholder='请选择' onChange={(e) => {
                        this.setState({
                          spry: e
                        })
                      }}>
                        {
                          (approveUser || []).map(item => {
                            return <Select.Option key={item.gh} value={item.gh}>{item.xm}</Select.Option>
                          })
                        }
                      </Select>
                    </div>
                  )
                )
              ) : ''
            }
            <div className='approve_text'>
              <div>  {result === 3 ? <span style={{ color: 'red' }}>* </span> : <span style={{ color: 'white' }}>* </span>}审批意见</div>
              <TextareaItem
                placeholder="请输入"
                rows={5}
                value={remark}
                onChange={(e) => {
                  this.setState({
                    remark: e
                  })
                }}
              />
            </div>
            <div className='subscription_list_but'>
              <div onClick={() => {
                this.handleApproveOk()
              }}>提交审批</div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default deviceDetail