import React, { Component } from 'react'
import { DatePicker, List, Toast } from 'antd-mobile'
import { statisticsRoomData } from '../../../api/api'
import moment from 'moment'
import IP from '../../../config/config'
import './index.less'

class UseStatistics extends Component {

  state = {
    data: [],
    startTime: '',
    endTime: '',
  }

  componentDidMount = () => {
    this.setState({
      startTime: new Date(this.fun_date(-6)),
      endTime: new Date(),
    }, () => {
      this.fetch(moment(new Date(this.fun_date(-6))).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'))
    })
  }

  fetch = async (startTime, endTime) => {
    Toast.loading('加载中...', 0)
    const res = await statisticsRoomData({ flag: 1, startTime, endTime })
    if (res.code === 20000) {
      Toast.hide()
      this.setState({
        data: res.data
      })
    }
  }

  down = roomId => {
    const { startTime, endTime } = this.state
    window.location.href = `${IP.host}/usagerecord/statisticsapplabrecord/exportRoomDataExcel?roomId=${roomId ? roomId : ''}&startTime=${moment(startTime).format('YYYY-MM-DD')}&endTime=${moment(endTime).format('YYYY-MM-DD')}`
  }

  // 获取几天前的日期
  fun_date = (num) => {
    var date1 = new Date()
    var date2 = new Date(date1)
    date2.setDate(date1.getDate() + num)
    //num是正数表示之后的时间，num负数表示之前的时间，0表示今天
    var time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate()
    return time2
  }

  render() {
    const { startTime, endTime, data } = this.state
    return (
      <div className='use'>
        <div className='use_con'>
          <div className='use_time'>
            <div className='stat_card2_time'>
              <DatePicker
                mode="date"
                title="开始时间"
                extra="开始时间"
                value={startTime}
                maxDate={endTime ? endTime : null}
                onChange={date => {
                  this.setState({
                    startTime: date
                  }, () => {
                    this.fetch(moment(this.state.startTime).format('YYYY-MM-DD'), moment(this.state.endTime).format('YYYY-MM-DD'))
                  })
                }}
              >
                <List.Item arrow="horizontal"></List.Item>
              </DatePicker>
              <div style={{ background: '#f4f6f8' }}>~</div>
              <DatePicker
                mode="date"
                title="结束时间"
                extra="结束时间"
                value={endTime}
                minDate={startTime ? startTime : null}
                onChange={date => {
                  this.setState({
                    endTime: date
                  }, () => {
                    this.fetch(moment(this.state.startTime).format('YYYY-MM-DD'), moment(this.state.endTime).format('YYYY-MM-DD'))
                  })
                }}
              >
                <List.Item arrow="horizontal"></List.Item>
              </DatePicker>
            </div>
          </div>
          <div className='use_table'>
            <table border="1" bordercolor='#fff'>
              <thead>
                <tr>
                  <th>实验室</th>
                  <th>任课教师</th>
                  <th>上课课程</th>
                  <th>上课班级</th>
                  <th>上课专业</th>
                  <th>上课院系</th>
                  <th>上课人次</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                {
                  (data || []).length ? data.map(item => {
                    return <tr key={item.area_id}>
                      <td>{item.area_name}</td>
                      <td>{item.jiaoshi}</td>
                      <td>{item.kecheng}</td>
                      <td>{item.banji}</td>
                      <td>{item.zuanye}</td>
                      <td>{item.yuanxi}</td>
                      <td>{item.xuesheng}</td>
                      <td onClick={() => this.down(item.area_id)}>导出</td>
                    </tr>
                  }) : <tr>
                    <td colSpan={8}>--暂无数据--</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
          <div className='use_btn'>
            <div className='use_btn_list' onClick={() => this.down()}>导出使用统计</div>
          </div>
        </div>
      </div>
    )
  }
}

export default UseStatistics