import React, { Component } from 'react'
import { DatePicker, List } from 'antd-mobile'
import { findCmdRecordZnkzq } from '../../../api/api'
import moment from 'moment'
import './controller.less'

class ControllerOperation extends Component {

  state = {
    start_date: '', // 开始时间
    end_date: '', // 结束时间
    relayMac: '',
    deviceNumber: '',
    data: [],
  }

  componentDidMount = () => {
    document.title = "智能终端-操作记录"
    this.setState({
      start_date: new Date(this.fun_date(-2)),
      end_date: new Date(),
      relayMac: this.props.match.params.mac,
      deviceNumber: this.props.match.params.number,
    }, () => {
      this.fetch()
    })
  }

  fetch = async () => {
    const { relayMac, deviceNumber, start_date, end_date } = this.state
    const res = await findCmdRecordZnkzq({ relayMac, deviceNumber, startTime: start_date ? moment(start_date).format('YYYY-MM-DD') : '', endTime: end_date ? moment(end_date).format('YYYY-MM-DD') : '' })
    if (res.code === 20000) {
      this.setState({
        data: res.data
      })
    }
  }

  // 获取几天前的日期
  fun_date = (num) => {
    var date1 = new Date();
    var date2 = new Date(date1);
    date2.setDate(date1.getDate() + num);
    //num是正数表示之后的时间，num负数表示之前的时间，0表示今天
    var time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();
    return time2;
  }

  render() {
    const { start_date, end_date, data } = this.state
    return (
      <div className='controllerOperation'>
        <div className='controllerOperation_head'>
          <div className='controllerOperation_head_date'>
            <DatePicker
              mode="date"
              title="开始时间"
              extra="开始时间"
              value={start_date}
              maxDate={end_date ? end_date : null}
              onChange={date => {
                this.setState({
                  start_date: date
                }, () => {
                  this.fetch()
                })
              }}
            >
              <List.Item arrow="horizontal"></List.Item>
            </DatePicker>
            <div style={{ background: '#f4f6f8' }}>~</div>
            <DatePicker
              mode="date"
              title="结束时间"
              extra="结束时间"
              value={end_date}
              minDate={start_date ? start_date : null}
              onChange={date => {
                this.setState({
                  end_date: date
                }, () => {
                  this.fetch()
                })
              }}
            >
              <List.Item arrow="horizontal"></List.Item>
            </DatePicker>
          </div>
        </div>
        <div className='controllerOperation_body'>
          {
            (data || []).length ? data.map(item => {
              return <div className='controllerOperation_body_list' key={item.record_id}>
                <div className='controllerOperation_body_list_con'>
                  <div className='controllerOperation_body_list_con_name'>{item.operate_user_name}</div>
                  <div className='controllerOperation_body_list_con_time'>
                    {item.create_time ? moment(item.create_time).format('YYYY-MM-DD HH:mm:ss') : moment(new Date()).format('YYYY-MM-DD HH:mm:ss')}
                  </div>
                </div>
                <div className={`controllerOperation_body_list_btn ${item.device_cmd === '开' ? 'btn_open' : 'btn_close'}`}>{item.device_cmd}</div>
              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
      </div>
    )
  }
}

export default ControllerOperation
