import React, { Component } from 'react'
import { Toast, Icon } from 'antd-mobile'
import cookie from 'react-cookies'
import { uploadRlcj } from '../../../api/api'
import H5Cropper from 'h5-cropper'
import './index.less'

class Cropping extends Component {

  state = {
    imgUrl: '',
  }

  componentDidMount = () => {
    const record = this.props.location.state
    if (record) {
      this.setState({
        imgUrl: record.imgUrl
      }, () => {
        this.handleCrop()
      })
    }
  }

  // 裁剪图片
  handleCrop = () => {
    const { imgUrl } = this.state
    const that = this
    const cropper = new H5Cropper(imgUrl, function (base64) {
      // to do something
      that.setState({
        imgUrl: base64
      })
    })
  }

  // 上传照片
  handleSubmit = async () => {
    const { imgUrl } = this.state
    const res = await uploadRlcj({ base64String: imgUrl, account: cookie.load('userData').id })
    if (res.code === 20000) {
      Toast.info(res.message)
      setTimeout(() => {
        this.props.history.push('/home')
      }, 1500)
    }
  }

  // 回退重新上传
  handleGoBack = () => {
    this.props.history.go(-1)
  }

  render () {
    const { imgUrl } = this.state
    return (
      <div className='qrImage'>
        <div className='qrImage_title'>请采集照片</div>
        <div className='qrImage_con'>
          <div className='qrImage_con_photo'>
            <div className='qrImage_con_photo_close' onClick={() => this.handleGoBack()}><Icon type='cross-circle-o' /></div>
            <img alt='' src={imgUrl} />
          </div>
        </div>
        <div className='cropping_footer'>
          <div className='cropping_btn_edit' onClick={() => this.handleCrop()}>编辑图片</div>
          <div className='cropping_btn_sub' onClick={() => this.handleSubmit()}>上传照片</div>
        </div>
      </div>
    )
  }
}

export default Cropping