import React, { Component } from 'react'
import { findDtuSwitchDevice } from '../../../api/api'
import './room_air_switch.less'

class RoomAirSwitch extends Component {

  state = {
    roomInfo: {},
    data: [],
  }

  componentDidMount = () => {
    document.title = "智能空开"
    this.fetch(this.props.match.params.id)
    this.setState({
      roomInfo: this.props.location.state.roomInfo,
    })
  }

  fetch = async roomId => {
    const res = await findDtuSwitchDevice({ roomId })
    if (res.code === 20000) {
      this.setState({
        data: res.data,
      })
    }
  }

  render() {
    const { roomInfo, data } = this.state
    return (
      <div className='roomAirSwitch'>
        <div className='shortTerm_name'>{roomInfo.wzName}</div>
        <div className='shortTerm_addr'>{roomInfo.buildingName ? roomInfo.buildingName.split('/')[0] : ''}</div>
        <div className='roomAirSwitch_con'>
          {
            (data || []).length ? data.map(item => {
              return <div className='electricity_con_list' key={item.dtu_id} onClick={() => this.props.history.push({ pathname: `/module/electricity/airSwitch/${item.dtu_id}`, state: { item } })}>
                <div className='electricity_con_list_name'>{item.dtu_name}</div>
                <div className='electricity_con_list_addr'>
                  {item.building_name}/{item.floor_name}/{item.room_name}
                </div>
                <div className='electricity_con_list_desc'>
                  {
                    item.dtu_status === 1 ? <div className='roomAirSwitch_con_all_type_state roomDevice_con_list_desc_state state_online'>
                      <span></span>
                      <span>设备在线</span>
                    </div> : <div className='roomAirSwitch_con_all_type_state roomDevice_con_list_desc_state state_offline'>
                      <span></span>
                      <span>设备离线</span>
                    </div>
                  }
                  <div className='electricity_con_list_desc_num'>
                    {
                      item.startNum ? <span className='electricity_con_list_desc_num_yes'>已开启 {item.startNum}/{item.totalNum}</span> : <span className='electricity_con_list_desc_num_no'>未开启 0/{item.totalNum ? item.totalNum : 0}</span>
                    }
                  </div>
                </div>
              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
      </div>
    )
  }
}

export default RoomAirSwitch
