import React, { Component } from 'react'
import { Toast, Modal, TextareaItem } from 'antd-mobile'
import { approvalDetailsDevice, submitResultDevice } from '../../../api/api'
import moment from 'moment'
import './approve.less'

class deviceDetail extends Component {

  state = {
    data: {},
    visible: false,
    opinion: '',
  }

  componentDidMount = () => {
    this.fetch(this.props.match.params.id)
  }

  fetch = async prId => {
    const res = await approvalDetailsDevice({ prId })
    if (res.code === 20000) {
      this.setState({
        data: res.data || {}
      })
    }
  }

  // 审核成功
  handleSuccess = async () => {
    const { data } = this.state
    const res = await submitResultDevice({ pr_id: data.pr_id, pr_approval_result: 2 })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.props.history.go(-1)
    }
  }

  // 审核驳回弹窗
  handleFailModal = () => {
    this.setState({
      visible: true
    })
  }

  // 输入审批意见
  onChangeOpinion = e => {
    this.setState({
      opinion: e
    })
  }

  // 提交审批驳回
  handleSubmit = async () => {
    const { data, opinion } = this.state
    if (!opinion) return Toast.info('请输入驳回意见！')
    const res = await submitResultDevice({
      pr_id: data.pr_id,
      pr_approval_result: 3,
      pr_approval_opinion: opinion
    })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.props.history.go(-1)
    }
  }

  render() {
    const { data, visible } = this.state
    return (
      <div className='approveDetail' style={{ padding: '0px' }}>
        <div className='approve_detail_head'>
          <div className='approve_detail_left'>
            <div className='approve_detail_left_title'>
              {data.reservationist}
            </div>
            <div className='approve_list_head_desc' style={{ paddingLeft: '0px', marginTop: '8px' }}>
              <div className='approve_list_head_date'>
                {data.dep_name}
              </div>
              <div className='approve_list_head_seat'>{data.yxmc}-{data.zymc}</div>
            </div>
          </div>
          <div className='approve_detail_right'>
            {data.pr_approval_result === 1 ? (<img src={require('./../../../images/new/21.png')} alt='' />) : ('')}
          </div>
        </div>
        <div className='approve_detail_cont'>
          <div className='approve_detail_bar'>
            <div className='approve_detail_item'>
              <div>设备名称</div>
              <div>{data.equipment_name}</div>
            </div>
            <div className='approve_detail_item'>
              <div>姓名</div>
              <div>{data.reservationist}</div>
            </div>
            <div className='approve_detail_item'>
              <div>性别</div>
              <div>{data.reservationist_sex ? '男' : '女'}</div>
            </div>
            <div className='approve_detail_item'>
              <div>学号</div>
              <div>{data.dep_number}</div>
            </div>
            <div className='approve_detail_item'>
              <div>院系</div>
              <div>{data.yxmc}</div>
            </div>
            <div className='approve_detail_item'>
              <div>专业</div>
              <div>{data.zymc}</div>
            </div>
            <div className='approve_detail_item'>
              <div>班级</div>
              <div>{data.bjmc}</div>
            </div>
            <div className='approve_detail_item'>
              <div>联系方式</div>
              <div>{data.reservationist_phone}</div>
            </div>
          </div>

          <div className='approve_detail_bar'>
            {data.pr_approval_time ? (<div className='approve_detail_item'>
              <div>审批时间</div>
              <div>
                {moment(data.pr_approval_time).format('YYYY/MM/DD HH:mm')}
              </div>
            </div>) : ('')}

            {
              data.pr_approval_result === 2 ? (<div className='approve_detail_item'>
                <div>审批操作</div>
                <div>
                  审核成功
                </div>
              </div>) : ('')
            }
            {
              data.pr_approval_result === 3 ? (<div className='approve_detail_item'>
                <div>审批操作</div>
                <div>
                  审核驳回
                </div>
              </div>) : ('')
            }
            {
              data.pr_approval_result === 3 ? (<div className='approve_detail_item'>
                <div>驳回原因</div>
                <div>
                  <TextareaItem
                    rows={5}
                    placeholder=""
                    value={data.sp_remark}
                  />
                </div>
              </div>) : ('')
            }
          </div>
        </div>
        {/* <div className='approveDetail_con'>
          <div className='approveDetail_detail'>
            <div className='approveDetail_detail_list'>
              <div className='approveDetail_detail_list_name'>设备名称</div>
              <div className='approveDetail_detail_list_con'>{data.equipment_name}</div>
            </div>
            <div className='approveDetail_detail_list'>
              <div className='approveDetail_detail_list_name'>所属房间</div>
              <div className='approveDetail_detail_list_con'>{data.room_name}</div>
            </div>
            <div className='approveDetail_detail_list'>
              <div className='approveDetail_detail_list_name'>预约时间</div>
              <div className='approveDetail_detail_list_con'>{data.date_time} {data.startTimes}-{data.endTimes}</div>
            </div>
            <div className='approveDetail_detail_list'>
              <div className='approveDetail_detail_list_name'>姓名</div>
              <div className='approveDetail_detail_list_con'>{data.reservationist}</div>
            </div>
            <div className='approveDetail_detail_list'>
              <div className='approveDetail_detail_list_name'>性别</div>
              <div className='approveDetail_detail_list_con'>{data.reservationist_sex ? '男' : '女'}</div>
            </div>
            <div className='approveDetail_detail_list'>
              <div className='approveDetail_detail_list_name'>学号</div>
              <div className='approveDetail_detail_list_con'>{data.reservationist_number}</div>
            </div>
            {
              data.yxmc ? <div className='approveDetail_detail_list'>
                <div className='approveDetail_detail_list_name'>院系</div>
                <div className='approveDetail_detail_list_con'>{data.yxmc}</div>
              </div> : <div className='appointmentDetail_detail_list'>
                <div className='approveDetail_detail_list_name'>部门</div>
                <div className='approveDetail_detail_list_con'>{data.dep_name}</div>
              </div>
            }
            {
              data.zymc ? <div className='approveDetail_detail_list'>
                <div className='approveDetail_detail_list_name'>专业</div>
                <div className='approveDetail_detail_list_con'>{data.zymc}</div>
              </div> : ''
            }
            {
              data.yxmc ? <div className='approveDetail_detail_list'>
                <div className='approveDetail_detail_list_name'>班级</div>
                <div className='approveDetail_detail_list_con'>{data.dep_name}</div>
              </div> : ''
            }
            <div className='approveDetail_detail_list'>
              <div className='approveDetail_detail_list_name'>提交时间</div>
              <div className='approveDetail_detail_list_con'>{
                data.submit_time ? moment(data.submit_time).format('YYYY/MM/DD HH:mm') : moment(new Date()).format('YYYY/MM/DD HH:mm')
              }</div>
            </div>
            {
              data.pr_approval_time ? <div className='approveDetail_detail_list'>
                <div className='approveDetail_detail_list_name'>审批时间</div>
                <div className='approveDetail_detail_list_con'>
                  {moment(data.pr_approval_time).format('YYYY/MM/DD HH:mm')}</div>
              </div> : ''
            }
            {
              data.pr_approval_result === 2 && <div className='approveDetail_detail_list'>
                <div className='approveDetail_detail_list_name'>审批操作</div>
                <div className='approveDetail_detail_list_con'>审核成功</div>
              </div>
            }
            {
              data.pr_approval_result === 3 && <div className='approveDetail_detail_list'>
                <div className='approveDetail_detail_list_name'>审批操作</div>
                <div className='approveDetail_detail_list_con'>审核驳回</div>
              </div>
            }
            {
              data.pr_approval_result === 3 && <div className='approveDetail_detail_list_textarea'>
                <div className='approveDetail_detail_list_textarea_title'>驳回原因</div>
                <TextareaItem
                  rows={5}
                  placeholder="说点什么吧~"
                  value={data.pr_approval_opinion}
                />
              </div>
            }
          </div>
        </div> */}
        {
          data.pr_approval_result === 1 ? <div className='approveDetail_btn'>
            <div className='approveDetail_btn_list' onClick={() => this.handleFailModal()}>审核驳回</div>
            <div className='approveDetail_btn_list' onClick={() => this.handleSuccess()}>审核通过</div>
          </div> : ''
        }
        <Modal
          visible={visible}
          transparent
          onClose={() => {
            this.setState({
              visible: false,
              opinion: '',
            })
          }}
          title="审批意见"
          footer={[{ text: <div className='approve_modal_btn'><div>确定</div></div>, onPress: () => this.handleSubmit() }]}
          className="approve_modal"
        >
          <div>
            <TextareaItem
              rows={5}
              placeholder="说点什么吧~"
              onChange={this.onChangeOpinion}
            />
          </div>
        </Modal>
      </div>
    )
  }
}

export default deviceDetail