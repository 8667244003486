import React, { Component } from 'react'
import { SearchBar, Toast } from 'antd-mobile'
import { Cascader, Field } from 'react-vant'
import { findSpaceInfo, findSubEquipment } from '../../../api/api'
import IP from '../../../config/config'
import './index.less'


class DeviceAppo extends Component {

  state = {
    searchContent: '', // 搜索值
    data: [],
    wt: 236,
    roomData: [], // 位置数据
    buildId: undefined, // 楼宇id
    floorId: undefined, // 楼层id
    roomId: undefined, // 房间id
  }

  componentDidMount = () => {
    document.title = "大仪设备"
    this.setState({
      wt: document.body.clientWidth - 139
    })
    Toast.loading('', 0)
    this.fetch()
    this.fetchRoom()
  }

  fetch = async () => {
    const { searchContent, buildId, floorId, roomId } = this.state
    const res = await findSubEquipment({ spaceId: roomId ? roomId : floorId ? floorId : buildId ? buildId : '', equipmentName: searchContent })
    if (res.code === 20000) {
      Toast.hide()
      this.setState({
        data: res.data
      })
    }
  }

  // 查询位置
  fetchRoom = async () => {
    const res = await findSpaceInfo()
    if (res.code === 20000) {
      let menuList = res.data.map(item => {
        return {
          text: item.dwmc,
          parentId: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
        }
      })
      menuList = menuList.map(item => {
        if (menuList.filter(p => p.parentId === item.value).length) {
          item.children = menuList.filter(p => p.parentId === item.value)
        }
        return item
      }).filter(p => p.parentId === '0')
      this.setState({
        roomData: menuList
      })
    }
  }

  // 搜索房间
  handleChange = e => {
    this.setState({
      searchContent: e
    }, () => {
      this.fetch()
    })
  }

  handleCancel = () => {
    this.setState({
      searchContent: ''
    }, () => {
      this.fetch()
    })
  }

  // 全部选项选择完成
  handleCascaderFinish = (val, selectedRows, actions) => {
    this.setState({
      roomId: val[val.length - 1],
    }, () => {
      this.fetch()
    })
  }

  render () {
    const { data, wt, roomData } = this.state
    return (
      <div className='deviceAppo'>
        <div className='deviceAppo_header'>
          <div className='deviceAppo_search'>
            <SearchBar placeholder="请输入设备信息" onCancel={() => this.handleCancel()} onChange={e => this.handleChange(e)} />
          </div>
          <div className='deviceAppo_title'>
            <span>设备列表</span>
            <div className='deviceAppo_cascader'>
              <Cascader
                popup={{ round: true }}
                title='选择实验室'
                activeColor="#00B4C4"
                options={roomData}
                onFinish={this.handleCascaderFinish}
              >
                {(_, selectedRows, actions) => (
                  <Field
                    isLink
                    value={selectedRows.length > 0 ? selectedRows[selectedRows.length - 1].text : ''}
                    readOnly
                    className='door_lock_cascader'
                    placeholder='全部房间'
                    onClick={() => actions.open()}
                  />
                )}
              </Cascader>
            </div>
          </div>
        </div>
        <div className='deviceAppo_body'>
          {
            (data || []).length ? data.map(item => {
              return <div className='deviceAppo_body_list' key={item.id} onClick={() => this.props.history.push(`/module/deviceDetail/${item.id}`)}>
                <div className='deviceAppo_body_list_l' style={{ width: `${wt}px` }}>
                  <div className='deviceAppo_body_list_l_name'>{item.yqmc}</div>
                  <div className='deviceAppo_body_list_l_addr'>{item.sssysmc}</div>
                  <div className='deviceAppo_body_list_l_type'>
                    <div className='deviceAppo_body_list_l_type_fl'>{item.yqflmc}</div>
                    <div className='deviceAppo_body_list_l_type_bh'>{item.yqbh}</div>
                  </div>
                </div>
                <div className='deviceAppo_body_list_img'>
                  {
                    item.imageurl ? <img alt='' src={IP.fileHost + item.imageurl} /> : <img alt='' src={require('../../../images/008.png')} />
                  }
                </div>
              </div>
            }) : <div className='not-yet'>--暂无数据--</div>
          }
        </div>
      </div>
    )
  }
}

export default DeviceAppo