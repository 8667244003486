import React, { Component } from 'react'
import { Pagination, Icon, Tabs } from 'antd-mobile'
import { Cascader, Field } from 'react-vant'
import { findSpaceInfo, req_findCheckHiddenList } from '../../../api/api'
import './hidden_danger.less'
import moment from 'moment'

class HiddenDanger extends Component {

  state = {
    searchContent: '', // 搜索值
    listData: [], // 空调设备
    roomData: [], // 位置数据
    wt: 265,
    hiddeState: '',
    roomValue: [],
    task_level: 1,
    state: 1,
    tabs: [
      { title: '校级' },
      { title: '院级' },
      { title: '日常' },
      { title: '随手报' },
      { title: '自查' },
      { title: '简报' },

    ],
    tabs2: [
      { title: '待验收' },
      { title: '已验收' },

    ],
    pageNum: 1,
    total: 0,
  }

  componentDidMount = () => {
    document.title = "整改验收"
    this.fetch()
    this.fetchRoom()
    this.setState({
      wt: document.body.clientWidth - 110
    })
  }

  fetch = async () => {
    const { task_level, state, pageNum } = this.state
    const res = await req_findCheckHiddenList({ task_level, state, page: pageNum, size: 20 })
    if (res.code === 20000) {
      this.setState({
        listData: res.data.list,
        pages: res.data ? (res.data.pages ? res.data.pages : 1) : 1,
        total: res.data.total,
      })
    }
  }

  // 查询位置
  fetchRoom = async () => {
    const res = await findSpaceInfo()
    if (res.code === 20000) {
      let menuList = res.data.map(item => {
        return {
          text: item.dwmc,
          parentId: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
        }
      })
      menuList = menuList.map(item => {
        if (menuList.filter(p => p.parentId === item.value).length) {
          item.children = menuList.filter(p => p.parentId === item.value)
        }
        return item
      }).filter(p => p.parentId === '0')
      this.setState({
        roomData: [{
          children: menuList,
          parentId: "-1",
          text: "全部校区",
          value: "-1"
        }]
      })
    }
  }

  // 搜索房间
  handleChange = e => {
    this.setState({
      searchContent: e
    }, () => {
      this.fetch()
    })
  }

  handleCancel = () => {
    this.setState({
      searchContent: ''
    }, () => {
      this.fetch()
    })
  }

  // 选中项变化时触发
  handleCascaderChange = (val, selectedRows, actions) => {
    // console.log(val, selectedRows, actions, 'xxxxxx')
  }

  // 全部选项选择完成
  handleCascaderFinish = (val, selectedRows, actions) => {
    this.setState({
      roomId: val[val.length - 1],
      roomValue: val,
    }, () => {
      this.fetch()
    })
  }
  // 全部选项选择完成
  handleCascaderType = (val, selectedRows, actions) => {
    this.setState({
      hiddeState: val[0],
    }, () => {
      this.fetch()
    })
  }

  render() {
    const { roomData, listData, wt } = this.state
    return (
      <div className='banpai'>
        <div className='banpai_img'>
          <div className='banpai_img_title'>
            <div>整改验收</div>
            <div>整改验收隐患</div>
          </div>
          <img alt='' src={require('../../../img/hidden_danger.png')} />
          <div className='banpai_search'>
            <Tabs tabs={this.state.tabs}
              initialPage={0}
              onChange={(tab, index) => {
                this.setState({
                  task_level: index + 1
                }, () => {
                  this.fetch()
                })
              }}
            >
            </Tabs>

          </div>
          {/* <div className='banpai_search'>
              <SearchBar placeholder="请输入房间名称" onCancel={() => this.handleCancel()} onChange={e => this.handleChange(e)} />
          </div> */}

        </div>
        <div className='banpai_title'>
          <Tabs tabs={this.state.tabs2}
            initialPage={0}
            onChange={(tab, index) => {
              this.setState({
                state: index + 1
              }, () => {
                this.fetch()
              })
            }}
          >
          </Tabs>
          {/* <div className='banpai_cascader'>
            <Cascader
              popup={{ round: true }}
              title='选择实验室'
              activeColor="#00B4C4"
              options={roomData}
              onChange={this.handleCascaderFinish}
              value={this.state.roomValue}
            >
              {(_, selectedRows, actions) => (
                <Field
                  isLink
                  // value={selectedRows.map(el => el.text).join(',')}
                  value={selectedRows.length > 0 ? selectedRows[selectedRows.length - 1].text : ''}
                  readOnly
                  className='door_lock_cascader'
                  placeholder='全部房间'
                  onClick={() => actions.open()}
                />
              )}
            </Cascader>
          </div> */}
          {/* <div className='banpai_cascader'>
                        <Cascader
                            popup={{ round: true }}
                            title='选择处理结果'
                            activeColor="#00B4C4"
                            options={[
                                {
                                    text: '待整改',
                                    value: 0
                                },
                                {
                                    text: '已整改待确认',
                                    value: 1
                                },
                                {
                                    text: '整改完成',
                                    value: 2
                                },
                                {
                                    text: '整改不合格',
                                    value: 3
                                },
                                {
                                    text: '暂无法整改',
                                    value: 4
                                },
                                {
                                    text: '隐患撤回',
                                    value: 5
                                },
                            ]}
                            onFinish={this.handleCascaderType}
                        >
                            {(_, selectedRows, actions) => (
                                <Field
                                    isLink
                                    readOnly
                                    value={selectedRows.length === 1 ? selectedRows[0].text : ''}
                                    className='door_lock_cascader'
                                    placeholder='全部结果'
                                    onClick={() => actions.open()}
                                />
                            )}
                        </Cascader>
                    </div> */}
        </div>
        <div className='hidden_danger_list' style={{ paddingBottom: '46px' }}>
          {(listData || []).length ? listData.map(item => {
            return (
              <div className='hidden_danger_item' key={item.details_id} onClick={() => {
                if (this.state.task_level !== 6) {
                  if (item.hidden_result === 1) {
                    this.props.history.push(`/module/patrol/check_accept/${item.details_id}`)
                  } else {
                    this.props.history.push(`/module/patrol/accept_detail/${item.details_id}`)
                  }
                } else {
                  if (item.hidden_result === 1) {
                    this.props.history.push(`/module/patrol/briefing_check_accept/${item.details_id}`)
                  } else {
                    this.props.history.push(`/module/patrol/briefing_accept_detail/${item.details_id}`)
                  }
                }


              }}>
                {item.hidden_result === -2 ? <div className='hidden_danger_type hidden_danger_no'>不属实隐患</div> : ''}

                {item.hidden_result === -1 ? <div className='hidden_danger_type hidden_danger_not'>待核实隐患</div> : ''}

                {item.hidden_result === 0 ? <div className='hidden_danger_type hidden_danger_not'>待整改</div> : ''}

                {item.hidden_result === 1 ? <div className='hidden_danger_type hidden_danger_wait'>待验收</div> : ''}

                {item.hidden_result === 2 ? <div className='hidden_danger_type hidden_danger_ok'>已验收</div> : ''}

                {item.hidden_result === 3 ? <div className='hidden_danger_type hidden_danger_ok'>已验收</div> : ''}

                {item.hidden_result === 4 ? <div className='hidden_danger_type hidden_danger_no'>暂无法整改</div> : ''}

                {item.hidden_result === 5 ? <div className='hidden_danger_type hidden_danger_no'>隐患撤回</div> : ''}

                <div className='hidden_danger_cont'>
                  <div className='hidden_danger_left'>
                    <div className='hidden_danger_icon'>
                      {item.details_state === 1 ? (<img src={require('./../../../img/一般隐患.png')} alt='' />) : (<img src={require('./../../../img/严重隐患.png')} alt='' />)}
                    </div>
                    <div className='hidden_danger_title'>
                      {item.task_title ? (<div style={{ width: wt + 'px' }}>{item.task_title}</div>) : ''}

                      {item.task_name ? <div className='potrol_num' style={{ width: wt + 'px' }}> {item.task_name}</div> : ''}

                      {item.task_level > 3 ? <div style={{ width: wt + 'px' }}>{item.room_name}</div> : ''}
                      <div className='hidden_danger_desc' style={{ width: wt + 'px' }}>
                        {item.task_level < 4 ? <div>
                          {item.building_name}/{item.floor_name}/{item.room_name}
                        </div> : <div>
                          {item.building_name}/{item.floor_name}
                        </div>}

                        {item.details_state === 1 ? (<div className='hidden_danger_sort'>
                          无隐患
                        </div>) : (item.details_state === 2 ? <div className='hidden_danger_serious'>
                          一般隐患
                        </div> : <div className='hidden_danger_noDangers'>
                          {
                            item.details_state === 3 ? '重大隐患' : '严重隐患'
                          }
                        </div>)}
                      </div>
                    </div>
                  </div>
                  {/* {item.sp_state === 2 && item.hidden_result === 0 ? (<div className='hidden_danger_sub' onClick={(e) => {
                    e.stopPropagation()
                    // 查看详情
                    this.props.history.push(`/module/patrol/hidden_detail/${item.details_id}`)
                  }}>处理</div>) : ('')} */}

                </div>
                <div className='hidden_danger_date'>
                  巡检时间：{moment(item.complete_time).format('YYYY-MM-DD HH:mm')}
                </div>
              </div>
            )
          })
            : (<div className='not-yet'>--暂无数据--</div>)}
        </div>
        {this.state.total > 20 ? <div className='curriculum_page'>
          <Pagination total={this.state.pages}
            current={this.state.pageNum}
            onChange={e => {
              this.setState({
                pageNum: e
              }, () => {
                this.fetch()
              })
            }}
            locale={{
              prevText: (<span className="arrow-align"><Icon type="left" /></span>),
              nextText: (<span className="arrow-align"><Icon type="right" /></span>),
            }}
          />
        </div> : ''}

      </div >
    )
  }
}

export default HiddenDanger