import React, { Component } from 'react'
import { Radio } from 'antd'
import { Toast, Picker, TextareaItem } from 'antd-mobile'
import { Cascader, Popup } from 'react-vant'
import { findSubRoomIds, getCourseSemesterByTime, findClientDate, getNodeBySemester, findScheduleRecord, findUserClientRecord, findSeatInfo, submitSubscribeRoom, listDictionary, selectOneLab } from '../../../api/api'
import moment from 'moment'
import $ from 'jquery'

class Appointment extends Component {

  state = {
    roomData: [], // 房间
    buildRoom: [], // 楼宇房间
    roomId: '', // 选中的房间id
    room: '', // 选中的房间名称
    build: '', // 选中的房间所属楼栋
    semesterId: '', // 当前学期id
    weekCount: [], // 所有周详情
    remainingWeeks: [], // 当前周之后所有周详情
    currentWeek: {}, // 当前周详情
    currentTimePeriod: {}, // 选中周详情
    weeks: [], // 星期
    semeterData: [], // 节次
    curriculumData: [], // 数据
    widths: 40,
    selectedTime: [], // 选中的时间段
    visible: false,
    seatData: [], // 座位布局
    seatNumber: '',
    dateVisible: false, // 日期选择器是否显示
    selectedWeek: [], // 选中的周
    ssytData: [], // 实验用途
    syyt_id: '',
    symd: '', // 实验目的
    synr: '', // 实验内容
    syyq: '', // 实验要求
  }

  componentDidMount = async () => {
    const res = await findSubRoomIds({ bs: 3 })
    if (res.code === 20000) {
      const record = this.props.location.state
      this.setState({
        roomId: record ? record.roomId : res.data.length ? res.data[0].dwdm : '',
        room: record ? res.data.find(x => x.dwdm === record.roomId).dwmc : res.data.length ? res.data[0].dwmc : ''
      })
      if (record) {
        this.fetchTime(record.roomId)
        this.fetchRoomInfo(record.roomId)
      } else {
        if (res.data.length) {
          this.fetchTime(res.data[0].dwdm)
          this.fetchRoomInfo(res.data[0].dwdm)
        }
      }
    }
    this.tablesWidth()
    this.fetchRoom()
    this.fetchSyyt()
  }

  // 查询位置
  fetchRoom = async () => {
    const res = await findSubRoomIds()
    if (res.code === 20000) {
      let menuList = res.data.map(item => {
        return {
          text: item.dwmc,
          parentId: item.bs === 1 ? '0' : item.sj_dm,
          value: item.dwdm,
        }
      })
      menuList = menuList.map(item => {
        if (menuList.filter(p => p.parentId === item.value).length) {
          item.children = menuList.filter(p => p.parentId === item.value)
        }
        return item
      }).filter(p => p.parentId === '0')
      this.setState({
        roomData: res.data,
        buildRoom: menuList
      })
    }
  }

  // 根据id查询房间详情信息
  fetchRoomInfo = async id => {
    const res = await selectOneLab({ id })
    if (res.code === 20000) {
      this.setState({
        build: res.data.lymc
      })
    }
  }

  // table表格最小宽度样式设置
  tablesWidth = () => {
    setTimeout(() => {
      if (document.getElementById("tables")) {
        var a = document.getElementById("tables").offsetWidth
        var list = $(".table_title")
        if (list.length > 1) {
          for (var i = 0; i < list.length; i++) {
            list[i].style.width = Math.floor((a / 8)) + "px"
          }
        }
        this.setState({
          widths: Math.floor((a / 8)) - 3
        })
      }
    }, 10)
  }

  // 查询实验用途
  fetchSyyt = async () => {
    const res = await listDictionary({ ZDLX: 'yy-syyt' })
    if (res.code === 20000) {
      this.setState({
        ssytData: res.data
      })
    }
  }

  // 查询当前课程表的时间范围
  fetchTime = async (roomId) => {
    const res = await getCourseSemesterByTime()
    if (res.code === 20000 && res.data) {
      const kssj = this.getFirstDayOfWeek(new Date(res.data.kssj))
      const weekCount = this.getDateByWeekCount(kssj, res.data.jssj)
      const currentTimePeriod = weekCount.find(x => (moment(x.begin + " 00:00:00") <= moment(new Date())) && (moment(x.end + " 23:59:59") >= moment(new Date())))
      this.findClientDate(res.data.id, currentTimePeriod.begin, roomId)
      this.fetchNode(res.data.id)
      const arr = weekCount.filter(x => x.weekCount >= currentTimePeriod.weekCount).map(t => ({ label: `第${t.weekCount}周`, value: t.weekCount }))
      this.setState({
        weekCount,
        remainingWeeks: arr,
        currentWeek: currentTimePeriod,
        currentTimePeriod,
        semesterId: res.data.id,
      })
    }
  }

  // 获取星期
  findClientDate = async (semesterId, dateStr, roomId) => {
    const res = await findClientDate({ semesterId, dateStr })
    if (res.code === 20000) {
      const arr = res.data.map(x => {
        return {
          date: Number(x.split('/')[0].split('-')[1]) + '日',
          week: x.split('/')[1]
        }
      })
      this.fetch(semesterId, dateStr, roomId)
      this.setState({
        weeks: arr
      })
    }
  }

  // 获取节次
  fetchNode = async semesterId => {
    const res = await getNodeBySemester({ semesterId })
    if (res.code === 20000) {
      this.setState({
        semeterData: res.data
      })
    }
  }

  // 获取数据
  fetch = async (semesterId, dateStr, roomId) => {
    const res = await findScheduleRecord({ semesterId, dateStr, roomId })
    if (res.code === 20000) {
      const result = await findUserClientRecord({ roomId, startDay: dateStr, flag: 1 })
      if (result.code === 20000) {
        Toast.hide()
        let arr = res.data
        let arr2 = result.data
        let arr3 = this.flatten(arr2)
        arr.map(x => {
          x.map(t => {
            t.time = arr3.find(y => y.count === t.count).time
            t.peopleNum = arr3.find(y => y.count === t.count).peopleNum
            t.flag = arr3.find(y => y.count === t.count).flag
            t.node = arr3.find(y => y.count === t.count).node
            t.reservationRecordVO = arr3.find(y => y.count === t.count).reservationRecordVO
            return t
          })
          return x
        })
        this.setState({
          curriculumData: arr
        })
      } else {
        this.setState({
          curriculumData: []
        })
      }
    }
  }

  // 切换房间
  handleCascaderFinish = (val, selectedRows, actions) => {
    const { roomData, currentWeek, semesterId } = this.state
    Toast.loading('', 0)
    this.setState({
      roomId: val[val.length - 1],
      room: roomData.find(x => x.dwdm === val[val.length - 1]).dwmc,
      currentTimePeriod: currentWeek,
      selectedWeek: [currentWeek.weekCount],
      selectedTime: []
    })
    this.fetchRoomInfo(val[val.length - 1])
    if (semesterId && currentWeek.begin) {
      this.fetch(semesterId, currentWeek.begin, val[val.length - 1])
    } else {
      Toast.hide()
    }
  }

  // 点击课程
  handleNotAppointmentCourse = item => {
    Toast.info(item)
  }

  // 点击不可预约时间段
  handleNotAppointment = () => {
    Toast.info('该时间段不可预约！')
  }

  // 点击可预约时间段
  handleAppointment = item => {
    const { selectedTime } = this.state
    if (selectedTime.find(x => x.count === item.count)) {
      if ((selectedTime[0].node === item.node) || selectedTime[selectedTime.length - 1].node === item.node) {
        this.setState({
          selectedTime: selectedTime.filter((t => t.count !== item.count))
        })
      } else {
        Toast.info('请按顺序取消预约时间段！')
      }
    } else {
      if (selectedTime.length) {
        const obj1 = selectedTime[0]
        const obj2 = selectedTime[selectedTime.length - 1]
        if (item.date !== selectedTime[0].date) {
          Toast.info('请选择同一天时间段！')
        } else if (item.node === (obj1.node - 1)) {
          selectedTime.unshift(item)
        } else if (item.node === (obj2.node + 1)) {
          selectedTime.push(item)
        } else {
          Toast.info('请选择相邻时间段！')
        }
        this.setState({
          selectedTime
        })
      } else {
        this.setState({
          selectedTime: selectedTime.concat([item])
        })
      }
    }
  }

  // 选择周
  handleDepPickerOkBtnClick = e => {
    const { weekCount, semesterId, roomId } = this.state
    const obj = weekCount.find(x => x.weekCount === e[0])
    this.setState({
      dateVisible: false,
      currentTimePeriod: obj
    })
    this.findClientDate(semesterId, obj.begin, roomId)
  }

  // 获取当前时间段内第几周的开始日期和结束日期
  getDateByWeekCount = (startDate, endDate) => {
    function getWeekCount(begin, end) {
      let weekCount = 0
      const weekData = []
      while (begin < end) {
        weekCount++
        begin = moment(begin).add(7, 'day').format('YYYY-MM-DD')
        weekData.push({ weekCount, begin: moment(begin).add(-7, 'day').format('YYYY-MM-DD'), end: moment(begin).add(-1, 'day').format('YYYY-MM-DD') })
      }
      return weekData
    }
    return getWeekCount(startDate, endDate)
  }

  // 数组扁平化
  flatten = (arr) => {
    // 递归实现数组扁平化
    let result = []
    for (let i = 0; i < arr.length; i++) {
      if (Array.isArray(arr[i])) {
        result = result.concat(this.flatten(arr[i]))
      } else {
        result.push(arr[i])
      }
    }
    return result
  }

  // 递归创建数组
  countdown = (n) => {
    if (n < 1) {
      return []
    } else {
      const arr = this.countdown(n - 1)
      arr.unshift(n)
      return arr
    }
  }

  // 根据日期的方法获取当前这一周的周一
  getFirstDayOfWeek = (date) => {
    var day = date.getDay() || 7
    var newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1 - day)
    return moment(newDate).format('YYYY-MM-DD')
  };

  getweekday = (date) => {
    var weekArray = new Array("周日", "周一", "周二", "周三", "周四", "周五", "周六")
    var week = weekArray[new Date(date).getDay()]
    return week
  }

  handleSeat = async () => {
    const { selectedTime, roomId } = this.state
    const res = await findSeatInfo({ roomId, nodes: JSON.stringify(selectedTime) })
    if (res.code === 20000) {
      let arr = res.data.map(x => {
        x.seat = this.countdown((x.seat_number || 0)).reverse()
        return x
      })
      let newArr = arr.map(x => {
        x.seat = x.seat.map(t => {
          return {
            seat_place: t,
            disabled: x.seatNumbers.find(p => p === t) ? true : false
          }
        })
        return x
      })
      this.setState({
        visible: true,
        seatData: newArr
      })
    }
  }

  // 选择座位
  handleChangeSeat = e => {
    this.setState({
      seatNumber: e.target.value
    })
  }

  handleSubmit = async () => {
    const { roomId, room, seatNumber, selectedTime, currentTimePeriod, semesterId, syyt_id, ssytData, symd, synr, syyq } = this.state
    const selectTimes = selectedTime.map(x => {
      return {
        start_time: x.startTime,
        end_time: x.endTime,
        date_time: x.date,
        node: x.node,
      }
    })

    if (selectedTime.length && selectedTime[0].peopleNum > 0) {
      if (!seatNumber) {
        Toast.info('请选择预约的座位号！')
        return
      }
    }
    // if (seatNumber) {
    if (!syyt_id) return Toast.info('请选择实验用途！')
    if (!symd) return Toast.info('请输入实验目的！')
    if (!synr) return Toast.info('请输入实验内容！')
    if (!syyq) return Toast.info('请输入实验要求！')
    const res = await submitSubscribeRoom({
      room_id: roomId,
      room,
      seat_number: seatNumber,
      timeInfo: JSON.stringify(selectTimes),
      syyt_id,
      syyt_name: syyt_id ? ssytData.find(x => x.id === syyt_id).zdmc : '',
      symd,
      synr,
      syyq,
    })
    if (res.code === 20000) {
      Toast.info(res.message)
      this.setState({
        visible: false,
        seatNumber: '',
        syyt_id: '',
        symd: '',
        synr: '',
        syyq: '',
        selectedTime: [],
      })
      this.fetch(semesterId, currentTimePeriod.begin, roomId)
    }
    // } else {
    //   Toast.info('请选择预约的座位号！')
    // }
  }

  render() {
    const { room, build, weeks, selectedTime, widths, curriculumData, semeterData, dateVisible, selectedWeek, remainingWeeks, currentTimePeriod, buildRoom, visible, seatData, seatNumber, syyt_id, ssytData, symd, synr, syyq } = this.state
    return (
      <div className='shortTerm'>
        <div className='shortTerm_img'>
          <Cascader
            popup={{ round: true }}
            title='选择实验室'
            activeColor="#00B4C4"
            options={buildRoom}
            onFinish={this.handleCascaderFinish}
          >
            {(_, selectedRows, actions) => (
              <div onClick={() => actions.open()}>切换房间</div>
            )}
          </Cascader>
        </div>
        <div className='shortTerm_name'>{room}</div>
        <div className='shortTerm_addr'>{build}</div>
        <div className='shortTerm_con'>
          <div className='shortTerm_con_top'>
            {
              currentTimePeriod.weekCount ? <div className='shortTerm_con_time' onClick={() => this.setState({ dateVisible: true })}>
                <span>第{currentTimePeriod.weekCount}周 {currentTimePeriod.begin && moment(currentTimePeriod.begin).format('YYYY年MM月')}</span>
                <img alt='' src={require('../../../images/xxjt.png')} />
              </div> : <span></span>
            }
            <div className='shortTerm_con_record' onClick={() => this.props.history.push('/module/appointmentRecord')}>
              <img alt='' src={require('../../../images/jsyy3.png')} />
              <span>我的预约</span>
            </div>
          </div>
          {weeks.length ? <div id='tables' className='shortTerm_con_table'>
            <table width='100%'>
              <thead>
                <tr>
                  <th className='table_title table_th_desc' style={{ width: `${widths}px` }}>时间</th>
                  {
                    (weeks || []).map((item, key) => {
                      return <th className='table_title table_th_week' key={key} style={{ width: `${widths}px` }}>
                        <div>{item.week}</div>
                        <div>{item.date}</div>
                      </th>
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  (curriculumData || []).map((item, index) => {
                    return <tr key={index}>
                      <td className='table_title table_jc' style={{ width: `${widths}px` }}>
                        第{semeterData[index].jc}节
                      </td>
                      {
                        (item || []).map((item_, key) => {
                          return <td className='table_title' key={key}>
                            {
                              (new Date(item_.date + ' ' + item_.startTime + ':00') < new Date()) ? (item_.courseScheduleRecordEntity ?
                                <div className='table_title_not' style={{ width: `${widths}px` }} onClick={() => this.handleNotAppointmentCourse(item_.courseScheduleRecordEntity.kcmc)}>{item_.courseScheduleRecordEntity.kcmc}</div>

                                : item_.reservationRecordVO ? <div className='table_title_not' style={{ width: `${widths}px`, fontSize: '12px' }} onClick={() => this.handleNotAppointmentCourse(item_.reservationRecordVO.reservationist)}>{item_.reservationRecordVO.reservationist}</div>
                                  : <div className='table_title_not' style={{ width: `${widths}px` }} onClick={() => this.handleNotAppointment()} />)

                                : (item_.flag === '0' ? (item_.courseScheduleRecordEntity ?
                                  <div className='table_title_not' style={{ width: `${widths}px` }} onClick={() => this.handleNotAppointment()}>{item_.courseScheduleRecordEntity.kcmc}</div>
                                  : item_.reservationRecordVO ? <div className='table_title_not' style={{ width: `${widths}px`, fontSize: '12px' }} onClick={() => this.handleNotAppointmentCourse(item_.reservationRecordVO.reservationist)}>{item_.reservationRecordVO.reservationist}</div>

                                    : <div className='table_title_not' style={{ width: `${widths}px`, fontSize: '12px' }} onClick={() => this.handleNotAppointment()} >无座位</div>)

                                  : (item_.courseScheduleRecordEntity ? <div className='table_title_not' style={{ width: `${widths}px` }} onClick={() => this.handleNotAppointment()}>{item_.courseScheduleRecordEntity.kcmc}</div>

                                    : <div className='table_title_yes' style={{ width: `${widths}px` }} onClick={() => this.handleAppointment(item_)}>
                                      {
                                        selectedTime.find(t => t.count === item_.count) ? <div className='table_title_yes_licked'>
                                          <div>可预约</div>
                                          <div>{item_.peopleNum > 0 ? '余' + item_.peopleNum : ''}</div>
                                        </div> : <div className='table_title_yes_not_licked'>
                                          <div>可预约</div>
                                          <div>{item_.peopleNum > 0 ? '余' + item_.peopleNum : ''}</div>
                                        </div>
                                      }
                                    </div>)
                                )
                            }
                          </td>
                        })
                      }
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div> :
            <div className='noCantTip'>
              <span className='tipBG'></span>
              当前不在学期范围内，无法预约！
            </div>

          }

        </div>
        {
          selectedTime && selectedTime.length ? <div className='shortTerm_footer'>
            <div className='shortTerm_footer_time'>
              <div>已选择</div>
              <div>{selectedTime[0].date.substr(5, 5)} {this.getweekday(selectedTime[0].date)} {selectedTime[0].startTime}-{selectedTime[selectedTime.length - 1].endTime}时段</div>
            </div>
            <div className='shortTerm_footer_btn' onClick={() => this.handleSeat()}>提交预约</div>
          </div> : ''
        }
        {/* 日期选择器 */}
        <Picker
          className='dateSelect'
          visible={dateVisible}
          cols={1}
          value={selectedWeek}
          data={remainingWeeks}
          onPickerChange={e => this.setState({ selectedWeek: e })}
          onDismiss={() => this.setState({ dateVisible: false })}
          onOk={e => this.handleDepPickerOkBtnClick(e)}
        >
        </Picker>
        <Popup
          visible={visible}
          position='bottom'
          title={selectedTime.length && selectedTime[0].peopleNum > 0 ? '选择座位' : "提交预约"}
          overlay
          round={true}
          closeable={true}
          isLink
          onClose={() => this.setState({
            visible: false,
            seatNumber: '',
            syyt_id: '',
            symd: '',
            synr: '',
            syyq: '',
          })}
        >
          <div className='appointment_modal_con'>
            {
              selectedTime.length && selectedTime[0].peopleNum > 0 ? (<div className='appointment_modal_con_radio'>
                <Radio.Group buttonStyle="solid" value={seatNumber} onChange={e => this.handleChangeSeat(e)}>
                  {
                    (seatData || []).map((item, i) => {
                      return <div className='appointment_modal_seat' key={'A' + i}>
                        <div className='appointment_modal_seat_con'>
                          {
                            (item.seat || []).map(item_ => {
                              return <div className='appointment_modal_seat_con_list' key={'B' + item_.seat_place}>
                                <Radio.Button value={item_.seat_place} disabled={item_.disabled}>{item_.seat_place}</Radio.Button>
                              </div>
                            })
                          }
                        </div>
                      </div>
                    })
                  }
                </Radio.Group>
              </div>) : ('')
            }

            <div className='appointment_modal_user'>
              <div className='appointment_modal_user_title'><span style={{ color: 'red' }}>* </span>实验用途</div>
              <div className='appointment_modal_con_radio'>
                <Radio.Group buttonStyle="solid" value={syyt_id} onChange={e => this.setState({ syyt_id: e.target.value })}>
                  {
                    (ssytData || []).map(item_ => {
                      return <div className='appointment_modal_seat_con_list' key={'C' + item_.id}>
                        <Radio value={item_.id}>{item_.zdmc}</Radio>
                      </div>
                    })
                  }
                </Radio.Group>
              </div>
            </div>
            <div className='appointment_modal_user'>
              <div className='appointment_modal_user_title'><span style={{ color: 'red' }}>* </span>实验目的</div>
              <TextareaItem
                rows={4}
                value={symd}
                placeholder="请输入实验目的~"
                onChange={val => this.setState({ symd: val })}
              />
            </div>
            <div className='appointment_modal_user'>
              <div className='appointment_modal_user_title'><span style={{ color: 'red' }}>* </span>实验内容</div>
              <TextareaItem
                rows={4}
                value={synr}
                placeholder="请输入实验内容~"
                onChange={val => this.setState({ synr: val })}
              />
            </div>
            <div className='appointment_modal_user'>
              <div className='appointment_modal_user_title'><span style={{ color: 'red' }}>* </span>实验要求</div>
              <TextareaItem
                rows={4}
                value={syyq}
                placeholder="请输入实验要求~"
                onChange={val => this.setState({ syyq: val })}
              />
            </div>
          </div>
          <div className='appointment_popup'>
            <div className='appointment_popup_btn' onClick={() => this.handleSubmit()}>确定</div>
          </div>
        </Popup>
      </div>
    )
  }
}

export default Appointment