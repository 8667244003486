import React, { Component } from 'react'
import { Pagination, Icon, SearchBar } from 'antd-mobile'
import { findSecondModuleInfo } from '../../../../api/api'
import { listMyApprovalRecord } from '../../../../api/gas'
import moment from 'moment'
import './list.less'

class Approve extends Component {

  state = {
    pageNum: 1,
    pageSize: 20,
    pages: 0,
    total: 0,
    configData: [],
    statusData: [
      { id: 1, name: '待审批', checked: true },
      { id: 2, name: '已审批', checked: false },
    ],
    status: 20, //审批类型 20开始
    type: 1, // 审批状态 （1待审批  2已审核）
    data: [],
    paramKey: ''
  }

  componentDidMount = () => {
    document.title = '审批管理'
    window.scrollTo(0, 0)
    this.fetchModuleNum()
  }

  // 查询mobule_url数字模块
  fetchModuleNum = async () => {
    const res = await findSecondModuleInfo()
    if (res.code === 20000) {
      const arr = res.data.filter(x => x.module_type === '12')
      const newArr = arr.map((x, i) => {
        return {
          id: Number(x.module_url),
          name: x.module_name,
          checked: i ? false : true
        }
      })
      this.setState({
        configData: newArr,
        status: newArr[0].id,
      }, () => {
        if (this.props.location.state) {
          const record = this.props.location.state
          if (record.status === 25) {
            this.warehousingFetch()
            this.setState({
              status: 25
            })
          } else if (record.status === 26) {
            this.useFetch()
            this.setState({
              status: 26
            })
          } else if (record.status === 27) {
            this.repayFetch()
            this.setState({
              status: 27
            })
          }
        } else {
          if (newArr[0].id === 25) {
            this.warehousingFetch()
          } else if (newArr[0].id === 26) {
            this.useFetch()
          } else if (newArr[0].id === 27) {
            this.repayFetch()
          }
        }
      })
    }
  }

  // 入库审批
  warehousingFetch = async () => {
    const { pageSize, pageNum, type, paramKey } = this.state
    const res = await listMyApprovalRecord({ size: pageSize, page: pageNum, result: type, flag: 2, paramKey })
    if (res.code === 20000) {
      this.setState({
        data: res.data.list || [],
        pages: res.data ? (res.data.pages ? res.data.pages : 1) : 1,
        total: res.data.total,
      })
    }
  }

  // 领用审批
  useFetch = async () => {
    const { pageSize, pageNum, type, paramKey } = this.state
    const res = await listMyApprovalRecord({ size: pageSize, page: pageNum, result: type, flag: 3, paramKey })
    if (res.code === 20000) {
      console.log(res.data.list)
      this.setState({
        data: res.data.list || [],
        pages: res.data ? (res.data.pages ? res.data.pages : 1) : 1,
        total: res.data.total,
      })
    }
  }

  // 归还审批
  repayFetch = async () => {
    const { pageSize, pageNum, type, paramKey } = this.state
    const res = await listMyApprovalRecord({ size: pageSize, page: pageNum, result: type, flag: 4, paramKey })
    if (res.code === 20000) {
      this.setState({
        data: res.data.list || [],
        pages: res.data ? (res.data.pages ? res.data.pages : 1) : 1,
        total: res.data.total,
      })
    }
  }


  render() {
    const { statusData, data, configData, status, type } = this.state
    return (
      <div className='approve'>
        <div className='approve_header'>
          <div className='gas_manage_list_head_top gas_manage_list_head_manage'>
            <SearchBar placeholder="请输入入库单号" value={this.state.paramKey} onCancel={(e) => {
              this.setState({
                paramKey: ''
              }, () => {
                if (status === 25) {
                  this.warehousingFetch()
                } else if (status === 26) {
                  this.useFetch()
                } else if (status === 27) {
                  this.repayFetch()
                }
              })
            }} onChange={e => {
              this.setState({
                paramKey: e
              }, () => {
                if (status === 25) {
                  this.warehousingFetch()
                } else if (status === 26) {
                  this.useFetch()
                } else if (status === 27) {
                  this.repayFetch()
                }
              })
            }} />
            <div className='gas_manage_list_head_tabs'>
              {
                configData.map(item => {
                  return <div className={status === item.id ? 'gas_manage_list_head_tabs_selected' : 'gas_manage_list_head_tabs_item'} key={item.id} onClick={() => {
                    this.setState({
                      status: item.id,
                      type: 1,
                    }, () => {
                      if (item.id === 25) {
                        this.warehousingFetch()
                      } else if (item.id === 26) {
                        this.useFetch()
                      } else if (item.id === 27) {
                        this.repayFetch()
                      }
                    })
                  }}>
                    <div>{item.name}</div>
                    <div></div>
                  </div>
                })
              }
            </div>

            {/* 待审核，审核成功，审核失败 */}
            <div className='gas_approve_tabs'>
              <div className='gas_approve_tabs_con'>
                {
                  statusData.map(item => {
                    return <div className={`gas_approve_tabs_con_list ${type === item.id ? 'gas_approve_tabs_con_list_active' : ''}`} key={item.id} onClick={() => {
                      this.setState({
                        type: item.id
                      }, () => {
                        if (status === 25) {
                          this.warehousingFetch()
                        } else if (status === 26) {
                          this.useFetch()
                        } else if (status === 27) {
                          this.repayFetch()
                        }
                      })
                    }}>{item.name}</div>
                  })
                }

              </div>
            </div>
          </div>

        </div>

        <div className='gas_approve_con'>



          {/* 入库审批 */}
          {status === 25 ? (<div className='approve_manager_top'>

            {data.map((item, key) => {
              return (
                <div className='gas_warehousing_list_item' key={key} onClick={() => {
                  this.props.history.push(`/module/gasCylinder/approveWarRecord/${item.pr_id}`)
                }}>
                  <div className='gas_warehousing_head'>
                    <div>
                      入库单号 {item.rkdh}
                    </div>

                    {type === 1 ? <div className='gas_warehousing_none'>
                      待审批
                    </div> : ''}
                    {type === 2 ? <div className='gas_warehousing_ok'>
                      审批通过
                    </div> : ''}

                  </div>
                  <div className='gas_warehousing_address'>
                    {item.xymc}-{item.zxmc}-{item.jsmc}
                  </div>
                  <div className='gas_warehousing_tag'>
                    <div>{item.rksqrxm}</div>
                    <div>气体种类{item.rkzls}种</div>
                  </div>
                  <div className='gas_warehousing_date'>
                    {item.rksj ? moment(item.rksj).format('YYYY-MM-DD HH:mm:ss') : ''}
                  </div>
                </div>
              )
            })}

            {
              this.state.total > 20 ? (<div className='subscription_manager_page'>
                <Pagination total={this.state.pages}
                  current={this.state.pageNum}
                  onChange={e => {
                    this.setState({
                      pageNum: e
                    }, () => {
                      window.scrollTo(0, 0)
                      this.warehousingFetch()
                    })
                  }}
                  locale={{
                    prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                    nextText: (<span className="arrow-align"><Icon type="right" /></span>),
                  }}
                />
              </div>) : ''
            }
          </div>) : ''}

          {/* 领用审批 */}
          {status === 26 ? (<div className='approve_manager_top'>

            {data.map((item, key) => {
              return (
                <div className='gas_warehousing_list_item' key={key} onClick={() => {
                  this.props.history.push(`/module/gasCylinder/approveUseRecord/${item.pr_id}`)
                }}>
                  <div className='gas_warehousing_head'>
                    <div>
                      领用单号 {item.lybh}
                    </div>

                    {type === 1 ? <div className='gas_warehousing_none'>
                      待审批
                    </div> : ''}
                    {type === 2 ? <div className='gas_warehousing_ok'>
                      审批通过
                    </div> : ''}

                  </div>
                  <div className='gas_warehousing_address'>
                    {item.xymc}-{item.zxmc}-{item.jsmc}
                  </div>
                  <div className='gas_warehousing_tag'>
                    <div>{item.lyrmc1}、{item.lyrmc2}</div>
                    <div>气体种类{item.whpzlsl}种</div>
                  </div>
                  <div className='gas_warehousing_date'>
                    {item.lysqsj ? moment(item.lysqsj).format('YYYY-MM-DD HH:mm:ss') : ''}
                  </div>
                </div>
              )
            })}

            {
              this.state.total > 20 ? (<div className='subscription_manager_page'>
                <Pagination total={this.state.pages}
                  current={this.state.pageNum}
                  onChange={e => {
                    this.setState({
                      pageNum: e
                    }, () => {
                      window.scrollTo(0, 0)
                      this.useFetch()
                    })
                  }}
                  locale={{
                    prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                    nextText: (<span className="arrow-align"><Icon type="right" /></span>),
                  }}
                />
              </div>) : ''
            }

          </div>) : ''}

          {/* 归还审批 */}
          {status === 27 ? (<div className='approve_manager_top'>

            {data.map((item, key) => {
              return (
                <div className='gas_warehousing_list_item' key={key} onClick={() => {
                  this.props.history.push(`/module/gasCylinder/approveRepayRecord/${item.pr_id}`)
                }}>
                  <div className='gas_warehousing_head'>
                    <div>
                      归还单号 {item.ghbh}
                    </div>

                    {type === 1 ? <div className='gas_warehousing_none'>
                      待审批
                    </div> : ''}
                    {type === 2 ? <div className='gas_warehousing_ok'>
                      审批通过
                    </div> : ''}


                  </div>
                  <div className='gas_warehousing_address'>
                    {item.xymc}-{item.zxmc}-{item.jsmc}
                  </div>
                  <div className='gas_warehousing_tag'>
                    <div>{item.sqrmc}</div>
                    <div>气体种类{item.whpzlsl}种</div>
                  </div>
                  <div className='gas_warehousing_date'>
                    {item.sqsj ? moment(item.sqsj).format('YYYY-MM-DD HH:mm:ss') : ''}
                  </div>
                </div>
              )
            })}

            {
              this.state.total > 20 ? (<div className='subscription_manager_page'>
                <Pagination total={this.state.pages}
                  current={this.state.pageNum}
                  onChange={e => {
                    this.setState({
                      pageNum: e
                    }, () => {
                      window.scrollTo(0, 0)
                      this.repayFetch()
                    })
                  }}
                  locale={{
                    prevText: (<span className="arrow-align"><Icon type="left" /></span>),
                    nextText: (<span className="arrow-align"><Icon type="right" /></span>),
                  }}
                />
              </div>) : ''
            }
          </div>) : ''}

          {/* 暂无数据 */}
          {data.length === 0 ? (<div className='not-yet'>--暂无数据--</div>) : ('')}
        </div>
      </div >
    )
  }
}

export default Approve