import React, { Component } from 'react'
import { Upload } from 'antd'
import { List, Picker, DatePicker, Toast, InputItem } from 'antd-mobile'
import { uploadTeacherTemplateInfo, findTemplateId, req_findJcInfo } from '../../../api/api'
import moment from 'moment'
import cookie from 'react-cookies'
import IP from '../../../config/config'

class TestMb extends Component {

  state = {
    xn: '', // 学年
    xqData: [
      { value: '1', label: '秋季学期' },
      { value: '2', label: '春季学期' },
      { value: '3', label: '夏季学期' },
    ],
    xqId: [], // 学期
    endTime: '', // 截止日期
    fileAddr: '', // 上传模板
    kcmc: '', // 课程
    templateId: '',
    assistant: [], // 助教
    assistantId: [],
    bjData: [{ key: 0 }],
    weekData: [
      {
        label: '周一',
        value: 1,
      },
      {
        label: '周二',
        value: 2,
      },
      {
        label: '周三',
        value: 3,
      },
      {
        label: '周四',
        value: 4,
      },
      {
        label: '周五',
        value: 5,
      },
      {
        label: '周六',
        value: 6,
      },
      {
        label: '周日',
        value: 7,
      },
    ],
    sectionData: [],//节次
    kc_week: [],
    kc_ksjc: [],
    kc_jsjc: []
  }

  componentDidMount = () => {
    const id = this.props.match.params.id
    this.findJcInfo();
    if (id !== '0') {
      this.fetchTemplate(id)
      this.setState({
        templateId: id
      })
    }
  }

  // 查询上课节次
  findJcInfo = async () => {
    const res = await req_findJcInfo()
    if (res.code === 20000) {
      this.setState({
        sectionData: res.data.map(item => {
          return {
            label: '第' + item.jcmc + '节',
            value: item.jc,
          }
        })
      })
    }
  }

  // 查询模板详情
  fetchTemplate = async templateId => {
    const res = await findTemplateId({ templateId })
    if (res.code === 20000) {
      this.setState({
        xn: res.data.template_xn,
        xqId: [res.data.template_xq],
        kcmc: res.data.template_kcmc,
        endTime: res.data.end_time ? new Date(res.data.end_time) : new Date(),
        fileAddr: res.data.template_url,
        // kc_week: [res.data.kc_week],
        // kc_ksjc: [res.data.kc_ksjc],
        // kc_jsjc: [res.data.kc_jsjc],
      })
    }
  }

  handleSubmit = async () => {
    const { xn, xqId, fileAddr, endTime, kcmc, templateId, kc_week, kc_ksjc, kc_jsjc } = this.state
    if (!xn) return Toast.info('请选择学年！')
    if (!xqId.length) return Toast.info('请选择学期！')
    if (!kcmc) return Toast.info('请输入课程名称！')
    if (!endTime) return Toast.info('请选择截止日期！')
    if (!fileAddr) return Toast.info('请上传模板文件！')

    // if (!kc_week) return Toast.info('请上传模板文件！')
    // if (!kc_ksjc) return Toast.info('请上传模板文件！')
    // if (!kc_jsjc) return Toast.info('请上传模板文件！')

    const req = templateId ? {
      template_id: templateId,
      template_url: fileAddr,
      template_kcmc: kcmc,
      end_time: endTime,
      // end_time: moment(endTime).format('YYYY-MM-DD HH:mm:00'),
    } : {
      template_xn: xn,
      template_xq: xqId[0],
      template_kcmc: kcmc,
      end_time: endTime,
      // end_time: moment(endTime).format('YYYY-MM-DD HH:mm:00'),
      template_url: fileAddr,
      // kc_week: kc_week.join(','),
      // kc_ksjc: kc_ksjc.join(','),
      // kc_jsjc: kc_jsjc.join(','),
    }
    const res = await uploadTeacherTemplateInfo(req)
    if (res.code === 20000) {
      Toast.info(res.message)
      setTimeout(() => {
        this.props.history.go(-1)
      }, 1500)
    }
  }

  render() {
    const that = this
    const { xn, xqData, xqId, endTime, kcmc, templateId, fileAddr, weekData, kc_week, sectionData, kc_ksjc, kc_jsjc } = this.state
    const props = {
      name: 'file',
      action: IP.host + '/report/templateinfo/uploadTemplateFile',
      headers: { 'Authorization': 'sastc ' + cookie.load('token') },
      showUploadList: false,
      onChange(info) {
        if (info.file.status !== 'uploading') {
          Toast.info('上传中', 0)
        }
        if (info.file.type.includes('pdf')) {

        } else {
          Toast.info("请上传pdf的文件！")
          return false;
        }
        if (info.file.status === 'done') {
          Toast.info(info.file.response.message)
          that.setState({
            fileAddr: info.file.response.data
          })
        } else if (info.file.status === 'error') {
          Toast.fail(`${info.file.name} 文件上传失败.`)
        }
      }
    }
    return (
      <div className='testMb'>
        <div className={templateId ? 'testMb_list testMb_list_disabled' : 'testMb_list'}>
          <DatePicker
            mode="year"
            disabled={templateId ? true : false}
            extra={xn ? xn + '年' : '学年'}
            onChange={date => this.setState({ xn: moment(date).format('YYYY') })}
          >
            <List.Item arrow="horizontal">学年</List.Item>
          </DatePicker>
        </div>
        <div className={templateId ? 'testMb_list testMb_list_disabled' : 'testMb_list'}>
          <Picker
            disabled={templateId ? true : false}
            extra="学期"
            data={xqData}
            cols={1}
            value={xqId}
            onChange={e => this.setState({ xqId: e })}
          >
            <List.Item arrow="horizontal">学期</List.Item>
          </Picker>
        </div>
        <div className={templateId ? 'testMb_list testMb_list_disabled' : 'testMb_list'}>
          <InputItem value={kcmc} placeholder="请输入课程" onChange={e => this.setState({ kcmc: e })}>
            课程
          </InputItem>
        </div>


        {/* <div className={templateId ? 'testMb_list testMb_list_disabled' : 'testMb_list'}>
          <Picker
            disabled={templateId ? true : false}
            extra="课程星期"
            data={weekData}
            cols={1}
            value={kc_week}
            onChange={e => this.setState({ kc_week: e })}
          >
            <List.Item arrow="horizontal">课程星期</List.Item>
          </Picker>
        </div>

        <div className={templateId ? 'testMb_list testMb_list_disabled' : 'testMb_list'}>
          <Picker
            disabled={templateId ? true : false}
            extra="开始节次"
            data={kc_jsjc.length ? sectionData.filter(x => x.value < Number(kc_jsjc.join(','))) : sectionData}
            cols={1}
            value={kc_ksjc}
            onChange={e => this.setState({ kc_ksjc: e })}
          >
            <List.Item arrow="horizontal">开始节次</List.Item>
          </Picker>
        </div>

        <div className={templateId ? 'testMb_list testMb_list_disabled' : 'testMb_list'}>
          <Picker
            disabled={templateId ? true : false}
            extra="结束节次"
            data={kc_ksjc.length ? sectionData.filter(x => x.value > Number(kc_ksjc.join(','))) : sectionData}
            cols={1}
            value={kc_jsjc}
            onChange={e => this.setState({ kc_jsjc: e })}
          >
            <List.Item arrow="horizontal">结束节次</List.Item>
          </Picker>
        </div> */}


        <div className='testMb_list'>
          <DatePicker
            mode='datetime'
            extra='截止日期'
            value={endTime}
            onChange={date => this.setState({ endTime: date })}
          >
            <List.Item arrow="horizontal">截止日期</List.Item>
          </DatePicker>
        </div>

        <div className='testMb_upload'>
          <div className='testMb_upload_title'>上传模板</div>
          <Upload {...props}>
            <div className='testMb_upload_btn'>
              上传
            </div>
          </Upload>
        </div>
        {
          fileAddr ? <div className='testMb_file'>
            {fileAddr.split('/')[fileAddr.split('/').length - 1]}
          </div> : ''
        }
        <div className='testMb_btn'>
          <div className='testMb_btn_list' onClick={() => this.handleSubmit()}>保存</div>
        </div>
      </div>
    )
  }
}

export default TestMb
