import React, { Component } from 'react'
import { NavBar, Icon, List, Button, TextareaItem, WingBlank, WhiteSpace, Toast } from 'antd-mobile';
import ktxx from '../../../images/kt.png'
import redok from '../../../images/red.png'
import redno from '../../../images/unred.png'
import blackok from '../../../images/black.png'
import blackno from '../../../images/unblack.png'
import centerOk from '../../../images/centerOk.png'
import centerNo from '../../../images/centerno.png'
import okno from '../../../images/okno.png'
import okyes from '../../../images/okyes.png'
import { req_commentOrder } from '../../../api/api';

export default class MaintenanceRecord extends Component {
  state = {
    data: [],
    text: '',
    good: false,
    center: false,
    bad: false,
    okGood: false,
  }

  goodOkClick = () => {
    if (this.state.okGood === false) {
      this.setState({
        okGood: true,
        good: false,
        center: false,
        bad: false,
      })
    } else {
      this.setState({
        okGood: false,
      })
    }
  }

  goodClick = () => {
    if (this.state.good === false) {
      this.setState({
        good: true,
        center: false,
        bad: false,
        okGood: false,
      })
    } else {
      this.setState({
        good: false,
      })
    }
  }

  centerClick = () => {
    if (this.state.center === false) {
      this.setState({
        center: true,
        good: false,
        bad: false,
        okGood: false,
      })
    } else {
      this.setState({
        center: false,
      })
    }
  }

  badClick = () => {
    if (this.state.bad === false) {
      this.setState({
        bad: true,
        good: false,
        center: false,
        okGood: false,
      })
    } else {
      this.setState({
        bad: false,
      })
    }
  }

  // 评价
  submit = async () => {
    if (this.state.good === false && this.state.center === false && this.state.bad === false && this.state.okGood === false) {
      Toast.info('请选择评价等级！', 1)
      return ''
    }
    if (this.state.okGood === true) {
      var cStart = 1
    } else if (this.state.good === true) {
      cStart = 2
    } else if (this.state.center === true) {
      cStart = 3
    } else {
      cStart = 4
    }
    this.setState({ clickAble: true })
    const res = await req_commentOrder({ orderId: this.props.match.params.id, content: this.state.text, rank: cStart })
    if (res.code === 20000) {
      Toast.success("评价成功", 2);
      setTimeout(() => {
        this.props.history.push(`/module/order/details/${this.props.match.params.id}/${this.props.match.params.tabs_id}/${this.props.match.params.tabs_type_id}`)
      }, 1000);
    }
  }
  render() {
    const { text } = this.state
    return (
      <div style={{ width: "100%", height: "100vh", background: "#F2F2F2" }}>
        <NavBar
          mode="dark"
          icon={<Icon type="left" />}
          style={{ background: '#00B4C4' }}
          onLeftClick={() => {
            this.props.history.push(`/module/order/details/${this.props.match.params.id}/${this.props.match.params.tabs_id}/${this.props.match.params.tabs_type_id}`)
          }}
        >评价工单</NavBar>
        <div style={{ display: 'flex', background: "white", paddingLeft: "20px", height: 100, alignItems: 'center' }}>
          <div style={{ width: 80 }}>
            <img src={ktxx} style={{ width: '100%', height: '100%' }} alt="卡通形象" />
          </div>
          <div style={{ marginRight: 20, lineHeight: 1.5 }}>
            请您及时对维修人员的维修结果做出评价，十分感谢。
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', background: "white", paddingBottom: 12 }}>
          <div className='evaluate-state' onClick={() => {
            this.goodOkClick();
          }}>
            <img src={this.state.okGood === false ? okno : okyes} alt="okGood" width="25px" />
            <div style={{ paddingTop: 5 }}>非常满意</div>
          </div>
          <div className='evaluate-state' onClick={() => {
            this.goodClick();
          }}>
            <img src={this.state.good === false ? blackok : redok} alt="good" width="25px" />
            <div style={{ paddingTop: 5 }}>满意</div>
          </div>
          <div className='evaluate-state' onClick={() => {
            this.centerClick();
          }}>
            <img src={this.state.center === false ? centerNo : centerOk} alt="middle" width="25px" />
            <div style={{ paddingTop: 5 }}>一般般</div>
          </div>
          <div className='evaluate-state' onClick={() => {
            this.badClick();
          }}>
            <img src={this.state.bad === false ? blackno : redno} alt="bad" width="25px" />
            <div style={{ paddingTop: 5 }}>不满意</div>
          </div>
        </div>
        <WhiteSpace size="xl" />
        <WhiteSpace size="md" />
        <WingBlank size="lg">
          <List>
            <TextareaItem
              rows={6}
              count={100}
              placeholder="请输入评价内容..."
              value={text}
              onChange={(e) => {
                this.setState({
                  text: e
                })
              }}
            />
          </List>
        </WingBlank>
        <WhiteSpace size="xl" />
        <WhiteSpace size="xl" />
        <WingBlank size="lg">
          <Button type="primary" className='evaluate_btn' onClick={this.submit} disabled={this.state.clickAble}>提交评价</Button>
        </WingBlank>
      </div>
    )
  }
}
